import { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import styles from './LearnAboutSkillsComponent.module.scss';

import { NavLink } from 'react-router-dom';
import { ProjectSkillData } from '../../../../../../types/ProjectOnboardingData';
import {capitalizeFirstLetter} from "../../../../../../utils/capitalize-first-letter";

const LearnAboutSkillsComponent: React.FC<{chosenSkillData: any, closeSkillDescriptionPanel: Function, isReadOnly? : boolean}> = (props) => {
	useEffect(() => {	
		ReactTooltip.rebuild();
	}, [props.chosenSkillData]);
	return (
		<div className={styles["skill-description"]}>
			<div className='header-wrapper'>
				<h3>{capitalizeFirstLetter(props.chosenSkillData.jobPosition.name)}
					<span className='skill-numbers'>{props.chosenSkillData.commonSkills.length}/{props.chosenSkillData.commonSkills.length + props.chosenSkillData.missingSkills.length + props.chosenSkillData.commonNotApprovedSkills.length} skills</span>
				</h3>							
				<div className='close-button' onClick={e => { props.closeSkillDescriptionPanel(e)
				}}>
					<i className="fa-solid fa-xmark"></i>
				</div>
			</div>
			{ props.chosenSkillData.commonSkills && props.chosenSkillData.commonSkills.length > 0 &&
				<div className='skills-warapper'>
					<div className='skills-header-wrapper'>
						<img src='/assets/images/global-icons/skills-has.svg' alt='Icon' />
						<h3>Has for this role:</h3>
					</div>
					<div className='skills-tags-wrapper'>
						{
							props.chosenSkillData.commonSkills.map((skill: ProjectSkillData) => {
								return <div key={skill.skill.id} className='skill-tag' data-html={true} data-for='skill-description-2'
								data-tip={`<div style="">		
									<h3 style="font-size: 16px; margin-top: 8px">${capitalizeFirstLetter(skill.synonym ? skill.synonym.name : skill.skill.name)}</h3>					
									<p style="line-height: 140%; font-size: 14px">${skill.synonym ? skill.synonym.description ? skill.synonym.description : skill.skill.description : skill.skill.description}</p>
								</div>`}>
									{capitalizeFirstLetter(skill.synonym ? skill.synonym.name : skill.skill.name)}
								</div>
							})
						}
					</div>
				</div>			
			}

			{ props.chosenSkillData.missingSkills && props.chosenSkillData.missingSkills.length > 0 &&
				<div className='skills-warapper'>
					<div className='skills-header-wrapper'>
						<img src='/assets/images/global-icons/skills-learn.svg' alt='Icon' />
						<h3>Skills to be learned:</h3>
					</div>
					<div className='skills-tags-wrapper'>
						{
							props.chosenSkillData.missingSkills.map((skill: ProjectSkillData) => {
								return <div key={skill.skill.id} className='skill-tag' data-html={true} data-for='skill-description-2'
								data-tip={`<div style="">		
									<h3 style="font-size: 16px; margin-top: 8px">${capitalizeFirstLetter(skill.synonym ? skill.synonym.name : skill.skill.name)}</h3>					
									<p style="line-height: 140%; font-size: 14px">${skill.synonym ? skill.synonym.description ? skill.synonym.description : skill.skill.description : skill.skill.description}</p>
								</div>`}>
									{capitalizeFirstLetter(skill.synonym ? skill.synonym.name : skill.skill.name)}
								</div>
							})
						}
					</div>
				</div>				
			}

			{ props.chosenSkillData.commonNotApprovedSkills && props.chosenSkillData.commonNotApprovedSkills.length > 0 &&
				<div className='skills-warapper not-accepted-skills'>
					<div className='skills-header-wrapper'>			
						<img src='/assets/images/global-icons/skills-not-accepted.svg' alt='Icon' />
						<h3>Hasn't accepted yet:</h3>				
					</div>
					<div className='skills-tags-wrapper'>
						{
							props.chosenSkillData.commonNotApprovedSkills.map((skill: ProjectSkillData) => {
								return <div key={skill.skill.id} className='skill-tag' data-html={true} data-for='skill-description-2'
								data-tip={`<div style="">		
									<h3 style="font-size: 16px; margin-top: 8px">${capitalizeFirstLetter(skill.synonym ? skill.synonym.name : skill.skill.name)}</h3>					
									<p style="line-height: 140%; font-size: 14px">${skill.synonym ? skill.synonym.description ? skill.synonym.description : skill.skill.description : skill.skill.description}</p>
								</div>`}>
									{capitalizeFirstLetter(skill.synonym ? skill.synonym.name : skill.skill.name)}
								</div>
							})
						}
					</div>
					{
						!props.isReadOnly &&
						<p className='skills-wallet-link'>
						<span>
							<NavLink to='/employee/skills-wallet'>Open skills wallet <i className="fa-solid fa-arrow-right-long"></i></NavLink>
						</span>	
					</p>
					}
				</div>				
			}
			
			<ReactTooltip id="skill-description-2" place="top" type="dark" aria-haspopup='true' html={true} effect="solid" event="click" globalEventOff="click"
					className='tooltip-class' delayShow={200} delayHide={200} isCapture={true} clickable={true}/>  
		</div> 
	)
}

export default LearnAboutSkillsComponent;