import React from 'react';

import styles from './SpinnerButton.module.scss';

type ButtonProps = {
  title: string,
  buttonType?: 'button' | 'login' | 'submit' | 'next' | 'back',
  cssClasses?: string,
  dataLoading: boolean,
  initialDisabled?: boolean,
  onClickCallback?: React.MouseEventHandler<HTMLButtonElement>
}

const SpinnerButton: React.FC<ButtonProps> = (props) => {

  const Button = <>
      <button className={`button ${props.cssClasses}  ${styles['spinner-button']}`} type='button' disabled={props.dataLoading || props.initialDisabled} onClick={props.onClickCallback}>
        {
          props.dataLoading ? <><i className="fa fa-spinner fa-spin"></i> {props.title}</> :  <>{props.title}</>
        }
      </button>
    </>

  const LoginButton = <>
    <button className={`button ${props.cssClasses}  ${styles['spinner-button']}`} disabled={props.dataLoading || props.initialDisabled} onClick={props.onClickCallback}>
      {
        props.dataLoading ? <><i className="fa fa-spinner fa-spin"></i> {props.title}</> : <><i className="fa-solid fa-right-to-bracket"></i> {props.title}</>
      }
    </button>
  </>

  const SubmitButton = <>
    <button className={`button ${props.cssClasses}  ${styles['spinner-button']}`} disabled={props.dataLoading || props.initialDisabled}>
      {
        props.dataLoading ? <><i className="fa fa-spinner fa-spin"></i>{props.title}</> : <>{props.title}</>
      }
    </button>
  </>

  const NextButton = <>
    <button className={`button ${props.cssClasses}  ${styles['spinner-button']}`} disabled={props.dataLoading || props.initialDisabled} onClick={props.onClickCallback}>
      {
        props.dataLoading ? <><i className="fa fa-spinner fa-spin"></i>{props.title} <i className="fa-solid fa-arrow-right-long"></i></> : <>{props.title} <i className="fa-solid fa-arrow-right-long"></i></>
      }
    </button>
  </>

  const BackButton = <>
    <button className={`button ${props.cssClasses}  ${styles['spinner-button']}`} disabled={props.dataLoading || props.initialDisabled} onClick={props.onClickCallback}>
      {
        props.dataLoading ? <><i
           className="fa-solid fa-arrow-left-long"></i> {props.title} <i className="fa fa-spinner fa-spin"></i></> : <>
          <i className="fa-solid fa-arrow-left-long"></i> {props.title}</>
      }
    </button>
  </>

  switch (props.buttonType) {
    case 'login':
      return LoginButton;   
    case 'submit':
      return SubmitButton;
    case 'next':
      return NextButton;
    case 'back':
      return BackButton;
    default:
      return Button;
  }
}

export default SpinnerButton;

