import { useRef } from 'react';
import styles from './SkillListFilterSortComponent.module.scss';
import Tooltip from '../../../../../../../../services/tooltip.service';

type FilterOption = {
  title: string,
  value: string
}

const sortOptions: FilterOption[] = [
  {
    title: "Alphabetical: A to Z",
    value: 'asc'
  },
  {
    title: "Alphabetical: Z to A",
    value: 'desc'
  }
];

const SkillListFilterSortComponent: React.FC<{sortData: Function, filterData: Function, approvedSkillsCounter: number,
  unacceptedSkillsCounter: number, newSkillsCounter: number, learningSkillsCounter: number, filterType: string}> = (props) => {
  
  const sorterInput = useRef<HTMLButtonElement>(null!);
  const buttonId: string = 'needed-skills-sorter';
  
  return (
    <div className={styles['filter-sort-search']}>
      <div className='filter-element'>
        <span className='text-secondary' style={{'marginRight': '8px'}}>Show: </span>

        <Tooltip place="top"                                                            
          tooltipId={'unaccepted-skill-tooltip'}  
          backgroundColor="#101319"
          border={true}
          className='filter-skills-tooltip tooltip-class'
          content={
            <div>
              <p className='tooltip-title ai-extracted'>AI extracted skill</p>
              <p>The skill AI extracted, but the employee has not confirmed yet.</p>
            </div>
          }>
            <div className={`ai-extracted-button ${props.filterType === 'unaccepted' ? 'active' : ''}`} 
              onClick={() => {props.filterType === 'unaccepted' ? props.filterData('all') : props.filterData('unaccepted')} }>
              <span>AI extracted</span> { props.filterType === 'unaccepted' && props.unacceptedSkillsCounter}
            </div>
        </Tooltip>

        <Tooltip place="top"                                                            
          tooltipId={'approved-skill-tooltip'}  
          backgroundColor="#101319"
          border={true}
          className='filter-skills-tooltip tooltip-class'
          content={
            <div>
              <p className='tooltip-title approved'>Approved skill</p>
              <p>Skill that is extracted from AI and validated by the employee.</p>
            </div>
          }>                                
            <div className={`approved-button ${props.filterType === 'approved' ? 'active' : ''}`} 
            onClick={() => {props.filterType === 'approved' ? props.filterData('all') : props.filterData('approved') }}>
              <span>Approved</span> {props.filterType === 'approved' && props.approvedSkillsCounter }
            </div>
        </Tooltip>

        <Tooltip place="top"                                                            
          tooltipId={'learning-skill-tooltip'}
          backgroundColor="#101319"
          border={true}
          className='filter-skills-tooltip tooltip-class'
          content={
            <div>
              <p className='tooltip-title new-skills'>Learning skill</p>
              <p>The skill that currently is being learned by the employee.</p>
            </div>
          }>
          <div className={`learning-button ${props.filterType === 'learning' ? 'active' : ''}`}
            onClick={() => { props.filterType === 'learning' ? props.filterData('all') : props.filterData('learning')} }>
            <span>Learning</span> {props.filterType === 'learning' && props.learningSkillsCounter}
          </div>
        </Tooltip>

        <Tooltip place="top"
                 tooltipId={'new-skill-tooltip'}
                 backgroundColor="#101319"
                 border={true}
                 className='filter-skills-tooltip tooltip-class'
                 content={
                   <div>
                     <p className='tooltip-title new-skills'>New skill</p>
                     <p>The skill was extracted in the last 48 hours.</p>
                   </div>
                 }>
          <div className={`new-button ${props.filterType === 'new' ? 'active' : ''}`}
               onClick={() => { props.filterType === 'new' ? props.filterData('all') : props.filterData('new')} }>
            <span>New</span> { props.filterType === 'new' && props.newSkillsCounter}
          </div>
        </Tooltip>

      </div>

      <div className='sort-element'>
        <div className={'sort-wrapper dropdown'}>
          <button id={buttonId} ref={sorterInput} className="btn btn-outline-secondary dropdown-toggle mt-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          <i className="fa-solid fa-arrow-down-a-z"></i>
          </button>
          <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby={buttonId}>
            {
              sortOptions.map((option) => {
                return <li key={option.value}>
                  <button className="dropdown-item" type="button" onClick={() => { handleSort(option.value, option.title) }}>{option.title}</button>
                </li>
              })
            }
          </ul>
        </div>
      </div>
    </div>
  )

  function handleSort(type: string, title: string) {
    // sorterInput.current.innerText = title; 
    if (type == 'asc') {
      sorterInput.current.innerHTML = `<i class="fa-solid fa-arrow-down-a-z"></i>`;
    }
    else {
      sorterInput.current.innerHTML = `<i class="fa-solid fa-arrow-down-z-a"></i>`;
    }
    props.sortData(type);
  }
}

export default SkillListFilterSortComponent;