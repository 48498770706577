import React, {useState} from 'react';
import CryptoJS from 'crypto-js';
import Pusher from 'pusher-js';

import {UserAuth, UserData} from '../types/AuthData';
import {decryptItem} from "../utils/decrypt-item";
import {MenusEndpointsDto} from "../types/UserMenu";

export const AuthContext = React.createContext({} as UserAuth);

export const AuthContextProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
	const mobileNumber: string = '1a70gfdg@fd235dgj45hgffd4gf!83';
	const [userData, setUserData] = React.useState<UserData>();

	const [channel] = useState(()=> {
		let pusher =  new Pusher('44ba88727279103cb61c', {
      cluster: 'us2' 
    });
		return pusher.subscribe('my-channel');
	});

	const [channelNotifications] = useState(() => {
		let pusher =  new Pusher(process.env.REACT_APP_NOTIFICATION_PUSHER_KEY!, {
			cluster: 'us2'
		});
		return pusher.subscribe('my-channel');
	});
	const [channelRegister] = useState(()=> {
		let pusher =  new Pusher(process.env.REACT_APP_REGISTER_PUSHER_KEY!, {
			cluster: 'us2'
		});
		return pusher.subscribe('my-channel');
	});

	const localStorageAccessToken: string | null = userData?.accessToken ? null : localStorage.getItem('accessToken');
	if (localStorageAccessToken) {
		try {
			let userId: string = decryptItem<string>('userId', mobileNumber);
			let fullName: string = decryptItem<string>('fullName', mobileNumber);
			let displayName: string =  decryptItem<string>('displayName', mobileNumber);
			let email: string = decryptItem<string>('email', mobileNumber);
			let color: string = decryptItem<string>('color', mobileNumber);
			let accessToken = decryptItem<string>('accessToken', mobileNumber);
			let refreshToken: string = decryptItem<string>('refreshToken', mobileNumber);
			let recoveryEmail: string = decryptItem<string>('recoveryEmail', mobileNumber);
			let menu = decryptItem<any>('menu', mobileNumber, true);
			let isManager : boolean = decryptItem<string>('isManager', mobileNumber).toLowerCase() === "true";
			let firstLogin : boolean = decryptItem<string>('firstLogin', mobileNumber).toLowerCase() === "true";
			let sessionId = decryptItem<string>('sessionId', mobileNumber);
			let companyId = decryptItem<number>('companyId', mobileNumber);
			let isOnboarding: boolean = decryptItem<string>('isOnboarding', mobileNumber).toLowerCase() === "true";
			let userRole: string = decryptItem<string>('userRole', mobileNumber);

			// Check if it's the new menu
			if (!menu[0].title || (menu[0].title !== 'Upper Left' && menu[0].title !== 'Admin Panel')) {
				throw new Error();
			}

			setUserData((prevState: any) => {
				return {
					...prevState,
					fullName,
					menu,
					accessToken,
					refreshToken,
					displayName,
					userId,
					color,
					email,
					recoveryEmail,
					isManager,
					notifications: null,
					firstLogin,
					sessionId,
					companyId,
					isOnboarding,
					userRole
				}
			});
		}

		catch(error$: any) {
			logoutHandler();
			window.location.replace('/login');
		}
	}

	const loginHandler = (data: UserData): void => {
		data.updatedProfilePicture = true;

		if (!data.displayName || (data.displayName && data.displayName.trim() === '')) {
			data.displayName = data.fullName;
		}
		if (data.recoveryEmail === null) {
			data.recoveryEmail = '';
		}
		if (data.isOnboarding === null) {
			data.isOnboarding = false;
		}
		// Check if the user is manager	
		let manager = false;
		let upperMenu: any = data.menu.find(item => item.title === 'Upper Left');
		if (upperMenu) {
			let managerMenu: any = upperMenu.menus.find((menu: any) => menu.title.includes('Manager'));
			if (managerMenu) {
				manager = !!managerMenu.menus.find((menu: any) => menu.title === 'Projects');
			}
		}

		setUserData({...data, isManager: manager});
		
		let fullName = CryptoJS.AES.encrypt(data.fullName, mobileNumber).toString();
		
		let displayName = fullName;
		if (data.displayName && data.displayName.trim() !== '') {
			displayName = CryptoJS.AES.encrypt(data.displayName, mobileNumber).toString();
		}

		let color = CryptoJS.AES.encrypt(data.color, mobileNumber).toString();
		let email =  CryptoJS.AES.encrypt(data.email, mobileNumber).toString();
		let recoveryEmail = CryptoJS.AES.encrypt(data.recoveryEmail!, mobileNumber).toString();
		let accessToken = CryptoJS.AES.encrypt(data.accessToken, mobileNumber).toString();
		let refreshToken = CryptoJS.AES.encrypt(data.refreshToken, mobileNumber).toString();
		let menu = CryptoJS.AES.encrypt(JSON.stringify(data.menu), mobileNumber).toString();
		let userId = CryptoJS.AES.encrypt(data.userId, mobileNumber).toString();
		let isManager = CryptoJS.AES.encrypt(manager.toString(), mobileNumber).toString();
		let firstLogin = CryptoJS.AES.encrypt(data.firstLogin.toString(), mobileNumber).toString();
		let sessionId = CryptoJS.AES.encrypt(data.sessionId, mobileNumber).toString();
		let companyId = CryptoJS.AES.encrypt(data.companyId ? data.companyId.toString() : '-1', mobileNumber).toString();
		const isOnboarding = CryptoJS.AES.encrypt(data.isOnboarding.toString(), mobileNumber).toString();
		const userRole = CryptoJS.AES.encrypt(data.userRole.toString(), mobileNumber).toString();

		localStorage.setItem('refreshToken', refreshToken);
		localStorage.setItem('accessToken', accessToken);
		localStorage.setItem('menu', menu);
		localStorage.setItem('fullName', fullName);
		localStorage.setItem('color', color);
		localStorage.setItem('displayName', displayName);
		localStorage.setItem('userId', userId);
		localStorage.setItem('email', email);
		localStorage.setItem('recoveryEmail', recoveryEmail);
		localStorage.setItem('isManager', isManager);
		localStorage.setItem('firstLogin', firstLogin);
		localStorage.setItem('sessionId', sessionId);
		localStorage.setItem('companyId', companyId);
		localStorage.setItem('isOnboarding', isOnboarding);
		localStorage.setItem('userRole', userRole);
	};

	function logoutHandler() {
		if (userData?.userId) {
			channel.unbind('personal_' + userData.userId);
			channel.unbind('company_' + userData.userId);
			channelNotifications.unbind(`notification_${userData.userId}`);
			channelRegister.unbind(`augmentation_${userData.userId}`);
		}
		localStorage.removeItem('accessToken');
		localStorage.removeItem('menu');
		localStorage.removeItem('profilePicture');
		localStorage.removeItem('fullName');
		localStorage.removeItem('displayName');
		localStorage.removeItem('refreshToken');
		localStorage.removeItem('userId');
		localStorage.removeItem('color');
		localStorage.removeItem('email');
		localStorage.removeItem('recoveryEmail');
		localStorage.removeItem('isManager');
		localStorage.removeItem('firstLogin');
		localStorage.removeItem('sessionId');
		localStorage.removeItem('companyId');
		localStorage.removeItem('isOnboarding');
		localStorage.removeItem('userRole');
		setUserData(undefined);
	}

	const changeProfileImage = (url: string) => {
		setUserData((prevState: any) => {
			return {...prevState, profilePicture: url, updatedProfilePicture: true}
		});
	}

	const changeDisplayName = (name: string) => {
		let displayName = CryptoJS.AES.encrypt(name, mobileNumber).toString();
		localStorage.setItem('displayName', displayName);
		localStorage.setItem('fullName', displayName);
		setUserData((prevState: any) => {
			return {...prevState, fullName: name, displayName: name}
		});
	}

	const changeRecoveryEmail = (recoveryEmail : string) => {
		let recoveryEmailEncrypt =  CryptoJS.AES.encrypt(recoveryEmail, mobileNumber).toString();
		localStorage.setItem('recoveryEmail', recoveryEmailEncrypt);
		setUserData((prevState: any) => {
			return {...prevState, recoveryEmail: recoveryEmail};
		});
	}

	const deleteRecoveryEmail = () => {
		let recoveryEmailEncrypt =  CryptoJS.AES.encrypt("", mobileNumber).toString();
		localStorage.setItem('recoveryEmail', recoveryEmailEncrypt);
		setUserData((prevState: any) => {
			return {...prevState, recoveryEmail: ""};
		});
	}

	const changePageTitle = (pageTitle: string) => {
		setUserData((prevState: any) => {
			if (prevState!.pageTitle === pageTitle) {
				// If the title is the same, return the previous state to avoid unnecessary re-render
				return prevState;
			}
			return {...prevState, pageTitle: pageTitle};
		});
	}

	function changeFirstLogin(flag: boolean) {
		let firstLoginEncrypt = CryptoJS.AES.encrypt(flag.toString(), mobileNumber).toString();
		localStorage.setItem('firstLogin', firstLoginEncrypt);
		setUserData((prevState: any) => {
			return {
				...prevState,
				firstLogin: flag
			}
		});
	}

	function changeOnboarding() {
		const isOnboarding = CryptoJS.AES.encrypt(false.toString(), mobileNumber).toString();
		localStorage.setItem('isOnboarding', isOnboarding);
		setUserData((prevState: any) => {
			return {
				...prevState,
				isOnboarding: false
			}
		});
	}

	function storeTokens (accessToken: string, refreshToken: string, sessionId: string) {
		let accessTokenEncrypt = CryptoJS.AES.encrypt(accessToken, mobileNumber).toString();
		let refreshTokenEncrypt = CryptoJS.AES.encrypt(refreshToken, mobileNumber).toString();
		localStorage.setItem('refreshToken', refreshTokenEncrypt);
		localStorage.setItem('accessToken', accessTokenEncrypt);
		localStorage.setItem('sessionId', sessionId);
		
		setUserData((prevState: any) => {
			return  {...prevState, accessToken: accessToken, refreshToken: refreshToken, sessionId: sessionId}
		});
	}

	function updateMenu(menu: MenusEndpointsDto[]) {
		// Check if the user is manager
		let manager = false;
		let upperMenu: any = menu.find(item => item.title === 'Upper Left');
		if (upperMenu) {
			let managerMenu: any = upperMenu.menus.find((menu: any) => menu.title.includes('Manager'));
			if (managerMenu) {
				manager = !!managerMenu.menus.find((menu: any) => menu.title === 'Projects');
			}
		}

		let isManager = CryptoJS.AES.encrypt(manager.toString(), mobileNumber).toString();
		let menuEncrypted = CryptoJS.AES.encrypt(JSON.stringify(menu), mobileNumber).toString();

		localStorage.removeItem('isManager');
		localStorage.removeItem('menu');

		localStorage.setItem('isManager', isManager);
		localStorage.setItem('menu', menuEncrypted);

		window.location.reload();
	}

	const context = {
		userData: userData as UserData,
		channel: channel,
		channelNotifications: channelNotifications,
		channelRegister: channelRegister,
		login: loginHandler,
		logout: logoutHandler,
		storeTokens: storeTokens,
		changeProfileImage: changeProfileImage,
		changeDisplayName: changeDisplayName,
		changePageTitle: changePageTitle,
		changeRecoveryEmail: changeRecoveryEmail,
		deleteRecoveryEmail: deleteRecoveryEmail,
		changeFirstLogin: changeFirstLogin,
		changeOnboarding: changeOnboarding,
		updateMenu: updateMenu,
	}

	return (
		<AuthContext.Provider value={context}>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthContext;