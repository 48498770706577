import ReactDOM from 'react-dom';

import styles from './NotificationModal.module.scss';

const NotificationModal: React.FC<{data: {jobPosition: string}, removeModal: React.MouseEventHandler<HTMLButtonElement>}> = (props) => {  
  let modal = (
    <div className={styles['personal-info-modal']}>
      <div className='card card-modal'>
        <div className='card-header'>
          <div className='close-modal-button'>
            <i className="fa-solid fa-xmark" onClick={props.removeModal}></i>
          </div>  
        </div>
        <div className="card-body">
          <p><strong>{props.data.jobPosition}</strong> job position is already added.</p>      
        </div>
        <div className="card-footer">
          <div className='buttons-wrapper'>   
            <button className='btn btn-primary' onClick={props.removeModal}>Ok</button>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    ReactDOM.createPortal(modal, document.getElementById('modal-root') as HTMLDivElement)
  )
}

export default NotificationModal;