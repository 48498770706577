import React, {useState, useContext, useEffect} from "react";
import styles from "./SearchComponent.module.scss";
import AuthContext from "../../../../../../../store/auth-context";
import {capitalizeFirstLetter} from "../../../../../../../utils/capitalize-first-letter";
import ProjectsContext from "../../../../../../../store/projects-context";

import Select from "react-select";
import { withAsyncPaginate } from "react-select-async-paginate";
import Tooltip from "../../../../../../../services/tooltip.service";
import useApiService from "../../../../../../../services/api.service";
import {showNotification} from "../../../../../../../ui/Toast/ToastNotification";
import {ErrorResponseDto} from "../../../../../../../types/ErrorData";

const CreatableAsyncPaginate = withAsyncPaginate(
  Select
);

const selectStyles = {
  container: (provided: {}) => ({
    ...provided,
    flex: "1",
  }),
  control: (provided: {}) => ({
    ...provided,
    backgroundColor: "#1C1B28",
    border: "none",
    paddingTop: "8px",
    paddingBottom: "8px",
    paddingLeft: "52px",
    paddingRight: "8px",
    marginRight: '8px',
    borderRadius: "0px",
    fontSize: '18px'
  }),
  indicatorSeparator: (provided: {}) => ({
    ...provided,
    border: "1px solid #757575",
    display: "none",
  }),
  dropdownIndicator: (provided: {}) => ({
    ...provided,
    color: "#757575",
    display: "none",
  }),
  clearIndicator: (provided: {}) => ({
    ...provided,
    color: "#757575",
    with: "50px"
  }),
  placeholder: (provided: {}) => ({
    ...provided,
    color: "#828892",
    fontWeight: "400",
    fontSize: "18px",
  }),
  loadingMessage: (provided: {}) => ({
    ...provided,
    color: "#757575",
  }),
  loadingIndicator: (provided: {}) => ({
    ...provided,
    color: "#757575",
  }),
  input: (provided: {}) => ({
    ...provided,
    color: "#F1F6FB",    
    fonSize: '24px'
  }),
  noOptionsMessage: (provided: {}) => ({
    ...provided,
    color: "#757575",
  }),
  menu: (provided: {}) => ({
    ...provided,
    backgroundColor: "#1C1B28"
  }),
  singleValue: (provided: {}) => ({
    ...provided,
    color: "#F7F7F8",
    '.fa-regular': {
      display: 'none'
    },
    '.select-skill-description-symbol': {
      display: 'none'
    }
  }),
  option: (provided: any, data: any) => ({
    ...provided,
    color: "#B3BAC3",
    backgroundColor: "#2F303C",
    border: "1px solid #383A46",
    borderBottom: 'none',
    width: '95%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '2px',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    transition: '0.5s background-color, 0.5s border, 0.5s color',
    ':hover' : {
      backgroundColor: '#383A46',
      border: '1px solid #50545F',
      borderBottom: 'none',
      color: '#F1F6FB'
    },
    ':last-child' : {
      borderBottom: "1px solid #383A46",
    }
  }),  
  multiValue:  (provided: {}) => ({
    ...provided,
    backgroundColor: "#626872",
    border: "1px solid #B3BAC3",
    color: "#F1F6FB", 
    marginRight: '8px',
    '.fa-regular': {
      display: 'none'
    },
    '.select-skill-description-symbol': {
      display: 'none'
    }
  }),
  multiValueLabel: (provided: {}) => ({
    ...provided,
    color: '#F1F6FB'
  }),
  multiValueRemove: (provided: {}, state: {}) => ({
    ...provided,
    color: 'white',
    transition: '0.5s color',
    backgroundColor: 'transparent !important',
    ':hover': {
      color: '#CAD1D8'
    }
  }),
};

enum SEARCH_TYPES {
  EMPLOYEES = "Employees",
  SKILLS = "Skills"
}

type SearchedSkill = {
  projectSkillId: number,
  name: string,
  description: string
}

type SearchedTaxonomySkill = {
  id: number;
  name: string;
  description: string;
  icon: string;
  category: string;
  aiId: string;
  isTool: boolean;
  isAITool: boolean;
  level: any;
  synonym?: any;
}

type SearchedEmployee = {
  id: number,
  name: string,
  surname: string
}

type SearchResult = {
  page: number,
  pageSize: number,
  searchedSkills?: SearchedSkill[] | SearchedTaxonomySkill[],
  searchedEmployees?: SearchedEmployee[],
  totalCount: number,
  totalPages: number
}

const SearchComponent: React.FC<{searchSkills: Function, searchEmployee: Function, cancelSearch: React.MouseEventHandler, projectId?: number, companySkillsFlag?: boolean}> = (props) => {
  const authStore = useContext(AuthContext);
  const projectStore = useContext(ProjectsContext);
  const [searchType, setSearchType] = useState<SEARCH_TYPES>(SEARCH_TYPES.EMPLOYEES);
  const [selectValue, setSelectValue] = useState<any>(null);
  const [inputValue, setInputValue] = useState<string>(''); 

  const searchSkillsURL = process.env.REACT_APP_PUBLIC_URL + (props.companySkillsFlag ? "/company/search-taxonomy-skills" : "/manager/search-project-skills");
  const searchEmployeesURL = process.env.REACT_APP_PUBLIC_URL + "/manager/search-employees";

  const apiService = useApiService();

  // useEffect(()=> {
  //   const dropdown = document.getElementById('search-type-dropdown') as HTMLDivElement;
  //   const dropdownArrow = document.getElementById('dropdown-arrow') as HTMLElement;
  //
  //   dropdown.addEventListener('shown.bs.dropdown', event => {
  //     dropdownArrow.classList.add('fa-angle-up');
  //     dropdownArrow.classList.remove('fa-angle-down');
  //   })
  //   dropdown.addEventListener('hidden.bs.dropdown', event => {
  //     dropdownArrow.classList.add('fa-angle-down');
  //     dropdownArrow.classList.remove('fa-angle-up');
  //   })
  //
  //   return () => {
  //     dropdown.removeEventListener('shown.bs.dropdown', event => {
  //       dropdownArrow.classList.add('fa-angle-up');
  //       dropdownArrow.classList.remove('fa-angle-down');
  //     })
  //     dropdown.removeEventListener('hidden.bs.dropdown', event => {
  //       dropdownArrow.classList.add('fa-angle-down');
  //       dropdownArrow.classList.remove('fa-angle-up');
  //     })
  //   }
  // }, [])
 
  return (
    <div className={styles["search-component"]}>
      <div className="search-elements-wrapper">
        <h2 className="main-title">Bring the best out of your employees</h2>

        <div className="search-input-container">
          <CreatableAsyncPaginate
            key={searchType}               
            menuPlacement="bottom"         
            className="search-input"
            classNamePrefix="select"
            isClearable={true}
            isSearchable={true}
            value={selectValue}
            inputValue={searchType === SEARCH_TYPES.SKILLS ? inputValue : undefined}
            name="skill"
            styles={selectStyles}
            backspaceRemovesValue={false}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: '#2D2F38',
                primary: '#757575',
              },
            })}
            formatOptionLabel={formatOptionLabel} 
            placeholder={searchType === SEARCH_TYPES.SKILLS ? "Front end, Data science, Agile methodology, etc." : 'Eileen Kunde, John Doe, etc.'}
            loadingMessage={() => 'Loading...'}              
            noOptionsMessage={
              (value) => value.inputValue === "" ? "Start typing" : value.inputValue.length === 1 ? 'Please enter a minimum of two characters to view the searched skills' : "No options"
            }               
            debounceTimeout={1200}          
            loadOptionsOnMenuOpen={false}
            closeMenuOnSelect={searchType === SEARCH_TYPES.SKILLS ? false : true}
            isMulti={searchType === SEARCH_TYPES.SKILLS ? true : false}
            additional={{page: 1}}          
            loadOptions={(search: string, options: any, aditional: any) => loadOptionsHandler(search, options, aditional)}  
            onChange={(value: any) => {
              setSelectValue(value)      
            }}
            onInputChange={(value, extra: any)=> {
              if (extra.action === 'input-change') {
                setInputValue(value);
                return value;
              }
              else if (extra.action === 'set-value') {
                setInputValue('');
                return '';
              }
              return inputValue;           
            }}
            onMenuClose={()=> {         
              setInputValue("");
            }}
          />
          {/*<div className="search-type-dropdown">*/}
          {/*  <div id="search-type-dropdown" className={'sort-wrapper dropdown'}>*/}
          {/*    <button className="dropdown-button" type="button" data-bs-toggle="dropdown" aria-expanded="false">*/}
          {/*      {capitalizeFirstLetter(searchType)} <i id="dropdown-arrow" className="fa-solid fa-angle-down"></i>*/}
          {/*    </button>*/}
          {/*    <ul className="dropdown-menu dropdown-menu-dark">*/}
          {/*      /!*<li><button className="dropdown-item" type="button" onClick={()=> applySearchType(SEARCH_TYPES.SKILLS)}>{SEARCH_TYPES.SKILLS}</button></li>*!/*/}
          {/*      <li><button className="dropdown-item" type="button" onClick={()=> applySearchType(SEARCH_TYPES.EMPLOYEES)}>{SEARCH_TYPES.EMPLOYEES}</button></li>*/}
          {/*    </ul>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
        <div className='buttons-wrapper'>
          <button className='button button-secondary' onClick={props.cancelSearch}>Cancel</button>
          <button className='button button-primary u-margin-left-s' onClick={applySearch} disabled={selectValue === null || selectValue.length === 0}>Search</button>
        </div> 
      </div>
    </div>
  );

  async function loadOptionsHandler(search: string, loadedOptions: unknown[], additional: any) {
    let options: any = [];
    let additionalObject: any = {};
    let returningObject: any = {
      options: options
    }    

    if (search.trim().length > 1) {
      let result: SearchResult | any = await loadOptions(search.trim(), additional.page,  authStore.userData.accessToken);
      if (searchType === SEARCH_TYPES.SKILLS) {
        if (result.searchedSkills.length > 0) {
          for (let item of result.searchedSkills) {
            if (props.companySkillsFlag) { // SEARCHING SKILLS IN WHOLE COMPANY
              options.push({ value: item.id, label: capitalizeFirstLetter(item.name), description: item.description});
            }
            else { // SEARCHING SKILLS IN ALL PROJECTS
              options.push({ value: item.projectSkillId, label: capitalizeFirstLetter(item.name), description: item.description })
            }
          }
        }  
      }
      else {
        if (result.searchedEmployees.length > 0) {
          for (let item of result.searchedEmployees) {
            options.push({ value: item.id, label: item.name + ' ' + item.surname })
          }
        }
      }

      returningObject.options = options;
      returningObject.hasMore = result.totalCount > result.page * result.pageSize ? true : false;
      additionalObject.page = additional.page + 1;
      returningObject.additional = additionalObject;
    }
    return returningObject;   
  }

  async function loadOptions(term: string, page: number, accessToken: string) {
    const apiUrl: string = searchType === SEARCH_TYPES.SKILLS ? searchSkillsURL : searchEmployeesURL;

    let promise = new Promise((resolve: Function, reject: Function) => {
      projectStore.loadSkillAndEmployeesOptions(term, page, accessToken, apiUrl, props.projectId)
        .then((data: any)=> {        
          resolve(data);
        })
        .catch((error$: ErrorResponseDto)=> {
          if (error$.response.data.message === 'Unauthorized') {
            // Get new Access Token
            apiService.refreshToken(authStore.userData.refreshToken)
              .then((response$: any) => {
                authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                resolve(loadOptions(term, page, response$.data.accessToken));
              })
          }
          else {
            showNotification('warning', error$.response.data.message);
          }
        })  
    })
    return await promise;
  }

  function applySearchType(type: SEARCH_TYPES): void {    
    setSelectValue(null);
    setInputValue('');
    setSearchType(type);
  }

  function applySearch() {
    if (searchType === SEARCH_TYPES.SKILLS) {
      props.searchSkills(selectValue, authStore.userData.accessToken);
    }
    else {
      props.searchEmployee(selectValue.value, authStore.userData.accessToken);
    }
  }

  function formatOptionLabel (data: any) {
    return <div style={{ display: "block" }}>
      <span>
        { searchType === SEARCH_TYPES.SKILLS ? <i className="fa-regular fa-gem" style={{'marginTop': '4px', 'marginRight': '8px'}}></i> : 
          <i className="fa-regular fa-user" style={{'marginRight': '16px'}}></i>}
      </span>
      <span>{data.label}</span>
      {
        data.description &&
        <span>
          <Tooltip
            place="right"                                         
            tooltipId={data.value.toString()}  
            backgroundColor="#101319"
            borderColor='#5C5F6A !important'
            border={true}            
            content={
              <span>{data.description}</span>                                 
            }
            className='select-skill-description'
          >
            <span className='select-skill-description-symbol'><i className="fa-regular fa-circle-question"></i></span>
          </Tooltip>  
        </span>
      }
    </div>
  }
};

export default SearchComponent;