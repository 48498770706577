import React from "react";
import {Route, Routes, Navigate} from "react-router-dom";
import {CreateProjectContextProvider} from "../../../../store/create-project-context";
import CreateProjectComponent from "../ProjectsPage/CreateProjectComponent/CreateProjectComponent";
import {ProjectsContextProvider} from "../../../../store/projects-context";
import ManagerDashboardPage from "../ProjectsPage/ManagerDashboardPage/ManagerDashboardPage";
import AllProjectsPage from "../ProjectsPage/AllProjectsPage/AllProjectsPage";

const JobPostingsPage: React.FC<{}> = props => {

   return (
      <>
         <Routes>
            <Route path="/" element={<Navigate replace to='/manager/tasks/all-tasks'></Navigate>}/>

            <Route path="all-tasks" element={
               <CreateProjectContextProvider>
                  <AllProjectsPage jobPosting={true} />
               </CreateProjectContextProvider>
            } />

            <Route path="create/:id?/:component?" element={
               <CreateProjectContextProvider>
                  <CreateProjectComponent jobPosting={true} />
               </CreateProjectContextProvider>
            } />

            <Route path=":id/*" element={
               <ProjectsContextProvider>
                  <ManagerDashboardPage jobPosting={true} />
               </ProjectsContextProvider>
            } />
         </Routes>
      </>
   )
}

export default JobPostingsPage;