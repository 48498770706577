import styles from './WelcomeComponent.module.scss';
import React from "react";

const WelcomeComponent: React.FC<{user: string, changeSection: Function}> = (props) => {

  return (
    <div className={styles['welcome-admin-component']}>
      <div className='title-wrapper'>
        <div className='logo-container'>
          <img src='/assets/images/global-icons/teamlift-icon-white.svg' alt="TeamLift logo" />
        </div>
        <h1 className={'header-1'}>Welcome to TeamLift</h1>
      </div>
      <div className='info-container'>
        <p>Hi, {props.user}<br /> We are so excited you're here!</p>
      </div>
      <div className='button-container'>
        <button className='btn btn-blue-custom u-margin-top-s' onClick={() => props.changeSection('add-categories')}>Get started</button>
      </div>
    </div>
  )
}

export default WelcomeComponent;