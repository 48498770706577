import {useContext} from 'react';
import { SkillCardData } from '../../../../../types/SkillsData';
import { SKILL_STATUS } from '../../../../../enums/Skills.enum';
import styles from './SkillCard.module.scss';
import {CircularProgressbar, buildStyles, CircularProgressbarWithChildren} from 'react-circular-progressbar';
import Tooltip from "../../../../../services/tooltip.service";
import AuthContext from '../../../../../store/auth-context';
import {Checkbox} from "@mui/material";
import RadialSeparators from "../../../../../utils/RadialSeparators";
import {capitalizeFirstLetter} from "../../../../../utils/capitalize-first-letter";

const SkillCard = (props: SkillCardData) => {
  let addDenySkill = props.addDenySkill as Function;
  let check = props.checkSkill as Function;

  let validationTotalProgress: number = props.totalValidationScore;
  const validationTotalLevel: string = props.totalValidationLevel;

  let tooltipValidationTitle: string = '';
  let chartColor: string = '';
  const authStore = useContext(AuthContext);


  switch(validationTotalLevel) {
    case 'LOW':
      tooltipValidationTitle = "Low";
      chartColor = '#A0A8E5';
      break;
    case 'MEDIUM':
      tooltipValidationTitle = `Medium`;
      chartColor = '#5969D4';
      break;
    case 'HIGH':
      tooltipValidationTitle = `High`;
      chartColor = '#3146C1';
      break;
  }
  const sources : any[] = props.descriptors.map(item => item.source);
  const uniqueSources = Array.from(new Set(sources));

  return (
    <>
      <div className={`${styles[`skill-card`]}`} style={{borderColor: `${props.checked ? '#A0A8E5FF': ''}` , backgroundColor: `${props.checked ? '#383A46' : ''}`}}>
        <div className={`card-body`}>
          <div className='title-wrapper'>
             <div className="checkbox-wrapper">
                <Checkbox
                   disableRipple={true}
                   sx={{
                      margin: '0 0 0 0',
                      padding: 0,
                      transition: 'all 0.5s',
                      backgroundColor: 'transparent',
                      color: '#50545FFF',
                      '&:hover, &:active, &:active:hover, &:hover:active': {
                         backgroundColor: 'transparent',
                         color: '#626872FF',
                      },
                      '&.Mui-checked': {
                         color: '#A0A8E5FF',
                         backgroundColor: 'transparent',
                         '&:hover, &:active, &:active:hover, &:hover:active': {
                            backgroundColor: 'transparent',
                            color: '#C6CAEFFF',
                         },
                      },
                      '&.Mui-disabled' : {
                         color: '#383A46FF',
                         backgroundColor: 'transparent',
                      },
                      '&.MuiCheckbox-indeterminate': {
                         color: '#A0A8E5FF',
                         backgroundColor: 'transparent',
                         '&:hover, &:active, &:active:hover, &:hover:active': {
                            backgroundColor: 'transparent',
                            color: '#C6CAEFFF',
                         },
                      },
                   }}
                   onChange={toggleSkill}
                   checked={props.checked}
                   disabled={props.type === SKILL_STATUS.LEARNING}
                />
             </div>
             {
                props.type !== SKILL_STATUS.LEARNING.valueOf() ?
                   <Tooltip tooltipId={`social_validation_${props.id}`}
                      place="top"
                      backgroundColor="#1C1B28"
                      borderColor="#50545F !important"
                      border={true}
                      className={'tooltip-card'}
                      content={<>
                        <div className={'tooltip-main-content'}>
                          <div><strong>{tooltipValidationTitle}</strong> level of confidence</div>
                          <div>{props.validationMessage}</div>
                        </div>
                        {
                           uniqueSources.length > 0 &&
                           <div className={'sources'}>
                              <div className={'sources-title'}>Source:</div>
                              <div className={'sources-body'}>
                                 {
                                    uniqueSources.map(item => {
                                       return <span key={item} className={'sources-item'}>{item.replace("_", " ")}</span>;
                                    })
                                 }
                              </div>
                           </div>
                        }
                      </>}>
                      <div className={'social-validation-chart-container'}>
                         <CircularProgressbar
                            value={validationTotalProgress} strokeWidth={28}
                            styles={buildStyles({pathColor: chartColor, trailColor: '#50545F', strokeLinecap: "butt"})}/>
                      </div>
                   </Tooltip>
                   :
                   <div className={'social-validation-chart-container'}>
                      <CircularProgressbarWithChildren value={100} strokeWidth={28}
                                                       styles={buildStyles({pathColor: '#0E0C19FF', trailColor: '#383A46', strokeLinecap: "butt"})}>
                         <RadialSeparators count={7}
                                           style={{
                                              background: "#fff",
                                              width: "2px",
                                              // This needs to be equal to props.strokeWidth
                                              height: `${25}%`
                                           }}/>
                      </CircularProgressbarWithChildren>
                   </div>
             }

            <h3 className='skill-title'><span className={`title-text ${props.checked ? 'checked-title-text' : ''}`}>{capitalizeFirstLetter(props.title)}</span>
              <Tooltip tooltipId={`skill_description_${props.id}`}
                        place="top"
                        backgroundColor="#1C1B28"
                        borderColor="#50545F !important"
                        border={true}
                        className={'tooltip-description'}
                        content={<>
                          <div>{capitalizeFirstLetter(props.description!)}</div>
                        </>}>
                <span className={'skill-description'}><i className="fa-solid fa-circle-question"></i></span>
              </Tooltip>
              {
                props.isNewSkill && <span className='self-reported-tag bg-text-primary text-surface-90' style={{'marginRight': '8px'}}>new</span>
              }
              {
                props.selfReported && <span className='self-reported-tag'>self-reported</span>
              }
            </h3>

          </div>
          <div className='buttons-wrapper'>
            {
              props.type === SKILL_STATUS.NEW &&
                <>
                  <span className={'accept-deny-wrapper'}>
                    <button className='button button-tertiary skill-button decline-button u-margin-right-s' onClick={() => { addDenySkill(authStore.userData.accessToken, false, props.id, false) }}>Deny</button>
                    <button className='button button-tertiary skill-button' onClick={() => { addDenySkill(authStore.userData.accessToken, false, props.id, true) }}>Accept</button>
                  </span>
                </>
            }
            <span className={'accept-deny-wrapper'}>
              {
                props.type === SKILL_STATUS.APPROVED &&
                  <button className='button button-tertiary skill-button decline-button' onClick={() => { addDenySkill(authStore.userData.accessToken, false, props.id, true, true) }}>Remove</button>
              }
              { props.type === SKILL_STATUS.REJECTED &&
                  <button className='button button-tertiary skill-button decline-button' onClick={() => { addDenySkill(authStore.userData.accessToken, false, props.id, true, true) }}>Undo</button>
              }
            </span>
          </div>
        </div>
      </div>
    </>
  )

  function toggleSkill() {
    check(props.id);
  }
}

export default SkillCard;