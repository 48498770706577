import styles from './DeleteEmployeeModal.module.scss';

const DeleteEmployeeModal:React.FC<{data: any, okCallback: any, cancelCallback: React.MouseEventHandler<HTMLElement>}> = (props)=> {

  return (
    <div className={styles['new-edit-employee-modal']}>
      <div className='card card-modal'>
        <div className='card-header'>
          <h2>Delete {props.data.employeeData.firstName} {props.data.employeeData.lastName} from project {props.data.projectData.name}</h2>
        </div>
        <div className="card-body">
          <div className={'mb-4 body-content'}>
            <div className={'mb-1'}>This action will revoke {props.data.employeeData.firstName} {props.data.employeeData.lastName}'s access to the project using their work email. However, their skills wallet will still be visible.</div>
            <div>Are you sure you want to proceed?</div>
          </div>
          <div className='buttons-wrapper'>
            <button className='btn button-cancel' onClick={props.cancelCallback}>Cancel</button>
            <button className='btn button-delete' onClick={submitForm}>Delete</button>
          </div>         
        </div> 
      </div>
    </div>
  )

  function submitForm() {
    let payload = props.data.employeeData.id;
    props.okCallback(payload);
  }
}

export default DeleteEmployeeModal;