import React, {useState, useEffect, useContext, useRef, MouseEventHandler} from 'react';

import AuthContext from '../../../../../store/auth-context';

import ImageUploadComponent from './ImageUploadComponent/ImageUploadComponent';

import { UserData } from '../../../../../types/AuthData';

import PersonalInfoModal from './Modals/PersonalInfoModal/PersonalInfoModal';
import ChangePasswordModal from './Modals/ChangePasswordModal/ChangePasswordModal';
import useModal from '../../../../../services/modal.service';

import axios, {AxiosRequestConfig} from 'axios';

import styles from './PersonalInfoPage.module.scss';

import useApiService from '../../../../../services/api.service';

import { showNotification }   from '../../../../../ui/Toast/ToastNotification';

import useLoadingSpinner from '../../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner';
import ChangeProfilePictureModal from "./Modals/ChangeProfilePictureModal/ChangeProfilePictureModal";
import SpinnerButton from "../../../../../ui/SpinnerButton/SpinnerButton";
import {capitalizeFirstLetter} from "../../../../../utils/capitalize-first-letter";
import {ErrorResponseDto} from "../../../../../types/ErrorData";

type PersonalData = {
  displayName: string
  email: string,
  fullName: string,
  jobPositionName: string | null
}
enum ValidationType {
  NO_ERROR,
  NO_FURTHER_STEPS,
  ALREADY_EXISTING,
  VALIDATION_NUMBER_SENT
}

const PersonalInfoPage: React.FC<{checkTerms: MouseEventHandler<HTMLButtonElement>}> = props => {
  const initializedComponent = useRef(false);
  const { refreshToken } = useApiService();
  
  const authStore = useContext(AuthContext);
  const userData: UserData = authStore.userData;

  const upperMenu = userData.menu.find(item => item.title === 'Lower Left');
  const componentData = upperMenu?.menus?.find(item => item.title === 'Settings');

  const personalInfoLoadUrl = process.env.REACT_APP_PUBLIC_URL + componentData!.url!;
  
  const [personalData, setPersonalData] = useState<PersonalData>({} as PersonalData);

  const apiUrls = componentData!.commands;
  const editProfileUrl = process.env.REACT_APP_PUBLIC_URL + apiUrls![0].url;
  const editPasswordUrl = process.env.REACT_APP_PUBLIC_URL + apiUrls![1].url;
  const saveProfilePictureURL = process.env.REACT_APP_PUBLIC_URL + apiUrls![2].url;
  const deleteProfilePictureURL = process.env.REACT_APP_PUBLIC_URL + '/file/delete-profile-picture';
  const sendRecoveryEmailURL = process.env.REACT_APP_PUBLIC_URL + '/auth/recovery-email';
  const sendRecoveryEmailToPersonalAccURL = process.env.REACT_APP_PUBLIC_URL + '/auth/personal-account';
  const sendValidationNumberURL = process.env.REACT_APP_PUBLIC_URL + '/auth/validate-email';
  const deleteRecoveryEmailURL = process.env.REACT_APP_PUBLIC_URL + '/auth/delete-personal-account';

  const modalServiceDisplyName = useModal();
  const modalServicePassword = useModal();
  const modalServiceProfilePicture = useModal();

  const [file, setFile] = useState(null)

  // states for recovery email
  const [dataIsLoading, setDataIsLoading] = useState(false); // Save button for recovery email
  const [dataIsLoadingNumber, setDataIsLoadingNumber] = useState(false); // Confirm button for validation number
  const [dataIsLoadingLink, setDataIsLoadingLink] = useState(false); // Save button when asked if that's your email
  const [showRecoveryEmailInput, setShowRecoveryEmailInput] = useState(false)
  const [clickedSaveRE, setClickedSaveRE] = useState(false)
  const [ontoValidationNumber , setOntoValidationNumber] = useState(false)
  const [errState, setErrState] = useState(ValidationType.NO_ERROR)
  const [errMsg, setErrMsg] = useState('')
  const [recoveryEmail, setRecoveryEmail] = useState('')
  const [validationNumber, setValidationNumber] = useState('')
  const [errorEmail, setErrorEmail] = useState('')
  const [wrongCode, setWrongCode] = useState(false)
    const [confirmDelete, setConfirmDelete] = useState(false);
  const [regexState, setRegexState] = useState(false);

  const spinnerService = useLoadingSpinner();

  const fileChangeHandler = (file : any) => {
    prepareDisplayChangeProfilePictureModal(URL.createObjectURL(file))
  }
  const croppedImageHandler = (file : any) => {
    setFile(file)
    modalServiceProfilePicture.removeModal()
  }

  useEffect(() => {
    initializeComponent(userData.accessToken);
  }, []);

  useEffect(() => {
      const delayInputTimeoutId = setTimeout(() => {
          setRegexState(
              (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(recoveryEmail)));
      }, 500);
      return () => clearTimeout(delayInputTimeoutId);
  }, [recoveryEmail]);

  return (
      <>
        <div className={styles['personal-info-page']}>
            {initializedComponent.current &&
                <>
                  <div className='row mt-4'>
                    <div className='col-12 col-md-8 col-lg-6 col-xxl-4'>
                      <div className='row'>
                        <div className='col-12'>
                          <div className='image-name-section'>
                            <ImageUploadComponent
                                onUploadImage={fileChangeHandler}
                                file={file}
                                saveProfilePictureURL={saveProfilePictureURL}
                                deleteProfilePictureURL={deleteProfilePictureURL}/>
                            <div className='name-wrapper'>
                              <h1 className={'header-1 u-margin-bottom-xxs'}>{personalData.fullName}</h1>
                              <p>@{personalData.displayName}</p>
                              {personalData.jobPositionName &&
                                  <p style={{'marginBottom': '5px'}}
                                     className='text-secondary'>{capitalizeFirstLetter(personalData.jobPositionName)}</p>}
                            </div>
                             <button className={'button button-tertiary'} onClick={prepareDisplayNameModal}>Edit</button>
                          </div>

                           <div className={'col-12 col-md-12 col-lg-12 col-xxl-12'}>
                            <div className='email-section'>
                              <div className='email-section-wrapper'>
                                <div className='email-info'>
                                  <div className={'label'}>Email</div>
                                  <div className={'value'}>{personalData.email}</div>
                                </div>
                              </div>
                            </div>
                            {
                                (authStore.userData.recoveryEmail && errState === ValidationType.NO_ERROR && !showRecoveryEmailInput) &&
                                <div className={'recoveryEmail-section'}>
                                  <div className={'recoveryEmail-info-wrapper'}>
                                    <div className={'label'}>Recovery email</div>
                                    <div className={'value'}>
                                      <div className={'recovery-email'}>{authStore.userData.recoveryEmail}</div>
                                        <div className={'pen'} onClick={clickedEdit}><i className={'fa-solid fa-pen'}></i></div>
                                      <div onClick={toggleDeleteSection} className={'xmark'}><i className="fa-solid fa-xmark"></i></div>
                                    </div>

                                  </div>
                                </div>
                            }

                            {
                              showRecoveryEmailInput &&
                                <div className={'recoveryEmail-section'}>
                                  <div className={'recoveryEmail-info-wrapper'}>
                                    <div className={'label'}>Recovery email</div>
                                    <div className={'input-save-wrapper'}>
                                      <input value={recoveryEmail} onChange={recoveryEmailHandler} disabled={ontoValidationNumber} className={`form-control ${errState === ValidationType.NO_FURTHER_STEPS ? 'err' : ''} ${regexState ? '' : 'rgx-err'}`} type={'text'}/>
                                      {
                                        !clickedSaveRE &&
                                          <>
                                              <SpinnerButton
                                                  onClickCallback={() => sendRecoveryEmail(authStore.userData.accessToken)}
                                                  cssClasses={`button button-primary u-margin-left-xs`} initialDisabled={recoveryEmail === errorEmail || !regexState} dataLoading={dataIsLoading} title={'Save'}></SpinnerButton>
                                              <button onClick={toggleShowRecoveryEmailInput} className={'button button-secondary u-margin-left-xs'}>Cancel</button>
                                          </>
                                      }
                                    </div>
                                  </div>
                                </div>
                            }

                            {
                                (clickedSaveRE && errState === ValidationType.VALIDATION_NUMBER_SENT) &&
                                <div className={'verification-section'}>
                                  <div className={'label'}><i className="fa-solid fa-envelope"></i> The verification code has been sent to your recovery email. Input the code below:</div>
                                  <div className={'confirm-again-section'}>
                                    <input value={validationNumber} onChange={validationNumberHandler} className={`form-control ${wrongCode ? 'wrong-control' : ''}`} type={'text'} />
                                    {
                                        !wrongCode &&
                                        <SpinnerButton onClickCallback={() => sendValidationNumber(authStore.userData.accessToken)} cssClasses={'button button-primary u-margin-left-xs'} title={'Confirm'} dataLoading={dataIsLoadingNumber}></SpinnerButton>
                                    }
                                    {
                                      wrongCode &&
                                        <div className={'wrong-code-msg'}>Wrong code</div>
                                    }
                                    <button onClick={() => sendRecoveryEmail(authStore.userData.accessToken)} className={'button button-tertiary send-again-button'}><i className="fa-solid fa-rotate"></i> Send again</button>
                                  </div>
                                </div>
                            }

                            {
                              (errState === ValidationType.NO_FURTHER_STEPS) &&
                              <div className={'error-section'}>
                                <div className={'label'}>{errMsg}</div>
                              </div>
                            }
                            {
                                confirmDelete &&
                                <div className={'delete-section'}>
                                    <div className={'label'}>Are you sure you want to delete your recovery email address?</div>
                                    <div className={'buttons-wrapper'}>
                                        <button onClick={toggleDeleteSection} className={'btn btn-secondary cancel-button'}>Cancel</button>
                                        <button onClick={() => deleteRecoveryEmail(authStore.userData.accessToken)} className={'btn btn-primary confirm-button'}>Confirm</button>
                                    </div>
                                </div>
                            }

                            {
                              (clickedSaveRE && errState === ValidationType.ALREADY_EXISTING) &&
                                <div className={'existing-section'}>
                                  <div className={'label'}>{errMsg}</div>
                                  <div className={'button-section'}>
                                    <button onClick={resetState} className={'btn btn-secondary cancel'}>Cancel</button>
                                    <SpinnerButton onClickCallback={
                                      () => {linkRecoveryEmail(authStore.userData.accessToken)}
                                    } dataLoading={dataIsLoadingLink} cssClasses={'button button-primary confirm'} title={'Confirm'}></SpinnerButton>
                                  </div>
                                </div>
                            }

                            <div className='password-section'>
                              <div className='password-info-wrapper'>
                                <div className={'label'}>Password</div>
                                <div className={'value'}><div className={'psw'}>**********</div>
                                  <div className={'pen'} onClick={modalServicePassword.createModal}><i className={'fa-solid fa-pen'}></i></div>
                                </div>
                              </div>
                            </div>
                            {
                                (!authStore.userData.recoveryEmail && !showRecoveryEmailInput) &&
                                <button className={'button button-tertiary u-margin-top-s'} onClick={toggleShowRecoveryEmailInput}>
                                  <i className="fa-solid fa-triangle-exclamation"></i> <span>Add recovery email</span>
                                </button>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {modalServiceDisplyName.showModal &&
                      <PersonalInfoModal removeModal={modalServiceDisplyName.removeModal}
                                         dataIsLoading={modalServiceDisplyName.dataIsLoading}
                                         data={modalServiceDisplyName.modalData}
                                         okCallback={(name: string) => changeDisplayName(name, userData.accessToken)} />}
                  {modalServicePassword.showModal && <ChangePasswordModal removeModal={modalServicePassword.removeModal}
                                                                          dataIsLoading={modalServicePassword.dataIsLoading}
                                                                          okCallback={(password: string) => changePassword(password, userData.accessToken)}/>}
                </>}
            <div>
              {modalServiceProfilePicture.showModal && <ChangeProfilePictureModal removeModal={modalServiceProfilePicture.removeModal}
                            dataisLoading={modalServiceProfilePicture.dataIsLoading}
                                                      data={modalServiceProfilePicture.modalData}
                                                                                  onUploadedCroppedImage={croppedImageHandler}
              />}
            </div>
           <button onClick={props.checkTerms} className="button button-tertiary terms-link">Terms & Conditions Of Use</button>
          {spinnerService.spinner}
        </div>
        </>
  )

  function initializeComponent(accessToken : string) {
    spinnerService.createSpinner();

     const headers: AxiosRequestConfig['headers'] = {
        'Authorization': `Bearer ${accessToken}`
     }

    axios
      .get(personalInfoLoadUrl, {headers})
      .then((response$) => {
        setPersonalData(response$.data);
        authStore.changePageTitle("Your personal info");
        initializedComponent.current = true;
        spinnerService.removeSpinner();
      })
      .catch((error$: ErrorResponseDto) => {
        authStore.changePageTitle("Your personal info");
        spinnerService.removeSpinner();

        if (error$.response.data.message === 'Unauthorized') {
          initializedComponent.current = false;
          // Get new Access Token
          refreshToken(authStore.userData.refreshToken)
            .then((response$: any) => {
              authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
              initializeComponent(response$.data.accessToken);
            })
        }
        else {       
          showNotification('warning', error$.response.data.message);
          initializedComponent.current = false;
        }
      });
  }

  function toggleDeleteSection() {
      setConfirmDelete((prevState) => {
          return !prevState;
      })
  }
  function recoveryEmailHandler(e : any) {
    setRecoveryEmail(e.target.value);
  }

  function validationNumberHandler(e : any) {
    setValidationNumber(e.target.value);
  }

  function clickedEdit() {
      setShowRecoveryEmailInput(true);
      setRecoveryEmail(authStore.userData.recoveryEmail!);
      setErrorEmail(authStore.userData.recoveryEmail!);
  }

  function resetState() {
    setClickedSaveRE(false);
    setRecoveryEmail('');
    setErrState(ValidationType.NO_ERROR);
    setErrMsg('');
    setErrorEmail('');
  }

  function sendRecoveryEmail(accessToken: string) {
    const data= {
      recoveryEmail: recoveryEmail
    };
     const headers: AxiosRequestConfig['headers'] = {
        'Authorization': `Bearer ${accessToken}`
     }


    if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(recoveryEmail))){
        return;
    }

    setDataIsLoading(true);

    axios
        .post(sendRecoveryEmailURL, data, {headers})
        .then((response$) => {
          if (response$.data.status == ValidationType[ValidationType.VALIDATION_NUMBER_SENT]) {
            setOntoValidationNumber(true);
            setErrState(ValidationType.VALIDATION_NUMBER_SENT);
            setClickedSaveRE(true);
            setDataIsLoading(false);
            setErrMsg(response$.data.message);
            setWrongCode(false);
            setValidationNumber('');
          }
          else if (response$.data.status == ValidationType[ValidationType.NO_FURTHER_STEPS]) {
            setErrState(ValidationType.NO_FURTHER_STEPS);
            setErrMsg(response$.data.message);
            setDataIsLoading(false);
            setErrorEmail(recoveryEmail);
          }
          else if (response$.data.status == ValidationType[ValidationType.ALREADY_EXISTING]){
            setErrState(ValidationType.ALREADY_EXISTING);
            setClickedSaveRE(true);
            setDataIsLoading(false);
            setErrMsg(response$.data.message);
          }
        })
        .catch((error$) => {
          if (error$.response.data.message === 'Unauthorized') {
            // Get new Access Token
            refreshToken(authStore.userData.refreshToken)
                .then((response$: any) => {
                  authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                  sendRecoveryEmail(response$.data.accessToken);
                })
          }
          else{
            setDataIsLoading(false)
            showNotification('warning', 'There was an error with saving your recovery email! Please try again.')
          }
        });
  }

  function linkRecoveryEmail(accessToken: string) {
    const data= {
      recoveryEmail: recoveryEmail
    }
     const headers: AxiosRequestConfig['headers'] = {
        'Authorization': `Bearer ${accessToken}`
     }
    setDataIsLoadingLink(true)

    axios
        .post(sendRecoveryEmailToPersonalAccURL, data, {headers})
        .then((response$) => {
          setErrState(ValidationType.VALIDATION_NUMBER_SENT);
          setOntoValidationNumber(true);
          setErrState(ValidationType.VALIDATION_NUMBER_SENT);
          setClickedSaveRE(true);
          setDataIsLoading(false);
          setErrMsg(response$.data.message);
          setWrongCode(false);
          setValidationNumber('')
          setDataIsLoadingLink(false)
        })
        .catch((error$) => {
          if (error$.response.data.message === 'Unauthorized') {
            // Get new Access Token
            refreshToken(authStore.userData.refreshToken)
                .then((response$: any) => {
                  authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                  linkRecoveryEmail(response$.data.accessToken);
                })
          }
          else{
            setDataIsLoadingLink(false)
            showNotification('warning', 'There was an error with saving your recovery email! Please try again.')
          }
        });
  }

  function sendValidationNumber(accessToken: string) {
    const data= {
      validationNumber: +validationNumber
    }

     const headers: AxiosRequestConfig['headers'] = {
        'Authorization': `Bearer ${accessToken}`
     }
    setDataIsLoadingNumber(true)

    axios
        .post(sendValidationNumberURL, data, {headers})
        .then((response$) => {
          authStore.changeRecoveryEmail(recoveryEmail);
          resetState();
          setErrState(ValidationType.NO_ERROR);
          setDataIsLoadingNumber(false);
          setShowRecoveryEmailInput(false);
          showNotification('success', 'Recovery email successfully set!');
        })
        .catch((error$) => {
          if (error$.response.data.message === 'Unauthorized') {
            // Get new Access Token
            refreshToken(authStore.userData.refreshToken)
                .then((response$: any) => {
                  authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                  sendRecoveryEmail(response$.data.accessToken);
                })
          }
          else {
              showNotification('warning', 'Something went wrong , please try again!');
              setWrongCode(true)
              setDataIsLoadingNumber(false)
          }
        });
  }

  function deleteRecoveryEmail(accessToken: string) {
     const headers: AxiosRequestConfig['headers'] = {
        'Authorization': `Bearer ${accessToken}`
     }
    spinnerService.createSpinner();
    axios
        .post(deleteRecoveryEmailURL, {} , {headers})
        .then((response$) => {
            if (response$.data) {
                authStore.deleteRecoveryEmail();
                resetState();
                setShowRecoveryEmailInput(false);
            }
            else {
                showNotification('warning', 'There was an error deleting your recovery email, try again.');
            }
            spinnerService.removeSpinner();
            setConfirmDelete(false);
        })
        .catch((error$) => {
          if (error$.response.data.message === 'Unauthorized') {
            // Get new Access Token
            refreshToken(authStore.userData.refreshToken)
                .then((response$: any) => {
                  authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                  sendRecoveryEmail(response$.data.accessToken);
                })
          }
          else {
              showNotification('warning', 'Something went wrong please try again!');
              setConfirmDelete(false);
              spinnerService.removeSpinner();
          }
        });
  }

  function toggleShowRecoveryEmailInput() {
    setShowRecoveryEmailInput((prevState) => {
      return !prevState;
    })
  }

  function prepareDisplayNameModal() {
    let data: any = {};
    data.name = personalData.displayName;
    modalServiceDisplyName.createModal(data);
  }

  function prepareDisplayChangeProfilePictureModal(url : any){
    let data : any = {}
    data.photoUrl = url;
    modalServiceProfilePicture.createModal(data);
  }

  function changeDisplayName(name: string, accessToken: string): void {
    const data= {
      displayName: name
    }
     const headers: AxiosRequestConfig['headers'] = {
        'Authorization': `Bearer ${accessToken}`
     }
    
    modalServiceDisplyName.toggleDataIsLoading();

    axios
      .post(editProfileUrl, data, {headers})
      .then(() => {     
        authStore.changeDisplayName(name);
        setPersonalData((prevState) => {
          return {...prevState, displayName: name}
        })
        modalServiceDisplyName.removeModal();
        showNotification('success', 'Display Name was changed successfully.');
      })
      .catch((error$) => {
        if (error$.response.data.message === 'Unauthorized') {
          // Get new Access Token
          refreshToken(authStore.userData.refreshToken)
            .then((response$: any) => {
              authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
              changeDisplayName(name,response$.data.accessToken);
            })
        }
        else{
          modalServiceDisplyName.removeModal();
          showNotification('warning', 'There was an error with changing your Display Name! Please try again.')
        }
        modalServiceDisplyName.toggleDataIsLoading();
      });
  }

  function changePassword (password: string, accessToken: string): void {
    const data= {
      password: password
    }
     const headers: AxiosRequestConfig['headers'] = {
        'Authorization': `Bearer ${accessToken}`
     }

    modalServicePassword.toggleDataIsLoading();

    axios
    .post(editPasswordUrl, data, {headers})
    .then((response$) => {
      modalServicePassword.removeModal();
      showNotification('success', 'Password changed successfully.');
    })
    .catch((error$) => {
      if (error$.response.data.message === 'Unauthorized') {
        // Get new Access Token
        refreshToken(authStore.userData.refreshToken)
          .then((response$: any) => {
            authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
            changePassword(password, response$.data.accessToken);
          })
      }
      else {
        modalServicePassword.removeModal();
        if (error$.response.data.statusCode === 400) {
          showNotification('warning', 'Error - Password must be at least 8 characters, a mixture of uppercase and lowercase letters, numbers and special characters!')
        }
        else {
          showNotification('warning', 'There was an error with changing your password! Please try again.')
        }
      }
      modalServicePassword.toggleDataIsLoading();
    });
  }
}

export default PersonalInfoPage;