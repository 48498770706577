import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import axios, {AxiosRequestConfig} from 'axios';
import randomColor from "../utils/random-color";
import {
  UserDto,
  UserDataDto,
  AdminPanelData,
  EmployeePayloadInformation,
  CompanyEmployeePayloadInformation, Role, EMPLOYEES_TYPE
} from "../types/AdminData";
import {ErrorResponseDto} from "../types/ErrorData";
import AuthContext from "./auth-context";
import {showNotification} from "../ui/Toast/ToastNotification";
import useApiService from "../services/api.service";

const getAdminPanelURL: string = process.env.REACT_APP_PUBLIC_URL + '/admin-onboard/admin-panel';
const deleteEmployeeURL: string = process.env.REACT_APP_PUBLIC_URL + '/admin-onboard/user/delete';
const updateEmployeeURL: string = process.env.REACT_APP_PUBLIC_URL + '/admin-onboard/user/update';
const saveUserDataFileURL: string = process.env.REACT_APP_PUBLIC_URL + '/admin-onboard/save-user-data-file';
const createUserURL: string = process.env.REACT_APP_PUBLIC_URL + '/admin-onboard/user-data/create';
const updateUserURL: string = process.env.REACT_APP_PUBLIC_URL + '/admin-onboard/user-data/update';
const deleteUserURL: string = process.env.REACT_APP_PUBLIC_URL + '/admin-onboard/user-data/delete';
const saveEmployeesURL: string = process.env.REACT_APP_PUBLIC_URL + '/admin-onboard/save-users';
const getCsvFileDownloadLinkURL = process.env.REACT_APP_PUBLIC_URL + '/auth/user-data-template';
const getCategoriesURL = process.env.REACT_APP_PUBLIC_URL + '/skill/categories';
const chooseSkillCategoriesURL = process.env.REACT_APP_PUBLIC_URL + '/admin-onboard/choose-skill-category';

export const AdminPanelContext = React.createContext({
  employeeData: [],
  userData: [],
  categories: [],
  csvFileDownloadLink: '',
  getAdminPanel: (accessToken: string): any => {},
  deleteEmployee: (id: string, accessToken: string): any => {},
  updateEmployee: (payload: CompanyEmployeePayloadInformation, accessToken: string): any => {},
  uploadCSVFile: (file: any, accessToken: string): any => {},
  createTempUser: (file: EmployeePayloadInformation, accessToken: string): any => {},
  updateTempUser: (payload: EmployeePayloadInformation, accessToken: string): any => {},
  deleteTempUser: (userDataIds: number[], accessToken: string): any => {},
  saveTempUsersIntoDB: (type: EMPLOYEES_TYPE, accessToken: string): any => {},
  getCategories: (accessToken: string): any => {},
  chooseSkillCategories: (categories: string[], accessToken: string): any => {}
});

export const AdminPanelContextProvider: React.FC<{children: React.ReactNode}> = (props) => {
  const authStore = useContext(AuthContext);
  const { refreshToken } = useApiService();
  const navigate = useNavigate();
  const [employeeData, setEmployeeData] = useState<UserDto[]>([]);
  const [userData, setUserData] = useState<UserDataDto[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [csvFileDownloadLink, setCsvFileDownloadLink] = useState('');

  useEffect(() => {
    getCsvFileDownloadLinkWrapper(authStore.userData.accessToken);
  }, []);

  async function getAdminPanel(accessToken: string) {
    let promise = new Promise((resolve: any, reject: any) => {

      const headers: AxiosRequestConfig['headers'] = {
        'Authorization': `Bearer ${accessToken}`
      }

      axios
         .get(getAdminPanelURL, {headers})
         .then((res$: { data: AdminPanelData }) => {
           const updatedExistingUsers = res$.data.existingUsers.map(user => ({
             ...user,
             isManager: user.userRole === Role.MANAGER || user.userRole === Role.ADMIN_MANAGER,
           }));

           const updatedUserData = res$.data.userData.map(user => ({
             ...user,
             // Assuming the default state is false unless specified true
             isManager: user.userType === 'Manager' || user.userRole === 'EmployeeAndManager',
           }));

           // Update state with the newly created arrays
           setEmployeeData(updatedExistingUsers);
           setUserData(updatedUserData);
           setCategories(res$.data.chosenCategories);
           if (res$.data.isOnboarding) {
             navigate('/admin-onboard/admin-onboarding');
             resolve(updatedUserData);
           }
           else {
             resolve();
           }
         })
         .catch((error$) => {
           reject(error$);
         })
    })
    return await promise;
  }

  async function updateEmployee (payload: CompanyEmployeePayloadInformation, accessToken: string) {

    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }

    let data = {
      id: payload.id,
      name: payload.name,
      jobTitle: payload.jobTitle,
      email: payload.email,
      isManager: payload.isManager,
      // availability: payload.availability
    }

    let promise = new Promise((resolve: any, reject: any)=> {
      axios.post(updateEmployeeURL, data, {headers})
      .then((response$: any)=> {
        const updatedUser: UserDto = { ...response$.data, isManager: payload.isManager };
        const tempData = employeeData.map(user => user.id === updatedUser.id ? updatedUser : user)
        setEmployeeData(tempData);
        resolve();
      })
      .catch((error$: ErrorResponseDto)=> {
        reject(error$);
      }) 
    })
    return await promise;          
  }

  async function deleteEmployee (id: string, accessToken: string) {
    let promise = new Promise((resolve: any, reject: any)=> {

      const headers: AxiosRequestConfig['headers'] = {
        'Authorization': `Bearer ${accessToken}`
      }

      let data: any = {
        userId: id
      };

      axios        
        .post(deleteEmployeeURL, data, {headers})
        .then((response$: {data: boolean})=> {
          if (response$.data) {
            const tempData = employeeData.filter(user => user.id !== id);
            setEmployeeData(tempData);
          }
          resolve();
        })
        .catch((error$: ErrorResponseDto)=> {
          reject(error$);
        })
    })

    return await promise;
  }

  async function uploadCSVFile (file: any, accessToken: string) {
    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);

    let promise = new Promise((resolve: any, reject: any)=> {
      axios
        .post(saveUserDataFileURL, bodyFormData, {headers})
        .then((response$) => {
          const updatedUserData = response$.data.map((user: UserDataDto) => ({
            ...user,
            // Assuming the default state is false unless specified true
            isManager: user.userType === 'Manager' || user.userRole === 'EmployeeAndManager',
          }));
          setUserData(updatedUserData);
          resolve();
        })
        .catch((error$) => {            
          reject(error$);        
        });
    });
    return await promise;
  }

  async function createTempUser (payload: EmployeePayloadInformation, accessToken: string) {
    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }
    const color = randomColor();

    let data =  {
      ...payload,
      'color': color
    }

    let promise = new Promise((resolve: any, reject: any)=> {
      axios
      .post(createUserURL, data, {headers})
      .then((response$: any) => {
        const newUser: UserDataDto = response$.data;
        newUser.isManager = newUser.userType === 'Manager' || newUser.userRole === 'EmployeeAndManager';
        setUserData([...userData, newUser]);
        resolve();
      })
      .catch((error$) => {
        reject(error$);
      }); 
    });
    return await promise;
  }

  async function deleteTempUser (userDataIds: number[], accessToken: string) {
    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }
    let data =  {
      userDataIds: userDataIds,
		}
    
    let promise = new Promise((resolve: any, reject: any)=> {
      axios
        .post(deleteUserURL, data, {headers})
        .then((response$) => {
          const tempData = userData.filter(user => {
            return !userDataIds.find((userDataId: number) => user.id === userDataId);
          })
          setUserData(tempData);
          resolve();
        })
        .catch((error$) => {
          reject(error$);
        });
    })
    return await promise;
  }

  async function updateTempUser (payload: EmployeePayloadInformation, accessToken: string) {
    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }
    let data =  {
      ...payload
    }

    let promise = new Promise((resolve: any, reject: any)=> {
      axios
        .post(updateUserURL, data, {headers})
        .then((response$) => {
          const updatedUser: UserDataDto = response$.data;
          updatedUser.isManager = updatedUser.userType === 'Manager' || updatedUser.userRole === 'EmployeeAndManager';
          let tempData = userData.map(user =>
             user.id === payload.id ? updatedUser: user
          );
          setUserData(tempData);
          resolve();
        })
        .catch((error$) => {
          reject(error$);
        });
    });
    return await promise;
  }

  async function saveTempUsersIntoDB(type: EMPLOYEES_TYPE, accessToken: string) {
    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }

    let data = {
      type: type.valueOf()
    }
  
    let promise = new Promise((resolve: any, reject: any)=> {
      axios
      .post(saveEmployeesURL, data, {headers})
      .then((response$: any) => {
        const updatedExistingUsers = response$.data.map((user: UserDto) => ({
          ...user,
          isManager: user.userRole === Role.MANAGER || user.userRole === Role.ADMIN_MANAGER,
        }));
        setUserData([]);
        setEmployeeData(updatedExistingUsers);
        resolve();
      })
      .catch((error$) => { 
        reject(error$);       
      });
    })
    return await promise;
  }

  function getCsvFileDownloadLinkWrapper(accessToken: string) {
    getCsvFileDownloadLink(accessToken)
       .then((response$: any) => {})
       .catch((error$: ErrorResponseDto)=> {
         if (error$.response.data.message === 'Unauthorized') {
           // Get new Access Token
           refreshToken(authStore.userData.refreshToken)
              .then((response$: any) => {
                authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                getCsvFileDownloadLinkWrapper(response$.data.accessToken);
              })
         }
         else {
           showNotification('warning', error$.response.data.message);
         }
       })
  }

  async function getCsvFileDownloadLink(accessToken: string) {
    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }
    let promise = new Promise((resolve: any, reject: any)=> {
      axios
         .get(getCsvFileDownloadLinkURL, {
           headers
         })
         .then((response$: {data: string}) => {
           setCsvFileDownloadLink(response$.data);
           resolve();
         })
         .catch((error$) => {
           reject(error$);
         });
    })
    return await promise;
  }

  async function getCategories(accessToken: string) {
    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }
    let promise = new Promise((resolve: any, reject: any)=> {
      axios
         .get(getCategoriesURL, {
           headers
         })
         .then((response$: {data: {category: string}[]}) => {
           resolve(response$.data);
         })
         .catch((error$) => {
           reject(error$);
         });
    })
    return await promise;
  }

  async function chooseSkillCategories(categories: string[], accessToken: string) {
    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }
    let data = {
      companyId: +authStore.userData.companyId,
      categories
    }
    let promise = new Promise((resolve: any, reject: any)=> {
      axios
         .post(chooseSkillCategoriesURL, data, {headers})
         .then((response$: {data: string[]}) => {
           setCategories(response$.data);
           resolve();
         })
         .catch((error$) => {
           reject(error$);
         });
    })
    return await promise;
  }

  const context = {
    employeeData: employeeData as any,
    userData: userData as any,
    categories: categories as any,
    csvFileDownloadLink: csvFileDownloadLink as any,
    getAdminPanel: getAdminPanel as any,
    deleteEmployee: deleteEmployee as any,
    updateEmployee: updateEmployee as any,
    uploadCSVFile: uploadCSVFile as any,
    createTempUser: createTempUser as any,
    updateTempUser: updateTempUser as any,
    deleteTempUser: deleteTempUser as any,
    saveTempUsersIntoDB: saveTempUsersIntoDB as any,
    getCategories: getCategories as any,
    chooseSkillCategories: chooseSkillCategories as any
	}

	return (
		<AdminPanelContext.Provider value={context}>
			{props.children}
		</AdminPanelContext.Provider>
	);
}

export default AdminPanelContext;