import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import styles from './ReminderModal.module.scss';
import {capitalizeFirstLetter} from "../../../../../../../../utils/capitalize-first-letter";

type ReminderData = {
  skillId: number, 
  skillTitle: string, 
  employeeName: string, 
  employeeSurname: string, 
  employeeId: number
}

const ReminderModal: React.FC<{data: ReminderData, cancelCallback: React.MouseEventHandler<HTMLButtonElement>, 
  okCallback: Function}> = (props) => {
  
  const [showClass, setShowClass] = useState('');
  
  const modal = (
    <div className={`${styles['modal-container']} fade ${showClass}`}>      
      <div className='card card-modal'>      
        <div className="card-body">          
          <p>Are you sure you want to remind <strong>{props.data.employeeName} {props.data.employeeSurname}</strong> to accept AI identified skill <strong>{capitalizeFirstLetter(props.data.skillTitle)}</strong>?</p>
        </div>
        <div className="card-footer">
          <div className='buttons-wrapper'>
            <button className='btn btn-secondary' onClick={props.cancelCallback}>No</button>
            <button className='btn btn-primary-60' onClick={() => props.okCallback(props.data.skillId, props.data.employeeId)}>Yes</button>
          </div>
        </div>
      </div>
    </div>
  )

  useEffect(() => {
    let timer = setTimeout(() => {
      setShowClass('show');
    }, 200);

    return () => {
      clearTimeout(timer);
    }    
  }, []);

	return (
    ReactDOM.createPortal(modal, document.getElementById('modal-root') as HTMLDivElement)
	);
};

export default ReminderModal;
