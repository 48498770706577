import React, { useState, useEffect, useContext, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import AuthContext from '../../../../../../../store/auth-context';
import { UserData } from '../../../../../../../types/AuthData';
import axios from 'axios';
import Tooltip from '../../../../../../../services/tooltip.service';

import styles from './SearchNeededSkillComponent.module.scss';
import OnboardingManagerContext from '../../../../../../../store/create-project-context';
import useLoadingSpinner from '../../../../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner';

import { components } from 'react-select';
import { showNotification } from '../../../../../../../ui/Toast/ToastNotification';
import useApiService from '../../../../../../../services/api.service';

import Select from "react-select";
import { withAsyncPaginate } from "react-select-async-paginate";
import {capitalizeFirstLetter} from "../../../../../../../utils/capitalize-first-letter";
import {ErrorResponseDto} from "../../../../../../../types/ErrorData";

const CreatableAsyncPaginate = withAsyncPaginate(
  Select
);

const selectStyles = {
  container: (provided: {}) => ({
    ...provided,
    'flex': '1',
    'paddingLeft': '2px !important',
    'paddingRight': '2px !important',
  }),
  control: (provided: {}) => ({
    ...provided,
    'backgroundColor': '#21262E',
    'border': '1px solid rgba(79, 89, 105, 0.45)',
    'paddingLeft': '16px',
    'paddingTop': '12px',
    'paddingRight': '16px',
    'paddingBottom': '12px',
    'borderRadius': '4px'
  }),
  indicatorSeparator: (provided: {}) => ({
    'display': 'none'
  }),
  dropdownIndicator: (provided: {}) => ({
    'display': 'none'
  }),
  clearIndicator: (provided: {}) => ({
    'display': 'none'
  }),
  placeholder: (provided: {}) => ({
    ...provided,
    'color': 'rgba(201, 209, 217, 0.45)'
  }),
  loadingMessage: (provided: {}) => ({
    ...provided,
    'color': 'rgba(201, 209, 217, 0.45)'
  }),
  loadingIndicator: (provided: {}) => ({
    ...provided,
    'color': 'rgba(201, 209, 217, 0.45)',
  }),
  input: (provided: {}) => ({
    ...provided,
    'color': '#F7F7F8'
  }),
  noOptionsMessage: (provided: {}) => ({
    ...provided,
    'color': '#757575'
  }),
  menu: (provided: {}) => ({
    ...provided,
    'backgroundColor': '#191D24'
  }),
  singleValue: (provided: {}) => ({
    ...provided,
    'color': '#F7F7F8'
  }),
  option: (provided: {}) => ({
    ...provided,
    'color': '#F7F7F8'
  })
}

const SearchNeededSkillComponent: React.FC<{ changeSection: Function, isDraft: boolean, projectId: any, clickedSaveAsDraft: boolean, saved: Function, saveProgress: Function, jobPosting?: boolean}> = (props) => {
  const authStore = useContext(AuthContext);
  const userData: UserData = authStore.userData;
  const onboardingManagerStore = useContext(OnboardingManagerContext);

  const [loadingMessage, setLoadingMessage] = useState('Loading...'); 
  const neededSkillsData: any = sortSkills(removeDuplicates(onboardingManagerStore.neededSkillsData));

  const [newSkill, setNewSkill] = useState<{ name: string, id: number } | any>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const [inputAIValue, setInputAIValue] = useState<string>('');
  const [newSkillName, setNewSkillName] = useState<string>('');
  const [newSkillDescription, setNewSkillDescription] = useState<string>('');
  const [skillDescriptionInput, setSkillDescriptionInput] = useState<string>('');
  const [newSkillCategory, setNewSkillCategory] = useState<string>('');
  const [showNextStepMessage, setShowNextStepMessage] = useState<boolean>(false);
  const spinnerService = useLoadingSpinner(); 
  const [isInit, setIsInit] = useState<boolean>(false);
  const apiService = useApiService();

  const [typeSkillToAddSpring] = useSpring(() => ({
    from: { opacity: 0 }, to: { opacity: 1 }, delay: 600
  }))
  const [skillNameResSpring, skillNameResSpringApi] = useSpring(() => ({
    from: { opacity: 0 }
  }))
  const [newSkillNameResSpring, newSkillNameResSpringApi] = useSpring(() => ({
    from: { opacity: 0 }
  }))
  const [inputSkillDescriptionSpring, inputSkillDescriptionSpringApi] = useSpring(() => ({
    from: { opacity: 0 }
  }))
  const [skillDescSpring, skillDescSpringApi] = useSpring(() => ({
    from: { opacity: 0 }
  }))
  const [skillDescResSpring, skillDescResSpringApi] = useSpring(() => ({
    from: { opacity: 0 }
  }))
  const [selectCategorySpring, selectCategorySpringApi] = useSpring(() => ({
    from: { opacity: 0 }
  }))
  const [categorisListSpring, categorisListSpringApi] = useSpring(() => ({
    from: { opacity: 0 }
  }))

  const [nextStepSpring, nextStepSpringApi] = useSpring(() => ({
    key: 'next-step',
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 1200
  }))

  const [nextStepMessageSpring, nextStepMessageSpringApi] = useSpring(() => ({
    from: { opacity: 0 }
  }))
  
  useEffect(() => { 
    if (props.isDraft) {
      getProjectSkills(props.projectId, userData.accessToken);
    }
    else {
      setIsInit(true)
    }
  }, [])

  useEffect(() => {
    if (newSkillName !== '') {
      newSkillNameResSpringApi.start({
        to: {opacity: 1 },
        onResolve: () => {
          inputSkillDescriptionSpringApi.start({ // Show project description input
            to: { opacity: 1 }
          }),
            skillDescSpringApi.start({  // Show and animate project description 'answer'  
              to: { opacity: 1 }
            })
        }
      })
    }
  }, [newSkillName])

  useEffect(() => {
    if (newSkill != null) {
      skillNameResSpringApi.start({  
        to: { opacity: 1 },
        onResolve: () => {
          setTimeout(()=> {
            skillNameResSpringApi.start({
              to: { opacity: 0 }}
            )        
          }, 2000)
          setTimeout(()=> {
            skillNameResSpringApi.start({
              to: { opacity: 0 }}         
            )                 
          }, 2000)
          setTimeout(()=> {
            setNewSkill(null); 
            setShowNextStepMessage(true);
          }, 2600)          
        }
      })
    }     
  }, [newSkill])

  useEffect(() => { 
    if (newSkillDescription != '') {
      skillDescResSpringApi.start({
        to: { opacity: 1 }
      })      
      selectCategorySpringApi.start({
        to: { opacity: 1 }, delay: 600
      })
      categorisListSpringApi.start({
        to: { opacity: 1}, delay: 1200
      })      
    }
  }, [newSkillDescription])

  useEffect(()=> {
    if (showNextStepMessage) {
      nextStepMessageSpringApi.start({
        to: { opacity: 1 }
      })
    }
  }, [showNextStepMessage])

  useEffect(() => {
    if (props.clickedSaveAsDraft) {
      goToNextSection();
    }
  }, [props.clickedSaveAsDraft]);

  return (
    <section className={styles['search-neededed-skill-section']}>
      
      { isInit &&
        <>
      <div className='needed-skills-wrapper'>
        <div className='info-wrapper'>
          {/* <div className='back-section-button'>
            <button className='btn btn-outline-text-secondary btn-sm' onClick={()=>props.changeSection('project-skills-section')}><i className="fa-solid fa-angle-left"></i> Back</button>      
          </div>    */}
          <div className='generating-skills'>Skills, needed for your {props.jobPosting ? 'task' : 'project'}:</div>

          {/* Skills tags */}
          <div className='skills-tag-wrapper'>
            { neededSkillsData.map((item: any) => (
              <span key={item.id} id={item.id} className={'skill-tag'}>
                {capitalizeFirstLetter(item.synonym ? item.synonym.name as string : item.skill.name as string)}
                <span>
                  <Tooltip
                    place="top"                                         
                    tooltipId={item.skill.id.toString()}  
                    backgroundColor="#101319"
                    borderColor='#5C5F6A !important'
                    border={true}            
                    content={
                      <span>{item.skill.description}</span>                                 
                    }
                    className='tag-skill-description'
                  >
                    <span className='select-skill-description-symbol'><i className="fa-regular fa-circle-question"></i></span>
                  </Tooltip>  
                </span>
                <span style={{marginLeft: '12px'}} title="Remove skill">
                <Tooltip
                  place="top"                                         
                  tooltipId={item.skill.id.toString() + "_remove"}  
                  backgroundColor="#101319"
                  borderColor='#5C5F6A !important'
                  border={true}
                  content={
                    <span>Remove skill</span>                                 
                  }
                  className='tag-skill-description'
                >
                    <i className="fa-solid fa-xmark" onClick={() => removeSkill(item, userData.accessToken)}></i> 
                  </Tooltip>                                 
                </span>
              </span>
            ))
            }
          </div>

          <animated.span className={'q-field'} style={{ ...typeSkillToAddSpring }}>
            Type the skill you want to add.
          </animated.span>
          
          {newSkill !== null &&
            <animated.span className={'a-field'} style={{ ...skillNameResSpring }}>
              {capitalizeFirstLetter(newSkill.name)}
            </animated.span>
          }
          { newSkillName !== '' &&
            <animated.span className={'a-field'} style={{ ...newSkillNameResSpring }}>
              {newSkillName}
            </animated.span>
          }
          { newSkillName !== '' &&
            <animated.span className={'q-field'} style={{ ...skillDescSpring }}>
              Describe "{newSkillName}" for our AI:
            </animated.span>
          }
          { newSkillDescription !== '' &&
            <animated.span className={'a-field'} style={{ ...skillDescResSpring }}>
              {newSkillDescription}
            </animated.span>
          }
          { newSkillDescription !== '' &&
            <animated.span className={'q-field'} style={{ ...selectCategorySpring }}>
              Select to which category {newSkillName} fits better:
            </animated.span>
          }
          { showNextStepMessage && newSkillName == '' &&
            <animated.span className={'q-field'} style={{ ...nextStepMessageSpring, marginBottom: '16px' }}>
              Any other skill you want to add? If no, click Next.
            </animated.span>
          }       
        </div>

        {newSkillName == '' &&
          <div className='add-description-wrapper'>
            <CreatableAsyncPaginate
              key={inputAIValue}               
              menuPlacement="top"         
              className="basic-single"
              classNamePrefix="select"
              isClearable={true}
              isSearchable={true}
              name="skill"
              components={{ Option: CustomOption }}
              formatOptionLabel={formatOptionLabel} 
              styles={selectStyles}
              value={''}
              defaultInputValue={inputAIValue !== '' ? inputAIValue : inputValue !== '' ? inputValue : undefined }
              menuIsOpen={inputAIValue !== '' ? true : inputValue !== '' ? true : undefined}
              defaultOptions={inputAIValue !== '' ? true : false}     
              autoFocus={inputAIValue !== '' ? true : inputValue !== '' ? true : false}   
              backspaceRemovesValue={true}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: '#2D2F38',
                  primary: '#757575',
                },
              })} 
              placeholder="Front end, Data science, Agile methodology, etc."
              loadingMessage={() => loadingMessage}              
              noOptionsMessage={
                (value) => value.inputValue === "" ? "Start typing" : value.inputValue.length === 1 ? 'Please enter a minimum of two characters to view the searched skills' : "No options"
              }               
              debounceTimeout={1200}
              loadOptionsOnMenuOpen={false}
              additional={{page: 1}}
              loadOptions={(search: string, options: any, aditional: any)=> loadOptionsHandler(search, options, aditional)}
              onChange={(value: any) => {
                if (value) {
                  if (value.value === 'What is the closest skill' || value.value === 'Search AI' || value.value === 'No closest skill') {
                    return;
                  }                      
                  selectSkill(value); 
                }                          
              }}
              onInputChange={(value)=> {
                if (value.trim() === '') {
                  setInputValue('');
                  setInputAIValue('');
                }
                else {
                  setInputValue(value.trim());
                  setInputAIValue('');
                  setLoadingMessage('Loading...');
                }
              }}
              onMenuClose={()=> { 
                setInputAIValue('');
                setInputValue('');  
                setLoadingMessage('Loading...');
              }}
            />
            <animated.div style={{ ...nextStepSpring, position: 'relative' }} key='cancel-searching'>
              <span className={'next-step-button cancel-button'} onClick={goToPrevSection}>Back</span>
            </animated.div>
            { showNextStepMessage && newSkillName == '' &&
              <animated.div style={{ ...nextStepSpring, position: 'relative' }} key='finish-searching'>
                <span className={'next-step-button finish-button'} onClick={goToNextSection}>Next <i className="fa-solid fa-arrow-right"></i></span>
              </animated.div>
            }
          </div>
        }
        { newSkillName !== '' && newSkillDescription === '' &&
          <animated.div className='add-description-wrapper' style={{ ...inputSkillDescriptionSpring }}>
            <div className='textarea-wrapper'>
              <textarea id='projectDesc' autoComplete='off' placeholder='Answer here'
                value={skillDescriptionInput} onChange={insertSkillDescHandler} />
              <div className='button-wrapper'>
                {
                  skillDescriptionInput.length > 1 && newSkillDescription === '' &&
                  <button className='button button-primary u-margin-bottom-xs' onClick={saveSkillDescription}>Send</button>
                }
              </div>
            </div>
          </animated.div>      
        } 
        { newSkillDescription != '' &&
          <animated.div className='categories-wrapper' style={{ ...categorisListSpring }}>
          {
            onboardingManagerStore.skillCategoriesData.map((category: string)=> {
              return category ? <span key={category} className='category-tag' onClick={() => saveNewSkill(category, userData.accessToken)}>{capitalizeFirstLetter(category)}</span> : null
            })
          }
          </animated.div>
        }
      </div>
      </>
    }
    {
      spinnerService.spinner
    }
    </section>
  );

  async function loadOptionsHandler(search: string, loadedOptions: unknown[], additional: any) {
    let options: any = [];
    let additionalObject: any = {};
    let returningObject: any = {
      options: options
    }
    if (search.trim().length > 1) {
      // setInputValue(search.trim());
      if (inputAIValue === '') {
        let result: any = await loadOptions(search.trim(), additional.page, userData.accessToken);

        if (result.searchedSkills.length > 0) {
          for (let item of result.searchedSkills) {
            options.push({ value: item.id, label: capitalizeFirstLetter(item.name), description: item.description })
          }
          returningObject.hasMore = result.totalCount > result.page * result.pageSize ? true : false;
          if (returningObject.hasMore) {
            returningObject.options = options;
          }
          else {              
            returningObject.options = [...options, {value: 'Search AI'}]
          }
          
          additionalObject.page = additional.page + 1;
          returningObject.additional = additionalObject;
        }
        else {
          setLoadingMessage('AI is checking our database...');
          let response$: any = await searchAISimilarSkills(search.trim().toLowerCase(), userData.accessToken);
          let opt = [] as any[];
          if (response$.synonym && response$.synonym.aiId != undefined) {
            opt.push({ value: response$.synonym.aiId, label: capitalizeFirstLetter(response$.synonym.name), preferredSkillAIId: response$.aiSimilarSkills[0].preferredSkillAIId });
          } 
          else {
            for (let item of response$.aiSimilarSkills) {
              opt.push({ value: item.preferredSkillAIId, label: capitalizeFirstLetter(item.preferredSkillName!), preferredSkill: true, description: item.preferredSkillDescription });
            }  
          }
          returningObject.options = [{value : opt.length > 0 ? 'What is the closest skill' : 'No closest skill'}, ...opt, {value : 'create-new-skill'}];
        }       
      }
      else {
        setLoadingMessage('AI is checking our database...');                        
        let response$: any= await searchAISimilarSkills(inputAIValue.trim().toLowerCase(), userData.accessToken);
        let opt = [] as any[];
        if (response$.synonym && response$.synonym.aiId != undefined) {
          opt.push({ value: response$.synonym.aiId, label: capitalizeFirstLetter(response$.synonym.name), preferredSkillAIId: response$.aiSimilarSkills[0].preferredSkillAIId });
        } 
        else {
          for (let item of response$.aiSimilarSkills) {
            opt.push({ value: item.preferredSkillAIId, label: capitalizeFirstLetter(item.preferredSkillName!), preferredSkill: true, description: item.preferredSkillDescription });
          }  
        }
        returningObject.options = [{value : opt.length > 0 ? 'What is the closest skill' : 'No closest skill'}, ...opt, {value : 'create-new-skill'}];
      }
    }
    return returningObject;   
  }

  async function loadOptions(term: string, page: number, accessToken: string) {
    let promise = new Promise((resolve: Function, reject: Function) => {
      onboardingManagerStore.searchSkill(term, page, props.projectId, accessToken)
      .then((data: any)=> {
        resolve(data);
      })
      .catch((error$: ErrorResponseDto)=> {
        if (error$.response.data.message === 'Unauthorized') {
          // Get new Access Token
          apiService.refreshToken(authStore.userData.refreshToken)
            .then((response$: any) => {
              authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
              resolve(loadOptions(term, page, response$.data.accessToken));
            })
        }
        else {
          showNotification('warning', error$.response.data.message);
        }
      });
    })
    return await promise;
  }

  function getProjectSkills (projectId: any, accessToken: string) {
    spinnerService.createSpinner();
    onboardingManagerStore.getProjectSkills(projectId, accessToken)
      .then(()=> {
        setIsInit(true);
        spinnerService.removeSpinner();
      })
      .catch((error$: ErrorResponseDto)=> {
        if (error$.response.data.message === 'Unauthorized') {
          // Get new Access Token
          apiService.refreshToken(authStore.userData.refreshToken)
            .then((response$: any) => {
              authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
              getProjectSkills(projectId, response$.data.accessToken);
            })
        }
        else {
          setIsInit(true);
          showNotification('warning', error$.response.data.message);
          spinnerService.removeSpinner();
        }
      })
  }

  async function searchAISimilarSkills (value: string, accessToken: string) {
    let promise = new Promise((resolve: Function, reject: Function)=> {
      onboardingManagerStore.searchAISimilarSkills(value, accessToken)
      .then((response$: any) => {
        resolve(response$)
      })
      .catch((error$: ErrorResponseDto) => {
        if (error$.response.data.message === 'Unauthorized') {
          // Get new Access Token
          apiService.refreshToken(authStore.userData.refreshToken)
            .then((response$: any) => {
              authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
              resolve(searchAISimilarSkills(value, response$.data.accessToken));
            })
        }
        else {
          showNotification('warning', error$.response.data.message);
        }
      });
    });
    return await promise;
  }

  function insertSkillDescHandler(e: any) {
    e.target.style.height = 'auto';
    e.target.style.height = (e.target.scrollHeight) + 'px';
    setSkillDescriptionInput(e.target.value);
  }

  function saveSkillDescription() {
    setNewSkillDescription(skillDescriptionInput);
  }

  function selectSkill(newValue: any) {
    if (newValue.preferredSkill == true) {
      // if we are adding a synonym and we chose a preferred term from similar skills from AI
      addNewSkillOrSynonymNeededSkill(newValue, userData.accessToken);
    } 
    else if (newValue.preferredSkillAIId != null) { // if we are adding a selected synonym from the search
      addNewSkillOrSynonymNeededSkill2(newValue, userData.accessToken);  
    }
    else {
      // if we are adding an existing needed skill from our DB
      addNeededSkill(newValue, userData.accessToken);
    }
  }

  function addNewSkillOrSynonymNeededSkill(newValue: any, accessToken: string) {
    const payload: {} = {
      synonymName: inputValue != '' ? inputValue : inputAIValue,
      preferredSkillAIId: newValue.value
    }
    
    spinnerService.createSpinner();
    
    onboardingManagerStore.addNewSkillOrSynonymNeededSkill(payload, props.isDraft ? props.projectId : onboardingManagerStore.projectData.project?.id, accessToken)
      .then((response$: any) => {
        if (response$.tag == 'EXISTING_PREFERRED_NEEDED_SKILL') {
          showNotification('warning', `There is already a similar skill to the chosen one (${capitalizeFirstLetter(response$.projectSkill.synonym.name)})`);
        }
        else {
          setNewSkill({
            name: response$.projectSkill.synonym.name,
            id: response$.projectSkill.id
          });     
        }
        setInputValue('');
        setInputAIValue('');
        spinnerService.removeSpinner();
      })
      .catch((error$: ErrorResponseDto) => {
        if (error$.response.data.message === 'Unauthorized') {
          // Get new Access Token
          apiService.refreshToken(authStore.userData.refreshToken)
            .then((response$: any) => {
              authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
              addNewSkillOrSynonymNeededSkill(newValue, response$.data.accessToken);
            })
        }
        else {
          spinnerService.removeSpinner();
          showNotification('warning', error$.response.data.message);
        }        
      });
  }

  function addNewSkillOrSynonymNeededSkill2 (newValue: any, accessToken: string) {
    spinnerService.createSpinner();
    const payload: {} = {
      synonymName: newValue.label,
      synonymAIId: newValue.value,
      preferredSkillAIId: newValue.preferredSkillAIId
    }

    onboardingManagerStore.addNewSkillOrSynonymNeededSkill(payload, props.isDraft ? props.projectId : onboardingManagerStore.projectData.project?.id, accessToken)
    .then((response$: any) => {
      setNewSkill({
        name: response$.projectSkill.synonym.name,
        id: response$.projectSkill.id
      });
      // setSelectValue('');
      setInputValue('');
      setInputAIValue('');     
      spinnerService.removeSpinner();
    })
    .catch((error$: ErrorResponseDto) => {
      if (error$.response.data.message === 'Unauthorized') {
        // Get new Access Token
        apiService.refreshToken(authStore.userData.refreshToken)
          .then((response$: any) => {
            authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
            addNewSkillOrSynonymNeededSkill2(newValue, response$.data.accessToken);
          })
      }
      else {
        spinnerService.removeSpinner();
        showNotification('warning', error$.response.data.message);
      }      
    });
  }

  function addNeededSkill(skill: any, accessToken: string) {
    onboardingManagerStore.addNeededSkill(+skill?.value!, props.isDraft ? props.projectId : onboardingManagerStore.projectData.project?.id!, accessToken)
      .then((response$: any) => {
        setNewSkill({
          name: response$.skill.name,
          id: response$.id
        });
        // setSelectValue('');
        setInputValue('');
        setInputAIValue('');
      })
      .catch((error$: ErrorResponseDto) => {
        if (error$.response.data.message === 'Unauthorized') {
          // Get new Access Token
          apiService.refreshToken(authStore.userData.refreshToken)
            .then((response$: any) => {
              authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
              addNeededSkill(skill, response$.data.accessToken);
            })
        }
        else {
          showNotification('warning', error$.response.data.message);
        }
      });
  }

   function saveNewSkill(category: any, accessToken: string) {
    spinnerService.createSpinner();

    const payload: {} = {
      synonymName: newSkillName,
      synonymDescription: newSkillDescription,
      synonymCategory: category
    }
    onboardingManagerStore.addNewSkillOrSynonymNeededSkill(payload, props.isDraft ? props.projectId : onboardingManagerStore.projectData.project?.id, accessToken)
      .then((response$: any) => {
        setNewSkill({
            name: response$.projectSkill.skill.name,
            id: response$.projectSkill.id
          });
        setInputValue('');
        setInputAIValue('');
        setNewSkillName('');
        setNewSkillDescription('');
        setNewSkillCategory('');
        setSkillDescriptionInput('');
        
        spinnerService.removeSpinner();
      })
      .catch((error$: ErrorResponseDto) => {
        if (error$.response.data.message === 'Unauthorized') {
          // Get new Access Token
          apiService.refreshToken(authStore.userData.refreshToken)
            .then((response$: any) => {
              authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
              saveNewSkill(category, response$.data.accessToken);
            })
        }
        else {
          showNotification('warning', error$.response.data.message);
          setNewSkill(false);
          setNewSkillName('');
          setNewSkillDescription('');
          setNewSkillCategory('');
          setSkillDescriptionInput('');  
  
          spinnerService.removeSpinner();
        }
      })
  }

  function removeSkill(skill: any, accessToken: string) {    
    let skillId: number =  skill.id;
    let projectid: number = props.isDraft ? props.projectId : skill.project.id;
    onboardingManagerStore.deleteProjectSkill([skillId], projectid, accessToken)
      .then(()=> {
        return
      })
      .catch((error$: ErrorResponseDto)=> {
        if (error$.response.data.message === 'Unauthorized') {
          // Get new Access Token
          apiService.refreshToken(authStore.userData.refreshToken)
            .then((response$: any) => {
              authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
              removeSkill(skill, response$.data.accessToken);
            })
        }
        else {
          showNotification('warning', error$.response.data.message);
        }
      })
  }

  function CustomOption (props: any) {  
    const { data, innerRef, innerProps } = props;
    if (data.value == 'What is the closest skill') {
      return <div ref={innerRef} {...innerProps} className='closest-skill-description'>
                <span className='closest-skill'>What is the closest skill to the {capitalizeFirstLetter(inputValue)}:</span>              
              </div>
    }
    else if (data.value == 'No closest skill'){
      return <div ref={innerRef} {...innerProps} className='closest-skill-description'>
        <span className='closest-skill'>There is no closest skill to the {capitalizeFirstLetter(inputValue)}</span>
      </div>
    }
    else if (data.value == 'Search AI') {
      return <div ref={innerRef} {...innerProps} className='closest-skill-description' onClick={searchAISkills}>
                <span className='search-ai'>Don't see what you need? Search for other similar skills</span>            
              </div>
    }
    else if (data.value == 'create-new-skill') {
      return <div ref={innerRef} {...innerProps} className='closest-skill-description' 
              onClick={()=> {
                setNewSkillName(inputValue.trim());
                setInputAIValue('');
              }}>
                <span className='search-ai'>Create '{capitalizeFirstLetter(inputValue)}' as a new skill</span>            
              </div>
    }
    else {
      return <components.Option {...props} />   
    }
  }

  function formatOptionLabel (data: any) {
    return <div style={{ display: "block" }}>
      <span>{data.label}</span>
      {
        data.description &&
        <span>
          <Tooltip
            place="right"                                         
            tooltipId={data.value.toString()}  
            backgroundColor="#101319"
            borderColor='#5C5F6A !important'
            border={true}            
            content={
              <span>{data.description}</span>                                 
            }
            className='select-skill-description'
          >
            <span className='select-skill-description-symbol'><i className="fa-regular fa-circle-question"></i></span>
          </Tooltip>  
        </span>
      }
    </div>
  }

  function searchAISkills() {  
    setInputAIValue(inputValue);
  }

  function goToPrevSection() {
    props.changeSection('project-skills-section');
  }

  function goToNextSection() {
    let projectId = props.isDraft ? props.projectId : onboardingManagerStore.projectData.project?.id
    props.saveProgress(projectId, props.jobPosting ? 'finish-onboarding-section' : 'project-employees-section', userData.accessToken);
    if (props.clickedSaveAsDraft) {
      props.saved();
    }
    props.changeSection(props.jobPosting ? 'finish-onboarding-section' : 'project-employees-section');
  }

  function sortSkills(data: any) {
    data.sort((a: any, b: any) => {
      return ((a.synonym ? a.synonym.name : a.skill.name).toUpperCase() > (b.synonym ? b.synonym.name : b.skill.name).toUpperCase()) ? 
      1 : (((b.synonym ? b.synonym.name : b.skill.name).toUpperCase() > (a.synonym ? a.synonym.name : a.skill.name).toUpperCase()) ? -1 : 0)
    });
    return data;
  }

  function removeDuplicates(data: any) {
    const skillMap = new Map<number, boolean>();

    data.forEach((skill: any) => {
      if (skill.synonym) {
        skillMap.set(skill.skill.id, true);
      }
    }) // set skills with synonyms
    return data.filter((skill: any) => {
      if (skillMap.has(skill.skill.id)) {
        return skill.synonym;
      }
      return true;
    })
  }
}

export default SearchNeededSkillComponent;