import {Avatar} from "@mui/material";

const ItemsTemplate = ({fields}) => {
   // if field has projectEmployeeId then it is in the team, if not then he is candidate or learner
   const rows = fields.map((field) => (
      <div key={field.employeeId} className={`skill-tag-dragging ${field.projectEmployeeId ? 'project-skill' : ''}`}>
         <div>
            {field.signedUserProfilePicture ? <img src={field.signedUserProfilePicture} alt={'picture'}/> :
               <Avatar sx={{backgroundColor: field.color}}
                       className={'img'}>{field.employeeName.slice(0, 1).toUpperCase()}</Avatar>}
         </div>
         <div className="field-info">
            <div className="field-title">{field.employeeName}</div>
            <div className={'field-projects'}>
               <div>{field.projectAvailability}</div>
               <div className="field-hours">projects</div>
            </div>
         </div>
         {
            field.projectEmployeeId &&
            <>
               <div className={"field-learn"}>{field.skillsToLearn ? field.skillsToLearn.length : field.type === 'LEARNER' ? coveredSkills.length : 0}</div>
               <div className="field-skills">{field.skills ? field.skills.length : (field.type === 'CANDIDATE' || field.type === 'SIMILAR_PEOPLE') ? coveredSkills.length : 0}</div>
            </>

         }
         {
            !field.projectEmployeeId && field.coveredSkills &&
            <div className="field-skills">{field.coveredSkills.length}</div>
         }
      </div>
   ));

   return <div>{rows}</div>;
};

export default ItemsTemplate;