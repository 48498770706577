import './UpdatesComponent.scss';

const UpdatesComponent = () => {
	return (
		<>
			<div className='update-card'>
				<div className='card-wrapper'>
					<div className='icon-wrapper'>
						<i className="fa-solid fa-lock"></i>
					</div>
					<h3>TeamLift is preparing more for your career...</h3>
					<p>Please, wait for the updates</p>
				</div>
			</div>
		</>
	)
}

export default UpdatesComponent;