import ReactDOM from "react-dom/client";
import { BrowserRouter } from 'react-router-dom';

import "./styles/styles.scss";

import { AuthContextProvider } from './store/auth-context';
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("app-root") as HTMLElement);
root.render(
	<BrowserRouter>
		<AuthContextProvider>
			<App />
		</AuthContextProvider>
	</BrowserRouter>
);