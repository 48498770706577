import React, {MouseEventHandler} from "react";
import styles from './SkillEmployeesModal.module.scss';
import {
   EmployeeInfoModal,
   EmployeeModalData,
   EmployeeType,
   NeededSkill
} from "../../../../../../../../../types/NeededPeopleData";
import {buildStyles, CircularProgressbar, CircularProgressbarWithChildren} from "react-circular-progressbar";
import {Avatar} from "@mui/material";
import RadialSeparators from "../../../../../../../../../utils/RadialSeparators";

const SkillEmployeesModal: React.FC<{ data: EmployeeModalData, closeModal: MouseEventHandler<HTMLDivElement> }> = props => {

   return (
      <>
         <div className={styles['skill-employees-modal']}>
            <div className="card card-modal-employees">
               <div className='card-header'>
                  <h2 className={'needed-people-title'}>All candidates</h2>
                  <div onClick={props.closeModal} className='close-modal-button'>
                     <i className="fa-solid fa-xmark"></i>
                  </div>
               </div>
               <div className="card-body">
                  {
                     props.data.allEmployees.neededSkillEmployees.length > 0 &&
                     <>
                        <div className={'employees-title'}>Employee from skill</div>
                        {
                           props.data.allEmployees.neededSkillEmployees.map(employee => {
                              return (
                                 <div className={'employee-card'} key={employee.employeeId}>
                                    {
                                       employee.status === 'NEW' || employee.status === "APPROVED" ?
                                          <div className={`employee-image-container`}>
                                             <CircularProgressbar
                                                value={+employee.totalValidationScore}
                                                strokeWidth={8}
                                                styles={buildStyles({
                                                   pathColor: employee.unique_owner ? '#F3B24D' : '#A0A8E5',
                                                   trailColor: '#191D24',
                                                   strokeLinecap: "butt"
                                                })}/>
                                             <div
                                                className={'image-container'}>
                                                {employee.signedUserProfilePicture ?
                                                   <img src={employee.signedUserProfilePicture}
                                                        alt="picture"/> : <Avatar
                                                      sx={{backgroundColor: employee.color}}
                                                      className={'avatar'}>{employee.employeeName.slice(0, 1).toUpperCase()}</Avatar>}
                                             </div>
                                          </div> :
                                          <div
                                             className={`employee-image-container`}>
                                             <CircularProgressbarWithChildren
                                                value={100}
                                                strokeWidth={8}
                                                styles={buildStyles({
                                                   pathColor: '#191D24',
                                                   trailColor: '#191D24',
                                                   strokeLinecap: "butt"
                                                })}>
                                                <RadialSeparators
                                                   count={12}
                                                   style={{
                                                      background: "#fff",
                                                      width: "5px",
                                                      // This needs to be equal to props.strokeWidth
                                                      height: `${9}%`
                                                   }}
                                                />
                                             </CircularProgressbarWithChildren>
                                             <div
                                                className={'image-container'}>
                                                {employee.signedUserProfilePicture ?
                                                   <img src={employee.signedUserProfilePicture}
                                                        alt="picture"/> : <Avatar sx={{backgroundColor: employee.color}} className={'avatar'}>{employee.employeeName.slice(0, 1).toUpperCase()}</Avatar>}
                                             </div>
                                          </div>
                                    }
                                    <div className={'employee-name'}>{employee.employeeName}</div>
                                 </div>
                              )
                           })
                        }
                     </>
                  }

                  {
                     props.data.allEmployees.employeeSelectedList.length > 0 &&
                     <>
                        <div className={'employees-title'}>Selected employee</div>
                        {
                           props.data.allEmployees.employeeSelectedList.map(employee => {
                              return (
                                 <div className="employee-card" key={employee.employeeId}>
                                    {
                                       employee.type === EmployeeType.CANDIDATE ?
                                          <div
                                             className={`employee-image-container selection employee-selected`}>
                                             <CircularProgressbar
                                                value={findValidationScore(employee, props.data.skill)}
                                                strokeWidth={8}
                                                styles={buildStyles({
                                                   pathColor: employee.coveredSkills.filter((cs: any) => cs.skill_id === props.data.skill.skillId)[0].unique_owner ? '#F3B24D' : '#A0A8E5',
                                                   trailColor: '#191D24',
                                                   strokeLinecap: "butt"
                                                })}/>
                                             <div
                                                className={'image-container'}>
                                                {employee.signedUserProfilePicture ?
                                                   <img src={employee.signedUserProfilePicture}
                                                        alt="picture"/> : <Avatar
                                                      sx={{backgroundColor: employee.color}}
                                                      className={'avatar'}>{employee.employeeName.slice(0, 1).toUpperCase()}</Avatar>}
                                             </div>
                                          </div> :
                                          <div
                                             className={`employee-image-container selection employee-selected`}>
                                             <CircularProgressbarWithChildren
                                                value={100}
                                                strokeWidth={8}
                                                styles={buildStyles({
                                                   pathColor: '#191D24',
                                                   trailColor: '#191D24',
                                                   strokeLinecap: "butt"
                                                })}>
                                                <RadialSeparators
                                                   count={12}
                                                   style={{
                                                      background: "#fff",
                                                      width: "5px",
                                                      // This needs to be equal to props.strokeWidth
                                                      height: `${9}%`
                                                   }}
                                                />
                                             </CircularProgressbarWithChildren>
                                             <div
                                                className={'image-container'}>
                                                {employee.signedUserProfilePicture ?
                                                   <img src={employee.signedUserProfilePicture}
                                                        alt="picture"/> : <Avatar
                                                      sx={{backgroundColor: employee.color}}
                                                      className={'avatar'}>{employee.employeeName.slice(0, 1).toUpperCase()}</Avatar>}
                                             </div>
                                          </div>
                                    }
                                    <div className={'employee-name'}>{employee.employeeName}</div>
                                 </div>
                              )
                           })
                        }
                     </>
                  }

                  {
                     props.data.allEmployees.selectedSkillEmployeeList.length > 0 &&
                     <>
                        <div className={'employees-title'}>Selected skill employees</div>
                        {
                           props.data.allEmployees.selectedSkillEmployeeList.map(employee => {
                              return (
                                 <div className="employee-card" key={employee.employeeId}>
                                    {
                                       employee.type === 'CANDIDATE' ?
                                          <div
                                             className={`employee-image-container selection skill-selected`}>
                                             <CircularProgressbar
                                                value={findValidationScore(employee, props.data.skill)}
                                                strokeWidth={8}
                                                styles={buildStyles({
                                                   pathColor: employee.coveredSkills.filter((cs: any) => cs.skill_id === props.data.skill.skillId)[0].unique_owner ? '#F3B24D' : '#A0A8E5',
                                                   trailColor: '#191D24',
                                                   strokeLinecap: "butt"
                                                })}/>
                                             <div
                                                className={'image-container'}>
                                                {employee.signedUserProfilePicture ?
                                                   <img src={employee.signedUserProfilePicture}
                                                        alt="picture"/> : <Avatar
                                                      sx={{backgroundColor: employee.color}}
                                                      className={'avatar'}>{employee.employeeName.slice(0, 1).toUpperCase()}</Avatar>}
                                             </div>
                                          </div> :
                                          <div
                                             className={`employee-image-container selection skill-selected`}>
                                             <CircularProgressbarWithChildren
                                                value={100}
                                                strokeWidth={8}
                                                styles={buildStyles({
                                                   pathColor: '#191D24',
                                                   trailColor: '#191D24',
                                                   strokeLinecap: "butt"
                                                })}>
                                                <RadialSeparators
                                                   count={12}
                                                   style={{
                                                      background: "#fff",
                                                      width: "5px",
                                                      // This needs to be equal to props.strokeWidth
                                                      height: `${9}%`
                                                   }}
                                                />
                                             </CircularProgressbarWithChildren>
                                             <div
                                                className={'image-container'}>
                                                {employee.signedUserProfilePicture ?
                                                   <img src={employee.signedUserProfilePicture}
                                                        alt="picture"/> : <Avatar
                                                      sx={{backgroundColor: employee.color}}
                                                      className={'avatar'}>{employee.employeeName.slice(0, 1).toUpperCase()}</Avatar>}
                                             </div>
                                          </div>
                                    }
                                    <div className={'employee-name'}>{employee.employeeName}</div>
                                 </div>
                              )
                           })
                        }
                     </>
                  }
               </div>
            </div>
         </div>
      </>
   )

   function findValidationScore(employee: any, skill: NeededSkill): number {
      return +employee.coveredSkills.find((cs: any) => cs.skill_id === skill.skillId).total_validation_score;
   }

}

export default SkillEmployeesModal;