import React, { useState, useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './SkillsGrowthPage.module.scss';
import useLoadingSpinner from '../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner';
import useApiService from '../../../../services/api.service';
import AuthContext from '../../../../store/auth-context';
import axios, {AxiosRequestConfig} from 'axios';
import { showNotification } from '../../../../ui/Toast/ToastNotification';
import { capitalizeFirstLetter } from '../../../../utils/capitalize-first-letter';
import SelectSkillsComponent from './SelectSkillsComponent/SelectSkillsComponent';
import SkillsGrowthChartComponent from './SkillsGrowthChartComponent/SkillsGrowthChartComponent';
import {ErrorResponseDto} from "../../../../types/ErrorData";

type Skill = { value: number, label: string, description: string };

const SkillsGrowthPage: React.FC = () => {
  const authStore = useContext(AuthContext);
  const { refreshToken } = useApiService();
  const spinnerService = useLoadingSpinner();

  const loadSkillsOptionsURL = process.env.REACT_APP_PUBLIC_URL + '/employee/skill-growth';
  let getChartDataURL = process.env.REACT_APP_PUBLIC_URL + '/employee/skill-growth/history';
  
  const [ skillsOptions, setSkillsOptions ] = useState<{}[]>([]);
  const [ chartData, setChartData ] = useState<any>([]);
  const [ initializedComponent, setInitializedComponent ] = useState<boolean>(false)

  useEffect(()=> {
    authStore.changePageTitle("Skill Growth");
    getSkillsOption(authStore.userData.accessToken);
  }, [])
  
  return (
    <>
      { initializedComponent && 
        <div className={styles['skills-growth-component']}>
          <div className="container-fluid">
            <div className='row'>
              <div className='col-12'>
                <h1 className={'header-1 u-margin-bottom-xxs'}>Skill Growth Overview</h1>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                {
                  skillsOptions.length !== 0 ? <SelectSkillsComponent selectOptions={skillsOptions} getChartData={getChartData}/> : 
                  <>
				            <p className='text-secondary u-margin-bottom-s'>We haven’t extracted any skills for you yet. Start populating your wallet by sharing some data with our AI.</p>
								<NavLink to={'/file/personal-data-types'} className="button button-primary upload-personal-data-button">Upload Personal Data</NavLink>
                  </>                  
                }                
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
                {
                  <SkillsGrowthChartComponent rawData={chartData}/>
                }
              </div>
            </div>
          </div>
        </div>
      }
      {
				spinnerService.spinner
			}    
    </>
  )

  function getSkillsOption(accessToken: string) {
		spinnerService.createSpinner();
	  const headers: AxiosRequestConfig['headers'] = {
		  'Authorization': `Bearer ${accessToken}`
	  }

    axios
			.get(loadSkillsOptionsURL, {headers}
			)
			.then((res$: any) => { 
        let data: any[] = res$.data.map((skill: any) => {
          return {value: skill.id, label: capitalizeFirstLetter(skill.name), description: skill.description}
        })
        setSkillsOptions(data);
        setInitializedComponent(true);
				spinnerService.removeSpinner();
			})
			.catch((error$: ErrorResponseDto) => {
				if (error$.response.data.message === 'Unauthorized') {					
					// Get new Access Token
					refreshToken(authStore.userData.refreshToken)
						.then((response$: any) => {						
							authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
							getSkillsOption(response$.data.accessToken);
						})
				}
				else {	
					showNotification('warning', error$.response.data.message);          
          setInitializedComponent(true);
          spinnerService.removeSpinner();
				}	
			});
	}

  function getChartData(accessToken: string, selectedSkills: Skill[]) {
		spinnerService.createSpinner();
    const selectedSkillIds: number[] = selectedSkills.map(skill => skill.value);

    const data = {
      skillIds: selectedSkillIds
    }
	  const headers: AxiosRequestConfig['headers'] = {
		  'Authorization': `Bearer ${accessToken}`
	  }

    axios
			.post(getChartDataURL, data, {headers}
			)
			.then((res$: any) => {
        setChartData([...res$.data]);
				spinnerService.removeSpinner();
			})
			.catch((error$: ErrorResponseDto) => {
				if (error$.response.data.message === 'Unauthorized') {					
					// Get new Access Token
					refreshToken(authStore.userData.refreshToken)
						.then((response$: any) => {						
							authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
							getChartData(response$.data.accessToken, selectedSkills);
						})
				}
				else {	
					showNotification('warning', error$.response.data.message);          
          spinnerService.removeSpinner();
				}	
			});
	}
}

export default SkillsGrowthPage;