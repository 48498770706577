import { useState, useRef } from 'react';
import ReactDOM from 'react-dom';

import styles from './ChangePasswordModal.module.scss';

import SpinnerButton from '../../../../../../../ui/SpinnerButton/SpinnerButton';

const ChangePasswordModal: React.FC<{okCallback: Function, 
  removeModal: React.MouseEventHandler<HTMLButtonElement>, dataIsLoading: boolean }> = (props) => {  
  
  const password = useRef<any>();
	const confirmPassword = useRef<any>();
  const [errorMessagge, setErrorMessagge] = useState(false);
  const [typePassword, setTypePassword] = useState(true)
  const [typeConfirmPassword, setTypeConfirmPassword] = useState(true)
  
  let modal = (
    <div className={styles['change-password-modal']}>
      <div className='card card-modal'>
        <div className='card-header'>
          <h2>Change password</h2>
          <div className='close-modal-button'>
            <i className="fa-solid fa-xmark" onClick={props.removeModal}></i>            
          </div>  
        </div>
        <div className="card-body">
          <label htmlFor="password" className="form-label">New password</label>
          <div className="mb-4 input-wrapper">
            <input onKeyDown={onEnterDownHandler} type={typePassword ? 'password' : 'text'} className={`form-control `} id="password" ref={password} autoComplete='off' autoFocus />
            <span onClick={toggleShowPassword}><i className={typePassword ? 'fa-solid fa-eye' : 'fa-solid fa-eye-slash'}></i></span>
          </div>
          <label htmlFor="confirm-password" className="form-label">Confirm new password</label>
          <div className="mb-4 input-wrapper">
            <input onKeyDown={onEnterDownHandler} type={typeConfirmPassword ? 'password' : 'text'} className={`form-control `} id="confirm-password" ref={confirmPassword} autoComplete='off' />
            <span onClick={toggleShowConfirmPassword}><i className={typeConfirmPassword ? 'fa-solid fa-eye' : 'fa-solid fa-eye-slash'}></i></span>
          </div>
          {
              errorMessagge && <p className="error-message mt-3">New Password and Confirm New Password don't match</p>
          }
        </div>
        <div className="card-footer">
          <div className='buttons-wrapper'>
            <button className='button button-secondary u-margin-right-xs' onClick={props.removeModal}>Cancel</button>
            <SpinnerButton cssClasses='button button-primary' title='Save password'
            dataLoading={props.dataIsLoading} buttonType='button' onClickCallback={changeDisplayNameHandler}></SpinnerButton>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    ReactDOM.createPortal(modal, document.getElementById('modal-root') as HTMLDivElement)
  )

  function toggleShowConfirmPassword() {
    setTypeConfirmPassword((prevState) => {
      return !prevState;
    })
  }
  function toggleShowPassword() {
    setTypePassword((prevState) => {
      return !prevState;
    })
  }

  function onEnterDownHandler(e : any) {
    if (e.key === 'Enter') {
      (document.getElementsByClassName('submit-button')[0] as HTMLButtonElement).click();
    }
  }

  function changeDisplayNameHandler() {
    let formIsValid: boolean = true;
    password.current?.classList.remove('error');
    confirmPassword.current?.classList.remove('error');
    document.getElementsByClassName('input-wrapper')[0].classList.remove('error');
    document.getElementsByClassName('input-wrapper')[1].classList.remove('error');
    setErrorMessagge(false);  

    if (password.current.value.trim().length === 0) {     
      document.getElementsByClassName('input-wrapper')[0].classList.add('error');
      formIsValid = false;
    }
    if (confirmPassword.current.value.trim().length === 0) {
      confirmPassword.current?.classList.add('error');
      document.getElementsByClassName('input-wrapper')[1].classList.add('error');
      formIsValid = false;
    }
    if (formIsValid && password.current.value.trim() !== confirmPassword.current.value.trim()) {
      formIsValid = false;
      setErrorMessagge(true);
    }

    if (formIsValid) {  
      props.okCallback(password.current.value.trim());
    }
  }
}

export default ChangePasswordModal;