import {animated, useSpring} from "react-spring";
import React from "react";
import style from './EmployeeFinishPage.module.scss';
import SpinnerButton from "../../../../../ui/SpinnerButton/SpinnerButton";

const EmployeeFinishPage: React.FC<{
   goBack: () => void,
   finish: () => void,
   dataLoading: boolean
}> = props => {

   const FadeIn = ({ children, delay, className }: { children: React.ReactNode; delay: number, className?: string }) => {
      const fadeStyles = useSpring({
         from: { opacity: 0 },
         to: { opacity: 1 },
         config: { duration: 1000 },
         delay: delay,
      });

      return <animated.div className={className || ""} style={fadeStyles}>{children}</animated.div>;
   };

   return (
      <>
         <div className={style['employee-finish-page']}>
            <FadeIn delay={500}>
               <h3 className="header-3 u-margin-bottom-m">Congratulations on completing your onboarding! 🎉</h3>
            </FadeIn>
            <FadeIn delay={1000} className={"info-text"}>
               <p>Click 'Finish' to receive your AI Impact Report and see how you can supercharge your skills!</p>
            </FadeIn>
            <FadeIn delay={1500} className={"u-margin-top-xl"}>
               <div className="buttons-wrapper">
                  <button className={`button button-secondary`} onClick={props.goBack}><i className="fa-solid fa-arrow-left-long"></i><span>Back</span></button>
                  <SpinnerButton title={'Finish'} cssClasses={`button button-primary`} onClickCallback={props.finish} dataLoading={props.dataLoading} />
               </div>
            </FadeIn>
         </div>
      </>
   );
}

export default EmployeeFinishPage;