import React, { useState, useEffect, useRef, useContext } from "react";
import {TabulatorFull as Tabulator} from "tabulator-tables"; 
import ProjectsContext from '../../../../../../../../store/projects-context';
import AuthContext from '../../../../../../../../store/auth-context';
import useLoadingSpinner from "../../../../../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner";
import ReactTooltip from "react-tooltip";
import styles from './ProjectEmployeesGrid.module.scss';
import {UserData} from "../../../../../../../../types/AuthData";
import useModal from "../../../../../../../../services/modal.service";
import DeleteEmployeeModal from "./DeleteEmployeeModal/DeleteEmployeeModal";
import {showNotification} from "../../../../../../../../ui/Toast/ToastNotification";
import useApiService from "../../../../../../../../services/api.service";
import {ProjectDto} from "../../../../../../../../types/Projects";
import {ErrorResponseDto} from "../../../../../../../../types/ErrorData";


const ProjectEmployeesGrid: React.FC<{projectData: ProjectDto, addEditComponent: Function, managers: string[]}> = (props) => {
  let projectsStore = useContext(ProjectsContext);
  let authStore = useContext(AuthContext);
  const spinnerService = useLoadingSpinner();

  const gridRef = useRef<any>();
  const grid = useRef<any>();
  const [projectEmployeesData, setProjectEmployeesData] = useState(projectsStore.projectEmployeesData);
  const deleteModal = useModal()
  const { refreshToken } = useApiService();

  const gridColumnsDefinition: any = [
    { title: "", field: "rowNumber", formatter: "rownum", headerSort: false },
    { title: "Id", field: "id", visible: false},
    { title: "Name", field: "firstName", minWidth: 200, headerSortTristate: true},
    { title: "Surname", field: "lastName", minWidth: 200, headerSortTristate: true },
    { title: "Email", field: "email", minWidth: 200, headerSortTristate: true},
    { title: "JobTitle", field: "jobTitle", minWidth: 200, headerSortTristate: true},
    // { title: "Work availability", field: "workAvailability", minWidth: 200, headerSortTristate: true},
    // { title: "Learning availability", field: "learningAvailability", minWidth: 200, headerSortTristate: true},
    { title: "TeamLead", field: "teamLead", minWidth: 200,  sorter:"boolean",  headerSortTristate: true, editor: 'tickCross',
      cellClick: (e: any, cell: any)=> {        
        let cellElement = cell.getElement() as HTMLDivElement;
        let checkbox = cellElement.querySelector('.checkbox-wrapper') as HTMLDivElement;
        if (checkbox) {
          checkbox.click();
        }
      },
      editable: ((cell : any) => {
        let flag = true;
        let data = cell.getData().email;
        props.managers.forEach((manager: string) => {
          if (manager === data) {
            flag = false
          }
        })
        return flag;
      }),
      titleFormatter: (column: any) => {
        const title = column.getValue();
        let wrapper = document.createElement('span');        
        
        let tooltip = document.createElement('i'); 
        tooltip.classList.add('fa-regular');
        tooltip.classList.add('fa-circle-question');
        tooltip.style.marginLeft = '4px'; 
        tooltip.style.cursor = 'pointer';   
        tooltip.setAttribute('data-tip', 
          `<div class='teamlead-tooltip'>
            <h2>Who is Team Lead?</h2>
            <p>The Manager chooses Team Leads during the project lifecycle.</p>
            <p>Their responsibilities:</p>
            <div class='list-wrapper'>
              <div class="list-item">
                <div class="icon-container"><i class="fa-solid fa-arrow-up-from-bracket"></i></div>
                <div class="content"><p>Upload <strong>dynamic data</strong> for their team from Jira, Webflow, TeamViewer, etc</p></div>
              </div>
              <div class="list-item">
                <div class="icon-container"><i class="fa-regular fa-eye"></i></div>
                <div class="content"><p>View the <strong>manager dashboard</strong> with extracted, missing and approved skills</p></div>
              </div>
            </div>
          </div>`
        ); 
        tooltip.setAttribute('data-for', 'team-lead'); 
        tooltip.setAttribute('data-html', 'true');    4   
        setTimeout(()=> {
          ReactTooltip.rebuild();
        }, 0)
        wrapper.append(title);
        wrapper.append(tooltip);
        return wrapper;  
      },
      formatter: (cell: any) => {
        let flag = false;
        props.managers.forEach((manager: string) => {
          if (manager === cell.getData().email) {
            flag = true
          }
        })

        if (flag) {
          return null;
        }

        let data = cell.getValue();
        let checkboxWrapper: HTMLDivElement = document.createElement("div");       
        checkboxWrapper.classList.add("checkbox-wrapper");
        if (!data) {
          checkboxWrapper.innerHTML = '<i class="fa-regular fa-square"></i>';
        }
        else {
          checkboxWrapper.innerHTML = '<i class="fa-regular fa-square-check"></i>';
        }
        checkboxWrapper.addEventListener('click', (e)=> {
          e.stopPropagation();
          cell.setValue(!cell.getValue());
        })
        return checkboxWrapper;
      }
    },
  ]

  useEffect(() => {
    setProjectEmployeesData(projectsStore.projectEmployeesData);
  }, [projectsStore.projectEmployeesData]);

  useEffect(()=> {
    let gridData: any = [];
 
    for (let employee of projectEmployeesData as any) {    
      gridData.push({
        id: employee.id,
        firstName: employee.employee.name,
        lastName: employee.employee.surname,
        email: employee.email,
        jobTitle: employee.position,
        // workAvailability: employee.availability,
        // learningAvailability: employee.learningAvailability,
        teamLead: employee.teamLead,
      })
    }
    
    grid.current = new Tabulator(gridRef.current, {
      data: JSON.parse(JSON.stringify(gridData)),
      columns: gridColumnsDefinition,
      headerSortClickElement:"icon",
      layout:"fitDataStretch",      
      pagination: true,
      paginationSize: 8, 
      langs:{
        "default":{
            "pagination":{
              "prev": "<",
              "next": ">",
            },
        }
      },     
      headerSortElement: function(column, dir) {
        switch (dir) {
          case "asc":
            return "<span class='material-symbols-outlined'>sort</span>";    
          case "desc":
            return "<span class='material-symbols-outlined'>sort</span>";     
          default:
            return "<span class='material-symbols-outlined'>sort</span>"; 
        }
      }
    });

    grid.current.on("cellEdited", function(cell: any) {      
      let data = cell.getData();
      let employeeTempData = JSON.parse(JSON.stringify(projectEmployeesData));
      let employee = employeeTempData.find((item: any)=> item.id === data.id);
      
      if (employee) {    
        spinnerService.createSpinner();    
        
        if (data.teamLead === true) {
          employee.teamLead = true;
          assignTeamLead(employee,authStore.userData.accessToken);
        }
        else {
          // Remove
          employee.teamLead = false;
          removeTeamLead(employee,authStore.userData.accessToken);
        }
      }
    });
  }, [projectEmployeesData])

  function assignTeamLead(employee : any, accessToken : string){
    projectsStore.assignProjectTeamLeads([employee.id], props.projectData.id, accessToken)
        .then(()=> {
          spinnerService.removeSpinner();
        })
        .catch((error$ : ErrorResponseDto)=> {
          if (error$.response.data.message === 'Unauthorized') {
            // Get new Access Token
            refreshToken(authStore.userData.refreshToken)
                .then((response$: any) => {
                  authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                  assignTeamLead(employee, response$.data.accessToken);
                })
          }
          else {
            spinnerService.removeSpinner();
            showNotification('warning', error$.response.data.message);
          }
        })
  }

  function removeTeamLead(employee: any, accessToken: string) {
    projectsStore.removeProjectTeamLeads([employee.id], props.projectData.id, accessToken)
        .then(()=> {
          spinnerService.removeSpinner();
        })
        .catch((error$: ErrorResponseDto)=> {
          if (error$.response.data.message === 'Unauthorized') {
            // Get new Access Token
            refreshToken(authStore.userData.refreshToken)
                .then((response$: any) => {
                  authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                  removeTeamLead(employee, response$.data.accessToken);
                })
          }
          else{
            spinnerService.removeSpinner();
            showNotification('warning', error$.response.data.message);
          }
        })
  }

  function openDeleteModal(data : any) {
    let modalData = {
      employeeData : data,
      projectData : props.projectData
    }
    deleteModal.createModal(modalData);
  }

  function removeProjectEmployee(projectEmployeeId: number, accessToken : string) {
    spinnerService.createSpinner();
    deleteModal.removeModal();
    projectsStore.deleteProjectEmployees(projectEmployeeId, props.projectData.id, accessToken)
        .then((resp$: any)=> {
          setProjectEmployeesData(resp$)
          spinnerService.removeSpinner();
        })
        .catch((error$: ErrorResponseDto)=> {
          if (error$.response.data.message === 'Unauthorized') {
            // Get new Access Token
            refreshToken(authStore.userData.refreshToken)
                .then((response$: any) => {
                  authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                  removeProjectEmployee(projectEmployeeId, response$.data.accessToken);
                })
          }
          else{
            spinnerService.removeSpinner();
            showNotification('warning', error$.response.data.message);
          }
        })
  }

  return (  
    <div className={styles['settings-people']}> 
      <div className='settings-people-header'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='header-2'>All employees: {projectEmployeesData.length}</h2>
          </div>
          <div className='col-12'>
            <div className='settings-people-commands'>
              <button className='button button-tertiary' onClick={()=> props.addEditComponent(true)}><i className="fa-solid fa-pen"></i> Edit employees</button>
            </div>
          </div>
        </div>
          <div className='row'>
            <div className='col-12'>
              <div className='employee-grid-wrapper'>
                <div id='employee-grid' ref={gridRef}></div>
              </div> 
            </div>
        </div>
      </div>
      {
        spinnerService.spinner
      }
      {
        deleteModal.showModal && <DeleteEmployeeModal data={deleteModal.modalData} okCallback={(data : any) => {removeProjectEmployee(data, authStore.userData.accessToken)}} cancelCallback={deleteModal.removeModal} />
      }
      <ReactTooltip id="team-lead" place="top" type="dark"  html={true} aria-haspopup='true' effect="solid" 
        className='tooltip-class' delayShow={200} delayHide={200}  />   
    </div> 
  )
}

export default ProjectEmployeesGrid;