import { useState, useContext } from 'react';
import {useNavigate} from 'react-router-dom';
import styles from './AddManuallySkillsComponent.module.scss';
import SelectComponent from './SelectComponent/SelectComponent';
import DescriptionComponent from './DescriptionComponent/DescriptionComponent';
import Tooltip from '../../../../../services/tooltip.service';
import axios, {AxiosRequestConfig} from 'axios';
import useLoadingSpinner from '../../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner';
import AuthContext from '../../../../../store/auth-context';
import useApiService from '../../../../../services/api.service';
import { PulseLoader } from 'react-spinners';
import useModal from '../../../../../services/modal.service';
import ConfirmModal from './ConfirmModal/ConfirmModal';
import { showNotification } from '../../../../../ui/Toast/ToastNotification';
import {capitalizeFirstLetter} from "../../../../../utils/capitalize-first-letter";

const AddManuallySkillsComponent: React.FC<{closeModal: Function}> = (props) => {
  const [addingMode, setAddingMode] = useState<string>('');
  const [skills, setSkills] = useState<any>([]);
  const [skillsAreExtracting, setSkillsAreExtracting] = useState<boolean>(false);
  
  const spinnerService = useLoadingSpinner();
  const authStore = useContext(AuthContext);
  const addManuallyAddedSkillsURL: string = process.env.REACT_APP_PUBLIC_URL + '/employee/save-employee-skills';
  const extraxtSkillsFromDescriptionURL: string = process.env.REACT_APP_PUBLIC_URL + '/employee/extract-employee-skills';
  const { refreshToken } = useApiService();
  const navigate = useNavigate();
  const modal = useModal();

  return (
    <div className={styles['add-manually-skills-component']}>
      <div className='card-modal'>
        <div className='modal-header'>
          <div className='modal-title'>Select or Extract Skills</div>
          <div className='modal-exit-button-wrapper'>
            <i className="fa-solid fa-xmark" onClick={closeModal}></i>
          </div>
        </div>
        <div className='modal-body'>
          <div className='commands-wrapper'>
            <button className={`btn ${addingMode === 'select' ? 'btn-primary-60' : 'btn-outline-primary-30'}`} onClick={()=> chooseAddingMode('select')}>Select a skill</button>
            <span className='ms-3 me-3'>or</span>
            <button className={`btn ${addingMode === 'description' ? 'btn-primary-60' : 'btn-outline-primary-30'}`} onClick={()=> chooseAddingMode('description')}>Extract skills from a task description</button>
          </div>
          <div className='added-skills-wrapper'>
            {
              skills.length > 0 &&
              <div className='adding-skills-section'>
                <div className='added-skills-title'>Skills we'll add to your skills wallet:</div>
                <div className='skills-tag-wrapper'>
                  { skills.map((item: any) => (                  
                    <span  key={item.value} id={item.value} className="skill-tag">
                      {capitalizeFirstLetter(item.label)}
                      <span>
                        <Tooltip
                          place="top"                                         
                          tooltipId={item.value.toString()}  
                          backgroundColor="#101319"
                          borderColor='#5C5F6A !important'
                          border={true}            
                          content={
                            <span>{item.description}</span>                                 
                          }
                          className='tag-skill-description'
                        >
                          <span className='select-skill-description-symbol'><i className="fa-regular fa-circle-question"></i></span>
                        </Tooltip>
                      </span>
                      <span style={{marginLeft: '12px'}} title="Remove skill">
                        <Tooltip
                          place="top"                                         
                          tooltipId={item.value.toString() + "_remove"}  
                          backgroundColor="#101319"
                          borderColor='#5C5F6A !important'
                          border={true}
                          content={
                            <span>Remove skill</span>                                 
                          }
                          className='tag-skill-description'
                        >
                          <i className="fa-solid fa-xmark" onClick={() => removeSkill(item)}></i> 
                        </Tooltip>                                 
                      </span>
                    </span>            
                    ))
                  }
                </div>
              </div> 
            }
            { skills.length === 0 &&
              <div className='added-skills-title'>No selected or extracted skills</div>
            }
          </div>
          <div className='inputs-wrapper'>
            {
              addingMode === 'select' && 
              <div className='select-wrapper'>
                <SelectComponent skillsToBeAdded={skills} addSkillFromSelect={addSkillFromSelect} />
              </div>              
            }
            {
              addingMode === 'description' && 
              <>
                { skillsAreExtracting ? 
                  <div className='generating-skills'>
                    AI is extracting skills
                    <PulseLoader
                      loading={true}
                      color='#9A9DA7'
                      size={7}
                      speedMultiplier={.8}
                      margin={8}
                      style={{ width: 'auto', display: 'inline-flex', alignItems: 'flex-end' }}
                    />
                  </div>
                  :
                  <DescriptionComponent extractSkillsFromDescription={extractSkillsFromDescription} />
                }
              </>              
            }
            <div className='submit-wrapper'>
              {
                skills.length > 0 && <button className={`btn btn-primary-60 pt-3 pb-3 ps-4 pe-4`} disabled={skillsAreExtracting} onClick={()=> addSkillsToTheWallet(authStore.userData.accessToken)}>Add skill(s) to your skills wallet</button>
              }
            </div>
          </div>
        </div>
      </div>
      {
        spinnerService.spinner
      }
      {
        modal.showModal && <ConfirmModal okCallback={()=> {}} cancelCallback={()=> modal.removeModal()} />
      }
    </div>
  )

  function chooseAddingMode (mode: string): void {
    if (skillsAreExtracting) {
      return
    }

    if (mode === addingMode) {
      setAddingMode('');
    }
    else {
      setAddingMode(mode);
    }
  }

  function closeModal(): void {
    // if (skills.length > 0) {
    //   // TODO Confirm modal
    //   modal.createModal();
    // }
    // else {
    //   props.closeModal('hide');
    // }
    props.closeModal('hide');
  }

  function addSkillFromSelect(skill: any) {
    setSkills([...skills, skill]);
  }

  function removeSkill (skill: any) {
    let newSkillsList: any = skills.filter((item: any) => item.value !== skill.value);
    setSkills(newSkillsList);
  }

  function addSkillsToTheWallet(accessToken: string) {
    spinnerService.createSpinner();

    let skillsIds: string[] = skills.map((item: any) => item.value);

    let payload = {
      extractedSkills: skillsIds
    }

    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }

    axios.post(addManuallyAddedSkillsURL, payload, {headers})
        .then((response$) => {
          navigate('/employee/skills-wallet');
        })
        .catch((error$) => {
          spinnerService.removeSpinner();
 
          if (error$.response.data.message === 'Unauthorized') {         
            // Get new Access Token
            refreshToken(authStore.userData.refreshToken)
              .then((response$: any) => {
                authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                addSkillsToTheWallet(response$.data.accessToken);
              })
          }
          else {
            showNotification('warning', error$.response.data.message);
          }
        });
  }
  
  function extractSkillsFromDescription (accessToken: string, description: string) {
    setSkillsAreExtracting(true);   

    let payload = {
      description: description
    }

    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }

    axios.post(extraxtSkillsFromDescriptionURL, payload, {headers})
        .then((response$: any) => {
          if (response$.data.length > 0) {
            let newSkills: any = [];

            response$.data.forEach((item: any)=> {
              let skillId: number = item.aiId;
              let skill: any = skills.find((skill: any) => skill.value === skillId);
              
              if (!skill) {
                newSkills.push({
                  value: item.aiId,
                  description: item.description,
                  label: item.name
                })
              }
            })
            if (newSkills.length > 0) {
              setSkills([...skills, ...newSkills]);
            }            
          }
          setSkillsAreExtracting(false);
        })
        .catch((error$) => {
          if (error$.response.data.message === 'Unauthorized') {         
            // Get new Access Token
            refreshToken(authStore.userData.refreshToken)
              .then((response$: any) => {
                authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                extractSkillsFromDescription(response$.data.accessToken, description);
              })
          }
          else {
            showNotification('warning', "We're currently experiencing difficulty extracting the skills from this text. Kindly enter another text or try again later.");
          }
        });
  }
}

export default AddManuallySkillsComponent;