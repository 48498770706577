import {toast} from 'react-toastify';
import styles from './ToastNotification.module.scss';

import {capitalizeFirstLetter} from '../../utils/capitalize-first-letter';

export const showNotification = (type: 'info' | 'warning' | 'success', content: string, title?: string, toastId?: any) => {

   let params: any = {
      className: `${styles['toast-notification']} ${styles[type]}`,
      theme: 'dark'
   }

   if (toastId) {
      params.toastId = toastId;
   }

   let newTitle = title;
   let newContent = content;

   if ((!title || title.length === 0) && (!content || content.length === 0)) {
      newTitle = 'Unknown error';
      newContent = 'Something went wrong. Please try again!';
   }

   toast(createNotificationContent(type, newContent, newTitle), {
      ...params
   })
};

function createNotificationContent(type: string, content: string, title?: string) {
   return (
      <div className='toast-wrapper'>
         <div className='icon-container'>
            {
               type === 'success' && <img src='/assets/images/toast-icons/success-icon.svg' alt="Success Icon"/>
            }
            {
               type === 'warning' && <img src='/assets/images/toast-icons/warning-icon.svg' alt="Warning Icon"/>
            }
            {
               type === 'info' && <img src='/assets/images/toast-icons/info-icon.svg' alt="Info Icon"/>
            }
         </div>
         <div className='content-wrapper'>
            {
               title && title.length > 0 && <h1>{capitalizeFirstLetter(title)}</h1>
            }
            <p>{capitalizeFirstLetter(content)}</p>
         </div>
      </div>
   )
}