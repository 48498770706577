import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import TestPage from "./TestPage/TestPage";
import ResultsPage from "./ResultsPage/ResultsPage";

const PersonaPage: React.FC = () => {

   return (
      <>
         <Routes>

            <Route path="/" element={<Navigate replace to='/persona/questions'></Navigate>}/>

            <Route path="/questions" element={<TestPage />} />

            <Route path="/results" element={<ResultsPage />} />

         </Routes>
      </>
   )
}

export default PersonaPage;