import {
   ApprovedStatus,
   SearchSkillsWalletDto,
   SetDetailJsonDto,
   SkillFilterOptions,
   SkillsListInformationDto
} from "../../../../../types/SkillsData";
import {SKILLS_SORT_TYPE} from "../../../../../enums/Skills.enum";
import React, {useContext, useEffect, useState} from "react";
import AuthContext from "../../../../../store/auth-context";
import styles from './SkillsComponent.module.scss';
import {Checkbox} from "@mui/material";
import FilterComponent from "../../../FilterComponent/FilterComponent";
import SkillCard from "../SkillCardComponent/SkillCard";
import Select, {components} from "react-select";
import {Sources} from "../../../../../utils/sources";
import {getMessages, SKILL_MESSAGES_TITLE} from "../../../../../utils/skill-messages";

const {Option} = components;
const IconOption = (props: any) => {
   return (
      <Option {...props}>
         <i className="fa-regular fa-gem" style={{'marginRight': '8px'}}></i>
         {props.data.label}
      </Option>
   )
};

const selectStyles = {
   container: (provided: {}) => ({
      ...provided,
      flex: "1",
      alignItems: 'center',
   }),
   control: (provided: {}) => ({
      ...provided,
      backgroundColor: "transparent",
      border: "1px solid #383A46FF",
      padding: '5px 10px',
      borderRadius: '4px',
   }),
   indicatorSeparator: (_provided: {}) => ({
      display: "none",
   }),
   dropdownIndicator: (_provided: {}) => ({
      display: "none",
   }),
   clearIndicator: (provided: {}) => ({
      ...provided,
      color: "#757575",
      with: "50px"
   }),
   input: (provided: {}) => ({
      ...provided,
      color: '#828892FF',
   }),
   placeholder: (provided: {}) => ({
      ...provided,
      color: "#828892",
      fontWeight: "400"
   }),
   menu: (provided: {}) => ({
      ...provided,
      backgroundColor: "#1C1B28",
      zIndex: 1000000
   }),
   option: (provided: any, _data: any) => ({
      ...provided,
      color: "#B3BAC3",
      transition: '0.5s background-color, 0.5s color',
      ':hover': {
         backgroundColor: '#383A46',
         color: '#F1F6FB',
         cursor: 'pointer'
      }
   }),
   multiValue: (provided: {}) => ({
      ...provided,
      backgroundColor: "#383A46",
      border: "1px solid #626872",
      color: "#B3BAC3",
      marginRight: "6px"
   }),
   multiValueLabel: (provided: {}) => ({
      ...provided,
      color: '#B3BAC3'
   }),
   multiValueRemove: (provided: {}, _state: {}) => ({
      ...provided,
      color: 'white',
      transition: '0.5s color',
      backgroundColor: 'transparent !important',
      ':hover': {
         color: '#B3BAC3'
      }
   }),
};

const SkillsComponent: React.FC<{
   skillList: SetDetailJsonDto[],
   addDenySkill: any,
   returnSkill: any,
   filterOptions: SkillFilterOptions[],
   skillsType: ApprovedStatus,
   searchSkills: Function,
   searchData?: SearchSkillsWalletDto,
   skillListInformation?: SkillsListInformationDto
}> = (props) => {

   // Sort
   const [sortType, setSortType] = useState(SKILLS_SORT_TYPE.NEWEST as string);
   let skillsList = sortSkills(props.skillList);
   const authStore = useContext(AuthContext);
   const [checkedSkillsState, setCheckedSkillsState] = useState(new Map<number, boolean>());
   const [checkedBState, setCheckedBState] = useState<boolean>(false);
   const [indeterminateBState, setIndeterminateBState] = useState<boolean>(false);
   const [filterNameState, setFilterNameState] = useState('');
   const [filterSourceState, setFilterSourceState] = useState<{
      value: string,
      label: string,
      description: string
   }[]>([]);


   useEffect(() => {
      const mappedSkills = new Map<number, boolean>(
         skillsList.map(item => [item.skill.id, false])
      );
      setCheckedSkillsState(mappedSkills);
   }, [props.skillList]);

   useEffect(() => {
      const skillValues = Array.from(checkedSkillsState.values());
      const checkedCount = skillValues.filter(Boolean).length;
      setCheckedBState(checkedCount === skillValues.length); // if all checked then the list sizes will be the same
      setIndeterminateBState(checkedCount > 0 && checkedCount < skillValues.length); // if some are selected then it will be greater than 0 and smaller than the whole size of the array
   }, [checkedSkillsState]);

   useEffect(() => {
      if (!props.searchData) {
         setFilterNameState('');
         setFilterSourceState([]);
      }
   }, [props.searchData]);

   return (
      <>
         <div className={styles['skills-component']}>
            {skillsList.length > 0 ?
               <div className='accordion-wrapper'>
                  <div className="heading-wrapper">
                     {
                        props.skillsType !== ApprovedStatus.LEARNING &&
                        <>
                           <Checkbox
                              disableRipple={true}
                              sx={{
                                 transition: 'all 0.5s',
                                 backgroundColor: 'transparent',
                                 color: '#50545FFF',
                                 '&:hover, &:active, &:active:hover, &:hover:active': {
                                    backgroundColor: 'transparent',
                                    color: '#626872FF',
                                 },
                                 '&.Mui-checked': {
                                    color: '#A0A8E5FF',
                                    backgroundColor: 'transparent',
                                    '&:hover, &:active, &:active:hover, &:hover:active': {
                                       backgroundColor: 'transparent',
                                       color: '#C6CAEFFF',
                                    },
                                 },
                                 '&.MuiCheckbox-indeterminate': {
                                    color: '#A0A8E5FF',
                                    backgroundColor: 'transparent',
                                    '&:hover, &:active, &:active:hover, &:hover:active': {
                                       backgroundColor: 'transparent',
                                       color: '#C6CAEFFF',
                                    },
                                 },
                              }}
                              checked={checkedBState}
                              indeterminate={indeterminateBState}
                              onChange={checkButtonHandler}
                           />

                           <button onClick={() => getSelectedFunction(1)}
                                   className={`button button-tertiary ${props.skillsType === ApprovedStatus.NEW ? 'u-margin-right-s' : ''} ${Array.from(checkedSkillsState.values()).filter(item => item).length === 0 ? 'no-skills' : ''}`}>
                              {
                                 props.skillsType === ApprovedStatus.NEW ? 'Accept' :
                                    props.skillsType === ApprovedStatus.REJECTED ? 'Undo' :
                                       props.skillsType === ApprovedStatus.APPROVED ? 'Remove' : ''
                              }
                              <span
                                 className={'u-margin-left-xxs'}>{Array.from(checkedSkillsState.values()).filter(item => item).length}</span>
                           </button>
                           {
                              props.skillsType === ApprovedStatus.NEW &&
                              <button onClick={() => getSelectedFunction(2)}
                                      className={`button button-tertiary ${Array.from(checkedSkillsState.values()).filter(item => item).length === 0 ? 'no-skills' : ''}`}>Deny
                                 <span
                                    className={'u-margin-left-xxs'}>{Array.from(checkedSkillsState.values()).filter(item => item).length}</span>
                              </button>
                           }
                        </>
                     }
                     <FilterComponent sort={setSort} id='SkillsSortButton' filterOptions={props.filterOptions}/>
                  </div>

                  <div className={'filter-row'}>
                     <div className="filter-name">
                        <div className="wrapper-filter-name">
                           <i className="fa-solid fa-magnifying-glass"></i>
                           <input className={'filter-name-input form-control'} onKeyDown={checkEnterKey} type="text"
                                  onChange={filterNameStateHandler} value={filterNameState}
                                  placeholder={'Search skill by name'}/>
                        </div>
                     </div>

                     {
                        props.skillsType !== ApprovedStatus.LEARNING &&
                        <div className="search-input-container">
                           <Select
                              options={Sources}
                              className="search-input"
                              classNamePrefix="select"
                              isClearable={true}
                              isSearchable={true}
                              isMulti={true}
                              placeholder="Search skill by source"
                              value={filterSourceState}
                              onKeyDown={checkEnterKey}
                              noOptionsMessage={(value) =>
                                 value.inputValue === "" ? "Start typing" : "No options"
                              }
                              onChange={(options: any) => {
                                 setFilterSourceState(options);
                              }}
                              styles={selectStyles}
                              theme={(theme) => ({
                                 ...theme,
                                 borderRadius: 0,
                                 colors: {
                                    ...theme.colors,
                                    primary25: "#2D2F38",
                                    primary: "#757575",
                                 },
                              })}
                              components={{Option: IconOption}}
                           />
                        </div>
                     }
                     <button className={'button button-secondary u-margin-left-s'}
                             onClick={() => props.searchSkills(filterNameState, filterSourceState)}>Search
                     </button>
                     {
                        props.searchData &&
                        <button className={'button button-tertiary u-margin-left-s'}
                                onClick={() => props.searchSkills()}>Remove filters</button>
                     }
                  </div>

                  <div id='skills-accordion-content'>
                     <div className={'skill-list-wrapper'}>
                        {
                           props.skillList.length > 0 ? skillsList.map(item => {
                                 return (
                                    <div key={item.skill.id} className="col-12">
                                       <SkillCard
                                          type={item.approved}
                                          id={item.skill.id}
                                          title={item.skill.name}
                                          addDenySkill={getSkillCardFunction()}
                                          descriptors={item.descriptors}
                                          description={item.skill.description}
                                          isNewSkill={item.isNewSkill}
                                          checkSkill={checkSkills}
                                          checked={checkedSkillsState.get(item.skill.id) ? true : false}
                                          totalValidationLevel={item.totalValidationLevel}
                                          totalValidationScore={item.totalValidationScore}
                                          validationMessage={item.message}/>
                                    </div>
                                 );
                              }) :
                              <div>
                                 <h2
                                    className={'header-2 u-margin-top-s u-margin-bottom-xs'}>No matches found</h2>
                                 <p className={'text-secondary u-margin-bottom-xs'}>We couldn't find any skills that
                                    match your current filters. If you're looking for something specific and it's not
                                    yet part of your profile, consider adding new skills or adjusting your filter
                                    settings.</p>
                              </div>
                        }
                     </div>
                  </div>
               </div>
               : !props.searchData ?
                  props.skillListInformation ?
                     <div>
                        <h2 className={'header-2 u-margin-bottom-xs'}>{SKILL_MESSAGES_TITLE[props.skillsType]}</h2>
                        <div className={'u-margin-bottom-xs text-skill-wallet'}>
                           {getMessages(props.skillsType, props.skillListInformation)}
                        </div>
                     </div>
                     :
                     <div>
                        <h2 className={'header-2 u-margin-bottom-xs'}>{SKILL_MESSAGES_TITLE[props.skillsType]}</h2>
                        <p className={'u-margin-bottom-xs text-skill-wallet'}>There are currently no skills. Maybe check
                           your other skill categories for more information.</p>
                     </div>
                  :
                  <div>
                     <h2 className={'header-2 u-margin-bottom-xs'}>No matches found</h2>
                     <p className={'text-secondary u-margin-bottom-xs'}>There are no skills found for your
                        filters {props.searchData.name ? 'for skill name: \'' + props.searchData.name + '\'' : ''}
                        {props.searchData.name && props.searchData.sources && props.searchData.sources.length > 0 ? ' and ' : ''}
                        {props.searchData.sources && props.searchData.sources.length > 0 ? `for skill sources ${props.searchData.sources.map(s => s.label)}` : ''}.</p>
                     <button className={'button button-tertiary u-margin-bottom-s'}
                             onClick={() => props.searchSkills()}>Go back
                     </button>
                  </div>
            }
         </div>
      </>
   )

   function checkEnterKey(e: React.KeyboardEvent<HTMLElement>) {
      if (e.key === 'Enter' && !e.shiftKey) {
         props.searchSkills(filterNameState, filterSourceState);
      }
   }

   function filterNameStateHandler(event: any) {
      setFilterNameState(event.target.value);
   }

   function getSelectedFunction(accepting?: number) {
      if (props.skillsType === ApprovedStatus.NEW) {
         if (accepting === 1) {
            acceptDenySelected(true);
         } else if (accepting === 2) {
            acceptDenySelected(false);
         }
      } else if (props.skillsType === ApprovedStatus.REJECTED) {
         undoSelected();
      } else if (props.skillsType === ApprovedStatus.APPROVED) {
         removeSelected();
      }
   }

   function getSkillCardFunction() {
      if (props.skillsType === ApprovedStatus.NEW) {
         return props.addDenySkill;
      } else if (props.skillsType === ApprovedStatus.REJECTED || props.skillsType === ApprovedStatus.APPROVED) {
         return props.returnSkill;
      } else {
         return undefined;
      }
   }

   function undoSelected() {
      const selectedSkills: number[] = Array.from(checkedSkillsState.keys()).filter(key => checkedSkillsState.get(key))
      if (selectedSkills.length === 0) {
         return;
      }
      props.returnSkill(authStore.userData.accessToken, false, selectedSkills, true, true);
   }

   function removeSelected() {
      const selectedSkills: number[] = Array.from(checkedSkillsState.keys()).filter(key => checkedSkillsState.get(key))
      if (selectedSkills.length === 0) {
         return;
      }
      props.returnSkill(authStore.userData.accessToken, false, selectedSkills, true, true);
   }

   function acceptDenySelected(accepting: boolean) {
      const selectedSkills: number[] = Array.from(checkedSkillsState.keys()).filter(key => checkedSkillsState.get(key))

      if (selectedSkills.length === 0) {
         return;
      }

      if (accepting) {
         props.addDenySkill(authStore.userData.accessToken, false, selectedSkills, true);
      } else {
         props.addDenySkill(authStore.userData.accessToken, false, selectedSkills, false);
      }
   }

   function checkButtonHandler() {
      // Determine the value to set based on the button state.
      const valueToSet = !checkedBState || indeterminateBState;

      // Directly create and populate the map in the constructor.
      const mappedSkills = new Map(skillsList.map(item => [item.skill.id, valueToSet]));

      // Update the state with the new map.
      setCheckedSkillsState(mappedSkills);
   }

   function checkSkills(id: number) {
      setCheckedSkillsState(new Map(checkedSkillsState.set(id, !checkedSkillsState.get(id))));
   }

   function sortSkills(skills: SetDetailJsonDto[]): SetDetailJsonDto[] {
      let list: SetDetailJsonDto[] = JSON.parse(JSON.stringify(skills));

      switch (sortType) {
         case SKILLS_SORT_TYPE.NEWEST:
            break;
         case SKILLS_SORT_TYPE.OLDEST:
            list = list.reverse();
            break;
         case SKILLS_SORT_TYPE.TITLE:
            list.sort((a, b) => {
               return (a.skill.name.toUpperCase() > b.skill.name.toUpperCase()) ? 1 : ((b.skill.name.toUpperCase() > a.skill.name.toUpperCase()) ? -1 : 0)
            });
            break;
      }
      return list;
   }

   function setSort(sortType: string) {
      setSortType(sortType);
   }
}

export default SkillsComponent;