import styles from './AddSkillModal.module.scss';

import SearchSkillsComponent from './SearchSkillsComponent/SearchSkillsComponent';

const AddSkillModal: React.FC<{projectData: any, closelModal: Function, jobPosting?: boolean}> = (props) => {
  
  return (
    <div className={styles['add-skills-page']}>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12'>
            <div className='add-skills-modal'>
              <div className='card-modal'>              
                <div className='row'>
                  <div className='col-12'>
                    <div className='modal-header'>            
                      <div className='logo-container'>
                        <img src='/assets/images/global-icons/teamlift-logo-white.svg' alt="TeamLift logo" />
                      </div>                     
                      <div className='logout-button' onClick={()=>props.closelModal()}>Back to {props.jobPosting ? 'dashboard' : 'settings'}</div>
                    </div>   
                  </div>
                </div>        
                <div className='modal-body'>
                  <div id='adding-skill-process'>
                    <SearchSkillsComponent projectData={props.projectData} jobPosting={props.jobPosting} />
                  </div>
                </div>
              </div>
            </div>   
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddSkillModal;