import ReactDOM from 'react-dom';

import styles from './FileTypeErrorModal.module.scss';

const FileTypeErrorModal: React.FC<{data: {category: string, allowedTypes: string[],}, removeModal: React.MouseEventHandler<HTMLButtonElement>}> = (props) => {  
	
	let modal = (
    <div className={styles['file-type-error-modal']}>
      <div className='card card-modal'>
        <div className='card-header'>
					<h3>File type is not supported!</h3>
          <div className='close-modal-button'>
            <i className="fa-solid fa-xmark" onClick={props.removeModal}></i>
          </div>  
        </div>
        <div className="card-body">
          <p>To upload <strong>{props.data.category}</strong> data, please choose {' '}						
						{
							 props.data.allowedTypes.map((item, index) => {							
									if (index === 0) {
										return <strong>{item}</strong>
									} 
									else if (index < props.data.allowedTypes.length - 1) {
										return <>, <strong>{item}</strong></>
									}
									else return <> or {<strong>{item} </strong>}</>
							 })
						}					
					 file type.</p>      
        </div>
        <div className="card-footer">
          <div className='buttons-wrapper'>   
            <button className='button button-secondary' onClick={props.removeModal}>Close</button>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    ReactDOM.createPortal(modal, document.getElementById('modal-root') as HTMLDivElement)
  )
}

export default FileTypeErrorModal;