import React, {useContext, useEffect, useState} from "react";
import style from './PersonalityInsights.module.scss';
import AuthContext from "../../../../store/auth-context";
import useLoadingSpinner from "../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner";
import {
   CollaborativeRoleIcons,
   PowerSynergyIcons,
   ResultsDto, RoleDto,
   UnderlyingEnablerIcons
} from "../../../../types/PersonaTypes";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {ErrorResponseDto} from "../../../../types/ErrorData";
import {showNotification} from "../../../../ui/Toast/ToastNotification";
import useApiService from "../../../../services/api.service";
import {useNavigate} from "react-router-dom";
import {Avatar} from "@mui/material";
import {PieChart} from "@mui/x-charts";
import ReactSlidingPane from "react-sliding-pane";
import Tooltip from "../../../../services/tooltip.service";
import {getFormattedTime} from "../../../../utils/get-time";

const PersonalityInsights: React.FC = () => {

   const authStore = useContext(AuthContext);
   const navigate = useNavigate();
   const {refreshToken} = useApiService();
   const spinnerService = useLoadingSpinner();

   const [data, setData] = useState<ResultsDto>();
   const colors = ['#ADD8E6', '#87CEEB', '#6495ED', '#1E90FF', '#4169E1', '#0000CD', '#00008BBF', '#253BB5', '#1A31A9', '#16298C', '#00008B'];
   const [isOpen, setIsOpen] = useState(false);
   const [sidePanelData, setSidePanelData] = useState<{name: string, role: RoleDto, imageSource: string}>();
   const [highlightedItem, setHighlightedItem] = useState<number>();

   useEffect(() => {
      initializeComponent(authStore.userData.accessToken);
   }, []);

   return (
      <>
         {
            data &&
            <div className={style["personality-insights"]}>
               <h1 className="header-1">Personality insights</h1>
               <div className="pi-header">
                  <div className="user-details">
                     {
                        authStore.userData.profilePicture ? <Avatar src={authStore.userData.profilePicture} /> : <Avatar sx={{backgroundColor: authStore.userData.color}}>{authStore.userData.fullName[0].toUpperCase()}</Avatar>
                     }
                     <div className="name-details">
                        <h2 className="header-2">{authStore.userData.fullName}</h2>
                        <div className="text-surface-30">{data.persona}</div>
                     </div>
                  </div>
                  {
                     data.finished &&
                     <div className="test-details">
                        <div>Psychometric Assessment Test</div>
                        <div>Completed on {getFormattedTime(data.date)}</div>
                     </div>
                  }
               </div>

               {
                  !data.finished ?
                     <>
                        <div className="pi-unfinished-main">
                           <div className="text-container">
                              <div className={"wrapper"}>
                                 <h2 className="header-2">Psychometric Assessment Test</h2>
                                 <div>
                                    <p>Unlock Your Potential with Our Psychometric Assessment Test!</p>
                                    <p>Dive into a world of self-discovery and unleash your hidden talents.</p>
                                    <p>Click now and embark on a journey of growth!</p>
                                 </div>
                                 <button className="button button-primary" onClick={() => navigate("/persona")}>Start Test</button>
                              </div>
                           </div>
                        </div>
                     </>
                     :
                     <>
                        <main className="pi-main">
                           <section className="role-section">

                              <div className="core-role">
                                 <div className="cr-title">
                                    <div className="image-wrapper">
                                       <img src="/assets/images/personality-insights-icon/core-role/clould-icon.svg"
                                            alt="Cloud image" className="cloud-image"/>
                                       <Avatar className={"role-image"}
                                            src={CollaborativeRoleIcons.get(data.collaborationRole.name.toLowerCase().trim())}
                                            alt="Core role wrapper"/>
                                    </div>
                                    <div className="cr-title-wrapper">
                                       <div className={'role'}>Your core collaborative role</div>
                                       <h2 className={'header-2'}>{data.collaborationRole.name}</h2>
                                    </div>
                                 </div>

                                 <button className="button button-primary" onClick={() => openSidePanel({
                                    name: "Core collaborative role",
                                    role: data!.collaborationRole,
                                    imageSource: CollaborativeRoleIcons.get(data.collaborationRole.name.toLowerCase().trim())!
                                 })}>Find out more
                                 </button>

                              </div>

                              <div className="additional-role">

                                 <div className="additional-role-title">
                                    <img
                                       src={UnderlyingEnablerIcons.get(data.underlyingEnabler.name.toLowerCase().trim())}
                                       alt="Underlying enabler icon"/>
                                    <div className="wrapper">
                                       <div className={"role"}>Underlying enabler</div>
                                       <h2 className={"header-2"}>{data.underlyingEnabler.name}</h2>
                                    </div>
                                 </div>

                                 <Tooltip tooltipId={data.underlyingEnabler.name} className={"custom-tooltip"}
                                          content={<div>Click <b>Learn More</b> to discover detailed information about
                                             this role.</div>}>
                                    <button className="button button-tertiary" onClick={() => openSidePanel({
                                       name: "Underlying enabler",
                                       role: data!.underlyingEnabler,
                                       imageSource: UnderlyingEnablerIcons.get(data.underlyingEnabler.name.toLowerCase().trim())!
                                    })}>
                                       <i className="fa-solid fa-circle-question"></i> Learn more
                                    </button>
                                 </Tooltip>

                              </div>

                              <div className="additional-role">

                                 <div className="additional-role-title">
                                    <img src={PowerSynergyIcons.get(data.powerSynergy.name.toLowerCase().trim())}
                                         alt="Power synergy icon"/>
                                    <div className="wrapper">
                                       <div className={"role"}>Power synergy</div>
                                       <h2 className={"header-2"}>{data.powerSynergy.name}</h2>
                                    </div>
                                 </div>

                                 <Tooltip tooltipId={data.powerSynergy.name} className={"custom-tooltip"}
                                          content={<div>Click <b>Learn More</b> to discover detailed information about
                                             this role.</div>}>
                                    <button className="button button-tertiary" onClick={() => openSidePanel({
                                       name: "Power synergy",
                                       role: data!.powerSynergy,
                                       imageSource: PowerSynergyIcons.get(data.powerSynergy.name.toLowerCase().trim())!
                                    })}>
                                       <i className="fa-solid fa-circle-question"></i> Learn more
                                    </button>
                                 </Tooltip>

                              </div>

                           </section>

                           <section className="skills-section">
                              <h2 className="header-2">Skills Statistics</h2>

                              <div className="pie-chart">
                                 <PieChart
                                    margin={{top: 10, bottom: 10, left: 10, right: 10}}
                                    colors={colors}
                                    className={"mui-chart"}
                                    series={[
                                       {
                                          data: data.skills.map(s => ({id: s.name, value: s.weight, label: s.name})),
                                          highlightScope: {fade: 'global', highlight: 'item'},
                                          valueFormatter: (v, {dataIndex}) => {
                                             return data ? data.skills[dataIndex].weight.toFixed(2).toString() + "%" : "No exact value";
                                          },
                                          innerRadius: 30,
                                          paddingAngle: 3,
                                       }
                                    ]}
                                    height={250}
                                    width={400}
                                    onHighlightChange={e => setHighlightedItem(e ? e.dataIndex : undefined)}
                                    slotProps={{
                                       legend: {
                                          hidden: true
                                       },
                                    }}
                                 />
                              </div>

                              <div className="skills">
                                 {
                                    data.skills.map((skill, index) => {
                                       return (
                                          <div className={`skill-wrapper ${index === highlightedItem ? "active" : ""}`} key={index}>
                                             <div className="color" style={{backgroundColor: colors[index]}}></div>
                                             <div className="percentage">{skill.weight} %</div>
                                             <div className={"name"}>{skill.name}</div>
                                          </div>
                                       )
                                    })
                                 }
                              </div>

                           </section>
                        </main>

                        <div className="pi-additional">
                           <h2 className="header-2">A little extra for your curiosity</h2>
                           <p className="text-surface-30">Your rational and intuitive inclinations respective to the
                              collaborative roles assessed are fully aligned.</p>
                           <div className="pi-additional-main">
                              <div className="pi-section">
                                 <div className="pi-button-wrapper">
                                    <div className="pi-button">Rational</div>
                                    <div className="chip-line-wrapper">
                                       <div className="chip"></div>
                                       <div className="pi-line"></div>
                                       <div className="chip"></div>
                                    </div>
                                 </div>
                                 <div className="chip"></div>
                                 <div className="line"></div>
                                 <div className="chip"></div>
                                 <div className="section-content">
                                    <h3 className="header-3">{data.rationality.name}</h3>
                                    <p className="text-surface-30">{data.rationality.description}</p>
                                 </div>
                              </div>
                              <img src="/assets/images/personality-insights-icon/brain-icon.svg" alt="Brain icon"
                                   className="brain-image"/>
                              <div className="pi-section">
                                 <div className="pi-button-wrapper reverse">
                                    <div className="chip-line-wrapper">
                                       <div className="chip"></div>
                                       <div className="pi-line"></div>
                                       <div className="chip"></div>
                                    </div>
                                    <div className="pi-button">Intuitive</div>
                                 </div>
                                 <div className="chip"></div>
                                 <div className="line"></div>
                                 <div className="chip"></div>
                                 <div className="section-content">
                                    <h3 className="header-3">{data.intuitive.name}</h3>
                                    <p className="text-surface-30">{data.intuitive.description}</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </>
               }

            </div>
         }
         <ReactSlidingPane isOpen={isOpen}
                           onRequestClose={() => setIsOpen(false)}
                           from={'right'}
                           className='admin-side-panel'
                           width='35%' hideHeader>
            <div className={"admin-side-panel-content"}>
               <div className="admin-side-panel-body">
                  <div className="admin-side-panel-header">
                     <h2 className="header-2">{sidePanelData?.name}</h2>
                     <div onClick={() => setIsOpen(false)} className="close-button-container"><i
                        className="fa-solid fa-xmark"></i></div>
                  </div>

                  <div className="side-panel-title">
                     <img src={sidePanelData?.imageSource} alt="Role image"/>
                     <h1 className="header-1">{sidePanelData?.role.name}</h1>
                  </div>
                  <p className={'text-surface-20 u-margin-top-m personal-text'}>{sidePanelData?.role.description}</p>
               </div>

               <div className="buttons-wrapper">
                  <button className="button button-secondary" onClick={() => setIsOpen(false)}>Close</button>
               </div>

            </div>
         </ReactSlidingPane>
         {
            spinnerService.spinner
         }
      </>
   )

   function openSidePanel(data: { name: string, role: RoleDto, imageSource: string }) {
      setSidePanelData(data);
      setIsOpen(true);
   }

   function initializeComponent(accessToken: string) {
      authStore.changePageTitle("Skills Portfolio");
      const getResultsURL = process.env.REACT_APP_PUBLIC_URL + "/answer/calculate-results";

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      const params: AxiosRequestConfig['params'] = {
         userId: authStore.userData.userId
      };

      const config: AxiosRequestConfig = {
         headers,
         params
      };

      spinnerService.createSpinner();

      axios
         .post(getResultsURL, undefined, config)
         .then((response$: AxiosResponse<ResultsDto>) => {
            spinnerService.removeSpinner();
            setData(response$.data);
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     initializeComponent(response$.data.accessToken);
                  })
            } else {
               spinnerService.removeSpinner();
               showNotification("warning", error$.response.data.message);
            }
         })
   }

}

export default PersonalityInsights;