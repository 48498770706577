export type Notification = {
    id: number;
    title: string;
    description: string;
    read: boolean;
    deleted: boolean;
    category: NotificationCategory;
    dateCreated: Date;
    dateRead: Date;
    dateDeleted: Date;
    user: User;
    createdBy: User;
    params?: NotificationParams[] | any;
    projectId?: number;
}

export type User = {
    id: string;
    username: string;
    name: string;
    phone: string;
    displayName: string;
    joinedOn: Date;
    expirationDate: Date;
    autoGeneratedPassword: boolean;
    email: string;
    validity: number;
    level: any;
    enabled: number;
    userRole?: any;
    jobTitle?: string;
}

export enum NotificationCategory {
    NOTIFICATION = 'NOTIFICATION',
    SOCIAL_VALIDATION = 'SOCIAL_VALIDATION',
    REMOVED_NOTIFICATION = 'REMOVED_NOTIFICATION',
    LEARNING_NOTIFICATION = 'LEARNING_NOTIFICATION',
    WORKING_NOTIFICATION = 'WORKING_NOTIFICATION',
    ARCHIVED_PROJECT_NOTIFICATION = 'ARCHIVED_PROJECT_NOTIFICATION'
}

export type NotificationParams = {
    skill: {
        id: number,
        name: string,
        description: string,
    },
    aiTools: aiTool[],
    courses: Course[],
}

export type aiTool = {
    uid: string,
    name: string,
    description: string,
    url?: string,
    category?: string,
}

export type Course = {
    uid: string,
    name: string,
    description: string,
    url: string,
    source: string,
    is_paid: boolean,
    level: string,
    category: string,
    rating: number,
    duration_in_hours?: number,
}