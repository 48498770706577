import React, {MouseEventHandler, useRef} from "react";
import styles from './TermsConditionsPage.module.scss';
import ScrollHighlightNavbar from "./ScrollHighlightNavbar/ScrollHighlightNavbar";
import {TermsConditions} from "../../../../../utils/terms-conditions";

const TermsConditionsPage: React.FC<{checkTerms: MouseEventHandler<HTMLButtonElement>}> = props => {

   const section1Ref = useRef<any>();
   const section2Ref = useRef<any>();
   const section3Ref = useRef<any>();
   const section4Ref = useRef<any>();
   const section5Ref = useRef<any>();
   const section6Ref = useRef<any>();
   const section7Ref = useRef<any>();
   const section8Ref = useRef<any>();
   const section9Ref = useRef<any>();
   const section10Ref = useRef<any>();
   const section11Ref = useRef<any>();

   const navHeader = [
      {
         headerTitle: "Introduction",
         headerRef: section1Ref,
         headerID: "section1"
      },
      {
         headerTitle: "What Data Do We Collect?",
         headerRef: section2Ref,
         headerID: "section2"
      },
      {
         headerTitle: "How We Collect Your Data",
         headerRef: section3Ref,
         headerID: "section3"
      },
      {
         headerTitle: "How We Use Your Data",
         headerRef: section4Ref,
         headerID: "section4"
      },{
         headerTitle: "Access to Your Results",
         headerRef: section5Ref,
         headerID: "section5"
      },
      {
         headerTitle: "How We Store Your Data",
         headerRef: section6Ref,
         headerID: "section6"
      },{
         headerTitle: "Your Data Protection Rights",
         headerRef: section7Ref,
         headerID: "section7"
      },{
         headerTitle: "Lawful Basis for Processing under GDPR",
         headerRef: section8Ref,
         headerID: "section8"
      },{
         headerTitle: "Data Management and Deletion",
         headerRef: section9Ref,
         headerID: "section9"
      },{
         headerTitle: "Changes to our Privacy Policy",
         headerRef: section10Ref,
         headerID: "section10"
      },{
         headerTitle: "Conclusion",
         headerRef: section11Ref,
         headerID: "section11"
      },
   ];

   return (
      <>
         <div className={styles['terms-conditions-page']}>
            <div className="terms-header">
               <button className="button button-tertiary" onClick={props.checkTerms}>
                  <span><i className="fa-solid fa-arrow-left"></i> Back to settings</span>
               </button>
            </div>
            <div className="terms-body">
               <h1 className="header-1 u-margin-bottom-xl">Terms & Conditions Of Use</h1>
               <div className="terms-content">
                  <div className="terms-scroll">
                     <ScrollHighlightNavbar navHeader={navHeader}/>
                  </div>
                  <div className="terms-paragraph">
                     {
                        TermsConditions.filter((item, index) => index !== 0).map((item, index) => {
                           return (
                              <div key={navHeader[index].headerID} className="terms-paragraph-section" id={navHeader[index].headerID} ref={navHeader[index].headerRef}>
                                 <div className="terms-paragraph-section-container">
                                    <h2 className="terms-paragraph-section-container-title">{item.sectionTitle}</h2>
                                    <div className="terms-paragraph-section-container-content">{item.sectionContent}</div>
                                    {
                                       item.sectionSubContent &&
                                       <ul className={'terms-paragraph-section-container-list'}>
                                          {
                                             item.sectionSubContent.map((item, index) => {
                                                return (
                                                   <li key={index} className={'terms-paragraph-section-container-list-item'}>
                                                      {item.title && <span className={'terms-paragraph-section-container-list-item-title'}>{item.title + ' '}</span>}
                                                      {item.body}
                                                   </li>
                                                );
                                             })
                                          }
                                       </ul>
                                    }
                                    {
                                       item.sectionAfterContent &&
                                       <div className={'terms-paragraph-section-container-after'}>{item.sectionAfterContent}</div>
                                    }
                                 </div>
                                 {
                                    index + 2 !== TermsConditions.length && <hr className={'terms-paragraph-section-hr'}/>
                                 }
                              </div>
                           )
                        })
                     }
                  </div>
               </div>
            </div>
         </div>
      </>
   )
}

export default TermsConditionsPage;