import React, {MouseEventHandler, useState} from "react";
import styles from './TermsConditionsModal.module.scss';
import {TermsConditions} from "../../../utils/terms-conditions";

const TermsConditionsModal: React.FC<{acceptCallBack: Function, cancelCallBack: MouseEventHandler<HTMLButtonElement>}> = props => {

   const [checked, setChecked] = useState<boolean>(false);

   return (
      <>
         <div className={styles['terms-conditions-modal']}>
            <div className="card-terms-modal">
               <div className="card-header">
                  <div className="card-header-title">
                     <h1 className="card-header-title-text header-1">Terms & Conditions Of Use</h1>
                     <div className="card-header-title-time">
                        <img src="/assets/images/global-icons/last-updated-icon.svg" alt="last updated icon"/>
                        <span className="card-header-title-time-text">Last Updated: July 31, 2024</span>
                     </div>
                  </div>
                  <h2 className="card-header-subtitle header-2">Your Agreement</h2>
                  <hr className="card-header-hr"/>
               </div>
               <div className="card-terms-body">
                  <div className="terms-paragraph">
                     {
                        TermsConditions.map((item, index) => {
                           return (
                              <div key={index} className="terms-paragraph-section">
                                 <div className="terms-paragraph-section-container">
                                    {item.sectionTitle && <h2 className="terms-paragraph-section-container-title">{item.sectionTitle}</h2>}
                                    <div className="terms-paragraph-section-container-content">{item.sectionContent}</div>
                                    {
                                       item.sectionSubContent &&
                                       <ul className={'terms-paragraph-section-container-list'}>
                                          {
                                             item.sectionSubContent.map((item, index) => {
                                                return (
                                                   <li key={index} className={'terms-paragraph-section-container-list-item'}>
                                                      {item.title && <span className={'terms-paragraph-section-container-list-item-title'}>{item.title + ' '}</span>}
                                                      {item.body}
                                                   </li>
                                                );
                                             })
                                          }
                                       </ul>
                                    }
                                    {
                                       item.sectionAfterContent &&
                                       <div className={'terms-paragraph-section-container-after'}>{item.sectionAfterContent}</div>
                                    }
                                 </div>
                              </div>
                           )
                        })
                     }
                  </div>
               </div>
               <div className="card-terms-footer">
                  <div className="card-terms-footer-checkbox">
                     <div onClick={checkedHandler} className={`checkbox ${checked ? 'checked' : ''}`}>
                        {
                           checked && <i className="fa-solid fa-check"></i>
                        }
                     </div>
                     <div className="card-terms-footer-checkbox-body">
                        I confirm that I have read and accept the <b>Terms and Conditions</b> and <b>Privacy Policy</b>.
                     </div>
                  </div>
                  <div className="buttons-wrapper">
                     <button onClick={props.cancelCallBack} className={'button button-secondary u-margin-right-xs'}>Cancel</button>
                     <button onClick={() => props.acceptCallBack(checked)} disabled={!checked} className={`button button-primary accept-button`}>Accept</button>
                  </div>
               </div>
            </div>
         </div>
      </>
   )

   function checkedHandler() {
      setChecked(prevState => !prevState);
   }
}

export default TermsConditionsModal;