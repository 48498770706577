import React, {useContext, useEffect, useRef, useState} from "react";
import style from './ResultsPage.module.scss'
import {PieChart} from "@mui/x-charts";
import {
   CollaborativeRoleIcons,
   PowerSynergyIcons,
   ResultsDto,
   UnderlyingEnablerIcons
} from "../../../../../types/PersonaTypes";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {ErrorResponseDto} from "../../../../../types/ErrorData";
import {showNotification} from "../../../../../ui/Toast/ToastNotification";
import AuthContext from "../../../../../store/auth-context";
import {useNavigate} from "react-router-dom";
import useLoadingSpinner from "../../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner";
import useApiService from "../../../../../services/api.service";
import generatePDF, {Margin} from "react-to-pdf";

const ResultsPage: React.FC = () => {

   const authStore = useContext(AuthContext);
   const navigate = useNavigate();
   const [data, setData] = useState<ResultsDto>();
   const targetPDFRef = useRef<HTMLDivElement>(null);

   const spinnerService = useLoadingSpinner();
   const {refreshToken} = useApiService();

   useEffect(() => {
      initializeComponent(authStore.userData.accessToken);
   }, []);

   return (
      <>
         {
            data &&
            <div className={style['results-page']}>

               <div className="buttons-container">
                  <button className="button button-primary u-margin-right-xs"
                          onClick={() => navigate("/answer/calculate-results")}>Finish
                  </button>
                  <button className="button button-secondary" onClick={() => generatePDF(targetPDFRef, {
                     filename: "page.pdf", page: {orientation: "portrait", margin: Margin.NONE}, canvas: {
                        mimeType: 'image/png', // PNG format
                        qualityRatio: 1,
                     },
                  })}>Save as pdf
                  </button>
               </div>

               <div id={'persona-results-card'} className="full-card" ref={targetPDFRef}>
                  <div className="card-header">
                     <div className="image-container">
                        <img src='/assets/images/global-icons/teamlift-logo-white.svg' alt="Teamlift logo"/>
                     </div>
                     <div className="card-title">
                        <h1 className={'header-1'}>Collaborative Profile</h1>
                        <div>OVERVIEW</div>
                     </div>
                  </div>

                  <div className="persona u-margin-top-s u-margin-bottom-s">
                     <h2 className="header-2">Prepared for: {data.name}</h2>
                     <h3 className="header-3">Date: {data.date.split("T")[0]}</h3>
                  </div>

                  <main>
                     <section className="team-persona-section">
                        <h2 className="team-persona-title header-2">
                           Team Persona: {data.persona}
                        </h2>
                        <div className="team-persona-chart-wrapper">
                           <PieChart
                              colors={['#E8EAF9', '#C6CAEF', '#A0A8E5', '#7885DB', '#5969D4', '#374ECB', '#3146C1', '#253BB5', '#1A31A9', '#16298C', '#122274']}
                              className={"mui-chart"}
                              series={[
                                 {
                                    data: data.skills.map(s => ({id: s.name, value: s.weight, label: s.name})),
                                    highlightScope: {faded: 'global', highlighted: 'item'},
                                    innerRadius: 30,
                                    paddingAngle: 3,
                                    valueFormatter: (v, {dataIndex}) => {
                                       return data ? data.skills[dataIndex].weight.toFixed(2).toString() + "%" : "No exact value";
                                    },
                                 }
                              ]}
                              height={250}
                           />
                        </div>

                        <div className="role">
                           <h2 className="r-title header-2">Your core collaborative role
                              is {data.collaborationRole.name}</h2>
                           <div className="r-content">
                              <img src={CollaborativeRoleIcons.get(data.collaborationRole.name.toLowerCase().trim())}
                                   alt="Collaborations image"/>
                              <p>{data.collaborationRole.description}</p>
                           </div>
                        </div>

                        <div className="role u-margin-top-s">
                           <h2 className="r-title header-2">Your underlying enabler is
                              being {data.underlyingEnabler.name}</h2>
                           <div className="r-content">
                              <img src={UnderlyingEnablerIcons.get(data.underlyingEnabler.name.toLowerCase().trim())}
                                   alt="Underlying enabler image"/>
                              <p>{data.underlyingEnabler.description}</p>
                           </div>
                        </div>

                     </section>

                     <section className="collaborations">

                        <h2 className="team-persona-title header-2">
                           Collaborations: {data.persona}
                        </h2>

                        <div className="role u-margin-top-s">
                           <h2 className="r-title header-2">Power synergy: {data.powerSynergy.name}</h2>
                           <div className="r-content">
                              <img src={PowerSynergyIcons.get(data.powerSynergy.name.toLowerCase().trim())}
                                   alt="Power synergy image"/>
                              <p>{data.powerSynergy.description}</p>
                           </div>
                        </div>

                     </section>

                     <section className="curiosity-section">
                        <h2 className="team-persona-title header-2">A little extra for your curiosity</h2>
                        <div className="curiosity-content u-margin-top-s">
                           <p>Your team persona is complex. Based on your test results, we have assessed both your
                              rational
                              and your intuitive inclinations. The rational inclination is slower, deliberate and
                              logical.
                              It is influenced more by your current team and working environment. The intuitive, on the
                              other hand, is fast, instinctive and emotional. The intuitive inclination is less
                              influenced
                              by current happenings. These two could be anywhere from perfectly aligned to significantly
                              differing. Your final result is a reflection of what is currently prevalent taking both
                              inclinations into account, as calculated by our unique TeamLift™ algorithm.</p>
                           <div className="brain-section">
                              <img src="/assets/images/results-icons/brains_new_light_blue.png" alt="Brain image"/>
                              <div className="texts">
                                 <div className="text-section">
                                    <h3>{data.rationality.name}</h3>
                                    <p>{data.rationality.description}</p>
                                 </div>
                                 <div className="text-section">
                                    <h3>{data.intuitive.name}</h3>
                                    <p>{data.intuitive.description}</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </section>
                  </main>
               </div>
            </div>
         }
         {
            spinnerService.spinner
         }
      </>
   )

   function initializeComponent(accessToken: string) {
      const getResultsURL = process.env.REACT_APP_PUBLIC_URL + "/answer/calculate-results";

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      const params: AxiosRequestConfig['params'] = {
         userId: authStore.userData.userId
      };

      const config: AxiosRequestConfig = {
         headers,
         params
      };

      spinnerService.createSpinner();

      axios
         .post(getResultsURL, undefined, config)
         .then((response$: AxiosResponse<ResultsDto>) => {
            if (!response$.data.finished) {
               navigate("/persona/questions");
            }
            spinnerService.removeSpinner();
            setData(response$.data);
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     initializeComponent(response$.data.accessToken);
                  })
            }
            else {
               spinnerService.removeSpinner();
               showNotification("warning", error$.response.data.message);
            }
         })
   }

}

export default ResultsPage;