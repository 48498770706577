import React, {useContext, useEffect, useState} from "react";
import {EmployeeOnboardPages} from "../EmployeeOnboardPage";
import style from './UploadPage.module.scss';
import {adminRegexPatterns} from "../../../../../types/AdminData";
import ReactSlidingPane from "react-sliding-pane";
import Uploader from "../../../UploadDataPage/DragDropUploader/Uploader";
import {animated, useSpring} from "react-spring";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {ErrorResponseDto} from "../../../../../types/ErrorData";
import {showNotification} from "../../../../../ui/Toast/ToastNotification";
import AuthContext from "../../../../../store/auth-context";
import useApiService from "../../../../../services/api.service";
import useLoadingSpinner from "../../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner";
import {FileUploadedDto} from "../../../../../types/EmployeeOnboardData";
import Tooltip from "../../../../../services/tooltip.service";
import {ClipLoader, FadeLoader} from "react-spinners";

const UploadPage: React.FC<{
   uploadType: EmployeeOnboardPages,
   changeSection: Function,
}> = props => {

   const authStore = useContext(AuthContext);
   const {refreshToken} = useApiService();
   const spinnerService = useLoadingSpinner();

   const [uploadedData, setUploadedData] = useState<FileUploadedDto>();
   const [githubLink, setGithubLink] = useState("");
   const [validGithub, setValidGithub] = useState(false);
   const [openPanel, setOpenPanel] = useState(false);
   const [file, setFile] = useState<File>();
   const [loadingData, setLoadingData] = useState(false);
   const fadeStyles = useSpring({
      from: {opacity: 0},
      to: {opacity: 1},
      config: {duration: 750},
   });

   useEffect(() => {
      const delayInputTimeoutId = setTimeout(() => {
         setValidGithub(adminRegexPatterns.githubUrl.test(githubLink));
      }, 500);
      return () => clearTimeout(delayInputTimeoutId);
   }, [githubLink]);

   useEffect(() => {
      initializeComponent(authStore.userData.accessToken);
   }, []);

   return (
      <>
         <animated.div className={style['upload-page']} style={fadeStyles}>

            <div className="upload-header">
               {
                  props.uploadType === EmployeeOnboardPages.GITHUB_PAGE &&
                  <>
                     <img src="/assets/images/upload-data-icons/github-icon.svg" alt="GitHub icon"/>
                     <h1 className="header-1 u-margin-left-xs">Connect your GitHub account</h1>
                  </>
               }
               {
                  props.uploadType === EmployeeOnboardPages.LINKEDIN_PAGE &&
                  <>
                     <img src="/assets/images/upload-data-icons/linkedin-icon.svg" alt="Linkedin icon"/>
                     <h1 className="header-1 u-margin-left-xs">Connect your Linkedin account</h1>
                     <Tooltip tooltipId={`linkedin_tooltip`}
                              place="right"
                              className={'custom-tooltip'}
                              content={<div>Discover how to export your LinkedIn data.</div>}>
                        <i className="fa-solid fa-circle-info" onClick={() => setOpenPanel(true)}></i>
                     </Tooltip>
                  </>
               }
               {
               props.uploadType === EmployeeOnboardPages.CV_PAGE &&
                  <>
                     <img src="/assets/images/upload-data-icons/cv-icon.svg" alt="CV icon"/>
                     <h1 className="header-1 u-margin-left-xs">Share your CV</h1>
                  </>
               }
            </div>

            <div className="u-margin-top-s u-margin-bottom-s">
               {
                  props.uploadType === EmployeeOnboardPages.GITHUB_PAGE && uploadedData && uploadedData.isGithubUploaded && <p className="text-surface-30">Your admin already uploaded your github profile.</p>
               }
               {
                  props.uploadType === EmployeeOnboardPages.CV_PAGE && uploadedData && uploadedData.isCVUploaded && <p className="text-surface-30">Your admin already uploaded your CV.</p>
               }
               {
                  props.uploadType === EmployeeOnboardPages.LINKEDIN_PAGE && uploadedData && uploadedData.isLinkedinUploaded && <p className="text-surface-30">Your admin already uploaded your LinkedIn profile.</p>
               }
            </div>

            <p className={'text-surface-30 u-margin-bottom-l'}>
               {getText()[props.uploadType]}
            </p>

            {
               props.uploadType === EmployeeOnboardPages.GITHUB_PAGE ?
               <div className="custom-input-wrapper">
                  <i className="fa-solid fa-link u-margin-right-xs"></i>
                  <input value={githubLink} onChange={e => setGithubLink(e.target.value)} type="text"
                         placeholder={"https://github.com/YourProfileLink"}/>
                  <i className={`fa-solid fa-check ${validGithub ? "active" : ""}`}></i>
               </div> :
                  <div>
                     <Uploader uploadFile={uploadFileHandler} cancelUpload={() => {}} progress={0} category={props.uploadType === EmployeeOnboardPages.CV_PAGE ? "CV" : "LINKEDIN"} />
                     <p className={"u-margin-top-m text-surface-30"}>We prioritize your security — your file is never stored.</p>
                  </div>
            }

            <div className="buttons-container">
               <button className="button button-secondary u-margin-top-big"
                       onClick={() => props.changeSection(true)}><i className="fa-solid fa-arrow-left"></i> Back
               </button>
               <button disabled={(githubLink.trim().length > 0 && !validGithub) || loadingData} className={`button button-${file || githubLink.trim().length > 0 ? "primary" : "secondary"} u-margin-top-big`} onClick={upload}>
                  {loadingData && <ClipLoader color={"#7885DBFF"} size={16} className={"spinner"} />}
                  {file || githubLink.trim().length > 0 ? <>Next <i className="fa-solid fa-arrow-right"></i></> : "Skip"}</button>
            </div>

         </animated.div>
         <ReactSlidingPane isOpen={openPanel}
                           onRequestClose={() => setOpenPanel(false)}
                           from={'right'}
                           className='admin-side-panel employee-onboard'
                           width='45%' hideHeader>
            <div className={"admin-side-panel-content"}>
               <div className="admin-side-panel-body">
                  <div className="admin-side-panel-header">
                     <h1 className="header-1">How to export data from LinkedIn</h1>
                     <div onClick={() => setOpenPanel(false)} className="close-button-container"><i className="fa-solid fa-xmark"></i></div>
                  </div>
                  <ol className={"linkedin-tips u-margin-top-m"}>
                     <li>Go to your LinkedIn profile page</li>
                     <li>Click "More..."<div>On the profile page, find the "More..." button (usually with three dots).</div></li>
                     <li>Select "Save to PDF"<div>Choose this option from the dropdown menu.</div></li>
                     <li>The PDF will download<div>The profile will be saved as a PDF to your computer.</div></li>
                  </ol>
                  <div className="images-container">
                     <img src="/assets/images/employee-onboarding-icons/How%20to%20export%20data%20from%20LinkedIn.png" alt="Export data"/>
                  </div>
               </div>
            </div>
         </ReactSlidingPane>
         {
            spinnerService.spinner
         }
      </>
   );

   function initializeComponent(accessToken: string) {
      const checkUploadedDataURL = process.env.REACT_APP_PUBLIC_URL + '/file/static-data-information';

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      axios
         .get(checkUploadedDataURL, {headers})
         .then((response$: AxiosResponse<FileUploadedDto>) => {
            setUploadedData(response$.data);
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     initializeComponent(response$.data.accessToken);
                  })
            } else {
               showNotification('warning', error$.response.data.message);
            }
         });
   }

   function upload() {
      if (githubLink.trim().length === 0 && !file) {
         props.changeSection();
         return;
      }
      props.uploadType === EmployeeOnboardPages.GITHUB_PAGE ? uploadGithub(authStore.userData.accessToken) : sendFile(authStore.userData.accessToken);
   }

   function uploadGithub(accessToken: string) {

      setLoadingData(true);

      const saveGitURL: string = process.env.REACT_APP_PUBLIC_URL + '/file/save-github-link';

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      const body = {
         githubLink
      }

      axios
         .post(saveGitURL, body, {headers})
         .then(() => {
            setLoadingData(false);
            showNotification("success", "Your GitHub profile has been successfully uploaded");
            props.changeSection();
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     uploadGithub(response$.data.accessToken);
                  })
            } else {
               setLoadingData(false);
               showNotification('warning', error$.response.data.message);
            }
         });
   }

   function sendFile(accessToken: string) {
      const saveFileURL = process.env.REACT_APP_PUBLIC_URL + '/file/save-file';

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      const bodyFormData = new FormData();
      bodyFormData.append('file', file!);
      bodyFormData.append('subCategory', props.uploadType === EmployeeOnboardPages.CV_PAGE ? "CV" : "LINKEDIN");

      setLoadingData(true);

      axios
         .post(saveFileURL, bodyFormData, {headers})
         .then(() => {
            setLoadingData(false);
            showNotification("success", `Your ${props.uploadType === EmployeeOnboardPages.LINKEDIN_PAGE ? "LinkedIn" : "CV"} has been successfully uploaded.`, undefined, props.uploadType === EmployeeOnboardPages.LINKEDIN_PAGE ? "LinkedIn" : "CV");
            props.changeSection();
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     sendFile(response$.data.accessToken);
                  })
            } else {
               setLoadingData(false);
               showNotification('warning', error$.response.data.message);
            }
         });
   }

   function uploadFileHandler(file: File) {
      setFile(file);
   }

   function getText(): any {
      return {
         [EmployeeOnboardPages.GITHUB_PAGE]: "Analyzing your GitHub activity will provide TeamLift's AI with more context on your skills and abilities.",
         [EmployeeOnboardPages.LINKEDIN_PAGE]: "TeamLift can automatically review your LinkedIn profile to identify relevant skills, experience, recommendations and certifications listed there.",
         [EmployeeOnboardPages.CV_PAGE]: "Upload your CV to provide TeamLift's AI with even more context on your skills."
      }
   }
}

export default UploadPage