import {MenusEndpointsDto} from "./UserMenu";
import {Notification} from "./Notification";

export type UserData = {
  fullName: string;
  displayName: string;
  email: string;
  recoveryEmail?: string;
  accessToken: string;
  refreshToken: any;
  menu: MenusEndpointsDto[];
  profilePicture: string;
  color: string;
  userId: string;
  pageTitle: string;
  updatedProfilePicture: boolean;
  isManager: boolean;
  firstLogin: boolean;
  notifications: Notification[];
  companyId: number;
  sessionId: string;
  isOnboarding: boolean;
  userRole: UserRoleDto;
};

export type UserAuth = {
  userData: UserData;
  channel: any;
  channelNotifications: any;
  channelRegister: any;
  login: (data: UserData) => void;
  logout: () => void;
  changeProfileImage: (url: string) => void;
  changeDisplayName: (displayName: string) => void;
  changePageTitle: (string: string) => void;
  storeTokens: (accessToken: string, refreshToken: string, sessionId: string) => void;
  changeRecoveryEmail: (recoveryEmail: string) => void;
  deleteRecoveryEmail: () => void;
  changeFirstLogin: (flag: boolean) => void;
  changeOnboarding: () => void;
  updateMenu: (menu: MenusEndpointsDto[]) => void;
};

export enum UserRoleDto {
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  EMPLOYEE = 'EMPLOYEE',
  PERSONAL_USER = 'PERSONAL_USER',
  ADMIN_MANAGER = 'ADMIN_MANAGER',
  ADMIN_EMPLOYEE = 'ADMIN_EMPLOYEE',
  FREEMIUM_INDIVIDUAL = 'FREEMIUM_INDIVIDUAL',
  SUPER_ADMIN = 'SUPER_ADMIN',
}
