import styles from './EmployeeInfo.module.scss';
import {Avatar} from "@mui/material";
import {capitalizeFirstLetter} from "../../../../../utils/capitalize-first-letter";
import React from "react";

const EmployeeInfo: React.FC<{
   name: string,
   surname: string,
   profileImageUrl: string,
   jobPositionName: string,
   readOnlyMode?: boolean,
   color: string
}> = (props) => {
   return (
      <div className={styles['employee-info']}>
         <div className='card-main' style={!props.readOnlyMode ? {} : {'padding': '0'}}>
            <div className={`picture-container ${props.readOnlyMode ? 'border-radius-smaller' : ''}`}>
               {
                  props.profileImageUrl ? <img src={props.profileImageUrl} alt="User Image"/> :
                     <Avatar sx={{backgroundColor: props.color}} className={'avatar-image'}>{props.name.slice(0, 1).toUpperCase()}</Avatar>
               }
            </div>
            <div className='info-container'>
               <h2>{props.name} {props.surname}</h2>
               <p>{capitalizeFirstLetter(props.jobPositionName)}</p>
            </div>
         </div>
      </div>
   )
}

export default EmployeeInfo;