import { useState, useContext } from "react";
import AuthContext from '../../../../../../store/auth-context';

const DescriptionComponent: React.FC<{extractSkillsFromDescription: Function}> = (props) => {
  const [projectDescriptionInput, setProjectDescriptionInput] = useState<string>('');
  const authStore = useContext(AuthContext);
  
  return (
    <div className='description-wrapper'>
      <div className='textarea-wrapper'>
        <textarea id='projectDescription' autoComplete='off' placeholder='Describe your task...'
          value={projectDescriptionInput} onInput={insertProjectDescHandler} />
          <div className='button-wrapper'>
            {
              projectDescriptionInput.trim() !== '' && projectDescriptionInput.trim().length > 1 &&
                <button className='button button-primary u-margin-bottom-xxs' onClick={()=> props.extractSkillsFromDescription(authStore.userData.accessToken, projectDescriptionInput.trim())}>Extract skills</button>
            }
          </div>
      </div>
    </div> 
  )

  function insertProjectDescHandler(e: any) {
    e.target.style.height = 'auto';
    e.target.style.height = (e.target.scrollHeight) + 'px';
    setProjectDescriptionInput(e.target.value);
  }
}

export default DescriptionComponent;