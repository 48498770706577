import React, {useContext, useEffect, useState} from "react";
import styles from './JobPostingDashboardPage.module.scss';
import {
   BestCandidate,
   JobPostingDashboardDto, JobPostingSkillDto,
   JobPostingSkillsDashboardDto, SkillsDashboardDto
} from "../../../../../types/ManagerDashboardData";
import {Avatar} from "@mui/material";
import axios, {AxiosRequestConfig} from "axios";
import {showNotification} from "../../../../../ui/Toast/ToastNotification";
import ProjectsContext from "../../../../../store/projects-context";
import AuthContext from "../../../../../store/auth-context";
import useApiService from "../../../../../services/api.service";
import AddSkillModal from "../../ProjectsPage/ManagerDashboardPage/SettingsPage/SettingsNeededSkills/AddSkillModal/AddSkillModal";
import useLoadingSpinner from "../../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner";
import {ProjectDto} from "../../../../../types/Projects";
import {ErrorResponseDto} from "../../../../../types/ErrorData";
import {capitalizeFirstLetter} from "../../../../../utils/capitalize-first-letter";
import Tooltip from "../../../../../services/tooltip.service";
import {buildStyles, CircularProgressbarWithChildren} from "react-circular-progressbar";
import FilterComponent from "../../../FilterComponent/FilterComponent";

const filterOptions = [
   {
      title: "Alphabetical: A to Z",
      value: 'asc'
   },
   {
      title: "Alphabetical: Z to A",
      value:'desc'
   },
];

const JobPostingDashboardPage: React.FC<{
   projectData: ProjectDto
}> = props => {

   const jobPostingDashboardURL = process.env.REACT_APP_PUBLIC_URL + `/manager/task-matcher-dashboard`;
   const getCategoriesURL = process.env.REACT_APP_PUBLIC_URL + '/manager/categories-dropdown';

   const projectsStore = useContext(ProjectsContext);
   const authStore = useContext(AuthContext);
   const { refreshToken } = useApiService();
   const spinnerService = useLoadingSpinner();

   const [skills, setSkills] = useState<JobPostingSkillsDashboardDto[]>([]);
   const [bestCandidates, setBestCandidates] = useState<BestCandidate[]>([]);
   const [categories, setCategories] = useState<string[]>([]);
   const [selectedEmployee, setSelectedEmployee] = useState<BestCandidate>();
   const [sortType, setSortType] = useState<number>(1);
   const [skillSort, setSkillSort] = useState('asc');
   const [showAddSkillSection, setShowAddSkillSection] = useState(false);

   useEffect(() => {
      authStore.changePageTitle('Task Dashboard');
      initializeComponent(authStore.userData.accessToken);
      getCategoriesDropDown(authStore.userData.accessToken);
   }, []);

   return (
      <>
         {
            skills && bestCandidates &&
            <div className={styles['job-posting-dashboard-page']}>
               <h1 className="header-1">{props.projectData.name}</h1>
               <div className="job-posting-options">
                  <button className="button button-tertiary add-skill" onClick={prepareAddSkillModal}>
                     + Add a skill
                  </button>
               </div>
               <div className="job-posting-wrapper">
                  <div className="best-candidates">
                     <h2 className={'header-2 u-margin-bottom-xs'}>Best candidates ({bestCandidates.length})</h2>
                     <div className="candidates-container">
                        <div className="candidates-container-header">
                           <span className={'header-number'}>#</span>
                           <span className={'header-employee'}>Employee</span>
                           <button disabled={!selectedEmployee}
                                   className={`header-deselect-button button button-tertiary`}
                                   onClick={deselectCandidate}>Deselect
                           </button>
                           <span className={'header-skill'} onClick={changeSortType}><span
                              className={'header-skill-title'}>Skill knows</span>
                           <div className="header-skill-container">
                              <i className={`fa-solid fa-sort-up ${sortType === 1 ? 'selected' : ''}`}></i>
                              <i className={`fa-solid fa-sort-down ${sortType === -1 ? 'selected' : ''}`}></i>
                           </div>
                        </span>
                        </div>
                        <div className="candidates-container-body">
                           {
                              sortEmployees(sortType).map(bestCandidate => {
                                 const ifSelected = selectedEmployee?.employee.id === bestCandidate.employee.id;
                                 return (
                                    <div onClick={() => selectCandidate(bestCandidate)} key={bestCandidate.employee.id}
                                         className={`candidates-card ${bestCandidate.rank === 1 ? 'first-rank' : ''} ${(selectedEmployee && !ifSelected) ? 'disabled' : ''}
                                      ${ifSelected ? 'selected' : ''}`}>
                                       <div className={`candidates-card-rank`}><span>{bestCandidate.rank}</span></div>
                                       <div className={`candidates-card-image`}>{bestCandidate.employee.profilePicture ?
                                          <img src={bestCandidate.employee.profilePicture} alt="Candidate picture"
                                               className={'candidate-image'}/> :
                                          <Avatar sx={{backgroundColor: bestCandidate.employee.color}}
                                                  className={'avatar-image'}>{bestCandidate.employee.name.slice(0, 1).toUpperCase()}</Avatar>}</div>
                                       <div className={'candidates-card-name'}>{bestCandidate.employee.name}</div>
                                       <div className={'candidates-card-count'}>{bestCandidate.skillsCount}</div>
                                    </div>
                                 )
                              })
                           }
                        </div>
                     </div>
                  </div>
                  <section className="skills-section">
                     <h2 className="header-2">Skills ({skills.length})</h2>
                     <FilterComponent sort={(sortType: string) => setSkillSort(sortType)} id='SkillsSortButton' filterOptions={filterOptions} />
                     <div className="skill-card-list-container u-margin-top-xs">
                        {
                           sortSkills(skills).map((skill, index) => {
                              const selected = selectedEmployee?.skills.find(s => s.skillId === skill.skill.id);
                              return (
                                 <div className={`skill-card ${selectedEmployee ? selected ? '' : 'hidden' : ''}`} key={index}>
                                    <div className="skill-card-name-container">
                                       <h3 className="skill-card-name header-3">{capitalizeFirstLetter(skill.skill.synonym ? skill.skill.synonym.name : skill.skill.name)}</h3>
                                       <div className="skill-card-tooltip">
                                          <Tooltip tooltipId={`tooltip-skills-information_${skill.skill.id}`}
                                                   place="right"
                                                   backgroundColor="#101319"
                                                   borderColor='#5C5F6A !important'
                                                   border={true}
                                                   content={
                                                      <div>{skill.skill.description}</div>
                                                   }
                                                   className={'custom-tooltip'}>
                                             <i className="fa-solid fa-circle-question u-margin-left-xs"></i>
                                          </Tooltip>
                                       </div>
                                    </div>
                                    <div className={`avatars-container`}>
                                       {
                                          skill.employees.length > 0 ?
                                             skill.employees.slice(0, 2).map((employee, index) => {
                                                return (
                                                   <div className="avatar" key={index}>
                                                      <CircularProgressbarWithChildren
                                                         value={employee.validationFormulaScore}
                                                         strokeWidth={8}
                                                         styles={buildStyles({
                                                            pathColor: '#A0A8E5',
                                                            trailColor: '#191D24',
                                                            strokeLinecap: "butt"
                                                         })}>
                                                         {
                                                            employee.profilePicture ? <img className={'avatar-image'}
                                                                                           src={employee.profilePicture}
                                                                                           alt={'Employee Picture'}/> :
                                                               <Avatar sx={{backgroundColor: employee.color}}
                                                                       className={'avatar-image'}>{employee.name.slice(0, 1).toUpperCase()}</Avatar>
                                                         }
                                                      </CircularProgressbarWithChildren>
                                                   </div>
                                                );
                                             }) : <div className="missing-button">Missing</div>
                                       }
                                       {
                                          skill.employees.length - 3 > 0 &&
                                          <div className="avatar">
                                             <CircularProgressbarWithChildren
                                                value={100}
                                                strokeWidth={6}
                                                styles={buildStyles({
                                                   pathColor: '#2F303CFF',
                                                   trailColor: '#191D24',
                                                   strokeLinecap: "butt"
                                                })}><Avatar sx={{backgroundColor: "#0E0C19FF"}} className={'avatar-image'}>{skill.employees.length - 3}+</Avatar></CircularProgressbarWithChildren>
                                          </div>
                                       }
                                    </div>
                                 </div>
                              )
                           })
                        }
                     </div>
                  </section>
               </div>
            </div>
         }
         {showAddSkillSection &&
            <AddSkillModal jobPosting={true} projectData={props.projectData} closelModal={closeAddSkillModal}/>
         }
         {
            spinnerService.spinner
         }
      </>
   )

   function sortSkills(skills: JobPostingSkillsDashboardDto[]) {
      return skills.sort((a, b) => {
         const comparator = skillSort === 'asc' ? 1 : -1;
         return a.skill.name.toLowerCase().localeCompare(b.skill.name.toLowerCase()) * comparator;
      })
   }

   function deselectCandidate() {
      if (selectedEmployee) {
         setSelectedEmployee(undefined);
      }
   }

   function selectCandidate(candidate: BestCandidate) {
      if (selectedEmployee && selectedEmployee.employee.id === candidate.employee.id) {
         setSelectedEmployee(undefined);
      } else {
         setSelectedEmployee(candidate);
      }
   }

   function closeAddSkillModal() {
      setShowAddSkillSection(false);
      initializeComponent(authStore.userData.accessToken);
   }

   function sortEmployees(sortType: number) {
      return bestCandidates.sort((c1, c2) => {
         return (sortType === 1 ? c1.rank - c2.rank : c2.rank - c1.rank);
      })
   }

   function changeSortType() {
      setSortType(prevState => prevState * -1);
   }

   function prepareAddSkillModal() {
      initializeAddSkillModal(authStore.userData.accessToken);
   }

   function initializeComponent(accessToken: string) {

      spinnerService.createSpinner();

      const req = {
         projectId: props.projectData.id
      }

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      axios
         .post(jobPostingDashboardURL, req, {headers})
         .then((response$: { data: JobPostingDashboardDto }) => {
            setSkills(response$.data.dashboardData);
            setBestCandidates(response$.data.bestCandidates);
            spinnerService.removeSpinner();
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     initializeComponent(response$.data.accessToken);
                  })
            } else {
               spinnerService.removeSpinner();
               showNotification('warning', error$.response.data.message);
            }
         })
   }

   function getCategoriesDropDown(accessToken: string) {

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      const data = {
         projectId: props.projectData.id,
         isProjectSkillSet: true
      }

      axios
         .post(getCategoriesURL, data, {headers})
         .then((response$: { data: string[] }) => {
            setCategories(response$.data);
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     getCategoriesDropDown(response$.data.accessToken);
                  })
            } else {
               showNotification('warning', error$.response.data.message);
            }
         })
   }

   function initializeAddSkillModal(accessToken: string) {
      spinnerService.createSpinner();
      projectsStore.getCompanyEmployees(props.projectData.id, accessToken);

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      axios.get(process.env.REACT_APP_PUBLIC_URL + '/manager/project-panel', {
            params: {
               projectId: props.projectData.id
            },
            headers
         })
         .then((response$: any) => {
            projectsStore.initialProjectEmployees(response$.data.projectEmployees);
            projectsStore.initialNeededSkills(response$.data.neededSkills);
            setShowAddSkillSection(true);
            spinnerService.removeSpinner();
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     initializeAddSkillModal(response$.data.accessToken);
                  })
            } else {
               spinnerService.removeSpinner();
               showNotification('warning', error$.response.data.message);
            }
         })
   }
}

export default JobPostingDashboardPage;