import React, {useContext, useEffect, useState} from "react";
import style from "./RolePage.module.scss";
import {animated, useTransition} from "react-spring";
import AuthContext from "../../../../../store/auth-context";
import useApiService from "../../../../../services/api.service";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {ErrorResponseDto} from "../../../../../types/ErrorData";
import {showNotification} from "../../../../../ui/Toast/ToastNotification";
import {JobPositionDto, JobPositionsDto} from "../../../../../types/EmployeeOnboardData";
import {capitalizeFirstLetter} from "../../../../../utils/capitalize-first-letter";
import Tooltip from "../../../../../services/tooltip.service";
import useLoadingSpinner from "../../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner";

const RolePage: React.FC<{
   changeSection: Function;
}> = props => {

   const authStore = useContext(AuthContext);
   const {refreshToken} = useApiService();

   const [jobPositions, setJobPositions] = useState<JobPositionDto[]>([]);
   const [selectedJobPosition, setSelectedJobPosition] = React.useState<JobPositionDto>();
   const [initialJobPosition, setInitialJobPosition] = React.useState<JobPositionDto>();
   const spinnerService = useLoadingSpinner();

   const transition2 = useTransition(initialJobPosition, {
      from: { opacity: 0 },
      enter: { opacity: 1 },
   })

   const transitions = useTransition(jobPositions, {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 1 },
      trail: 200,
      delay: 500,
      update: null,
      key: (item: JobPositionDto) => {
         return item.id
      },
   })

   useEffect(() => {
      initializeComponent(authStore.userData.accessToken);
   }, []);

   return (
      <>
         <div className={style["role-page"]}>
            <h1 className="header-1 u-margin-bottom-m">Which role below best describes your main work activities?</h1>
            <section className="job-positions">
               {
                  !initialJobPosition && jobPositions.length === 0 ?
                     <>
                        <h3 className="header-3">No roles present at this moment.</h3>
                        <p className="text-surface-30">It seems we don't have any roles listed right now. Please feel free to contact us if this is a problem for you.</p>
                     </> :
                     <>
                        {
                           initialJobPosition &&
                           transition2((style, item) => {
                              return <animated.div style={style}>
                                 <p>It looks like you have preselected role:</p>
                                 <div className={`activity ${selectedJobPosition?.id === item?.id ? "active" : ""} u-margin-top-xs u-margin-bottom-xs`}
                                      onClick={() => !selectedJobPosition ? setSelectedJobPosition(item) : setSelectedJobPosition(undefined)}>
                                    <span>{capitalizeFirstLetter(item?.name)}</span>
                                    <Tooltip tooltipId={`${item?.id}_jobPosition`}
                                             className={`custom-tooltip`}
                                             place="right"
                                             content={<>{item?.description}</>}>
                                       <i className="fa-regular fa-circle-question"></i>
                                    </Tooltip>
                                 </div>
                                 <p className={"u-margin-top-l"}>Not quite the right fit? Choose your most suitable job title:</p>
                              </animated.div>
                           })
                        }
                        <div className="activities">
                           {
                              transitions((style, item) => {
                                 return (
                                    <animated.div style={style} key={item.id} id={item.id}
                                                  className={`activity ${item.id === selectedJobPosition?.id ? "active" : ""}`}
                                                  onClick={() => !selectedJobPosition ? setSelectedJobPosition(item) : setSelectedJobPosition(undefined)}>
                                       <span>{capitalizeFirstLetter(item.name)}</span>
                                       <Tooltip tooltipId={`${item.id}_jobPosition`}
                                                className={`custom-tooltip`}
                                                place="right"
                                                content={<>{item.description}</>}>
                                          <i className="fa-regular fa-circle-question"></i>
                                       </Tooltip>
                                    </animated.div>
                                 )
                              })
                           }
                        </div>
                     </>
               }
            </section>

            <div className="buttons-container">
               <button className="button button-secondary" onClick={() => props.changeSection(true)}><i
                  className="fa-solid fa-arrow-left"></i> Back</button>
               <div>
                  <button className="button button-primary" onClick={() => chooseJobPosition(authStore.userData.accessToken)}>
                     {
                        selectedJobPosition ?
                        <>Next <i className="fa-solid fa-arrow-right"></i></> : "Skip"
                     }
                  </button>
               </div>
            </div>
         </div>
         {
            spinnerService.spinner
         }
      </>
   )

   function chooseJobPosition(accessToken: string) {
      const chooseJobPositionURL = process.env.REACT_APP_PUBLIC_URL + "/employee/onboarding/set-job-position";

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      const data = {
         jobPositionId: selectedJobPosition?.id
      }

      if (!data.jobPositionId) {
         props.changeSection();
         return;
      }

      spinnerService.createSpinner();

      axios
         .post(chooseJobPositionURL, data, {headers})
         .then(_response$ => {
            props.changeSection();
            spinnerService.removeSpinner();
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     chooseJobPosition(response$.data.accessToken);
                  })
            }
            else {
               spinnerService.removeSpinner();
               showNotification('warning', error$.response.data.message);
            }
         })

   }

   function initializeComponent(accessToken: string) {
      const getJobPositionsURL = process.env.REACT_APP_PUBLIC_URL + "/employee/similar-job-positions";

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      spinnerService.createSpinner();

      axios
         .post(getJobPositionsURL, undefined, {headers})
         .then((response$: AxiosResponse<JobPositionsDto>) => {
            setJobPositions(response$.data.similarJobPositions.filter(jobPosition => jobPosition.id !== response$.data.currentJobPosition?.id));
            setSelectedJobPosition(response$.data.currentJobPosition);
            setInitialJobPosition(response$.data.currentJobPosition);
            spinnerService.removeSpinner();
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     initializeComponent(response$.data.accessToken);
                  })
            }
            else {
               spinnerService.removeSpinner();
               showNotification('warning', error$.response.data.message);
            }
         })

   }

}

export default RolePage;