import { useState, useRef, useContext, useEffect } from 'react';
import ReactDOM from 'react-dom';

import styles from './PersonalInfoModal.module.scss';

import SpinnerButton from '../../../../../../../ui/SpinnerButton/SpinnerButton';

const PersonalInfoModal: React.FC<{okCallback: Function, removeModal: React.MouseEventHandler<HTMLButtonElement>, 
  data: {name: string}, dataIsLoading: boolean }> = (props) => {  
  
  const displayName = useRef<any>();
  const initialDisplayName = useRef<any>();
  const [errorMessagge, setErrorMessagge] = useState(false);

  useEffect(() => { 
    initialDisplayName.current = props.data.name;   
  }, [])
  
  let modal = (
    <div className={styles['personal-info-modal']}>
      <div className='card card-modal'>
        <div className='card-header'>
          <h2>Change general information</h2>
          <div className='close-modal-button'>
            <i className="fa-solid fa-xmark" onClick={props.removeModal}></i>
          </div>  
        </div>
        <div className="card-body">
          <label htmlFor="displayname" className="form-label">Display name</label>
          <div className="mb-4 input-wrapper">
            <input onKeyDown={onEnterDownHandler} type="text" className={`form-control `} id="displayname" defaultValue={props.data.name} ref={displayName} autoComplete='off' autoFocus />
          </div>
          {
              errorMessagge && <p className="error-message mt-1">Please insert new value</p>
          }
        </div>
        <div className="card-footer">
          <div className='buttons-wrapper'>
            <button className='button button-secondary u-margin-right-xs' onClick={props.removeModal}>Cancel</button>
            <SpinnerButton cssClasses='button button-primary' title='Save'
            dataLoading={props.dataIsLoading} buttonType='button' onClickCallback={changeDisplayNameHandler}></SpinnerButton>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    ReactDOM.createPortal(modal, document.getElementById('modal-root') as HTMLDivElement)
  )

  function onEnterDownHandler(e : any) {
    if (e.key === 'Enter') {
      (document.getElementsByClassName('submit-button')[0] as HTMLButtonElement).click();
    }
  }

  function changeDisplayNameHandler() {
    document.getElementsByClassName('input-wrapper')[0].classList.remove('error');

    if (displayName.current.value.trim().length === 0) {
      document.getElementsByClassName('input-wrapper')[0].classList.add('error');
    }
    else {
      if (displayName.current.value.trim() !== initialDisplayName.current) {  
        props.okCallback(displayName.current.value.trim());
      }
      else {
        setErrorMessagge(true);
      }
    }
  }
}

export default PersonalInfoModal;