import ReactDOM from "react-dom";
import styles from './ChangeProfilePictureModal.module.scss'
import SpinnerButton from "../../../../../../../ui/SpinnerButton/SpinnerButton";
import CropImageComponent from "../../ImageUploadComponent/ImageCropComponent/CropImageComponent";
import {useState} from "react";

const ChangeProfilePictureModal = (props : any) => {

    const [clickedSave, setClickedSave] = useState(false);
    const [dataLoading, setDataLoading] = useState(props.dataIsLoading)

    const clickedSaveHandler = () => {
        setDataLoading(true)
        setClickedSave(true);
    }

    const uploadedCroppedImageHandler = (file : any) => {
        props.onUploadedCroppedImage(file)
    }

    let modal = (
        <div className={styles['profile-picture-modal']}>
            <div className='card card-modal'>
                <div className='card-header'>
                    <h2>Change profile picture</h2>
                    <div className='close-modal-button'>
                        <i className="fa-solid fa-xmark" onClick={props.removeModal}></i>
                    </div>
                </div>
                <div className="card-body">
                    {/*<div className="mb-4 input-wrapper">*/}
                    {/*    <label htmlFor="displayname" className="form-label">Display name</label>*/}
                    {/*    <input type="text" className={`form-control `} id="displayname" defaultValue={props.data.name}  autoComplete='off' autoFocus />*/}
                    {/*</div>*/}
                    <CropImageComponent uploadCroppedImage={uploadedCroppedImageHandler} clickedSave={clickedSave} photoUrl={props.data.photoUrl} />
                </div>
                <div className="card-footer">
                    <div className='buttons-wrapper'>
                        <button className='button button-secondary u-margin-right-xs' onClick={props.removeModal}>Cancel</button>
                        <SpinnerButton onClickCallback={clickedSaveHandler} cssClasses='button button-primary' title='Save'
                                       dataLoading={dataLoading} buttonType='button'></SpinnerButton>
                    </div>
                </div>
            </div>
        </div>
    )

    return (
        ReactDOM.createPortal(modal, document.getElementById('modal-root') as HTMLDivElement)
    )
}

export default ChangeProfilePictureModal;