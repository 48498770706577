import React, { useState, useContext, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import styles from './AdminOnboardingPage.module.scss';
import AuthContext from '../../../../store/auth-context';

import WelcomeComponent from './WelcomeComponent/WelcomeComponent';

import useLoadingSpinner from '../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner';
import useApiService from '../../../../services/api.service';
import { UserData } from '../../../../types/AuthData';
import {showNotification} from "../../../../ui/Toast/ToastNotification";
import {UserDataDto} from "../../../../types/AdminData";
import AddColleaguesComponent from "../AdminPanelPage/AddEmployeeComponent/AddColleaguesComponent/AddColleaguesComponent";
import EmployeesGridComponent from "../AdminPanelPage/AddEmployeeComponent/EmployeesGrid/EmployeesGridComponent";
import AdminPanelContext from "../../../../store/admin-panel-context";
import {ErrorResponseDto} from "../../../../types/ErrorData";
import AddCategoriesComponent from "./AddCategoriesComponent/AddCategoriesComponent";

const AdminOnboardingPage: React.FC = () => {
  const authStore = useContext(AuthContext);
  const userData: UserData = authStore.userData;
  const spinnerService = useLoadingSpinner();
  const [componentIsInit, setComponentIsInit] = useState(false);
  const { refreshToken } = useApiService();

  const [activeSection, setActiveSection] = useState<string>('welcome');
  const navigate = useNavigate();

  const adminPanelStore = useContext(AdminPanelContext);

  const [showOnboardingFinishedInfo, setShowOnboardingFinishedInfo] = useState(false);

  useEffect(()=> {
    initializeComponent(userData.accessToken);
  }, [])

  return (
    <div className={styles['onboarding-wrapper']}>
      { componentIsInit &&
        <div className='onboarding-page'>
          <div className='onboarding-header'>
            <button className='button button-tertiary' onClick={authStore.logout}>
              <i className="fa-solid fa-arrow-right-from-bracket"></i> Log out
            </button>
          </div>
          <div className='onboarding-content'>
            { !showOnboardingFinishedInfo ?
              <>
                { 
                  activeSection === 'welcome' && <WelcomeComponent changeSection={setActiveSection} user={authStore.userData.fullName} />
                }
                {
                   activeSection === 'add-categories' && <AddCategoriesComponent changeSection={setActiveSection} />
                }
                {
                  activeSection === 'add-employee-upload' && <AddColleaguesComponent changeEmployeesSection={setActiveSection} />
                }
                {
                  activeSection === 'add-employee-grid' && <EmployeesGridComponent gridData={adminPanelStore.userData} changeEmployeesSection={setActiveSection} changeSection={setActiveSection} adminOnboarding={() => setShowOnboardingFinishedInfo(true)} />
                }
              </> :
                <div className='onboarding-process-finished-info'>
                  <div className='onboarding-process-finished-wrapper'>
                    <div className='icon-wrapper'>
                      <i className="fa-regular fa-circle-check"></i>
                    </div>
                    <h2>Congratulations!</h2>
                    <p>Onboarding process has been successfully completed.</p>
                    <div style={{'textAlign': 'center', 'marginTop': '18px'}}>
                      <button className='btn btn-blue-custom' style={{'fontWeight': '500'}} onClick={goToAdminPanel}>Go to the admin panel</button>
                    </div>
                  </div>
              </div>
            }
          </div>
        </div>
      }
      {
        activeSection !== 'welcome' && <div className='onboarding-background'></div>        
      }  
      {
        spinnerService.spinner
      }
    </div>
  )

  function initializeComponent(accessToken: string): void {
    spinnerService.createSpinner();

    adminPanelStore.getAdminPanel(accessToken)
       .then((data: UserDataDto[])=> {
         setComponentIsInit(true);
         if (data === undefined) {
            goToAdminPanel();
            return;
         }
         if (data.length > 0) {
            setActiveSection('add-employee-grid');
         }
         spinnerService.removeSpinner();
       })
      .catch((error$: ErrorResponseDto)=> {
        if (error$.response.data.message === 'Unauthorized') {
          // Get new Access Token
          refreshToken(authStore.userData.refreshToken)
            .then((response$: any) => {		
              authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
              initializeComponent(response$.data.accessToken);						
            })
        }
        else {
          setComponentIsInit(true);
          spinnerService.removeSpinner();
          showNotification('warning', error$.response.data.message);
        }
      })
  }

  function goToAdminPanel() {
      navigate('/admin-onboard/admin-panel');
  }
}

export default AdminOnboardingPage;