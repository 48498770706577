import axios, {AxiosRequestConfig} from 'axios';
import React, { createContext, useContext, useState } from 'react';
import AuthContext from './auth-context';
import useApiService from '../services/api.service';
import { NewNeededSkillData, CompanyEmployeesData, ProjectEmployeeData, ExtractedNeededSkillData, SearchSkillResultData, SkillCategoryData } from '../types/ProjectOnboardingData';
import {ManagerProjectsData} from "../types/Projects";
import {
  CompanyEmployeesDto,
  CreateProjectResponseDto,
  ExtractedNeededSkillsAndUrlDto,
  ExtractedNeededSkillsDto,
  ProjectEmployeeDto,
  ProjectSkillDto,
  SaveNewNeededSkillDto,
  SearchSynonymResultDto,
  SkillsPaginationDto
} from "../types/ProjectsStoreTypes";
import {NeededPeopleData} from "../types/NeededPeopleData";

const ProjectsContext = createContext({
  projectsData: {projects: []},
  neededSkillsData: [],
  companyEmployeesData: [],
  projectEmployeesData: [],
  neededSkillsTempData: [],
  searchSkillResultData: [],
  skillCategoriesData: [],
  extractedNeededSkillsData: [],
  similarSkillsResponseData: {},
  neededSkillsDetectChanges: false,


  toggleNeededSkillsTempData: (id: string): void => { },
  resetExtractedSkillsData: (): void => { },
  searchSkill: (searchTerm: string, page: number, projectId: any, accessToken: string): any => {},
  getSkillCategories: (accessToken: string): any => {},
  searchAISimilarSkills: (payload: any, accessToken: string): any => { },
  addNewSkillOrSynonymNeededSkill: (payload: any, projectId: any, accessToken: string): any => { },
  addNeededSkill: (skillId: number, projectId: number, accessToken: string): any => { },
  deleteProjectSkill: (projectSkillIds: number[], projectId: any, accessToken: string): any => { },
  getCompanyEmployees: (projectId: any, accessToken: string): any => { },
  getExtractedNeededSkillsAI: (projectId: any, accessToken: string): any => { },
  uploadNeededSkillsFileHandler: (file: any, projectId: any, accessToken: string): any => { },
  sendTextNeededSkills: (description: string, projectId: any, accessToken: string): any => { },
  selectExtractedNeededSkill: (projectDataIds: number[], projectId: any, accessToken: string): any => { },
  updateProject: (name: string, description: string, projectId: any, accessToken: string): any => { },
  initialProjectEmployees: (data: any): any => { },
  initialNeededSkills: (data: any): any => { },
  assignProjectTeamLeads: (projectEmployees: number[], projectId: any, accessToken: string): any => { },
  removeProjectTeamLeads: (projectEmployees: number[], projectId: any, accessToken: string): any => { },
  loadSkillAndEmployeesOptions:(searchTerm: string, page: number, accessToken: string, url: string, projectId?: number): any => { },
  deleteProjectEmployees: (projectEmployeeId: number, projectId: any, accessToken: string): any => { },
  updateProjectData: (data: ManagerProjectsData) => { },
  changeFirstAttempt: (projectId: number, accessToken: string): any => { }
});

export const ProjectsContextProvider: React.FC<{children : React.ReactNode}> = (props) => { 
  const authStore = useContext(AuthContext);
  const apiService = useApiService();

  const [projectsData, setProjectsData] = useState<ManagerProjectsData>({onboarding: false, projects: []});
  const [neededSkillsData, setNeededSkillsData] = useState<ProjectSkillDto[]>([]);
  const [neededSkillsTempData, setNeededSkillsTempData] = useState<ExtractedNeededSkillsDto[]>([]);
  const [neededSkillsDetectChanges, setNeededSkillsDetectChanges] = useState<boolean>(false);
  const [companyEmployeesData, setCompanyEmployeesData] = useState<CompanyEmployeesData[]>([]);
  const [projectEmployeesData, setProjectEmployeesData] = useState<ProjectEmployeeDto[]>([]);
  const [searchSkillResultData, setSearchSkillResultData] = useState<SearchSkillResultData[]>([]);
  const [skillCategoriesData, setSkillCategoriesData] = useState<string[]>([]);
  const [similarSkillsResponseData, setSimilarSkillsResponseData] = useState<SearchSynonymResultDto>();
  const [extractedNeededSkillsData, setExtractedNeededSkillsData] = useState<ExtractedNeededSkillData[]>([]);

  async function searchSkill(searchTerm: string, page: number, projectId: any, accessToken: string) {
    const searchSkillURL: string = process.env.REACT_APP_PUBLIC_URL + '/company/search-taxonomy-skills';
    const paginationSize: number = 100;

    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }
    let params: {} = {
      projectId: projectId,
      searchTerm: searchTerm,
      page: page,
      pageSize: paginationSize
    }
    let promise = new Promise((resolve: any, reject: any) => {
      axios
        .post(searchSkillURL, params,{ headers })
        .then((response$: {data: SkillsPaginationDto}) => {
          resolve(response$.data);
        })
        .catch((error$) => {
          reject(error$);
        });
    });
    return await promise;
  }

  async function getSkillCategories(accessToken: string) {
    const getSkillCategoriesURL: string = process.env.REACT_APP_PUBLIC_URL + '/skill/categories';

    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }
    let promise = new Promise((resolve: any, reject: any) => {
      axios
        .get(getSkillCategoriesURL, { headers })
        .then((response$: {data: SkillCategoryData[]}) => {
          const categories: string[] = response$.data
             .filter(item => item.category !== null && item.category !== undefined) // Filter out null and undefined
             .map(item => item.category as string); // Cast to string because we filtered out null and undefined

          setSkillCategoriesData(categories);
          resolve();
        })
        .catch((error$) => {
          reject(error$);
        });
    });
    return await promise;
  }

  function toggleNeededSkillsTempData (id: string): void {
    const tempData = neededSkillsTempData.map(neededSkill => {
      return {
        ...neededSkill,
        deleted: neededSkill.skillAIId === id ? !neededSkill.deleted : neededSkill.deleted
      }
    })
    setNeededSkillsTempData(tempData);
  }

  function resetExtractedSkillsData () {
    setExtractedNeededSkillsData([]);
  }

  async function searchAISimilarSkills(skillName: string, accessToken: string) {
    const searchAISimilarSkillsURL: string = process.env.REACT_APP_PUBLIC_URL + '/project/search-ai-similar-skills';

    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }

    let params = {
      skillName: skillName
    }

    let promise = new Promise((resolve: any, reject: any) => {
      axios
        .get(searchAISimilarSkillsURL, { headers, params })
        .then((response$: { data: SearchSynonymResultDto }) => {
          setSimilarSkillsResponseData(response$.data);
          resolve(response$.data);
        })
        .catch((error$) => {
          reject(error$);
        });
    });
    return await promise;
  }

  async function addNewSkillOrSynonymNeededSkill(payload: any, projectId: any, accessToken: string) {
    const addNewSkillOrSynonymNeededSkillURL: string = process.env.REACT_APP_PUBLIC_URL + '/project/confirm-skill-or-synonym';

    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }
    /**
     * "newNeededSkill": {
     *  "projectId": 1,
     *  "synonymName": 13019,
     *  "synonymAIId"[this is sent only when adding a synonym from the list when searching a skill]: 135585,
     *  "preferredSkillAIId"[this is sent only when adding a synonym to a preferred term from the list
     *                        or a synonym from the list when searching a skill]: "8ff7f58f3a1945aca5bc31b15592b775",
     *  "synonymDescription" [optional / not optional when adding a new skill]: "Nest. JS is a framework that helps build Node. JS server-side applications. The Nest framework is built using TypeScript which allows developers to build highly scalable and testable applications. Built on top of Express.",
     *  "synonymCategory" [optional / not optional when adding a new skill]: "information and communication technologies (icts)"
     * }
     */
    let data: {} = {
      ...payload,
      projectId: projectId
    }

    let promise = new Promise((resolve: any, reject: any) => {
      axios
        .post(addNewSkillOrSynonymNeededSkillURL, data, { headers })
        .then((response$: {data: SaveNewNeededSkillDto}) => {
          if (response$.data.tag !== 'EXISTING_PREFERRED_NEEDED_SKILL') {
            setNeededSkillsData([...neededSkillsData, response$.data.projectSkill]);
          } 
          resolve(response$.data);
        })
        .catch((error$) => {
          reject(error$);
        });
    });
    return await promise;
  }

  async function addNeededSkill(skillId: number, projectId: number, accessToken: string) {
    const addNeededSkillURL: string = process.env.REACT_APP_PUBLIC_URL + '/project/add-needed-skill';
    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }
    /**
     * "neededSkill": {
     *  "projectId": 1,
     *  "skillId": 13019
     * }
     */
    let data = {
      skillId: skillId,
      projectId: projectId
    }

    let promise = new Promise((resolve: any, reject: any) => {
      axios
        .post(addNeededSkillURL, data, { headers })
        .then((response$: {data: SaveNewNeededSkillDto}) => {
          setNeededSkillsData([...neededSkillsData, response$.data.projectSkill]);
          resolve(response$.data.projectSkill);
        })
        .catch((error$) => {
          reject(error$);
        });
    });
    return await promise;
  }

  async function deleteProjectSkill(projectSkillIds: number[], projectId: any, accessToken: string) {
    const deleteProjectSkillURL: string = process.env.REACT_APP_PUBLIC_URL + '/project/delete-project-skill';

    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }
    /**
     * "deleteProjectSkill": {
     *  "projectId": 1,
     *  "projectSkillId": 31
     *  "projectSkillId": 31
     *  }
     */
    let data = {
      projectSkillIds: projectSkillIds,
      projectId: projectId
    }

    let promise = new Promise((resolve: any, reject: any) => {
      axios
        .post(deleteProjectSkillURL, data, { headers })
        .then((response$: {data: ProjectSkillDto[]}) => {
          setNeededSkillsData(response$.data);
          resolve();
        })
        .catch((error$) => {
          reject(error$);
        });
    });
    return await promise;
  }

  async function getCompanyEmployees(projectId: any, accessToken: string) {
    const getCompanyEmployeesURL: string = process.env.REACT_APP_PUBLIC_URL + '/project/company-employees';

    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }
    let params = {
      projectId: projectId
    }
    let promise = new Promise((resolve: any, reject: any) => {
      axios
        .get(getCompanyEmployeesURL, { headers, params })
        .then((response$: {data: CompanyEmployeesDto[]}) => {
          setCompanyEmployeesData(response$.data);
          resolve(response$.data);
        })
        .catch((error$) => {
          reject(error$);
        });
    });
    return await promise;
  }

  async function getExtractedNeededSkillsAI(projectId: any, accessToken: string) {
    const extractedNeededSkillsAIURL: string = process.env.REACT_APP_PUBLIC_URL + '/project/extracted-needed-skills-ai';

    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }
    let params: {} = {
      'projectId': projectId
    }

    let promise = new Promise((resolve: any, reject: any) => {
      axios
        .get(extractedNeededSkillsAIURL, { headers, params })
        .then((response$: {data: ExtractedNeededSkillsDto[] | ExtractedNeededSkillsAndUrlDto}) => {
          let tempData: any = JSON.parse(JSON.stringify(neededSkillsTempData));

          let skills: ExtractedNeededSkillsDto[] = [];

          if ((response$.data as ExtractedNeededSkillsAndUrlDto).csvUrl) {
            // setCSVFileDownloadURL(response$.data.csvUrl)
            skills = (response$.data as ExtractedNeededSkillsAndUrlDto).extractedNeededSkills;
          }
          else {
            skills = response$.data as ExtractedNeededSkillsDto[];
          }

          for (let skill of skills) {
            let id = skill.skillAIId;
            let neededSkill = tempData.find((item: ExtractedNeededSkillData) => {
              return item.skillAIId === id;
            });
            if (!neededSkill) {
              let tempSkill: ExtractedNeededSkillData = JSON.parse(JSON.stringify(skill));
              tempSkill.deleted = false;
              tempData.push(tempSkill);
            }
          }
          setNeededSkillsTempData(tempData);
          setExtractedNeededSkillsData([...skills]);
          resolve(skills);
        })
        .catch((error$) => {
            reject(error$);
        });
    });
    return await promise;
  }

  async function uploadNeededSkillsFileHandler(file: any, projectId: any, accessToken: string) {
    const uploadNeededSkillFileURL: string = process.env.REACT_APP_PUBLIC_URL + '/file/upload-needed-skill-file';

    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }
    let params: {} = {
      'projectId': projectId
    }

    const bodyFormData = new FormData();
    bodyFormData.append('file', file);

    let promise = new Promise((resolve: any, reject: any) => {
      axios
        .post(uploadNeededSkillFileURL, bodyFormData, {
          headers, params
        })
        .then(() => {
          resolve();
        })
        .catch((error$) => {
            reject(error$);
        });
    });
    return await promise;
  };


  async function sendTextNeededSkills(description: string, projectId: any, accessToken: string) {
    const sendTextNeededSkillsURL: string = process.env.REACT_APP_PUBLIC_URL + '/project/extract-needed-skills-text';

    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }
    /**
     * "projectOnboardingNeededSkillDto": {
     *  "projectId": 1,
     *  "projectDescription": "Project description"
     * }
     */

    let data = {
      projectDescription: description,
      projectId: +projectId
    }

    let promise = new Promise((resolve: any, reject: any) => {
      axios
        .post(sendTextNeededSkillsURL, data, { headers })
         .then((response$: {data: ExtractedNeededSkillsDto[] | ExtractedNeededSkillsAndUrlDto}) => {
           let skills: ExtractedNeededSkillsDto[] = [];

           if ((response$.data as ExtractedNeededSkillsAndUrlDto).csvUrl) {
             skills = (response$.data as ExtractedNeededSkillsAndUrlDto).extractedNeededSkills;
           }
           else {
             skills = response$.data as ExtractedNeededSkillsDto[];
           }
           const temp: ExtractedNeededSkillsDto[] = [];
           skills.forEach(skill => {
             const exists = neededSkillsTempData.some(neededSkill => neededSkill.skillAIId === skill.skillAIId);
             const tempSkill = { ...skill, deleted: !exists ? false : skill.deleted };
             temp.push(tempSkill);
           })
           setNeededSkillsTempData(temp);
           setExtractedNeededSkillsData([...skills]);
           resolve(skills);
         })
        .catch((error$) => {
          reject(error$);
        });
    });
    return await promise;
  }

  async function selectExtractedNeededSkill(projectDataIds: number[], projectId: number, accessToken: string) {
    const selectExtractedNeededSkillsURL: string = process.env.REACT_APP_PUBLIC_URL + '/project/select-extracted-needed-skills';

    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }
    /**
     * "extractedNeededSkills": {
     *  "projectId": 1,
     *  "projectDataIds": [7, 6, 8, 12, 13, 14, 15]
     * }
     */
    let data = {
      projectDataIds: projectDataIds,
      projectId: +projectId
    }

    let promise = new Promise((resolve: any, reject: any) => {
      axios
        .post(selectExtractedNeededSkillsURL, data, { headers })
        .then((response$: {data: ProjectSkillDto[]}) => {
          setNeededSkillsData(response$.data);
          setNeededSkillsTempData([]);
          setExtractedNeededSkillsData([]);
          resolve();
        })
        .catch((error$) => {
          reject(error$);
        });
    });
    return await promise;
  }


  async function updateProject(name: string, projectDescription : string, projectId: any, accessToken: string) {
    const updateProjectURL: string = process.env.REACT_APP_PUBLIC_URL + '/project/update';

    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }
    /**
     * "project": {
    *   "projectId": 2,
     *  "projectName": "Project name",
     *  "projectDescription": "Project description" - this is optional
     * }
     */
    let data: {} = {
      projectName: name,
      projectId: projectId,
      projectDescription
    }

    let promise = new Promise((resolve: any, reject: any) => {
      axios
        .post(updateProjectURL, data, { headers })
        .then((response$: {data: CreateProjectResponseDto}) => {
          let currentProject = projectsData.projects.find(item=> item.id === response$.data.project.id );
          if (currentProject) {
            currentProject.name = name;
            currentProject.description = projectDescription;
            setProjectsData({...projectsData});
          }
          resolve();
        })
        .catch((error$) => {
          reject(error$);
        });
    });
    return await promise;
  }

  async function deleteProjectEmployees(projectEmployeeId: number, projectId: any, accessToken: string) {
    const deleteProjectEmployeeURL: string = process.env.REACT_APP_PUBLIC_URL + '/project/delete-project-employee';

    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }
    /**
     * "deleteProjectEmployee" : {
     *  "projectId": 1,
     *  "projectEmployeeId": 4 
     *  }
     */
    let data: {} = {
      deleteProjectEmployee: { projectEmployeeId: projectEmployeeId, projectId: projectId }
    }

    let promise = new Promise((resolve: any, reject: any) => {
      axios
        .post(deleteProjectEmployeeURL, data, { headers })
        .then((response$: {data: NeededPeopleData}) => {
          let updatedProjectEmployeeList = projectEmployeesData.filter((p) => p.id != projectEmployeeId);
          setProjectEmployeesData([...updatedProjectEmployeeList]);
          resolve(updatedProjectEmployeeList);
        })
        .catch((error$) => {
          reject(error$);
        });
    });
    return await promise;
  }

  async function assignProjectTeamLeads(projectEmployees: number[], projectId: any, accessToken: string) {
    const assignTeamLeadsURL: string = process.env.REACT_APP_PUBLIC_URL + '/project/add-team-leads';

    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }
    /**
     * "teamLeadsIds" : {
     *  "projectId": 1,
     *  "projectEmployees": [5, 6]
     *  }
     */
    let data: {} = {
      projectEmployees: projectEmployees,
      projectId: projectId
    }

    let promise = new Promise((resolve: any, reject: any) => {
      axios
        .post(assignTeamLeadsURL, data, { headers })
        .then((response$: {data: ProjectEmployeeDto[]}) => {
          let employeeId = response$.data[0].id;
          let employee = projectEmployeesData.find(item => item.id === employeeId);
          if (employee) {
            employee.teamLead = true;         
          }     
          setProjectEmployeesData([...projectEmployeesData]);
          resolve();  
        })
        .catch((error$) => {
          reject(error$);
        });
    });
    return await promise;
  }

  async function removeProjectTeamLeads(projectEmployees: number[], projectId: any, accessToken: string) {
    const removeTeamLeadsURL: string = process.env.REACT_APP_PUBLIC_URL + '/project/remove-team-leads';

    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }
    /**
     * "teamLeadsIds" : {
     *  "projectId": 1,
     *  "projectEmployees": [5, 6]
     *  }
     */
    let data: {} = {
      projectEmployees: projectEmployees,
      projectId: projectId
    }

    let promise = new Promise((resolve: any, reject: any) => {
      axios
        .post(removeTeamLeadsURL, data, { headers })
        .then((response$: {data: ProjectEmployeeDto[]}) => {
          let employeeId = response$.data[0].id;
          let employee = projectEmployeesData.find(item => item.id === employeeId);
          if (employee) {
            employee.teamLead = false;         
          }     
          setProjectEmployeesData([...projectEmployeesData]);
          resolve();
        })
        .catch((error$) => {
          reject(error$);
        });
    });
    return await promise;
  }

  async function loadSkillAndEmployeesOptions(searchTerm: string, page: number, accessToken: string, url: string, projectId?: number) {
    const paginationSize: number = 100;

    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }
    let params: any = {
      searchTerm: searchTerm,
      page: page,
      pageSize: paginationSize
    }

    if (projectId) {
      params.projectId = projectId
    }

    let promise = new Promise((resolve: any, reject: any) => {
      axios
        .post(url, params, { headers })
        .then((response$: any) => { // data resolved in the component
          resolve(response$.data);
        })
        .catch((error$) => {
          // REFRESH TOKEN - DONE
          reject(error$);
        });
    });
    return await promise;
  }

  async function changeFirstAttempt(projectId: number, accessToken: string) {
    let changeFirstAttemptURL = process.env.REACT_APP_PUBLIC_URL + '/project/change-first-attempt-status';

    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }

    let params: { projectId: number } = {
      projectId
    }
    let promise = new Promise((resolve: any, reject: any) => {
      axios
         .post(changeFirstAttemptURL, params, { headers })
         .then((response$: { data: boolean }) => {
           resolve(response$.data);
         })
         .catch((error$) => {
           // REFRESH TOKEN - DONE
           reject(error$);
         });
    });
    return await promise;
  }

  function initialProjectEmployees (data: any) {
    setProjectEmployeesData(data);
  }
  function initialNeededSkills (data: any) {
    setNeededSkillsData(data);
  }
  function updateProjectData(data: ManagerProjectsData) {
    setProjectsData(data);
  }
  
  let context = {
    neededSkillsData: neededSkillsData as any,
    neededSkillsTempData: neededSkillsTempData as any,
    neededSkillsDetectChanges: neededSkillsDetectChanges as boolean,
    projectsData: projectsData as any,
    searchSkillResultData: searchSkillResultData as any,
    skillCategoriesData: skillCategoriesData as any,
    similarSkillsResponseData: similarSkillsResponseData as {},
    extractedNeededSkillsData: extractedNeededSkillsData as [],
    companyEmployeesData: companyEmployeesData as any,
    projectEmployeesData: projectEmployeesData as any,
    updateProjectData: updateProjectData as any,
    toggleNeededSkillsTempData: toggleNeededSkillsTempData as any,
    resetExtractedSkillsData: resetExtractedSkillsData as any,
    searchSkill: searchSkill as any,
    getSkillCategories: getSkillCategories as any,
    searchAISimilarSkills: searchAISimilarSkills as any,
    addNewSkillOrSynonymNeededSkill: addNewSkillOrSynonymNeededSkill as any,
    addNeededSkill: addNeededSkill as any,
    deleteProjectSkill: deleteProjectSkill as any,
    getCompanyEmployees: getCompanyEmployees as any,
    getExtractedNeededSkillsAI: getExtractedNeededSkillsAI as any,
    uploadNeededSkillsFileHandler: uploadNeededSkillsFileHandler as any,
    sendTextNeededSkills: sendTextNeededSkills as any,
    selectExtractedNeededSkill: selectExtractedNeededSkill as any,
    updateProject: updateProject as any,
    initialProjectEmployees: initialProjectEmployees as any,
    initialNeededSkills: initialNeededSkills as any,
    assignProjectTeamLeads: assignProjectTeamLeads as any,
    removeProjectTeamLeads: removeProjectTeamLeads as any,
    loadSkillAndEmployeesOptions: loadSkillAndEmployeesOptions as any,
    deleteProjectEmployees: deleteProjectEmployees as any,
    changeFirstAttempt: changeFirstAttempt as any
  }
  
  return (
    <ProjectsContext.Provider value={context}>
      {props.children}
    </ProjectsContext.Provider>
  )
}

export default ProjectsContext;