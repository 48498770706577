import styles from './SkillsInventoryPage.module.scss';
import SkillsInventoryComponent from "./SkillsInventoryComponent/SkillsInventoryComponent";
import {showNotification} from "../../../../ui/Toast/ToastNotification";
import axios, {AxiosRequestConfig} from "axios";
import React, {useContext, useEffect, useState} from "react";
import AuthContext from "../../../../store/auth-context";
import useLoadingSpinner from "../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner";
import useApiService from "../../../../services/api.service";
import {SKILL_INVENTORY_TYPE} from "../../../../types/SkillsInventoryData";
import SearchComponent from "../ProjectsPage/ManagerDashboardPage/NeededSkillsPage/SearchComponent/SearchComponent";
import SearchResultComponent from "../ProjectsPage/ManagerDashboardPage/NeededSkillsPage/SearchResultComponent/SearchResultComponent";
import {ErrorResponseDto} from "../../../../types/ErrorData";
import {capitalizeFirstLetter} from "../../../../utils/capitalize-first-letter";
import {MenusEndpointsDto} from "../../../../types/UserMenu";

const SkillsInventoryPage: React.FC<{type: SKILL_INVENTORY_TYPE}> = (props) => {

   const authStore = useContext(AuthContext);
   const userData = authStore.userData;

   const searchSkillURL = process.env.REACT_APP_PUBLIC_URL + "/manager/needed-skill-view";
   const searchEmployeeURL = process.env.REACT_APP_PUBLIC_URL + "/manager/manager-career-mobility-view";
   const getFileLinkURL =  process.env.REACT_APP_PUBLIC_URL + "/company/skill-wallets-data";

   const spinnerService = useLoadingSpinner();

   const {refreshToken} = useApiService();
   const [fileLink, setFileLink] = useState<string>();

   const [showSearchComponent, setShowSearchComponent] = useState(false);
   const [searchResultData, setSearchResultData] = useState<any>(null);

   const companySkillsFlag = props.type === SKILL_INVENTORY_TYPE.COMPANY;

   const upperLeftMenu = userData.menu.find(m => m.title === "Upper Left")!;
   const canDownloadSkillWalletsFlag = props.type === SKILL_INVENTORY_TYPE.COMPANY && upperLeftMenu.menus![0].title.includes("Admin");

   useEffect(() => {
      authStore.changePageTitle(capitalizeFirstLetter(props.type).concat(' Skill Set'));
      if (canDownloadSkillWalletsFlag) {
         if (checkDownloadSkillWallets(upperLeftMenu)) {
            initializeFileLink(userData.accessToken);
         }
      }
   }, []);

   return (
      <>
         <div className={styles['skills-inventory-page']}>
            <div className="container-fluid">
               {
                  !searchResultData &&
                  <SkillsInventoryComponent type={props.type} fileWalletsLink={fileLink} downloadWalletsFlag={canDownloadSkillWalletsFlag ? !!checkDownloadSkillWallets(upperLeftMenu) : false} showSearchPanel={toggleSearchPanel} />
               }
               {
                  showSearchComponent && <SearchComponent searchSkills={searchSkills} searchEmployee={searchEmployee} cancelSearch={toggleSearchPanel} companySkillsFlag={companySkillsFlag} />
               }
               {
                  searchResultData && <SearchResultComponent skillInventoryType={props.type} backToNeededSkills={showNeededSkillsComponent} data={searchResultData} companySkillsFlag={companySkillsFlag}
                                                             searchUser={(id: number, searchedFromSkillsList: boolean) => searchEmployee(id, authStore.userData.accessToken, searchedFromSkillsList)} />
               }
            </div>
         </div>
         {
            spinnerService.spinner
         }
      </>
   )

   function showNeededSkillsComponent (_event: any, prevData?: any) {
      if (prevData) {
         setSearchResultData(prevData);
      }
      else {
         setSearchResultData(null);
      }
   }

   function toggleSearchPanel() {
      setShowSearchComponent(prevState => !prevState);
   }

   function checkDownloadSkillWallets(menuItem: MenusEndpointsDto): MenusEndpointsDto | null {
      if (menuItem.url && menuItem.url.includes("company-skill-set")) {
         if (menuItem.commands!.find(c => c.title === "Download Employees Wallets")){
            return menuItem;
         }
      } else if (menuItem.menus) {
         for (const m of menuItem.menus) {
            const foundMenuItem = checkDownloadSkillWallets(m);
            if (foundMenuItem) {
               return foundMenuItem;
            }
         }
      }
      return null;
   }

   function searchSkills(skillsList: any, accessToken: string) {
      spinnerService.createSpinner();
      // close search form
      setShowSearchComponent(false);

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      let data: {projectSkillIds?: number[], skillIds?: number[]};

      if (props.type === SKILL_INVENTORY_TYPE.COMPANY) {
         let skillIds: number[] = [];
         for (let i = 0; i < skillsList.length; i++ ) {
            skillIds.push(skillsList[i].value);
         }
         data = {
            skillIds
         }
      }

      else {
         let projectSkillIds: number[] = [];
         for (let i = 0; i < skillsList.length; i++ ) {
            projectSkillIds.push(skillsList[i].value);
         }
         data = {
            projectSkillIds
         }
      }

      axios
         .post(searchSkillURL, data, {headers})
         .then((response$: any) => {
            response$.data.resultType = "skills";
            response$.data.skillList = skillsList;
            setSearchResultData(response$.data);
            spinnerService.removeSpinner();
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     searchSkills(skillsList,response$.data.accessToken);
                  })
            }
            else {
               spinnerService.removeSpinner();
               showNotification('warning', error$.response.data.message);
            }
         })
   }

   function searchEmployee(employeeId: number, accessToken: string, searchedFromSkillsList: boolean = false) {
      spinnerService.createSpinner();

      // close search form
      setShowSearchComponent(false);

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }
      let params: {} =  {
         employeeId: employeeId,
      }

      axios
         .post(searchEmployeeURL, params, {headers})
         .then((response$) => {
            if (searchedFromSkillsList) {
               response$.data.prevData = searchResultData;
            }
            response$.data.resultType = "employees";

            for (let category of response$.data.wallet) {
               for (let skill of category.setDetails) {
                  let currentDateTime = Date.now();
                  let addedDateTime = Date.parse(skill.dateAdded as string);
                  skill.isNewSkill = (currentDateTime - addedDateTime) < (1000 * 60 * 60 * 24 * 2) ? true : false;
               }
            }
            setSearchResultData(response$.data);
            spinnerService.removeSpinner();
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     searchEmployee(employeeId, response$.data.accessToken, searchedFromSkillsList);
                  })
            }
            else{
               spinnerService.removeSpinner();
               showNotification('warning', error$.response.data.message);
            }
         })
   }

   function initializeFileLink(accessToken: string) {

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      axios
         .get(getFileLinkURL, {headers})
         .then((resp$: any) => {
            setFileLink(resp$.data);
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     initializeFileLink(response$.data.accessToken);
                  })
            }
            else {
               showNotification('warning', error$.response.data.message);
            }
         })
   }
}

export default SkillsInventoryPage;