import styles from './JoyrideTooltip.module.scss';

const JoyrideTooltip: React.FC<{
   continuous: boolean, // tour if can continue or not // stignat do kraj ili ne
   index: number, // broj na step
   isLastStep: boolean, //dali e kraj
   size: number, // broj na steps
   step: any, // step
   backProps: any, // back button props
   closeProps: any, // close button props
   skipProps: any, // props za skip button
   primaryProps: any, // primary button props/ next ili finish
   tooltipProps: any, // root element , so ref
}> = (props) => {
   return (
      <>
         <div {...props.tooltipProps} className={styles[`joyride-tooltip`]}>
            <div className="joyride-header">
               {
                  props.step.title &&
                  <div className={`joyride-title ${props.index > 0 ? 'joyride-left' : ''}`}>{props.step.title}</div>
               }
               {
                  props.index > 0 &&
                  <span className={'joyride-close'}>
                   <i className="fa-solid fa-xmark"  {...props.skipProps}></i>
                  </span>
               }
            </div>
            <div className={`joyride-content ${props.index > 0 ? 'joyride-left' : ''}`}>{props.step.content}</div>
            <div className={`joyride-footer ${props.index === 0 ? 'first-step' : 'next-step'}`}>
               {
                  props.index > 0 &&
                  <div className={'joyride-steps'}>{props.index} of {props.size - 1}</div>
               }
               {
                  props.index === 0 && (
                     <button className={'joyride-skip'} {...props.skipProps}>Skip</button>
                  )
               }
               {props.continuous && (
                  <div>
                     {props.index > 1 && props.index !== 5 && (
                        <button className={'joyride-previous'} {...props.backProps}>Previous</button>
                     )}
                     {
                        props.index !== 4 &&
                        <button
                           className={'joyride-next'} {...props.primaryProps}>{props.index === 0 ? 'Let\'s start' : props.isLastStep ? 'Finish' : 'Next'}</button>
                     }
                  </div>
               )}
               {!props.continuous && (
                  <button {...props.closeProps}>Close</button>
               )}
            </div>
         </div>
      </>
   );
}

export default JoyrideTooltip;