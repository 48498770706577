import { useState, useRef } from 'react'; 
import styles from './SkillsList.module.scss';
import SkillListFilterSortComponent from './SkillListFilterSortComponent/SkillListFilterSortComponent';
import SkillsListCard from './SkillsListCard/SkillsListCard';
import {capitalizeFirstLetter} from "../../../../../../../utils/capitalize-first-letter";

const SkillsList: React.FC<{skillsWallet: any, isTabComponent: boolean}> = (props) => {
  const [filterType, setFilterType] = useState('all');
  const [sortType, setSortType] = useState('asc');

  let approvedSkillsCounter = useRef(0);
  let unacceptedSkillsCounter = useRef(0);
  let newSkillsCounter = useRef(0);
  let learningSkillsCounter = useRef(0);

  let data = filterData();
  data = sortData();

  return (
    <div className={`${styles['skills-list']}`}>
      <div className='card-main'>
        {
          !props.isTabComponent && <h3 style={{'fontSize': '18px', 'marginBottom': '16px'}}>Skills wallet</h3>
        }
        <SkillListFilterSortComponent sortData={setSortType} filterType={filterType} filterData={setFilterType} newSkillsCounter={newSkillsCounter.current}
          approvedSkillsCounter={approvedSkillsCounter.current} unacceptedSkillsCounter={unacceptedSkillsCounter.current} learningSkillsCounter={learningSkillsCounter.current} />
          <div style={{'paddingRight': '12px', 'maxHeight': '600px', overflow: 'auto'}}>
          {
            data.map((category: any)=> {
              return <div className='category-card' key={category.category}>
                <div className='accordion-wrapper'>
                  <div className="heading-wrapper">
                    <h3>{capitalizeFirstLetter(category.category)}</h3>
                    <div className='acordion-toggle-button accordion-active'>
                      <i className="fa-solid fa-angle-down" onClick={event => toggleAccordion(event)}></i>
                    </div>
                  </div>
                </div>       
                <div className='accordion-content'>
                  {
                    category.setDetails.map((skill: any)=> {
                      return <SkillsListCard key={skill.skill.id} item={skill} />
                    })
                  }
                </div>
              </div>
            })
          }
          </div>
      </div>    
    </div>
  )

  function sortData() {
    // Helper function to sort by category
    const sortByCategory = (a: { category: string; }, b: { category: string; }, asc = true) => {
      const nameA = a.category.toUpperCase();
      const nameB = b.category.toUpperCase();
      if (nameA < nameB) return asc ? -1 : 1;
      if (nameA > nameB) return asc ? 1 : -1;
      return 0;
    };

    // Helper function to sort setDetails by skill name
    const sortBySkillName = (details: any[], asc = true) => {
      return details.sort((a, b) => {
        const nameA = a.skill.name.toUpperCase();
        const nameB = b.skill.name.toUpperCase();
        if (nameA < nameB) return asc ? -1 : 1;
        if (nameA > nameB) return asc ? 1 : -1;
        return 0;
      });
    };

    // Sort data by category based on sortType
    data.sort((a: { category: string; }, b: { category: string; }) => sortByCategory(a, b, sortType === 'asc'));

    // Sort each item's setDetails by skill name
    data.forEach((item: { setDetails: any[]; }) => sortBySkillName(item.setDetails, sortType === 'asc'));

    return data;
  }


  function filterData() {
    let tempData: any = [];
    let counter: number = 0;
    
    if (filterType === 'approved') {
      for (let item of props.skillsWallet) {
        const category: string = item.category;  
        let approvedSkills: [] = item.setDetails.filter((skill: any) => skill.approved === 'APPROVED');
  
        if (approvedSkills.length > 0) {
          tempData.push({
            category: category,
            setDetails: approvedSkills
          });          
          counter = counter + approvedSkills.length;           
        }
      }
      approvedSkillsCounter.current = counter;
      return tempData;
    }
    else if (filterType === 'unaccepted') {
      for (let item of props.skillsWallet) {
        const category: string = item.category;  
        let unapprovedSkills: [] = item.setDetails.filter((skill: any) => skill.approved === 'NEW');
  
        if (unapprovedSkills.length > 0) {
          tempData.push({
            category: category,
            setDetails: unapprovedSkills
          });          
          counter = counter + unapprovedSkills.length;           
        }
      }
      unacceptedSkillsCounter.current = counter;
      return tempData;
    }
    else if (filterType === 'learning') {
      for (let item of props.skillsWallet) {
        const category: string = item.category;
        let learningSkills: [] = item.setDetails.filter((skill: any) => skill.approved === 'LEARNING');

        if (learningSkills.length > 0) {
          tempData.push({
            category: category,
            setDetails: learningSkills
          });
          counter = counter + learningSkills.length;
        }
      }
      learningSkillsCounter.current = counter;
      return tempData;
    }
    else if (filterType === 'new') { 
      for (let item of props.skillsWallet) {
        const category: string = item.category;  
        let newSkills: [] = item.setDetails.filter((skill: any) => skill.isNewSkill === true);
  
        if (newSkills.length > 0) {
          tempData.push({
            category: category,
            setDetails: newSkills
          });          
          counter = counter + newSkills.length;           
        }
      }
      newSkillsCounter.current = counter;
      return tempData;
    }
    return props.skillsWallet;    
  }

  function toggleAccordion (event: any) {   
    let accordionButton: HTMLDivElement = event.target.parentElement as HTMLDivElement;
    accordionButton.classList.toggle('accordion-active');
    
    let accordionRoot: HTMLDivElement = accordionButton.closest('.category-card') as HTMLDivElement;
    let accordionContent: HTMLDivElement = accordionRoot.querySelector('.accordion-content') as HTMLDivElement;
    
    if (accordionContent.style.maxHeight === '0px') {          
      accordionContent.style.maxHeight = "4000px";
    }
    else {          
      accordionContent.style.maxHeight = "0px";
    }
  }
}

export default SkillsList;