import style from './MapTeamSkillsPage.module.scss';
import React, {useContext, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {animated, useSpring} from "react-spring";
import InfiniteScroll from "react-infinite-scroll-component";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {ErrorResponseDto} from "../../../../../types/ErrorData";
import {showNotification} from "../../../../../ui/Toast/ToastNotification";
import AuthContext from "../../../../../store/auth-context";
import useApiService from "../../../../../services/api.service";
import {EmployeeDto, SearchEmployeesDto} from "../../../../../types/EmployeeOnboardData";
import {PulseLoader} from "react-spinners";
import {FileTypeValue} from "../../../../../types/UploadData";
import TagEmployeesInput from "../../../../../ui/TagEmployeesInput/TagEmployeesInput";

export type PaginationDto = {
   page: number;
   pageSize: number;
   searchTerm: string;
   totalCount: number;
}

const MapTeamSkillsPage: React.FC<{
   changeSection: Function
}> = props => {

   const authStore = useContext(AuthContext);
   const {refreshToken} = useApiService();

   const [content, setContent] = useState('');

   const fadeStyles = useSpring({
      from: {opacity: 0},
      to: {opacity: 1},
      config: {duration: 750},
   });

   return (
      <>
         <animated.div className={style['map-team-skills-page']} style={fadeStyles}>
            <h1 className="header-1 u-margin-bottom-m">Map Team Skills</h1>
            <p>Describe your team's skills and experience.</p>
            <p className={'u-margin-top-s u-margin-bottom-l'}>Use @ to tag individual team members and describe their unique contributions, skills, and areas of expertise.</p>
            <TagEmployeesInput setContent={text => setContent(text)} />
            <div className="buttons-container">
               <button className="button button-secondary u-margin-top-big" onClick={() => props.changeSection(true)}><i className="fa-solid fa-arrow-left"></i> Back</button>
               <button className="button button-primary u-margin-top-big" onClick={() => uploadText(authStore.userData.accessToken)}>Finish</button>
            </div>

         </animated.div>
      </>
   )

   function uploadText(accessToken: string) {
      const uploadTextURL = process.env.REACT_APP_PUBLIC_URL + '/file/save-text';

      if (content.trim().length === 0) {
         props.changeSection();
         return;
      }

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      const data = {
         text: content,
         subCategory: FileTypeValue.TEAM_SKILLS
      }

      axios
         .post(uploadTextURL, data, {headers})
         .then(() => {
            props.changeSection();
         })
         .catch(error$ => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     uploadText(response$.data.accessToken);
                  })
            } else {
               showNotification('warning', error$.response.data.message);
            }
         })
   }
}

export default MapTeamSkillsPage;