import React, {useContext, useEffect, useRef, useState} from "react";
import styles from './ChatBotComponent.module.scss';
import {animated, useSpring} from "react-spring";
import SpinnerButton from "../../../../../ui/SpinnerButton/SpinnerButton";
import axios, {AxiosRequestConfig} from "axios";
import {showNotification} from "../../../../../ui/Toast/ToastNotification";
import AuthContext from "../../../../../store/auth-context";
import useApiService from "../../../../../services/api.service";
import {ErrorResponseDto} from "../../../../../types/ErrorData";

const ChatBotComponent: React.FC<{}> = props => {

   const authStore = useContext(AuthContext);
   const apiService = useApiService();

   const [chatBotResponses, setChatBotResponses] = useState<{ title: string }[]>([{title: 'Hi! I’m Teamo,\n' +
         'Here to assist you with everything you need to get some work done. How may I help you today?'}]);
   const [userRequests, setUserRequests] = useState<{ title: string }[]>([]);
   const interaction = getInteraction();

   const [userInput, setUserInput] = useState<string>('');
   const [dataIsLoading, setDataIsLoading] = useState<boolean>(false);

   const scrollRef = useRef<any>();

   const inputAnimation = useSpring({
      from: {opacity: 0},
      to: {opacity: 1},
      delay: 350
   })

   const [chatBotResponseAnimation, chatBotResponseAnimationApi] = useSpring(() => ({
      from: {top: '15%', opacity: 0}
   }))

   // Prepare to show and animate project name 'response'
   const [userResponseAnimation, userResponseAnimationApi] = useSpring(() => ({
      from: {top: '15%', opacity: 0}
   }))

   useEffect(() => {
      chatBotResponseAnimationApi.start({
         from: {
            top: '15%', opacity: 0
         },
         to: {
            top: '0%', opacity: 1
         },
         onResolve: () => {
         }
      })
   }, []);

   useEffect(() => {
      scrollRef.current?.scrollIntoView({
         behavior: "smooth",
         block: "end",
      });
   }, [chatBotResponses, userRequests]);

   return (
      <>
         <div className={styles['chat-bot-component']}>
            <div className="chat-bot-wrapper">

               <div className='info-wrapper'>
                  {
                     interaction.map((item, index) => {
                        const isChatBotResponse = index % 2 === 0;
                        const animationStyle = isChatBotResponse ? { ...chatBotResponseAnimation } : { ...userResponseAnimation };
                        const className = isChatBotResponse ? 'q-field' : 'a-field';

                        if (index + 2 >= interaction.length) {
                           return (
                              <animated.span key={index} className={className} style={animationStyle}>
                                 {item.title}
                              </animated.span>
                           );
                        }

                        return (
                           <span key={index} className={className}>
                              {item.title}
                           </span>
                        );
                     })
                  }
                  <div ref={scrollRef}></div>
               </div>

               <animated.div className='input-wrapper' style={{...inputAnimation}}>
                  <input className={'chat-bot-input'} type='text' id='chat-bot-input' autoComplete='off' placeholder='Answer here'
                         value={userInput} onChange={changeUserInput} disabled={dataIsLoading} onKeyDown={checkEnterKey} />
                  <div className='button-wrapper'>
                     {
                        userInput.trim() !== '' && userInput.trim().length > 1 &&
                        <SpinnerButton title="Send" dataLoading={dataIsLoading} buttonType="button"
                                       cssClasses='button button-primary' onClickCallback={() => {displayQuestion();sendQuestionToAiFunction(userInput, authStore.userData.accessToken)}}/>
                     }
                  </div>
               </animated.div>
            </div>
         </div>
      </>
   )

   function getInteraction() {
      const array: {title: string}[] = [];
      for (let i = 0; i < Math.max(chatBotResponses.length, userRequests.length); i++) {
         if (i < chatBotResponses.length) {
            array.push(chatBotResponses[i]);
         }
         if (i < userRequests.length) {
            array.push(userRequests[i]);
         }
      }
      return array;
   }
   function changeUserInput(event: any) {
      setUserInput(event.target.value);
   }

   function checkEnterKey(e: any) {
      if (e.key === 'Enter' && !e.shiftKey) {
         displayQuestion();
         sendQuestionToAiFunction(userInput, authStore.userData.accessToken)
      }
   }

   function displayQuestion() {
      setUserRequests(prevState => [...prevState, {title: userInput.trim()}]);

      userResponseAnimationApi.start({
         from: {
            top: '15%', opacity: 0
         },
         to: {
            top: '0%', opacity: 1
         },
         onResolve: () => {
         }
      })
   }

   function displayAnswer(answer: string) {
      setChatBotResponses(prevState => [...prevState, {title: answer}]);

      chatBotResponseAnimationApi.start({
         from: {
            top: '15%', opacity: 0
         },
         to: {
            top: '0%', opacity: 1
         },
         onResolve: () => {
         }
      })

   }

   function sendQuestionToAiFunction(question: string, accessToken: string) {
      setDataIsLoading(true);

      sendQuestionToAi(question, accessToken)
         .then((response$: any) => {
            setDataIsLoading(false);
            setUserInput('');
            displayAnswer(response$.data);
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               apiService.refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     sendQuestionToAiFunction(question, response$.data.accessToken);
                  })
            } else {
               showNotification('warning', error$.response.data.message);
               setDataIsLoading(false);
            }
         })

   }

   async function sendQuestionToAi(question: string, accessToken: string) {
      const sendQuestionToAiURL = process.env.REACT_APP_PUBLIC_URL + '/manager/chat-bot';

      let headers: AxiosRequestConfig['headers'] = {
         Authorization: `Bearer ${accessToken}`,
      }

      let data: { question: string, sessionId: string } = {
         sessionId: authStore.userData.sessionId,
         question
      }

      let promise = new Promise((resolve: any, reject: any) => {
         axios.post(sendQuestionToAiURL, data, {headers})
            .then((response$: any) => {
               resolve(response$);
            })
            .catch((error$: ErrorResponseDto) => {
               reject(error$);
            })
      })
      return await promise;
   }
}

export default ChatBotComponent;