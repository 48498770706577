import React, { useState, useRef, useContext } from 'react';
import axios, {AxiosRequestConfig} from "axios";

import './PersonalGrowthComponent.scss';

import AuthContext from "../../../../../store/auth-context";

import RelatedNotApprovedSkills from './RelatedNotApprovedSkills/RelatedNotApprovedSkills';
import PolarChart from './PolarChart/PolarChart';
import LearnAboutSkillsComponent from './LearnAboutSkillsComponent/LearnAboutSkillsComponent';

import useLoadingSpinner from '../../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner';
import useApiService from '../../../../../services/api.service';
import useModal from '../../../../../services/modal.service';
import CustomJobModal from '../CustomJobModal/CustomJobModal';
import { showNotification } from '../../../../../ui/Toast/ToastNotification';
import {ErrorResponseDto} from "../../../../../types/ErrorData";
import {
	EmployeeDashboardDto,
	SimilarJobPositionSkillsDto
} from "../../../../../types/EmployeeDashboard";

const PersonalGrowthComponent: React.FC<{data: EmployeeDashboardDto, readOnlyMode?: boolean, isTabComponent?: boolean}> = props => {
  const authStore = useContext(AuthContext);

	const [chartData, setChartData] = useState<SimilarJobPositionSkillsDto[]>(props.data.similarJobs!);
	const [isDefaultSimilar, setIsDefaultSimilar] = useState<boolean>(props.data.isDefaultSimilar ? props.data.isDefaultSimilar : false);

	const [chosenSkillData, setChosenSkillData] = useState<any>({});	
	const chartRef = useRef<any>();
	const formatArcColorRef = useRef<any>();

	const spinnerService = useLoadingSpinner();
	const modalCustomJobPosition = useModal();
	const { refreshToken } = useApiService();

	return (
		<div className='card-main'>
			<div className='card-chart'>
				{
					!props.readOnlyMode &&
					<div className='title-wrapper'>
						<h3 style={{'fontSize': '20px'}}>Career growth potential</h3>			
						{
							isDefaultSimilar ?
							<button className='button button-tertiary' onClick={() => getAllJobPositions(authStore.userData.accessToken)}><i className="fa-solid fa-plus"></i> Add new jobs</button> :
							<button className='button button-tertiary' onClick={() => resetManualJobPositions(authStore.userData.accessToken)}> Back to the standard chart </button>
						}															
					</div>	
				}
				{
					props.readOnlyMode && !props.isTabComponent &&
						<h3 style={{'fontSize': '18px', 'marginBottom': '16px'}}>Career growth potential</h3>
				}
		
				<PolarChart data={chartData} chartRef={chartRef} setChosenSkillData={setChosenSkillData} formatArcColorRef={formatArcColorRef} />
				<div className='card-skill-hint'>
					{
						Object.keys(chosenSkillData).length === 0 ?						
						<div className='no-skill-hint'>
							<div className='icon-light-wrapper'>
								<img src='/assets/images/global-icons/lightbulb.svg'   alt={''} style={{width: '18px', height: '18px'}}/>
							</div>						
							<p>Click on the role to learn more about the skills required for it.</p>
						</div> : 
						<LearnAboutSkillsComponent chosenSkillData={chosenSkillData} closeSkillDescriptionPanel={closeSkillDescriptionPanel} isReadOnly={props.readOnlyMode} />
					}	
				</div>

				{
					Object.keys(chosenSkillData).length === 0 && props.data.personalNotApprovedSkills && props.data.personalNotApprovedSkills.length > 0 && !props.readOnlyMode &&
						<RelatedNotApprovedSkills personalNotApprovedSkills={props.data.personalNotApprovedSkills} />					
				}			

			</div>
			{
				modalCustomJobPosition.showModal && <CustomJobModal removeModal={modalCustomJobPosition.removeModal} 
				 data={modalCustomJobPosition.modalData} okCallback={(data: []) => submitCustomJobPositions(data, authStore.userData.accessToken)} dataIsLoading={modalCustomJobPosition.dataIsLoading} />
      }
			{
				spinnerService.spinner
			}
 
		</div>
	)

	function getAllJobPositions(accessToken: string) {
		spinnerService.createSpinner();
		const headers: AxiosRequestConfig['headers'] = {
			'Authorization': `Bearer ${accessToken}`
		}

		axios
		.get(process.env.REACT_APP_PUBLIC_URL + '/employee/job-positions', {headers})
		.then((response$) => {	
			spinnerService.removeSpinner();
			modalCustomJobPosition.createModal({allJobs: response$.data, similarJobs: chartData});
		})
		.catch((error$: ErrorResponseDto) => {
			spinnerService.removeSpinner();
			if (error$.response.data.message === 'Unauthorized') {
				// Get new Access Token
				refreshToken(authStore.userData.refreshToken)
					.then((response$: any) => {						
						authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
						getAllJobPositions(response$.data.accessToken);
					})
			}
			else {
				showNotification('warning', error$.response.data.message);
			}			
		})
	}

	function submitCustomJobPositions(data: [], accessToken: string) {
		modalCustomJobPosition.toggleDataIsLoading();
		const headers: AxiosRequestConfig['headers'] = {
			'Authorization': `Bearer ${accessToken}`
		}
		let payload: any = {};
		payload.jobPositionIds = [];

		data.forEach((item: {id: string}) => {
			payload.jobPositionIds.push(item.id);
		});

		axios
			.post(process.env.REACT_APP_PUBLIC_URL + '/employee/manual-similar-positions', payload, {headers})
			.then((response$: any) => {		
				modalCustomJobPosition.toggleDataIsLoading();
				modalCustomJobPosition.removeModal();
				setChartData([...response$.data]);
				setIsDefaultSimilar(false);
			})
			.catch((error$) => {
				if (error$.response.data.message === 'Unauthorized') {
					// Get new Access Token
					refreshToken(authStore.userData.refreshToken)
						.then((response$: any) => {
							authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
							submitCustomJobPositions(data, response$.data.accessToken);
						})
				}
				else {
					modalCustomJobPosition.toggleDataIsLoading();
					modalCustomJobPosition.removeModal();
					showNotification('warning', error$.response.data.message);
				}
			})
	}

	function resetManualJobPositions(accessToken: string) {
		spinnerService.createSpinner();

		const headers: AxiosRequestConfig['headers'] = {
			'Authorization': `Bearer ${accessToken}`
		}

		axios
			.post(process.env.REACT_APP_PUBLIC_URL + '/employee/reset-manual-choice', null, {headers})
			.then((response$) => {
				setChartData([...response$.data.employeeDashboard.similarJobs]);
				setIsDefaultSimilar(true);
				spinnerService.removeSpinner();
			})
			.catch((error$) => {
				if (error$.response.data.message === 'Unauthorized') {
					// Get new Access Token
					refreshToken(authStore.userData.refreshToken)
						.then((response$: any) => {
							authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
							resetManualJobPositions(response$.data.accessToken);
						})
				}
				else {
					spinnerService.removeSpinner();
					showNotification('warning', error$.response.data.message);
				}
			})
	}
	
	function closeSkillDescriptionPanel () {
		chartRef.current.data.datasets[0].backgroundColor.forEach((color: string, i: number, colors: string[]) => {
			colors[i] = formatArcColorRef.current(color, 1);
		});
		chartRef.current.update();
		setChosenSkillData({});
	}
}

export default PersonalGrowthComponent;