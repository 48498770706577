import { useState, useContext } from "react";
import styles from './SelectSkillsComponent.module.scss';
import Select, { components } from "react-select";
import AuthContext from '../../../../../store/auth-context';

const { Option } = components;

const IconOption = (props: any) => {
  return (
    <Option {...props}> 
      <i className="fa-regular fa-gem" style={{'marginRight': '8px'}}></i>
      {props.data.label}
    </Option>
)};

const selectStyles = {
  container: (provided: {}) => ({
    ...provided,
    flex: "1"    
  }),
  control: (provided: {}) => ({
    ...provided,
    backgroundColor: "#1C1B28",
    border: "1px solid #2F303C",
    paddingTop: "6px",
    paddingBottom: "6px",
    paddingLeft: "32px",
    paddingRight: "6px"    
  }),
  indicatorSeparator: (provided: {}) => ({
    display: "none",
  }),
  dropdownIndicator: (provided: {}) => ({
    display: "none",
  }),
  clearIndicator: (provided: {}) => ({
    ...provided,
    color: "#757575",
    with: "50px",
    marginLeft: '48px'    
  }),
  input: (provided: {}) => ({
    ...provided,
    color: '#B3BAC3',
    fontSize: '16px'
  }),
  placeholder: (provided: {}) => ({
    ...provided,
    color: "#828892",
    fontWeight: "400",
    fontSize: "16px",
  }),
  menu: (provided: {}) => ({
    ...provided,
    backgroundColor: "#1C1B28",
    zIndex: 1000000
  }),
  option: (provided: any, data: any) => ({
    ...provided,
    color: "#B3BAC3",
    transition: '0.5s background-color, 0.5s color',
    ':hover' : {
      backgroundColor: '#383A46',
      color: '#F1F6FB',
      cursor: 'pointer'
    }
  }),  
  multiValue:  (provided: {}) => ({
    ...provided,
    backgroundColor: "#383A46",
    border: "1px solid #626872",
    color: "#B3BAC3",
    marginRight: "6px"
  }),
  multiValueLabel: (provided: {}) => ({
    ...provided,
    color: '#B3BAC3'
  }),
  multiValueRemove: (provided: {}, state: {}) => ({
    ...provided,
    color: 'white',
    transition: '0.5s color',
    backgroundColor: 'transparent !important',
    ':hover': {
      color: '#B3BAC3'
    }
  }),
};

type Skill = { value: string, label: String, description: string };

const SelectSkillsComponent: React.FC<{selectOptions: any, getChartData: Function}> = (props) => {
  const [selectedSkills, setSselectedSkills] = useState<Skill[]>([]);
  const authStore = useContext(AuthContext);
  
  return (
    <div className={styles['select-skill-component']}>      
      <div className="search-input-container">        
        <Select
          options={props.selectOptions}      
          className="search-input"
          classNamePrefix="select"
          isClearable={true}
          isSearchable={true}
          isMulti={true}     
          placeholder="Search for a skill: Front End, Marketing, CSS..."
          noOptionsMessage={(value) =>
            value.inputValue === "" ? "Start typing" : "No options"
          }
          onChange={(options: any) => {        
            setSselectedSkills(options);     
          }}
          styles={selectStyles}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: "#2D2F38",
              primary: "#757575",
            },
          })}
          components={{ Option: IconOption }}
        />
      </div>
      <div className="apply-button-wrapper">
        <button className="button button-primary" disabled={selectedSkills.length < 1 || selectedSkills.length > 8}
          onClick={()=> props.getChartData(authStore.userData.accessToken, selectedSkills)}>Create chart</button>
      </div>
    </div>
  )
}

export default SelectSkillsComponent;