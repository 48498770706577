import axios, {AxiosRequestConfig} from 'axios';
import { showNotification } from '../../../../../ui/Toast/ToastNotification';
import { useState, useEffect, useContext } from 'react'; 
import AuthContext from '../../../../../store/auth-context';
import useApiService from "../../../../../services/api.service";
import {ErrorResponseDto} from "../../../../../types/ErrorData";
import {Navigate} from "react-router-dom";

const UnsubscribeJira = () => {
  const [isInitialized, setIsInitialized] = useState(false);
  const authStore = useContext(AuthContext);
  const { refreshToken } = useApiService();
  
  useEffect(() => {
    initializeComponent(authStore.userData.accessToken);
  }, []);

  return (
    isInitialized ? <Navigate replace to={'/file/company-data-types'} /> : null
  )

  function initializeComponent(accessToken: string) {
    const jiraUnsubscribeUrl: string = process.env.REACT_APP_PUBLIC_URL + `/auth/jira-unsubscribe`;

    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }

    axios
      .post(jiraUnsubscribeUrl, null, {headers})
      .then((response$) => {
        showNotification('success', 'Unsubscribing from Jira has been done successfully.');
        setIsInitialized(true);
      })
      .catch((error$: ErrorResponseDto) => {
        if (error$.response.data.message === 'Unauthorized') {
          // Get new Access Token
          refreshToken(authStore.userData.refreshToken)
            .then((response$: any) => {
              authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
              initializeComponent(response$.data.accessToken);
            })
        }
        else {
          showNotification('warning', 'There was an error while unsubscribing from Jira. Please retry.');
          setIsInitialized(true);
        }
      });
  }
}

export default UnsubscribeJira;