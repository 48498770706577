import styles from './AddDescriptionModal.module.scss';
import AddDescriptionComponent from './AddDescriptionComponent/AddDescriptionComponent';

const AddDescriptionModal: React.FC<{projectData: any, closelModal: Function}> = (props) => {
  
  return (
    <div className={styles['add-description-page']}>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12'>
            <div className='add-description-modal'>
              <div className='card-modal'>              
                <div className='row'>
                  <div className='col-12'>
                    <div className='modal-header'>            
                      <div className='logo-container'>
                        <img src='/assets/images/global-icons/teamlift-logo-white.svg' alt="TeamLift logo" />
                      </div>                     
                      <div className='logout-button' onClick={()=>props.closelModal()}>Back to Settings</div>
                    </div>   
                  </div>
                </div>        
                <div className='modal-body'>
                  <div id='adding-description-process'>
                    <AddDescriptionComponent projectData={props.projectData} closeModal={props.closelModal} />
                  </div>
                </div>
              </div>
            </div>   
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddDescriptionModal;