import {useContext, useEffect, useState} from "react";
import styles from './SkillsResultsComponent.module.scss';

import axios, {AxiosRequestConfig} from "axios";
import {toast} from "react-toastify";

import AuthContext from "../../../../../../../../store/auth-context";

import useModal from "../../../../../../../../services/modal.service";
import ReminderModal from '../../NeededSkillsComponent/ReminderModal/ReminderModal';
import useLoadingSpinner from "../../../../../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner";
import Tooltip from '../../../../../../../../services/tooltip.service';
import {buildStyles, CircularProgressbar, CircularProgressbarWithChildren} from 'react-circular-progressbar';
import {ClipLoader} from 'react-spinners';
import useApiService from "../../../../../../../../services/api.service";
import {Avatar} from "@mui/material";
import {showNotification} from "../../../../../../../../ui/Toast/ToastNotification";
import {useNavigate} from "react-router-dom";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import RadialSeparators from "../../../../../../../../utils/RadialSeparators";
import FilterComponent from "../../../../../../FilterComponent/FilterComponent";
import {capitalizeFirstLetter} from "../../../../../../../../utils/capitalize-first-letter";
import {SKILL_INVENTORY_TYPE} from "../../../../../../../../types/SkillsInventoryData";
import {ErrorResponseDto} from "../../../../../../../../types/ErrorData";

const SkillsResultsComponent: React.FC<{
   title?: string, backToNeededSkills: any, data: {
      knowAccepted?: [],
      knowUnaccepted?: [],
      knowLearning: [],
      skillList: { label: string, value: number}[],
      resultType: string
   }, searchUser: Function,
   companySkillsFlag?: boolean,
   skillInventoryType?: SKILL_INVENTORY_TYPE
}> = (props) => {
   const authStore = useContext(AuthContext);
   const navigate = useNavigate();

   const reminderURL: string = process.env.REACT_APP_PUBLIC_URL + '/auth/reminder-email';
   const getCandidatesURL: string = process.env.REACT_APP_PUBLIC_URL + '/manager/best-candidates-to-learn';

   const filterOptions = [{title: 'Name A-Z', value: 'A-Z'}, {
      title: 'Name Z-A',
      value: 'Z-A'
   }, {title: 'Confidence high-low', value: 'high-low'}, {title: 'Confidence low-high', value: 'low-high'},];

   const [bestCandidatesToLearn, setBestCandidatesToLearn] = useState<any>(null);

   const {refreshToken} = useApiService();

   let modalService = useModal();
   let spinner = useLoadingSpinner();
   // Format title
   let skillTitle: string = "";
   if (props.data.skillList.length > 1) {
      for (let i = 0; i < props.data.skillList.length; i++) {
         if (i === props.data.skillList.length - 2) {
            skillTitle += props.data.skillList[i].label + " & ";
         } else if (i === props.data.skillList.length - 1) {
            skillTitle += props.data.skillList[i].label + " ";
         } else {
            skillTitle += props.data.skillList[i].label + ", ";
         }
      }
      skillTitle += "skills"
   } else {
      skillTitle += props.data.skillList[0].label + " skill";
   }

   let knowAccepted = props.data.knowAccepted as any;
   let knowUnaccepted = props.data.knowUnaccepted as any;
   let learningCandidates = props.data.knowLearning as any;

   const [sortStateHasSkill, setSortStateHasSkill] = useState<string>('A-Z');
   const [sortStateLearning, setSortStateLearning] = useState<string>('A-Z');
   const [sortStateCandidates, setSortStateCandidates] = useState<string>('A-Z');
   const [messageState, setMessageState] = useState<boolean>(false); // true / false ako nema drugi employees i ima samo kandidati
   const [selectedSkills, setSelectedSkills] = useState<{ employeeId: number, skills: number[] }>({
      employeeId: -1, skills: []
   });

   const finalKnowAcceptedList = knowAccepted.sort((item1: any, item2: any) => sortHasSkill(item1, item2, sortStateHasSkill));
   const finalKnowUnacceptedList = knowUnaccepted.sort((item1: any, item2: any) => sortHasSkill(item1, item2, sortStateHasSkill));
   const finalLearningList = learningCandidates.sort((item1: any, item2: any) => sortHasSkill(item1, item2, sortStateLearning));
   const finalBestCandidatesList = bestCandidatesToLearn ? bestCandidatesToLearn.sort((item1: any, item2: any) => sortHasSkill(item1, item2, sortStateCandidates)) : [];

   useEffect(() => {
      getBestCandidates(authStore.userData.accessToken);
   }, []);

   useEffect(() => {
      if (bestCandidatesToLearn && bestCandidatesToLearn.length > 0) {
         if (knowAccepted.length + knowUnaccepted.length + learningCandidates.length === 0) {
            setMessageState(true);
         }
      }
   }, [bestCandidatesToLearn]);


   return (<div className={styles["search-result-skills"]}>
      {/* Back button */}
      <div className={'back-link'}>
         {props.title ? <>
            <button className={'button button-tertiary'} onClick={backToAllProjects}>All projects</button> / <button className={'button button-tertiary'}
            onClick={(e) => props.backToNeededSkills(e, null)}>{props.title}</button> / <span>{skillTitle}</span></> : <>
            <button className={'button button-tertiary'} onClick={(e) => props.backToNeededSkills(e, null)}>{props.skillInventoryType ? capitalizeFirstLetter(props.skillInventoryType.valueOf()) : ''}
               Skill Set</button> / <span>{skillTitle}</span>
         </>}
      </div>
      <div className="search-results-component-wrapper-component">
         <h1 className="header-1">{skillTitle}</h1>

         {messageState && <div className={'only-candidates-card u-margin-top-s'}>
            <div className={'message-header'}>
               <div className={'message-title'}>Assign a candidate to learn {skillTitle}</div>
               <span className={'message-x'}><i onClick={setMessageStateHandler}
                                                className="fa-solid fa-xmark"></i></span>
            </div>
            <div className="message-body">
               Currently, no employee from your company has or is learning {skillTitle}. <br/>
               If you need this skill for your project, consider the
               candidate{bestCandidatesToLearn ? bestCandidatesToLearn.length > 0 ? 's' : '' : ''} for the
               learning opportunity!
            </div>
         </div>}

         <div className="tabs-container">
            <Tabs className={'custom-tabs'} selectedTabClassName={'selected-tab'}>
               <TabList>
                  {knowAccepted.length + knowUnaccepted.length > 0 &&
                     <Tab onClick={resetStates}><img src='/assets/images/global-icons/has-these-skills.svg' alt={'success'}/>
                        {knowAccepted.length > 0 && knowUnaccepted.length > 0 && <>Has or unaccepted</>}
                        {knowAccepted.length > 0 && knowUnaccepted.length === 0 && <>Has the skill</>}
                        {knowAccepted.length === 0 && knowUnaccepted.length > 0 && <>Unaccepted</>}
                        <span
                           className={'badge rounded-pill'}>{knowAccepted.length + knowUnaccepted.length}</span></Tab>}
                  {learningCandidates.length > 0 &&
                     <Tab onClick={resetStates}><i className="fa-solid fa-book-open"></i> Learning <span
                        className={'badge rounded-pill'}>{learningCandidates.length}</span></Tab>}
                  <Tab disabled={(!finalBestCandidatesList || finalBestCandidatesList.length === 0)}
                       onClick={resetStates}><img src='/assets/images/global-icons/best-candidates-icon.svg' alt={'best candidates icon'}/> Candidates
                     to learn <span
                        className={'badge rounded-pill'}>{bestCandidatesToLearn ? bestCandidatesToLearn.length : 0}</span>
                     {bestCandidatesToLearn === null &&
                        <ClipLoader color="#9A9DA7" size={16}
                                    cssOverride={{verticalAlign: '-2px', marginLeft: '5px'}}/>}
                  </Tab>
               </TabList>

               {knowAccepted.length + knowUnaccepted.length > 0 && <TabPanel> {/* ACCEPTED + UNACCEPTED */}
                  <div>
                     {knowAccepted.length > 0 && <div className="accepted-section">
                        <div className={'filters'}>
                           <div className={'filter-title'}>Has accepted
                              skill{props.data.skillList.length > 1 ? 's' : ''} <span
                                 className={'badge rounded-pill'}>{knowAccepted.length}</span></div>
                           <div className="filter-component">
                              <FilterComponent sort={sortStateHasHandler} id={'filter'}
                                               filterOptions={filterOptions}/>
                           </div>
                        </div>
                        <div className={'body'}>
                           {knowAccepted.length > 0 && props.data.skillList.length === 1 && finalKnowAcceptedList.map((item: any) => {
                              let validationMessageTitle: string = '';
                              let chartColor: string = '';
                              const totalValidationScore: number = item.skills[0].totalValidationScore;

                              switch (item.skills[0].totalValidationLevel) {
                                 case 'LOW':
                                    validationMessageTitle = `Low level of confidence`;
                                    chartColor = '#A0A8E5';
                                    break;
                                 case 'MEDIUM':
                                    validationMessageTitle = `Medium level of confidence`;
                                    chartColor = '#5969D4';
                                    break;
                                 case 'HIGH':
                                    validationMessageTitle = `High level of confidence`;
                                    chartColor = '#3146C1';
                                    break;
                              }

                              return (<div key={item.employee.id + '_' + item.skills[0].id}
                                           className="skill-employee-card">
                                 <Tooltip
                                    place="right"
                                    tooltipId={item.employee.id.toString() + +'_' + item.skills[0].id}
                                    backgroundColor="#101319"
                                    borderColor='#5C5F6A !important'
                                    border={true}
                                    content={<div className="tooltip-wrapper">
                                       <div className="tooltip-chart-wrapper">
                                          <CircularProgressbar value={totalValidationScore}
                                                               strokeWidth={28}
                                                               styles={buildStyles({
                                                                  pathColor: chartColor,
                                                                  trailColor: '#191D24',
                                                                  strokeLinecap: "butt"
                                                               })}/>
                                       </div>
                                       <div className="tooltip-info-wrapper">
                                          <div><strong>{validationMessageTitle}</strong></div>
                                          <div>{item.skills[0].message}</div>
                                          <span className="validated-user"><i
                                             className="fa-solid fa-check-double"></i> Validated by the user</span>
                                       </div>
                                    </div>}
                                    className='tooltip-employee'>
                                    <div className="info-wrapper">
                                       <div
                                          className={`image-wrapper ${!item.employee.profilePicture ? "no-image" : ""}`}>
                                          <CircularProgressbar value={totalValidationScore}
                                                               strokeWidth={8}
                                                               styles={buildStyles({
                                                                  pathColor: chartColor,
                                                                  trailColor: '#191D24',
                                                                  strokeLinecap: "butt"
                                                               })}/>
                                          <div className="image-container">
                                             {item.employee.profilePicture ?
                                                <img src={item.employee.profilePicture}
                                                     alt={'profilePicture'}/> : <Avatar className={'img'}
                                                                                        sx={{backgroundColor: item.employee.color}}>{item.employee.name.slice(0, 1).toUpperCase()}</Avatar>}
                                          </div>
                                       </div>
                                       <div
                                          className="name-wrapper">{item.employee.name} {item.employee.surname}</div>
                                    </div>
                                 </Tooltip>

                                 <div className="action-wrapper">
                                    <button className="button button-tertiary"
                                         onClick={() => props.searchUser(item.employee.id, true)}>Open profile
                                    </button>
                                 </div>
                              </div>)
                           })}

                           {knowAccepted.length > 0 && props.data.skillList.length > 1 && finalKnowAcceptedList.map((item: any) => {
                              return (<div key={item.employee.id} className="skill-employee-card">
                                 <div className="info-wrapper">
                                    <div
                                       className={`image-wrapper ${!item.employee.profilePicture ? "no-image" : ""}`}>
                                       {item.employee.profilePicture ? <img src={item.employee.profilePicture}
                                                                            alt={'profilePicture'}/> :
                                          <Avatar className={'img'}
                                                  sx={{backgroundColor: item.employee.color}}>{item.employee.name.slice(0, 1).toUpperCase()}</Avatar>}
                                    </div>
                                    <div
                                       className="name-wrapper">{item.employee.name} {item.employee.surname}</div>
                                 </div>

                                 <div className="skills-tag-wrapper">
                                    {item.skills.map((skill: any, index: number) => {
                                       let validationMessageTitle: string = '';
                                       let chartColor: string = '';
                                       const totalValidationScore: number = skill.totalValidationScore;

                                       switch (skill.totalValidationLevel) {
                                          case 'LOW':
                                             validationMessageTitle = `Low level of confidence`;
                                             chartColor = '#A0A8E5';
                                             break;
                                          case 'MEDIUM':
                                             validationMessageTitle = `Medium level of confidence`;
                                             chartColor = '#5969D4';
                                             break;
                                          case 'HIGH':
                                             validationMessageTitle = `High level of confidence`;
                                             chartColor = '#3146C1';
                                             break;
                                       }

                                       return <Tooltip key={`${skill.id}_${item.employee.name}_${index}`}
                                                       place="bottom"
                                                       tooltipId={item.employee.id.toString() + +'_' + skill.id}
                                                       backgroundColor="#101319"
                                                       borderColor='#5C5F6A !important'
                                                       border={true}
                                                       content={<div className="tooltip-wrapper">
                                                          <div className="tooltip-chart-wrapper">
                                                             <CircularProgressbar
                                                                value={totalValidationScore}
                                                                strokeWidth={28}
                                                                styles={buildStyles({
                                                                   pathColor: chartColor,
                                                                   trailColor: '#191D24',
                                                                   strokeLinecap: "butt"
                                                                })}/>
                                                          </div>
                                                          <div className="tooltip-info-wrapper">
                                                             <div>
                                                                <strong>{validationMessageTitle}</strong>
                                                             </div>
                                                             <div>{skill.message}</div>
                                                             <span className="validated-user"><i
                                                                className="fa-solid fa-check-double"></i> Validated by the user</span>
                                                          </div>
                                                       </div>}
                                                       className='tooltip-employee'>
                                          <span className="skill-tag">
                                            <div className={'progress-bar'}>
                                               <CircularProgressbar value={totalValidationScore} strokeWidth={28}
                                                                    styles={buildStyles({
                                                                       pathColor: chartColor,
                                                                       trailColor: '#2F303CFF',
                                                                       strokeLinecap: "butt"
                                                                    })}/>
                                            </div>
                                             {skill.synonym ? capitalizeFirstLetter(skill.synonym.name) : capitalizeFirstLetter(skill.name)}</span>
                                       </Tooltip>
                                    })}
                                 </div>
                                 <div className="action-wrapper">
                                    <div className="profile-link"
                                         onClick={() => props.searchUser(item.employee.id, true)}>Open
                                       profile
                                    </div>
                                 </div>
                              </div>)
                           })}
                        </div>
                     </div>}

                     {knowUnaccepted.length > 0 && <div className={'unaccepted-section'}>
                        <div className={'filters'}>
                           <div className={'filter-title'}>Hasn't accepted the
                              skill{props.data.skillList.length > 1 ? 's' : ''} <span
                                 className={'badge rounded-pill'}>{knowUnaccepted.length}</span></div>
                           {knowAccepted.length === 0 && <div className="filter-component">
                              <FilterComponent sort={sortStateHasHandler} id={'filter'}
                                               filterOptions={filterOptions}/>
                           </div>}
                        </div>
                        <div className="body">
                           {knowUnaccepted.length > 0 && props.data.skillList.length === 1 && finalKnowUnacceptedList.map((item: any) => {
                              let validationMessageTitle: string = '';
                              let chartColor: string = '';
                              const totalValidationScore: number = item.skills[0].totalValidationScore;

                              switch (item.skills[0].totalValidationLevel) {
                                 case 'LOW':
                                    validationMessageTitle = `Low level of confidence`;
                                    chartColor = '#A0A8E5';
                                    break;
                                 case 'MEDIUM':
                                    validationMessageTitle = `Medium level of confidence`;
                                    chartColor = '#5969D4';
                                    break;
                                 case 'HIGH':
                                    validationMessageTitle = `High level of confidence`;
                                    chartColor = '#3146C1';
                                    break;
                              }

                              return (<div key={item.employee.id} className="skill-employee-card">
                                 <Tooltip
                                    place="right"
                                    tooltipId={item.employee.id.toString() + +'_' + item.skills[0].id}
                                    backgroundColor="#101319"
                                    borderColor='#5C5F6A !important'
                                    border={true}
                                    content={<div className="tooltip-wrapper">
                                       <div className="tooltip-chart-wrapper">
                                          <CircularProgressbar value={totalValidationScore}
                                                               strokeWidth={28}
                                                               styles={buildStyles({
                                                                  pathColor: chartColor,
                                                                  trailColor: '#191D24',
                                                                  strokeLinecap: "butt"
                                                               })}/>
                                       </div>
                                       <div className="tooltip-info-wrapper">
                                          <div><strong>{validationMessageTitle}</strong></div>
                                          <div>{item.skills[0].message}</div>
                                          <button className="button button-tooltip button-tertiary u-margin-top-xs"
                                                onClick={() => remindUserHandler(item.skills[0].id, item.skills[0].name, item.employee.id, item.employee.name, item.employee.surname)}>Remind to accept</button>
                                       </div>
                                    </div>}
                                    className='tooltip-employee'
                                 >
                                    <div className="info-wrapper">
                                       <div
                                          className={`image-wrapper ${!item.employee.profilePicture ? "no-image" : ""}`}>
                                          <CircularProgressbar value={totalValidationScore}
                                                               strokeWidth={8}
                                                               styles={buildStyles({
                                                                  pathColor: chartColor,
                                                                  trailColor: '#191D24',
                                                                  strokeLinecap: "butt"
                                                               })}/>
                                          <div className="image-container">
                                             {item.employee.profilePicture ?
                                                <img src={item.employee.profilePicture}
                                                     alt={'profilePicture'}/> : <Avatar className={'img'}
                                                                                        sx={{backgroundColor: item.employee.color}}>{item.employee.name.slice(0, 1).toUpperCase()}</Avatar>}
                                          </div>
                                       </div>
                                       <div
                                          className="name-wrapper">{item.employee.name} {item.employee.surname}</div>
                                    </div>
                                 </Tooltip>
                                 <div className="action-wrapper">
                                    <div className="profile-link"
                                         onClick={() => props.searchUser(item.employee.id, true)}>Open
                                       profile
                                    </div>
                                 </div>
                              </div>)
                           })}

                           {knowUnaccepted.length > 0 && props.data.skillList.length > 1 && finalKnowUnacceptedList.map((item: any) => {
                              return (<div key={item.employee.id} className="skill-employee-card">
                                 <div className="info-wrapper">
                                    <div
                                       className={`image-wrapper ${!item.employee.profilePicture ? "no-image" : ""}`}>
                                       {item.employee.profilePicture ? <img src={item.employee.profilePicture}
                                                                            alt={'profilePicture'}/> :
                                          <Avatar sx={{backgroundColor: item.employee.color}}
                                                  className={'img'}>{item.employee.name.slice(0, 1).toUpperCase()}</Avatar>}
                                    </div>
                                    <div
                                       className="name-wrapper">{item.employee.name} {item.employee.surname}</div>
                                 </div>

                                 <div className="skills-tag-wrapper">
                                    {item.skills.map((skill: any, index: number) => {
                                       let validationMessageTitle: string = '';
                                       let chartColor: string = '';
                                       const totalValidationScore: number = skill.totalValidationScore;

                                       switch (skill.totalValidationLevel) {
                                          case 'LOW':
                                             validationMessageTitle = `Low level of confidence`;
                                             chartColor = '#A0A8E5';
                                             break;
                                          case 'MEDIUM':
                                             validationMessageTitle = `Medium level of confidence`;
                                             chartColor = '#5969D4';
                                             break;
                                          case 'HIGH':
                                             validationMessageTitle = `High level of confidence`;
                                             chartColor = '#3146C1';
                                             break;
                                       }

                                       return <Tooltip key={`${skill.id}_${item.employee.name}_${index}`}
                                                       place="bottom"
                                                       tooltipId={item.employee.id.toString() + +'_' + skill.id}
                                                       backgroundColor="#101319"
                                                       borderColor='#5C5F6A !important'
                                                       border={true}
                                                       content={<div className="tooltip-wrapper">
                                                          <div className="tooltip-chart-wrapper">
                                                             <CircularProgressbar
                                                                value={totalValidationScore}
                                                                strokeWidth={28}
                                                                styles={buildStyles({
                                                                   pathColor: chartColor,
                                                                   trailColor: '#191D24',
                                                                   strokeLinecap: "butt"
                                                                })}/>
                                                          </div>
                                                          <div className="tooltip-info-wrapper">
                                                             <div>
                                                                <strong>{validationMessageTitle}</strong>
                                                             </div>
                                                             <div>{skill.message}</div>
                                                             <button className="button button-tertiary"
                                                                   onClick={() => remindUserHandler(skill.id, skill.name, item.employee.id, item.employee.name, item.employee.surname)}>Remind to accept</button>
                                                          </div>
                                                       </div>}
                                                       className='tooltip-employee'>
                                           <span className="skill-tag-unaccepted">
                                             <div className="progress-bar">
                                               <CircularProgressbar value={totalValidationScore} strokeWidth={28}
                                                                    styles={buildStyles({
                                                                       pathColor: chartColor,
                                                                       trailColor: '#191D24',
                                                                       strokeLinecap: "butt"
                                                                    })}/>
                                             </div>
                                              {skill.synonym ? capitalizeFirstLetter(skill.synonym.name) : capitalizeFirstLetter(skill.name)}
                                              <span className={'unaccepted-title'}> unaccepted</span></span>
                                       </Tooltip>
                                    })}
                                 </div>
                                 <div className="action-wrapper">
                                    <div className="profile-link"
                                         onClick={() => props.searchUser(item.employee.id, true)}>Open
                                       profile
                                    </div>
                                 </div>
                              </div>)
                           })}
                        </div>
                     </div>}
                  </div>
               </TabPanel>}
               {finalLearningList.length > 0 && <TabPanel>
                  <div className="learning-section">
                     <div className={'filters'}>
                        <div className={'filter-title'}>Learning the
                           skill{props.data.skillList.length > 1 ? 's' : ''} <span
                              className={'badge rounded-pill'}>{finalLearningList.length}</span></div>
                        <div className="filter-component">
                           <FilterComponent sort={sortLearningHandler} id={'filter-component-learning'}
                                            filterOptions={filterOptions.slice(0, 2)}/>
                        </div>
                     </div>
                     <div className="body">
                        {props.data.skillList.length === 1 && finalLearningList.map((item: any) => {
                           return (<div key={item.employee.id} className="skill-employee-card">
                              <Tooltip
                                 place="right"
                                 tooltipId={item.employee.id.toString() + +'_' + item.skills[0].id}
                                 backgroundColor="#101319"
                                 borderColor='#5C5F6A !important'
                                 border={true}
                                 content={<div className="tooltip-wrapper">
                                    <div className="tooltip-chart-wrapper learning-chart-wrapper">
                                       <CircularProgressbarWithChildren value={100} strokeWidth={28}
                                                                        styles={buildStyles({
                                                                           pathColor: '#191D24',
                                                                           trailColor: '#383A46',
                                                                           strokeLinecap: "butt"
                                                                        })}>
                                          <RadialSeparators count={7}
                                                            style={{
                                                               background: "#fff",
                                                               width: "3px", // This needs to be equal to props.strokeWidth
                                                               height: `${25}%`
                                                            }}/>
                                       </CircularProgressbarWithChildren>
                                    </div>
                                    <div className="tooltip-info-wrapper">
                                       <div><strong>Learning skill</strong></div>
                                       <div>This employee is currently learning this skill.</div>
                                    </div>
                                 </div>}
                                 className='tooltip-employee'>
                                 <div className="info-wrapper">
                                    <div
                                       className={`image-wrapper ${!item.employee.profilePicture ? "no-image" : ""}`}>
                                       <CircularProgressbarWithChildren value={100} strokeWidth={8}
                                                                        styles={buildStyles({
                                                                           pathColor: '#191D24',
                                                                           trailColor: '#191D24',
                                                                           strokeLinecap: "butt"
                                                                        })}>
                                          <RadialSeparators count={12}
                                                            style={{
                                                               background: "#fff",
                                                               width: "5px", // This needs to be equal to props.strokeWidth
                                                               height: `${9}%`
                                                            }}
                                          />
                                       </CircularProgressbarWithChildren>
                                       <div className="image-container">
                                          {item.employee.profilePicture ? <img src={item.employee.profilePicture}
                                                                               alt={'profilePicture'}/> :
                                             <Avatar className={'img'}
                                                     sx={{backgroundColor: item.employee.color}}>{item.employee.name.slice(0, 1).toUpperCase()}</Avatar>}
                                       </div>
                                    </div>
                                    <div
                                       className="name-wrapper">{item.employee.name} {item.employee.surname}</div>
                                 </div>
                              </Tooltip>
                              <div className="action-wrapper">
                                 <button className="button button-tertiary"
                                      onClick={() => props.searchUser(item.employee.id, true)}>Open profile
                                 </button>
                              </div>
                           </div>);
                        })}
                        {props.data.skillList.length > 1 && finalLearningList.map((item: any) => {
                           return (<div key={item.employee.id} className="skill-employee-card">
                              <div className="info-wrapper">
                                 <div
                                    className={`image-wrapper ${!item.employee.profilePicture ? "no-image" : ""}`}>
                                    {item.employee.profilePicture ?
                                       <img src={item.employee.profilePicture} alt={'profilePicture'}/> :
                                       <Avatar sx={{backgroundColor: item.employee.color}}
                                               className={'img'}>{item.employee.name.slice(0, 1).toUpperCase()}</Avatar>}
                                 </div>
                                 <div
                                    className="name-wrapper">{item.employee.name} {item.employee.surname}</div>
                              </div>
                              <div className="skills-tag-wrapper">
                                 {item.skills.map((skill: any, index: number) => {
                                    return <Tooltip key={`${skill.id}_${item.employee.id}_${index}`}
                                                    place="bottom"
                                                    tooltipId={item.employee.id.toString() + +'_' + skill.id}
                                                    backgroundColor="#101319"
                                                    borderColor='#5C5F6A !important'
                                                    border={true}
                                                    content={<div className="tooltip-wrapper">
                                                       <div
                                                          className="tooltip-chart-wrapper learning-chart-wrapper">
                                                          <CircularProgressbarWithChildren value={100}
                                                                                           strokeWidth={28}
                                                                                           styles={buildStyles({
                                                                                              pathColor: '#191D24',
                                                                                              trailColor: '#383A46',
                                                                                              strokeLinecap: "butt"
                                                                                           })}>
                                                             <RadialSeparators count={7}
                                                                               style={{
                                                                                  background: "#fff",
                                                                                  width: "3px", // This needs to be equal to props.strokeWidth
                                                                                  height: `${25}%`
                                                                               }}/>
                                                          </CircularProgressbarWithChildren>
                                                       </div>
                                                       <div className="tooltip-info-wrapper">
                                                          <div><strong>Learning skill</strong></div>
                                                          <div>This employee is currently learning
                                                             this skill.
                                                          </div>
                                                       </div>
                                                    </div>}
                                                    className='tooltip-employee'>
                                       <span className="skill-tag">
                                         <div className="progress-bar">
                                           <CircularProgressbarWithChildren value={100} strokeWidth={28}
                                                                            styles={buildStyles({
                                                                               pathColor: '#191D24',
                                                                               trailColor: '#383A46',
                                                                               strokeLinecap: "butt"
                                                                            })}>
                                             <RadialSeparators count={7}
                                                               style={{
                                                                  background: "#fff",
                                                                  width: "3px", // This needs to be equal to props.strokeWidth
                                                                  height: `${25}%`
                                                               }}/>
                                           </CircularProgressbarWithChildren>
                                         </div>
                                          {skill.synonym ? capitalizeFirstLetter(skill.synonym.name) : capitalizeFirstLetter(skill.name)}</span>
                                    </Tooltip>
                                 })}
                              </div>
                              <div className="action-wrapper">
                                 <button className="button button-tertiary"
                                      onClick={() => props.searchUser(item.employee.id, true)}>Open profile
                                 </button>
                              </div>
                           </div>);
                        })}
                     </div>
                  </div>
               </TabPanel>}
               <TabPanel>
                  <div className="candidates-section">
                     <div className={'filters'}>
                        <div className={'filter-title'}>Candidates for the
                           skill{props.data.skillList.length > 1 ? 's' : ''} <span
                              className={'badge rounded-pill'}>{bestCandidatesToLearn ? bestCandidatesToLearn.length : 0}</span>
                        </div>
                        <FilterComponent sort={sortCandidatesHandler} id={'filter-component-candidates'}
                                         filterOptions={filterOptions.slice(0, 2)}/>
                     </div>
                     <div className="body">
                        {bestCandidatesToLearn !== null && finalBestCandidatesList.map((item: any, index: number) => {
                           return (<div key={item.employee.id} className="skill-employee-card">
                              <div className="info-wrapper">
                                 <div
                                    className={`image-wrapper ${!item.employee.profilePicture ? "no-image" : ""}`}>
                                    {item.employee.profilePicture ?
                                       <img src={item.employee.profilePicture} alt={'profilePicture'}/> :
                                       <Avatar sx={{backgroundColor: item.employee.color}}
                                               className={'img'}>{item.employee.name.slice(0, 1).toUpperCase()}</Avatar>}
                                 </div>
                                 <div
                                    className="name-wrapper">{item.employee.name} {item.employee.surname}</div>
                              </div>
                              <div className="skills-tag-wrapper">
                                 {props.data.skillList.length > 1 && item.skillsNames.map((skillName: string, index: number) => {
                                    // return <span
                                    //    onClick={() => addSelectedSkill(item.employee.id, skill.id)}
                                    //    className={`skill-tag candidate-tag ${findSelectedSkill(item.employee.id, skill.id) ? 'selected-skill' : ''}`}
                                    //    key={`${skill.id}_${item.employee.name}_${index}`}>{skill.synonym ? capitalizeFirstLetter(skill.synonym.name) : capitalizeFirstLetter(skill.name)}</span>
                                    return <span key={index} className={'skill-tag'}>{skillName}</span>
                                 })}
                              </div>
                              <div
                                 className={`action-wrapper ${checkSkillsEmployee(item.employee.id) ? 'visible-wrapper' : ''}`}>
                                 <div className={'suggest'}>Suggest to learn
                                    {props.data.skillList.length === 1 ? skillTitle : checkSkillsEmployee(item.employee.id) ? ` selected (${selectedSkills.skills.length})` : ' all'}</div>
                                 <button className="button button-tertiary"
                                      onClick={() => props.searchUser(item.employee.id, true)}>Open profile
                                 </button>
                              </div>
                           </div>)
                        })}
                     </div>
                  </div>
               </TabPanel>
            </Tabs>
         </div>
      </div>
      {modalService.showModal && <ReminderModal data={modalService.modalData} okCallback={createUserReminder}
                                                cancelCallback={modalService.removeModal}/>}
      {spinner.spinner}
   </div>)

   function sortHasSkill(item1: any, item2: any, state: string) {
      switch (state) {
         case 'A-Z':
            return item1.employee.name.localeCompare(item2.employee.name);
         case 'Z-A':
            return item2.employee.name.localeCompare(item1.employee.name);
         case 'high-low':
         case 'low-high': {
            const lengthComparison = item1.skills.length - item2.skills.length;
            if (lengthComparison !== 0) {
               return state === 'high-low' ? -lengthComparison : lengthComparison;
            }
            const firstSum = item1.skills.reduce((sum: number, { totalValidationScore }: any) => sum + totalValidationScore, 0);
            const secondSum = item2.skills.reduce((sum: number, { totalValidationScore }: any) => sum + totalValidationScore, 0);
            return state === 'high-low' ? secondSum - firstSum : firstSum - secondSum;
         }
         default:
            return 0;
      }
   }

   function sortCandidatesHandler(value: string) {
      setSortStateCandidates(value);
   }

   function sortLearningHandler(value: string) {
      setSortStateLearning(value);
   }

   function sortStateHasHandler(value: string) {
      setSortStateHasSkill(value);
   }

   function checkSkillsEmployee(employeeId: number) {
      if (selectedSkills.employeeId === employeeId && selectedSkills.skills.length > 0) {
         return selectedSkills;
      }
   }

   function findSelectedSkill(employeeId: number, skillId: number) {
      if (selectedSkills.employeeId === employeeId) {
         return selectedSkills.skills.find(id => id === skillId);
      }
   }

   function addSelectedSkill(employeeId: number, skillId: number) {
      if (selectedSkills.employeeId === employeeId) {
         let flag = selectedSkills.skills.find(id => id === skillId);
         if (flag) {
            setSelectedSkills(prevState => {
               return {
                  employeeId: prevState.employeeId, skills: [...prevState.skills.filter(id => id !== skillId)]
               };
            });
         } else {
            setSelectedSkills({
               employeeId: selectedSkills.employeeId, skills: [...selectedSkills.skills, skillId]
            });
         }
      } else {
         setSelectedSkills({
            employeeId, skills: [skillId]
         });
      }
   }

   function resetStates() {
      setSortStateHasSkill('A-Z');
      setSortStateLearning('A-Z');
      setSortStateCandidates('A-Z');
      setSelectedSkills({
         employeeId: -1, skills: []
      });
   }

   function setMessageStateHandler() {
      setMessageState((prevState) => {
         return !prevState;
      })
   }

   function remindUserHandler(skillId: number, skillTitle: string, employeeId: number, employeeName: string, employeeSurname: string) {
      modalService.createModal({skillId, skillTitle, employeeId, employeeName, employeeSurname,});
   }

   function createUserReminder(skillId: number, employeeID: number, accessToken: string) {
      spinner.createSpinner();

      const data = {
         skillId,
         employeeIds: [employeeID]
      };

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      let notification: string = "An email notification was sent successfully!";

      modalService.removeModal();
      axios
         .post(reminderURL, data, {headers})
         .then((response$) => {
            spinner.removeSpinner();
            toast.success(notification, {theme: "dark"});
            showNotification('success', "Something went wrong! Please try again.");
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     createUserReminder(skillId, employeeID, response$.data.accessToken);
                  })
            } else {
               spinner.removeSpinner();
               showNotification('warning', error$.response.data.message);
            }
         });
   }

   function getBestCandidates(accessToken: string) {
      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }
      let ids: number[] = props.data.skillList.map(skill => skill.value);

      let data: {projectSkillIds?: number[], skillIds?: number[]} = {};

      if (props.companySkillsFlag) {
         data.skillIds = ids;
         data.projectSkillIds = undefined;
      }
      else {
         data.skillIds = undefined;
         data.projectSkillIds = ids;
      }

      axios
         .post(getCandidatesURL, data, {headers})
         .then((response$: any) => {
            setBestCandidatesToLearn(response$.data);
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     getBestCandidates(response$.data.accessToken);
                  })
            } else {
               setBestCandidatesToLearn([]);
               showNotification('warning', error$.response.data.message);
            }
         })
   }

   function backToAllProjects() {
      navigate('/manager/projects');
   }
};

export default SkillsResultsComponent;