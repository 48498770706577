import React, {useEffect, useState} from "react";
import style from './EmployeeQuestionPage.module.scss';
import {QuestionTypeEnum, RegisterQuestionDto} from "../../../../../types/EmployeeOnboardRegisterData";
import {animated, useSpring} from "react-spring";
import SpinnerButton from "../../../../../ui/SpinnerButton/SpinnerButton";
import {capitalizeFirstLetter} from "../../../../../utils/capitalize-first-letter";
import Tooltip from "../../../../../services/tooltip.service";
import {SearchedSkillDto} from "../../../../../types/SearchComponentData";
import SelectComponent from "../../../UploadDataPage/UploadPersonalDataComponent/AddManuallySkillsComponent/SelectComponent/SelectComponent";

const EmployeeQuestionPage: React.FC<{
   question: RegisterQuestionDto,
   saveAnswer: (questionId: number, answer: string) => void,
   dataLoading: boolean,
   goBack?: () => void,
}> = props => {

   const fadeStyles = useSpring({
      from: {opacity: 0},
      to: {opacity: 1},
      config: {duration: 750},
   });

   const [skills, setSkills] = useState<SearchedSkillDto[]>([]);
   const [inputValue, setInputValue] = useState("");
   const [chosenAnswers, setChosenAnswers] = useState<{value: string, selected: boolean}[]>([]);
   const [newAnswer, setNewAnswer] = useState('');

   useEffect(() => {
      if (props.question.answerType === QuestionTypeEnum.TEXT || props.question.answerType === QuestionTypeEnum.SHORT_TEXT) {
         setSkills([]);
         setNewAnswer('');
         setInputValue(props.question.answer ? props.question.answer : "");
         setChosenAnswers([]);
      }
      else if (props.question.answerType === QuestionTypeEnum.CHECKBOX) {
         const alreadyAnswered = props.question.answer ? props.question.answer.split(';').map(a => a.trim()) : [];
         const recommendedAnswers = props.question.preferredAnswer!.split(';').map(a => a.trim());
         const answersArray = Array.from(new Set(alreadyAnswered.concat(recommendedAnswers)));
         setSkills([]);
         setInputValue('');
         setNewAnswer('');
         setChosenAnswers(answersArray.map(t => ({value: t.trim(), selected: alreadyAnswered.includes(t.trim())})));
      }
      else {
         setInputValue('');
         setSkills(props.question.skills ? props.question.skills.map(s => ({value: s.aiId, label: s.name, description: s.description})) : []);
         setNewAnswer('');
         setChosenAnswers([]);
      }
   }, [props.question]);

   return (
      <>
         <div className={style['employee-question-page']}>
            <animated.div className={style["name-page"]} style={fadeStyles}>
               <h3 className="header-3 u-margin-bottom-xl question-title">{props.question.questionText}</h3>

               <div className={`form ${props.question.answerType === QuestionTypeEnum.SKILL_SELECTION ? "skill-selection" : ""}`}>
                  {
                     props.question.answerType === QuestionTypeEnum.SHORT_TEXT &&
                     <input disabled={props.dataLoading} value={inputValue} placeholder={"Your answer here..."}
                            onChange={e => setInputValue(e.target.value)}
                            id={"questionInput"} className={"custom-input u-margin-bottom-m"}/>
                  }
                  {
                     props.question.answerType === QuestionTypeEnum.TEXT &&
                        <textarea disabled={props.dataLoading} value={inputValue} placeholder={"Your answer here..."}
                                  onChange={e => setInputValue(e.target.value)}
                                  id={"questionInput"} className={"custom-input u-margin-bottom-m"} />
                  }
                  {
                     props.question.answerType === QuestionTypeEnum.CHECKBOX &&
                        <>
                           <div className="chosen-answers">
                              {
                                 chosenAnswers.map((chosenAnswer, index) => {
                                    return (
                                       <div className="chosen-item" key={`${chosenAnswer.value}_${index}`}>
                                          <div onClick={() => changeChosenAnswer(index)} className={`checkbox-dark ${chosenAnswer.selected ? 'checked' : ''}`}><i className="fa-solid fa-check"></i></div>
                                          <input type="text" className={"custom-input"} disabled value={chosenAnswer.value} />
                                       </div>
                                    );
                                 })
                              }
                           </div>

                           <div className={'add-tool'}>
                              <input type="text" className={"custom-input"} placeholder={'Add yours...'} value={newAnswer} onChange={e => setNewAnswer(e.target.value)} />
                              <button className="button button-tertiary" onClick={addChosenAnswer}><i className="fa-solid fa-plus"></i> Add yours</button>
                           </div>
                        </>
                  }
                  {
                     props.question.answerType === QuestionTypeEnum.SKILL_SELECTION &&
                     <>
                        <SelectComponent skillsToBeAdded={skills} addSkillFromSelect={addSkillFromSelect}
                                         menuPlacement={"bottom"}/>
                        <div className="skills-container u-margin-top-m u-margin-bottom-m">
                           {
                              skills.map(skill => {
                                 return (
                                    <div key={skill.value}
                                         className={`skill-wrapper`}
                                         onClick={() => selectSkill(skill)}>
                                       <div
                                          className="skill-name u-margin-right-xs">{capitalizeFirstLetter(skill.label)}</div>
                                       <Tooltip tooltipId={`skill_${skill.value}`}
                                                place="bottom"
                                                className={'custom-tooltip'}
                                                content={<div>{skill.description}</div>}>
                                          <i className="fa-regular fa-circle-question"></i>
                                       </Tooltip>
                                       <i className="fa-solid fa-xmark"></i>
                                    </div>
                                 )
                              })
                           }
                        </div>
                     </>
                  }
                  {
                     props.question.answerType !== QuestionTypeEnum.CHECKBOX &&
                     <>
                        <div className="title">
                           <img src="/assets/images/global-icons/lightbulb.svg" alt="Ligth bulb"/>
                           <div>Here’s an example response</div>
                        </div>
                        <p className={'text-surface-30'}>{props.question.preferredAnswer}</p>
                     </>
                  }
               </div>

               <div className={`buttons-wrapper u-margin-top-xl ${!props.goBack ? "first-section" : ""}`}>
                  {
                     props.goBack && <button className={`button button-secondary`} onClick={goBack}><i
                        className="fa-solid fa-arrow-left-long"></i><span>Back</span></button>
                  }
                  <SpinnerButton cssClasses={"button button-primary"} initialDisabled={inputValue.trim().length === 0 && skills.length === 0 && chosenAnswers.filter(a => a.selected).length === 0}
                                 dataLoading={props.dataLoading} title={"Next"} buttonType={'next'}
                                 onClickCallback={saveAnswer}/>
               </div>
            </animated.div>
         </div>
      </>
   );

   function addChosenAnswer() {
      const answer = newAnswer.trim();
      if (answer.length > 0 && !chosenAnswers.some(a => a.value.trim().toLowerCase() === answer.toLowerCase())) {
         setChosenAnswers(prevState => ([...prevState, {value: answer, selected: false}]));
         setNewAnswer('');
      }
   }

   function changeChosenAnswer(index: number) {
      setChosenAnswers(prevState => {
         return prevState.map((item, i) => i === index ? {...item, selected: !item.selected} : item);
      });
   }

   function selectSkill(skill: SearchedSkillDto) {
      setSkills(prevState => prevState.filter(s => s.value !== skill.value));
   }

   function addSkillFromSelect(skill: SearchedSkillDto) {
      setSkills(prevState => [...prevState, skill]);
   }

   function goBack() {
      props.goBack && props.goBack();
   }

   function saveAnswer() {
      if (props.question.answerType === QuestionTypeEnum.TEXT || props.question.answerType === QuestionTypeEnum.SHORT_TEXT) {
         props.saveAnswer(props.question.id, inputValue);
      }
      else if (props.question.answerType === QuestionTypeEnum.CHECKBOX) {
         let answer = '';
         chosenAnswers.filter(a => a.selected).forEach(a => answer += a.value + ";");
         props.saveAnswer(props.question.id, answer.substring(0, answer.length - 1));
      }
      else { // answer type === SKILL_SELECTION
         let skillsString = "";
         skills.forEach(skill => {
            skillsString += skill.value + ";";
         });
         props.saveAnswer(props.question.id, skillsString.substring(0, skillsString.length - 1));
      }
   }
}

export default EmployeeQuestionPage;