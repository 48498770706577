import styles from './SkillsInventoryComponent.module.scss';
import {
   DashboardDto,
   DashBoardRequestDto,
   SideBarDto,
   SideBarRequestDto,
   SKILL_VIEW_FILTER,
   SkillsDashboardDto
} from "../../../../../types/ManagerDashboardData";
import {SKILL_INVENTORY_SOURCES, SKILL_INVENTORY_TYPE} from "../../../../../types/SkillsInventoryData";
import {PulseLoader} from "react-spinners";
import React, {useContext, useEffect, useState} from "react";
import axios, {AxiosRequestConfig} from "axios";
import {ErrorResponseDto} from "../../../../../types/ErrorData";
import {showNotification} from "../../../../../ui/Toast/ToastNotification";
import AuthContext from "../../../../../store/auth-context";
import useLoadingSpinner from "../../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner";
import useApiService from "../../../../../services/api.service";
import SkillsDetailsComponent from "../../ProjectsPage/ManagerDashboardPage/ProjectDetailsPage/SkillsDetailsComponent/SkillsDetailsComponent";
import {ProjectDto} from "../../../../../types/Projects";
import {capitalizeOnlyFirstLetter} from "../../../../../utils/capitalize-only-first-letter";

const SkillsInventoryComponent: React.FC<{
   downloadWalletsFlag: boolean,
   fileWalletsLink?: string,
   showSearchPanel: React.MouseEventHandler,
   type: SKILL_INVENTORY_TYPE,
}> = (props) => {

   const [initializedComponent, setInitializedComponent] = useState<DashboardDto>();
   const [sideBarData, setSideBarData] = useState<SideBarDto>();
   const [categories, setCategories] = useState<string[]>([]);
   const [projectsData, setProjectsData] = useState<ProjectDto[]>([]);
   const sources = props.type === SKILL_INVENTORY_TYPE.COMPANY ? SKILL_INVENTORY_SOURCES : undefined;

   const skillInventoryURL = process.env.REACT_APP_PUBLIC_URL + `/manager/${props.type.valueOf()}-skill-set`;
   const skillSideBarURL = process.env.REACT_APP_PUBLIC_URL + `/manager/skill-view/${props.type.valueOf()}-skill-set`;
   const getProjectsURL = process.env.REACT_APP_PUBLIC_URL + '/manager/projects-dropdown';
   const getCategoriesURL = process.env.REACT_APP_PUBLIC_URL + '/manager/categories-dropdown';
   const reminderURL: string = process.env.REACT_APP_PUBLIC_URL + '/auth/reminder-email';

   const authStore = useContext(AuthContext);
   const spinnerService = useLoadingSpinner();
   const {refreshToken} = useApiService();

   useEffect(() => {
      const req: DashBoardRequestDto = {page: 1, pageSize: 50};
      getDashboardData(req, authStore.userData.accessToken);

      if (props.type === SKILL_INVENTORY_TYPE.PROJECT) {
         getProjectsDropDown(authStore.userData.accessToken);
      }

      getCategoriesDropDown(authStore.userData.accessToken);

   }, []);

   return (
      <>
         {
            initializedComponent &&
            <div className={styles['skills-inventory']}>
               <div className="inventory-header">
                  <h1 className="header-1 component-title">{capitalizeOnlyFirstLetter(props.type.valueOf())} Skill Set</h1>
                  <div className='search-button' onClick={props.showSearchPanel}>
                     <span className="search-icon"><i className="fa-solid fa-magnifying-glass"></i> Search for an employee</span>
                  </div>
                  {
                     props.downloadWalletsFlag &&
                     <button onClick={downloadCsvFile} disabled={!props.fileWalletsLink} className={'download-csv-button u-margin-left-xs'}><i className="fa-solid fa-file-arrow-down"></i>
                        {
                           typeof props.fileWalletsLink === "string" ? "Download as .csv file" :
                              <>Loading the .csv file link <PulseLoader loading={!props.fileWalletsLink} color='#B3BAC3' size={3} style={{display: 'inline', verticalAlign: '-2px'}} speedMultiplier={0.7} margin={2}/></>
                        }
                     </button>
                  }
               </div>
               <SkillsDetailsComponent data={initializedComponent}
                                       sideBarData={sideBarData}
                                       reminderUserEmail={(skillId, employeeIds) => reminderEmail(skillId, employeeIds, authStore.userData.accessToken)}
                                       categories={categories}
                                       skillInventoryType={props.type}
                                       projectsData={projectsData}
                                       sources={sources}
                                       dashboardRequest={(req: DashBoardRequestDto) => getDashboardData(req, authStore.userData.accessToken)}
                                       sideBarRequest={(skill: SkillsDashboardDto, skillFilter: SKILL_VIEW_FILTER) => sideBarRequest(skill, skillFilter, authStore.userData.accessToken)}
                                       closeSideBar={() => setSideBarData(undefined)} />
            </div>
         }
         {
            spinnerService.spinner
         }
      </>
   )

   function sideBarRequest(skill: SkillsDashboardDto, skillFilter: SKILL_VIEW_FILTER, accessToken: string) {

      const req: SideBarRequestDto = {
         synonymId: skill.skill.synonym ? skill.skill.synonym.id : undefined,
         skillId: skill.skill.id,
         skillViewFilter: skillFilter,
      }

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      axios
         .post(skillSideBarURL, req, {headers})
         .then((response$: { data: SideBarDto['skillPlusInformation'] }) => {
            setSideBarData({skill, activeSkillFilter: skillFilter, skillPlusInformation: response$.data});
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     sideBarRequest(skill, skillFilter, response$.data.accessToken);
                  })
            } else {
               showNotification('warning', error$.response.data.message);
            }
         })
   }

   function getDashboardData(req: DashBoardRequestDto, accessToken: string) {

      spinnerService.createSpinner();

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      axios
         .post(skillInventoryURL, req, {headers})
         .then((response$: { data: DashboardDto }) => {
            setInitializedComponent(response$.data);
            setSideBarData(undefined);
            spinnerService.removeSpinner();
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     getDashboardData(req, response$.data.accessToken);
                  })
            } else {
               spinnerService.removeSpinner();
               showNotification('warning', error$.response.data.message);
            }
         })
   }

   function getProjectsDropDown(accessToken: string) {

      spinnerService.createSpinner();

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      axios
         .post(getProjectsURL, undefined, {headers})
         .then((response$: { data: ProjectDto[] }) => {
            setProjectsData(response$.data);
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     getProjectsDropDown(response$.data.accessToken);
                  })
            } else {
               showNotification('warning', error$.response.data.message);
            }
         })

   }

   function getCategoriesDropDown(accessToken: string) {

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      const data = {
         isProjectSkillSet: props.type === SKILL_INVENTORY_TYPE.PROJECT
      }

      axios
         .post(getCategoriesURL, data, {headers})
         .then((response$: { data: string[] }) => {
            setCategories(response$.data);
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     getCategoriesDropDown(response$.data.accessToken);
                  })
            } else {
               spinnerService.removeSpinner();
               showNotification('warning', error$.response.data.message);
            }
         })
   }

   function reminderEmail(skillId: number, employeeIds: number[], accessToken: string) {

      spinnerService.createSpinner();

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      const data = {
         skillId,
         employeeIds
      }

      axios
         .post(reminderURL, data, {headers})
         .then(() => {
            spinnerService.removeSpinner();
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     reminderEmail(skillId, employeeIds, response$.data.accessToken);
                  })
            } else {
               spinnerService.removeSpinner();
               showNotification('warning', error$.response.data.message);
            }
         })

   }

   function downloadCsvFile() {
      if (props.fileWalletsLink) {
         const link = document.createElement('a');
         link.href = props.fileWalletsLink;
         link.download = '';
         document.body.appendChild(link);
         link.click();
         document.body.removeChild(link);
      }
   }
}

export default SkillsInventoryComponent;