import React from "react";
import style from './IntroductionPage.module.scss';
import {animated, useSpring} from "react-spring";

const IntroductionPage: React.FC<{
   changeSection: Function;
}> = props => {

   const FadeIn = ({ children, delay, className }: { children: React.ReactNode; delay: number, className?: string }) => {
      const fadeStyles = useSpring({
         from: { opacity: 0 },
         to: { opacity: 1 },
         config: { duration: 1000 },
         delay: delay,
      });

      return <animated.div className={className || ""} style={fadeStyles}>{children}</animated.div>;
   };

   const questions = [
      {
         title: "Answer 3 Quick Questions",
         subtitle: "Before diving into your AI Impact Score, take a few minutes to answer 3 short questions.",
      },
      {
         title: "Discover Automation Opportunities",
         subtitle: "Find out how much of your work can be automated with AI tools, making you more efficient and productive.",
      },
      {
         title: "Join the New Era",
         subtitle: "Explore how AI and skills come together to supercharge your career and be part of the exciting new marketplace!",
      },
   ];

   return (
      <>
         <div className={style['introduction-page']}>
            <FadeIn delay={500}>
               <h1 className="header-1 u-margin-bottom-l">We’re thrilled to have you!</h1>
            </FadeIn>
            <FadeIn delay={1000} className={"questions-wrapper"}>
               {
                  questions.map((question, i) => {
                     return (
                        <div className="question" key={i}>
                           <h3 className="question-title header-3">{question.title}</h3>
                           <div className="question-subtitle">
                              <i className="fa-solid fa-check"></i>
                              <span>{question.subtitle}</span>
                           </div>
                        </div>
                     )
                  })
               }
            </FadeIn>
            <FadeIn delay={1500}>
               <button className="button button-primary u-margin-top-l" onClick={() => props.changeSection()}>Start building your TeamLift profile <i className="fa-solid fa-arrow-right"></i></button>
            </FadeIn>
         </div>
      </>
   )
}

export default IntroductionPage;