function randomColor() {
    const colors = [
        '#C2D1E5',
        '#E5D6C2',
        '#D1C2E5',
        '#E5C2D4',
        '#C2E5D7',
        '#D8E5C2',
        '#E5C2C2',
        '#C2E5E2',
        '#D1E5C2',
        '#E5E2C2',
        '#C2D7E5',
        '#E2C2E5',
        '#D7E5C2',
        '#E5D4C2',
        '#C2E5D6',
        '#D4C2E5',
        '#E5C2D1',
        '#C2E2E5',
        '#E5C2E2',
        '#D1C2E5',
        '#E5E2D4',
        '#C2D1E5',
        '#E2E5C2',
        '#D1E5D4',
        '#E5C2D8',
        '#D6E5C2',
        '#E5D1C2',
        '#C2E5D1',
        '#E5C2D6',
        '#D1C2E2',
        '#E5D7C2',
        '#C2D1E2',
        '#E2C2D1',
        '#D7C2E5',
        '#E2D1C2',
    ];

    return colors[Math.floor(Math.random() * colors.length)];
}

export default randomColor;