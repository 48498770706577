import React from "react";

function Separator({ turns, style, isImage, imageSrc }) {
   return (
      <div
         style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            transform: `rotate(${turns}turn)`,
            transformOrigin: "center",
         }}
      >
         {isImage ? (
            <img src={imageSrc} alt="progress-end" style={{
               ...style,
               position: "absolute",
               top: "0",
               left: "50%",
               transform: "translate(-50%, -50%)"
            }}  />
         ) : (
            <div style={{
               ...style,
               position: "absolute",
               top: "0",
               left: "50%",
               transform: "translate(-50%, -50%)"
            }}  />
         )}
      </div>
   );
}

function RadialSeparators1({ count, style, progress, imageSrc }) {
   const turns = 1 / count;
   const progressTurns = progress / 100;

   return (
      <>
         {_.range(count).map((index) => (
            <Separator
               key={index}
               turns={index * turns}
               style={style}
               isImage={false}
            />
         ))}
         <Separator
            turns={progressTurns}
            style={{ ...style, width: '30px', height: '30px' }} // Adjust image size as needed
            isImage={true}
            imageSrc={imageSrc}
         />
      </>
   );
}

export default RadialSeparators1;

