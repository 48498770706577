import React, {ChangeEvent, useContext, useEffect, useRef, useState} from "react";
import {NavLink} from 'react-router-dom';
import axios, {AxiosRequestConfig} from "axios";
import styles from "./SkillsWalletPage.module.scss";

import {UserData} from '../../../../types/AuthData';
import {
    AnimationDateMapping,
    ApprovedStatus,
    PaginationDataDto,
    SearchSkillsWalletDto,
    SetDetailJsonDto, SkillsListInformationDto,
    SkillsResponseDto
} from '../../../../types/SkillsData';
import {SKILLS_SORT_TYPE} from '../../../../enums/Skills.enum';

import AuthContext from "../../../../store/auth-context";
import RateSkillsComponent from "./RateSkills/RateSkillsCard/RateSkillsComponent";

import useLoadingSpinner from '../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner';
import useApiService from '../../../../services/api.service';

import {showNotification} from '../../../../ui/Toast/ToastNotification';
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import SkillsComponent from "./SkillsComponent/SkillsComponent";
import {ErrorResponseDto} from "../../../../types/ErrorData";
import {Pagination, PaginationItem} from "@mui/material";

const SkillsWalletPage: React.FC = () => {
    // This is used because we show conditional content
    const initializedComponent = useRef(false);
    const {refreshToken} = useApiService();

    // User data
    const authStore = useContext(AuthContext);
    const userData: UserData = authStore.userData;

    // API urls
    const loadSkillWalletURL = process.env.REACT_APP_PUBLIC_URL + '/employee/skills-wallet';
    const acceptDenySkillURL =  process.env.REACT_APP_PUBLIC_URL + '/employee/approve-skill';
    const loadSearchedWalletURL =  process.env.REACT_APP_PUBLIC_URL + '/employee/searched-skills-wallet';

    // Skill wallet data
    const [skillsList, setSkillsList] = useState<SetDetailJsonDto[]>([]);
    const [skillStatusState, setSkillStatusState] = useState<ApprovedStatus>(ApprovedStatus.NEW);
    const [hasSocialValidation, setHasSocialValidation] = useState<boolean>(false);
    const [pageInfo, setPageInfo] = useState<PaginationDataDto>({page: 1, pageSize: 50, totalPages: 1, totalCount: 50});
    const [skillListInformation, setSkillListInformation] = useState<SkillsListInformationDto>();
    const spinnerService = useLoadingSpinner();

    // Searching skills
    const [searchData, setSearchData] = useState<SearchSkillsWalletDto>();

    // Adding skills animation
    const [plusSkillAnimation, setPlusSkillAnimation] = useState<AnimationDateMapping>(
       {
           [ApprovedStatus.NEW]: [],
           [ApprovedStatus.APPROVED]: [],
           [ApprovedStatus.REJECTED]: []
       }
    );

    const filterOptions = [
        {
            title: "Newest first",
            value: SKILLS_SORT_TYPE.NEWEST
        },
        {
            title: "Oldest first",
            value: SKILLS_SORT_TYPE.OLDEST
        },
        {
            title: "By name",
            value: SKILLS_SORT_TYPE.TITLE
        }
    ];

    const statusTabs = [
        ApprovedStatus.NEW,
        ApprovedStatus.APPROVED,
        ApprovedStatus.LEARNING,
        ApprovedStatus.REJECTED
    ];

    // Load skills
    useEffect(() => {
        getSkillsWallet(pageInfo.page, pageInfo.pageSize, userData.accessToken, ApprovedStatus.NEW);
    }, []);

    return (
        <div className={styles["skills-wallet"]}>
            {initializedComponent.current &&
                <div className="container-fluid">
                    {
                       (skillsList.length === 0 && skillListInformation && skillListInformation.learningListTotalCount ===0 && skillListInformation.approvedListTotalCount === 0 && skillListInformation.newListTotalCount === 0 && skillListInformation.rejectedListTotalCount === 0) ?
                            <div>
                                <h1 className="header-1 u-margin-bottom-xxs">No extracted skills</h1>
                                <p className='text-secondary u-margin-bottom-s'>We haven’t extracted any skills for you yet. Start populating your wallet by sharing some data with our AI.</p>
                                <NavLink to={'/file/personal-data-types'}
                                         className="button button-primary upload-personal-data-button">Upload Personal
                                    Data</NavLink>
                            </div> :
                            <>
                                <div className="row">
                                    <div className='col-12'>
                                        <div className="heading-wrapper">
                                            <h1 className='header-1'>Skills from TeamLift’s AI</h1>
                                            <p className="alt text-secondary u-margin-bottom-xs">Our AI thinks you have
                                                these
                                                skills! Add them to your wallet or deny. If you deny the skill, we will
                                                store it here for the next month.</p>
                                        </div>
                                    </div>
                                </div>
                                {hasSocialValidation &&
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <RateSkillsComponent/>
                                        </div>
                                    </div>
                                }
                                <div className="row">
                                    <div className="tabs-container">
                                        <Tabs className={'custom-tabs'} selectedTabClassName={'selected-tab'}>
                                            <TabList>
                                                <Tab onClick={() => makeApiCall(ApprovedStatus.NEW)}><img src={'/assets/images/global-icons/fact-check.svg'}
                                                          alt={'fact-image'}/>To review
                                                    {skillStatusState === ApprovedStatus.NEW && <span className={'badge rounded-pill'}>{pageInfo.totalCount}</span>}
                                                    {plusSkillAnimation[ApprovedStatus.NEW].map(a => <span
                                                       key={a.id}
                                                       className={'added-skill animateThis'}
                                                       onAnimationEnd={() => animationEnds(ApprovedStatus.NEW)}>+{a.counter}</span>)}
                                                </Tab>

                                                <Tab onClick={() => makeApiCall(ApprovedStatus.APPROVED)}><img src={'/assets/images/global-icons/validated-skills-icon.svg'}
                                                                                                               alt={'validated-image'}/>Validated skills {skillStatusState === ApprovedStatus.APPROVED && <span className={'badge rounded-pill'}>{pageInfo.totalCount}</span>}
                                                    {plusSkillAnimation[ApprovedStatus.APPROVED].map(a => <span
                                                       key={a.id}
                                                       className={'added-skill animateThis'}
                                                       onAnimationEnd={() => animationEnds(ApprovedStatus.APPROVED)}>+{a.counter}</span>)}</Tab>

                                                <Tab onClick={() => makeApiCall(ApprovedStatus.LEARNING)}><i className="fa-solid fa-book-open"></i>To learn
                                                    {skillStatusState === ApprovedStatus.LEARNING && <span className={'badge rounded-pill'}>{pageInfo.totalCount}</span>}</Tab>

                                                <Tab onClick={() => makeApiCall(ApprovedStatus.REJECTED)}><i className="fa-solid fa-trash-can"></i>Denied
                                                    {skillStatusState === ApprovedStatus.REJECTED && <span className={'badge rounded-pill'}>{pageInfo.totalCount}</span>}
                                                    {plusSkillAnimation[ApprovedStatus.REJECTED].map(a => <span
                                                       key={a.id}
                                                       className={'added-skill animateThis'}
                                                       onAnimationEnd={() => animationEnds(ApprovedStatus.REJECTED)}>+{a.counter}</span>)}</Tab>
                                            </TabList>
                                            {
                                                statusTabs.map(status => {
                                                    return (
                                                       <TabPanel key={status.toString()}>
                                                           {
                                                              skillStatusState === status &&
                                                              <SkillsComponent skillList={skillsList}
                                                                               searchData={searchData}
                                                                               addDenySkill={acceptDenySkill}
                                                                               searchSkills={searchSkills}
                                                                               returnSkill={acceptDenySkill}
                                                                               skillListInformation={skillListInformation}
                                                                               filterOptions={filterOptions}
                                                                               skillsType={status}/>
                                                           }
                                                       </TabPanel>
                                                    );
                                                })
                                            }
                                        </Tabs>
                                    </div>
                                </div>
                                <div className="pagination-row">
                                    <div className="categories">
                                        <div className="categories-title">Skills per page:</div>
                                        <div className="categories-number">{skillsList.length}</div>
                                    </div>
                                    <Pagination defaultPage={1} page={pageInfo.page} count={pageInfo.totalPages}
                                                variant="outlined"
                                                shape="rounded"
                                                renderItem={(item) => <PaginationItem {...item} />}
                                                onChange={clickedOnPage}/>
                                </div>

                            </>
                    }
                </div>
            }
            {
                spinnerService.spinner
            }
        </div>
    );

    function animationEnds(_status: ApprovedStatus) {

    }

    function searchSkills(name?: string, sources?: SearchSkillsWalletDto['sources']) {
        if (((name && name.trim().length === 0) || !name) && ((sources && sources.length === 0) || !sources)) {
            makeApiCall(skillStatusState);
        }
        else {
            setSearchData({name, sources});
            getSkillsWallet(1, pageInfo.pageSize, userData.accessToken, skillStatusState, {name, sources});
        }
    }

    function makeApiCall(skillStatus: ApprovedStatus) {
        setSearchData(undefined);
        setSkillStatusState(skillStatus);
        getSkillsWallet(1, pageInfo.pageSize, userData.accessToken, skillStatus);
    }

	function clickedOnPage(_event: ChangeEvent<any> | undefined, page: number, changeState?: AnimationDateMapping) {
        getSkillsWallet(page, pageInfo.pageSize, userData.accessToken, skillStatusState, searchData, changeState);
	}

    function getSkillsWallet(page: number, pageSize: number, accessToken: string, skillStatus: ApprovedStatus, searchParams?: SearchSkillsWalletDto, changeState?: AnimationDateMapping) {
        authStore.changePageTitle(userData.displayName + "'s Skills Wallet");

        let requestData: {page: number, pageSize: number, status: string, searchTerm?: string, sources?: string[]} = {
            page: page,
            pageSize: pageSize,
            status : skillStatus.toString()
        }

        const apiURL = searchParams ? loadSearchedWalletURL : loadSkillWalletURL;

        if (searchParams) {
            requestData.searchTerm = searchParams.name ? searchParams.name : undefined;
            requestData.sources = searchParams.sources ? searchParams.sources.map(s => s.value) : undefined;
        }

        const headers: AxiosRequestConfig['headers'] = {
            'Authorization': `Bearer ${accessToken}`
        }

        spinnerService.createSpinner();
        axios
            .post(apiURL, requestData, {headers})
            .then((res$: {data: SkillsResponseDto}) => {
                if (skillStatusState === ApprovedStatus.NEW) {
                    let currentDateTime = Date.now();

                    for (let item of res$.data.skills) {
                        let addedDateTime = Date.parse(item.dateAdded!.toString());
                        item.isNewSkill = (currentDateTime - addedDateTime < 86400000 * 2);
                    }
                }
                setPageInfo(prevState => {
                    return {
                        page: res$.data.pagination.page,
                        pageSize: prevState.pageSize,
                        totalPages: res$.data.pagination.totalPages,
                        totalCount: res$.data.pagination.totalCount,
                    }
                });
                setHasSocialValidation(res$.data.socialValidation);
                setSkillsList(res$.data.skills);
                setSkillListInformation(res$.data.skillsListInformation);
                initializedComponent.current = true;
                spinnerService.removeSpinner();
                setTimeout(() => {
                    if (changeState) {
                        setPlusSkillAnimation(changeState);
                    }
                }, 50);
            })
            .catch((error$: ErrorResponseDto) => {
                spinnerService.removeSpinner();

                if (error$.response.data.message === 'Unauthorized') {
                    initializedComponent.current = false;
                    // Get new Access Token
                    refreshToken(authStore.userData.refreshToken)
                        .then((response$: any) => {
                            authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                            getSkillsWallet(page, pageSize, response$.data.accessToken, skillStatus, searchParams, changeState);
                        })
                } else {
                    showNotification('warning', error$.response.data.message);
                    initializedComponent.current = true;
                    setSkillsList([]);
                }
            });
    }

    function acceptDenySkill(accessToken: string, _isTokenRefreshed: boolean, skillId: number | number[], isApproved: boolean, returnToNew: boolean = false) {
        spinnerService.createSpinner();

        if (!Array.isArray(skillId)) {
            skillId = [skillId]
        }

        let data: {} = {
            'skillIds': skillId,
            'approved': isApproved,
        }
        const headers: AxiosRequestConfig['headers'] = {
            'Authorization': `Bearer ${accessToken}`
        }

        axios
            .post(acceptDenySkillURL, data, {headers})
            .then((_res$) => {
                // true true - za new
                // true false - za validated
                // false false - za denied

                const counter = (skillId as number[]).length;

                let changeState;

                if (isApproved && returnToNew) {
                    changeState = {
                        ...plusSkillAnimation,
                        [ApprovedStatus.NEW]: [...plusSkillAnimation[ApprovedStatus.NEW], {id: Date.now(), counter}]
                    }
                }
                else if (isApproved && !returnToNew) {
                    changeState = {
                        ...plusSkillAnimation,
                        [ApprovedStatus.APPROVED]: [...plusSkillAnimation[ApprovedStatus.APPROVED], {id: Date.now(), counter}]
                    }
                }
                else if (!isApproved && !returnToNew) {
                    changeState = {
                        ...plusSkillAnimation,
                        [ApprovedStatus.REJECTED]: [...plusSkillAnimation[ApprovedStatus.REJECTED], {id: Date.now(), counter}]
                    }
                }

                if (skillsList.length - counter !== 0) { // To not make changes behind the loader
                    setSkillsList(prevState => {
                        return prevState.filter(s => !(skillId as number[]).find(sId => sId === s.skill.id));
                    });
                    if (changeState) {
                        setPlusSkillAnimation(changeState);
                    }
                    setPageInfo(prevState => {
                        return {
                            ...prevState,
                            totalCount: prevState.totalCount - counter
                        }
                    });
                    spinnerService.removeSpinner();
                }
                else {
                    const pageNumber = (pageInfo.page === pageInfo.totalPages && pageInfo.totalPages > 1) ? pageInfo.page - 1 : pageInfo.page;
                    clickedOnPage(undefined, pageNumber, changeState);
                }
            })
            .catch((error$: ErrorResponseDto) => {
                spinnerService.removeSpinner();
                if (error$.response.data.message === 'Unauthorized') {
                    // Get new Access Token
                    refreshToken(authStore.userData.refreshToken)
                        .then((response$: any) => {
                            authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                            acceptDenySkill(response$.data.accessToken, true, skillId, isApproved, returnToNew);
                        })
                } else {
                    spinnerService.removeSpinner();
                    showNotification('warning', error$.response.data.message);
                }
            });

        // if (!isTokenRefreshed) {
        //
        //     // skillId.forEach(skill => {
        //     //     let skillIndex: number = skillsList.findIndex(item => {
        //     //         return item.skill.id === skill;
        //     //     });
        //     //
        //     //     if (skillIndex > -1) {
        //     //         if (isApproved) {
        //     //             skillsList[skillIndex].approved = returnToNew ? ApprovedStatus.NEW : ApprovedStatus.APPROVED;
        //     //         } else {
        //     //             skillsList[skillIndex].approved = ApprovedStatus.REJECTED;
        //     //         }
        //     //         setSkillsList([...skillsList]);
        //     //     }
        //     // })
        // }
    }
};

export default SkillsWalletPage;