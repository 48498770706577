import React, {useContext, useState} from "react";
import {useNavigate} from "react-router-dom";
import axios, {AxiosRequestConfig} from "axios";

import SearchComponent from "./SearchComponent/SearchComponent";
import SearchResultComponent from "./SearchResultComponent/SearchResultComponent";

import styles from "./NeededSkillsPage.module.scss";

import AuthContext from "../../../../../../store/auth-context";
import useApiService from "../../../../../../services/api.service";

import useLoadingSpinner from '../../../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner';
import {showNotification} from "../../../../../../ui/Toast/ToastNotification";
import JobPostingDashboardPage from "../../../JobPostingsPage/JobPostingDashboardPage/JobPostingDashboardPage";
import {ErrorResponseDto} from "../../../../../../types/ErrorData";
import ProjectDetailsPage from "../ProjectDetailsPage/ProjectDetailsPage";

const NeededSkillsPage: React.FC<{ projectData: any, jobPosting?: boolean }> = (props) => {
   const authStore = useContext(AuthContext);
   const [showSearchComponent, setShowSearchComponent] = useState(false);
   const [searchResultData, setSearchResultData] = useState<any>(null);

   const searchSkillURL: string = process.env.REACT_APP_PUBLIC_URL + "/manager/needed-skill-view";
   const searchEmployeeURL: string = process.env.REACT_APP_PUBLIC_URL + "/manager/manager-career-mobility-view";

   const navigate = useNavigate();

   const spinnerService = useLoadingSpinner();
   const {refreshToken} = useApiService();
   const projectTitle: string = props.projectData.name;

   return (
      <div className={styles["manager-dashboard"]}>
         {
            !searchResultData &&
            <div className="col-12">
               <div className="manager-dashboard-header">
                  <button onClick={backToAllProjects} className="button button-tertiary">
                     <span><i
                        className="fa-solid fa-arrow-left"></i> Back to all {props.projectData.jobPosting ? 'tasks' : 'projects'}</span>
                  </button>
               </div>
            </div>
         }
         <>
            {
               !searchResultData && props.projectData.jobPosting && <JobPostingDashboardPage projectData={props.projectData} />
            }
            {
               !searchResultData && !props.projectData.jobPosting && <ProjectDetailsPage projectData={props.projectData} showSearchPanel={() => showSearchPanel(authStore.userData.accessToken)}/>
            }
            {
               showSearchComponent && <SearchComponent searchSkills={searchSkills} searchEmployee={searchEmployee}
                                                       cancelSearch={closeSearchPanel}
                                                       projectId={props.projectData.id}/>
            }
            {
               searchResultData &&
               <SearchResultComponent title={projectTitle} backToNeededSkills={showNeededSkillsComponent}
                                      data={searchResultData}
                                      searchUser={(id: number, searchedFromSkillsList: boolean) => searchEmployee(id, authStore.userData.accessToken, searchedFromSkillsList)}/>
            }
         </>
         {
            spinnerService.spinner
         }
      </div>
   );

   function showNeededSkillsComponent(_event: any, prevData?: any) {
      if (prevData) {
         setSearchResultData(prevData);
      } else {
         setSearchResultData(null);
      }
   }

   function showSearchPanel(_accessToken: string) {
      setShowSearchComponent(true);
   }

   function closeSearchPanel(_e: any) {
      setShowSearchComponent(false);
   }

   function searchSkills(skillsList: any, accessToken: string) {
      spinnerService.createSpinner();
      // close search form
      setShowSearchComponent(false);

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      let projectSkillIds: number[] = [];
      for (let i = 0; i < skillsList.length; i++) {
         projectSkillIds.push(skillsList[i].value); // value = projectSkillId
      }

      let data = {
         projectId: props.projectData.id,
         projectSkillIds
      }

      axios
         .post(searchSkillURL, data, {headers})
         .then((response$: any) => {
            response$.data.resultType = "skills";
            response$.data.skillList = skillsList;
            setSearchResultData(response$.data);
            spinnerService.removeSpinner();
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     searchSkills(skillsList, response$.data.accessToken);
                  })
            } else {
               spinnerService.removeSpinner();
               showNotification('warning', error$.response.data.message);
            }
         })
   }

   function searchEmployee(employeeId: number, accessToken: string, searchedFromSkillsList: boolean = false) {
      spinnerService.createSpinner();

      // close search form
      setShowSearchComponent(false);

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }
      let params: {} = {
         employeeId: employeeId,
         projectId: props.projectData.id
      }

      axios
         .post(searchEmployeeURL, params, {headers})
         .then((response$) => {
            if (searchedFromSkillsList) {
               response$.data.prevData = searchResultData;
            }
            response$.data.resultType = "employees";

            for (let category of response$.data.wallet) {
               for (let skill of category.setDetails) {
                  let currentDateTime = Date.now();
                  let addedDateTime = Date.parse(skill.dateAdded as string);
                  skill.isNewSkill = (currentDateTime - addedDateTime) < (1000 * 60 * 60 * 24 * 2);
               }
            }
            setSearchResultData(response$.data);
            spinnerService.removeSpinner();
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     searchEmployee(employeeId, response$.data.accessToken, searchedFromSkillsList);
                  })
            } else {
               spinnerService.removeSpinner();
               showNotification('warning', error$.response.data.message);
            }
         })
   }

   function backToAllProjects() {
      navigate(`/manager/${props.projectData.jobPosting ? 'tasks' : 'projects'}`);
   }
};

export default NeededSkillsPage;