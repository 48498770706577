import styles from'./SkillsListCard.module.scss';

import {CircularProgressbar, buildStyles, CircularProgressbarWithChildren} from 'react-circular-progressbar';
import ReactTooltip from 'react-tooltip';
import Tooltip from "../../../../../../../../services/tooltip.service";
import {capitalizeFirstLetter} from "../../../../../../../../utils/capitalize-first-letter";
import {SKILL_STATUS} from "../../../../../../../../enums/Skills.enum";
import RadialSeparators from "../../../../../../../../utils/RadialSeparators";

const SkillsListCard: React.FC<{item: any}> = (props) => {
  let validationTotalProgress: number = props.item.validationFormula ? props.item.validationFormula.totalValidationScore : 0;
  const validationTotalLevel: string = props.item.validationFormula ? props.item.validationFormula.totalValidationLevel : 0;

  let tooltipValidationTitle: string = ''; 
  let chartColor: string = '';

  switch(validationTotalLevel) {
    case 'LOW': 
      tooltipValidationTitle = `Low`;
      chartColor = '#A0A8E5';
      break;
    case 'MEDIUM': 
      tooltipValidationTitle = `Medium`;
      chartColor = '#5969D4';
      break;
    case 'HIGH': 
      tooltipValidationTitle = `High`;
      chartColor = '#3146C1';
      break;
  }
  
  return (
    <>
      <div className={styles['skill-card']}>                    
        <div className="title-wrapper">
          
          {/* Validation chart */}
          <Tooltip
             tooltipId={`${props.item.skill.id}_skill_title_tooltip`}
             place="right"
             backgroundColor="#101319"
             borderColor='#5C5F6A !important'
             border={true}
             content={
               <>
                 {
                    (props.item.approved === SKILL_STATUS.NEW.valueOf() || props.item.approved === SKILL_STATUS.APPROVED.valueOf()) &&
                    <>
                      <div className={'validation-title'}><strong>{tooltipValidationTitle}</strong> level of confidence</div>
                      <div className={'validation-content'}>{props.item.validationFormula.message}</div>
                    </>
                 }
                 {
                    props.item.approved === SKILL_STATUS.LEARNING.valueOf() &&
                    <>
                      <div className="validation-title"><strong>Learning</strong> skill</div>
                      <div className="validation-content">This employee is currently learning this skill.</div>
                    </>
                 }
               </>}
             className={'tooltip-validation'}>
            <div className="social-validation-chart-container">
              {
                 (props.item.approved === SKILL_STATUS.NEW.valueOf() || props.item.approved === SKILL_STATUS.APPROVED.valueOf()) &&
                 <CircularProgressbar
                    value={validationTotalProgress} strokeWidth={28}
                    styles={buildStyles({pathColor: chartColor, trailColor: '#383A46FF', strokeLinecap: "butt"})} />
              }
              {
                 props.item.approved === SKILL_STATUS.LEARNING.valueOf() &&
                 <CircularProgressbarWithChildren value={100} strokeWidth={28}
                                                  styles={buildStyles({pathColor: '#383A46FF', trailColor: '#383A46FF', strokeLinecap: "butt"})}>
                   <RadialSeparators count={7}
                                     style={{
                                       background: "#EAECF0FF",
                                       width: "2px",
                                       // This needs to be equal to props.strokeWidth
                                       height: `${25}%`
                                     }}/>
                 </CircularProgressbarWithChildren>
              }
            </div>
          </Tooltip>
          
          {/* Skill title */}
          <div className='skill-name'>
            {capitalizeFirstLetter(props.item.skill.name)}
          </div>

          {/* Skill description */}
          {
            props.item.skill.description &&
             <Tooltip
                tooltipId={`${props.item.skill.id}_skill_tooltip`}
                place="right"
                backgroundColor="#101319"
                borderColor='#5C5F6A !important'
                border={true}
                content={
               <>
                 <span className={'description-content'}>{props.item.skill.description}</span>
               </>}
                className={'tooltip-description'}>
               <span className={'skill-description'}><i className="fa-solid fa-circle-question"></i></span>
             </Tooltip>
          }
        </div>

        {
          props.item.isNewSkill && <span className='self-reported-tag bg-text-primary text-surface-90' style={{'marginRight': '8px'}}>new</span>
        }
      </div>
    </> 
  )
}

export default SkillsListCard;