import {useEffect, useState} from "react";
import Cropper from "react-easy-crop";
import {Slider} from "@mui/material";
import getCroppedImg from "./CroppedImage";

const CropImageComponent = (props: any) => {

    const [crop, setCrop] = useState({x: 0, y: 0})
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState({x: 0, y: 0, width: 0, height: 0})
    const [croppedImage, setCroppedImage] = useState('')
    const [onHover, setOnHover] = useState(false)

    const handleMouseOver = () => {
        setOnHover(true)
    }

    const handleMouseLeave = () => {
        setOnHover(false)
    }

    const cropCompleteHandler = (croppedArea: any, croppedAreaPixels: any) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }

    useEffect(() => {
        if (props.clickedSave) {
            cropImage().then(r => {})
        }
    }, [props.clickedSave])

    const cropImage = async () => {
        try {
            const croppedImage = await getCroppedImg(
                props.photoUrl,
                croppedAreaPixels,
                0
            );
            // @ts-ignore
            setCroppedImage(croppedImage.url)
            // @ts-ignore
            props.uploadCroppedImage(croppedImage.file)
        } catch (e) {}
    }

    return (
        <>
            <div style={{
                background: "transparent",
                position: 'relative',
                height: '50vh',
                width: 'auto',
                minWidth: '{sm: 500}'
            }}>
                <Cropper
                    image={props.photoUrl}
                    onCropChange={setCrop}
                    crop={crop}
                    zoom={zoom}
                    rotation={rotation}
                    aspect={1}
                    cropShape={"round"}
                    onZoomChange={setZoom}
                    onRotationChange={setRotation}
                    onCropComplete={cropCompleteHandler}
                    showGrid={false}
                />
            </div>
            <div style={{
                flexDirection: 'column',
                margin: '3 2',
            }}>
                <div style={{width: '100%', marginBottom: '3', marginTop: '3vh'}}>
                    <div>
                        {/*<Typography>Zoom: {zoom}</Typography>*/}
                        <Slider
                            valueLabelDisplay={'auto'}
                            value={zoom}
                            min={1}
                            max={3}
                            step={0.1}
                            aria-labelledby="Zoom"
                            onChange={(e, zoom) => setZoom(Number(zoom))}
                            classes={{root: "slider"}}
                            style={{color: !onHover ? '#A0A8E5' : '#5969D4', transition: 'color .7s'}}
                            onMouseEnter={handleMouseOver}
                            onMouseLeave={handleMouseLeave}
                        />
                    </div>
                </div>
                {/*<div>*/}
                {/*    <button onClick={cropImage}>Save</button>*/}
                {/*</div>*/}
            </div>
            {/*<div style={{width: '200px', height: '200px'}}>*/}
            {/*    <img style={{height: '200px', width: 'auto', borderRadius: '50%'}} src={croppedImage} alt={'image'}/>*/}
            {/*</div>*/}
        </>
    )
}

export default CropImageComponent;