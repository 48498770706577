export type QuestionsResponseDto = {
   questions: QuestionsDto[];
   totalSections: number;
   assessmentId: number;
   finished: boolean;
}
export type QuestionsDto = {
   section: string;
   questions: QuestionDto[];
}

export type QuestionDto = {
   questionId: number;
   optionA: string;
   optionB: string;
}

export type AnswerDto = {
   questionId: number;
   weight: number;
   option: string;
}

export type ResultsDto = {
   name: string;
   date: string;
   persona: string;
   finished: boolean;
   skills: SkillDto[];
   collaborationRole: RoleDto;
   underlyingEnabler: RoleDto;
   powerSynergy: RoleDto;
   rationality: RoleDto;
   intuitive: RoleDto;
}

export type SkillDto = {
   name: string;
   weight: number;
}

export type RoleDto = {
   name: string;
   description: string;
   url: string | null;
}

export const QuestionAnswers = {
   option: [
      {
         name: "A",
         weight: [
            {
               id: 2,
               name: "Strongly"
            },
            {
               id: 1,
               name: "Moderately"
            }
         ]
      },
      {
         name: "A",
         weight: [
            {
               id: 0,
               name: "No preference"
            },
         ]
      },
      {
         name: "B",
         weight: [
            {
               id: 1,
               name: "Moderately"
            },
            {
               id: 2,
               name: "Strongly"
            }
         ]
      },
   ],
}

export const PowerSynergyIcons = new Map([
   ["agent", "/assets/images/personality-insights-icon/power-synergy/agent-icon.svg"],
   ["communicator", "/assets/images/personality-insights-icon/power-synergy/communicator-icon.svg"],
   ["fighter", "/assets/images/personality-insights-icon/power-synergy/fighter-icon.svg"],
   ["make-doer", "/assets/images/personality-insights-icon/power-synergy/make-doer-icon.svg"],
   ["observer", "/assets/images/personality-insights-icon/power-synergy/observer-icon.svg"],
   ["relationship-builder", "/assets/images/personality-insights-icon/power-synergy/relationship-builder-icon.svg"],
   ["problem-solver", "/assets/images/personality-insights-icon/underlying-enabler/problem-solving-icon.svg"],
]);

export const UnderlyingEnablerIcons = new Map([
   ["aware", "/assets/images/personality-insights-icon/underlying-enabler/aware-icon.svg"],
   ["communicative", "/assets/images/personality-insights-icon/underlying-enabler/communicative-icon.svg"],
   ["go-getting", "/assets/images/personality-insights-icon/underlying-enabler/go-getting-icon.svg"],
   ["problem-solving", "/assets/images/personality-insights-icon/underlying-enabler/problem-solving-icon.svg"],
   ["resilient", "/assets/images/personality-insights-icon/underlying-enabler/resilient-icon.svg"],
   ["resourceful", "/assets/images/personality-insights-icon/underlying-enabler/resourceful-icon.svg"],
   ["sociable", "/assets/images/personality-insights-icon/underlying-enabler/sociable-icon.svg"]
]);

export const CollaborativeRoleIcons = new Map([
   ["relationship-builder", "/assets/images/personality-insights-icon/power-synergy/relationship-builder-icon.svg"],
   ["observer", "/assets/images/personality-insights-icon/power-synergy/observer-icon.svg"],
   ["fighter", "/assets/images/personality-insights-icon/power-synergy/fighter-icon.svg"],
   ["problem-solver", "/assets/images/personality-insights-icon/underlying-enabler/problem-solving-icon.svg"],
   ["agent", "/assets/images/personality-insights-icon/power-synergy/agent-icon.svg"],
   ["communicator", "/assets/images/personality-insights-icon/power-synergy/communicator-icon.svg"],
   ["make-doer", "/assets/images/personality-insights-icon/power-synergy/make-doer-icon.svg"],
]);