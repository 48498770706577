import React, {useContext, useState} from 'react';
import styles from './SettingsPeopleComponent.module.scss';
import ProjectEmployeesGrid from './ProjectEmployeesGrid/ProjectEmployeesGrid';
import ProjectTeamWrapperComponent from "./ProjectTeamWrapperComponent/ProjectTeamWrapperComponent";
import AuthContext from "../../../../../../../store/auth-context";
import {ProjectDto} from "../../../../../../../types/Projects";

const SettingsPeopleComponent: React.FC<{
   projectData: ProjectDto,
   managers: string[],
   initializeComponent: Function
}> = (props) => {
   const userData = useContext(AuthContext).userData;
   const [showAddPeople, setShowAddPeople] = useState<boolean>(false);

   return (
      <div className={styles['settings-people-component']}>
         {
            !showAddPeople ?
            <ProjectEmployeesGrid addEditComponent={addEditComponent} managers={props.managers} projectData={props.projectData}/> :
            <ProjectTeamWrapperComponent projectId={props.projectData.id} removeEditComponent={removeEditComponent}/>
         }
      </div>
   )

   function addEditComponent() {
      setShowAddPeople(true);
   }

   function removeEditComponent() {
      props.initializeComponent(userData.accessToken);
      setShowAddPeople(false);
   }
}

export default SettingsPeopleComponent;