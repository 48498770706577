import EmployeeInfo from "../../../../../../EmployeePages/EmployeeDashboard/EmployeeInfo/EmployeeInfo";
import PersonalHighlights from "../../../../../../EmployeePages/EmployeeDashboard/PersonalHighlights/PersonalHighlights";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import SkillsList from "../../SkillsList/SkillsList";
import PersonalGrowthComponent from "../../../../../../EmployeePages/EmployeeDashboard/PersonalGrowthComponent/PersonalGrowthComponent";
import {EmployeeDashboardData} from "../../../../../../../../types/EmployeeDashboard";
import {useNavigate} from "react-router-dom";
import styles from './EmployeesResultComponent.module.scss';
import {SKILL_INVENTORY_TYPE} from "../../../../../../../../types/SkillsInventoryData";
import {capitalizeFirstLetter} from "../../../../../../../../utils/capitalize-first-letter";
import React from "react";

const EmployeesResultComponent: React.FC<{title?: string, backToNeededSkills: Function, data: EmployeeDashboardData | any,skillInventoryType?: SKILL_INVENTORY_TYPE}> = (props) => {

   const navigate = useNavigate();

   return (
      <>
         <div className={styles['employees-result-component']}>
            <div className={'back-link'}>
            {
               props.title ?
                     <>
                        <button className={'button button-tertiary'} onClick={backToAllProjects}>All projects</button> <span>/</span> <button className={'button button-tertiary'}
                        onClick={(e : any) => props.backToNeededSkills(e, null)}>{props.title}</button>
                        {props.data.prevData && <> / <button className="button button-tertiary"
                                                             onClick={(e: any) => props.backToNeededSkills(e, props.data.prevData)}><span>Back to skills</span></button> </>} / <span>{props.data.employeeDashboard.employee.name} {props.data.employeeDashboard.employee.surname}</span>
                     </>
                  :
                  <>
                     <button className={'button button-tertiary'}
                              onClick={(e) => props.backToNeededSkills(e, null)}>{props.skillInventoryType ? capitalizeFirstLetter(props.skillInventoryType.valueOf()) : ''} Skill Set</button>
                          {props.data.prevData && <> / <button className="button button-tertiary"
                                                                onClick={(e: any) => props.backToNeededSkills(e, props.data.prevData)}><span>Back to skills</span></button> </>}
                     <span>/</span>
                     <span>{props.data.employeeDashboard.employee.name} {props.data.employeeDashboard.employee.surname}</span>
                     </>
               }
            </div>
            <div className="row">
               <div className="col-12 col-xl-5">
                  <EmployeeInfo name={props.data.employeeDashboard.employee.name}
                                surname={props.data.employeeDashboard.employee.surname}
                                profileImageUrl={props.data.employeeDashboard.employee.profilePicture}
                                jobPositionName={props.data.dashboardAvailable ? props.data.employeeDashboard.jobPosition.name : props.data.jobPositionTitle}
                                readOnlyMode={true}
                                color={props.data.employeeDashboard.employee.color}
                  />

                  {
                     props.data.dashboardAvailable ?
                        <PersonalHighlights mostUsedSkills={props.data.employeeDashboard.personalMostUsedSkills}
                                            uniqueSkills={props.data.employeeDashboard.personalUniqueSkills}
                                            readOnlyMode={true}/> :
                        <>
                           {props.data.wallet.length > 0 &&
                              <div className='more-data-container d-none d-xl-block'>
                                 <span>You will see more data after this employee<br/>confirms their job title</span>
                              </div>
                           }
                        </>
                  }
               </div>

               {(props.data.wallet.length > 0 || props.data.dashboardAvailable) &&
                  <>
                     {(props.data.wallet.length > 0 && props.data.dashboardAvailable) ?
                        <div className="tabs-container col-12 col-xl-7 col-xxl-6">
                           <Tabs className={'custom-tabs'} selectedTabClassName={'selected-tab'}>
                              <TabList>
                                 <Tab>Skills wallet</Tab>
                                 <Tab>Career growth potential</Tab>
                              </TabList>

                              <TabPanel>
                                 <SkillsList skillsWallet={props.data.wallet} isTabComponent={true}/>
                              </TabPanel>
                              <TabPanel>
                                 <PersonalGrowthComponent data={props.data.employeeDashboard} readOnlyMode={true}
                                                          isTabComponent={true}/>
                              </TabPanel>
                           </Tabs>
                        </div> :
                        <div className="col-12 col-xl-7 col-xxl-6">
                           {props.data.dashboardAvailable &&
                              <PersonalGrowthComponent data={props.data.employeeDashboard} readOnlyMode={true}
                                                       isTabComponent={false}/>
                           }
                           {props.data.wallet.length > 0 &&
                              <SkillsList skillsWallet={props.data.wallet} isTabComponent={false}/>
                           }
                        </div>
                     }
                  </>
               }
            </div>
         </div>
      </>
   );

   function backToAllProjects() {
      navigate('/manager/projects');
   }
}

export default EmployeesResultComponent;