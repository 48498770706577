import React, {useContext, useEffect, useRef, useState} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {PulseLoader} from "react-spinners";
import AuthContext from "../../store/auth-context";
import useApiService from "../../services/api.service";
import {EmployeeDto} from "../../types/Settings";
import {
   PaginationDto
} from "../../components/MainPage/EmployeePages/EmployeeOnboardPage/MapTeamSkillsPage/MapTeamSkillsPage";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {SearchEmployeesDto} from "../../types/EmployeeOnboardData";
import {ErrorResponseDto} from "../../types/ErrorData";
import {showNotification} from "../Toast/ToastNotification";
import style from './TagEmployeesInput.module.scss';
import {Avatar} from "@mui/material";

const TagEmployeesInput: React.FC<{
   setContent: (content: string) => void
}> = props => {

   const authStore = useContext(AuthContext);
   const {refreshToken} = useApiService();

   const [employees, setEmployees] = useState<EmployeeDto[]>([]);
   const [pagination, setPagination] = useState<PaginationDto>({page: 1, pageSize: 10, searchTerm: "", totalCount: 1});

   const [content, setContent] = useState('');
   const [showModal, setShowModal] = useState(false);
   const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
   const textareaRef = useRef<HTMLTextAreaElement>(null);
   const mirrorDivRef = useRef<HTMLDivElement>(null);
   const mirrorDivContentRef = useRef<HTMLDivElement>(null);
   const [taggedPersons, setTaggedPersons] = useState<string[]>([]);
   const debounceTimeout = useRef<any>(null);

   useEffect(() => {
      const textarea = textareaRef.current;
      const mirrorDiv = mirrorDivRef.current;
      const computedStyle = getComputedStyle(textarea!);

      mirrorDiv!.style.width = computedStyle.width;
      mirrorDiv!.style.height = computedStyle.height;
      mirrorDiv!.style.lineHeight = computedStyle.lineHeight;
      mirrorDiv!.style.fontSize = computedStyle.fontSize;
      mirrorDiv!.style.fontFamily = computedStyle.fontFamily;
      mirrorDiv!.style.whiteSpace = 'pre-wrap';
      mirrorDiv!.style.wordWrap = 'break-word';
   }, []);

   useEffect(() => {
      if (mirrorDivRef.current) {
         mirrorDivRef.current.innerHTML = getHighlightedText(content);
      }
      props.setContent(content);
   }, [content]);

   return (
      <>
         <div className={style['text-area-container']}>
            <textarea ref={textareaRef} value={content} onChange={handleTextareaChange} className={'custom-input'} placeholder={'ex. @John doe was working with React on this task...'}
                      style={{height: `${textareaRef.current ? textareaRef.current.scrollHeight < 200 ? "200px" : textareaRef.current.scrollHeight + "px" : "200px"}`}} />
            <div ref={mirrorDivRef} className={"mirror-div"} style={{height: `${textareaRef.current ? textareaRef.current.scrollHeight < 200 ? "200px" : textareaRef.current.scrollHeight + "px" : "200px"}`}}>
               <div className="mirror-div-content" ref={mirrorDivContentRef}></div>
            </div>
            {showModal && (
               <div
                  className="modal"
                  style={{
                     top: modalPosition.top + 30,
                     left: modalPosition.left,
                  }}>
                  {
                     <InfiniteScroll height={"300px"} next={() => getEmployees(({
                        ...pagination,
                        page: pagination.page + 1
                     }), authStore.userData.accessToken)}
                                     hasMore={employees.length < pagination.totalCount}
                                     loader={<div className={'loader text-primary-20'}>Loading <PulseLoader
                                        color='#A0A8E5FF' size={3} style={{display: 'inline', verticalAlign: '-2px'}}
                                        speedMultiplier={0.7} margin={2}/></div>}
                                     dataLength={employees.length}>
                        {
                           employees.map((e, index) => {
                              return <div onClick={() => handlePersonClick(`${e.name} ${e.surname}`)} key={`${e.id}_${index}`} className="employee-card">
                                 <div className="picture u-margin-right-xs">
                                    {
                                       e.profilePicture ? <Avatar src={e.profilePicture} /> : <Avatar sx={{backgroundColor: e.color}}>{e.name.substring(0, 1).toUpperCase()}</Avatar>
                                    }
                                 </div>
                                 <div className="name">{e.name} {e.surname}</div>
                              </div>
                           })
                        }
                     </InfiniteScroll>
                  }
               </div>
            )}
         </div>
      </>
   )

   function getEmployees(pagination: PaginationDto, accessToken: string) {
      const getEmployeesURL = process.env.REACT_APP_PUBLIC_URL + "/manager/search-employees";

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      axios
         .post(getEmployeesURL, pagination, {headers})
         .then((response$: AxiosResponse<SearchEmployeesDto>) => {
            if (pagination.page === 1) {
               setEmployees(response$.data.searchedEmployees);
            } else {
               setEmployees(prevState => [...prevState, ...response$.data.searchedEmployees]);
            }
            setPagination(({...pagination, totalCount: response$.data.totalCount}));
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     getEmployees(pagination, response$.data.accessToken);
                  })
            } else {
               showNotification('warning', error$.response.data.message);
            }
         })
   }

   function handleTextareaChange(e: any) {
      setContent(e.target.value);

      const {selectionStart} = e.target;

      const textBeforeCursor = e.target.value.substring(0, selectionStart);
      const lastAtIndex = textBeforeCursor.lastIndexOf('@');

      if (lastAtIndex !== -1 && (textBeforeCursor[lastAtIndex - 1] === " " || textBeforeCursor.length === 1 || lastAtIndex === 0 || textBeforeCursor[lastAtIndex - 1] === "\n")) {
         const searchTerm = textBeforeCursor.substring(lastAtIndex + 1, textBeforeCursor.length);
         if (/^[a-zA-Z]+$/.test(searchTerm) || searchTerm.length === 0) {
            const position = getCaretCoordinates(e.target, selectionStart);
            setModalPosition(position);
            setShowModal(true);

            if (debounceTimeout.current) {
               clearTimeout(debounceTimeout.current);
            }

            // Set a new timeout
            debounceTimeout.current = setTimeout(() => {
               // search employees
               getEmployees({page: 1, pageSize: 10, searchTerm, totalCount: 1}, authStore.userData.accessToken);
            }, 500);

         } else {
            setShowModal(false);
            setEmployees([]);
            setPagination({ page: 1, pageSize: 10, searchTerm: '', totalCount: 1});
            // set pagination to default and employee list to []
         }
      } else {
         setShowModal(false);
         setEmployees([]);
         setPagination({ page: 1, pageSize: 10, searchTerm: '', totalCount: 1});
         // set pagination to default and employee list to []
      }
   }

   function getCaretCoordinates(element: any, position: any){
      const div = mirrorDivRef.current;
      const span = document.createElement('span');
      const textContent = element.value.substring(0, position);

      div!.textContent = textContent;
      span.textContent = element.value.substring(position) || '.';
      div!.appendChild(span);

      const { offsetTop: top, offsetLeft: left } = span;
      div!.textContent = '';
      return { top: top + element.offsetTop, left: element.offsetLeft };
   }

   function handlePersonClick(person: string) {
      const lastAtIndex = content.lastIndexOf('@');
      const updatedContent = `${content.slice(0, lastAtIndex)}@${person} `;
      setContent(updatedContent);
      setShowModal(false);
      setPagination({page: 1, pageSize: 10, searchTerm: "", totalCount: 1});
      textareaRef.current!.focus();
      setEmployees([]);
      setTaggedPersons([...taggedPersons, person]);
   }

   function getHighlightedText(text: any) {
      let highlightedText = text;
      taggedPersons.forEach(person => {
         const personTag = `@${person}`;
         const regex = new RegExp(`(${personTag})`, 'g');
         highlightedText = highlightedText.replace(regex, `<span class="highlight">${personTag}</span>`);
      });
      return highlightedText;
   }
}

export default TagEmployeesInput;