import React, { useState, useEffect, useContext, useRef } from 'react';
import { useSpring, animated, useTransition } from 'react-spring';
import axios from 'axios';
import styles from './ProjectNeededSkillsComponent.module.scss';
import AuthContext from '../../../../../../../store/auth-context';
import { UserData } from '../../../../../../../types/AuthData';
import { showNotification } from '../../../../../../../ui/Toast/ToastNotification';
import OnboardingManagerContext from '../../../../../../../store/create-project-context';
import { ExtractedNeededSkillData } from '../../../../../../../types/ProjectOnboardingData';
import { PulseLoader } from 'react-spinners';
import Pusher from 'pusher-js';
import { FileUploader } from 'react-drag-drop-files';
import Tooltip from '../../../../../../../services/tooltip.service';
import useApiService from '../../../../../../../services/api.service';
import SpinnerButton from "../../../../../../../ui/SpinnerButton/SpinnerButton";
import {capitalizeFirstLetter} from "../../../../../../../utils/capitalize-first-letter";
import {ErrorResponseDto} from "../../../../../../../types/ErrorData";

const ProjectNeededSkillComponent: React.FC<{ changeSection: Function, isDraft: boolean, projectId: any, clickedSaveAsDraft:boolean, saved: Function, saveProgress: Function, uploadedFile: boolean, jobPosting?: boolean}> = (props) => {
  const authStore = useContext(AuthContext);
  const userData: UserData = authStore.userData;
  const onboardingManagerStore = useContext(OnboardingManagerContext);
   
  const [showGeneratingSkillsTitle, setShowGeneratingSkillsTitle] = useState(true);
  const [showFindSkillButton, setShowFindSkillButton] = useState(false);
  const [projectDescriptionInput, setProjectDescriptionInput] = useState<string>('');
  const [projectDescriptionResponse, setProjectDescriptionResponse] = useState<string>('');
  const [runAi, setRunAi] = useState<boolean>(false);
  const [dataIsLoadingDesc, setDataIsLoadingDesc] = useState<boolean>(false);
  const apiService = useApiService();
  
  const lastSkillElement: any = onboardingManagerStore.extractedNeededSkillsData ? onboardingManagerStore.extractedNeededSkillsData[onboardingManagerStore.extractedNeededSkillsData.length - 1] : null;

  // Animation definitions
  const [generatingSkillsTextSpring, generatingSkillsTextSpringApi] = useSpring(() => ({
    from: { opacity: 0 },
    to: { opacity: 1 }
  }))

  const [neededSkillsTextSpring, neededSkillsTextSpringApi] = useSpring(() => ({
    from: { opacity: 0 }
  }))

  const [findSkillSpring, findSkillSpringApi] = useSpring(()=> ({
    key: 'find-skill',
    from: { opacity: 0 }
  }))

  const [deleteSkillSpring, deleteSkillSpringApi] = useSpring(()=> ({
    key: 'delete-skill',
    from: { top: '95%', opacity: 0 }
  }))

  const [downloadText, downloadTextApi] = useSpring(()=> ({
    key: 'download-text',
    from: { opacity: 0 }
  }))

  const [downloadButton, downloadButtonApi] = useSpring(()=> ({
    key: 'download-button',
    from: { opacity: 0 }
  }))

  const [runAISpring, runAISpringApi] = useSpring(()=> ({
    key: 'run-ai',
    from: { top: '95%', opacity: 0 }
  }))

  const [nextStepSpring, nextStepSpringApi] = useSpring(()=> ({
    key: 'next-step',
    from: { top: '95%', opacity: 0 }
  }))

  const [projectDescResSpring, projectDescResSpringApi] = useSpring(()=> ({
    from: { top: '95%', opacity: 0 }
  }))

  const transitions = useTransition(onboardingManagerStore.extractedNeededSkillsData ? onboardingManagerStore.extractedNeededSkillsData : [], {    
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 1 },
    trail: 200,
    delay: 500,
    update: null,
    key: (item: any) => {
      return item.skillAIId
    },    
    onRest: (event: any, event2: any, element: ExtractedNeededSkillData) => {  
      if (lastSkillElement && lastSkillElement.skillName === element.skillName) {
        setShowGeneratingSkillsTitle(false);
        setShowFindSkillButton(true);
        
        neededSkillsTextSpringApi.start({
          from: { opacity: 0 }, to: { opacity: 1 }
        })
        findSkillSpringApi.start({             
          to: { opacity: 1 }       
        })  
        deleteSkillSpringApi.start({  
          to: { top: '0%', opacity: 1 },
          delay: 800,     
        })
        downloadTextApi.start({
          to: { opacity: 1 },
        })
        downloadButtonApi.start({
          to: { opacity: 1 },
        })
        runAISpringApi.start({      
          to: { top: '0%', opacity: 1 },
          delay: 1600,        
        })
        nextStepSpringApi.start({    
          to: { top: '0%', opacity: 1 },
          delay: 2400,              
        })
      }
    }
  })

  useEffect(()=> {
    if (onboardingManagerStore.extractedNeededSkillsData && onboardingManagerStore.extractedNeededSkillsData.length > 0) {
      setShowGeneratingSkillsTitle(false);
      setShowFindSkillButton(true);

      neededSkillsTextSpringApi.start({
        from: { opacity: 0 }, to: { opacity: 1 }
      }),
      findSkillSpringApi.start({
        to: { opacity: 1 }       
      })  
      deleteSkillSpringApi.start({  
        to: { top: '0%', opacity: 1 },
        delay: 800,     
      })
      runAISpringApi.start({      
        to: { top: '0%', opacity: 1 },
        delay: 1600,        
      })
      nextStepSpringApi.start({    
        to: { top: '0%', opacity: 1 },
        delay: 2400,              
      })
    }
    else if (!props.uploadedFile) {
      setShowGeneratingSkillsTitle(false);
      setShowFindSkillButton(true);

      neededSkillsTextSpringApi.start({
        from: { opacity: 0 }, to: { opacity: 1 }
      }),
         findSkillSpringApi.start({
           to: { opacity: 1 }
         }),
         runAISpringApi.start({
           to: { top: '0%', opacity: 1 },
           delay: 1600,
         })
      nextStepSpringApi.start({
        to: { top: '0%', opacity: 1 },
        delay: 2400,
      })

    }
  }, [onboardingManagerStore.extractedNeededSkillsData])

  useEffect(() => {
    getCompanyEmployees(userData.accessToken);

    // let pusher = new Pusher('44ba88727279103cb61c', {
    //   cluster: 'us2'
    // });

    // const channel = pusher.subscribe('my-channel');
    authStore.channel.unbind(`skills_${props.isDraft ? props.projectId : onboardingManagerStore.projectData.project?.id?.toString()}`);
    authStore.channel.bind(`skills_${props.isDraft ? props.projectId : onboardingManagerStore.projectData.project?.id?.toString()}`, (data: any) => {
      if (data.message.indexOf('failed') > -1) {
        setShowGeneratingSkillsTitle(false);
        setShowFindSkillButton(true);
        setRunAi(false);
        neededSkillsTextSpringApi.start({
          from: { opacity: 0 }, to: { opacity: 1 }
        });
        findSkillSpringApi.start({
             to: { opacity: 1 }
        });
        showNotification('warning', 'Your file has failed processing. Please upload it again.');
      }
      else { 
        getExtractedNeededSkills(userData.accessToken);
      }
    }); 
    
    if (props.isDraft) { 
      getExtractedNeededSkills(userData.accessToken);
    }
  }, []);

  useEffect(()=>{
    if (projectDescriptionResponse !== '') {
      projectDescResSpringApi.start({
        from: {
          top: '90%', opacity: 0
        },
        to: {
          top: '10%', opacity: 1
        }
      })
    }
  }, [projectDescriptionResponse])

  useEffect(() => {
    if (props.clickedSaveAsDraft) {
      if (showFindSkillButton) {
        goToNextStep('project-skills-section', userData.accessToken);
      }
      else if (!runAi) {
        goToNextStep('search-skills-section', userData.accessToken);
      }
    }
  }, [props.clickedSaveAsDraft]);
 

  return (
    <section className={styles['needed-skills-section']}>
      <div className='needed-skills-wrapper'>
        <div className='info-wrapper'>        
          {/* Generating skills tag */}
          {showGeneratingSkillsTitle &&
            <animated.div className='generating-skills' style={{ ...generatingSkillsTextSpring }}>
              AI is generating needed skills
              <PulseLoader
                loading={true}
                color='#9A9DA7'
                size={7}
                speedMultiplier={.8}
                margin={8}
                style={{ width: 'auto', display: 'inline-flex', alignItems: 'flex-end' }}
              />
            </animated.div>
          }
          {/* Needed skills title */}
          {!showGeneratingSkillsTitle &&
            <animated.div style={{ ...neededSkillsTextSpring }}>
              {/* { onboardingManagerStore.extractedNeededSkillsData.length > 0 ?
                <div className='generating-skills'>Skills, needed for your project:</div> :
                <p className='mt-5'>AI can't find skills from your data. Please upload another file or write another project description, or just find the skills manually.</p>
              }             */}
              <div className='generating-skills'>
              { (onboardingManagerStore.extractedNeededSkillsData && onboardingManagerStore.extractedNeededSkillsData.length > 0) ? props.jobPosting ? 'Skills, needed for your task:' : 'Skills, needed for your project:' : 'No extracted skills'}
              </div>
            </animated.div>
          }

          {/* Skills tags */}      
          { onboardingManagerStore.extractedNeededSkillsData !== null &&
          <>
            <div className='skills-tag-wrapper'>
              { transitions((style: any, item: ExtractedNeededSkillData) => (                  
                  <animated.span style={style} key={item && item.skillAIId ? item.skillAIId : ''} id={item && item.skillAIId ? item.skillAIId : ''}  
                    onClick={()=> { toggleSkillSelect(item) }}
                    className={`skill-tag ${checkSelection(item.skillAIId ? item.skillAIId : '')}`}>
                    {item && item.skillName && capitalizeFirstLetter(item.skillName)}
                    { item.skillAIId && item.skillDescription &&
                      <span>
                        <Tooltip
                          place="top"                                         
                          tooltipId={item.skillAIId.toString()}  
                          backgroundColor="#101319"
                          borderColor='#5C5F6A !important'
                          border={true}            
                          content={
                            <span>{item.skillDescription}</span>                                 
                          }
                          className='tag-skill-description'
                        >
                          <span className='select-skill-description-symbol'><i className="fa-regular fa-circle-question"></i></span>
                        </Tooltip>  
                      </span>
                    }

                  </animated.span>            
                ))
              }         
            </div>  
            {/* Find a skill button */}      
            { showFindSkillButton &&
              <animated.div style={{...findSkillSpring}} key={'find-skill'} className='find-skill-wrapper'>
                <span className={`find-skill-button ${showGeneratingSkillsTitle? 'disabled-span-add-skill' : ''}`} onClick={()=>goToNextStep('search-skills-section', userData.accessToken)}>
                  <i className="fa-solid fa-plus"></i> Add a skill
                </span>
              </animated.div>
            }
            </>
          }                   
          { showFindSkillButton &&
            <>
              {
                 onboardingManagerStore.extractedNeededSkillsData && onboardingManagerStore.extractedNeededSkillsData.length > 0 &&
                <animated.span className={'q-field'} key={'delete-skill'} style={{ ...deleteSkillSpring }}>
                  To delete the skill click on it.
                </animated.span>
              }
            </>
          }   
          {/* { !showFindSkillButton &&
            <animated.span className={'q-field'} key={'run-ai'} style={{ ...runAISpring }}>
              To run the AI again, send a new project description.
            </animated.span>
          } */}
      
          { projectDescriptionResponse !== '' && showGeneratingSkillsTitle &&
            <animated.span className={'a-field'} style={{...projectDescResSpring}}>
              {projectDescriptionResponse}
            </animated.span> 
          } 

          {/* { showFindSkillButton &&
            <animated.div style={{ ...nextStepSpring }} key='next-step'>
              <button className={'btn btn-sm btn-blue-custom next-step-button'} onClick={()=>goToNextStep('skills-weight-section', userData.accessToken)}>
                Next step <i className="fa-solid fa-arrow-right-long"></i>
              </button>
            </animated.div>
          }           */}

          { onboardingManagerStore.csvFileDownloadURL &&
             <animated.span className={'q-field'} style={{...downloadText}}>
               To download skills as .csv file click on the button below
             </animated.span>
          }

          {
            onboardingManagerStore.csvFileDownloadURL &&
            <animated.div className={'u-margin-top-s'} style={{...downloadButton}} key={'download-button'}>
              <a href={onboardingManagerStore.csvFileDownloadURL} className={'button button-secondary'}
                 download>Download .csv file</a>
            </animated.div>
          }
        </div>
        {/* Description text area and upload document */}
        { projectDescriptionResponse === '' && runAi &&
          <div className='add-description-wrapper'>
            <div className='textarea-wrapper'>
              <textarea autoComplete='off' placeholder={`Describe your ${props.jobPosting ? "task" : "project"} in other words`} value={projectDescriptionInput} onKeyDown={checkKeyEnterDesc} onChange={insertProjectDescHandler} />
                <div className='button-wrapper'>
                  {
                    projectDescriptionInput !== '' && projectDescriptionInput.length > 1 &&              
                      <SpinnerButton dataLoading={dataIsLoadingDesc} title={'Send'} cssClasses='button  button-primary u-margin-bottom-xxs' onClickCallback={()=> saveProjectDescription(userData.accessToken)}></SpinnerButton>
                  }
                </div>
            </div>
            <FileUploader name="fileHiddenInput" classes={"file-uploader"} children={
              <button className={'button button-tertiary u-margin-left-xs'}>upload a file</button>
            }  handleChange={(file: any)=> browseFile(file, userData.accessToken)} /> 
            <div style={{position: 'relative' }} className='u-margin-left-xs'  key='cancel-button'>
              <span className={'next-step-button cancel-button'} onClick={()=>setRunAi(false)}>Cancel</span>
            </div>
          </div>
        }
        { !runAi &&
          <animated.div className={'rin-ai-wrapper mt-4'}>
            <div style={{position: 'relative' }} key='run-ai-again'>
              <span className={`next-step-button ai-button ${showGeneratingSkillsTitle ? 'disabled-span-ai' : ''}`} onClick={()=> {
                if(showGeneratingSkillsTitle) {
                  return;
                }
                setProjectDescriptionResponse(''); setRunAi(true)}}>Run AI again</span>
            </div>
            <span className='ms-3 me-3'>or</span>
            <div style={{position: 'relative' }} key='next-step'>
              <span className={`next-step-button cancel-button ${showGeneratingSkillsTitle ? 'disabled-span-next' : ''}`} onClick={()=>goToNextStep(props.jobPosting ? 'finish-onboarding-section' : 'project-employees-section', userData.accessToken)}>Move to the next step <i className="fa-solid fa-arrow-right"></i></span>
            </div>
          </animated.div>
        }
      </div>
    </section>
  );

  function getCompanyEmployees(accessToken: string) {
    onboardingManagerStore.getCompanyEmployees(props.isDraft ? props.projectId : onboardingManagerStore.projectData.project?.id, accessToken)
      .then(()=> {
        return
      })
      .catch((error$: ErrorResponseDto)=> {
        if (error$.response.data.message === 'Unauthorized') {
          // Get new Access Token
          apiService.refreshToken(userData.refreshToken)
            .then((response$: any) => {
              authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
              getCompanyEmployees(response$.data.accessToken);
            })
        }
        else {
          showNotification('warning', error$.response.data.message);
        }
      })
  }

  function getExtractedNeededSkills(accessToken: string) { 
    onboardingManagerStore.getExtractedNeededSkillsAI(props.isDraft ? props.projectId : onboardingManagerStore.projectData.project?.id, accessToken)
      .then((response$: any) => {
        setShowGeneratingSkillsTitle(false);
        setShowFindSkillButton(true);
        setRunAi(false);
        
        neededSkillsTextSpringApi.start({
          from: { opacity: 0 }, to: { opacity: 1 }
        }),
        findSkillSpringApi.start({             
          to: { opacity: 1 }       
        })  
        deleteSkillSpringApi.start({  
          to: { top: '0%', opacity: 1 },
          delay: 800,     
        })
        runAISpringApi.start({      
          to: { top: '0%', opacity: 1 },
          delay: 1600,        
        })
        nextStepSpringApi.start({    
          to: { top: '0%', opacity: 1 },
          delay: 2400,              
        })
      })
      .catch((error$: ErrorResponseDto) => {
        if (error$.response.data.message === 'Unauthorized') {
          // Get new Access Token
          apiService.refreshToken(userData.refreshToken)
            .then((response$: any) => {
              authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
              getExtractedNeededSkills(response$.data.accessToken);
            })
        }  
        else {
          showNotification('warning', error$.response.data.message);
        }        
      });
  }

  function browseFile (file: any, accessToken: string) {
    setShowGeneratingSkillsTitle(true);
    let hiddenFileInput = document.getElementsByName('fileHiddenInput')[0] as HTMLInputElement;
    
    if (hiddenFileInput) {
      hiddenFileInput.value = "";
    }

    setProjectDescriptionInput('');
    setRunAi(false);
    onboardingManagerStore.uploadNeededSkillsFileHandler(file, props.isDraft ? props.projectId : onboardingManagerStore.projectData.project?.id, accessToken)
      .then(()=> {
        return
      })
      .catch((error$: ErrorResponseDto)=> {
        if (error$.response.data.message === 'Unauthorized') {
          // Get new Access Token
          apiService.refreshToken(userData.refreshToken)
            .then((response$: any) => {
              authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
              browseFile(file, response$.data.accessToken);
            })
        }
        else {
          setShowGeneratingSkillsTitle(false);
          showNotification('warning', error$.response.data.message);
        }       
      });
  }

  function insertProjectDescHandler(e: any) {
    e.target.style.height = 'auto';
    e.target.style.height = (e.target.scrollHeight) + 'px';    
    setProjectDescriptionInput(e.target.value);
  }

  function saveProjectDescription(accessToken: string) {
    setProjectDescriptionResponse(projectDescriptionInput);    
    // setTimeout(()=> {
      setShowGeneratingSkillsTitle(true); 
      // setProjectDescriptionResponse('');
      setDataIsLoadingDesc(true);
      onboardingManagerStore.sendTextNeededSkills(projectDescriptionInput, props.isDraft ? props.projectId : onboardingManagerStore.projectData.project?.id, accessToken)    
      .then((response$: any)=> {
        setShowGeneratingSkillsTitle(false);   
        setProjectDescriptionInput('');
        setRunAi(false);
        setDataIsLoadingDesc(false);
             
      })
      .catch((error$: ErrorResponseDto)=> {
        if (error$.response.data.message === 'Unauthorized') {
          // Get new Access Token
          apiService.refreshToken(userData.refreshToken)
            .then((response$: any) => {
              authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
              saveProjectDescription(response$.data.accessToken);
            })
        }
        else {
          setDataIsLoadingDesc(false);
          setProjectDescriptionInput('');
          setShowGeneratingSkillsTitle(false);
          setRunAi(false); 
          setProjectDescriptionResponse('');  
          showNotification('warning', error$.response.data.message);
        }        
      });   
    // }, 1000)
  }

  function checkSelection(id: string) {    
    let skill: any = onboardingManagerStore.neededSkillsTempData.find((item: any) => item.skillAIId === id);
    return skill.deleted  ? 'removed' : '';
  }

  function toggleSkillSelect (skill: ExtractedNeededSkillData) {
    let skillElement = document.getElementById(skill.skillAIId as string) as HTMLSpanElement;
    if (skillElement) {
      skillElement.classList.toggle('removed');
    }
    onboardingManagerStore.toggleNeededSkillsTempData(skill.skillAIId as string);
  }

  function goToNextStep(section: string, accessToken: string): void {
    if (showGeneratingSkillsTitle) {
      return;
    }
    let neededSkils: any = [];
    let projectId = props.isDraft ? props.projectId : onboardingManagerStore.projectData.project?.id;

    for (let skill of onboardingManagerStore.neededSkillsTempData as ExtractedNeededSkillData[]) {
      if (!skill.deleted) {
        neededSkils.push(skill.skillProjectDataId);
      }      
    }

    onboardingManagerStore.selectExtractedNeededSkill(neededSkils, projectId, accessToken)
      .then(() => {    
        props.saveProgress(projectId, section, accessToken);
        props.changeSection(section);
        if (props.clickedSaveAsDraft) {
          props.saved();
        }
      })
      .catch((error$: ErrorResponseDto)=> {
        if (error$.response.data.message === 'Unauthorized') {
          // Get new Access Token
          apiService.refreshToken(userData.refreshToken)
            .then((response$: any) => {
              authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
              goToNextStep(section, response$.data.accessToken);
            })
        }
        else {
          showNotification('warning', error$.response.data.message);
        }
      })
  }

  function checkKeyEnterDesc (e: any) {
    if (e.key === 'Enter' && !dataIsLoadingDesc) {
      saveProjectDescription(userData.accessToken);
    }
  }

}

export default ProjectNeededSkillComponent;