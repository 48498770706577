export function getFormattedTime(time: string) {
    let date = new Date(time);

    let hours = date.getHours().toString();
    if (hours.length === 1) {
        hours = '0' + hours;
    }

    let minutes = date.getMinutes().toString();
    if (minutes.length === 1) {
        minutes = '0' + minutes;
    }

    let day = date.getDate();

    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let month = months[date.getMonth()];

    let year = date.getFullYear();

    return `${day} ${month} ${year}, ${hours}:${minutes}`
}