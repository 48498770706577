import { useState } from 'react';
import './FullPageLoadingSpinner.scss';
import ReactDOM from 'react-dom';

const useLoadingSpinner = () => {

	const [showSpinner, setShowSpinner] = useState(false);

		const spinner = (
			<div className='loading-spinner'>
				<img src='/assets/images/gifs/teamlift-loading-spinner.gif' alt={'Teamlift loading gif'} className={'loading-gif'}/>
				<h2 className='header-2 loading-header'>Loading</h2>
			</div>
		)

		return {
			spinner: showSpinner ? ReactDOM.createPortal(spinner, document.getElementById('modal-root') as HTMLDivElement) : null,
			createSpinner: () => { setShowSpinner(true) },
			removeSpinner: () => { setShowSpinner(false) }
		}
}

export default useLoadingSpinner;