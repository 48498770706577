import styles from './NotificationItem.module.scss';
import {NotificationCenterItem} from "react-toastify/addons/use-notification-center";
import {aiTool, Notification, NotificationCategory, NotificationParams} from "../../../../../types/Notification";

const NotificationItem : React.FC<{notification: NotificationCenterItem<Notification>, markAsReadNotification: Function, removeNotification: Function}> = (props) => {

    const notificationData: Notification = props.notification.data!;

    const date = new Date((notificationData.dateCreated));
    const hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours().toString();
    const minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes().toString();
    const dateString = hours + ":" + minutes;

    return (
        <>
            <div className={styles['notification-item']} onClick={clickedOnBody}>
                <div className={'notification-card'}>
                    <div className={'icon-container'}>
                        <img src={getIcon(notificationData.category)} alt="icon"/>
                    </div>
                    <div className={'body'}>
                        <div className={'header-wrapper'}>
                            <div className={'title'}>{notificationData.title}</div>
                            {(!props.notification.read && !notificationData.read) &&  <div onClick={clickedOnBadge} className={'new-icon'}><i className="fa-solid fa-circle"></i></div>}
                        </div>
                        <div className={'content'}>{notificationData.description}</div>
                        {
                            notificationData.category === NotificationCategory.LEARNING_NOTIFICATION && getLearningItems().skills.length > 0 &&
                            <div className={'learning-content'}>
                                <div className="learning-skills">
                                    <div className={'skills-content-title'}>Skills to learn:</div>
                                    <div className={'skills-content'}>
                                        {
                                            getLearningItems().skills.slice(0, 3).map((skill, index) => {
                                            return (
                                               <span key={`${skill.id}_${index}`} className={'skill'}>{skill.name}</span>
                                            );
                                        })}
                                        {
                                           getLearningItems().skills.length > 3 && <span className={'more-skills'}>and {getLearningItems().skills.length - 3} more</span>
                                        }
                                    </div>
                                </div>
                                {
                                    getLearningItems().aiTools.length > 0 &&
                                    <div className="learning-aiTools">
                                        <div className="aiTools-content-title">Ai tools to learn:</div>
                                        <div className="aiTools-content">
                                            {
                                                getLearningItems().aiTools.slice(0,3).map((aiTool, index) => {
                                                    return (
                                                      <span key={`${aiTool.uid}_${index}`} className="aiTool">{aiTool.name}</span>
                                                    );
                                                })
                                            }
                                            {
                                                getLearningItems().aiTools.length > 3 && <span className={'more-aiTools'}>and {getLearningItems().aiTools.length - 3} more</span>
                                            }
                                        </div>
                                    </div>
                                }
                                {
                                    getLearningItems().courses.length > 0 &&
                                    <div className={'learning-courses'}>
                                        <div className="courses-content-title">Courses to learn:</div>
                                        <div className="courses-content">
                                            {
                                                getLearningItems().courses.slice(0,3).map((course, index) => {
                                                    return (
                                                       <span key={`${course.uid}_${index}`} className="course">{course.name}</span>
                                                    );
                                                })
                                            }
                                            {
                                               getLearningItems().aiTools.length > 3 && <span className={'more-courses'}>and {getLearningItems().courses.length - 3} more</span>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                        <div className={'footer'}>
                            <div className={'time'}>{dateString}</div>
                            <div onClick={clickedOnTrashcan} className={'trashcan-button'}><i className="fa-solid fa-trash-can"></i></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )


    function getLearningItems() {
        if (notificationData.category === NotificationCategory.LEARNING_NOTIFICATION) {
            let params: NotificationParams[] = notificationData.params;
            let skills = params.map(param => param.skill);
            let aiTools = Array.from(new Set<aiTool>(params.flatMap(param => param.aiTools)));
            let courses = Array.from(new Set<aiTool>(params.flatMap(param => param.courses)));
            return {skills: skills, aiTools: aiTools, courses: courses};
        }
        return {skills: [], aiTools: [], courses: []};
    }
    function getIcon(category: NotificationCategory): string {
        switch (category) {
            case NotificationCategory.WORKING_NOTIFICATION:
                return '/assets/images/notification-icons/working-on-project.svg';
            case NotificationCategory.LEARNING_NOTIFICATION:
                return '/assets/images/notification-icons/learning-on-project.svg';
            case NotificationCategory.REMOVED_NOTIFICATION:
                return '/assets/images/notification-icons/removed-from-project.svg';
            case NotificationCategory.ARCHIVED_PROJECT_NOTIFICATION:
                return '/assets/images/notification-icons/completed-or-archived.svg';
            default:
                return '/assets/images/notification-icons/lightning.svg';
        }
    }
    function clickedOnBadge(e : any) {
        e.stopPropagation();
        props.markAsReadNotification(props.notification.id, props.notification.data!.id, 0);
    }

    function clickedOnBody(e: any) {
        e.stopPropagation();

        if (notificationData.category === NotificationCategory.REMOVED_NOTIFICATION) {
            props.markAsReadNotification(props.notification.id, props.notification.data!.id, 0);
        }
        else if (notificationData.category === NotificationCategory.ARCHIVED_PROJECT_NOTIFICATION) {
            props.markAsReadNotification(props.notification.id, props.notification.data!.id, 2);
        }
        else if (notificationData.category === NotificationCategory.WORKING_NOTIFICATION || notificationData.category === NotificationCategory.LEARNING_NOTIFICATION) {
            props.markAsReadNotification(props.notification.id, props.notification.data!.id, 1);
        }
        else {
            props.markAsReadNotification(props.notification.id, props.notification.data!.id, 1, props.notification.data!.projectId);
        }
    }

    function clickedOnTrashcan(e: any) {
        e.stopPropagation();
        props.removeNotification(props.notification.id, props.notification.data!.id);
    }

}
export default NotificationItem;