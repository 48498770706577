export enum SKILL_STATUS {
  NEW = 'NEW',
  APPROVED = 'APPROVED',
  LEARNING = 'LEARNING',
  REJECTED = 'REJECTED'
}

export enum SKILLS_SORT_TYPE {
  NEWEST = 'newest',
  OLDEST = 'oldest',
  LEVEL = 'level',
  TITLE = 'title'
} 