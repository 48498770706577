import styles from './SkillsComponent.module.scss';
import {Avatar} from "@mui/material";
import Tooltip from "../../../../../../../../services/tooltip.service";
import {buildStyles, CircularProgressbar, CircularProgressbarWithChildren} from "react-circular-progressbar";
import React, {useEffect, useState} from "react";
import {capitalizeFirstLetter} from "../../../../../../../../utils/capitalize-first-letter";
import RadialSeparators from "../../../../../../../../utils/RadialSeparators";
import {
   EmployeeInfoModal,
   EmployeeModalData,
   NeededEmployee,
   NeededSkill,
   ProjectEmployee
} from "../../../../../../../../types/NeededPeopleData";
import useModal from "../../../../../../../../services/modal.service";
import SkillEmployeesModal from "./SkillEmployeesModal/SkillEmployeesModal";

const SkillsComponent: React.FC<{
   skills: NeededSkill[],
   selectedTeam: { team: boolean, employees: any },
   selectSkill: Function,
   selectedSkillEmployees: any,
   // similarPeople: any,
   // similarFlag?: boolean,
}> = (props) => {

   const [selectedSkill, setSelectedSkill] = useState<NeededSkill | null>(null);
   const [filterState, setFilterState] = useState(0); // all - 0,unassigned - 1, assigned - 2, 3 - missing
   const [sortState, setSortState] = useState(true); // A-Z - true , Z-A - false
   const unassignedSkills = props.skills.filter(s => s.people.length === 0);
   const assignedSkills = props.skills.filter(s => s.people.length > 0);
   const missingSkills = props.skills.filter(s => s.missing);
   const skills = filterState === 0 ? props.skills : filterState === 1 ? unassignedSkills : filterState === 2 ? assignedSkills : missingSkills;
   const sortedSkills = skills.sort((s1, s2) => s1.skillName.localeCompare(s2.skillName));
   let finalSkillsList = sortState ? sortedSkills : sortedSkills.reverse();

   const filterEmployeeBySkill = (skill: NeededSkill, employee: NeededEmployee | ProjectEmployee) => {
      if ('coveredSkills' in employee) { // NeededEmployee
         return employee.coveredSkills.find(cs => cs.skill_id === skill.skillId);
      } else if ('skills' in employee) {
         return employee.skills.find(s => s.skill.id === skill.skillId) || employee.skillsToLearn.find(s => s.skill.id === skill.skillId);
      }
      return false;
   }

   if (props.selectedTeam.employees && props.selectedTeam.employees.length > 0) {
      finalSkillsList = new Array(...finalSkillsList.sort((s1, s2) => {
         const employeeHasSkill1 =
            props.selectedTeam.employees.filter((emp: NeededEmployee | ProjectEmployee) => filterEmployeeBySkill(s1, emp)).length;

         const employeeHasSkill2 =
            props.selectedTeam.employees.filter((emp: NeededEmployee | ProjectEmployee) => filterEmployeeBySkill(s2, emp)).length;

         return employeeHasSkill1 > employeeHasSkill2 ? -1 : employeeHasSkill1 < employeeHasSkill2 ? 1 : 0;
      }));
   }

   let skillEmployeeModal = useModal();

   useEffect(() => {
      if (props.selectedTeam.employees && props.selectedTeam.employees.length > 0) {
         setSelectedSkill(null);
         props.selectSkill(null);
      }
   }, [props.selectedTeam]);

   return (
      <>
         <div className={styles['skills-component']}>
            <div className="row">
               <div className="col-12">
                  <div className="needed-skills-wrapper">
                     <h2 className='needed-people-title'>Needed skills</h2>
                     <div className={'needed-skills-description'}>All skills which you selected as needed for your
                        project.
                     </div>
                     <div className={'line-info'}>
                        <span className={'first-hr'}></span><span>Know</span>
                        <span className={'second-hr'}></span><span>Can learn</span>
                        <span className={'third-hr'}></span><span>Unique owner</span>
                     </div>
                     <div className={'filters'}>
                        <div className="filter-component">
                           <div className={`${filterState === 0 ? 'selected' : ''}`}
                                onClick={() => changeFilters(0)}>All {props.skills.length}</div>
                           <div className={`${filterState === 1 ? 'selected' : ''}`}
                                onClick={() => changeFilters(1)}>Unassigned {unassignedSkills.length}</div>
                           <div className={`${filterState === 2 ? 'selected' : ''}`}
                                onClick={() => changeFilters(2)}>Assigned {assignedSkills.length}</div>
                           <div className={`${filterState === 3 ? 'selected' : ''}`}
                                onClick={() => changeFilters(3)}>Missing {missingSkills.length}</div>
                        </div>
                        <span onClick={changeSortState} className={`sort ${sortState ? '' : 'rotating'}`}>A-Z <i
                           className="fa-solid fa-chevron-down"></i></span>
                     </div>
                     <div className={'needed-skills-body'}>
                        { finalSkillsList.length > 0 && finalSkillsList.map((skill: NeededSkill) => {
                           return (
                              <div key={skill.neededSkillId}
                                   className={`skill-wrapper ${checkSelectedSkill(skill)} ${skillOpacity(skill)}`}
                                   onClick={(e: any) => clickSkillHandler(e, skill)}>
                                 <div className={'skill-title'}>{capitalizeFirstLetter(skill.skillName)}</div>
                                 <div className={'skill-people'}>
                                    {
                                       checkEmployeeSelected(skill).length + checkSelectedSkillEmployee(skill).length + skill.people.length - 2 > 0 && // checkSimilarPeopleSelected(skill).length
                                       <Avatar onClick={(event : any) => showEmployeesModal(event, skill)} className={`plus-employees ${checkPlusEmployeeSelection(skill)}`}>+{checkEmployeeSelected(skill).length + checkSelectedSkillEmployee(skill).length + skill.people.length - 2}</Avatar> // + checkSimilarPeopleSelected(skill).length
                                    }
                                    { // lista od people od skill mapirani
                                       skill.people.slice(0, 2).map((employee: any, index: number) => {
                                          return (
                                             employee.status === 'NEW' || employee.status === "APPROVED" ?
                                                <div key={index}
                                                     className={`employee-image-container ${checkOpacity(employee)}`}>
                                                   <CircularProgressbar
                                                      value={+employee.totalValidationScore}
                                                      strokeWidth={8}
                                                      styles={buildStyles({
                                                         pathColor: employee.unique_owner ? '#F3B24D' : '#A0A8E5',
                                                         trailColor: '#191D24',
                                                         strokeLinecap: "butt"
                                                      })}/>
                                                   <div
                                                      className={'image-container'}>
                                                      {employee.signedUserProfilePicture ?
                                                         <img src={employee.signedUserProfilePicture}
                                                              alt="picture"/> :
                                                         <Avatar key={index} sx={{backgroundColor: employee.color}}
                                                                 className={'avatar'}>{employee.employeeName.slice(0, 1).toUpperCase()}</Avatar>}
                                                   </div>
                                                </div> :
                                                <div key={index}
                                                     className={`employee-image-container ${checkOpacity(employee)}`}>
                                                   <CircularProgressbarWithChildren
                                                      value={100}
                                                      strokeWidth={8}
                                                      styles={buildStyles({
                                                         pathColor: '#191D24',
                                                         trailColor: '#191D24',
                                                         strokeLinecap: "butt"
                                                      })}>
                                                      <RadialSeparators
                                                         count={12}
                                                         style={{
                                                            background: "#fff",
                                                            width: "5px",
                                                            // This needs to be equal to props.strokeWidth
                                                            height: `${9}%`
                                                         }}
                                                      />
                                                   </CircularProgressbarWithChildren>
                                                   <div
                                                      className={'image-container'}>
                                                      {employee.signedUserProfilePicture ?
                                                         <img src={employee.signedUserProfilePicture}
                                                              alt="picture"/> :
                                                         <Avatar key={index} sx={{backgroundColor: employee.color}}
                                                                 className={'avatar'}>{employee.employeeName.slice(0, 1).toUpperCase()}</Avatar>}
                                                   </div>
                                                </div>
                                          )
                                       })
                                    }
                                    { // selektirano employee od knowers ili learners
                                       skill.people.length < 2 &&
                                       checkEmployeeSelected(skill).slice(0, 2 - skill.people.length).map((employee: any, index: number) => {
                                          return (
                                             employee.type === 'CANDIDATE' ?
                                                <div key={index}
                                                     className={`employee-image-container selection employee-selected`}>
                                                   <CircularProgressbar
                                                      value={findValidationScore(employee, skill)}
                                                      strokeWidth={8}
                                                      styles={buildStyles({
                                                         pathColor: employee.coveredSkills.filter((cs: any) => cs.skill_id === skill.skillId)[0].unique_owner ? '#F3B24D' : '#A0A8E5',
                                                         trailColor: '#191D24',
                                                         strokeLinecap: "butt"
                                                      })}/>
                                                   <div
                                                      className={'image-container'}>
                                                      {employee.signedUserProfilePicture ?
                                                         <img src={employee.signedUserProfilePicture} alt="picture"/> :
                                                         <Avatar key={index} sx={{backgroundColor: employee.color}}
                                                                 className={'avatar'}>{employee.employeeName.slice(0, 1).toUpperCase()}</Avatar>}
                                                   </div>
                                                </div> :
                                                <div key={index}
                                                     className={`employee-image-container selection employee-selected`}>
                                                   <CircularProgressbarWithChildren
                                                      value={100}
                                                      strokeWidth={8}
                                                      styles={buildStyles({
                                                         pathColor: '#191D24',
                                                         trailColor: '#191D24',
                                                         strokeLinecap: "butt"
                                                      })}>
                                                      <RadialSeparators
                                                         count={12}
                                                         style={{
                                                            background: "#fff",
                                                            width: "5px",
                                                            // This needs to be equal to props.strokeWidth
                                                            height: `${9}%`
                                                         }}
                                                      />
                                                   </CircularProgressbarWithChildren>
                                                   <div
                                                      className={'image-container'}>
                                                      {employee.signedUserProfilePicture ?
                                                         <img src={employee.signedUserProfilePicture} alt="picture"/> :
                                                         <Avatar key={index} sx={{backgroundColor: employee.color}}
                                                                 className={'avatar'}>{employee.employeeName.slice(0, 1).toUpperCase()}</Avatar>}
                                                   </div>
                                                </div>
                                          )
                                       })
                                    }
                                    { // selektiran skill od needed skills
                                       skill.people.length < 2 &&
                                       checkSelectedSkillEmployee(skill).slice(0, 2 - skill.people.length).map((employee: any, index: number) => {
                                          return (
                                             employee.type === 'CANDIDATE' ?
                                                <div key={index}
                                                     className={`employee-image-container selection skill-selected`}>
                                                   <CircularProgressbar
                                                      value={findValidationScore(employee, skill)}
                                                      strokeWidth={8}
                                                      styles={buildStyles({
                                                         pathColor: employee.coveredSkills.filter((cs: any) => cs.skill_id === skill.skillId)[0].unique_owner ? '#F3B24D' : '#A0A8E5',
                                                         trailColor: '#191D24',
                                                         strokeLinecap: "butt"
                                                      })}/>
                                                   <div
                                                      className={'image-container'}>
                                                      {employee.signedUserProfilePicture ?
                                                         <img src={employee.signedUserProfilePicture} alt="picture"/> :
                                                         <Avatar key={index} sx={{backgroundColor: employee.color}}
                                                                 className={'avatar'}>{employee.employeeName.slice(0, 1).toUpperCase()}</Avatar>}
                                                   </div>
                                                </div> :
                                                <div key={index}
                                                     className={`employee-image-container selection skill-selected`}>
                                                   <CircularProgressbarWithChildren
                                                      value={100}
                                                      strokeWidth={8}
                                                      styles={buildStyles({
                                                         pathColor: '#191D24',
                                                         trailColor: '#191D24',
                                                         strokeLinecap: "butt"
                                                      })}>
                                                      <RadialSeparators
                                                         count={12}
                                                         style={{
                                                            background: "#fff",
                                                            width: "5px",
                                                            // This needs to be equal to props.strokeWidth
                                                            height: `${9}%`
                                                         }}
                                                      />
                                                   </CircularProgressbarWithChildren>
                                                   <div
                                                      className={'image-container'}>
                                                      {employee.signedUserProfilePicture ?
                                                         <img src={employee.signedUserProfilePicture} alt="picture"/> :
                                                         <Avatar key={index} sx={{backgroundColor: employee.color}}
                                                                 className={'avatar'}>{employee.employeeName.slice(0, 1).toUpperCase()}</Avatar>}
                                                   </div>
                                                </div>
                                          )
                                       })
                                    }
                                    {/*{ // similar people selektirani*/}
                                    {/*   skill.people.length < 2 &&*/}
                                    {/*   checkSimilarPeopleSelected(skill).slice(0, 2 - skill.people.length).map((employee: any, index: number) => {*/}
                                    {/*      return (*/}
                                    {/*         employee.type === 'SIMILAR_PEOPLE' ?*/}
                                    {/*            <div key={index}*/}
                                    {/*                 className={`employee-image-container selection ${checkOpacity(employee)}`}>*/}
                                    {/*               <CircularProgressbar*/}
                                    {/*                  value={findValidationScore(employee, skill)}*/}
                                    {/*                  strokeWidth={8}*/}
                                    {/*                  styles={buildStyles({*/}
                                    {/*                     pathColor: '#A0A8E5',*/}
                                    {/*                     trailColor: '#191D24',*/}
                                    {/*                     strokeLinecap: "butt"*/}
                                    {/*                  })}/>*/}
                                    {/*               <div*/}
                                    {/*                  className={'image-container'}>*/}
                                    {/*                  {employee.signedUserProfilePicture ?*/}
                                    {/*                     <img src={employee.signedUserProfilePicture} alt="picture"/> :*/}
                                    {/*                     <Avatar key={index} sx={{backgroundColor: employee.color}}*/}
                                    {/*                             className={'avatar'}>{employee.employeeName.slice(0, 1).toUpperCase()}</Avatar>}*/}
                                    {/*               </div>*/}
                                    {/*            </div> :*/}
                                    {/*            <div key={index}*/}
                                    {/*                 className={`employee-image-container selection ${checkOpacity(employee)}`}>*/}
                                    {/*               <CircularProgressbarWithChildren*/}
                                    {/*                  value={100}*/}
                                    {/*                  strokeWidth={8}*/}
                                    {/*                  styles={buildStyles({*/}
                                    {/*                     pathColor: '#191D24',*/}
                                    {/*                     trailColor: '#191D24',*/}
                                    {/*                     strokeLinecap: "butt"*/}
                                    {/*                  })}>*/}
                                    {/*                  <RadialSeparators*/}
                                    {/*                     count={12}*/}
                                    {/*                     style={{*/}
                                    {/*                        background: "#fff",*/}
                                    {/*                        width: "5px",*/}
                                    {/*                        // This needs to be equal to props.strokeWidth*/}
                                    {/*                        height: `${9}%`*/}
                                    {/*                     }}*/}
                                    {/*                  />*/}
                                    {/*               </CircularProgressbarWithChildren>*/}
                                    {/*               <div*/}
                                    {/*                  className={'image-container'}>*/}
                                    {/*                  {employee.signedUserProfilePicture ?*/}
                                    {/*                     <img src={employee.signedUserProfilePicture} alt="picture"/> :*/}
                                    {/*                     <Avatar key={index} sx={{backgroundColor: employee.color}}*/}
                                    {/*                             className={'avatar'}>{employee.employeeName.slice(0, 1).toUpperCase()}</Avatar>}*/}
                                    {/*               </div>*/}
                                    {/*            </div>*/}
                                    {/*      )*/}
                                    {/*   })*/}
                                    {/*}*/}
                                 </div>
                                 {
                                    skill.missing &&
                                    <Tooltip place="right"
                                             tooltipId={"skill_" + skill.skillId}
                                             backgroundColor="#101319"
                                             border={true}
                                             borderColor={'#5C5F6A !important'}
                                             className='missing-skill-tooltip'
                                             content={
                                                <>
                                                   <span>No one from your company knows this skill.</span>
                                                </>
                                             }>
                                       <div className={'no-skill'}>
                                          <img src="/assets/images/global-icons/no-frame.svg" alt="no-frame"/>
                                       </div>
                                    </Tooltip>
                                 }
                              </div>
                           )
                        })}
                        {
                           finalSkillsList.length === 0 &&
                           <h2>No skills for this filter.</h2>
                        }
                     </div>
                  </div>
               </div>
            </div>
         </div>
         {
            skillEmployeeModal.showModal && <SkillEmployeesModal closeModal={closeModal}  data={skillEmployeeModal.modalData} />
         }
      </>
   );

   function closeModal() {
      skillEmployeeModal.removeModal();
   }

   function showEmployeesModal(event: any, skill: NeededSkill) {
      event.stopPropagation(); // stop from selecting skill
      let allEmployeesData: EmployeeInfoModal = { neededSkillEmployees: [], employeeSelectedList: [], selectedSkillEmployeeList: [] };

      allEmployeesData.neededSkillEmployees = skill.people; // people for needed skill from be

      allEmployeesData.employeeSelectedList = checkEmployeeSelected(skill) // select employee and it shows in the skill

      allEmployeesData.selectedSkillEmployeeList = checkSelectedSkillEmployee(skill); // select skill and it shows the employees for that skill

      const employeeData: EmployeeModalData = { skill, allEmployees: allEmployeesData}
      skillEmployeeModal.createModal(employeeData);
   }

   function findValidationScore(employee: any, skill: NeededSkill): number {
      return +employee.coveredSkills.find((cs: any) => cs.skill_id === skill.skillId).total_validation_score;
   }

   function checkPlusEmployeeSelection(skill: NeededSkill): string {
      if (getEmployeeSelectedListTooltip(skill).length > 0) {
         return 'selection';
      }
      return '';
   }

   function skillOpacity(skill: NeededSkill): string {
      // if (!props.similarFlag) {
      //    return '';
      // }

      let flag = false;
      // if (props.similarPeople && props.similarPeople.length > 0) {
      //    flag = props.similarPeople.find((e: any) => e.coveredSkills.find((s: any) => s.skill_id === skill.skillId));
      // }
      // if (flag) {
      //    return '';
      // }
      // ako se selektira nekoj od proektot togash se pokazuaa skills samo shto gi posedua toj ako ne togash ne se namaluva opacity
      if (!props.selectedTeam.employees || !props.selectedTeam.team || props.selectedTeam.employees.length === 0) {
         return '';
      }

      if (props.selectedTeam.team && props.selectedTeam.employees) {
         flag = props.selectedTeam.employees.find((e: ProjectEmployee) => e.skills.find(s => s.skill.id === skill.skillId));
      }
      if (flag) {
         return '';
      }
      if (props.selectedTeam.team && props.selectedTeam.employees) {
         flag = props.selectedTeam.employees.find((e: any) => e.skillsToLearn.find((s: any) => s.skill.id === skill.skillId));
      }
      if (flag) {
         return '';
      }
      return 'opacity';
   }

   // function checkSimilarPeopleSelected(skill: NeededSkill) {
   //    return props.similarPeople.filter((e: any) => e.coveredSkills.find((s: any) => s.skill_id === skill.skillId));
   // }

   function changeSortState() {
      setSortState((prevState) => {
         return !prevState;
      })
   }

   function checkEmployeeSelected(skill: NeededSkill) {
      if (props.selectedTeam.employees && !props.selectedTeam.team) {
         return props.selectedTeam.employees.filter((e: any) => e.coveredSkills.find((cs: any) => cs.skill_id === skill.skillId) && e.type !== 'SIMILAR_PEOPLE');
      }
      return [];
   }

   function getEmployeeSelectedListTooltip(skill: NeededSkill) {
      if (skill.people.length > 2) {
         return checkEmployeeSelected(skill);
      }
      return checkEmployeeSelected(skill).slice(2 - skill.people.length);
   }

   function checkSelectedSkillEmployee(skill: NeededSkill) {
      if (skill === selectedSkill) {
         return props.selectedSkillEmployees;
      }
      return [];
   }

   function getSelectedSkillEmployeeListTooltip(skill: NeededSkill) {
      if (skill.people.length > 2) {
         return checkSelectedSkillEmployee(skill);
      }
      return checkSelectedSkillEmployee(skill).slice(2 - skill.people.length);
   }

   function changeFilters(filterNum: number) {
      setFilterState(filterNum);
   }

   function clickSkillHandler(e: any, skill: NeededSkill) {
      // if ((props.similarPeople && props.similarPeople.length > 0) || props.similarFlag) {
      //    return;
      // }

      if (document.querySelector(".avatars-tooltip")) {
         if (Array.from(document.querySelector(".avatars-tooltip")!.querySelectorAll("*")).find(el => el === e.target) ||
            e.target == document.getElementsByClassName('avatars-tooltip')[0]) {
            return;
         }
      }

      if (selectedSkill) {
         if (selectedSkill.skillId === skill.skillId) {
            setSelectedSkill(null);
            props.selectSkill(null);
            return;
         }
      }
      setSelectedSkill(skill);
      props.selectSkill(skill);
   }

   function checkSelectedSkill(skill: NeededSkill): string {
      if (selectedSkill && selectedSkill.skillId === skill.skillId) {
         return 'selected';
      }
      return '';
   }

   function checkOpacity(employee: any): string {
      if (!props.selectedTeam.employees || !props.selectedTeam.team) {
         return '';
      }
      if (props.selectedTeam.employees.length === 0) {
         return '';
      }
      let flag = props.selectedTeam.employees.find((emp: any) => emp.projectEmployeeId === employee.projectEmployeeId);
      if (flag) {
         return '';
      }
      // if (props.similarPeople) {
      //    flag = props.similarPeople.find((e: any) => e.employeeId === employee.employeeId);
      // }
      if (flag) {
         return '';
      }
      return 'opacity';
   }
}
export default SkillsComponent;