import styles from './ProjectTeamComponent.module.scss';
import React, {useContext, useEffect, useState} from "react";
import AuthContext from "../../../../../../../store/auth-context";
import SkillsComponent from "./SkillsComponent/SkillsComponent";
import TeamComponent from "./TeamComponent/TeamComponent";
import OnboardingManagerContext from "../../../../../../../store/create-project-context";
import useLoadingSpinner from "../../../../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner";
import {showNotification} from "../../../../../../../ui/Toast/ToastNotification";
import useApiService from "../../../../../../../services/api.service";
import JoyrideTooltip from "../../../../../../../ui/JoyrideTooltip/JoyrideTooltip";
import ReactJoyride, {ACTIONS, CallBackProps, EVENTS, STATUS, Step} from "react-joyride";
import {
   EmployeeType,
   NeededEmployee,
   NeededPeopleData,
   NeededPeopleDto, NeededSkill, ProjectEmployee
} from "../../../../../../../types/NeededPeopleData";
import {ErrorResponseDto} from "../../../../../../../types/ErrorData";

const ProjectTeamComponent: React.FC<{
   projectId: number,
   clickedSaveAsDraft: boolean,
   saved: Function,
   saveProgress: Function,
   changeSection: Function,
   isOnboarding?: boolean
}> = (props) => {
   const authStore = useContext(AuthContext);
   const userData = authStore.userData;
   const onboardingManagerStore = useContext(OnboardingManagerContext);
   const spinnerService = useLoadingSpinner();
   const apiService = useApiService();

   const [neededPeopleData, setNeededPeopleData] = useState<NeededPeopleData>({
      employeesLists: { neededPeople: { topCandidates: [], potentialLearners: [] }, allEmployees: [] },
      team: { projectEmployees: [] },
      neededSkills: []
   });

   // const [similarPeople, setSimilarPeople] = useState<any>([]);
   // const [similarFlag, setSimilarFlag] = useState<boolean>();
   // const [similarMessage, setSimilarMessage] = useState('Select project employees.');

   const [selectedTeam, setSelectedTeam] = useState<any>({}); // selected employee
   const [selectedSkill, setSelectedSkill] = useState<any>(); // selected skill
   const [selectedSkillEmployees, setSelectedSkillEmployees] = useState<any>(); // when select// ed skill pass employee list from knowers/learners

   const projectId = props.projectId ? props.projectId : onboardingManagerStore.projectData.project!.id!;

   let steps: Step[] = [
      {
         title: 'It’s time to find the right people to join your team!',
         content: 'Create a team of people to assign to your project.',
         placement: 'center',
         target: 'body',
      },
      {
         placement: 'bottom',
         target: '.needed-skills-wrapper',
         title: 'The skills necessary to complete your project are organized in this list!',
         content: 'Next we’ll introduce people in your organization who possess those skills.',
      },
      {
         placement: 'right',
         target: '.candidates-container',
         title: 'Meet your top candidates!',
         content: 'TeamLift’s AI has identified individuals based on their knowledge of the skills you need.',
      },
      {
         placement: 'right',
         target: '.middle-part',
         title: 'Use this list to build your future team.',
         content: 'We’ll guide you through the process of adding members now!',
      },
      {
         placement: 'auto',
         target: '.teams',
         title: 'To add a team member to your project, simply drag and drop them into the ‘Your team’ list in the middle.',
         content: <div><img style={{width: '100%', height: 'auto'}}
                            src="/assets/images/gifs/needed-people-animation-tour-no-hours.gif" alt="gif"/></div>,
         spotlightClicks: true,
      },
      // {
      //    placement: 'left',
      //    target: '.lastChild',
      //    title: 'Add this person’s weekly hourly contribution to the project here.',
      //    content: 'Adding the maximum number of hours means this person is fully devoted to your project.',
      //    spotlightClicks: true
      // },

      {
         placement: 'right',
         target: (neededPeopleData.employeesLists.neededPeople.topCandidates.length > 0 ? '.learners-tab' : '.top-employees-tab'),
         title: 'Explore team members  who can learn the skills your project needs.',
         content: 'A helpful option when nobody in your company has needed skills.',
      },
      // {
      //    placement: 'bottom',
      //    target: '.similar-button',
      //    title: 'Find team members with similar skillsets.',
      //    content: 'Click here to make it happen.',
      // },
      {
         placement: 'right',
         target: '.all-employees-tab',
         title: 'This list contains all employees in your company.',
         content: 'It doesn\'t filter by people that possess the skills necessary to complete your project, in the event you need wider visibility.'
      },
      {
         placement: 'center',
         target: 'body',
         title: 'End of tour',
         content: 'That\'s the basics of this flow. If you want to see the tour again, click on me here.',
      }
   ];

   const [joyrideStep, setJoyrideState] = useState<{
      steps: Step[],
      stepIndex: number,
      run: boolean,
      inMiddle: boolean
   }>({
      steps: steps,
      stepIndex: 0,
      run: props.isOnboarding ? true : false,   // When is onboarding, run the steps component
      inMiddle: false,
   });

   useEffect(() => {
      initializeComponent(userData.accessToken);
   }, []);

   useEffect(() => {
      if (props.clickedSaveAsDraft) {
         props.saveProgress(projectId, 'project-employees-section', userData.accessToken);
         // Make redirection to /manager/projects
         props.saved();
      }
   }, [props.clickedSaveAsDraft]);

   // useEffect(() => {
   //    if (!similarFlag) {
   //       setSimilarMessage('Select project employees.');
   //       setSimilarPeople([]);
   //    }
   // }, [similarFlag]);
   return (
      <>
         {
            neededPeopleData &&
            <div className={styles['project-team-section']}>
               <div className="control-row">
                  <button onClick={goBack} className="button button-tertiary">
                     <span>
                          <i className="fa-solid fa-arrow-left"></i> Back
                     </span>
                  </button>

                  <button onClick={runStepsHandler} className="button button-secondary">
                     <span>
                        How do I use this?
                     </span>
                  </button>

               </div>
               <div className={'row'}>
                  <div className="col-12 col-xl-8">
                     <TeamComponent changeStep={changeStep} potentialLearners={neededPeopleData.employeesLists.neededPeople.potentialLearners}
                                    topCandidates={neededPeopleData.employeesLists.neededPeople.topCandidates} projectEmployees={neededPeopleData.team.projectEmployees}
                                    allEmployees={neededPeopleData.employeesLists.allEmployees}
                                    setSelectedTeamEmployees={setSelectedTeamEmployees}
                                    selectedSkill={selectedSkill} addProjectEmployee={addProjectEmployee}
                                    deleteProjectEmployees={deleteProjectEmployees}
                                    complete={completeTheTeam} inMiddle={joyrideStep.inMiddle}/>
                                    {/*similarPeople={similarPeople} similarMessage={similarMessage} setSimilarFlag={checkSimilarFlag} findSimilarPeople={findSimilarPeople} updateTeamEmployee={updateTeamEmployee} */}
                  </div>
                  <div className={'col-12 col-xl-4'}>
                     <SkillsComponent skills={neededPeopleData.neededSkills} selectedTeam={selectedTeam} selectSkill={selectSkill}
                                      selectedSkillEmployees={selectedSkillEmployees}/>
                     {/*similarFlag={similarFlag} similarPeople={similarPeople}*/}
                  </div>
               </div>

               <ReactJoyride
                  tooltipComponent={JoyrideTooltip}
                  steps={joyrideStep.steps}
                  stepIndex={joyrideStep.stepIndex}
                  callback={handleJoyrideCallback}
                  continuous
                  hideCloseButton
                  run={joyrideStep.run}
                  showProgress
                  showSkipButton
                  disableOverlayClose
                  styles={{
                     options: {
                        arrowColor: '#374ECBFF',
                        zIndex: 10000,
                     },
                  }}
               />
            </div>
         }
         {
            spinnerService.spinner
         }
      </>
   )

   function handleJoyrideCallback(data: CallBackProps) {
      const {action, index, status, type} = data;

      if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
         // Need to set our running state to false, so we can restart if we click start again.
         setJoyrideState({
               steps: steps,
               run: false,
               stepIndex: 0,
               inMiddle: false
            });
      } else if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)) {
         const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);

         // if (index === 5 && joyrideStep.inMiddle) {
         //    setJoyrideState(prevState => {
         //       return {
         //          ...prevState,
         //          inMiddle: false,
         //          stepIndex: nextStepIndex,
         //       }
         //    });
         // } else
         if (index === 4 && !joyrideStep.inMiddle && nextStepIndex > index) {
            setJoyrideState(prevState => {
               return {
                  ...prevState,
                  stepIndex: 5
               }
            });
         } else if (index === 5 && action === ACTIONS.PREV) {
            setJoyrideState(prevState => {
               return {
                  ...prevState,
                  stepIndex: 4,
               }
            });
         } else {
            // Update state to advance the tour
            setJoyrideState(prevState => {
               return {
                  ...prevState,
                  stepIndex: nextStepIndex,
               }
            });
         }
      }
   }

   function runStepsHandler() {
      setJoyrideState((prevState) => {
         return {
            ...prevState,
            run: true
         };
      });
   }

   function changeStep() {
      if (joyrideStep.run && joyrideStep.stepIndex === 4) {
         setJoyrideState(prevState => {
            return {
               ...prevState,
               stepIndex: prevState.stepIndex + 1,
               inMiddle: true
            }
         });
      }
   }

   function goBack() {
      props.changeSection('project-skills-section');
   }

   function completeTheTeam() {
      props.saveProgress(projectId, 'project-employees-section', userData.accessToken);
      props.changeSection('finish-onboarding-section');
   }

   // function checkSimilarFlag(value: boolean) {
   //    setSimilarFlag(value);
   // }

   function filterEmployees(currentState: NeededPeopleData, employee: NeededEmployee[]) {
      if (employee[0].type === EmployeeType.CANDIDATE) {
         currentState.employeesLists.allEmployees = currentState.employeesLists.allEmployees.filter(e => !employee.find(e1 => e.employeeId === e1.employeeId));
         currentState.employeesLists.neededPeople.topCandidates = currentState.employeesLists.neededPeople.topCandidates.filter(e => !employee.find(e1 => e.employeeId === e1.employeeId));
         return currentState;
         // if candidates are added filter the candidates from top knowers and all employees
      } else {
         currentState.employeesLists.neededPeople.potentialLearners = currentState.employeesLists.neededPeople.potentialLearners.filter(e => !employee.find(e1 => e.employeeId === e1.employeeId));
         return currentState;
         // if learners are added just filter from learners list
      }
   }

   function selectSkill(skill: any) {
      if (skill && neededPeopleData) {
         const employeesCandidates = neededPeopleData.employeesLists.neededPeople.topCandidates.filter(candidate => {
            return candidate.coveredSkills.find(object => object.skill_id === skill.skillId);
         })
         const employeeLearners = neededPeopleData.employeesLists.neededPeople.potentialLearners.filter(candidate => {
            return candidate.coveredSkills.find(object => object.skill_id === skill.skillId);
         })
         setSelectedSkillEmployees([...employeesCandidates, ...employeeLearners]);
      } else {
         setSelectedSkillEmployees([]);
      }
      setSelectedSkill(skill);
   }

   function setSelectedTeamEmployees(selection: any) {
      setSelectedTeam(selection);
   }

   function initializeComponent(accessToken: string) {
      spinnerService.createSpinner();

      onboardingManagerStore
         .neededPeople(projectId, accessToken)
         .then((response$: NeededPeopleData) => {
            setNeededPeopleData(response$);
            spinnerService.removeSpinner();
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === "Unauthorized") {
               // Get new Access Token
               apiService
                  .refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     initializeComponent(response$.data.accessToken);
                  });
            } else {
               spinnerService.removeSpinner();
               showNotification("warning", error$.response.data.message);
            }
         });
   }

   function addProjectEmployee(employee: NeededEmployee[], availability: number, accessToken: string) {
      spinnerService.createSpinner();

      onboardingManagerStore.addProjectEmployee(projectId, employee, availability, accessToken)
         .then((response$: NeededPeopleData) => {
            // new list for team and needed skills, employeesLists are undefined
            setNeededPeopleData(prevState => {
               const newState = filterEmployees(prevState, employee);
                  return {
                     ...newState,
                     team: response$.team,
                     neededSkills: response$.neededSkills
                  }
               });
            spinnerService.removeSpinner();

            if (joyrideStep.run) {
               setJoyrideState(prevState => {
                  return {
                     ...prevState,
                     stepIndex: 5,
                     inMiddle: false
                  };
               })
            }
         }).catch((error$: ErrorResponseDto) => {
         if (error$.response.data.message === 'Unauthorized') {
            // Get new Access Token
            apiService.refreshToken(authStore.userData.refreshToken)
               .then((response$: any) => {
                  authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                  addProjectEmployee(employee, availability, response$.data.accessToken);
               })
         } else {
            spinnerService.removeSpinner();
            showNotification('warning', error$.response.data.message);
         }
      })
   }

   function deleteProjectEmployees(employees: ProjectEmployee[], accessToken: string) {
      spinnerService.createSpinner();

      onboardingManagerStore.deleteTeamEmployees(projectId, employees, accessToken)
         .then((response$: NeededPeopleData) => {
            // updated list for team and neededSkills
            setNeededPeopleData(response$);
            spinnerService.removeSpinner();
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               apiService.refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     deleteProjectEmployees(employees, response$.data.accessToken);
                  })
            } else {
               spinnerService.removeSpinner();
               showNotification('warning', error$.response.data.message);
            }
         })
   }

   // function updateTeamEmployee(employee: {
   //    projectEmployeeId: number,
   //    availability: number,
   //    type: string
   // }, accessToken: string) {
   //    spinnerService.createSpinner();
   //
   //    onboardingManagerStore.updateTeamEmployee(projectId, employee, accessToken)
   //       .then((response$: any) => {
   //          setNeededPeopleData(response$);
   //          spinnerService.removeSpinner();
   //       })
   //       .catch((error$: any) => {
   //          if (error$.response.data.message === 'Unauthorized') {
   //             // Get new Access Token
   //             apiService.refreshToken(authStore.userData.refreshToken)
   //                .then((response$: any) => {
   //                   authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
   //                   updateTeamEmployee(employee, response$.data.accessToken);
   //                })
   //          } else {
   //             spinnerService.removeSpinner();
   //             showNotification('warning', error$.response.data.message);
   //          }
   //       })
   // }

   // function findSimilarPeople(projectEmployeeIds: any, accessToken: string) {
   //    // Check if there are knowers
   //    if (projectEmployeeIds.length === 0) {
   //       return;
   //    }
   //    spinnerService.createSpinner();
   //
   //    onboardingManagerStore.findSimilarPeople(projectId, projectEmployeeIds, accessToken)
   //       .then((response$: any) => {
   //          setSimilarPeople(response$);
   //          // setSimilarFlag(true); // CHECK
   //          if (response$.length === 0) {
   //             setSimilarMessage('No similar people found in the company.');
   //          }
   //          spinnerService.removeSpinner();
   //       })
   //       .catch((error$: any) => {
   //          if (error$.response.data.message === 'Unauthorized') {
   //             // Get new Access Token
   //             apiService.refreshToken(authStore.userData.refreshToken)
   //                .then((response$: any) => {
   //                   authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
   //                   findSimilarPeople(projectEmployeeIds, response$.data.accessToken);
   //                })
   //          } else {
   //             spinnerService.removeSpinner();
   //             showNotification('warning', error$.response.data.message);
   //          }
   //       })
   // }
}

export default ProjectTeamComponent;