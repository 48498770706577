import React, {useContext, useEffect, useState} from "react";
import useLoadingSpinner from "../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner";
import AuthContext from "../../../store/auth-context";
import useApiService from "../../../services/api.service";
import {
   CATEGORY_IMAGES,
   FileTypesAndDataDto,
   FileTypeValue,
   FrequencyTypeEnum,
   FrequencyTypesDto,
   getFileNames,
   ProcessedFileNotificationDto,
   SubscriptionsDto,
   UPLOAD_DATA_TEXTS,
   UploadDataType,
   UploadDataTypesDto,
   UploadedFileData
} from "../../../types/UploadData";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {ErrorResponseDto} from "../../../types/ErrorData";
import {showNotification} from "../../../ui/Toast/ToastNotification";
import style from './UploadDataPage.module.scss';
import Tooltip from "../../../services/tooltip.service";
import {capitalizeFirstLetter} from "../../../utils/capitalize-first-letter";
import ReactSlidingPane from "react-sliding-pane";
import Uploader from "./DragDropUploader/Uploader";
import {useNavigate} from "react-router-dom";
import AddManuallySkillsComponent
   from "./UploadPersonalDataComponent/AddManuallySkillsComponent/AddManuallySkillsComponent";
import {adminRegexPatterns} from "../../../types/AdminData";
import TagEmployeesInput from "../../../ui/TagEmployeesInput/TagEmployeesInput";
import {capitalizeOnlyFirstLetter} from "../../../utils/capitalize-only-first-letter";
import {LinearProgress} from "@mui/material";

const UploadDataPage: React.FC<{
   type: UploadDataType
}> = props => {

   const getDataURL = process.env.REACT_APP_PUBLIC_URL + `/file/${props.type}-data-types`;
   const saveFileURL = process.env.REACT_APP_PUBLIC_URL + '/file/save-file';
   const saveGitURL: string = process.env.REACT_APP_PUBLIC_URL + '/file/save-github-link';

   const [uploadData, setUploadData] = useState<UploadDataTypesDto>();
   const [processingValues, setProcessingValues] = useState<{category: string, value: number}[]>([]);
   const [pickedCategory, setPickedCategory] = useState<FileTypeValue>();
   const [fromPusher, setFromPusher] = useState(true);
   const [file, setFile] = useState<File>();
   const [showAddManuallySkillsComponent, setShowAddManuallySkillsComponent] = useState<boolean>(false);
   const [githubLink, setGithubLink] = useState('');
   const [githubValid, setGithubValid] = useState(false);
   const [textAreaContent, setTextAreaContent] = useState('');

   const authStore = useContext(AuthContext);
   const navigate = useNavigate();

   const {refreshToken} = useApiService();
   const spinnerService = useLoadingSpinner();

   useEffect(() => {
      authStore.changePageTitle(`Upload ${capitalizeFirstLetter(props.type)} Data`);
      initializeComponent(authStore.userData.accessToken);

      authStore.channel.unbind(`${props.type}_` + authStore.userData.userId);

      authStore.channel.bind(`${props.type}_` + authStore.userData.userId, (data: ProcessedFileNotificationDto) => {
         if (data.message.indexOf('failed') > -1) {
            showNotification('warning', 'Your file has failed processing. Please upload it again.');
         } else {
            if (location.pathname.startsWith(`/file/${props.type}-data-types`)) {
               initializeComponent(authStore.userData.accessToken, true);
            }
            setTimeout(() => {
               showNotification('success', 'Your file has finished processing. Check your dashboard.');
            }, 500);
         }
      });
   }, []);

   useEffect(() => {
      const delayInputTimeoutId = setTimeout(() => {
         setGithubValid(adminRegexPatterns.githubUrl.test(githubLink));
      }, 500);
      return () => clearTimeout(delayInputTimeoutId);
   }, [githubLink]);

   useEffect(() => {
      if (uploadData && fromPusher) {
         const names = uploadData.frequencyTypes.map(f => f.fileUploadsTypesAndData.filter(u => u.uploadedFileData?.isProcessing).map(u => u.fileType.value)).flat();

         setProcessingValues(prevState => {
            return prevState.map(p => names.find(s => s === p.category) ? {...p, value: !fromPusher ? 75 : p.value } : {...p, value: 100});
         });

         setTimeout(() => {
            setProcessingValues(prevState => {
               return prevState.filter(p => names.find(s => s === p.category));
            });
         }, 750);
      }
      else {
         setFromPusher(false);
      }
   }, [uploadData]);

   return (
      <>
         {
            uploadData &&
            <div className={style['upload-data-page']}>

               <div className="upload-heading u-margin-bottom-m">
                  <h1 className="header-1">{UPLOAD_DATA_TEXTS[props.type].title}</h1>
                  <p className={'u-margin-top-xs u-margin-bottom-xs'}>{UPLOAD_DATA_TEXTS[props.type].paragraph1}</p>
                  <p>{UPLOAD_DATA_TEXTS[props.type].paragraph2}</p>
                  {
                     props.type === UploadDataType.PERSONAL &&
                     <button className="button button-primary u-margin-top-xs" onClick={() => setShowAddManuallySkillsComponent(true)}><i className="fa-solid fa-plus"></i> Add skills</button>
                  }
               </div>

               <main className="upload-main">
                  <h2 className="header-2">{UPLOAD_DATA_TEXTS[props.type].paragraph3}</h2>
                  {
                     uploadData.frequencyTypes.filter(freq => freq.frequencyType !== FrequencyTypeEnum.NO_TYPE).map(freq => {
                        return (
                           <div className={'frequency-section'} key={freq.frequencyType}>
                              <div className="frequency-title">
                                 {
                                    checkInDueDate(freq) === freq.fileUploadsTypesAndData.length ?
                                       <i className="fa-solid fa-check green"></i> : <i className="fa-solid fa-hourglass-start yellow"></i>
                                 }
                                 <div><span className={checkInDueDate(freq) === freq.fileUploadsTypesAndData.length ? 'text-green-50' : 'text-yellow-30'}>{checkInDueDate(freq)}</span> / {freq.fileUploadsTypesAndData.length}</div>
                                 <div className={'frequency'}>{FrequencyTypesDto[freq.frequencyType]}</div>
                              </div>
                              <div className="categories-wrapper">
                                 {
                                    freq.fileUploadsTypesAndData.map(upload => {
                                       return (
                                          <div className={`category-card ${checkIfUploadedData(freq.frequencyType, upload.uploadedFileData) ? "uploaded" : ""}`} key={upload.fileType.id}>
                                             <div className="category-heading">
                                                <div className={'category-heading-wrapper'}>
                                                   {
                                                      upload.fileType.value === FileTypeValue.PSYCHOMETRIC_ASSESSMENT ? <div className={'new-banner'}>NEW</div> :
                                                         <img className={'category-image'} src={CATEGORY_IMAGES[upload.fileType.value]} alt={'Category Image'}/>
                                                   }
                                                   <h3 className="header-3">{getFileNames(upload.fileType.value)}</h3>
                                                </div>
                                                {
                                                   upload.uploadedFileData?.dueDate &&
                                                   new Date(upload.uploadedFileData.dueDate) < new Date() && (
                                                      <Tooltip place="right"
                                                               tooltipId={upload.fileType.id.toString() + "_overdue"}
                                                               backgroundColor="#101319"
                                                               border={true}
                                                               borderColor={'#5C5F6A !important'}
                                                               className='custom-tooltip'
                                                               content={<div>The task is overdue!</div>}>
                                                         <i className="fa-solid fa-hourglass-start red"></i>
                                                      </Tooltip>
                                                   )
                                                }
                                             </div>
                                             <div className={`category-time`}>
                                                {
                                                   upload.uploadedFileData?.dueDate &&
                                                   <div><i className="fa-solid fa-calendar-days text-yellow-50"></i>
                                                      <span className="text-surface-30">Due to: </span> {getTime(upload.uploadedFileData.dueDate).split(',')[1]}</div>
                                                }
                                             </div>
                                             <div className="category-footer">
                                                {
                                                   upload.uploadedFileData?.isProcessing || processingValues.find(p => p.category === upload.fileType.value)
                                                      ? <LinearProgress variant="determinate" value={processingValues.filter(p => p.category === upload.fileType.value)[0].value} />
                                                      : getButtonForFileType(upload)
                                                }
                                                {
                                                   upload.uploadedFileData?.dateAdded &&
                                                   <Tooltip place="right"
                                                            tooltipId={upload.fileType.id.toString()}
                                                            backgroundColor="#101319"
                                                            border={true}
                                                            borderColor={'#5C5F6A !important'}
                                                            className='custom-tooltip'
                                                            content={
                                                               <div>Last update: {getTime(upload.uploadedFileData.dateAdded)}</div>
                                                            }>
                                                      <span className={"u-margin-left-xs"}><i className="fa-solid fa-clock-rotate-left"></i></span>
                                                   </Tooltip>
                                                }
                                             </div>
                                          </div>
                                       )
                                    })
                                 }
                              </div>
                           </div>
                        )
                     })
                  }
                  {
                     uploadData.subscriptions &&
                     <div className="frequency-section">
                        <div className="frequency-title">
                           {
                              checkSubscriptionsIfActive(uploadData.subscriptions) === uploadData.subscriptions.length ?
                                 <i className="fa-solid fa-check green"></i> :
                                 <i className="fa-solid fa-hourglass-start yellow"></i>
                           }
                           <div><span
                              className={checkSubscriptionsIfActive(uploadData.subscriptions) === uploadData.subscriptions.length ? 'text-green-50' : 'text-yellow-30'}>{checkSubscriptionsIfActive(uploadData.subscriptions)}</span> / {uploadData.subscriptions.length}
                           </div>
                           <div className={'frequency'}>Subscriptions</div>
                        </div>
                        <div className="categories-wrapper">
                           {
                              uploadData.subscriptions.map(subscription => {
                                 return (
                                    <div
                                       className={`category-card ${subscription.active ? "uploaded" : ""}`}
                                       key={subscription.name}>
                                       <div className="category-heading">
                                          <div className="category-heading-wrapper">
                                             <img className={'category-image'} src={'/assets/images/upload-data-icons/jira-icon.svg'} alt={'Subscription Image'}/>
                                             <h3 className="header-3">{capitalizeOnlyFirstLetter(subscription.name)}</h3>
                                          </div>
                                       </div>
                                       <div className="category-footer">
                                          <button className="button button-tertiary" onClick={() => subscription.active ? unsubscribeJira() : integrateJiraHandler()}>
                                             <i className="fa-solid fa-link"></i> {subscription.active ? "Unlink" : "Link"} your project
                                          </button>
                                          {
                                             subscription.active &&
                                             <Tooltip place="right"
                                                      tooltipId={subscription.name}
                                                      backgroundColor="#101319"
                                                      border={true}
                                                      borderColor={'#5C5F6A !important'}
                                                      className='custom-tooltip'
                                                      content={
                                                         <div>{capitalizeOnlyFirstLetter(subscription.name)} is linked to your project.</div>
                                                      }>
                                             <span className={"u-margin-left-xs"}><i
                                                className="fa-solid fa-clock-rotate-left"></i></span>
                                             </Tooltip>
                                          }
                                       </div>
                                    </div>
                                 )
                              })
                           }
                        </div>
                     </div>
                  }
               </main>
            </div>
         }
         <ReactSlidingPane isOpen={!!pickedCategory}
                           onRequestClose={closeSideBar}
                           from={'right'}
                           className='admin-side-panel'
                           width='35%' hideHeader>
            <div>
               <div className="admin-side-panel-body">
                  <div className="admin-side-panel-header u-margin-bottom-xxl">
                     <h1 className="header-1">
                        {
                           pickedCategory && pickedCategory === FileTypeValue.GITHUB && "Extract skills from your GitHub profile"
                        }
                        {
                           pickedCategory && pickedCategory === FileTypeValue.TEAM_SKILLS && "Extract skills from text"
                        }
                        {
                           pickedCategory && pickedCategory !== FileTypeValue.TEAM_SKILLS && pickedCategory !== FileTypeValue.GITHUB && "Extract skills from file"
                        }
                     </h1>
                     <div onClick={closeSideBar} className="close-button-container"><i
                        className="fa-solid fa-xmark"></i></div>
                  </div>
                  {
                     pickedCategory && pickedCategory !== FileTypeValue.GITHUB && pickedCategory !== FileTypeValue.TEAM_SKILLS &&
                     <>
                        {
                           !file && <div className={'u-margin-bottom-s'}>To get started, upload a file for our AI to
                              analyze.</div>
                        }
                        <Uploader uploadFile={file => setFile(file)} cancelUpload={hideUpload}
                                  category={pickedCategory!.valueOf()} progress={0}/>
                        {
                           file &&
                           <div className="u-margin-top-s">Your data security and confidentiality are our top priorities
                              throughout this process.</div>
                        }
                     </>
                  }
                  {
                     pickedCategory && pickedCategory === FileTypeValue.GITHUB &&
                     <>
                        <div>To get started, add your GitHub profile link for our AI to analyze.</div>
                        <div className="custom-sidepanel-input u-margin-top-s">
                           <i className="fa-solid fa-link me-2"></i>
                           <input type="text" placeholder={'Your GitHub link'} value={githubLink}
                                  onChange={e => setGithubLink(e.target.value)}/>
                        </div>
                     </>
                  }
                  {
                     pickedCategory && pickedCategory === FileTypeValue.TEAM_SKILLS &&
                     <>
                        <p>Describe your team's skills and experience.</p>
                        <p className="text-surface-30 u-margin-top-xxs u-margin-bottom-s">Use @ to tag individual team members and describe their unique contributions, skills, and areas of expertise.</p>
                        <TagEmployeesInput  setContent={content => setTextAreaContent(content)} />
                     </>
                  }
               </div>
               <div className="buttons-wrapper u-margin-top-m">
                  <button className="button button-secondary u-margin-right-s" onClick={() => closeSideBar()}>Cancel</button>
                  <button disabled={!file && !githubValid && textAreaContent.trim().length === 0} className="button button-primary" onClick={
                     () => {
                        if (file) {
                           uploadFileHandler(file, authStore.userData.accessToken);
                        }
                        else if (pickedCategory && pickedCategory === FileTypeValue.GITHUB && adminRegexPatterns.githubUrl.test(githubLink) && githubValid) {
                           uploadGithub(githubLink, authStore.userData.accessToken);
                        }
                        else if (pickedCategory && pickedCategory === FileTypeValue.TEAM_SKILLS && textAreaContent.trim().length > 0) {
                           uploadText(authStore.userData.accessToken);
                        }
                        setUploadData((prevState: any) => {
                           const freqTypes = prevState!.frequencyTypes;
                           for (let i = 0; i < freqTypes.length; i++) {
                              freqTypes[i].fileUploadsTypesAndData = freqTypes[i].fileUploadsTypesAndData.map((fileUploads: any) => fileUploads.fileType.value === pickedCategory ? {...fileUploads, uploadedFileData: {...fileUploads.uploadedFileData, isProcessing: true}} : fileUploads);
                           }
                           return {...prevState, freqTypes: freqTypes};
                        });
                        addItemAndUpdateValues({category: pickedCategory!.toString(), value: 0});
                        closeSideBar();
                     }
                  }>Start analyzing</button>
               </div>
            </div>
         </ReactSlidingPane>
         {
            showAddManuallySkillsComponent && <AddManuallySkillsComponent closeModal={toggleAddSkillsComponent}/>
         }
         {
            spinnerService.spinner
         }
      </>
   );

   function addItemAndUpdateValues (newItem: { category: string; value: number }) {
      setProcessingValues((prevValues) => [...prevValues, newItem]);

      // Set up an interval to update the values
      const interval = setInterval(() => {
         setProcessingValues((prevValues) => {
            const updatedValues = prevValues.map((item) =>
               item.value < 75 ? { ...item, value: Math.min(item.value + 1, 75) } : item
            );

            // If all items have reached the value of 75, clear the interval
            if (updatedValues.every((item) => item.value >= 75)) {
               clearInterval(interval);
            }

            return updatedValues;
         });
      }, 500);
   }

   function checkSubscriptionsIfActive(subscriptions: SubscriptionsDto[]) {
      return subscriptions.filter(sub => sub.active).length;
   }

   function getButtonForFileType(uploadedData: FileTypesAndDataDto) {
      switch (uploadedData.fileType.value) {
         case FileTypeValue.PSYCHOMETRIC_ASSESSMENT:
            return <button className="button button-secondary"
                           onClick={() => navigate("/persona")}>{(uploadedData.uploadedFileData && uploadedData.uploadedFileData.dateAdded && uploadedData.uploadedFileData.isInDueDate) ? "See results" : "Start test"}</button>
         case FileTypeValue.GITHUB:
            return <button className="button button-tertiary"
                           onClick={() => {
                              setPickedCategory(uploadedData.fileType.value);
                           }}><i
               className="fa-solid fa-link"></i> Connect to your profile</button>
         default:
            return <button className="button button-tertiary"
                           onClick={() => {
                              setPickedCategory(uploadedData.fileType.value);
                           }}><i
               className="fa-solid fa-arrow-up-from-bracket"></i> Upload Data</button>
      }
   }

   function checkIfUploadedData(frequency: FrequencyTypeEnum, uploadedData?: UploadedFileData) {
      if (frequency === FrequencyTypeEnum.ONCE_ONLY) {
         return uploadedData && uploadedData.dateAdded;
      }
      else if (frequency === FrequencyTypeEnum.SEMI_ANNUALLY) {
         return uploadedData && uploadedData.dateAdded && uploadedData.isInDueDate;
      }
      else {
         return uploadedData?.isInDueDate;
      }
   }

   function checkInDueDate(frequencyTypes: FrequencyTypesDto) {
      if (frequencyTypes.frequencyType === FrequencyTypeEnum.ONCE_ONLY) { // if once only then due date is null and isInDueDate flag is null also
         return frequencyTypes.fileUploadsTypesAndData.filter(upload => upload.uploadedFileData && upload.uploadedFileData.dateAdded).length;
      }
      else {
         return frequencyTypes.fileUploadsTypesAndData.filter(upload => upload.uploadedFileData?.isInDueDate).length;
      }
   }

   function closeSideBar() {
      setPickedCategory(undefined);
      setFile(undefined);
      setTextAreaContent('');
      setGithubLink('');
   }

   function toggleAddSkillsComponent() {
      setShowAddManuallySkillsComponent(prevState => !prevState);
   }

   function integrateJiraHandler() {
      const clientState = Math.random().toString(36).slice(-5);
      const jiraHost: any = process.env.REACT_APP_JIRA_HOST;
      const link: string = `${jiraHost}${clientState}&response_type=code&prompt=consent`;
      window.location.replace(link);
   }

   function unsubscribeJira() {
      window.location.replace('/auth/unsubscribe-jira');
   }

   function getTime(time: Date) {
      let date = new Date(time);

      let hours = date.getHours().toString();
      if (hours.length === 1) {
         hours = '0' + hours;
      }

      let minutes = date.getMinutes().toString();
      if (minutes.length === 1) {
         minutes = '0' + minutes;
      }

      let day = date.getDate();

      const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      let month = months[date.getMonth()];

      let year = date.getFullYear();

      return `${hours}:${minutes}, ${day} ${month} ${year}`
   }

   function hideUpload() {
      setFile(undefined);
      setPickedCategory(undefined);
   }

   function uploadText(accessToken: string) {
      const uploadTextURL = process.env.REACT_APP_PUBLIC_URL + '/file/save-text';

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      const data = {
         text: textAreaContent,
         subCategory: FileTypeValue.TEAM_SKILLS
      }

      spinnerService.createSpinner();

      axios
         .post(uploadTextURL, data, {headers})
         .then(() => {
            setTextAreaContent('');
            spinnerService.removeSpinner();
         })
         .catch(error$ => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     uploadText(response$.data.accessToken);
                  })
            } else {
               spinnerService.removeSpinner();
               showNotification('warning', error$.response.data.message);
            }
         })
   }

   function uploadGithub(link: string, accessToken: string) {
      spinnerService.createSpinner();

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      const body = {
         'githubLink': link
      }

      axios
         .post(saveGitURL, body, {headers})
         .then(() => {
            setGithubLink('');
            spinnerService.removeSpinner();
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     uploadGithub(link, response$.data.accessToken);
                  })
            } else {
               hideUpload();
               spinnerService.removeSpinner()
               showNotification('warning', error$.response.data.message);
            }
         });
   }

   function uploadFileHandler(file: File, accessToken: string) {
      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      const bodyFormData = new FormData();
      bodyFormData.append('file', file);
      bodyFormData.append('subCategory', pickedCategory!);
      spinnerService.createSpinner();
      axios
         .post(saveFileURL, bodyFormData, {headers})
         .then(() => {
            setFile(undefined);
            spinnerService.removeSpinner();
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     uploadFileHandler(file, response$.data.accessToken);
                  })
            } else {
               hideUpload();
               spinnerService.removeSpinner()
               showNotification('warning', error$.response.data.message);
            }
         });
   }

   function initializeComponent(accessToken: string, fromPusher?: boolean) {
      if (!fromPusher) {
         spinnerService.createSpinner();
      }

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      axios.get(getDataURL, {headers})
         .then((response$: AxiosResponse<UploadDataTypesDto>) => {
            setUploadData(response$.data);
            if (!fromPusher) {
               const names = response$.data.frequencyTypes.map(f => f.fileUploadsTypesAndData.filter(u => u.uploadedFileData?.isProcessing).map(u => u.fileType.value)).flat();

               setProcessingValues(names.map(n => ({category: n, value: 75})));
            }
            setFromPusher(!!fromPusher);
            spinnerService.removeSpinner();
         })
         .catch((error$: ErrorResponseDto) => {
            spinnerService.removeSpinner();

            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     initializeComponent(response$.data.accessToken, fromPusher);
                  })
            } else {
               spinnerService.removeSpinner();
               showNotification('warning', error$.response.data.message);
            }
         });
   }
}

export default UploadDataPage;