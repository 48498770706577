import { useState, useContext } from 'react';
import styles from './RateSkillsComponent.module.scss';
import axios, {AxiosRequestConfig} from 'axios';

import RateSkillsModal from '../RateSkillsModal/RateSkillsModal';

import useModal from '../../../../../../services/modal.service';
import useLoadingSpinner from '../../../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner';
import { showNotification } from '../../../../../../ui/Toast/ToastNotification';
import useApiService from '../../../../../../services/api.service';
import AuthContext from '../../../../../../store/auth-context';
import { UserData } from '../../../../../../types/AuthData';
import {ErrorResponseDto} from "../../../../../../types/ErrorData";

const RateSkillsComponent: React.FC = () => {
  const authStore = useContext(AuthContext);
	const userData: UserData = authStore.userData;  
  const [initializedComponent, setInitializedComponent] = useState(true);
  const [showFinishedMessage, setShowFinishedMessage] = useState(false);
    
  const rateModalServise = useModal();
  const spinnerService = useLoadingSpinner();

  const getSocialValidationNotificationsURL: string = process.env.REACT_APP_PUBLIC_URL + '/notifications/social-validation';
  const saveEmployeeSkillsRatingsURL: string = process.env.REACT_APP_PUBLIC_URL + '/employee/social-skill-validation';
  const { refreshToken } = useApiService();

  return (
    <>
      { initializedComponent &&
        <div className={styles['rate-skills-card']}>
          <div className='content'> 
            {
              showFinishedMessage ? 
              <>
                <div className='content-text'>Thank you for your answers!</div>
                <div className='content-button'>
                  <i className="fa-solid fa-xmark close-card" onClick={()=> setInitializedComponent(false)}></i>
                </div>
              </> :
              <>
                <div className='content-text'>Help your teammates validate their skills.</div>
                <div className='content-button'>
                  <button className='button button-primary' onClick={()=> showRateModal(userData.accessToken)}>Provide input</button>
                </div>
              </>
            }
          </div>
        </div>
      }      
      {
        rateModalServise.showModal && <RateSkillsModal data={rateModalServise.modalData} 
                okCallback={(data: any)=> saveEmployeeSkillsRatings(data, userData.accessToken)} cancelCallback={closeRateModal} />
      }
      {
        spinnerService.spinner
      }  
    </>
  )

  function showRateModal(accessToken: string) {
    spinnerService.createSpinner();
    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }
    
    axios
    .get(getSocialValidationNotificationsURL, {headers})
    .then((response$) => {
      rateModalServise.createModal(response$.data);
      setInitializedComponent(true);
      spinnerService.removeSpinner();
    })
    .catch((error$: ErrorResponseDto) => {
      if (error$.response.data.message === 'Unauthorized') {
        // Get new Access Token
        refreshToken(authStore.userData.refreshToken)
          .then((response$: any) => {						
            authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
            showRateModal(response$.data.accessToken);
          })
      }
      else {
        rateModalServise.removeModal();
        spinnerService.removeSpinner();
        showNotification('warning', error$.response.data.message);
      }      
    })    
  }

  function closeRateModal() {
    rateModalServise.removeModal();
  }

  function saveEmployeeSkillsRatings(data: any, accessToken: string): void {
    let payload = data.socialSkillValidation;

    for (let item of payload) {
      if (item.rating === null) {
        delete item.rating;
      }
    }

    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }
    spinnerService.createSpinner();

    axios
      .post(saveEmployeeSkillsRatingsURL, payload, {headers})
      .then((response$) => {     
        setShowFinishedMessage(true);
        closeRateModal();
        spinnerService.removeSpinner();        
      })
      .catch((error$) => {
        if (error$.response.data.message === 'Unauthorized') {
          // Get new Access Token
          refreshToken(authStore.userData.refreshToken)
            .then((response$: any) => {						
              authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
              saveEmployeeSkillsRatings(data, response$.data.accessToken);
            })
        }
        else {
          closeRateModal();
          setInitializedComponent(false);
          spinnerService.removeSpinner();
          showNotification('warning', 'There was an error. Please try again.');
        }
      })
  }
}

export default RateSkillsComponent;