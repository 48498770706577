import React, { useContext, useEffect, useState } from 'react';
import styles from './SettingsNeededSkills.module.scss';
import ProjectsContext from '../../../../../../../store/projects-context';
import AuthContext from '../../../../../../../store/auth-context';
import AddSkillModal from './AddSkillModal/AddSkillModal';
import AddDescriptionModal from './AddDescriptionModal/AddDescriptionModal';
import useLoadingSpinner from '../../../../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner';
import Tooltip from '../../../../../../../services/tooltip.service';
import useApiService from "../../../../../../../services/api.service";
import {capitalizeFirstLetter} from "../../../../../../../utils/capitalize-first-letter";
import {showNotification} from "../../../../../../../ui/Toast/ToastNotification";
import {ProjectDto} from "../../../../../../../types/Projects";
import {ProjectPanelSkillDto} from "../../../../../../../types/Settings";
import {ErrorResponseDto} from "../../../../../../../types/ErrorData";

const SettingsNeededSkills: React.FC<{projectData: ProjectDto}> = (props) => {
  const projectsStore = useContext(ProjectsContext);
  const authStore = useContext(AuthContext);
  const [projectSkills, setProjectSkills] = useState<ProjectPanelSkillDto[]>([]);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [showAddSkillSection, setShowAddSkillSection] = useState(false);
  const [showAddDescriptionSection, setShowAddDescriptionSection] = useState(false);
  // const [isInit, setIsInit] = useState<boolean>(false);

  const spinnerService = useLoadingSpinner();
  const { refreshToken } = useApiService();

  useEffect(()=> {
    checkForChanges();
  }, [projectSkills])

  useEffect(()=> {    
    let tempData = JSON.parse(JSON.stringify(projectsStore.neededSkillsData));
    tempData.sort((a: any, b: any) => {
      const nameA = (a.synonym ? a.synonym.name : a.skill.name).toUpperCase();
      const nameB = (b.synonym ? b.synonym.name : b.skill.name).toUpperCase();
      return nameA.localeCompare(nameB);
    });
    setProjectSkills(tempData);
  }, [projectsStore.neededSkillsData])
 
  return (
    <> 
      <div className={styles['settings-needed-skills']}>
        <div className='settings-needed-skills-header'>
          <div className='row'>
            <div className='col-12 col-lg-6'>
              <h2 className='header-2'>Needed skills for this project</h2>
            </div>
            <div className='col-12 col-lg-6'>
              <div className='settings-needed-skills-commands'>
                <button className='button button-tertiary' onClick={openAddDescriptionModal}>Extract from file/description</button>
                <button className='button button-tertiary u-margin-left-s' onClick={openAddSkillModal}>+ Add skill</button>
              </div>
            </div>
          </div>
        </div>          
        <div className='skills-tag-wrapper-section'>
          { projectSkills.length > 0 &&
            <p className="u-margin-top-xs u-margin-bottom-xs">Click on skill to delete it.</p>
          }        
          <div className='skills-tag-wrapper'>
            {
              removeDuplicates(projectSkills).map(item => {
                return <div className={`skill-tag ${item.deleted ? 'removed' : ''}`} key={item.id} id={item.id.toString()} onClick={()=>toggleSkillSelect(item)}>
                  {capitalizeFirstLetter(item.synonym ? item.synonym.name : item.skill.name)}
                  {
                    <span>
                      <Tooltip
                        place="top"                                         
                        tooltipId={item.skill.id.toString()}  
                        backgroundColor="#101319"
                        borderColor='#5C5F6A !important'
                        border={true}            
                        content={
                          <span>{item.skill.description}</span>                                 
                        }
                        className='tag-skill-description'
                      >
                        <span className='select-skill-description-symbol'><i className="fa-regular fa-circle-question"></i></span>
                      </Tooltip>  
                    </span>
                  }
  
                  </div>
              })
            }
          </div>        
        </div>
        {
          hasChanges &&
          <div className='commands-wrapper-section'>
            <button className='button button-secondary u-margin-right-xs' onClick={resetNeededSkillsTempData}>Cancel</button>
            <button className='button button-primary' onClick={() => saveChanges(authStore.userData.accessToken)}>Save</button>
          </div>
        }
        { showAddSkillSection &&
          <AddSkillModal projectData={props.projectData} closelModal={closeAddSkillModal} />
        }
        { showAddDescriptionSection &&
          <AddDescriptionModal projectData={props.projectData} closelModal={closeAddDescriptionModal} />
        }
      </div>      
      {
        spinnerService.spinner
      }
    </>
  )

  function removeDuplicates(data: ProjectPanelSkillDto[]) {
    const skillMap = new Map<number, boolean>();

    data.forEach((skill: any) => {
      if (skill.synonym) {
        skillMap.set(skill.skill.id, true);
      }
    }) // set skills with synonyms
    return data.filter((skill: any) => {
      if (skillMap.has(skill.skill.id)) {
        return skill.synonym;
      }
      return true;
    })
  }
  
  function toggleSkillSelect (sk: ProjectPanelSkillDto) {
    const tempData = projectSkills.map(skill => {
      if (skill.id === sk.id) {
        return {
          ...skill,
          deleted: !skill.deleted
        }
      }
      return skill;
    })
    setProjectSkills(tempData);
  }

  function checkForChanges() {
    setHasChanges(projectSkills.find((skill: any) => skill.deleted) !== undefined);
  }
  
  function saveChanges(accessToken: string): void {
    let projectId = props.projectData.id;

    let skillsToBeDeleted: number[] = projectSkills.filter((skill: any) => skill.deleted).map((skill: any) => skill.id);

    spinnerService.createSpinner();

    projectsStore.deleteProjectSkill(skillsToBeDeleted, projectId, accessToken)
      .then((response$: any)=> {
        spinnerService.removeSpinner();
      })
      .catch((error$: ErrorResponseDto)=> {
        if (error$.response.data.message === 'Unauthorized') {
          // Get new Access Token
          refreshToken(authStore.userData.refreshToken)
              .then((response$: any) => {
                authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                saveChanges(response$.data.accessToken);
              })
        }
        else {
          spinnerService.removeSpinner();
          showNotification('warning', error$.response.data.message);
        }
      })
  }

  function resetNeededSkillsTempData () {
    setProjectSkills(projectsStore.neededSkillsData);
  }

  function openAddSkillModal() {
    resetNeededSkillsTempData();
    setShowAddSkillSection(true);
  }

  function closeAddSkillModal() {    
    setShowAddSkillSection(false);
  }

  function openAddDescriptionModal() {
    resetNeededSkillsTempData();
    setShowAddDescriptionSection(true);
  }

  function closeAddDescriptionModal() {
    projectsStore.resetExtractedSkillsData();
    setShowAddDescriptionSection(false);
  }
}

export default SettingsNeededSkills;