import React, {createContext, useContext, useEffect, useState} from "react";
import {NotificationCenterItem, useNotificationCenter} from "react-toastify/addons/use-notification-center";
import {Notification} from "../types/Notification";
import axios, {AxiosRequestConfig} from "axios";
import AuthContext from "./auth-context";
import useApiService from "../services/api.service";
import {showNotification} from "../ui/Toast/ToastNotification";
import {ErrorResponseDto} from "../types/ErrorData";

const getNotificationsURL = process.env.REACT_APP_PUBLIC_URL + '/notifications';
const setReadNotificationURL = process.env.REACT_APP_PUBLIC_URL + '/notifications/update-skill-notifications';
const deleteNotificationURL = process.env.REACT_APP_PUBLIC_URL + '/notifications/delete-skill-notifications';
const getLearningNotificationsURL = process.env.REACT_APP_PUBLIC_URL + '/notifications/learning';

export const NotificationContext = createContext({
   notificationList: [] as NotificationCenterItem<Notification>[],
   count: 0,
   setAuthStoreNotifications: (): any => {},
   getNotifications: (accessToken: string): any => {},
   clearAllNotifications: (accessToken: string): any => {},
   removeNotification: (id: number, idDB: number, accessToken: string): any => {},
   markAllAsReadNotifications: (accessToken: string): any => {},
   markAsReadNotification: (id: number, idDB: number, accessToken: string): any => {},
   addNotification: (data: any): any => {}
});

export const NotificationContextProvider: React.FC<{ children: React.ReactNode }> = (props) => {
   const authStore = useContext(AuthContext);
   const {
      notifications,
      clear,
      markAllAsRead,
      markAsRead,
      unreadCount,
      remove,
      sort,
      add,
      update,
      find
   } = useNotificationCenter<Notification>();

   const [notificationList, setNotificationList] = useState([] as NotificationCenterItem<Notification>[]);
   let count = notifications.filter(n => !n.data!.read && n.data!.id).length;

   const {refreshToken} = useApiService();

   useEffect(() => {
      setNotificationList(notifications.filter(notification => notification.data?.id))
   }, [notifications]);

   useEffect(() => {
      authStore.channelNotifications.unbind(`notification_${authStore.userData.userId}`);
      authStore.channelNotifications.bind(`notification_${authStore.userData.userId}`, (data: any) => {
         if (data.notifications) {
            data.notifications.forEach((n: Notification) => addNotification(n));
         }
      })

      authStore.channelNotifications.unbind(`learning_notification_${authStore.userData.userId}`);
      authStore.channelNotifications.bind(`learning_notification_${authStore.userData.userId}`, (data: any) => {
         getLearningNotificationsWrapper(authStore.userData.accessToken);
      })
   }, []);
   function getLearningNotificationsWrapper(accessToken: string) {
      getLearningNotifications(accessToken)
         .then((res$: any) => {
            if (res$.data) {
               res$.data.forEach((n: Notification) => addNotification(n));
            }
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     getLearningNotificationsWrapper(response$.data.accessToken);
                  })
            } else {
               showNotification('warning', error$.response.data.message);
            }
         })
   }

   function setAuthStoreNotifications(): boolean {
      if (authStore.userData.notifications) {
         authStore.userData.notifications.forEach(n => addNotification(n));
         return true;
      }
      return false;
   }

   function markLocalAsRead(ids: number[]) {
      const newState = notificationList.map(n => {
         if (ids.includes(n.data!.id)) {
            n.data!.read = true;
         }
         return n;
      });
      setNotificationList(newState);
   }

   function addNotification(data: Notification) {
      add({id: data.id,data: data});
   }

   async function getNotifications(accessToken: string) {
      let promise = new Promise((resolve: any, reject: any) => {

         const headers: AxiosRequestConfig['headers'] = {
            'Authorization': `Bearer ${accessToken}`
         }

         axios
            .get(getNotificationsURL, {headers})
            .then((response$: any) => {
               response$.data.forEach((n: Notification) => addNotification(n));
               resolve();
            })
            .catch((error$: ErrorResponseDto) => {
               reject(error$);
            })
      })
      return await promise;
   }

   async function clearAllNotifications(accessToken: string) {
      let ids: number[] = notificationList.map(n => n.data!.id);
      clear();

      let promise = new Promise((resolve: any, reject: any) => {
         const headers: AxiosRequestConfig['headers'] = {
            'Authorization': `Bearer ${accessToken}`
         }
         let data: any = {
            notifications: ids
         };

         axios
            .post(deleteNotificationURL, data, {headers})
            .then((response$: any) => {
               resolve();
            })
            .catch((error$: ErrorResponseDto) => {
               reject(error$);
            })
      })
      return await promise;
   }

   async function removeNotification(id: number, idDB: number, accessToken: string) {
      remove(id);
      let promise = new Promise((resolve: any, reject: any) => {
         const headers: AxiosRequestConfig['headers'] = {
            'Authorization': `Bearer ${accessToken}`
         }
         let data: any = {
            notifications: [idDB]
         };

         axios
            .post(deleteNotificationURL, data, {headers})
            .then((response$: any) => {
               resolve();
            })
            .catch((error$: ErrorResponseDto) => {
               reject(error$);
            })
      })
      return await promise;
   }

   async function markAllAsReadNotifications(accessToken: string) {
      markAllAsRead();
      let ids: number[] = notificationList.map(n => n.data!.id);
      markLocalAsRead(ids);

      let promise = new Promise((resolve: any, reject: any) => {
         const headers: AxiosRequestConfig['headers'] = {
            'Authorization': `Bearer ${accessToken}`
         }
         let data: any = {
            notifications: ids,
            "isRead": true
         };

         axios
            .post(setReadNotificationURL, data, {headers})
            .then((response$: any) => {
               resolve();
            })
            .catch((error$: ErrorResponseDto) => {
               reject(error$);
            })
      })
      return await promise;
   }

   async function markAsReadNotification(id: number, idDB: number, accessToken: string) {
      markAsRead(id);
      markLocalAsRead([idDB]);
      let promise = new Promise((resolve: any, reject: any) => {
         const headers: AxiosRequestConfig['headers'] = {
            'Authorization': `Bearer ${accessToken}`
         }
         let data: any = {
            notifications: [idDB],
            "isRead": true
         };

         axios
            .post(setReadNotificationURL, data, {headers})
            .then((response$: any) => {
               resolve();
            })
            .catch((error$: ErrorResponseDto) => {
               reject(error$);
            })
      })
      return await promise;
   }

   async function getLearningNotifications(accessToken: string) {
      let promise = new Promise((resolve: any, reject: any) => {

         const headers: AxiosRequestConfig['headers'] = {
            'Authorization': `Bearer ${accessToken}`
         }

         axios
            .get(getLearningNotificationsURL, {headers})
            .then((response$: any) => {
               resolve(response$);
            })
            .catch((error$: ErrorResponseDto) => {
               reject(error$);
            });
      });
      return await promise;
   }

   let context = {
      notificationList: notificationList as any,
      count: count as any,
      setAuthStoreNotifications: setAuthStoreNotifications as any,
      getNotifications: getNotifications as any,
      clearAllNotifications: clearAllNotifications as any,
      removeNotification: removeNotification as any,
      markAllAsReadNotifications: markAllAsReadNotifications as any,
      markAsReadNotification: markAsReadNotification as any,
      addNotification: addNotification as any
   }

   return (
      <NotificationContext.Provider value={context}>
         {props.children}
      </NotificationContext.Provider>
   )

}

export default NotificationContext;