import React, {Ref, useEffect, useState} from "react";
import styles from './ScrollHighlightNavbar.module.scss';

/**
 * @param {number} currentPosition Current Scroll position
 * @param {Array} sectionPositionArray Array of positions of all sections
 * @param {number} startIndex Start index of array
 * @param {number} endIndex End index of array
 * @return {number} Current Active index
 */
const nearestIndex = (
   currentPosition: number,
   sectionPositionArray: { headerID: string; headerRef: any; headerTitle: string; }[],
   startIndex: number,
   endIndex: number,
   container: { scrollTop: number; } | undefined
) => {
   let minDistance = Number.POSITIVE_INFINITY;
   let nearestIdx = startIndex;

   for (let i = startIndex; i <= endIndex; i++) {
      const headerRef = sectionPositionArray[i].headerRef.current;
      if (headerRef) {
         const distance = Math.abs(
            headerRef.offsetTop - (container!.scrollTop / 2.2) - currentPosition
         );

         if (distance < minDistance) {
            minDistance = distance;
            nearestIdx = i;
         }
      }
   }

   return nearestIdx;
};

const ScrollHighlightNavbar: React.FC<{ navHeader: {headerID: string, headerRef: any, headerTitle: string}[] }> = props => {
   const [activeIndex, setActiveIndex] = useState(0);

   useEffect(() => {
      const handleScroll = (e: any) => {
         var index = nearestIndex(
            document.querySelector(".terms-paragraph")!.scrollTop,
            props.navHeader,
            0,
            props.navHeader.length - 1,
            document.querySelector(".terms-paragraph")!
         );
         setActiveIndex(index);
      };

      document.addEventListener("scroll", handleScroll, true);
      return () => {
         document.removeEventListener("scroll", handleScroll, true);
      };
   }, []);

   return (
      <>
         <div className={styles['scroll-highlight-navbar']}>
            <div className="terms-navbar">
               {props.navHeader.map((header: any, index: number) => (
                  <a
                     key={index + header.headerID}
                     className={`terms-navbar-item ${activeIndex === index ? 'active' : ''}`}
                     href={`#${header.headerID}`}>
                     {header.headerTitle}
                  </a>
               ))}
            </div>
         </div>
      </>
   );
}
export default ScrollHighlightNavbar;
