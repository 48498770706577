import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './SettingsPage.module.scss';
import ProjectsContext from '../../../../../../store/projects-context';
import AuthContext from '../../../../../../store/auth-context';
import axios, {AxiosRequestConfig} from 'axios';

import SettingsNeededSkills from './SettingsNeededSkills/SettingsNeededSkills';
import SettingsPeopleComponent from './SettingsPeopleComponent/SettingsPeopleComponent';
import SettingsGeneralComponent from './SettingsGeneralComponent/SettingsGeneralComponent';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import useApiService from "../../../../../../services/api.service";
import { showNotification } from '../../../../../../ui/Toast/ToastNotification';
import {ProjectDto} from "../../../../../../types/Projects";
import {ProjectPanel} from "../../../../../../types/Settings";
import {ErrorResponseDto} from "../../../../../../types/ErrorData";

const SettingsPage: React.FC<{projectData: ProjectDto}> = (props) => {
  const params = useParams() as any;
  const navigate = useNavigate();

  const projectsStore = useContext(ProjectsContext);
  const authStore = useContext(AuthContext);
  const projectTitle: string = props.projectData.name;

  const { refreshToken } = useApiService();

  const [isInit, setIsInit] = useState<boolean>(false);
  const [managers, setManagers] = useState<string[]>([]);

  useEffect(()=> {
    initializeComponent(authStore.userData.accessToken);
  }, []);

  return (
    <div className={styles['manager-dashboard-settings']}>
      { isInit &&
        <div className="col-12">
          <div className="manager-dashboard-header">
            <div className="back-link u-margin-bottom-xs">
              <button className={'button button-tertiary'} onClick={backToAllProjects}>All projects</button>
              / <button className={'button button-tertiary'}
                        onClick={backToNeededSkills}>{projectTitle}</button> / <span>Settings</span>

            </div>
           <h1 className="header-1">{projectTitle}</h1>
          </div>
          <div className='manager-wrapper'>
            <div className="tabs-container col-12">
              <Tabs className={'custom-tabs'} selectedTabClassName={'selected-tab'}>
                <TabList>
                  <Tab>Skills</Tab>                
                  <Tab>People</Tab>
                  <Tab>General</Tab>                                     
                </TabList>                                      
                <TabPanel>
                  <SettingsNeededSkills projectData={props.projectData} />
                </TabPanel>        
                <TabPanel>
                  <SettingsPeopleComponent managers={managers} projectData={props.projectData} initializeComponent={initializeComponent}/>
                </TabPanel>
                <TabPanel>
                  <SettingsGeneralComponent projectData={props.projectData}/>
                </TabPanel>                                        
              </Tabs>                                     
            </div>
          </div>
        </div>
      }   
    </div>
  )

  function initializeComponent(accessToken : string) {
    authStore.changePageTitle("Settings");
    projectsStore.getCompanyEmployees(props.projectData.id, accessToken);

    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }

    axios.get(process.env.REACT_APP_PUBLIC_URL + '/manager/project-panel', {
        params: {
          projectId: +params.id
        },
        headers,
      })
      .then((response$: { data: ProjectPanel })=> {
        setManagers(response$.data.managers);
        projectsStore.initialProjectEmployees(response$.data.projectEmployees);
        projectsStore.initialNeededSkills(response$.data.neededSkills);
        setIsInit(true);
      })
      .catch((error$: ErrorResponseDto)=> {
        if (error$.response.data.message === 'Unauthorized') {
          // Get new Access Token
          refreshToken(authStore.userData.refreshToken)
            .then((response$: any) => {
              authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
              initializeComponent(response$.data.accessToken);
            })
        }
        else {
          setIsInit(true);
          showNotification('warning', error$.response.data.message);
        }
      })
  }

  function backToAllProjects () {
    navigate('/manager/projects');
  }

  function backToNeededSkills () {
    navigate(`/manager/projects/${props.projectData.id}/needed-skills`);
  }
}

export default SettingsPage;