import React, {useContext, useEffect, useState} from "react";
import styles from './SkillsDetailsComponent.module.scss';
import MultiSelectDropDown from "../../../../../../../ui/MultiSelectDropdown/MultiSelectDropDown";
import {Avatar, Pagination, PaginationItem} from "@mui/material";
import {buildStyles, CircularProgressbarWithChildren} from "react-circular-progressbar";
import {capitalizeFirstLetter} from "../../../../../../../utils/capitalize-first-letter";
import Tooltip from "../../../../../../../services/tooltip.service";
import {
   DashboardDto,
   DashBoardRequestDto,
   SideBarDto,
   SKILL_FILTER,
   SKILL_VIEW_FILTER,
   SkillsDashboardDto
} from "../../../../../../../types/ManagerDashboardData";
import RadialSeparators from "../../../../../../../utils/RadialSeparators";
import {SKILL_INVENTORY_TYPE} from "../../../../../../../types/SkillsInventoryData";
import {ProjectDto} from "../../../../../../../types/Projects";
import AuthContext from "../../../../../../../store/auth-context";
import {animated, useTransition} from "react-spring";
import {capitalizeOnlyFirstLetter} from "../../../../../../../utils/capitalize-only-first-letter";

const SkillsDetailsComponent: React.FC<{
   data: DashboardDto,
   sideBarData?: SideBarDto,
   dashboardRequest: (request: DashBoardRequestDto) => void,
   sideBarRequest: (skill: SkillsDashboardDto, skillFilter: SKILL_VIEW_FILTER) => void,
   closeSideBar: () => void,
   skillInventoryType?: SKILL_INVENTORY_TYPE,
   projectsData?: ProjectDto[],
   reminderUserEmail: (skillId: number, employeeIds: number[]) => void
   categories: string[],
   sources?: string[],
   addToProject?: (employeeId: number, req: DashBoardRequestDto, skill: SkillsDashboardDto) => void,
}> = props => {

   const [activeFilter, setActiveFilter] = useState<SKILL_FILTER>();
   const [selectedCategories, setSelectedCategories] = useState<{name: string, code: string}[]>([]);
   const [selectedProjects, setSelectedProjects] = useState<{name: string, code: string}[]>([]);
   const [selectedSources, setSelectedSources] = useState<{name: string, code: string}[]>([]);
   const [searchSkillInput, setSearchSkillInput] = useState('');
   const [reminderUsers, setReminderUsers] = useState<{skillId: number, employeeId: number}[]>([]);
   const [loadingState, setLoadingState] = useState<{skillId: number, loading: boolean}>({skillId: -1, loading: false});
   const [readMore, setReadMore] = useState(false);

   const employeeSources = props.skillInventoryType ? props.skillInventoryType === SKILL_INVENTORY_TYPE.PROJECT ? Object.values(SKILL_VIEW_FILTER).slice(0, 4) : Object.values(SKILL_VIEW_FILTER).slice(0, 3) : Object.values(SKILL_VIEW_FILTER);
   const sourcesList = props.sources?.map(source => ({name: source.replace('_', ' '), code: source}));
   const categoriesList = props.categories.filter(c => c).map(category => ({name: capitalizeFirstLetter(category), code: category.slice(0, 2).toUpperCase()}));
   const projectsList = props.projectsData?.map(project => ({
      name: project.name,
      code: project.id.toString()
   }));

   const transitions = useTransition(props.sideBarData, {
      from: { transform: 'translateY(5%)', opacity: 0 },
      enter: { transform: 'translateY(0%)', opacity: 1 },
      config: { duration: props.sideBarData?.skill.skill.id === loadingState.skillId && !loadingState.loading ? 0 : 500 }
   });

   const authStore = useContext(AuthContext);

   useEffect(() => {
      if (props.sideBarData?.skill.skill.id === loadingState.skillId) {
         setReadMore(false);
         setLoadingState(prevState => {
            return {
               ...prevState,
               loading: false
            }
         })
      }
      if (!props.sideBarData && loadingState.skillId !== -1) {
         setReadMore(false);
         setLoadingState({skillId: -1, loading: false});
      }
   }, [props.sideBarData]);

   return (
      <>
         <div className={styles['skill-details-component']}>
            <div className="skills-details-container">

               <div className="skill-details-header">
                  <h3 className="header-3 skills-no">Skills: {props.data.paginationData.totalCount}</h3>

                  <div className="buttons-container">
                     {
                        Object.values(SKILL_FILTER)
                           .filter(skill => !(skill === SKILL_FILTER.MISSING && props?.skillInventoryType === SKILL_INVENTORY_TYPE.COMPANY))
                           .map(skill => {
                           const name = skill === SKILL_FILTER.NEW ? 'To review' : skill;
                           return <button key={skill} className={`button button-filter-skills 
                           ${skill === activeFilter ? 'active' : ''}`} onClick={() => changeActiveFilter(skill)}>{capitalizeOnlyFirstLetter(name)} <i className="fa-solid fa-xmark"></i></button>
                        })
                     }
                     <MultiSelectDropDown options={categoriesList} selectedOptions={selectedCategories} onChange={categories => changeFilter('categories', categories)}  selectedName={'Categories'}/>
                     {
                        projectsList && projectsList.length > 0 && <MultiSelectDropDown options={projectsList} selectedOptions={selectedProjects} onChange={projects => changeFilter('projects', projects)} selectedName={'Projects'} />
                     }
                     {
                        sourcesList && sourcesList.length > 0 && <MultiSelectDropDown options={sourcesList} selectedOptions={selectedSources} onChange={sources => changeFilter('sources', sources)} selectedName={'Sources'} />
                     }
                  </div>
               </div>

               <div className="search-wrapper u-margin-top-s">
                  <span>Search for a skill:</span>
                  <input type="text" value={searchSkillInput} onChange={event => setSearchSkillInput(event.target.value)} />
                  <button onClick={() => apiCall(1)} className="button button-primary u-margin-left-xs">Search</button>
                  {
                     searchSkillInput.trim().length > 0 &&
                     <button onClick={() => {
                        setSearchSkillInput('');
                        apiCall(1, true)
                     }} className="button button-tertiary u-margin-left-xs">Reset search</button>

                  }
               </div>

               <main className="skill-details-container">
                  <div className="skill-card-list-container">
                     {
                        props.data.skillsDashboard.length > 0 ?
                           props.data.skillsDashboard.map((skill, index) => {
                              const filter = skill.lastEmployees.length === 0 ? props?.skillInventoryType === SKILL_INVENTORY_TYPE.COMPANY ? SKILL_VIEW_FILTER.LEARNING : SKILL_VIEW_FILTER.UPSKILL : SKILL_VIEW_FILTER.NEW;
                              return (
                                 <div onClick={() => sideBarRequest(skill, filter)}
                                      className={`skill-card ${props.sideBarData ? props.sideBarData.skill.skill.id === skill.skill.id && loadingState.skillId === skill.skill.id ? 'active' : '' : ''} ${loadingState.skillId === skill.skill.id && loadingState.loading ? 'loading' : ''}`} key={index}>
                                    <div className="skill-card-name-container">
                                       <h4 className="skill-card-name header-4">{capitalizeFirstLetter(skill.skill.synonym ? skill.skill.synonym.name : skill.skill.name)}</h4>
                                       <div className="skill-card-tooltip">
                                          <Tooltip tooltipId={`tooltip-skills-information_${skill.skill.id}`}
                                                   place="right"
                                                   backgroundColor="#101319"
                                                   borderColor='#5C5F6A !important'
                                                   border={true}
                                                   content={
                                                      <div>{skill.skill.description}</div>
                                                   }
                                                   className={'custom-tooltip'}>
                                             <i className="fa-solid fa-circle-question u-margin-left-xs"></i>
                                          </Tooltip>
                                       </div>
                                    </div>
                                    <div
                                       className={`skill-card-learning ${skill.learnersNumber === 0 ? 'opacity' : ''}`}>
                                       <Tooltip tooltipId={`learning_users_${skill.skill.id}`}
                                                place="right"
                                                backgroundColor="#101319"
                                                borderColor='#5C5F6A !important'
                                                border={true}
                                                content={
                                                   <div>People that currently are learning this skill.</div>
                                                }
                                                className={'custom-tooltip'}>
                                          <>
                                             <img
                                                src='/assets/images/menu-icons/new-icons-active/learning-hub-icon.svg'
                                                alt={'Learning image'}/>
                                          </>
                                       </Tooltip>
                                       <span>{skill.learnersNumber}</span>
                                    </div>
                                    <div
                                       className={`avatars-container ${skill.leftEmployeesNumber === 0 ? 'move-right' : ''}`}>
                                    {
                                          skill.lastEmployees.length > 0 ?
                                             skill.lastEmployees.map((employee, index) => {
                                                return (
                                                   <div className="avatar" key={index}>
                                                      <CircularProgressbarWithChildren
                                                         value={employee.validationFormulaScore}
                                                         strokeWidth={8}
                                                         styles={buildStyles({
                                                            pathColor: '#A0A8E5',
                                                            trailColor: '#191D24',
                                                            strokeLinecap: "butt"
                                                         })}>
                                                         {
                                                            employee.profilePicture ? <img className={'avatar-image'} src={employee.profilePicture}  alt={'Employee Picture'}/> :
                                                               <Avatar sx={{backgroundColor: employee.color}} className={'avatar-image'}>{employee.name.slice(0,1).toUpperCase()}</Avatar>
                                                         }
                                                      </CircularProgressbarWithChildren>
                                                   </div>
                                                );
                                             }) :
                                             <div className="missing-button">Missing</div>
                                       }
                                       {
                                          skill.leftEmployeesNumber > 0 &&
                                          <div className="avatar">
                                             <CircularProgressbarWithChildren
                                                value={100}
                                                strokeWidth={6}
                                                styles={buildStyles({
                                                   pathColor: '#2F303CFF',
                                                   trailColor: '#191D24',
                                                   strokeLinecap: "butt"
                                                })}><Avatar sx={{backgroundColor: "#0E0C19FF"}} className={'avatar-image'}>{skill.leftEmployeesNumber}+</Avatar></CircularProgressbarWithChildren>
                                          </div>
                                       }
                                    </div>
                                 </div>
                              );
                           }) :
                           <div className="no-skills">
                              <h1 className="header-1">Oops! Skills Not Found.</h1>
                              <img src="/assets/images/global-icons/no-skills-icon.svg" alt="No skills image" />
                              <p className={'text-surface-30'}>Double-check your dashboard or no skills have been added by the company or project..</p>
                           </div>
                     }
                  </div>

                  {
                     transitions((style, item) => item ?
                     <animated.div className="skill-info-container u-margin-left-m" style={style}>

                        <div className="skill-info-data">
                           <div className="skill-info-header u-margin-bottom-s">
                              <h2 className="header-2">{capitalizeFirstLetter(item.skill.skill.name)}</h2>
                              {
                                 item.skill.lastEmployees.length === 0 &&
                                 <div className={'missing-button u-margin-left-xs'}>Missing</div>
                              }
                              <div className="close-button" onClick={props.closeSideBar}><i
                                 className="fa-solid fa-xmark"></i></div>
                           </div>
                           <div className="category-row u-margin-bottom-s"><span>Category: </span>
                              <button className={'button button-filter-skills u-margin-left-xxs'}>{capitalizeFirstLetter(item.skill.skill.category)}</button>
                           </div>

                           {
                              item.skillPlusInformation.projects && item.skillPlusInformation.projects.length > 0 &&
                              <div className="category-row projects-list u-margin-bottom-s"><span>Projects: </span>
                                 <div className={'row-buttons'}>
                                    {
                                       item.skillPlusInformation.projects.map(project => {
                                          return (
                                             <button key={project.id}
                                                     className={'button button-filter-skills u-margin-left-xxs u-margin-bottom-xs'}>{project.name}</button>
                                          );
                                       })
                                    }
                                 </div>
                              </div>
                           }

                           {
                              item.skillPlusInformation.sources && item.skillPlusInformation.sources.length > 0 &&
                              <div className="category-row u-margin-bottom-s"><span>Sources: </span>
                                 <div className={'row-buttons'}>
                                    {
                                       item.skillPlusInformation.sources.map(source => {
                                          return (
                                             <button key={source.source} className={'button button-filter-skills u-margin-left-xxs'}>{source.source.replace("_", " ")}</button>
                                          );
                                       })
                                    }
                                 </div>
                              </div>
                           }

                           <p className="skill-info-text u-margin-bottom-s">
                              {
                                 item.skill.skill.description.length > 400 && !readMore ? <>{item.skill.skill.description.slice(0, 400)} <button className="button button-tertiary" onClick={() => setReadMore(true)}>Read more</button></> :  item.skill.skill.description
                              }
                           </p>

                           <div className="buttons-container u-margin-bottom-s">
                              {
                                 employeeSources.map(skillFilter => {
                                    const missing = props.sideBarData!.skill.lastEmployees.length === 0 && [SKILL_VIEW_FILTER.NEW, SKILL_VIEW_FILTER.APPROVED].includes(skillFilter);
                                    const name = (skillFilter === SKILL_VIEW_FILTER.NEW ? 'To review' : skillFilter).replace('_', ' ');
                                    return (
                                       <button
                                          key={skillFilter}
                                          disabled={missing}
                                          className={`button button-filter-skills ${props.sideBarData!.activeSkillFilter === skillFilter ? 'active' : ''}`}
                                          onClick={() => {
                                             !missing && props.sideBarRequest(props.sideBarData!.skill, skillFilter)
                                          }}>{capitalizeOnlyFirstLetter(name)}</button>
                                    )
                                 })
                              }
                           </div>

                           <div className="approved-employees u-margin-bottom-xxs">
                              <img src={getImageLink(item.activeSkillFilter)} alt="Filter image"/>
                              <span
                                 className={'u-margin-left-xs u-margin-right-xxs'}>{capitalizeFirstLetter(item.activeSkillFilter.replace("_", " "))}</span>
                              <span className="count">({item.skillPlusInformation.employees.length})</span>
                           </div>
                           <p className="filter-message">{getMessages(item.activeSkillFilter)}</p>

                           <div className="employee-card-container u-margin-top-s">
                              {
                                 item.skillPlusInformation.employees.length > 0 ?
                                    item.skillPlusInformation.employees.map((employee, index) => {
                                       return (
                                          <div className="employee-card" key={index}>
                                             <div className="avatar">
                                                {
                                                   (item.activeSkillFilter === SKILL_VIEW_FILTER.LEARNING ||
                                                      item.activeSkillFilter === SKILL_VIEW_FILTER.UPSKILL) ?
                                                      <div
                                                         className={`employee-image-container`}>
                                                         <CircularProgressbarWithChildren
                                                            value={100}
                                                            strokeWidth={8}
                                                            styles={buildStyles({
                                                               pathColor: '#191D24',
                                                               trailColor: '#191D24',
                                                               strokeLinecap: "butt"
                                                            })}>
                                                            <RadialSeparators
                                                               count={12}
                                                               style={{
                                                                  background: "#fff",
                                                                  width: "5px",
                                                                  // This needs to be equal to props.strokeWidth
                                                                  height: `${9}%`
                                                               }}
                                                            />
                                                            {
                                                               employee.profilePicture ?
                                                                  <img className={'avatar-image'}
                                                                       src={employee.profilePicture}
                                                                       alt="picture"/> :
                                                                  <Avatar sx={{backgroundColor: employee.color}}
                                                                          className={'avatar-image'}>{employee.name.slice(0, 1).toUpperCase()}</Avatar>
                                                            }
                                                         </CircularProgressbarWithChildren>
                                                      </div> :
                                                      <CircularProgressbarWithChildren
                                                         value={employee.validationFormulaScore}
                                                         strokeWidth={8}
                                                         styles={buildStyles({
                                                            pathColor: '#A0A8E5',
                                                            trailColor: '#191D24',
                                                            strokeLinecap: "butt"
                                                         })}>
                                                         {
                                                            employee.profilePicture ?
                                                               <img className={'avatar-image'}
                                                                    src={employee.profilePicture}
                                                                    alt={'Employee Picture'}/> :
                                                               <Avatar sx={{backgroundColor: employee.color}}
                                                                       className={'avatar-image'}>{employee.name.slice(0, 1).toUpperCase()}</Avatar>
                                                         }
                                                      </CircularProgressbarWithChildren>
                                                }
                                             </div>
                                             <div className="employee-card-name u-margin-left-s">{employee.name.concat(' ').concat(employee.surname)}</div>
                                             {
                                                props.sideBarData?.activeSkillFilter === SKILL_VIEW_FILTER.NEW &&
                                                !reminderUsers.some(user =>
                                                   user.skillId === props.sideBarData!.skill.skill.id && user.employeeId === employee.id) &&
                                                <button className="button button-tertiary" onClick={() => {
                                                   props.reminderUserEmail(props.sideBarData!.skill.skill.id, [employee.id]);
                                                   setReminderUsers(prevState => [...prevState, {skillId: props.sideBarData!.skill.skill.id, employeeId: employee.id}]);
                                                }}>Remind to accept</button>
                                             }
                                             {
                                                props.sideBarData?.activeSkillFilter === SKILL_VIEW_FILTER.COMPANY_WIDE && authStore.userData.isManager &&
                                                <button onClick={() => {
                                                   props.addToProject && props.addToProject(employee.id, getRequestData(props.data.paginationData.page), props.sideBarData!.skill);
                                                }} className="button button-tertiary">Add to project</button>
                                             }
                                          </div>
                                       )
                                    }) :
                                    <div className={'skill-info-text u-margin-bottom-m'}>No employees were found.</div>
                              }
                           </div>
                        </div>
                     </animated.div> : null )}
               </main>

               <div className="pagination-row">
                  <div className="categories">
                     <div className="categories-title">Skills per page:</div>
                     <div className="categories-number">{props.data.skillsDashboard.length}</div>
                  </div>
                  <Pagination defaultPage={1} page={props.data.paginationData.page}
                              count={props.data.paginationData.totalPages} variant="outlined"
                              shape="rounded"
                              renderItem={(item) => <PaginationItem {...item} />} onChange={clickedOnPage}/>
               </div>

            </div>
         </div>
      </>
   );

   function sideBarRequest(skill: SkillsDashboardDto, filter: SKILL_VIEW_FILTER) {
      if (skill.skill.id !== loadingState.skillId && !loadingState.loading) {
         setLoadingState({skillId: skill.skill.id , loading: true});
         props.sideBarRequest(skill, filter);
      }
   }

   function getImageLink(skillFilter: SKILL_VIEW_FILTER) {
      const object = {
         [SKILL_VIEW_FILTER.NEW]: '/assets/images/manager-dashboard-icons/extracted-icon.svg',
         [SKILL_VIEW_FILTER.APPROVED]: '/assets/images/manager-dashboard-icons/approved-icon.svg',
         [SKILL_VIEW_FILTER.LEARNING]: '/assets/images/manager-dashboard-icons/learning-icon.svg',
         [SKILL_VIEW_FILTER.UPSKILL]: '/assets/images/manager-dashboard-icons/upskill-icon.svg',
         [SKILL_VIEW_FILTER.COMPANY_WIDE]: '/assets/images/manager-dashboard-icons/company-wide-icon.svg',
      }
      return object[skillFilter];
   }

   function getMessages(skillFilter: SKILL_VIEW_FILTER) {
      const object = {
         [SKILL_VIEW_FILTER.NEW]: 'Employees whose AI-extracted skill hasn\'t been approved.',
         [SKILL_VIEW_FILTER.APPROVED]: 'Employees who have confirmed their skill proficiency.',
         [SKILL_VIEW_FILTER.LEARNING]: 'Employees currently in the process of learning the skill.',
         [SKILL_VIEW_FILTER.UPSKILL]: 'Upskill: Employees identified as best candidates for learning this skill.',
         [SKILL_VIEW_FILTER.COMPANY_WIDE]: 'Company-wide: Employees possessing the skill but not assigned to this project.',
      }
      return object[skillFilter];
   }

   function clickedOnPage(_event: React.ChangeEvent<unknown>, page: number) {
      apiCall(page);
   }

   function changeActiveFilter(filter: SKILL_FILTER) {
      const skillFilter = activeFilter === filter ? undefined : filter;
      setActiveFilter(skillFilter);
      apiCall(1, undefined, skillFilter || null);
   }

   function changeFilter(type: 'categories' | 'projects' | 'sources', items: { name: string, code: string }[]) {
      switch (type) {
         case 'categories':
            setSelectedCategories(items);
            break;
         case "projects":
            setSelectedProjects(items);
            break;
         case "sources":
            setSelectedSources(items);
            break;
      }
      const filterParams = {
         categories: type === 'categories' ? items.map(c => c.name) : undefined,
         projectIds: type === 'projects' ? items.map(p => +p.code) : undefined,
         sources: type === 'sources' ? items.map(s => s.code) : undefined
      };
      apiCall(1, undefined, undefined, filterParams);
   }

   function getRequestData(page: number, searchFlag?: boolean, skillFilter?: SKILL_FILTER | null, filterParams?: {
      categories?: string[],
      projectIds?: number[],
      sources?: string[]
   }) {
      const filterType = skillFilter ? skillFilter : skillFilter === null ? undefined : activeFilter;
      const projectIds = filterParams?.projectIds ?? selectedProjects.map(p => +p.code);
      const categories = filterParams?.categories ?? selectedCategories.map(c => c.name);
      const sources = filterParams?.sources ?? selectedSources.map(s => s.code);

      const req: DashBoardRequestDto = {
         page: page,
         pageSize: props.data.paginationData.pageSize,
         searchTerm: searchFlag ? undefined : searchSkillInput.trim().length > 0 ? searchSkillInput : undefined
      };

      if (filterType || (projectIds && projectIds.length > 0) || (categories && categories.length > 0) || (sources && sources.length > 0)) {
         req.dashboardFilter = {
            dashboardFilterType: filterType,
            projectIds: projectIds.length > 0 ? projectIds : undefined,
            categories: categories.length > 0 ? categories : undefined,
            sources: sources.length > 0 ? sources : undefined,
         }
      }
      return req;
   }

   function apiCall(page: number, searchFlag?: boolean, skillFilter?: SKILL_FILTER | null, filterParams?: {
      categories?: string[],
      projectIds?: number[],
      sources?: string[]
   }) {
      props.dashboardRequest(getRequestData(page, searchFlag, skillFilter, filterParams));
   }
}

export default SkillsDetailsComponent;