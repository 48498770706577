import style from './TooltipModal.module.scss';
import React, {useEffect, useState} from "react";
import {ToolDto, ToolFeedbackUses, ToolInformation} from "../../../../../types/AugmentationData";
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";
import SpinnerButton from "../../../../../ui/SpinnerButton/SpinnerButton";
import {getFormattedTime} from "../../../../../utils/get-time";
import GaugeComponent from "react-gauge-component";
import {animated, useSpring} from "react-spring";

const TooltipModal: React.FC<{
   tool: {tool: ToolDto, information: ToolInformation, errorLoading: boolean, impactScore?: {old: number, new: number}},
   close: () => void,
   submit: (tool: ToolDto, option: ToolFeedbackUses) => void,
}> = props => {

   const [showMoreDescription, setShowMoreDescription] = useState(false);
   const [checkedUsesInformation, setCheckedUsesInformation] = useState<ToolFeedbackUses>();
   const [loadingData, setLoadingData] = useState(false);
   const [showValue, setShowValue] = useState(false);

   const { number } = useSpring({
      from: { number:  props.tool.impactScore?.old },
      to: { number: props.tool.impactScore?.new },
      config: { duration: 5000 },
      delay: 1500
   });

   useEffect(() => {
      if (props.tool.errorLoading) {
         setLoadingData(false);
      }
   }, [props.tool.errorLoading]);

   useEffect(() => {
      if (props.tool.impactScore && props.tool.impactScore.old !== props.tool.impactScore.new) {
         setTimeout(() => {
            setShowValue(true);
         }, 6500);
      }
   }, [props.tool.impactScore]);

   return (
      <>
         <div className={style['tooltip-modal']}>
            <div className="tool-card">
               <div className="tool-header">
                  <h2 className="header-2">{props.tool.tool.name}</h2>
                  <i className="fa-solid fa-xmark" onClick={props.close}></i>
               </div>
               <main className="tool-content">
                  <div className="tool-wrapper">
                     <div className="tool-wrapper-title">
                        <h4 className="header-4">Description</h4>
                        <i className="fa-solid fa-circle-info text-primary-20"></i>
                     </div>
                     <div className="tool-description">
                        {
                           props.tool.information.description.length > 300 && !showMoreDescription ?
                              <div>
                                 <span>{props.tool.information.description.slice(0, 300)}...</span>
                                 <button onClick={() => setShowMoreDescription(prevState => !prevState)}
                                         className="button button-tertiary">Show more</button>
                              </div> :
                              <div>
                                 <span>{props.tool.information.description}</span>
                                 {
                                    showMoreDescription &&
                                    <button onClick={() => setShowMoreDescription(prevState => !prevState)} className="button button-tertiary">Show less</button>
                                 }
                              </div>
                        }
                     </div>
                  </div>

                  <div className="tool-wrapper">
                     <div className="tool-wrapper-title">
                        <h4 className="header-4">Tool URL</h4>
                        <i className="fa-solid fa-link text-accent-40"></i>
                     </div>
                     <div className="tool-url">
                        {
                           props.tool.information.websiteUrl ?
                              <a href={props.tool.information.websiteUrl} target={"_blank"}>{props.tool.information.websiteUrl}</a>
                              :
                              <>
                                 <i className="fa-solid fa-ban"></i>
                                 <span>No available URL</span>
                              </>
                        }
                     </div>
                  </div>

                  <div className="tool-wrapper">
                     <div className="tool-wrapper-title">
                        <h4 className="header-4">Hot sauce information</h4>
                        <i className="fa-solid fa-pepper-hot text-red-50"></i>
                     </div>
                     <div className="tool-hot-sauce">
                        {
                           !props.tool.information.hotSauce &&
                           <div className="tool-hot-sauce-item">
                              <i className="fa-solid fa-ban"></i>
                              <span>This information is hidden for now. Tap 'Notify Me' under Tips & Recommendations to get it in the next version!</span>
                           </div>
                        }
                        {
                           props.tool.information.hotSauce && props.tool.information.hotSauce.toolPros &&
                           <div className={'tool-hot-sauce-item pc'}>
                              <div className="pc-title">
                                 <i className="fa-solid fa-check"></i>
                                 <span>Tool Pros:</span>
                              </div>
                              <div className="pc-wrapper">
                              {
                                    props.tool.information.hotSauce.toolPros.trim().split("-")
                                    .filter(s => s.trim().length > 0).map(s => {
                                       return (
                                          <div key={s}>
                                             <i className="fa-solid fa-circle"></i>
                                             <span>{s}</span>
                                          </div>
                                       )
                                    })
                                 }
                              </div>
                           </div>
                        }
                        {
                           props.tool.information.hotSauce && props.tool.information.hotSauce.toolCons &&
                           <div className={'tool-hot-sauce-item pc'}>
                              <div className="pc-title">
                                 <i className="fa-solid fa-xmark"></i>
                                 <span>Tool Cons:</span>
                              </div>
                              <div className="pc-wrapper">
                                 {
                                    props.tool.information.hotSauce.toolCons.trim().split("-")
                                       .filter(s => s.trim().length > 0).map(s => {
                                       return (
                                          <div key={s}>
                                             <i className="fa-solid fa-circle"></i>
                                             <span>{s}</span>
                                          </div>
                                       )
                                    })
                                 }
                              </div>
                           </div>
                        }
                        {
                           props.tool.information.hotSauce && props.tool.information.hotSauce.toolPricingCategory &&
                           <div className={'tool-hot-sauce-item'}>
                              <i className="fa-solid fa-tag"></i>
                              <span>Tool Pricing Category:</span>
                              <span>{props.tool.information.hotSauce.toolPricingCategory}</span>
                           </div>
                        }
                        {
                           props.tool.information.hotSauce && props.tool.information.hotSauce.toolPricingDescription &&
                           <div className={'tool-hot-sauce-item'}>
                              <i className="fa-solid fa-tag"></i>
                              <span>Tool Pricing Description:</span>
                              <span>{props.tool.information.hotSauce.toolPricingDescription}</span>
                           </div>
                        }
                        {
                           props.tool.information.hotSauce && props.tool.information.hotSauce.toolRating &&
                           <div className={'tool-hot-sauce-item'}>
                              <i className="fa-solid fa-star-half-stroke"></i>
                              <span>Tool Rating:</span>
                              <span>{props.tool.information.hotSauce.toolRating}</span>
                           </div>
                        }
                     </div>
                  </div>

                  <div className="tool-wrapper">
                     <div className="tool-wrapper-title">
                        <h4 className="header-4">Uses of the Tool</h4>
                        <i className="fa-solid fa-check-double text-green-50"></i>
                     </div>
                     {
                        props.tool.information.feedback ?
                           props.tool.impactScore ?
                              <div className={'tool-feedback gauge'}>
                                 <div className="tool-info">
                                    <i className="fa-solid fa-circle-question"></i>
                                    {getToolSubmitText(props.tool.information.feedback.feedback)}
                                 </div>
                                 {
                                    props.tool.impactScore.old !== props.tool.impactScore.new &&
                                    <div className="gauge-wrapper">
                                       <GaugeComponent
                                          style={{width: '100%', height: '100%'}} // Make the gauge fill its container
                                          arc={{
                                             cornerRadius: 1,
                                             width: 0.2,
                                             subArcs: [
                                                {
                                                   limit: 15,
                                                   color: '#A2CEB4FF',
                                                   showTick: true
                                                },
                                                {
                                                   limit: 35,
                                                   color: '#F8D38FFF',
                                                   showTick: true
                                                },
                                                {
                                                   limit: 100,
                                                   color: '#F2AC9DFF',
                                                   showTick: true
                                                },
                                             ],
                                          }}
                                          value={showValue ? props.tool.impactScore.new : props.tool.impactScore.old}
                                          labels={{
                                             valueLabel: {hide: true}
                                          }}
                                       />
                                       <div className="gauge-label">
                                          <animated.div className={"gauge-percentage"}>
                                             {number!.to((n) => n.toFixed(0))}
                                          </animated.div>
                                          <span>%</span>
                                       </div>
                                    </div>
                                 }
                              </div> :
                              <div className={'tool-feedback'}>
                                 <div className="tool-feedback-wrapper">
                                    <i className="fa-solid fa-circle-info"></i>
                                    <div
                                       className="tool-feedback-title">{getToolLabel(props.tool.information.feedback.feedback)}</div>
                                 </div>
                                 {
                                    props.tool.information.feedback.startUsingDate &&
                                    <div className="tool-feedback-wrapper">
                                       <i className="fa-solid fa-calendar-days"></i>
                                       <span>Date saved: {getFormattedTime(props.tool.information.feedback.startUsingDate.toString())}</span>
                                    </div>
                                 }
                              </div> :
                           <FormControl className="tool-choices">
                              <RadioGroup row name={"tool-choices"} className={'tool-choices-group'}>
                                 {
                                    Object.values(ToolFeedbackUses).map(option => {
                                       return (
                                          <FormControlLabel key={option} value={option} className={"form-control-mui"}
                                                            onClick={() => setCheckedUsesInformation(option)}
                                                            control={<Radio className={"mui-radio"}/>}
                                                            label={getToolLabel(option)}/>
                                       )
                                    })
                                 }
                              </RadioGroup>
                              <SpinnerButton initialDisabled={!checkedUsesInformation}
                                             cssClasses={"button button-primary"} title={"Submit"}
                                             dataLoading={loadingData} onClickCallback={submitFeedbackForm}/>
                           </FormControl>
                     }
                  </div>
               </main>
            </div>
         </div>
      </>
   );

   function getToolSubmitText(toolUses: ToolFeedbackUses) {
      switch (toolUses) {
         case ToolFeedbackUses.ALREADY_USING:
            return (
               <p>Great to see you're making the most of the tools! Watch as your AI Impact Score improves
                  {
                     (props.tool.impactScore && props.tool.impactScore.new !== props.tool.impactScore.old) ? "—here’s a quick animation showing the progress you're making." : "."
                  }
               </p>
            );
         case ToolFeedbackUses.START_USING:
            return (
              <p>Awesome! We’ve saved your interest and will check back in 7 days to see how it's working for you. Looking forward to hearing your thoughts!</p>
            );
         case ToolFeedbackUses.IS_NOT_FOR_ME:
            return (
              <p>Thanks for your feedback! You've helped Teamo learn from this, and we're using it to make smarter suggestions for you in the future. Every bit helps us improve!</p>
            );
         default:
            return '';
      }
   }

   function submitFeedbackForm() {
      if (checkedUsesInformation) {
         props.submit(props.tool.tool, checkedUsesInformation);
         setLoadingData(true);
      }
   }

   function getToolLabel(toolUses: ToolFeedbackUses) {
      switch (toolUses) {
         case ToolFeedbackUses.ALREADY_USING:
            return (
               <div className={'tool-label'}>I’m already using this tool</div>
            );
         case ToolFeedbackUses.START_USING:
            return (
               <div className={'tool-label'}>I want to start using this tool</div>
            );
         case ToolFeedbackUses.IS_NOT_FOR_ME:
            return (
               <div className={'tool-label'}>This tool isn’t for me</div>
            );
         default:
            return '';
      }
   }
}

export default TooltipModal;