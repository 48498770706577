import React, {useContext} from "react";
import {animated, useSpring} from "react-spring";
import style from "./NamePage.module.scss";
import AuthContext from "../../../../../store/auth-context";
import axios, {AxiosRequestConfig} from "axios";
import {ErrorResponseDto} from "../../../../../types/ErrorData";
import {showNotification} from "../../../../../ui/Toast/ToastNotification";
import useApiService from "../../../../../services/api.service";

const NamePage: React.FC<{
   changeSection: Function;
}> = props => {

   const authStore = useContext(AuthContext);
   const {refreshToken} = useApiService();

   const fadeStyles = useSpring({
      from: {opacity: 0},
      to: {opacity: 1},
      config: {duration: 750},
   });

   const [fullName, setFullName] =
      React.useState<{ name: string, surname: string }>({name: authStore.userData.fullName.split(" ")[0], surname: authStore.userData.fullName.split(" ")[1]});

   return (
      <>
         <animated.div className={style["name-page"]} style={fadeStyles}>
            <h1 className="header-1 u-margin-bottom-m">What is your name?</h1>

            <div className="form">
               <label htmlFor={"nameInput"}>Name</label>
               <input value={fullName.name}
                      onChange={e => setFullName(prevState => ({name: e.target.value, surname: prevState.surname}))}
                      id={"nameInput"} type="text" className={"u-margin-bottom-m"}/>

               <label htmlFor={"surnameInput"}>Surname</label>
               <input value={fullName.surname}
                      onChange={e => setFullName(prevState => ({name: prevState.name, surname: e.target.value}))}
                      id={"surnameInput"} type="text"/>

               <button disabled={!checkFullName()} className="button button-primary u-margin-top-big" onClick={() => checkFullName() && updateName(authStore.userData.accessToken)}>Next <i className="fa-solid fa-arrow-right"></i></button>

            </div>
         </animated.div>
      </>
   )

   function updateName(accessToken: string) {
      const updateNameURL = process.env.REACT_APP_PUBLIC_URL + "/employee/update-employee-name";

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      const data = {
         firstName: fullName.name,
         lastName: fullName.surname
      }

      axios
         .post(updateNameURL, data, {headers})
         .then((response$) => {
            authStore.changeDisplayName(`${fullName.name} ${fullName.surname}`);
            props.changeSection();
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     updateName(response$.data.accessToken);
                  })
            }
            else {
               showNotification('warning', error$.response.data.message);
            }
         })
   }

   function checkFullName() {
      return fullName.name.trim().length > 0 && fullName.surname.trim().length > 0;
   }
}

export default NamePage;