import { useContext } from 'react';
import axios from 'axios';

import AuthContext from '../store/auth-context';
import {SendRegisterData} from "../types/LoginRegisterData";

const useApiService = () => {
  const authStore = useContext(AuthContext);

  // User login
  async function tryLogin (user: string, password: string ) {
    let url = process.env.REACT_APP_PUBLIC_URL + '/auth/login';

    let promise = new Promise((resolve, reject) => {
      axios
        .post(url, {
          usernameOrEmail: user,
          password: password
        })
        .then((response$) => {
          resolve(response$);
        })
        .catch((error$) => {
          reject(error$);
        })
    });

    let result = await promise;
    return result;
  }

  // User register
  async function tryRegister(registerData: SendRegisterData) {
    let url = process.env.REACT_APP_PUBLIC_URL + '/auth/register-individual-user';

    const data = {
      registrationCode: registerData.data.registerCode,
      fullName: registerData.data.fullName,
      email: registerData.data.username,
      password: registerData.data.password,
      companyName: registerData.data.company
    }

    let promise = new Promise((resolve, reject) => {
      axios
         .post(url, data)
         .then((response$) => {
           resolve(response$);
         })
         .catch((error$) => {
           reject(error$);
         })
    });

    let result = await promise;
    return result;
  }


  // Refresh Token
  async function refreshToken(refreshToken: string ) { 
    let url = process.env.REACT_APP_PUBLIC_URL + '/auth/refresh-token';
    url += '?refreshToken=' + refreshToken;
    
    let promise = new Promise((resolve, reject) => {
      axios
        .post(url)
        .then((response$) => {
          resolve(response$);
        })
        .catch((err) => {
          authStore.logout();
        })
    });

    let result = await promise;
    return result;
  }

  // Reset Password
  async function resetPassword(email: string ) {
    let url = process.env.REACT_APP_PUBLIC_URL + '/auth/reset-password';
    
    let promise = new Promise((resolve, reject) => {
      axios
        .post(url, {
          email: email
        })
        .then((response$) => {
          resolve(response$);
        })
        .catch((error$) => {
          reject(error$)
        })
    });

    let result = await promise;
    return result;
  }

  return {
    tryLogin,
    tryRegister,
    refreshToken,
    resetPassword
  }
}

export default useApiService;