import { useState, FormEvent } from 'react';
import styles from './ResetPasswordPage.module.scss';
import SpinnerButton from '../../ui/SpinnerButton/SpinnerButton';
import useApiService from '../../services/api.service';
import { NavLink } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { showNotification } from '../../ui/Toast/ToastNotification';
import {ErrorResponseDto} from "../../types/ErrorData";

const ResetPasswordPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [emailInputTouched, setEmailInputTouched] = useState(false);
  const [error, setError] = useState('');
  const [isDataLoading, setIsDataLoading] = useState(false);
  const {resetPassword } = useApiService();
  const [mailSent, setMailSent] = useState(false);
  
  return (
    <div className={styles['reset-password-page']}>
      <div className='main-container'>
        <div className='card'>
          <div className='card-header'>
             <img src="/assets/images/global-icons/teamlift-logo-white.svg" className="logo" alt="logo" />
          </div>
          <div className='card-body'>
            <div className='reset-password-form'>
              { !mailSent ?
                <>
                  <p>Enter the email address you used when you joined.<br/>We will send you a new password on that email.</p>
                  <form onSubmit={submitForm}>
                    <div className="mb-4 input-wrapper">
                      <label htmlFor="email" className="form-label">Email address</label>
                      <div className='input-icon'>
                        <span className='icon-container'>
                        <i className="fa-solid fa-envelope"></i>
                        </span>
                        <input type="text" className={`form-control ${email.trim().length === 0 && emailInputTouched ? "error" : ""}`}
                          id="email" value={email} onChange={emailInputHandler} autoComplete='off' placeholder='Enter your email address' />
                      </div>
                    </div>
                    { 
                      error !== "" && <div className="alert alert-danger" role="alert">{error}</div>
                    }
                    <div className="d-grid gap-2 col-12 col-sm-8 mx-auto">
                      {
                        <SpinnerButton title='Send New Password' buttonType='submit' cssClasses='button button-primary u-margin-top-xxs' dataLoading={isDataLoading } />
                      }
                    </div>
                  </form>
                </> :
                <>
                <div className='sent-mail-container'>
                  <div className='icon-wrapper'>
                    <i className="fa-solid fa-envelope-circle-check"></i>
                  </div>  
                  <h2>Check your email</h2>
                  <p>We have sent you a new password to log in.</p>                 
                          
                </div> 
                  <div style={{'textAlign': 'center', 'marginTop': '18px'}}>
                    <NavLink to='/login' className='btn btn-primary' style={{'fontWeight': '500'}}>Go to Login page</NavLink>
                  </div>                  
                </>
              }
            </div>
          </div>
        </div>
      </div>

      <ToastContainer 
				position="bottom-right"
				autoClose={5000}
				hideProgressBar={true}
				newestOnTop={false}
				closeOnClick
				rtl={false}
			/>	
    </div>
  )

  // Submit function
  function submitForm(event: FormEvent): void {

    event.preventDefault();
    
    if (email.trim().length > 3 && email.includes('@')) {
      // Form is valid
      setIsDataLoading(true);
      resetPassword(email)
        .then((response$) => {       
          setTimeout(()=> {
            // history.push('/login');
            setIsDataLoading(false);
            setMailSent(true);
          }, 1000)  
        })
        .catch((error$: ErrorResponseDto) => {
          setIsDataLoading(false);
          showNotification('warning', error$.response.data.message);
        })
    }
    else {
      // Form is not valid
      if (!emailInputTouched) {
        setEmailInputTouched(true);
      }
      setError('Please enter a valid email address');
    }
  }

  function emailInputHandler(event: React.ChangeEvent<HTMLInputElement>) {
    setEmail(event.target.value.trim());
    setEmailInputTouched(true);
  }
}

export default ResetPasswordPage;