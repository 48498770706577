import { useState, useEffect, useRef }  from 'react';
import styles from './RateSkillsModal.module.scss';

import Tooltip from '../../../../../../services/tooltip.service';
import { Avatar } from "@mui/material";
import {capitalizeFirstLetter} from "../../../../../../utils/capitalize-first-letter";

const RateSkillsModal: React.FC<{data: any, okCallback: React.MouseEventHandler, cancelCallback: React.MouseEventHandler}> = (props) => { 
  const initializedComponent = useRef(false);
  const [currentEmployeeIndex, setCurrentEmployeeIndex] = useState<number>(0);
  const socialNotifications = props.data;
  const [notificationsPayload, setNotificationsPayload] = useState<any>({});

  useEffect(() => {
    let payload = {} as any;    
    payload.socialSkillValidation = []; 
    
    for (let item of socialNotifications) {
      payload.socialSkillValidation.push({
        notificationId: item.notificationId,
        rating: null
      })
    }
    setNotificationsPayload(payload);
    initializedComponent.current = true;

    // Initialize key events
    document.addEventListener('keyup', initializeKeyboardEvents)

    return () => {
      // Clear key events
      document.removeEventListener('keyup', initializeKeyboardEvents)
    }

  }, [])

  return (
    <div className={styles["rate-skill-component"]}>
      <div className="rate-skill-wrapper">
        
        <div id="close-rate-modal-button" className='close-button' onClick={props.cancelCallback}>
          <i className="fa-solid fa-xmark"></i>
        </div>
        
        <h2 className="main-title">Help your teammates validate their skills</h2>
        
        <div className='progress-number'>
          <span>{currentEmployeeIndex + 1}</span> /{socialNotifications.length}
        </div>
        { initializedComponent.current &&
          <div style={{'position': 'relative'}}>
            {
              socialNotifications.map((item: any, index: number) => {
                return <div key={item.notificationId} className='skill-level-wrapper' style={{'display': currentEmployeeIndex === index ? 'block' : 'none'}}>
                  <div className='title-wrapper'>
                    <div className='title-image-container'>
                      {
                        item.employeeProfilePicture ? <img src={item.employeeProfilePicture} alt={'profilePicture'} /> :
                            <Avatar sx={{backgroundColor: item.employeeColor ? item.employeeColor : 'red'}} className={'img'}>{item.employeeName.slice(0,1).toUpperCase()}</Avatar>
                      }
                    </div>
                    <div className='title-text'>
                      <span className='text-secondary'>How good is</span><span style={{fontWeight: '600'}}> {item.employeeName}</span><br />
                      <span className='text-secondary'>at </span> <span style={{fontWeight: '600'}}>{capitalizeFirstLetter(item.skillTitle)}</span>
                      <Tooltip place="right"                                                            
                        tooltipId={item.notificationId.toString()}  
                        backgroundColor="#101319"
                        border={true}
                        borderColor={'#5C5F6A !important'}
                        className='skill-description-tooltip'
                        content={
                          <>
                            {item.skillDescription}
                          </>
                        }>                                
                        <span className='tooltip-icon'><i className="fa-solid fa-circle-question"></i></span> 
                      </Tooltip>                                
                    </div>              
                  </div>
                  
                  <div className='skill-buttons-wrapper'>
                    <div className={`skill-button ${notificationsPayload.socialSkillValidation[currentEmployeeIndex].rating === 0 ? 'checked' : ''}`}
                    onClick={() => setSkillRating(0)}>
                      <span>Basic</span>
                    </div>
                    <div className={`skill-button ${notificationsPayload.socialSkillValidation[currentEmployeeIndex].rating === 1 ? 'checked' : ''}`}
                      onClick={() => setSkillRating(1)}>
                      <span>Proficient</span>
                    </div>
                    <div className={`skill-button ${notificationsPayload.socialSkillValidation[currentEmployeeIndex].rating === 2 ? 'checked' : ''}`}
                      onClick={() => setSkillRating(2)}>
                      <span>Expert</span>
                    </div>
                  </div>
                  <button
                     className={`button button-tertiary skill-button-dont-know u-margin-top-xs ${notificationsPayload.socialSkillValidation[currentEmployeeIndex].rating === -1 ? 'checked' : ''}`}
                     onClick={() => setSkillRating(-1)}>I don’t know <i className="fa-solid fa-check"></i></button>
                </div>
              })
            }
            { currentEmployeeIndex > 0 &&
              <div id='nav-button-prev'>
                <button id="prev-button" className='btn' onClick={backToPrevValidation}><i className="fa-solid fa-arrow-left"></i></button>
              </div>
            }
            { currentEmployeeIndex < socialNotifications.length - 1 &&
              <div id='nav-button-next'>
                <button id='nav-button-next-btn' className='btn' onClick={moveToNextValidation}><i className="fa-solid fa-arrow-right"></i></button>
              </div>
            }
          </div>
        }
        <div className='modal-footer'>          
          <div className='buttons-wrapper'>     
            {
              currentEmployeeIndex === socialNotifications.length - 1 &&
                <button id='send-rates-button' className='btn btn-primary' onClick={submitValidation}>Send</button>
            }            
          </div>
        </div>
      </div>
    </div>
  )

  function setSkillRating(rating: number): void {
    let payload = JSON.parse(JSON.stringify(notificationsPayload));
    if (payload.socialSkillValidation[currentEmployeeIndex].rating === rating) {
      payload.socialSkillValidation[currentEmployeeIndex].rating = null;
    }
    else {
      payload.socialSkillValidation[currentEmployeeIndex].rating = rating;
    }
    setNotificationsPayload({...payload});
  }

  function moveToNextValidation () {
    let index: number = currentEmployeeIndex + 1;
    
    if (index < socialNotifications.length) {
      setCurrentEmployeeIndex(index);
    }    
  }

  function backToPrevValidation () {
    let index: number = currentEmployeeIndex -1;
    
    if (index >= 0) {
      setCurrentEmployeeIndex(index);
    }    
  }

  function submitValidation() {    
    props.okCallback(notificationsPayload);
  }

  function initializeKeyboardEvents (event: any) { 
    switch (event.key) {
      case 'ArrowLeft': 
        let prevButton = document.getElementById('prev-button') as HTMLButtonElement;
        if (prevButton) {
          prevButton.click();
        }
        break;
      case 'ArrowRight':
        let nextButton = document.getElementById('nav-button-next-btn') as HTMLButtonElement;
        if (nextButton) {
          nextButton.click();
        }
        break;
      case 'Escape':
        let closeModalButton = document.getElementById('close-rate-modal-button') as HTMLElement;
        if (closeModalButton) {
          closeModalButton.click();
        }
        break;
      case 'Enter':
        let sendButton = document.getElementById('send-rates-button') as HTMLElement;
        if (sendButton) {
          sendButton.click();
        }
        break;
    }  
  }
}

export default RateSkillsModal;