import { useState, useEffect, forwardRef, useImperativeHandle} from "react";
import { useDrop } from "react-dnd";
import DragItemNew from "./DragItemNew";

const styles = {
  content: {
    paddingTop: 0,
    paddingBottom: 0,
    flex: 1,
    minHeight: '100px'
  }
};

const DropContainer = forwardRef((props: any, ref) => {
  useImperativeHandle(ref, () => ({
    clearSelection () {
      clearItemSelection();
    },
    selectAll() {
      setSelectedFields(props.fields);

      props.employeeSelected({team: props.teamComponent, employees: props.fields});
    }
  }));

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: "ITEM",
    drop: () => ({ targetId: props.targetId}),
    canDrop: (item: any, monitor) => {
      return item.source !== props.targetId;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  const [selectedFields, setSelectedFields] = useState([]);
  const divItems = [1,2,3,4,5,6];
  let employees = props.fields;

  function clearItemSelection() {
    // if (!props.similarPeopleFlag) {
    //   setSelectedFields([]);
    // }

    setSelectedFields([]);
  }

  const handleItemSelection = (index: any) => {
    let newSelectedFields: any;
    const fields = props.fields;
    const field = index < 0 ? "" : fields[index];
    // if (props.similarPeopleFlag) {
    //   return;
    // }
    const foundIndex = selectedFields.findIndex((f: any) => f.employeeId === field.employeeId);
    // If found remove it to unselect it.
    if (foundIndex >= 0) {
      newSelectedFields = [
        ...selectedFields.slice(0, foundIndex),
        ...selectedFields.slice(foundIndex + 1)
      ];
    } else {
      // const newFields = !props.teamComponent && !props.similarZone;
      newSelectedFields = [...selectedFields, field];
    }
    // }

    const finalList = fields ? fields.filter((f: any) => newSelectedFields.find((a: any) => a.employeeId === f.employeeId)) : [];
    setSelectedFields(finalList);

    props.employeeSelected({team: props.teamComponent, employees: finalList});
  };

  useEffect(() => {
    clearItemSelection();
  }, []);

  useEffect(() => {
    if (!props.similarPeopleFlag) {
      clearItemSelection();
    }
  }, [props.similarPeopleFlag]);

  const items = employees.sort((e1 : any, e2: any) => props.sortFunction ? props.sortFunction(e1, e2) : () => { return 1 }).map((field: any, index: any) => (
    <DragItemNew
      name={field.employeeName}
      profilePicture={field.signedUserProfilePicture}
      id={field.employeeId}
      color={field.color}
      coveredSkills={field.coveredSkills}
      key={field.employeeId}
      availability={field.availability}
      workAvailability={field.workAvailability}
      learningAvailability={field.learningAvailability}
      duplicate={field.duplicate}
      selectedSource={props.targetId}
      addItemsToCart={props.addItemsToCart}
      clearItemSelection={clearItemSelection}
      selectedFields={selectedFields}
      handleSelection={handleItemSelection}
      moveItemBack={props.moveItemBack}
      index={index}
      field={field}
      selectedSkill={props.selectedSkill}
      teamComponent={props.teamComponent}
      addProjectEmployee={props.addProjectEmployee}
      projectEmployees={props.projectEmployees}
      updateProjectEmployee={props.updateProjectEmployee}
      similarPeopleFlag={props.similarPeopleFlag}
      similarZone={props.similarZone}
      inMiddle={props.inMiddle}
      isLastChild={props.sortState ? (index === 0 && props.teamComponent) : ((props.fields.length - 1) === index && props.teamComponent)}
    />
  ));
  const divs = divItems.slice(0, divItems.length - props.fields.length).map(item => {
    return (
        <div key={item} className={'empty-div'}></div>
    )
  })

  return (
    <div style={styles.content} ref={drop}>
      {" "}
      {items}{" "}
      {!props.teamComponent && items.length === 0 && <h2>No more suggested employees.</h2>}
      {(props.teamComponent && !props.similarPeopleFlag) && divs}{" "}
    </div>
  );
})

export default DropContainer;
