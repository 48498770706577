import React, { useState, useEffect, useContext } from "react";
import {Route, useParams, Routes, Navigate} from "react-router-dom";
import axios, {AxiosRequestConfig} from "axios";
import NeededSkillsPage from "./NeededSkillsPage/NeededSkillsPage";
import SettingsPage from "./SettingsPage/SettingsPage";
import ProjectsContext from '../../../../../store/projects-context'
import AuthContext from "../../../../../store/auth-context";
import useLoadingSpinner from "../../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner";
import useApiService from '../../../../../services/api.service';
import {CreateProjectContextProvider} from "../../../../../store/create-project-context";
import {showNotification} from "../../../../../ui/Toast/ToastNotification";
import {ManagerProjectsData, ProjectDto} from "../../../../../types/Projects";
import {ErrorResponseDto} from "../../../../../types/ErrorData";

const ManagerDashboardPage: React.FC<{ jobPosting?: boolean }> = props => {
  const projectsStore = useContext(ProjectsContext);
  const authStore = useContext(AuthContext);
  const [projectData, setProjectData] = useState<ProjectDto>();
  const [isInit, setIsInit] = useState<boolean>(false);
  const spinnerService = useLoadingSpinner();
  let params = useParams() as any;
  const projectId = parseInt(params.id);
  const apiService = useApiService();

  useEffect(()=> {
    initializeComponent(authStore.userData.accessToken);
  }, [])

  return (
    <div className="manager-dashboard-page">
      <div className="container-fluid">
        {
          isInit &&
            <div className="row">
              <div className="col-12">
                <Routes>
                  {
                    !props.jobPosting &&
                     <Route path="settings" element={
                       <CreateProjectContextProvider>
                         <SettingsPage projectData={projectData!} />
                       </CreateProjectContextProvider>
                     }/>
                  }
                  {
                     !props.jobPosting && <Route path="needed-skills" element={<NeededSkillsPage projectData={projectData!} />}/>
                  }

                  {
                    !props.jobPosting &&  <Route path="manager-dashboard" element={<Navigate replace to={`/manager/projects/${params.id}/needed-skills`}></Navigate>} />
                  }

                  {
                     props.jobPosting &&  <Route path="manager-dashboard" element={<Navigate replace to={`/manager/tasks/${params.id}/needed-skills`}></Navigate>} />
                  }

                  {
                    props.jobPosting &&
                     <Route path="needed-skills/" element={
                       <CreateProjectContextProvider>
                         <NeededSkillsPage projectData={projectData!} jobPosting={true} />
                       </CreateProjectContextProvider>
                     } />
                  }

                  {
                    props.jobPosting && <Route path='' element={<Navigate replace to={`/manager/tasks/${params.id}/needed-skills`}></Navigate>} />
                  }

                </Routes>
              </div>
            </div>
        }     
      </div>
      {
        spinnerService.spinner
      }
    </div>
  )

  function initializeComponent(accessToken : string) {
    const getProjectsURL = process.env.REACT_APP_PUBLIC_URL + `/manager/${props.jobPosting ? 'tasks' : 'projects'}`;
    spinnerService.createSpinner();

    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }

    axios
      .get(getProjectsURL, {headers}
      )
      .then((response$: { data: ManagerProjectsData })=> {
        projectsStore.getSkillCategories(accessToken);      
        let data = response$.data.projects.find(item => item.id === projectId);
        setProjectData(data);
        projectsStore.updateProjectData(response$.data);
        setIsInit(true);
        spinnerService.removeSpinner();
      })
      .catch((error$: ErrorResponseDto)=> {
        if (error$.response.data.message === 'Unauthorized') {
          // Get new Access Token
          apiService.refreshToken(authStore.userData.refreshToken)
            .then((response$: any) => {       
              authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
              initializeComponent(response$.data.accessToken);
            })
        }
        else {
          setIsInit(true);
          spinnerService.removeSpinner();
          showNotification('warning', error$.response.data.message);
        }
      })
  }
}

export default ManagerDashboardPage;