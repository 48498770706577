import styles from './SearchResultComponent.module.scss';
import SkillsResultsComponent from "./SkillsResultsComponent/SkillsResultsComponent";
import {EmployeeDashboardData} from "../../../../../../../types/EmployeeDashboard";
import 'react-tabs/style/react-tabs.css';
import EmployeesResultComponent from "./EmployeesResultComponent/EmployeesResultComponent";
import {SKILL_INVENTORY_TYPE} from "../../../../../../../types/SkillsInventoryData";

const SearchResultComponent: React.FC<{
   backToNeededSkills: any,
   data: EmployeeDashboardData | any,
   searchUser: Function,
   title?: string,
   companySkillsFlag?: boolean,
   skillInventoryType?: SKILL_INVENTORY_TYPE
}> = (props) => {

   return (
      <div className={styles['employee-results']}>
         {props.data.resultType === 'skills' ?
            <SkillsResultsComponent skillInventoryType={props.skillInventoryType} companySkillsFlag={props.companySkillsFlag} title={props.title} backToNeededSkills={props.backToNeededSkills} data={props.data} searchUser={props.searchUser}/> :
            <EmployeesResultComponent skillInventoryType={props.skillInventoryType} title={props.title} backToNeededSkills={props.backToNeededSkills} data={props.data} />
         }
      </div>
   )
};

export default SearchResultComponent;