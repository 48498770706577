import React, {useRef, useState} from 'react';
import styles from './EditEmployeeModal.module.scss';
import SpinnerButton from "../../../../../../ui/SpinnerButton/SpinnerButton";
import {CompanyEmployeePayloadInformation, EmployeePayloadInformation} from "../../../../../../types/AdminData";

const EditEmployeeModal: React.FC<{ data: { title: string, formData: EmployeePayloadInformation }, dataIsLoading: boolean, okCallback: any, cancelCallback: React.MouseEventHandler<HTMLElement> }> = (props) => {
   const nameRef = useRef<any>('');
   const emailRef = useRef<any>('');
   const jobTitleRef = useRef<any>('');
   // const availabilityRef = useRef<any>('');
   const isManagerRef = useRef<any>();

   const nameErrorRef = useRef<any>();
   const emailErrorRef = useRef<any>();
   const jobTitleErrorRef = useRef<any>();
   const availabilityErrorRef = useRef<any>();

   const [dataIsLoading, setDataIsLoading] = useState(props.dataIsLoading)

   return (
      <div className={styles['new-edit-employee-modal']}>
         {/* { isInit && */}
         <div className='card card-modal'>
            <div className='card-header'>
               <h2>{props.data.title}</h2>
               <div className='close-modal-button'>
                  <i className="fa-solid fa-xmark" onClick={props.cancelCallback}></i>
               </div>
            </div>
            <div className="card-body">
               <div className="mb-3 input-wrapper">
                  <label htmlFor="name" className="form-label">Name *</label>
                  <input type="text" className={`form-control`} id="name" ref={nameRef}
                         defaultValue={props.data.formData.name} autoComplete='off' autoFocus/>
                  <span className='error-message' ref={nameErrorRef}>Please enter a name</span>
               </div>
               <div className="mb-3 input-wrapper">
                  <label htmlFor="email" className="form-label">Email *</label>
                  <input type="text" className={`form-control`} id="email" ref={emailRef}
                         defaultValue={props.data.formData.email} autoComplete='off'/>
                  <span className='error-message' ref={emailErrorRef}>Please enter a valid email address</span>
               </div>
               <div className="mb-3 input-wrapper">
                  <label htmlFor="title" className="form-label">Job Title *</label>
                  <input type="text" className={`form-control`} id="title" ref={jobTitleRef}
                         defaultValue={props.data.formData.jobTitle} autoComplete='off'/>
                  <span className='error-message' ref={jobTitleErrorRef}>Please enter a job title</span>
               </div>
               {/*<div className="mb-3 input-wrapper">*/}
               {/*   <label htmlFor="availability" className="form-label">Availability *</label>*/}
               {/*   <input type="text" className={`form-control`} id="availability" ref={availabilityRef}*/}
               {/*          defaultValue={props.data.formData.availability} autoComplete='off'/>*/}
               {/*   <span className='error-message' ref={availabilityErrorRef}>Please enter availability</span>*/}
               {/*</div>*/}
               <div className="mb-4 input-wrapper">
                  <input type="checkbox" id="isManager" ref={isManagerRef}
                         defaultChecked={props.data.formData.isManager}/>
                  <label htmlFor="isManager" className="form-label" style={{'marginLeft': '8px'}}>This employee is a
                     manager</label>
               </div>
               <div className='buttons-wrapper'>
                  <button className='button button-secondary u-margin-right-xs' onClick={props.cancelCallback}>Cancel</button>
                  <SpinnerButton title={'Save'} cssClasses={'button button-primary submit-button'} dataLoading={dataIsLoading}
                                 onClickCallback={submitForm}/>
               </div>
            </div>
         </div>
         {/* } */}
      </div>
   )

// Please enter a valid email address
   function submitForm() {
      setDataIsLoading(true)
      let validForm: boolean = true;
      // First name
      if (nameRef.current.value.trim() === '' || !isNaN(nameRef.current.value.trim())) {
         validForm = false;
         nameRef.current.classList.add('error');
         nameErrorRef.current.classList.add('show');
      } else {
         nameRef.current.classList.remove('error');
         nameErrorRef.current.classList.remove('show');
      }
      // Email
      if (emailRef.current.value.trim() === '' || !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailRef.current.value.trim()))) {
         validForm = false;
         emailRef.current.classList.add('error');
         emailErrorRef.current.classList.add('show');
      } else {
         emailRef.current.classList.remove('error');
         emailErrorRef.current.classList.remove('show');
      }
      // Job Title
      if (jobTitleRef.current.value === '' || !isNaN(jobTitleRef.current.value)) {
         validForm = false;
         jobTitleRef.current.classList.add('error');
         jobTitleErrorRef.current.classList.add('show');
      } else {
         jobTitleRef.current.classList.remove('error');
         jobTitleErrorRef.current.classList.remove('show');
      }

      // Availability
      // if (availabilityRef.current.value === '') {
      //    validForm = false;
      //    availabilityRef.current.classList.add('error');
      //    availabilityErrorRef.current.classList.add('show');
      // } else {
      //    availabilityRef.current.classList.remove('error');
      //    availabilityErrorRef.current.classList.remove('show');
      // }

      if (validForm) {
         let payload: CompanyEmployeePayloadInformation = {
            "id": props.data.formData.id,
            "name": nameRef.current.value.trim(),
            "email": emailRef.current.value.trim(),
            "jobTitle": jobTitleRef.current.value.trim(),
            // "availability": parseInt(availabilityRef.current.value.trim()),
            "isManager": isManagerRef.current.checked,
         }
         props.okCallback(payload);
      }
   }
}

export default EditEmployeeModal;