import SpinnerButton from "../../../../../../ui/SpinnerButton/SpinnerButton";

const ConfirmModal: React.FC<{okCallback: any, cancelCallback: React.MouseEventHandler<HTMLElement>}> = (props)=> {

  return (
    <div>
      {/* { isInit && */}
        <div className='card card-modal'>
          <div className='card-header'>
            <h2>Some title</h2>
            <div className='close-modal-button'>
              <i className="fa-solid fa-xmark" onClick={props.cancelCallback}></i>            
            </div>  
          </div>
          <div className="card-body">         
              <h1>Modal</h1>
  
            <div className='buttons-wrapper'>
              <button className='button button-secondary u-margin-right-xs' onClick={props.cancelCallback}>Cancel</button>
              <SpinnerButton title={'Save'} cssClasses={'button button-primary'} dataLoading={false}  />
            </div>         
          </div> 
        </div>
      {/* } */}
    </div>
  )

}

export default ConfirmModal;