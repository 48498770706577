export const TermsConditions: {sectionTitle?: string, sectionContent: string, sectionSubContent?: {title?: string, body: string}[], sectionAfterContent?: string}[]
   = [
   {
      sectionContent: 'Please read these Terms of Use (these "Terms") carefully as they govern your use of (which includes access to) TeamLift.'
   },
   {
      sectionTitle: 'Introduction',
      sectionContent: 'TeamLift prioritizes respecting and safeguarding your privacy. This Privacy Policy elucidates the nature of the personal information we collect, our methods of collecting it, its utilization, and our approach to ensuring its security.',
   },
   {
      sectionTitle: 'What Data Do We Collect',
      sectionContent: 'TeamLift collects the following data for the purpose of extracting skills for an individual:',
      sectionSubContent: [
         {title: 'Personal Identification:', body: 'This includes your email address.'},
         {title: 'Professional and Work Data:', body: 'We collect information about your work tasks and responsibilities through a series of questions we ask you directly. This data helps us accurately extract and assess your skills and capabilities.'}
      ]
   },{
      sectionTitle: 'How We Collect Your Data',
      sectionContent: 'TeamLift acquires information in the following manners:',
      sectionSubContent: [
         {body: 'Directly from the user when voluntarily provided via contact forms on our website.'},
         {body: 'Directly from the user when voluntarily provided via onboarding questions asked during the onboarding process.'},
         {body: 'Automatically from linked professional platforms and databases, such as GitHub, LinkedIn, and Jira, upon obtaining user consent.'},
         {body: 'Analyzing provided textual data, such as CVs, which are uploaded or otherwise shared with us, following user agreement.'},
      ]
   },{
      sectionTitle: 'How We Use Your Data',
      sectionContent: 'We may utilize the gathered professional information and identifiers for:',
      sectionSubContent: [
         {body: 'Extracting skills and creating a skills profile for individuals using our product.'},
         {body: 'Processing and fulfilling your requests and providing product-related support.'},
         {body: 'Offering updates and services we consider relevant to your requirements.'},
      ],
      sectionAfterContent: 'The information that users directly provide is held confidential and is utilized solely for the designated purpose specified at the point of collection. TeamLift will neither sell nor provide access to your information to third parties without your explicit consent.'
   },
   {
      sectionTitle: 'Access to Your Results',
      sectionContent: 'TeamLift pledges to maintain the confidentiality of each assessment taker. While aggregated and anonymized report data may be utilized for research purposes, such data for research cannot be associated with your individual report.',
   },{
      sectionTitle: 'How We Store Your Data',
      sectionContent: 'TeamLift implements reasonable and appropriate security measures to safeguard your information. Data storage, transmission, and processing adhere to industry-standard security protocols. This includes using secure servers, firewalls, and encrypted data storage to prevent unauthorized access, data breaches, and ensuring integrity and confidentiality.',
   },{
      sectionTitle: 'Your Data Protection Rights',
      sectionContent: 'You have robust rights concerning your data, particularly:',
      sectionSubContent: [
         { title: 'The Right to Access:', body: 'Entitlement to request copies of your personal and professional data.' },
         { title: 'The Right to Rectification and Completion:', body: 'Should you believe any data we hold is inaccurate or incomplete, you have the right to request correction.' },
         { title: 'The Right to Erasure ("Right to be Forgotten"):', body: 'Entitlement to request your data erasure, under certain conditions.' },
         { title: 'The Right to Restrict and Object to Processing:', body: 'Under specific circumstances, you have the right to object to or restrict the processing of your data.' },
         { title: 'The Right to Data Portability:', body: 'Under particular conditions, you can request the transfer of your data to another entity.' },
      ],
      sectionAfterContent: 'If you choose to exercise these rights, kindly contact us via email: team@teamlift.co. Please note, we have one month to respond to your request.'
   },{
      sectionTitle: 'Lawful Basis for Processing under GDPR',
      sectionContent: 'Pursuant to GDPR and similar data protection regulations, TeamLift processes your data under the following lawful bases:',
      sectionSubContent: [
         {title: 'Consent:', body: 'Where we seek explicit consent to process your personal and professional data for specified purposes, such as skills extraction from various platforms and documents.',},
         {title: 'Contractual Necessity:', body: 'Where processing is necessary for the fulfillment of a contract to which you are a party, or to undertake steps at your request before entering into a contract.',},
         {title: 'Legitimate Interests:', body: 'Where processing is necessary for purposes that are in our, or a third party\'s, legitimate interests - such as providing you with a tailored skills profile - except where such interests are overridden by your interests or fundamental rights and freedoms.',},
      ],
   },{
      sectionTitle: 'Data Management and Deletion',
      sectionContent: 'You have the right to request deletion of your data at any point. If you wish to manage, export, or delete your data:',
      sectionSubContent: [
         {title: 'Data Management:', body: 'Contact our support team to manage your data or to obtain a copy of the data we hold about you.',},
         {title: 'Data Deletion:', body: 'Submit a request to our support team to delete your data. Post verification of your identity, we will proceed with the data deletion within one month.',},
      ],
   },
   {
      sectionTitle: 'Changes to our Privacy Policy',
      sectionContent: 'We review our privacy policy regularly, posting any updates on this webpage to ensure our continuous commitment to safeguarding your data.'
   },
   {
      sectionTitle: 'Conclusion',
      sectionContent: 'By engaging with our website and services, you signify your acceptance of this Privacy Policy. For additional clarifications, inquiries, or to exercise your data protection rights, reach out using the provided contact details.',
   },
];