import React, { useState, useEffect, useContext, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import styles from './ProjectNameDescriptionComponent.module.scss';

import AuthContext from '../../../../../../../store/auth-context';
import OnboardingManagerContext from '../../../../../../../store/create-project-context';

import { UserData } from '../../../../../../../types/AuthData';

import { FileUploader } from 'react-drag-drop-files';
import { PulseLoader } from 'react-spinners';
import SpinnerButton from '../../../../../../../ui/SpinnerButton/SpinnerButton';
import { showNotification } from '../../../../../../../ui/Toast/ToastNotification';
import useLoadingSpinner from '../../../../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner';
import useApiService from '../../../../../../../services/api.service';
import {ErrorResponseDto} from "../../../../../../../types/ErrorData";

const ProjectNameComponent: React.FC<{changeSection: Function, clickedSaveAsDraft: boolean, saved: Function, saveProgress: Function, jobPosting?: boolean }> = (props) => {
  const authStore = useContext(AuthContext);
  const onboardingManagerContext = useContext(OnboardingManagerContext);
  const userData: UserData = authStore.userData;

  const [projectNameInput, setProjectNameInput] = useState<string>('');
  const [projectNameResponse, setProjectNameResponse] = useState<string>('');
  const [projectDescriptionInput, setProjectDescriptionInput] = useState<string>('');
  const [projectDescriptionResponse, setProjectDescriptionResponse] = useState<string>('');
  const [dataIsLoading, setDataIsLoading] = useState<boolean>(false);
  const [dataIsLoadingDesc, setDataIsLoadingDesc] = useState<boolean>(false);

  const [showInfoMsg, setShowInfoMsg] = useState(true);

  const spinnerService = useLoadingSpinner();

  const projectLater = useRef<any>();
  const apiService = useApiService();

  // Show input for inserting project name
  const inputProjectNameSpring = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 500
  })
  // Show changes to your data later
  const changeDataLaterSpring = useSpring({
    from: { opacity: 0 },
    to: {  opacity: 1 },
    delay: 2000
  })

  // Show and animate "question" for inserting project name
  const projectNameSpring = useSpring({
    from: { top: '75%', opacity: 0 },
    to: { top: '0%', opacity: 1 },
    delay: 1200
  })
  
  // Prepare to show and animate project name 'response'
  const [projectNameResSpring, projectNameResSpringApi] = useSpring(() => ({
    from: { top: '75%', opacity: 0 }
  }))
  // Prepare input for inserting project description
  const [inputProjectDescriptionSpring, inputProjectDescriptionSpringApi] = useSpring(() => ({
    from: { opacity: 0 }
  }))
  // Prepare to show and animate project description "question"
  const [projectDesSpring, projectDesSpringApi] = useSpring(() => ({
    from: { top: '75%', opacity: 0 }
  }))
  // Prepare to show and animate project description "response"
  const [projectDescResSpring, projectDescResSpringApi] = useSpring(() => ({
    from: { top: '75%', opacity: 0 }
  }))
  // Show skill generating title
  const [generatingSkillsSpring, generatingSkillsSpringApi] = useSpring(() => ({
    from: { top: '75%', opacity: 0 }
  }))

  useEffect(()=>{
    if (projectNameResponse !== '') {
      projectNameResSpringApi.start({ // Show and animate project name 'response'
        from: {
          top: '75%', opacity: 0
        },
        to: {
          top: '0%', opacity: 1
        },
        onResolve: () => {
          inputProjectDescriptionSpringApi.start({ // Show project description input
            to: { opacity: 1 }
          })
          projectDesSpringApi.start({  // Show and animate project description 'answer'
            from: {
              top: '75%', opacity: 0
            },
            to: {
              top: '0%', opacity: 1
            }
          })
        }
      })
    }
  }, [projectNameResponse])

  useEffect(()=> {
    if (projectDescriptionResponse !== '') {
      projectDescResSpringApi.start({
        from: { top: '75%', opacity: 0 },
        to: { top: '0%', opacity: 1 },
        onResolve: ()=> {
          generatingSkillsSpringApi.start({
            from: { top: '75%', opacity: 0 },
            to: { top: '0%', opacity: 1 }
          })
        }
      })
    }
  }, [projectDescriptionResponse])

  useEffect(() => {
    if (props.clickedSaveAsDraft){
      props.saved();
    }
  }, [props.clickedSaveAsDraft]);

  return (
    <section className={styles['project-name']}>
      <div className='project-name-wrapper'>
        { showInfoMsg && !props.jobPosting &&
        
        <animated.div className='project-later' ref={projectLater} style={{...changeDataLaterSpring}}>
          <div className='project-later-wrapper'>
            <div className={'project-later-info'}>
              You can always make changes to your data on the platform later.
            </div>
            <div className={'project-later-cose-button'}>
              <i className={'fa-solid fa-xmark'} onClick={() => {setShowInfoMsg(false)}}></i>
            </div>
          </div>
        </animated.div>
        }
  
        <div className='info-wrapper'>
          <animated.span className={'q-field'} style={{...projectNameSpring}}>
            {
              props.jobPosting ? 'What is the task\'s title?' : 'What is the name of your project?'
            }
          </animated.span>
          { projectNameResponse !== '' &&
            <animated.span className={'a-field'} style={{...projectNameResSpring}}>
              {projectNameResponse}
            </animated.span>
          }
          { projectNameResponse !== '' &&
            <animated.span className={'q-field'} style={{...projectDesSpring}}>
              {
                props.jobPosting ? 'Describe which responsibilities, expectations, and skills associated with the task. This will help our AI identify the skills needed to make it successful.'
                   : 'Describe your project. Include goals, objectives, work that needs to be done etc.\nThis will help our AI identify the skills needed to make it successful.'
              }
            </animated.span>
          }
          { projectDescriptionResponse !== '' &&
            <animated.span className={'a-field'} style={{...projectDescResSpring}}>
              {projectDescriptionResponse}
            </animated.span>
          }
        </div>

        <div>
          {/* Project name input */}
          { projectNameResponse === '' &&
            <animated.div className='input-wrapper' style={{...inputProjectNameSpring}}>
              <input maxLength={50} type='text' id='projectName' autoComplete='off' placeholder='Answer here'
                value={projectNameInput} onChange={insertProjectNameHandler} onKeyDown={checkKeyEnterName} />
              <div className='button-wrapper'>
                {
                  projectNameInput.trim() !== '' && projectNameInput.trim().length > 1 &&
                  <SpinnerButton title="Send" dataLoading={dataIsLoading} buttonType="button" cssClasses='button button-primary' onClickCallback={()=> saveProjectName(userData.accessToken)} />
                }
              </div>
            </animated.div>
          }

          {/* Project description input */}
          { projectNameResponse !== '' && projectDescriptionResponse === '' &&
            <animated.div className='add-description-wrapper' style={{...inputProjectDescriptionSpring}}>
              <div className='textarea-wrapper u-margin-right-xs'>
                <textarea id='projectDescription' autoComplete='off' placeholder='Answer here'
                  value={projectDescriptionInput} onChange={insertProjectDescHandler} onKeyDown={checkKeyEnterDesc}/>
                  <div className='button-wrapper'>
                    {
                      projectDescriptionInput.trim() !== '' && projectDescriptionInput.trim().length > 1 &&
                        <SpinnerButton dataLoading={dataIsLoadingDesc} cssClasses='button button-primary u-margin-bottom-xs' title={'Send'} onClickCallback={()=> saveProjectDescription(userData.accessToken)}></SpinnerButton>
                    }
                  </div>
              </div>
              <FileUploader name="fileHiddenInput" classes={"file-uploader"} children={
                <button className={'button button-tertiary'} id="description-upload-file">upload a file</button>
              }  handleChange={(file: any)=> browseFile(file, userData.accessToken)} />
            </animated.div>
          }

          {/* Generating skills  */}
          {  projectDescriptionResponse !== '' &&
            <animated.div className='generating-skills' style={{...generatingSkillsSpring}}>
              AI is generating needed skills
              <PulseLoader
                loading={true}
                color='#9A9DA7'
                size={7}
                speedMultiplier={.8}
                margin={8}
                style={{width: 'auto', display: 'inline-flex', alignItems: 'flex-end'}}
              />
            </animated.div>
          }
        </div>
      </div>
      {
        spinnerService.spinner
      }
    </section>
  )

  function insertProjectNameHandler(e: any) {
    setProjectNameInput(e.target.value);
  }

  function checkKeyEnterName (e : any) {
    if (e.key === 'Enter' && !dataIsLoading){
      saveProjectName(userData.accessToken);
    }
  }

  function checkKeyEnterDesc (e: any) {
    if (e.key === 'Enter' && !dataIsLoadingDesc) {
      saveProjectDescription(userData.accessToken);
    }
  }

  function saveProjectName(accessToken: string) {
    if (projectNameInput.trim().length < 1) {
      return;
    }
    if (projectNameInput.trim().length > 50) {
      showNotification("warning", `Please enter shorter ${props.jobPosting ? "task" : "project"} name`, `Too long ${props.jobPosting ? "task" : "project"}`)
      return;
    }

    setDataIsLoading(true);

    onboardingManagerContext.createProject(projectNameInput, accessToken, props.jobPosting)
      .then((response$: any)=> {
        let projectId: number = response$.project.id;
        let section = "project-skills-section";
        props.saveProgress(projectId, section, accessToken);
        setProjectNameResponse(projectNameInput);
        setDataIsLoading(false);
      })
      .catch((error$: ErrorResponseDto)=> {
        if (error$.response.data.message === 'Unauthorized') {
          // Get new Access Token
          apiService.refreshToken(userData.refreshToken)
            .then((response$: any) => {
              authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
              saveProjectName(response$.data.accessToken);
            })
        }
        else {
          setDataIsLoading(false);
          showNotification('warning', error$.response.data.message);
        }        
      });
  }

  function insertProjectDescHandler(e: any) {
    e.target.style.height = 'auto';
    e.target.style.height = (e.target.scrollHeight) + 'px';
    setProjectDescriptionInput(e.target.value);
  }

  function saveProjectDescription(accessToken: string) {
    if (projectDescriptionInput.trim().length < 1) {
      return;
    }
    setDataIsLoadingDesc(true);
    setProjectDescriptionResponse(projectDescriptionInput);

    onboardingManagerContext.sendTextNeededSkills(projectDescriptionInput, onboardingManagerContext.projectData.project?.id, accessToken)
      .then(()=> {
        props.changeSection('project-skills-section');
        setDataIsLoadingDesc(false);
      })
      .catch((error$: ErrorResponseDto)=> {
        if (error$.response.data.message === 'Unauthorized') {
          // Get new Access Token
          apiService.refreshToken(userData.refreshToken)
            .then((response$: any) => {
              authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
              saveProjectDescription(response$.data.accessToken);
            })
        }
        else {
          setDataIsLoadingDesc(false);
          setProjectDescriptionResponse('');
          showNotification('warning', error$.response.data.message);
        }
      });
  }

  function browseFile (file: any, accessToken: string) {
    let hiddenFileInput = document.getElementsByName('fileHiddenInput')[0] as HTMLInputElement;
    if (hiddenFileInput) {
      hiddenFileInput.value = "";
    }
    
    setProjectDescriptionResponse('Uploaded file...');
    onboardingManagerContext.uploadNeededSkillsFileHandler(file, onboardingManagerContext.projectData.project?.id, accessToken)
      .then(()=> {
        props.changeSection('project-skills-section-file');
      })
      .catch((error$: ErrorResponseDto)=> {
        if (error$.response.data.message === 'Unauthorized') {
          // Get new Access Token
          apiService.refreshToken(userData.refreshToken)
            .then((response$: any) => {
              authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
              browseFile(file, response$.data.accessToken);
            })
        }
        else {
          setProjectDescriptionResponse('');
          showNotification('warning', error$.response.data.message);
        }
      });
  }
}

export default ProjectNameComponent;