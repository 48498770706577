import { useEffect } from 'react';
import Chart from 'chart.js/auto';
import {capitalizeFirstLetter} from "../../../../../../utils/capitalize-first-letter";

const PolarChart = (props) => {
	const formattedChartData = {
		labels: [],
		datasets: [
			{
				data: [],
				backgroundColor: [
					formatArcColor('rgba(55, 78, 203, 1)'),
					formatArcColor('rgba(89, 105, 212, 1)'),
					formatArcColor('rgba(120, 133, 219, 1)'),
					formatArcColor('rgba(160, 168, 229, 1)'),
					formatArcColor('rgba(198, 202, 239, 1)')
				],
				borderWidth: 4,
				borderColor: '#101319'
			}
		]
	};

	let maxSizeChart = 0;
	let chartStep = 10;

	for (let item of props.data) {
		formattedChartData.labels.push(capitalizeFirstLetter(item.jobPosition.name));
		let number = Math.round(item.jobPosition.overlappingSkills);	
		formattedChartData.datasets[0].data.push(number);

		if (number > maxSizeChart) {
			maxSizeChart = number;
		}
	}
	if (maxSizeChart > 60) {
		chartStep = 20;
	}

	useEffect(() => {		
		var ctx = document.getElementById('myChart');
		var myChart = new Chart(ctx, {
			type: 'polarArea',
			data: {
				labels: formattedChartData.labels,
				datasets: formattedChartData.datasets
			},
			options: {	
				responsive: true,
				aspectRatio: 1 / 1.5,
				maintainAspectRatio: false,
				onClick: (evt, activeEls, chart) => {			
					let index = activeEls.length > 0 ? activeEls[0].index : -1
					if (index > - 1) {					
						arcClickCallback(index, chart)				
					}	
				},
				scales: {		
					r: {			
						suggestedMin: 0,
						suggestedMax: maxSizeChart + 10 > 100 ? 100 : maxSizeChart + 10,		
						pointLabels: {
							display: false,
							color: '#9A9DA7',
							centerPointLabels: true,
							font: {
								size: 14		
							}
						},								
						grid: {
							display: true,					
							color: '#2D2F38'
						},
						ticks: {
							color: '#2D2F38',
							backdropColor: 'rgb(255, 255, 255, 0.7)',			
							mirror: true,
							z: 1,
							callback: function(value){return value+ "%"}				
						}	
					}
				},				
				scale: {				
					stepSize: chartStep,
					angleLines: {
						display: true
					}
				},	
				plugins: {								
					legend: {
						display: true,
						align: 'start',
						onClick: null,
						labels: {
							color: '#9A9DA7',
							boxWidth: 25,				
							boxHeight: 15
						}
					},
					tooltip: {
						enabled: false,
						external: function(context) {
							// Tooltip Element
							let tooltipEl = document.getElementById('chartjs-tooltip');

							// Create element on first render
							if (!tooltipEl) {
								tooltipEl = document.createElement('div');
								tooltipEl.id = 'chartjs-tooltip';
								tooltipEl.style.background = "#0A0C10";
								tooltipEl.style.border = "1px solid #2D2F38";
								tooltipEl.style.padding = '10px';
								tooltipEl.innerHTML = '<div id="tooltip"></div>';
								document.body.appendChild(tooltipEl);
							}

							// Hide if no tooltip
							const tooltipModel = context.tooltip;
							if (tooltipModel.opacity === 0) {
								tooltipEl.style.opacity = 0;
								return;
							}

							// Set caret Position
							tooltipEl.classList.remove('above', 'below', 'no-transform');
							if (tooltipModel.yAlign) {
								tooltipEl.classList.add(tooltipModel.yAlign);
							} 
							else {
								tooltipEl.classList.add('no-transform');
							}

							function getBody(bodyItem) {
									return bodyItem.lines;
							}

							// Set Text
							if (tooltipModel.body) {
									const titleLines = tooltipModel.body[0].lines;
									const bodyLines = tooltipModel.body.map(getBody);

									let innerHtml = '<div style="display: flex; align-items: center">';		

									bodyLines.forEach(function(body, i) {
										const colors = tooltipModel.labelColors[i];
										let style = 'background:' + colors.backgroundColor;
										style += '; border-color:' + colors.borderColor;
										style += '; border-width: 2px';				
										style += '; width: 16px';
										style += '; height: 16px';
										style += '; margin-right: 8px';
										const div = '<div style="' + style + '"></div>';
										innerHtml += div;	
										
										innerHtml += '<div style="font-size: 13px">' + tooltipModel.title[0] + '</div>';
									});
									innerHtml += '</div>';		
									
									bodyLines.forEach(function(body, i) { 
										let data = props.data[tooltipModel.dataPoints[0].dataIndex];
										let style='font-size: 13px; margin-top: 4px; margin-left: 24px';
										innerHtml += `<div style="${style}">${data.commonSkills.length}/${data.commonSkills.length + data.missingSkills.length + data.commonNotApprovedSkills.length} skills</div>`;
									});

									let root = tooltipEl.querySelector('#tooltip');
									if (root) {
										root.innerHTML = innerHtml;
									}
							}

							const position = context.chart.canvas.getBoundingClientRect();
					
							// Display, position, and set styles for font
							tooltipEl.style.opacity = 1;
							tooltipEl.style.position = 'absolute';
							tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
							tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';			
							tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
							tooltipEl.style.pointerEvents = 'none';
						}
					}
				}
			}
		});

		props.chartRef.current = myChart;
		props.formatArcColorRef.current = formatArcColor;

		return () => {
			myChart.destroy();
		}
	}, [props.data]);

	return (
		<div id="chart-container" style={{'padding': '0px', 'marginTop': '24px', 'marginBottom': '24px'}}>			
			<canvas id="myChart"></canvas>
		</div>
	)

	function arcClickCallback(index, chart) {
		props.setChosenSkillData((prevState) => {
			let choosenObject = JSON.stringify(prevState);
			let newObject = JSON.stringify(props.data[index]);

			if (choosenObject != newObject) {		
				chart.data.datasets[0].backgroundColor.forEach((color, i, colors) => {
					colors[i] = i === index ? formatArcColor(color, 1) : formatArcColor(color, 0.2);
				});
				chart.update();
				return {...props.data[index]};
			}
			else {
				chart.data.datasets[0].backgroundColor.forEach((color, i, colors) => {
					colors[i] = formatArcColor(color, 1);
				});	
				chart.update();
				return {};
			}
		})
	}

	function formatArcColor(color, alpha = null) {
		const startIndex = color.indexOf('(');
		const endtIndex = color.indexOf(')');
		let rgbaColor = color.substring(startIndex + 1, endtIndex);

		const red = rgbaColor.split(',')[0];
		const green = rgbaColor.split(',')[1];
		const blue = rgbaColor.split(',')[2];
		let opacity= rgbaColor.split(',')[3];

		if (alpha) {
			opacity = alpha;
		}		
		return `rgba(${red}, ${green}, ${blue}, ${opacity})`;
	}
}

export default PolarChart;