import React, {createContext, useContext, useState} from 'react';
import axios, {AxiosRequestConfig} from 'axios';
import useApiService from '../services/api.service';
import AuthContext from './auth-context';
import {
  CompanyEmployeesData,
  ExtractedNeededSkillData,
  NewNeededSkillData,
  NewProjectData,
  Project,
  ProjectEmployeeData,
  SearchSkillResultData,
  SkillCategoryData
} from '../types/ProjectOnboardingData';
import {EmployeeType, NeededEmployee, NeededPeopleData} from "../types/NeededPeopleData";
import {
  CompanyEmployeesDto,
  CreateProjectResponseDto,
  ExtractedNeededSkillsAndUrlDto,
  ExtractedNeededSkillsDto, ProjectDataAiDto,
  ProjectInfoResponseDto, ProjectPanelEmployeeDto,
  ProjectSkillDto,
  SaveNewNeededSkillDto,
  SearchSynonymResultDto,
  SkillsPaginationDto
} from "../types/ProjectsStoreTypes";

export const CreateProjectContext = createContext({
  projectData: {} as NewProjectData,  // 1  
  extractedNeededSkillsData: [],      // 2
  neededSkillsTempData: [],
  neededSkillsData: [],
  searchSkillResultData: [],
  similarSkillsResponseData: {},
  skillCategoriesData: [],
  companyEmployeesData: [],
  projectEmployeesData: [],
  finalProjectData: {},
  csvFileDownloadURL: "",

  createProject: (name: string, accessToken: string, jobPosting?: boolean): any => { }, // 1
  sendTextNeededSkills: (description: string, projectId: any, accessToken: string): any => { }, // 2  
  uploadNeededSkillsFileHandler: (file: any, projectId: any, accessToken: string): any => { },
  selectExtractedNeededSkill: (projectDataIds: [], projectId: any, accessToken: string): any => { },
  getExtractedNeededSkillsAI: (projectId: any, accessToken: string): any => { },
  searchSkill: (searchTerm: string, page: number, projectId: any, accessToken: string): any => { },
  addNeededSkill: (skillId: number, projectId: number, accessToken: string): any => { },
  searchAISimilarSkills: (payload: any, accessToken: string): any => { },
  addNewSkillOrSynonymNeededSkill: (payload: any, projectId: any, accessToken: string): any => { },
  getSkillCategories: (accessToken: string): any => { },
  getProjectSkills: (projectId: any, accessToken: string): any => { },
  deleteProjectSkill: (projectSkillIds: number[], projectId: any, accessToken: string): any => { },
  updateProjectSkillDemand: (payload: any, projectId: any, accessToken: string): any => { },
  getCompanyEmployees: (projectId: any, accessToken: string): any => { },
  deleteProjectEmployees: (projectEmployeeId: number, projectId: any, accessToken: string): any => { },
  finishOnboardingProject: (projectId: any, accessToken: string): any => { },
  toggleNeededSkillsTempData: (id: string): void => { },
  getMixedData: (projectId: any, accessToken: string): any => { },
  deleteProject: (ids: number[], accessToken: string) : any => { },
  changeProjectStatus: (ids: number[], change: boolean, accessToken: string) : any => { },
  neededPeople: (projectId: number, accessToken: string) : any => { },
  addProjectEmployee : (projectId: number,employee: any,availability: any,accessToken: string) : any => {},
  deleteTeamEmployees: (projectId:number, teamEmployees: any, accessToken: string) : any => {},
  updateTeamEmployee: (projectId: number, employee: {projectEmployeeId:number,availability:number,type: string}, accessToken: string) : any => {},
  findSimilarPeople: (projectId: number, projectEmployeesIds: number[], accessToken: string) : any => {},
});

export const CreateProjectContextProvider: React.FC<{ children: React.ReactNode }> = (props) => {
  const authStore = useContext(AuthContext);
  const apiService = useApiService();

  const [extractedNeededSkillsData, setExtractedNeededSkillsData] = useState<ExtractedNeededSkillData[]>();
  const [projectData, setProjectData] = useState<CreateProjectResponseDto>();
  const [neededSkillsTempData, setNeededSkillsTempData] = useState<ExtractedNeededSkillData[]>([]);
  const [neededSkillsData, setNeededSkillsData] = useState<ProjectSkillDto[]>([]);
  const [searchSkillResultData, setSearchSkillResultData] = useState<SearchSkillResultData[]>([]);
  const [similarSkillsResponseData, setSimilarSkillsResponseData] = useState<SearchSynonymResultDto>();
  const [skillCategoriesData, setSkillCategoriesData] = useState<string[]>([]);
  const [companyEmployeesData, setCompanyEmployeesData] = useState<CompanyEmployeesData[]>([]);
  const [projectEmployeesData, setProjectEmployeesData] = useState<ProjectPanelEmployeeDto[]>([]);
  const [finalProjectData, setFinalProjectData] = useState<ProjectDataAiDto>();
  const [csvFileDownloadURL, setCSVFileDownloadURL] = useState<string>();

  async function createProject(name: string, accessToken: string, jobPosting?: boolean) {
    const createProjectURL: string = process.env.REACT_APP_PUBLIC_URL + '/project/create';

    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }
    /**
     * "newProject": {
     *  "projectName": "Project name",
     *  "projectDescription": "Project description" - this is optional
     * }
     */
    let data: {} = {
      projectName: name,
      taskMatcher: jobPosting
    }

    let promise = new Promise((resolve: any, reject: any) => {
      axios
        .post(createProjectURL, data, { headers })
        .then((response$: { data: CreateProjectResponseDto }) => {
          setProjectData(response$.data);
          resolve(response$.data);
        })
        .catch((error$) => {
          // REFRESH TOKEN - DONE
          reject(error$);
        });
    });
    return await promise;
  }

  async function uploadNeededSkillsFileHandler(file: any, projectId: number, accessToken: string) {
    const uploadNeededSkillFileURL: string = process.env.REACT_APP_PUBLIC_URL + '/file/upload-needed-skill-file';

    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }
    let params: {} = {
      'projectId': +projectId
    }

    const bodyFormData = new FormData();
    bodyFormData.append('file', file);

    let promise = new Promise((resolve: any, reject: any) => {
      axios
        .post(uploadNeededSkillFileURL, bodyFormData, {
          headers, params
        })
        .then(() => {
          resolve();
        })
        .catch((error$) => {
          // REFRESH TOKEN - DONE
          reject(error$);  
        });
    });
    return await promise;
  };

  async function sendTextNeededSkills(description: string, projectId: number, accessToken: string) {
    const sendTextNeededSkillsURL: string = process.env.REACT_APP_PUBLIC_URL + '/project/extract-needed-skills-text';

    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }
    /**
     * "projectOnboardingNeededSkillDto": {
     *  "projectId": 1,
     *  "projectDescription": "Project description"
     * }
     */

    let data = {
      projectDescription: description,
      projectId: +projectId
    }

    let promise = new Promise((resolve: any, reject: any) => {
      axios
        .post(sendTextNeededSkillsURL, data, { headers })
        .then((response$: any) => {
          let skills: ExtractedNeededSkillsDto[] = [];

          if ((response$.data as ExtractedNeededSkillsAndUrlDto).csvUrl) {
            skills = (response$.data as ExtractedNeededSkillsAndUrlDto).extractedNeededSkills;
          }
          else {
            skills = response$.data as ExtractedNeededSkillsDto[];
          }
          const temp: ExtractedNeededSkillsDto[] = [];
          skills.forEach(skill => {
            const exists = neededSkillsTempData.some(neededSkill => neededSkill.skillAIId === skill.skillAIId);
            const tempSkill = { ...skill, deleted: !exists ? false : skill.deleted };
            temp.push(tempSkill);
          })
          setNeededSkillsTempData(temp);
          setExtractedNeededSkillsData([...skills]);
          resolve(skills);
        })
        .catch((error$) => {
          // REFRESH TOKEN - DONE
          reject(error$);
        });
    });
    return await promise;
  }

  async function selectExtractedNeededSkill(projectDataIds: [], projectId: number, accessToken: string) {
    const selectExtractedNeededSkillsURL: string = process.env.REACT_APP_PUBLIC_URL + '/project/select-extracted-needed-skills';

    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }
    /**
     * "extractedNeededSkills": {
     *  "projectId": 1,
     *  "projectDataIds": [7, 6, 8, 12, 13, 14, 15]
     * }
     */
    let data = {
      projectDataIds: projectDataIds,
      projectId: +projectId
    }

    let promise = new Promise((resolve: any, reject: any) => {
      axios
        .post(selectExtractedNeededSkillsURL, data, { headers })
        .then((response$: {data: ProjectSkillDto[]}) => {
          setNeededSkillsData(response$.data);
          resolve();
        })
        .catch((error$) => {
          // REFRESH TOKEN - DONE
          reject(error$);
        });
    });
    return await promise;
  }

  // this function is called after the extraction of needed skills from file has finished
  async function getExtractedNeededSkillsAI(projectId: any, accessToken: string) {
    const extractedNeededSkillsAIURL: string = process.env.REACT_APP_PUBLIC_URL + '/project/extracted-needed-skills-ai';

    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }

    let params: {} = {
      'projectId': projectId
    }

    let promise = new Promise((resolve: any, reject: any) => {
      axios
         .get(extractedNeededSkillsAIURL, { headers, params })
         .then((response$: {data: ExtractedNeededSkillsDto[] | ExtractedNeededSkillsAndUrlDto}) => {
           let tempData: any = JSON.parse(JSON.stringify(neededSkillsTempData));

           let skills: ExtractedNeededSkillsDto[] = [];

           if ((response$.data as ExtractedNeededSkillsAndUrlDto).csvUrl) {
             // setCSVFileDownloadURL(response$.data.csvUrl)
             skills = (response$.data as ExtractedNeededSkillsAndUrlDto).extractedNeededSkills;
           }
           else {
             skills = response$.data as ExtractedNeededSkillsDto[];
           }

           for (let skill of skills) {
             let id = skill.skillAIId;
             let neededSkill = tempData.find((item: ExtractedNeededSkillData) => {
               return item.skillAIId === id;
             });
             if (!neededSkill) {
               let tempSkill: ExtractedNeededSkillData = JSON.parse(JSON.stringify(skill));
               tempSkill.deleted = false;
               tempData.push(tempSkill);
             }
           }
           setNeededSkillsTempData(tempData);
           setExtractedNeededSkillsData([...skills]);
           resolve(skills);
         })
         .catch((error$) => {
           reject(error$);
         });
    });
    return await promise;
  }

  async function searchSkill(searchTerm: string, page: number, projectId: any, accessToken: string) {
    const searchSkillURL: string = process.env.REACT_APP_PUBLIC_URL + '/company/search-taxonomy-skills';
    const paginationSize: number = 100;

    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }

    let params: {} = {
      projectId: projectId,
      searchTerm: searchTerm,
      page: page,
      pageSize: paginationSize

    }
    let promise = new Promise((resolve: any, reject: any) => {
      axios
        .post(searchSkillURL, params,{ headers })
        .then((response$: {data: SkillsPaginationDto}) => {
          resolve(response$.data);
        })
        .catch((error$) => {
          // REFRESH TOKEN - DONE
          reject(error$);
        });
    });
    return await promise;
  }

  async function addNeededSkill(skillId: number, projectId: number, accessToken: string) {
    const addNeededSkillURL: string = process.env.REACT_APP_PUBLIC_URL + '/project/add-needed-skill';
    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }
    /**
     * "neededSkill": {
     *  "projectId": 1,
     *  "skillId": 13019
     * }
     */
    let data = {
      skillId: skillId,
      projectId: projectId
    }

    let promise = new Promise((resolve: any, reject: any) => {
      axios
        .post(addNeededSkillURL, data, { headers })
        .then((response$: {data: SaveNewNeededSkillDto}) => {
          setNeededSkillsData([...neededSkillsData, response$.data.projectSkill]);
          resolve(response$.data.projectSkill);
        })
        .catch((error$) => {    
          // REFRESH TOKEN - DONE
          reject(error$);   
        });
    });
    return await promise;
  }

  async function searchAISimilarSkills(skillName: string, accessToken: string) {
    const searchAISimilarSkillsURL: string = process.env.REACT_APP_PUBLIC_URL + '/project/search-ai-similar-skills';

    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }

    let params: {} = {
      skillName: skillName
    }

    let promise = new Promise((resolve: any, reject: any) => {
      axios
        .get(searchAISimilarSkillsURL, { headers, params })
        .then((response$: { data: SearchSynonymResultDto}) => {
          setSimilarSkillsResponseData(response$.data);
          resolve(response$.data);
        })
        .catch((error$) => {
          reject(error$); 
        });
    });
    return await promise;
  }

  async function addNewSkillOrSynonymNeededSkill(payload: any, projectId: any, accessToken: string) {
    const addNewSkillOrSynonymNeededSkillURL: string = process.env.REACT_APP_PUBLIC_URL + '/project/confirm-skill-or-synonym';

    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }
    /**
     * "newNeededSkill": {
     *  "projectId": 1,
     *  "synonymName": 13019,
     *  "synonymAIId"[this is sent only when adding a synonym from the list when searching a skill]: 135585,
     *  "prefferedSkillAIId"[this is sent only when adding a synonym to a preferred term from the list 
     *                        or a synonym from the list when searching a skill]: "8ff7f58f3a1945aca5bc31b15592b775",
     *  "synonymDescription" [optional / not optional when adding a new skill]: "Nest. JS is a framework that helps build Node. JS server-side applications. The Nest framework is built using TypeScript which allows developers to build highly scalable and testable applications. Built on top of Express.",
     *  "synonymCategory" [optional / not optional when adding a new skill]: "information and communication technologies (icts)"
     * }
     */
    let data: {} = {
      ...payload,
      projectId: +projectId
    }

    let promise = new Promise((resolve: any, reject: any) => {
      axios
        .post(addNewSkillOrSynonymNeededSkillURL, data, { headers })
        .then((response$: {data: SaveNewNeededSkillDto}) => {
          if (response$.data.tag !== 'EXISTING_PREFERRED_NEEDED_SKILL') {
            setNeededSkillsData([...neededSkillsData, response$.data.projectSkill]);
          }           
          resolve(response$.data);
        })
        .catch((error$) => {
          // REFRESH TOKEN - DONE
          reject(error$);
        });
    });
    return await promise;
  }

  async function getSkillCategories(accessToken: string) {
    const getSkillCategoriesURL: string = process.env.REACT_APP_PUBLIC_URL + '/skill/categories';

    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }
    let promise = new Promise((resolve: any, reject: any) => {
      axios
         .get(getSkillCategoriesURL, { headers })
         .then((response$: {data: SkillCategoryData[]}) => {
           const categories: string[] = response$.data
              .filter(item => item.category !== null && item.category !== undefined) // Filter out null and undefined
              .map(item => item.category as string); // Cast to string because we filtered out null and undefined

           setSkillCategoriesData(categories);
           resolve();
         })
         .catch((error$) => {
           reject(error$);
         });
    });
    return await promise;
  }

  async function getProjectSkills(projectId: any, accessToken: string) {
    const getProjectSkillsURL: string = process.env.REACT_APP_PUBLIC_URL + '/project/project-skills';

    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }
    let params: {} = {
      projectId: projectId
    }
    let promise = new Promise((resolve: any, reject: any) => {
      axios
        .get(getProjectSkillsURL, { headers, params })
        .then((response$: { data: ProjectSkillDto[] }) => {
          setNeededSkillsData(response$.data);
          resolve(response$.data);
        })
        .catch((error$) => {
          // REFRESH TOKEN - DONE
          reject(error$);
        });
    });
    return await promise;
  }

  async function deleteProjectSkill(projectSkillIds: number[], projectId: any, accessToken: string) {
    const deleteProjectSkillURL: string = process.env.REACT_APP_PUBLIC_URL + '/project/delete-project-skill';

    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }
    /**
     * "deleteProjectSkill": {
     *  "projectId": 1,
     *  "projectSkillId": 31
     *  "projectSkillId": 31
     *  }
     */
    let data = {
      projectSkillIds: projectSkillIds,
      projectId: projectId
    }

    let promise = new Promise((resolve: any, reject: any) => {
      axios
        .post(deleteProjectSkillURL, data, { headers })
        .then((response$: {data: ProjectSkillDto[]}) => {
          setNeededSkillsData(response$.data);
          resolve();
        })
        .catch((error$) => {     
          // REFRESH TOKEN - DONE
          reject(error$);
        });
    });
    return await promise;
  }

  async function updateProjectSkillDemand(payload: any, projectId: any, accessToken: string) { // NE SE KORISTI
    const updateSkillsDemandsURL: string = process.env.REACT_APP_PUBLIC_URL + '/project/update-skill-demand';

    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }
    /**
     * {
     *  "projectId": 1,
     *  "demandRequests": [
        {
            "projectSkillId": 4,
            "demandType": 50
        },
        {
            "projectSkillId": 5,
            "demandType": 100
        }
      ] 
     *  }
     */
    let data: {} = {
      demandRequests: payload ,
      projectId: projectId
    }

    let promise = new Promise((resolve: any, reject: any) => {
      axios
        .post(updateSkillsDemandsURL, data, { headers })
        .then((response$: any) => {
          setNeededSkillsData([...response$.data]);
          resolve();
        })
        .catch((error$) => { 
          // REFRESH TOKEN - DONE
          reject(error$);  
        });
    });
    return await promise;
  }

  async function getCompanyEmployees(projectId: any, accessToken: string) {
    const getCompanyEmployeesURL: string = process.env.REACT_APP_PUBLIC_URL + '/project/company-employees';

    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }
    let params = {
      projectId: projectId
    }
    let promise = new Promise((resolve: any, reject: any) => {
      axios
        .get(getCompanyEmployeesURL, { headers, params })
        .then((response$: {data: CompanyEmployeesDto[]}) => {
          setCompanyEmployeesData(response$.data);
          resolve(response$.data);
        })
        .catch((error$) => {
          // REFRESH TOKEN - DONE
          reject(error$);
        });
    });
    return await promise;
  }

  async function deleteProjectEmployees(projectEmployeeId: number, projectId: any, accessToken: string) {
    const deleteProjectEmployeeURL: string = process.env.REACT_APP_PUBLIC_URL + '/project/delete-project-employee';

    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }
    /**
     * "deleteProjectEmployee" : {
     *  "projectId": 1,
     *  "projectEmployeeId": 4 
     *  }
     */
    let data: {} = {
      deleteProjectEmployee: { projectEmployeeId: projectEmployeeId, projectId: +projectId }
    }

    let promise = new Promise((resolve: any, reject: any) => {
      axios
        .post(deleteProjectEmployeeURL, data, { headers })
        .then((response$: {data: NeededPeopleData}) => {
          let updatedProjectEmployeeList = projectEmployeesData.filter((p) => p.id != projectEmployeeId);
          setProjectEmployeesData([...updatedProjectEmployeeList]);
          resolve(updatedProjectEmployeeList);
        })
        .catch((error$) => {
          // REFRESH TOKEN - DONE
          reject(error$);
        });
    });
    return await promise;
  }

  // This function return project info, company employees and project employees data
  async function getMixedData (projectId: any, accessToken: string) {
    const getMixedDataURL: string = process.env.REACT_APP_PUBLIC_URL + '/project/onboard-project-info';

    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }
    let params: {} = {
      'projectId': projectId
    }

    let promise = new Promise((resolve: any, reject: any) => {
      axios
        .get(getMixedDataURL, { headers, params })
        .then((response$: {data: ProjectInfoResponseDto}) => {
          setCompanyEmployeesData([...response$.data.companyEmployees]);
          setProjectEmployeesData([...response$.data.projectEmployees]);  
          resolve(response$.data);          
        })
        .catch((error$) => {
          // REFRESH TOKEN - DONE
          reject(error$); 
        });
    });
    return await promise;
  }
  
  async function finishOnboardingProject(projectId: any, accessToken: string) {
    const finishOnboardingProjectURL: string = process.env.REACT_APP_PUBLIC_URL + '/project/finish-onboarding-project';

    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }

    let data: {} = {
      projectId: projectId
    }

    let promise = new Promise((resolve: any, reject: any) => {
      axios
        .post(finishOnboardingProjectURL, data, { headers })
        .then((response$: {data: ProjectDataAiDto}) => {
          setFinalProjectData(response$.data);
          resolve(response$.data);
        })
        .catch((error$) => {
          // REFRESH TOKEN - DONE
          reject(error$);
        });
    });
    return await promise;
  }
  
  function toggleNeededSkillsTempData (id: string): void {
    const tempData = neededSkillsTempData.map(neededSkill => {
      return {
        ...neededSkill,
        deleted: neededSkill.skillAIId === id ? !neededSkill.deleted : neededSkill.deleted
      }
    })
    setNeededSkillsTempData(tempData);
  }

  async function deleteProject(ids: number[], accessToken: string) {
    const deleteProjectURL: string = process.env.REACT_APP_PUBLIC_URL + '/project/delete';
    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }
    let params: {} = {
      projectIds: ids
    }
    let promise = new Promise((resolve: any, reject: any) => {
      axios
          .post(deleteProjectURL, params, { headers })
          .then((response$: {data: boolean}) => {
            resolve(response$.data);
          })
          .catch((error$) => {
            reject(error$);
          });
    });
    return await promise;
  }

  async function changeProjectStatus(ids: number[], change : boolean, accessToken: string) {
    const changeProjectStatusURL: string = process.env.REACT_APP_PUBLIC_URL + '/project/change-status';
    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }
    let params: {} = {
      projectIds: ids,
      change : change
    }
    let promise = new Promise((resolve: any, reject: any) => {
      axios
          .post(changeProjectStatusURL, params, { headers })
          .then((response$: {data: boolean}) => {
            resolve(response$.data);
          })
          .catch((error$) => {
            reject(error$);
          });
    });
    return await promise;
  }

  async function neededPeople(projectId : number, accessToken: string) {
    const neededPeopleURL: string = process.env.REACT_APP_PUBLIC_URL + '/project/needed-people';

    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }
    let params: {} = {
      projectId: projectId
    }
    let promise = new Promise((resolve: any, reject: any) => {
      axios
          .get(neededPeopleURL, {headers, params})
          .then((response$: {data: NeededPeopleData}) => {
            resolve(response$.data);
          })
          .catch((error$) => {
            reject(error$);
          });
    });
    return await promise;
  }

  async function addProjectEmployee(projectId: number,employee: NeededEmployee | NeededEmployee[],availability: any,accessToken: string) {
    const addProjectEmployeeURL: string = process.env.REACT_APP_PUBLIC_URL + '/project/add-project-employee';
    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }

    let params = [] as any;

    if (Array.isArray(employee)) {
      employee.forEach((emp: NeededEmployee) => {
        let coveredSkills = emp.coveredSkills.map((cs : any) => cs.project_skill_id);
        let employeeObject: any = {
          employeeId: emp.employeeId,
          projectId: projectId,
          coveredSkills: coveredSkills
        }
        if (emp.type === EmployeeType.CANDIDATE) {
          employeeObject.workAvailability = 1
        }
        else {
          employeeObject.learningAvailability = 1
        }
        params.push(employeeObject);
      })
    }

    else {
      let coveredSkills = employee.coveredSkills.map(cs => cs.project_skill_id);

      let employeeObject: any = {
        employeeId: employee.employeeId,
        projectId: projectId,
        coveredSkills: coveredSkills,
      }

      if (employee.type === EmployeeType.CANDIDATE) { // || employee.type === 'SIMILAR_PEOPLE'
        employeeObject.workAvailability = 1;
      }
      else {
        employeeObject.learningAvailability = 1;
      }
      params.push(employeeObject);
    }
    let promise = new Promise((resolve: any, reject: any) => {
      axios
         .post(addProjectEmployeeURL, params, { headers })
         .then((response$: {data: NeededPeopleData}) => {
           resolve(response$.data);
         })
         .catch((error$) => {
           reject(error$);
         });
    });
    return await promise;
  }

  async function deleteTeamEmployees(projectId:number, teamEmployees: any, accessToken: string) {
    let deleteTeamEmployeesURL = process.env.REACT_APP_PUBLIC_URL + "/project/delete-project-employees";
    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }

    let projectEmployees = teamEmployees.map((employee : any) => {
      if (!employee.workAvailability || employee.workAvailability === 0) { // vo slucaj da e learner samo

        return {
          projectEmployeeId: employee.projectEmployeeId,
        }
      }
      else {
        let coveredSkills = employee.skills.map((s : any) => s.id); // vo bilo koj drug slucaj

        return {
          projectEmployeeId: employee.projectEmployeeId,
          coveredSkills: coveredSkills
        };
      }
    });

    let params = {
      projectId: projectId,
      projectEmployees: projectEmployees
    }

    let promise = new Promise((resolve: any, reject: any) => {
      axios
         .post(deleteTeamEmployeesURL, params, { headers })
         .then((response$: {data: NeededPeopleData}) => {
           resolve(response$.data);
         })
         .catch((error$) => {
           reject(error$);
         });
    });
    return await promise;
  }

  async function updateTeamEmployee(projectId: number, employee: {projectEmployeeId:number,availability:number,type: string}, accessToken: string) {
    let updateTeamEmployeeURL = process.env.REACT_APP_PUBLIC_URL + "/project/update-project-employee-availability";
    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }

    let params = {
      projectId : projectId,
      projectEmployeeId: employee.projectEmployeeId,
      availability : employee.availability,
      type: employee.type
    }

    let promise = new Promise((resolve: any, reject: any) => {
      axios
         .post(updateTeamEmployeeURL, params, { headers })
         .then((response$: any) => {
           resolve(response$.data);
         })
         .catch((error$) => {
           reject(error$);
         });
    });
    return await promise;

  }

  async function findSimilarPeople(projectId: number, projectEmployeesIds: number[], accessToken: string) {
    let findSimilarPeopleURL = process.env.REACT_APP_PUBLIC_URL + "/project/find-similar-people";

    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }

    let params: {} = {
      projectId: projectId,
      projectEmployeeIds : projectEmployeesIds
    }

    let promise = new Promise((resolve: any, reject: any) => {
      axios
         .post(findSimilarPeopleURL, params,{headers})
         .then((response$: any) => {
           resolve(response$.data);
         })
         .catch((error$) => {
           reject(error$);
         });
    });
    return await promise;

  }

  let context = {
    extractedNeededSkillsData: extractedNeededSkillsData as any,
    projectData: projectData as any,
    neededSkillsTempData: neededSkillsTempData as any,
    neededSkillsData: neededSkillsData as any,
    searchSkillResultData: searchSkillResultData as any,
    similarSkillsResponseData: similarSkillsResponseData as any,
    skillCategoriesData: skillCategoriesData as any,
    companyEmployeesData: companyEmployeesData as any,
    projectEmployeesData: projectEmployeesData as any,
    finalProjectData: finalProjectData as any,
    csvFileDownloadURL: csvFileDownloadURL as any,
    createProject: createProject as any,
    uploadNeededSkillsFileHandler: uploadNeededSkillsFileHandler as any,
    sendTextNeededSkills: sendTextNeededSkills as any,
    selectExtractedNeededSkill: selectExtractedNeededSkill as any,
    getExtractedNeededSkillsAI: getExtractedNeededSkillsAI as any,
    searchSkill: searchSkill as any,
    addNeededSkill: addNeededSkill as any,
    searchAISimilarSkills: searchAISimilarSkills as any,
    addNewSkillOrSynonymNeededSkill: addNewSkillOrSynonymNeededSkill as any,
    getSkillCategories: getSkillCategories as any,
    getProjectSkills: getProjectSkills as any,
    deleteProjectSkill: deleteProjectSkill as any,
    updateProjectSkillDemand: updateProjectSkillDemand as any,
    getCompanyEmployees: getCompanyEmployees as any,
    deleteProjectEmployees: deleteProjectEmployees as any,
    finishOnboardingProject: finishOnboardingProject as any,
    toggleNeededSkillsTempData: toggleNeededSkillsTempData as any,
    getMixedData: getMixedData as any,
    deleteProject: deleteProject as any,
    changeProjectStatus: changeProjectStatus as any,
    neededPeople: neededPeople as any,
    addProjectEmployee: addProjectEmployee as any,
    deleteTeamEmployees: deleteTeamEmployees as any,
    updateTeamEmployee: updateTeamEmployee as any,
    findSimilarPeople: findSimilarPeople as any,
  }

  return (
    <CreateProjectContext.Provider value={context}>
      {props.children}
    </CreateProjectContext.Provider>
  )
}

export default CreateProjectContext;