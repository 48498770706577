import { useEffect, useState } from 'react';

const useResponsiveComponent = () => {

   const [windowWidth, setWindowWidth] = useState(
      typeof window !== 'undefined' ? window.innerWidth : 0
   );
   const [windowHeight, setWindowHeight] = useState(
      typeof window !== 'undefined' ? window.innerHeight : 0
   );

   let timeout: string | number | NodeJS.Timeout | undefined;

   useEffect(() => {
      const handleResize = () => {
         clearTimeout(timeout);

         timeout = setTimeout(() => {
            setWindowWidth(window.innerWidth);
            setWindowHeight(window.innerHeight);
         }, 100);
      }

      window.addEventListener('resize', handleResize);

      return () => window.removeEventListener('resize', handleResize);
   }, []);

   return { windowWidth, windowHeight };
};

export default useResponsiveComponent;