import React, {MouseEventHandler, useContext, useEffect, useState} from 'react';
import SkillsDetailsComponent from "./SkillsDetailsComponent/SkillsDetailsComponent";
import {
   DashboardDto,
   DashBoardRequestDto,
   SideBarDto,
   SideBarRequestDto,
   SKILL_VIEW_FILTER,
   SkillsDashboardDto
} from "../../../../../../types/ManagerDashboardData";
import useLoadingSpinner from "../../../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner";
import axios, {AxiosRequestConfig} from "axios";
import {ErrorResponseDto} from "../../../../../../types/ErrorData";
import {showNotification} from "../../../../../../ui/Toast/ToastNotification";
import useApiService from "../../../../../../services/api.service";
import AuthContext from "../../../../../../store/auth-context";
import {ProjectData} from "../../../../../../types/ProjectDashboardData";
import style from './ProjectDetailsPage.module.scss';
import {useNavigate} from "react-router-dom";
import useComponentLoadingSpinner from "../../../../../../ui/ComponentLoadingSpinner/ComponentLoadingSpinner";
import {toast} from "react-toastify";

const ProjectDetailsPage: React.FC<{
   projectData: ProjectData,
   showSearchPanel: MouseEventHandler<HTMLDivElement>
}> = props => {

   const [initializedComponent, setInitializedComponent] = useState<DashboardDto>();
   const [sideBarData, setSideBarData] = useState<SideBarDto>();
   const [categories, setCategories] = useState<string[]>([]);

   const authStore = useContext(AuthContext);
   const spinnerService = useLoadingSpinner();
   const {refreshToken} = useApiService();
   const navigate = useNavigate();

   const reminderURL: string = process.env.REACT_APP_PUBLIC_URL + '/auth/reminder-email';
   const managerDashboardURL = process.env.REACT_APP_PUBLIC_URL + `/manager/project-dashboard`;
   const sideBarDashboardURL = process.env.REACT_APP_PUBLIC_URL + '/manager/skill-view/project';
   const getCategoriesURL = process.env.REACT_APP_PUBLIC_URL + '/manager/categories-dropdown';
   const addEmployeeToProjectURL = process.env.REACT_APP_PUBLIC_URL + "/project/add-project-employee";
   let hasSettingsOption: boolean = authStore.userData.isManager && !props.projectData.isTeamLead;

   useEffect(() => {
      authStore.changePageTitle('Project Dashboard');
      const req: DashBoardRequestDto = {page: 1, pageSize: 50, projectId: props.projectData.id};
      getDashboardData(req, authStore.userData.accessToken);
      getCategoriesDropDown(authStore.userData.accessToken);
   }, []);

   return (
      <>
         {
            initializedComponent &&
            <div className={style['project-details-page']}>
               <div className={'needed-skill-header'}>
                  <h1 className="header-1 needed-skill-title u-margin-top-xs u-margin-bottom-s">{props.projectData.name}</h1>
                  <div className='search-button' onClick={props.showSearchPanel}>
                     <span className="search-icon"><i className="fa-solid fa-magnifying-glass"></i> Search for an employee</span>
                  </div>
                  {
                     hasSettingsOption && <button className='button button-secondary u-margin-left-m'
                                                  onClick={openSettings}>Settings</button>
                  }
               </div>
               <SkillsDetailsComponent data={initializedComponent}
                                       reminderUserEmail={(skillId, employeeIds) => reminderEmail(skillId, employeeIds, authStore.userData.accessToken)}
                                       sideBarData={sideBarData}
                                       categories={categories}
                                       dashboardRequest={(req: DashBoardRequestDto) => getDashboardData(req, authStore.userData.accessToken)}
                                       sideBarRequest={(skill: SkillsDashboardDto, skillFilter: SKILL_VIEW_FILTER) => sideBarRequest(skill, skillFilter, authStore.userData.accessToken)}
                                       closeSideBar={() => setSideBarData(undefined)} addToProject={(employeeId, req, skill) => addToProject(employeeId, req, skill, authStore.userData.accessToken)}/>
            </div>
         }
         {
            spinnerService.spinner
         }
      </>
   )
   
   function addToProject(employeeId: number, req: DashBoardRequestDto, skill: SkillsDashboardDto, accessToken: string) {

      spinnerService.createSpinner();

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      const employeeObject = {
         employeeId,
         projectId: props.projectData.id,
         workAvailability: 1
      }

      const params = [employeeObject];

      axios
         .post(addEmployeeToProjectURL, params, {headers})
         .then(_response$ => {
            getDashboardData(req, accessToken, true);
            sideBarRequest(skill, SKILL_VIEW_FILTER.COMPANY_WIDE, accessToken);
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     addToProject(employeeId, req, skill, response$.data.accessToken);
                  })
            } else {
               spinnerService.removeSpinner();
               showNotification('warning', error$.response.data.message);
            }
         })
   }

   function sideBarRequest(skill: SkillsDashboardDto, skillFilter: SKILL_VIEW_FILTER, accessToken: string) {

      const req: SideBarRequestDto = {
         synonymId: skill.skill.synonym ? skill.skill.synonym.id : undefined,
         skillId: skill.skill.id,
         skillViewFilter: skillFilter,
         projectIds: [props.projectData.id]
      }

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      axios
         .post(sideBarDashboardURL, req, {headers})
         .then((response$: { data: SideBarDto['skillPlusInformation'] }) => {
            setSideBarData({skill, activeSkillFilter: skillFilter, skillPlusInformation: response$.data});
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     sideBarRequest(skill, skillFilter, response$.data.accessToken);
                  })
            } else {
               showNotification('warning', error$.response.data.message);
            }
         })
   }

   function getDashboardData(req: DashBoardRequestDto, accessToken: string, sideBarFlag?: boolean) {

      spinnerService.createSpinner();

      if (!req.projectId) {
         req.projectId = props.projectData.id;
      }

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      axios
         .post(managerDashboardURL, req, {headers})
         .then((response$: { data: DashboardDto }) => {
            setInitializedComponent(response$.data);
            if (!sideBarFlag) {
               setSideBarData(undefined);
            }
            spinnerService.removeSpinner();
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     getDashboardData(req, response$.data.accessToken, sideBarFlag);
                  })
            } else {
               spinnerService.removeSpinner();
               showNotification('warning', error$.response.data.message);
            }
         })
   }

   function getCategoriesDropDown(accessToken: string) {

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }
      const data = {
         projectId: props.projectData.id,
         isProjectSkillSet: true
      }

      axios
         .post(getCategoriesURL, data, {headers})
         .then((response$: { data: string[] }) => {
            setCategories(response$.data);
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     getCategoriesDropDown(response$.data.accessToken);
                  })
            } else {
               showNotification('warning', error$.response.data.message);
            }
         })
   }

   function reminderEmail(skillId: number, employeeIds: number[], accessToken: string) {

      spinnerService.createSpinner();

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      const data = {
         skillId,
         employeeIds
      }

      axios
         .post(reminderURL, data, {headers})
         .then((_response$: { data: boolean }) => {
            showNotification("success", "Email reminder has been successfully sent.", "Sent notification");
            spinnerService.removeSpinner();
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     reminderEmail(skillId, employeeIds, response$.data.accessToken);
                  })
            } else {
               spinnerService.removeSpinner();
               showNotification('warning', error$.response.data.message);
            }
         })

   }

   function openSettings() {
      navigate(`/manager/projects/${props.projectData.id}/settings`);
   }
}

export default ProjectDetailsPage;