import { useState, useEffect, useRef, useContext } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import styles from './OnboardingManagerComponent.module.scss';
import axios, {AxiosRequestConfig} from 'axios';
import { UserData } from '../../../../types/AuthData';

import { AuthContext } from '../../../../store/auth-context';
import OnboardingManagerContext from '../../../../store/create-project-context';

import WelcomeComponent from './WelcomeComponent/WelcomeComponent';
import OnboardingManagerProcessComponent from '../ProjectsPage/CreateProjectComponent/OnboardingManagerProcessComponent/OnboardingManagerProcessComponent';
import useApiService from '../../../../services/api.service';
import useLoadingSpinner from '../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner';
import { showNotification } from '../../../../ui/Toast/ToastNotification';
import {ErrorResponseDto} from "../../../../types/ErrorData";

const OnboardingManagerComponent: React.FC = () => {
  const authStore = useContext(AuthContext);
  const onboardingManagerStore = useContext(OnboardingManagerContext);
  const userData: UserData = authStore.userData;
  const [initializedComponent, setInitializedComponent] = useState<boolean>(false);
  const [activeSection, setActiveSection] = useState<string>('');
  const [projectId, setProjectId] = useState<any>(null);
  const [feComponent, setFeComponent] = useState<any>(null);

  const apiService = useApiService();
  const spinnerService = useLoadingSpinner();
  const navigate = useNavigate();
  
  useEffect(() => {
    getProjects(userData.accessToken);
  }, [])

  return (
    <div className={styles['onboarding-manager-page']}>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12'>
            { 
              initializedComponent &&
              <div className='onboarding-manager-modal'>
                <div className='card-modal'>              
                    <div className='row'>
                      <div className='col-12'>
                        <div className={`modal-header ${activeSection === 'welcome' ? 'transparent' : ''}`}>
                          {
                            activeSection !== 'welcome' &&
                            <div className='logo-container'>
                              <img src='/assets/images/global-icons/teamlift-logo-white.svg' alt="TeamLift logo" />
                            </div>
                          }
                          <div className='logout-button' onClick={authStore.logout}>
                            <i className="fa-solid fa-arrow-right-from-bracket"></i> Log out
                          </div>
                        </div>   
                      </div>
                    </div>     
    
                  <div className='modal-body'>
                    {
                      activeSection === 'welcome' &&
                      <WelcomeComponent user={userData.displayName} changeSection={() => setActiveSection('onboarding-process')} />
                    }
                    {
                      activeSection === 'onboarding-process' &&
                      <OnboardingManagerProcessComponent clickedSaveAsDraft={false} projectId={projectId} feComponent={feComponent} isOnboarding={true} />
                    }
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
      {
        spinnerService.spinner
      }
    </div>
  )

  function initializeComponent(accessToken: string) {
    onboardingManagerStore.getSkillCategories(accessToken)
      .then(()=> {
        setInitializedComponent(true);
        spinnerService.removeSpinner();
      })
      .catch((error$: ErrorResponseDto)=> {
        if (error$.response.data.message === 'Unauthorized') {
          // Get new Access Token
          apiService.refreshToken(authStore.userData.refreshToken)
            .then((response$: any) => {
              authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
              initializeComponent(response$.data.accessToken);
            })
        }
        else {
          spinnerService.removeSpinner();
          showNotification('warning', error$.response.data.message);
        }
      })    
  }

  function getProjects(accessToken: string) {
    const getProjectsURL = process.env.REACT_APP_PUBLIC_URL + '/manager/projects';
    spinnerService.createSpinner();
    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }

    axios
      .get(getProjectsURL, {headers}
      )
      .then((response$: any)=> {
        if (!response$.data.onboarding) {
          navigate('/manager/projects/all-projects');
        }
        else {
          if (response$.data.projects.length > 0) { // It's draft project
            const projectId: number = response$.data.projects[0].id;
            const feComponent: string = response$.data.projects[0].feComponent;
            setProjectId(projectId);
            setFeComponent(feComponent);
            setActiveSection('onboarding-process');

          }
          else { // No created project, start the onboarding from the beginning
            setActiveSection('welcome');
          }
          initializeComponent(accessToken);
        }
      })
      .catch((error$: ErrorResponseDto)=> {
        if (error$.response.data.message === 'Unauthorized') {
          // Get new Access Token
          apiService.refreshToken(authStore.userData.refreshToken)
            .then((response$: any) => {
              authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
              getProjects(response$.data.accessToken);
            })
        }
        else {          
          setInitializedComponent(true);
          spinnerService.removeSpinner();
          showNotification('warning', error$.response.data.message);
        }
      })
  }
}

export default OnboardingManagerComponent;