import React, {useRef, useState} from 'react';
import styles from './NewEditEmployeeModal.module.scss';
import SpinnerButton from "../../../../../../../ui/SpinnerButton/SpinnerButton";
import {adminRegexPatterns, EmployeePayloadInformation} from "../../../../../../../types/AdminData";

const NewEditEmployeeModal: React.FC<{data: { title: string, formData: EmployeePayloadInformation, crud: 'edit' | 'new' }, dataIsLoading : boolean, okCallback: any, cancelCallback: React.MouseEventHandler<HTMLElement>}> = (props)=> {
  const firstNameRef = useRef<any>('');
  const surnameRef = useRef<any>('');
  const emailRef = useRef<any>('');
  const jobTitleRef = useRef<any>('');
  const isManagerRef = useRef<any>();
  // const availabilityRef = useRef<any>();
  const githubLinkRef = useRef<any>();
  const cvDriveLinkRef = useRef<any>();
  // const linkedinLinkRef = useRef<any>();
  
  const firstNameErrorRef = useRef<any>();
  const surnameErrorRef = useRef<any>();
  const emailErrorRef = useRef<any>();
  const jobTitleErrorRef = useRef<any>();
  const availabilityErrorRef = useRef<any>();
  const githubLinkErrorRef = useRef<any>();
  const cvDriveLinkErrorRef = useRef<any>();
  // const linkedinLinkErrorRef = useRef<any>();

  const [dataIsLoading, setDataIsLoading] = useState(props.dataIsLoading)

  return (
    <div className={styles['new-edit-employee-modal']}>
      {/* { isInit && */}
        <div className='card card-modal'>
          <div className='card-header'>
            <h2>{props.data.title}</h2>
            <div className='close-modal-button'>
              <i className="fa-solid fa-xmark" onClick={props.cancelCallback}></i>            
            </div>  
          </div>
          <div className="card-body">         
            <div className="mb-3 input-wrapper">
              <label htmlFor="firstName" className="form-label">Name *</label>     
              <input type="text" className={`form-control`} id="firstName" ref={firstNameRef} defaultValue={props.data.formData.name} autoComplete='off' autoFocus />
              <span className='error-message' ref={firstNameErrorRef}>Please enter a name</span>
            </div>
            <div className="mb-3 input-wrapper">
              <label htmlFor="surname" className="form-label">Surname *</label>     
              <input type="text" className={`form-control`} id="surname" ref={surnameRef} defaultValue={props.data.formData.surname} autoComplete='off' />
              <span className='error-message' ref={surnameErrorRef}>Please enter a surname</span>
            </div>
            <div className="mb-3 input-wrapper">
              <label htmlFor="email" className="form-label">Email *</label>     
              <input type="text" className={`form-control`} id="email" ref={emailRef} defaultValue={props.data.formData.email} autoComplete='off' />              
              <span className='error-message' ref={emailErrorRef}>Please enter a valid email address</span>                 
            </div>
            <div className="mb-3 input-wrapper">
              <label htmlFor="title" className="form-label">Job Title *</label>     
              <input type="text" className={`form-control`} id="title" ref={jobTitleRef} defaultValue={props.data.formData.jobTitle} autoComplete='off' />
              <span className='error-message' ref={jobTitleErrorRef}>Please enter a job title</span>
            </div>
            {/*<div className="mb-3 input-wrapper">*/}
            {/*  <label htmlFor="availability" className="form-label">Availability *</label>     */}
            {/*  <input type="number" className={`form-control`} id="availability" ref={availabilityRef} defaultValue={props.data.formData.availability} autoComplete='off' />*/}
            {/*  <span className='error-message' ref={availabilityErrorRef}>Please enter availability</span>*/}
            {/*</div>*/}
            <div className="mb-3 input-wrapper">
              <label htmlFor="githubLink" className="form-label">Github link</label>     
              <input type="text" className={`form-control`} id="githubLink" ref={githubLinkRef} defaultValue={props.data.formData.githubLink} autoComplete='off' />
              <span className='error-message' ref={githubLinkErrorRef}>Please enter a valid Github link</span>
            </div>
            <div className="mb-3 input-wrapper">
              <label htmlFor="cvDriveLink" className="form-label">CV Google Drive link</label>
              <input type="text" className={`form-control`} id="cvDriveLink" ref={cvDriveLinkRef} defaultValue={props.data.formData.cvDriveLink} autoComplete='off' />
              <span className='error-message' ref={cvDriveLinkErrorRef}>Please enter a valid CV Google Drive link</span>
            </div>
            {/*<div className="mb-3 input-wrapper">*/}
            {/*  <label htmlFor="linkedinLink" className="form-label">Linkedin link</label>*/}
            {/*  <input type="text" className={`form-control`} id="linkedinLink" ref={linkedinLinkRef} defaultValue={props.data.formData.linkedinLink} autoComplete='off' />*/}
            {/*  <span className='error-message' ref={linkedinLinkErrorRef}>Please enter a valid Linkedin link</span>*/}
            {/*</div>*/}
            <div className="mb-4 input-wrapper">
              <input type="checkbox" id="isManager" ref={isManagerRef} defaultChecked={props.data.formData.isManager} />
              <label htmlFor="isManager" className="form-label" style={{'marginLeft': '8px'}}>This employee is a manager</label>
            </div>
            <div className='buttons-wrapper'>
              <button className='button button-secondary' onClick={props.cancelCallback}>Cancel</button>
              <SpinnerButton title={'Save'} cssClasses={'button button-primary'} dataLoading={dataIsLoading} onClickCallback={submitForm} />
            </div>         
          </div> 
        </div>
      {/* } */}
    </div>
  )

  function validateField(inputRef: any, errorRef: any, isValid: Function, addErrorClass = 'error', showErrorClass = 'show') {
    if (isValid(inputRef.current.value.trim())) {
      inputRef.current.classList.remove(addErrorClass);
      errorRef.current.classList.remove(showErrorClass);
      return true;
    } else {
      inputRef.current.classList.add(addErrorClass);
      errorRef.current.classList.add(showErrorClass);
      return false;
    }
  }
  function submitForm() {
    let validForm: boolean = true;

    // First Name
    validForm = validateField(firstNameRef, firstNameErrorRef,
        (value: any) => value !== '' && isNaN(value)) && validForm;

    // Last name
    validForm = validateField(surnameRef, surnameErrorRef,
       (value: any) => value !== '' && isNaN(value)) && validForm;

    // Email
    validForm = validateField(emailRef, emailErrorRef,
        (value: string) => value !== '' && adminRegexPatterns.email.test(value)) && validForm;

    // Job Title
    validForm = validateField(jobTitleRef, jobTitleErrorRef, (value: any) => value !== '' && isNaN(value)) && validForm;

    // Availability
    // if (availabilityRef.current.value.trim() === '' || +availabilityRef.current.value < 1) {
    //   validForm = false;
    //   availabilityRef.current.classList.add('error');
    //   availabilityErrorRef.current.classList.add('show');
    // }
    // else {
    //   availabilityRef.current.classList.remove('error');
    //   availabilityErrorRef.current.classList.remove('show');
    // }

    // Github
    validForm = validateField(githubLinkRef, githubLinkErrorRef,
       (value: string) => value === '' || (value !== '' && adminRegexPatterns.githubUrl.test(value))) && validForm;

    // Cv google drive link
    validForm = validateField(cvDriveLinkRef, cvDriveLinkErrorRef,
       (value: string) => value === '' || (value !== '' && adminRegexPatterns.cvGoogleDriveLink.test(value))) && validForm;

    // Linkedin
    // validForm = validateField(linkedinLinkRef, linkedinLinkErrorRef,
    //    (value: string) => value === '' || (value !== '' && adminRegexPatterns.linkedinLink.test(value))) && validForm;


    if (validForm) {
      setDataIsLoading(true)
      let payload: EmployeePayloadInformation = {
        "name": firstNameRef.current.value.trim(),
        "surname": surnameRef.current.value.trim(),
        "email": emailRef.current.value.trim(),
        "jobTitle": jobTitleRef.current.value.trim(),
        "isManager": isManagerRef.current.checked,
        // "availability": parseInt(availabilityRef.current.value.trim()),
        "githubLink": githubLinkRef.current.value.trim(),
        "cvDriveLink": cvDriveLinkRef.current.value.trim(),
        // "linkedinLink": linkedinLinkRef.current.value.trim()
      }

      if (props.data.crud === 'edit') {
        payload.id = props.data.formData.id;
      }
      props.okCallback(props.data.crud, payload);
    }    
  }
}

export default NewEditEmployeeModal;