import React, {useContext, useEffect, useState} from "react";
import style from './ProfileBoostPage.module.scss';
import {animated, useSpring} from "react-spring";
import SelectComponent from "../../../UploadDataPage/UploadPersonalDataComponent/AddManuallySkillsComponent/SelectComponent/SelectComponent";
import {SearchedSkillDto} from "../../../../../types/SearchComponentData";
import Tooltip from "../../../../../services/tooltip.service";
import AuthContext from "../../../../../store/auth-context";
import {FileUploader} from "react-drag-drop-files";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {ErrorResponseDto} from "../../../../../types/ErrorData";
import useApiService from "../../../../../services/api.service";
import {SkillDto} from "../../../../../types/SkillsData";
import {showNotification} from "../../../../../ui/Toast/ToastNotification";
import {capitalizeFirstLetter} from "../../../../../utils/capitalize-first-letter";
import {ProfileBoostSkillDto} from "../../../../../types/EmployeeOnboardData";
import {FileTypeValue} from "../../../../../types/UploadData";
import useLoadingSpinner from "../../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner";

const ProfileBoostPage: React.FC<{
   changeSection: Function,
}> = props => {

   const authStore = useContext(AuthContext);
   const {refreshToken} = useApiService();
   const spinnerService = useLoadingSpinner();

   const [skills, setSkills] = useState<ProfileBoostSkillDto[]>([]);
   const [description, setDescription] = useState<string>('');

   const fadeStyles = useSpring({
      from: {opacity: 0},
      to: {opacity: 1},
      config: {duration: 750},
   });

   useEffect(() => {
      initializeComponent(authStore.userData.accessToken);
   }, []);

   return (
      <>
         <animated.div className={style["profile-boost"]} style={fadeStyles}>
            <h1 className="header-1 u-margin-bottom-s">Profile boost</h1>
            <p className={"u-margin-bottom-s"}>Thank you for entering your name and position. That's a great start! If you've uploaded any files, we'll review them shortly.</p>
            <p className={"u-margin-bottom-l"}>However, we need a little more information about you to help our AI assistant better understand your skills. We're not mind readers, you know!</p>
            <section className="skills-section">
               <h3 className="header-3">Technical skills (mandatory)</h3>
               <p className={"text-surface-30 u-margin-bottom-s"}>List any specific tools you use.<br/>E.g., web frameworks, data analysis tools, programming language.</p>
              <SelectComponent skillsToBeAdded={skills} addSkillFromSelect={addSkillFromSelect} />
               <div className="skills-container u-margin-top-xs">
                  {
                     skills.map(skill => {
                        return (
                           <div key={skill.value}
                                className={`skill-wrapper ${skill.isSuggested ? skill.selected ? "active" : "" : "active"}`}
                                onClick={() => selectSkill(skill)}>
                              <div className="skill-name u-margin-right-xs">{capitalizeFirstLetter(skill.label)}</div>
                              <Tooltip tooltipId={`skill_${skill.value}`}
                                       place="bottom"
                                       className={'custom-tooltip'}
                                       content={<div>{skill.description}</div>}>
                                 <i className="fa-regular fa-circle-question"></i>
                              </Tooltip>
                              {
                                 skill.isSuggested ? skill.selected ? <i className="fa-solid fa-minus"></i> :
                                    <i className="fa-solid fa-plus"></i> : <i className="fa-solid fa-minus"></i>
                              }
                           </div>
                        )
                     })
                  }
               </div>
               <div className="upload-data u-margin-top-l">
                  <div className="title">
                     <img src="/assets/images/global-icons/stars-icon.svg" alt="Stars icon"/>
                     <div className="title-text">
                        <h3 className="header-3 text-primary-20">Want to supercharge your profile?</h3>
                        <div>Add details about your skills and experience!</div>
                     </div>
                  </div>
                  <h3 className="header-3 u-margin-bottom-m">Describe projects, tasks, or achievements that showcase your experience</h3>
                  <textarea value={description} onChange={e => setDescription(e.target.value)} className={"custom-input u-margin-bottom-s"} placeholder={"E.g. Built web applications using Python and Django"}/>

                  <FileUploader name="experienceFile" classes={"file-uploader"} children={
                     <>
                        <button className={'button button-tertiary'} id="description-upload-experience-file">
                           <i className="fa-solid fa-arrow-up-from-bracket u-margin-right-xs"></i>
                           <span>Upload file</span>
                        </button>
                     </>
                  } handleChange={(file: File) => {sendFile(file, FileTypeValue.PERSONAL_NOTES, authStore.userData.accessToken)}} />

                  <h3 className="header-3 u-margin-top-m">Upload any trainings you've completed</h3>
                  <div className="text-surface-40 u-margin-bottom-s">(.pdf), (.txt)</div>

                  <FileUploader name="trainingFile" classes={"file-uploader"} children={
                     <>
                        <button className={'button button-tertiary'} id="description-upload-experience-file">
                           <i className="fa-solid fa-arrow-up-from-bracket u-margin-right-xs"></i>
                           <span>Upload file</span>
                        </button>
                     </>
                  }  handleChange={(file: File)=> {sendFile(file, FileTypeValue.CERTIFICATIONS, authStore.userData.accessToken)}} />
               </div>
            </section>
            <div className="buttons-container">
               <button className="button button-secondary" onClick={() => props.changeSection(true)}><i className="fa-solid fa-arrow-left"></i> Back</button>
               <button className="button button-primary" onClick={() => {
                  goToNextComponentOrFinish(authStore.userData.accessToken);
                  description.trim().length > 0 && uploadText(authStore.userData.accessToken);
               }}>
                  {authStore.userData.isManager ? <>Next <i className="fa-solid fa-arrow-right"></i></> : "Finish"}
               </button>
            </div>
         </animated.div>
         {
            spinnerService.spinner
         }
      </>
   );

   function goToNextComponentOrFinish(accessToken: string) {
      const saveSkillsURL = process.env.REACT_APP_PUBLIC_URL + '/employee/save-employee-skills';

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      const data = {
         extractedSkills: skills.filter(s => !s.isSuggested || s.selected).map(s => s.value)
      }

      if (data.extractedSkills.length === 0) {
         props.changeSection();
         return;
      }

      spinnerService.createSpinner();

      axios
         .post(saveSkillsURL, data, {headers})
         .then(() => {
            spinnerService.removeSpinner();
            props.changeSection();
         })
         .catch(error$ => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     goToNextComponentOrFinish(response$.data.accessToken);
                  })
            } else {
               spinnerService.removeSpinner();
               showNotification('warning', error$.response.data.message);
            }
         })
   }

   function uploadText(accessToken: string) {
      const uploadTextURL = process.env.REACT_APP_PUBLIC_URL + '/file/save-text';

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      const data = {
         text: description,
         subCategory: FileTypeValue.PERSONAL_NOTES
      }

      axios
         .post(uploadTextURL, data, {headers})
         .then(() => {

         })
         .catch(error$ => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     uploadText(response$.data.accessToken);
                  })
            } else {
               showNotification('warning', error$.response.data.message);
            }
         })
   }



   function sendFile(file:File, subCategory: string, accessToken: string) {
      const saveFileURL = process.env.REACT_APP_PUBLIC_URL + '/file/save-file';

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      const bodyFormData = new FormData();
      bodyFormData.append('file', file!);
      bodyFormData.append('subCategory', subCategory);

      axios
         .post(saveFileURL, bodyFormData, {headers})
         .then(() => {
            showNotification("success", `Your file has been successfully uploaded`);
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     sendFile(file, subCategory, response$.data.accessToken);
                  })
            } else {
               showNotification('warning', error$.response.data.message);
            }
         });
   }


   function initializeComponent(accessToken: string) {
      const getSkillsURL = process.env.REACT_APP_PUBLIC_URL + "/employee/suggested-skills-for-employee";

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      spinnerService.createSpinner();

      axios
         .post(getSkillsURL, undefined, {headers})
         .then((response$: AxiosResponse<SkillDto[]>) => {
            spinnerService.removeSpinner();
            setSkills(response$.data.map(s => ({
               label: s.name,
               description: s.description,
               value: s.aiId,
               isSuggested: true
            })));
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     initializeComponent(response$.data.accessToken);
                  })
            } else {
               spinnerService.removeSpinner();
               showNotification('warning', error$.response.data.message);
            }
         });
   }

   function addSkillFromSelect(skill: SearchedSkillDto) {
      setSkills(prevState => [...prevState, skill]);
   }

   function selectSkill(skill: ProfileBoostSkillDto) {
      if (skill.isSuggested) {
         setSkills(prevState => prevState.map(s => s.value === skill.value ? ({...s, selected: !s.selected}) : s));
      }
      else {
         setSkills(prevState => prevState.filter(s => s.value !== skill.value));
      }
   }
}

export default ProfileBoostPage;