import React, {useRef, useState} from "react";
import {MultiSelect, MultiSelectChangeEvent} from "primereact/multiselect";

const MultiSelectDropDown: React.FC<{
   options: {name: string, code: string}[],
   selectedOptions: {name: string, code: string}[],
   onChange: (options: {name: string, code: string}[]) => void,
   selectedName: string,
}> = props => {

   const [selectedOptions, setSelectedOptions] = useState<{name: string, code: string}[]>(props.selectedOptions);
   const ref = useRef<MultiSelect>(null);

   return (
      <>
         <MultiSelect ref={ref} value={selectedOptions} onChange={(e: MultiSelectChangeEvent) => {
            setSelectedOptions(e.value);
         }} options={props.options} optionLabel="name"
                      panelHeaderTemplate={event => {
                         return (
                            <div className={'p-multiselect-header-custom'}>
                               <div className="p-multiselect-filter-container">
                                  <i className="fa-solid fa-magnifying-glass"></i>
                                  <input className={'p-multiselect-filter'} type="text" onChange={e => {
                                     const filterValue = e.target.value;
                                     const filterEvent = {
                                        originalEvent: e,
                                        query: filterValue
                                     };
                                     if (event.props.onFilter) {
                                        event.props.onFilter(filterEvent as any);
                                     }
                                  }} placeholder="Search..."/>
                               </div>

                               <div className={`p-multiselect-checkbox-container-custom ${event.checked ? 'p-highlight' : ''}`} onClick={e => {
                                  event.onChange({originalEvent: e as any, checked: !event.checked});
                                  e.stopPropagation();
                               }}>
                                  <div className="p-checkbox">
                                     <div className="p-checkbox">
                                        {event.checked && <i className="fa-solid fa-check"></i>}
                                     </div>
                                  </div>
                                  <span>Select all</span>
                               </div>
                               <div className="p-header-line"></div>
                            </div>
                         );
                      }}
                      panelFooterTemplate={(_event) => {
                         return (
                            <div className={'p-footer-template'}>
                               <div className="buttons-row u-margin-top-m">
                                  <button disabled={selectedOptions.length === 0}
                                          className="button button-primary u-margin-right-m"
                                          onClick={() => {props.onChange(selectedOptions); ref.current!.hide()}}>Apply</button>
                                  <button className="button button-tertiary" onClick={() => ref.current!.hide()}>Cancel</button>
                                  <button className="button button-tertiary reset-button" onClick={() => {props.onChange([]); ref.current!.hide()}}>Reset</button>
                               </div>
                            </div>
                         );
                      }}
                      filterPlaceholder={'Search'}
                      selectedItemsLabel={`${props.selectedName}: ${selectedOptions.length}`}
                      onHide={() => setSelectedOptions(props.selectedOptions)}
                      dropdownIcon={() => <i className="fa-solid fa-caret-down u-margin-left-xs"></i>}
                      checkboxIcon={() => <i className="fa-solid fa-check"></i>}
                      filter placeholder={`${props.selectedName}: All`} maxSelectedLabels={0}
                      className="w-full multi-select md:w-20rem"/>
      </>
   );
}

export default MultiSelectDropDown;