import {ApprovedStatus, SkillsListInformationDto} from "../types/SkillsData";

export const SKILL_MESSAGES_TITLE = {
   [ApprovedStatus.NEW]: "No extracted skills",
   [ApprovedStatus.LEARNING]: "No learning skills",
   [ApprovedStatus.REJECTED]: "No denied skills",
   [ApprovedStatus.APPROVED]: "No validated skills",
};

export function getMessages(status: ApprovedStatus, skillListInformation: SkillsListInformationDto) {
   const messages = [];
   const approvedMessage = skillListInformation.approvedListTotalCount > 0 ? `Approved: ${skillListInformation.approvedListTotalCount}` : '';
   const rejectedMessage = skillListInformation.rejectedListTotalCount > 0 ? `Denied: ${skillListInformation.rejectedListTotalCount}` : '';
   const learningMessage = skillListInformation.learningListTotalCount > 0 ? `Learning: ${skillListInformation.learningListTotalCount}` : '';
   const toReviewMessage = skillListInformation.newListTotalCount > 0 ? `To review: ${skillListInformation.newListTotalCount}` : '';

   if (status === ApprovedStatus.NEW) {
      const baseMessage = skillListInformation.approvedListTotalCount > 0 ? 'Great news! You\'ve cleared your "To review list"!' : 'Your "To review list" is empty! Though your validated list is empty, you can upload more data to get more extracted skills.'
      const additionalMessage = skillListInformation.approvedListTotalCount > 0 ? 'Now, let\'s explore your skills:' : (skillListInformation.rejectedListTotalCount > 0 || skillListInformation.learningListTotalCount > 0) ? 'Also, there\'s still more to discover:' : '';
      const additionalRejectedMessage = skillListInformation.approvedListTotalCount === 0 && skillListInformation.rejectedListTotalCount > 0 ? 'Psst! You can "undo" some rejected skills to bring them back for review!' : '';
      return (
         <span>
            <span className={'u-margin-bottom-xs'}>{baseMessage}</span>
            {additionalMessage.length > 0 && <><br/>{additionalMessage}<br/></>}
            <ul className={'u-margin-bottom-xxs'}>
               {skillListInformation.rejectedListTotalCount > 0 && <li><span>{rejectedMessage}</span></li>}
               {skillListInformation.approvedListTotalCount > 0 && <li><span>{approvedMessage}</span></li>}
               {skillListInformation.learningListTotalCount > 0 && <li><span>{learningMessage}</span></li>}
            </ul>
            <span>{additionalRejectedMessage}</span>
         </span>
      )
   } else if (status === ApprovedStatus.LEARNING) {
      const baseMessage = 'Empty learning list? Go and chat with your manager about an upskilling plan, explore opportunities to learn and grow, and when you\'re ready to tackle new challenges, add them here to track your progress!';
      const plusMessage = skillListInformation.approvedListTotalCount > 0 || skillListInformation.rejectedListTotalCount > 0 || skillListInformation.newListTotalCount > 0;
      return <span>
         <span className={'u-margin-bottom-xs'}>{baseMessage}</span>
         {plusMessage && <span><br/>In the meantime, check out the other skills:<br/></span>}
         <ul>
            {skillListInformation.rejectedListTotalCount > 0 && <li><span>{rejectedMessage}</span></li>}
            {skillListInformation.approvedListTotalCount > 0 && <li><span>{approvedMessage}</span></li>}
            {skillListInformation.newListTotalCount > 0 && <li><span>{toReviewMessage}</span></li>}
         </ul>
      </span>
   } else if (status === ApprovedStatus.REJECTED) {
      return <span>Woohoo! Your rejected list is clean as a whistle!</span>
   }
   else { // ApprovedStatus.APPROVED
      const baseMessage = toReviewMessage.length > 0 ? ' Empty validated list? No worries!' +
         ` Let's dive into your ${skillListInformation.newListTotalCount} "to review" skills and give them a thumbs up by approving the ones that are extracted correctly for you!` : 'Empty validated and "to review" lists? Time to upload more data so you can get more extracted skills!';
      const additionalBaseMessage = toReviewMessage.length > 0 ? (skillListInformation.rejectedListTotalCount > 0 || skillListInformation.learningListTotalCount > 0) ? 'And hey, check out these other skills too:' : '' : (skillListInformation.rejectedListTotalCount > 0 || skillListInformation.learningListTotalCount > 0) ? 'Meanwhile, peek into these skills:' : '';
      const approvedPlusMessage = toReviewMessage.length === 0 && rejectedMessage.length > 0 ? 'Psst! You can "undo" some rejected skills to bring them back for review!' : '';
      return <span>
         <span className={'u-margin-bottom-xs'}>{baseMessage}</span>
         {additionalBaseMessage.length > 0 && <><br/>{additionalBaseMessage}<br/></>}
         <ul className={'u-margin-bottom-xxs'}>
             {skillListInformation.rejectedListTotalCount > 0 && <li><span>{rejectedMessage}</span></li>}
            {skillListInformation.learningListTotalCount > 0 && <li><span>{learningMessage}</span></li>}
         </ul>
         <span className={'u-margin-top-s'}>{approvedPlusMessage}</span>
      </span>;
   }
}