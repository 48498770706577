import React, { useState, useEffect } from 'react';

import styles from './AddEmployeeComponent.module.scss';

import useLoadingSpinner from "../../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner";

import AddColleaguesComponent from './AddColleaguesComponent/AddColleaguesComponent';
import EmployeesGridComponent from './EmployeesGrid/EmployeesGridComponent';
import {UserDataDto} from "../../../../../types/AdminData";

const AddEmployeeComponent: React.FC<{gridData: UserDataDto[], changeSection: Function}> = (props) => {
  let spinnerService = useLoadingSpinner();
  
  const [activeEmployeeSection, setActiveEmployeeSection] = useState('add-employee-upload');

  useEffect(()=> {
    if (props.gridData.length > 0) {
      setActiveEmployeeSection('add-employee-grid');
    }
  }, [props.gridData])

  return (
    <div className={styles['add-employee-component']}>
      <div className='row'>
        <div className='col-12'>
          {
            activeEmployeeSection === 'add-employee-upload' && 
              <AddColleaguesComponent changeSection={props.changeSection} changeEmployeesSection={changeEmployeeSection} />
          }
          {
            activeEmployeeSection === 'add-employee-grid' &&
            <EmployeesGridComponent gridData={props.gridData} changeEmployeesSection={changeEmployeeSection} changeSection={props.changeSection} />
          }
        </div>
      </div>
      <>
        {
          spinnerService.spinner
        }
      </>      
    </div>
  )

  function changeEmployeeSection (section: string): void {
    setActiveEmployeeSection(section);
  }
}

export default AddEmployeeComponent;