import React, {useEffect, useState} from 'react';
import styles from './Uploader.module.scss';

import useModal from '../../../../services/modal.service';
import FileTypeErrorModal from '../FileTypeErrorModal/FileTypeErrorModal';
import {FileUploader} from "react-drag-drop-files";
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';

const Uploader: React.FC<{
   uploadFile: (file: File) => void,
   cancelUpload: () => void,
   progress: number,
   category: string
}> =
   (props) => {
      const [file, setFile] = useState<any>(null);
      const fileTypeErrorModal = useModal();
      const allowedFileTypes: string[] = getAllowedFieTypes(props.category);

      useEffect(() => {
         if (file) {
            props.uploadFile(file);
         }
      }, [file]);

      return (
         <div className={styles['uploader-modal']}>
            <div className='card-modal'>
               <div className='card-body'>
                  {
                     !file &&
                     <FileUploader name="fileHiddenInput" types={allowedFileTypes} children={
                        <div className='upload-zone'>
                           {
                              props.progress === 0 ?
                                 <>
                                    <div className='icon-container'>
                                       <i className="fa-solid fa-arrow-up-from-bracket"></i>
                                    </div>
                                    <p className='alt'>Drag & Drop here<br/>or<br/><span
                                       className='select-file-text'>Select file</span></p>
                                 </> :
                                 <div className='progress-chart'>
                                    <CircularProgressbar
                                       value={props.progress}
                                       text={`${props.progress}%`}
                                       strokeWidth={2}
                                       styles={buildStyles({
                                          pathColor: 'rgb(0, 238, 152)',
                                          textColor: 'rgb(0, 238, 152)',
                                          trailColor: '#9A9DA7'
                                       })}/>
                                    <p className='alt text-secondary'>Uploading file</p>
                                 </div>
                           }
                        </div>
                     } handleChange={browseFile} maxSize={100} onTypeError={handleTypeError}/>
                  }
                  {
                     file &&
                     <FileUploader name="fileHiddenInput" types={allowedFileTypes} children={
                        <div className='upload-zone'>
                           {
                              props.progress === 0 ?
                                 <>
                                    <div className='icon-container'>
                                       <i className="fa-regular fa-file"></i>
                                    </div>
                                    <div className='alt u-margin-bottom-xs'>The {file.name} was uploaded</div>
                                    <div className='select-file-text'>Change file</div>
                                 </> :
                                 <div className='progress-chart'>
                                    <CircularProgressbar
                                       value={props.progress}
                                       text={`${props.progress}%`}
                                       strokeWidth={2}
                                       styles={buildStyles({
                                          pathColor: 'rgb(0, 238, 152)',
                                          textColor: 'rgb(0, 238, 152)',
                                          trailColor: '#9A9DA7'
                                       })}/>
                                    <p className='alt text-secondary'>Uploading file</p>
                                 </div>
                           }
                        </div>
                     } handleChange={browseFile} maxSize={100} onTypeError={handleTypeError}/>
                  }
               </div>
            </div>
            {
               fileTypeErrorModal.showModal &&
               <FileTypeErrorModal data={fileTypeErrorModal.modalData} removeModal={fileTypeErrorModal.removeModal}/>
            }
         </div>
      )

      function browseFile(newFile: any) {
         let hiddenFileInput = document.getElementsByName('fileHiddenInput')[0] as HTMLInputElement;
         if (hiddenFileInput) {
            hiddenFileInput.value = "";
         }
         setFile(newFile);
      }

      function getAllowedFieTypes(category: string): string[] {
         switch (category) {
            case 'CV':
               return ['pdf', 'txt', 'docx'];
            case 'LINKEDIN':
               return ['pdf', 'txt', 'docx'];
            case 'PSYCHOMETRIC_ASSESSMENTS':
               return ['pdf', 'txt', 'docx'];
            case 'LOTUS':
               return ['xlsx', 'csv', 'tsv'];
            case 'PLANVIEW':
               return ['xlsx', 'csv', 'tsv'];
            case 'ASANA':
               return ['xlsx', 'csv', 'tsv'];
            case 'TRELLO':
               return ['json'];
            case 'MEETING_NOTES':
               return ['pdf', 'txt', 'docx'];
            default:
               return ['pdf', 'txt', 'docx'];
         }
      }

      function handleTypeError(error: string) {
         let category: string = props.category;
         if (category === 'MEETING_NOTES') {
            category = 'MEETING NOTES';
         } else if (category === 'PSYCHOMETRIC_ASSESSMENTS') {
            category = 'PSYCHOMETRIC ASSESSMENTS';
         }
         fileTypeErrorModal.createModal({category: category, allowedTypes: allowedFileTypes});
      }

      function returnFileCategory(type: string): string {
         switch (type) {
            case 'MEETING_NOTES':
               return 'MEETING NOTES';
            case 'PSYCHOMETRIC_ASSESSMENTS':
               return 'PSYCHOMETRIC ASSESSMENTS';
            default:
               return type;
         }
      }
   }

export default Uploader;