import React, { useContext } from 'react';
import styles from './AddColleaguesComponent.module.scss';
import Tooltip from '../../../../../../services/tooltip.service';
import AuthContext from '../../../../../../store/auth-context';
import AdminPanelContext from '../../../../../../store/admin-panel-context';
import { FileUploader } from 'react-drag-drop-files';
import { UserData } from '../../../../../../types/AuthData'; 
import { showNotification } from '../../../../../../ui/Toast/ToastNotification';
import useLoadingSpinner from '../../../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner';
import useApiService from '../../../../../../services/api.service';
import {ErrorResponseDto} from "../../../../../../types/ErrorData";

const AddColleaguesComponent: React.FC<{changeSection?: Function, changeEmployeesSection: Function}> = (props) => {
  const authStore = useContext(AuthContext);
  const adminPanelStore = useContext(AdminPanelContext);
  const userData: UserData = authStore.userData;
  const spinnerService = useLoadingSpinner();
  const { refreshToken } = useApiService();

  return (
    <div className={styles["add-colleagues-component"]}>
      <div className="row">
        <div className="col-12">
             <div className='add-colleagues-header'>
               <button className='button button-tertiary' onClick={() => props.changeSection ? props.changeSection('tabs-section') : props.changeEmployeesSection('add-categories')}>
                 <i className="fa-solid fa-arrow-left"></i> Back
               </button>
             </div>
          <h1 className={'header-1 u-margin-top-s'}>Add your colleagues</h1>
          <p className='text-secondary u-margin-top-xs'>
            First we need to gather people from your company!<br/>
            After that managers from projects will be able to create teams.
          </p>
          <div className='buttons-wrapper u-margin-top-xs'>
            <a className='button button-tertiary download-template-button'
               href={adminPanelStore.csvFileDownloadLink} download>Download the
              template (.csv)</a>
            <div className='buttons u-margin-top-xxs'>
              <Tooltip
                 place="bottom"
                 tooltipId={"upload-csv-file"}
                 backgroundColor="#101319"
                 borderColor="#5C5F6A !important"
              border={true}
              content={
                <div style={{'maxWidth': '240px'}}>
                  <h4 style={{'fontSize': '15px', 'marginBottom': '4px', 'marginTop': '8px'}}>Follow the template</h4>
                  <p style={{'fontSize': '14px', 'marginBottom': '4px'}}>Please, make sure you follow exactly the same format as it is in the template.</p>
                </div>}
              className=""
            >
              <FileUploader name="fileHiddenInput" classes={"file-uploader"} children={
                <button className='btn btn-blue-custom'><i className="fa-solid fa-arrow-up-from-bracket"></i>Upload  file  (.csv)</button>
              }  handleChange={(file: any)=>uploadEmployeeFile(file, userData.accessToken)} />
            </Tooltip>
              <button className='btn btn-blue-custom-dark' onClick={() => props.changeEmployeesSection('add-employee-grid')}>Add colleagues manually</button>
            </div>
          </div>
        </div>
      </div>
      {
        spinnerService.spinner
      }
    </div>
  );

  function uploadEmployeeFile (file: any, accessToken: string): void {
    spinnerService.createSpinner();
    
    let hiddenFileInput = document.getElementsByName('fileHiddenInput')[0] as HTMLInputElement;
    if (hiddenFileInput) {
      hiddenFileInput.value = "";
    }
    adminPanelStore.uploadCSVFile(file, accessToken)
      .then(()=> {
        spinnerService.removeSpinner();
        props.changeEmployeesSection('add-employee-grid')
      })
      .catch((error$: ErrorResponseDto)=> {
        if (error$.response.data.message === 'Unauthorized') {
          // Get new Access Token
          refreshToken(authStore.userData.refreshToken)
            .then((response$: any) => {
              authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
              uploadEmployeeFile(file, response$.data.accessToken);					
            })
        }
        else {
          spinnerService.removeSpinner();
          showNotification('warning', error$.response.data.message);
        }
      });
  }
}

export default AddColleaguesComponent;