import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import styles from './AllProjectsPage.module.scss';
import axios, {AxiosRequestConfig} from 'axios';

import AuthContext from '../../../../../store/auth-context';
import {UserData} from '../../../../../types/AuthData';
import useLoadingSpinner from '../../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner';
import useApiService from '../../../../../services/api.service';
import {showNotification} from '../../../../../ui/Toast/ToastNotification';
import OnboardingManagerContext from "../../../../../store/create-project-context";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {ProjectData} from "../../../../../types/ProjectDashboardData";
import {PROJECT_SORT_TYPE, PROJECT_STATUS} from "../../../../../enums/Projects.enum";
import ProjectsComponent from "./ProjectsComponent/ProjectsComponent";
import {ErrorResponseDto} from "../../../../../types/ErrorData";

const AllProjectsPage: React.FC<{ jobPosting?: boolean }> = props => {
  const authStore = useContext(AuthContext);
  const userData: UserData = authStore.userData;
  const spinnerService = useLoadingSpinner();
  const navigate = useNavigate();
  const apiService = useApiService();
  const onboardingManagerStore = useContext(OnboardingManagerContext);

  const [initializedComponent, setInitializedComponent] = useState<boolean>(false);

  const [projectsList, setProjectsList] = useState<any>([]);
  const ongoingProjectsList = projectsList.filter((item: any) => item.status === PROJECT_STATUS.ONGOING.valueOf());
  const draftProjectsList = projectsList.filter((item: any) => item.status === PROJECT_STATUS.DRAFT.valueOf());
  const archiveProjectsList = projectsList.filter((item: any) => item.status === PROJECT_STATUS.ARCHIVED.valueOf());

  // Check if the user is manager or team leader
  let hasCreateProject: boolean = userData.isManager;

  const filterOptions = [
    {
      title: "Newest first",
      value: PROJECT_SORT_TYPE.NEWEST
    },
    {
      title: "Oldest first",
      value: PROJECT_SORT_TYPE.OLDEST
    },
    {
      title: "By name",
      value: PROJECT_SORT_TYPE.TITLE
    }
  ];

  useEffect(()=> {
    authStore.changePageTitle(props.jobPosting ? "Tasks" : "Projects Hub");
    getProjects(userData.accessToken);
  }, [])

  return (
    <div className={styles['all-projects-page']}>
      {
        initializedComponent &&
        <div className="container-fluid">
          <div className='title-wrapper'>
            {
              props.jobPosting ? <h1 className='header-1'>Task matcher</h1> : <h1 className='header-1'>All projects <span className='projects-counter'>{projectsList.length}</span></h1>
            }
            {
               hasCreateProject &&
               <button onClick={showCreateProject} className={'button button-primary project-add-button'}><img src='/assets/images/global-icons/plus-sign.svg' alt={'plus sign'}/><span>{props.jobPosting ? 'Add new task' : 'Create new project'}</span></button>
            }
          </div>
           {
              props.jobPosting &&
              <p className={'text-surface-30 u-margin-bottom-s'}>Seamlessly connect with the right talent for your task. Our
                 AI-powered tool analyzes your task requirements and identifies candidates with the skills to excel in your specific needs.</p>
           }
          <div className={'tabs-container'}>
            <Tabs className={'custom-tabs'} selectedTabClassName={'selected-tab'}>
              <TabList>
                <Tab>Ongoing <span className={'badge rounded-pill'}>{ongoingProjectsList.length}</span></Tab>
                <Tab>Drafts <span className={'badge rounded-pill'}>{draftProjectsList.length}</span></Tab>
                <Tab>Archived <span className={'badge rounded-pill'}>{archiveProjectsList.length}</span></Tab>
              </TabList>
              <TabPanel>
                <ProjectsComponent projectList={ongoingProjectsList} filterOptions={filterOptions}
                                   changeProjectStatus={changeProjectStatus} deleteProjects={deleteProjects}
                                   changeProjectStatusesOrDelete={changeProjectStatusesOrDelete}
                                   isManager={hasCreateProject} projectStatus={PROJECT_STATUS.ONGOING}
                                   jobPosting={props.jobPosting}/>
              </TabPanel>
              <TabPanel>
                <ProjectsComponent projectList={draftProjectsList} filterOptions={filterOptions}
                                   changeProjectStatus={changeProjectStatus} deleteProjects={deleteProjects}
                                   changeProjectStatusesOrDelete={changeProjectStatusesOrDelete}
                                   isManager={hasCreateProject} projectStatus={PROJECT_STATUS.DRAFT}
                                   jobPosting={props.jobPosting}/>
              </TabPanel>
              <TabPanel>
                <ProjectsComponent projectList={archiveProjectsList} filterOptions={filterOptions}
                                   changeProjectStatus={changeProjectStatus} deleteProjects={deleteProjects}
                                   changeProjectStatusesOrDelete={changeProjectStatusesOrDelete}
                                   isManager={hasCreateProject} projectStatus={PROJECT_STATUS.ARCHIVED}
                                   jobPosting={props.jobPosting}/>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      }
      {
        spinnerService.spinner
      }
    </div>
  )

  function changeProjectStatusesOrDelete(type: string, ids: number[]) {
    if (type === 'DELETE') {
      setProjectsList((prevState: any) => {
        return prevState.filter((project: ProjectData) => !ids.includes(project.id));
      })
    } else if (type === 'MOVE_ARCHIVE') {
      setProjectsList((prevState: any) => {
        return prevState.map((project: ProjectData) => {
          return ids.includes(project.id) ? {...project, status: 'ARCHIVED'} : project
        });
      })
    }
  }

  function getProjects(accessToken: string) {
    const getProjectsURL = process.env.REACT_APP_PUBLIC_URL + `/manager/${props.jobPosting ? 'tasks' : 'projects'}`;
    spinnerService.createSpinner();

    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }

    axios
       .get(getProjectsURL, {headers}
       )
       .then((response$: any) => {
         // Is onboarding
         if (response$.data.onboarding && !props.jobPosting) {
           navigate('/manager/onboarding-manager');
         } else {
           setProjectsList(response$.data.projects);
         }
         setInitializedComponent(true);
         spinnerService.removeSpinner();
       })
       .catch((error$: ErrorResponseDto) => {
         if (error$.response.data.message === 'Unauthorized') {
           // Get new Access Token
           apiService.refreshToken(authStore.userData.refreshToken)
              .then((response$: any) => {
                authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                getProjects(response$.data.accessToken);
              })
         } else {
          setInitializedComponent(true);
          spinnerService.removeSpinner();
          showNotification('warning', error$.response.data.message);
        }
      })
  }

  function deleteProjects(projectIds: number[], accessToken: string){
    spinnerService.createSpinner();

    onboardingManagerStore.deleteProject(projectIds, accessToken)
        .then((_resp$: any) => {
          spinnerService.removeSpinner();
          return;
        })
        .catch((error$: ErrorResponseDto)=> {
          if (error$.response.data.message === 'Unauthorized') {
            // Get new Access Token
            apiService.refreshToken(authStore.userData.refreshToken)
                .then((response$: any) => {
                  authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                  deleteProjects(projectIds,response$.data.accessToken);
                })
          }
          else {
            spinnerService.removeSpinner();
            showNotification('warning', error$.response.data.message);
          }
        })
  }

  function changeProjectStatus(projectIds: number[],change: boolean, accessToken: string){
    spinnerService.createSpinner();

    onboardingManagerStore.changeProjectStatus(projectIds,change,accessToken)
        .then((_resp$: any) => {
          spinnerService.removeSpinner();
          return;
        })
        .catch((error$: ErrorResponseDto)=> {
          if (error$.response.data.message === 'Unauthorized') {
            // Get new Access Token
            apiService.refreshToken(authStore.userData.refreshToken)
                .then((response$: any) => {
                  authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                  changeProjectStatus(projectIds,change,response$.data.accessToken);
                })
          }
          else {
            spinnerService.removeSpinner();
            showNotification('warning', error$.response.data.message);
          }
        })
  }

  function showCreateProject() {
    navigate(`/manager/${props.jobPosting ? 'tasks' : 'projects'}/create`);
  }
}

export default AllProjectsPage;