import styles from './ProjectTeamWrapperComponent.module.scss';
import ProjectTeamComponent from "../../../../CreateProjectComponent/OnboardingManagerProcessComponent/ProjectTeamComponent/ProjectTeamComponent";
import useLoadingSpinner from "../../../../../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner";
import React from "react";
const ProjectTeamWrapperComponent: React.FC<{projectId: number, removeEditComponent: Function}> = (props) => {

   return (
      <div className={styles['project-team-wrapper']}>
         <div className='container-fluid'>
            <div className='row'>
               <div className='col-12'>
                  <div className='onboarding-manager-modal'>
                     <div className='card-modal'>
                        <div className='row'>
                           <div className='col-12'>
                              <div className={`modal-header`}>
                                 <div className='logo-container'>
                                    <img src='/assets/images/global-icons/teamlift-logo-white.svg' alt="TeamLift logo" />
                                 </div>
                                 <div className='logout-button' onClick={closeHandler}>
                                    Close
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div className='modal-body'>
                           <div className="wrapper-component">
                              <ProjectTeamComponent projectId={props.projectId} clickedSaveAsDraft={false} saved={closeHandler} saveProgress={closeHandler} changeSection={closeHandler} />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );

   function closeHandler() {
      props.removeEditComponent();
   }
}

export default ProjectTeamWrapperComponent;