import React, {useContext, useEffect, useState} from "react";
import {
   AnswerDto,
   QuestionAnswers, QuestionsDto,
   QuestionsResponseDto,
} from "../../../../../types/PersonaTypes";
import style from './TestPage.module.scss';
import AuthContext from "../../../../../store/auth-context";
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {ErrorResponseDto} from "../../../../../types/ErrorData";
import {showNotification} from "../../../../../ui/Toast/ToastNotification";
import useLoadingSpinner from "../../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner";
import useApiService from "../../../../../services/api.service";
import {useNavigate} from "react-router-dom";
import {animated, useTransition} from "react-spring";
import SpinnerButton from "../../../../../ui/SpinnerButton/SpinnerButton";

const TestPage: React.FC = () => {

   const authStore = useContext(AuthContext);
   const navigate = useNavigate();
   const [assessmentId, setAssessmentId] = useState<number>();
   const [data, setData] = useState<QuestionsDto[]>([]);
   const [activeSection, setActiveSection] = useState(0);
   const [savedAnswers, setSavedAnswers] = useState(new Map<number, AnswerDto[]>());
   const [dataLoading, setDataLoading] = useState<boolean>(false);
   const spinnerService = useLoadingSpinner();
   const {refreshToken} = useApiService();

   const transition = useTransition(data[activeSection], {
      from: {opacity: 0, transform: "translateX(10%)"},
      enter: {opacity: 1, transform: "translateX(0%)"},
      config: {duration: 400}
   });

   useEffect(() => {
      initializeComponent(authStore.userData.accessToken);
   }, []);

   return (
      <>
         {
            data.length > 0 &&
            <div className={style['test-page']}>
               <div className="header-1 heading">
                  <img src='/assets/images/global-icons/teamlift-logo-white.svg' alt="Teamlift logo"/>
               </div>

               <main>
                  <div className="title">
                     <p>The questions present you with two options (note: they are not opposites). Please
                        consider the
                        options in a professional context and place yourself on a scale between the two. If you
                        are
                        indifferent, you can select no preference.</p>
                  </div>

                  <div className="exit">
                     <button className="button button-secondary u-margin-top-s"
                             onClick={() => navigate("/employee/skills-wallet")}>Exit
                     </button>
                  </div>

                  <div className="progress-bar">
                     {
                        data.map((_item, index) => (
                           <div key={index}
                                className={`progress-bar-bar ${index <= activeSection ? "active" : ""}`}></div>
                        ))
                     }
                  </div>
                  {
                     transition((style, item) => (
                        <animated.section style={style} className="questions">
                           {
                              item.questions.map(q => {
                                 return (
                                    <div key={q.questionId} className={`question`}>
                                       <div className="question-title">
                                          <div className="option-a">{q.optionA}</div>
                                          <div className="option-b">{q.optionB}</div>
                                       </div>
                                       <FormControl className="question-choices">
                                          <RadioGroup value={getValueForButton(q.questionId)} row
                                                      name={"question-choices"}
                                                      className={'question-choices-group'}>
                                             {
                                                QuestionAnswers.option.map(option => {
                                                   return (
                                                      option.weight.map(w => {
                                                         return (
                                                            <FormControlLabel onClick={() => saveAnswer({
                                                               questionId: q.questionId,
                                                               weight: w.id,
                                                               option: option.name
                                                            })} key={w.id} value={`${w.id}_${option.name}`}
                                                                              control={<Radio
                                                                                 className={"mui-radio"}/>}
                                                                              label={w.name}/>
                                                         )
                                                      })
                                                   )
                                                })
                                             }
                                          </RadioGroup>
                                       </FormControl>
                                    </div>
                                 )
                              })
                           }
                        </animated.section>
                     ))
                  }

                  <div className="buttons-wrapper">
                     <button disabled={activeSection === 0} className="button button-secondary"
                             onClick={() => changeSection(true)}>Previous Questions
                     </button>
                     <SpinnerButton
                        initialDisabled={savedAnswers.get(activeSection)!.length < data[activeSection].questions.length}
                        cssClasses="button button-primary"
                        onClickCallback={() => changeSection()} dataLoading={dataLoading}
                        title={activeSection === data.length - 1 ? "Finish" : "Next Questions"}></SpinnerButton>
                  </div>
               </main>
            </div>
         }
         {
            spinnerService.spinner
         }
      </>
   )

   function saveAnswers(accessToken: string) {
      const saveQuestionsURL = process.env.REACT_APP_PUBLIC_URL + "/answer/section";

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      const answerData = {
         userId: authStore.userData.userId,
         assessmentId: assessmentId,
         answers: savedAnswers.get(activeSection)
      }

      setDataLoading(true);

      axios
         .post(saveQuestionsURL, answerData, {headers})
         .then(_response$ => {
            if (activeSection === data.length - 1) {
               navigate(`/persona/results`);
            } else {
               setActiveSection(prevState => prevState + 1);
            }
            setDataLoading(false);
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     saveAnswers(response$.data.accessToken);
                  })
            } else {
               setDataLoading(false);
               showNotification("warning", error$.response.data.message);
            }
         })

   }

   function getValueForButton(questionId: number) {
      const answer = savedAnswers.get(activeSection)?.find(question => question.questionId === questionId);
      return answer ? `${answer.weight}_${answer.option}` : "";
   }

   function changeSection(goBack?: boolean) {
      if (goBack && activeSection !== 0) {
         setActiveSection(prevState => prevState - 1);
      } else {
         if (savedAnswers.get(activeSection)!.length === data[activeSection].questions.length) {
            saveAnswers(authStore.userData.accessToken);
         } else {
            showNotification("warning", "Something went wrong, please try again!");
         }
      }
   }

   function initializeComponent(accessToken: string) {
      const getQuestionsURL = process.env.REACT_APP_PUBLIC_URL + "/question/all";

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      const params: AxiosRequestConfig['params'] = {
         userId: authStore.userData.userId
      };

      const config: AxiosRequestConfig = {
         headers,
         params
      };

      spinnerService.createSpinner();

      axios
         .get(getQuestionsURL, config)
         .then((response$: AxiosResponse<QuestionsResponseDto>) => {
            if (response$.data.finished) {
               navigate("/persona/results");
               return;
            }
            response$.data.questions.forEach((_question, index) => {
               savedAnswers.set(index, []);
            });
            setData(response$.data.questions);
            setAssessmentId(response$.data.assessmentId);
            spinnerService.removeSpinner();
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     initializeComponent(response$.data.accessToken);
                  })
            } else {
               spinnerService.removeSpinner();
               showNotification("warning", error$.response.data.message);
            }
         })

   }

   function saveAnswer(answer: AnswerDto) {
      const answersForCurrentSection = savedAnswers.get(activeSection)!;
      if (answersForCurrentSection.find(a => a.questionId === answer.questionId)) {
         setSavedAnswers(prevState => {
            const newMap = new Map(prevState);
            newMap.set(activeSection, answersForCurrentSection.map(a => a.questionId === answer.questionId ? answer : a));
            return newMap;
         });
      } else {
         setSavedAnswers(prevState => {
            const newMap = new Map(prevState);
            newMap.set(activeSection, [...answersForCurrentSection, answer]);
            return newMap;
         });
      }
   }
}

export default TestPage;