import {useEffect, useRef, useState} from "react";
import {getEmptyImage} from "react-dnd-html5-backend";
import {useDrag} from "react-dnd";
import {Avatar} from "@mui/material";
import {showNotification} from "../../../../../../../../ui/Toast/ToastNotification";

export default function DragItem(props) {
   const [{isDragging}, drag, preview] = useDrag({
      type: "ITEM",
      item: () => {
         let dragFields = [];
         if (props.selectedFields.find(element => element.employeeId === props.id)) {
            dragFields = props.selectedFields;
         } else {
            dragFields = [...props.selectedFields, props.field];
         }
         return {fields: dragFields, source: props.selectedSource};
      },
      end: (item, monitor) => {
         const dropResult = monitor.getDropResult();
         // When dropped on a compatible target, do something
         if (dropResult) {
            props.addItemsToCart(item.fields, item.source, dropResult);
            props.clearItemSelection();
         }
      },
      collect: (monitor) => ({
         isDragging: monitor.isDragging()
      })
   });

   useEffect(() => {
      preview(getEmptyImage(), {
         captureDraggingState: true
      });
   }, []);

   const selected = props.selectedFields.find((field) => props.id === field.employeeId);
   let opacity = false;

   if (props.selectedSkill) {
      opacity = !props.field.coveredSkills.find(object => object.skill_id === props.selectedSkill.skillId);
   }

   return (
      <div ref={drag} className={`skill-tag ${selected ? 'selected-skill' : ''} ${(opacity && props.selectedSkill) ? 'opacity' : ''} ${props.isLastChild ? 'lastChild' : ''}`}
           onClick={() => props.handleSelection(props.index)}>
         <span className={`check-box ${selected ? "selected" : ""}`}> <i className="fa-solid fa-check"></i></span>
         <div>
            {props.profilePicture ? <img src={props.profilePicture} alt={'img'}/> : <Avatar sx={{backgroundColor: props.color}} className={'img'}>{props.name.slice(0, 1).toUpperCase()}</Avatar>}
         </div>

         <div className="field-info">
            <div className="field-title">{props.name}</div>
            <div className={'field-projects'}><div>{props.field.projectAvailability}</div> <div className="field-hours">project{props.field.projectAvailability !== 1 ? 's' : ''}</div></div>
         </div>
         {
            props.teamComponent &&
            <>
               <div className={"field-learn"}>{props.field.skillsToLearn ? props.field.skillsToLearn.length : props.field.type === 'LEARNER' ? props.coveredSkills.length : 0}</div>
               <div className="field-skills">{props.field.skills ? props.field.skills.length : (props.field.type === 'CANDIDATE' || props.field.type === 'SIMILAR_PEOPLE') ? props.coveredSkills.length : 0}</div>
            </>

         }
         {
            !props.teamComponent && props.field.coveredSkills &&
            <div className="field-skills">{props.field.coveredSkills.length}</div>
         }
      </div>
   );
}