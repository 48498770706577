import { useEffect } from "react";
import { NavLink } from 'react-router-dom';
import styles from './RelatedNotApprovedSkills.module.scss';
import ReactTooltip from 'react-tooltip';
import { ProjectSkillData } from "../../../../../../types/ProjectOnboardingData";
import {capitalizeFirstLetter} from "../../../../../../utils/capitalize-first-letter";
import {SimilarJobPositionSkillDto} from "../../../../../../types/EmployeeDashboard";

const RelatedNotApprovedSkills: React.FC<{personalNotApprovedSkills: SimilarJobPositionSkillDto[]}> = (props) => {
    useEffect(() => {	
		ReactTooltip.rebuild();
	}, [props.personalNotApprovedSkills]);
    
    return (
        <div className={styles['related-not-approved-skills-card']}>
            <h3>Not approved skills, related to your current position:</h3>
            <p>Accept these skills now to upgrade your career potential!</p>
            <div className='skills-tags-wrapper'>
            { 
                props.personalNotApprovedSkills.map(skill => {
                    return <div key={skill.skill.id} className='skill-tag' data-html={true} data-for='skill-personal-not-approved'
                    data-tip={`<div style="">		
                        <h3 style="font-size: 16px; margin-top: 8px">${capitalizeFirstLetter(skill.synonym ? skill.synonym.name : skill.skill.name)}</h3>					
                        <p style="line-height: 140%; font-size: 14px">${skill.synonym ? skill.synonym.description ? skill.synonym.description : skill.skill.description : skill.skill.description}</p>
                    </div>`}>
                        {capitalizeFirstLetter(skill.synonym ? skill.synonym.name : skill.skill.name)}
                    </div>
                })
            }
            </div>
            <div className='u-margin-top-s'>
               <NavLink className={'button button-tertiary'} to='/employee/skills-wallet'>Open skills wallet <i className="fa-solid fa-arrow-right-long u-margin-left-xs"></i></NavLink>
            </div>
            <ReactTooltip id="skill-personal-not-approved" place="top" type="dark" aria-haspopup='true' html={true} effect="solid" event="click" globalEventOff="click"
					className='tooltip-class' delayShow={200} delayHide={200} isCapture={true} clickable={true}/> 
        </div>
    )
}

export default RelatedNotApprovedSkills;