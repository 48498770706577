import { useState, useContext, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

import styles from './CustomJobModal.module.scss';
import SpinnerButton from '../../../../../ui/SpinnerButton/SpinnerButton';
import AuthContext from '../../../../../store/auth-context';
import AsyncSelect from 'react-select/async';

import NotificationModal from '../PersonalGrowthComponent/NotificationModal/NotificationModal';
import useModal from '../../../../../services/modal.service';
import Tooltip from '../../../../../services/tooltip.service';
import {capitalizeFirstLetter} from "../../../../../utils/capitalize-first-letter";

type JobPosition = {
  id: string,
  name: string
}

const selectStyles = {
  container: (provided: {}) => ({
    ...provided,
    'flex': '1' 
  }), 
  control: (provided: {}) => ({
    ...provided,
    'backgroundColor': '#191D24',
    'border': '1px solid #2D2F38',
    'paddingLeft': '30px',
    'borderRadius': '8px'
  }), 
  indicatorSeparator: (provided: {}) => ({
    ...provided,
    'border': '1px solid #757575',
    'display': 'none'
  }),
  dropdownIndicator: (provided: {}) => ({
    ...provided,
    'color': '#757575',
    'display': 'none'
  }),
  clearIndicator: (provided: {}) => ({
    ...provided,
    'color': '#757575',
  }),
  placeholder: (provided: {}) => ({
    ...provided,
    'color': '#757575',
    'fontWeight': '600'
  }),
  loadingMessage: (provided: {}) => ({
    ...provided,
    'color': '#757575'
  }), 
  loadingIndicator: (provided: {}) => ({
    ...provided,
    'color': '#757575',
  }),
  input: (provided: {}) => ({
    ...provided,
    'color': '#F7F7F8'
  }),
  noOptionsMessage: (provided: {}) => ({
    ...provided,
    'color': '#757575'
  }),
  menu: (provided: {}) => ({
    ...provided,
    'backgroundColor': '#191D24'
  }),
  singleValue: (provided: {}) => ({
    ...provided,
    'color': '#F7F7F8'
  }),
  option: (provided: {}) => ({
    ...provided,
    'color': '#F7F7F8' 
  })       
}

const CustomJobModal: React.FC<{okCallback: Function, removeModal: React.MouseEventHandler<HTMLButtonElement>, 
  data: any, dataIsLoading: boolean }> = (props) => {  
  
  const authStore = useContext(AuthContext);
  const [similarJobsList, setSimilarJobsList] = useState<[] | JobPosition[]>([]);
  const [customJobsList, setCustomJobsList] = useState<[] | JobPosition[]>([]);
  const [newChartList, setNewChartList] = useState<[] | JobPosition[]>([]);
  const [selectOptions, setSelectOption] = useState<{value: string, label: string}[]>([]);
  const [selectValue, setSelectValue] = useState<{value: string, label: string} | null>(null) as any;

  const notifiationModal = useModal();

  useEffect(() => {
    let list: JobPosition[] = [] as JobPosition[];
    for (let item of props.data.similarJobs) {
      list.push({id: item.jobPosition.id, name: item.jobPosition.name});
    }

    let opt = [] as {value: string, label: string}[];
    for (let item of props.data.allJobs) {
      opt.push({value: item.id, label: capitalizeFirstLetter(item.name)});
    }
    setSelectOption(opt);
    setSimilarJobsList([...list]);
    setNewChartList([...list]);
  }, [])
  
  let modal = (
    <div className={styles['custom-job-modal']}>
      <div className='card card-modal'>
        <div className='card-header'>
          <h2>Modify jobs for career dashboard</h2>
          <div className='close-modal-button'>
          <i className="fa-solid fa-xmark" onClick={props.removeModal}></i>
          </div>  
        </div>
        <div className="card-body">         
         <div className='jobs-section'>
            <h3>Jobs related to {authStore.userData.displayName}'s progress</h3>
            <p><span><img src='/assets/images/global-icons/teamlift-icon-white.svg' alt='TeamLift logo'/></span> Based on TeamLift AI</p>
            <div className='tag-section'>
              { 
                similarJobsList && similarJobsList.map((position: JobPosition) => {   
                  let index = newChartList.findIndex(item => position.id === item.id);
                  return <div key={position.id} className={`skill-tag ${index > -1 ? 'selected' : ''}`} 
                    onClick={(e: React.MouseEvent) => {toggleSkill(e, position.id, position.name)}}>{capitalizeFirstLetter(position.name)}</div>
                })
              }
            </div>

            { customJobsList.length > 0 &&
              <>
                <p><span className='search'><img src='/assets/images/global-icons/search-icon.svg' alt='TeamLift logo'/></span>Based on your custom selection</p>
                <div className='tag-section'>
                { 
                  customJobsList.map((position: JobPosition) => {   
                    let index = newChartList.findIndex(item => position.id === item.id);
                    return <div key={position.id} className={`skill-tag ${index > -1 ? 'selected' : ''}`} 
                      onClick={(e: React.MouseEvent) => {toggleSkill(e, position.id, position.name)}}>{capitalizeFirstLetter(position.name)}</div>
                  })
                }
                </div>  
              </>     
            }

            <div className='custom-position-search'>
              <AsyncSelect
                cacheOptions loadOptions={loadOptions}
                className="basic-single"
                classNamePrefix="select"
                isClearable={true}          
                isSearchable={true} 
                value={selectValue}
                name="job-position"        
                placeholder="Search job position (ex: Data engineer)"
                noOptionsMessage={(value) => value.inputValue === "" ? "Start typing" : "No options" }
                onChange={(newValue) => { 
                  setSelectValue(newValue);          
                }}       
                styles={selectStyles}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: '#2D2F38',
                    primary: '#757575',
                  },
                })}                  
              />

              <button className='button button-tertiary u-margin-left-xs' disabled={selectValue === null} onClick={addCustomJobPosition}>
                <i className="fa-solid fa-plus"></i> Add position
              </button>
            </div>
         </div>
        </div>
        <div className="card-footer">
          <div className='buttons-wrapper'>
            <button className='button button-secondary' onClick={props.removeModal}>Cancel</button>
            {
              newChartList.length < 1 || newChartList.length > 5 ?
              <Tooltip
                place="top"
                tooltipId={"create-chart-tooltip"}
                backgroundColor="#101319"
                borderColor="#5C5F6A !important"
                border={true}
                content={<div style={{'fontSize': '14px'}}>
                    { 
                      newChartList.length < 1 && 'Must select at least one job position'
                    }
                    { 
                      newChartList.length > 5 && 'Select maximum five job positions'
                    }
                  </div>}
                className=""
              >
                <SpinnerButton cssClasses='button button-primary' title={`Build new career chart (${newChartList.length}/5)`} initialDisabled={true}
                dataLoading={props.dataIsLoading} buttonType='button'></SpinnerButton>
              </Tooltip> 
              :
              <SpinnerButton cssClasses='button button-primary' title={`Build new career chart (${newChartList.length}/5)`} initialDisabled={false}
              dataLoading={props.dataIsLoading} buttonType='button' onClickCallback={()=> props.okCallback(newChartList)}></SpinnerButton>

            }
          </div>
        </div>
      </div>
      {
        notifiationModal.showModal && <NotificationModal data={notifiationModal.modalData} removeModal={notifiationModal.removeModal} />
      }
    </div>
  )

  return (
    ReactDOM.createPortal(modal, document.getElementById('modal-root') as HTMLDivElement)
  )
 
  function toggleSkill(event: React.MouseEvent, id: string, name: string) {
    let index: number = newChartList.findIndex(item => item.id === id);
    // Add in the list
    if (index === -1) {
      setNewChartList(prevState => [...prevState, {id: id, name: name}])
    }
    else {
      // Remove from the list
      setNewChartList((prevState) => {
        let temp = [...prevState];
        temp.splice(index, 1);
        return temp;
      });
    } 
  }

  function addCustomJobPosition() {
    let value: string = selectValue?.value as string;
    let label: string = selectValue?.label as string;
    
    let indexfromCustomList = customJobsList.findIndex((item: JobPosition) => item.id === value);
    let indexfromSimilarList = similarJobsList.findIndex((item: JobPosition) => item.id === value);
    
    if (indexfromCustomList === -1 && indexfromSimilarList === -1) {
      setCustomJobsList([...customJobsList, {id: value, name: label}]);
      setNewChartList([...newChartList, {id: value, name: label}]);         
      setSelectValue(null);
    }
    else {
      notifiationModal.createModal({'jobPosition': label});
    } 
  }

  function loadOptions (inputValue: string, callback: (options: any) => void) {
    setTimeout(() => {
      callback(() => {
        return selectOptions.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));  
      });
    }, 1000);
  }
}

export default CustomJobModal;