export enum PROJECT_STATUS {
    ONGOING = "PROCESSED",
    DRAFT = "NEW",
    ARCHIVED = "ARCHIVED"
}

export enum PROJECT_SORT_TYPE {
    NEWEST = 'newest',
    OLDEST = 'oldest',
    TITLE = 'title'
}