import React, {useContext, useEffect} from "react";
import styles from './ChatBotPage.module.scss';
import ChatBotComponent from "./ChatBotComponent/ChatBotComponent";
import {useNavigate} from "react-router-dom";
import AuthContext from "../../../../store/auth-context";

const ChatBotPage: React.FC<{}> = props => {

   const navigate = useNavigate();
   const authStore = useContext(AuthContext);
   const adminPanel = authStore.userData.menu[0].menus?.find(item => item.title.includes("Admin"));

   useEffect(() => {
      authStore.changePageTitle("Teamo AI page");
   }, []);

   return (
      <>
         <div className={styles['chat-bot-page']}>
            <div className='container-fluid'>
               <div className='row'>
                  <div className='col-12'>
                        <div className='onboarding-manager-modal'>
                           <div className='card-modal'>
                              <div className='row'>
                                 <div className='col-12'>
                                    <div className={`modal-header`}>
                                       <div className='logo-container'>
                                          <img src='/assets/images/global-icons/teamlift-logo-white.svg' alt="TeamLift logo" />
                                       </div>
                                       <div onClick={goBack} className="logout-button">Back</div>
                                    </div>
                                 </div>
                              </div>
                              <div className='modal-body'>
                                 <ChatBotComponent />
                              </div>
                           </div>
                        </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   )

   function goBack() {
      navigate(adminPanel ? `/admin-onboard/admin-panel` :`/manager/projects`);
   }
}

export default ChatBotPage;