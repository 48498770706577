import {useNavigate} from 'react-router-dom';
import styles from './ProjectCardComponent.module.scss';
import {Checkbox} from "@mui/material";
import {PROJECT_STATUS} from "../../../../../../enums/Projects.enum";
import React from "react";

const ProjectCardComponent: React.FC<{
   projectId: number,
   projectName: string,
   projectStatus: string,
   projectEmployeesNumber: number,
   feComponent?: string,
   deleteProject?: Function,
   check: Function,
   checked: boolean,
   moveToArchiveOrOngoing?: Function,
   isManager: boolean,
   isTeamLead?: boolean,
   jobPosting?: boolean
}> = props => {

   const navigate = useNavigate();

   return (
      <div className={styles['project-card']} onClick={goToManagerDashboard} style={{
         borderColor: `${props.checked ? '#A0A8E5FF' : ''}`,
         backgroundColor: `${props.checked ? '#383A46' : ''}`
      }}>
         <div className={`project-info-wrapper ${props.projectStatus !== 'PROCESSED' ? '' : ''}`}>
            <div className={'checkbox-container'}>
               {
                  props.isManager && !props.isTeamLead &&
                  <Checkbox
                     disableRipple={true}
                     sx={{
                        padding: 0,
                        transition: 'all 0.5s',
                        backgroundColor: 'transparent',
                        color: '#50545FFF',
                        '&:hover, &:active, &:active:hover, &:hover:active': {
                           backgroundColor: 'transparent',
                           color: '#626872FF',
                        },
                        '&.Mui-checked': {
                           color: '#A0A8E5FF',
                           backgroundColor: 'transparent',
                           '&:hover, &:active, &:active:hover, &:hover:active': {
                              backgroundColor: 'transparent',
                              color: '#C6CAEFFF',
                           },
                        },
                        '&.MuiCheckbox-indeterminate': {
                           color: '#A0A8E5FF',
                           backgroundColor: 'transparent',
                           '&:hover, &:active, &:active:hover, &:hover:active': {
                              backgroundColor: 'transparent',
                              color: '#C6CAEFFF',
                           },
                        },
                     }}
                     onClick={toggleProject}
                     checked={props.checked}
                  />
               }
            </div>
            <div className='project-name'>{props.projectName}</div>
            {
               !props.jobPosting &&
               <div className='project-employees-counter'>
                  <i className="fa-regular fa-user me-1 d-inline-block"></i> {props.projectEmployeesNumber ? props.projectEmployeesNumber : 0}
               </div>
            }
            <div className={`project-status ${props.projectStatus !== 'PROCESSED' ? '' : ''}`}>
               {
                  props.projectStatus === 'PROCESSED' ? 'Ongoing' : props.projectStatus === 'NEW' ? 'Draft' : 'Finished'
               }
            </div>
         </div>

         <div className='project-command-wrapper'>
            {
               props.projectStatus === 'PROCESSED' &&
               <>
                  {
                     props.isManager && !props.isTeamLead &&
                     <button onClick={(e: any) => {
                        e.stopPropagation();
                        props.moveToArchiveOrOngoing ? props.moveToArchiveOrOngoing(props.projectId) : () => {
                        }
                     }} className={'button button-tertiary u-margin-right-xs'}><i
                        className="fa-solid fa-trash-can"></i> Move to archive</button>
                  }
                  <button className={"button button-tertiary"}>Open</button>
               </>
            }
            {
               props.projectStatus === 'NEW' && props.isManager && !props.isTeamLead &&
               <>
                  <button className={'button button-tertiary red-button u-margin-right-xs'} onClick={(e: any) => {
                     e.stopPropagation();
                     props.deleteProject ? props.deleteProject(props.projectId) : () => {
                     }
                  }}>
                     <i className="fa-solid fa-circle-xmark"></i> Delete forever
                  </button>
                  <button className={"button button-tertiary"}>Edit</button>
               </>
            }
            {
               props.projectStatus === 'ARCHIVED' && props.isManager && !props.isTeamLead &&
               <>
                <button className={'button button-tertiary red-button'} onClick={(e: any) => {
                   e.stopPropagation();
                   props.deleteProject ? props.deleteProject(props.projectId) : () => {
                   }
                }}>
                    <i className="fa-solid fa-circle-xmark"></i> Delete forever</button>
                  {/*<span onClick={(e: any) => {*/}
                  {/*    e.stopPropagation();*/}
                  {/*    props.moveToArchiveOrOngoing(props.projectId)*/}
                  {/*}} className={'edit-button'}><i className="fa-solid fa-arrow-left-long"></i> Move to ongoing</span>*/}
               </>
            }
         </div>
      </div>
   )

   function toggleProject(e: any) {
      e.stopPropagation();
      props.check(props.projectId);
   }

   function goToManagerDashboard() {
      if (!props.isManager && props.projectStatus !== PROJECT_STATUS.ONGOING.valueOf()) {
         return;
      }
      if (props.projectStatus === PROJECT_STATUS.ONGOING.valueOf()) {
         navigate(`/manager/${props.jobPosting ? 'tasks' : 'projects'}/${props.projectId}/manager-dashboard`);
      } else if (props.projectStatus === PROJECT_STATUS.DRAFT.valueOf() && !props.isTeamLead) {
         navigate(`/manager/${props.jobPosting ? 'tasks' : 'projects'}/create/${props.projectId}/${props.feComponent ? props.feComponent : 'project-skills-section'}`)
      } else {
         return;
      }
   }
}

export default ProjectCardComponent;