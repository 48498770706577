import { useRef, useEffect, useContext } from 'react';
import styles from './EmployeesGridComponent.module.scss';
import { TabulatorFull as Tabulator } from "tabulator-tables";

import AuthContext from '../../../../../store/auth-context';
import AdminPanelContext from '../../../../../store/admin-panel-context';

import useModal from '../../../../../services/modal.service';
import EditEmployeeModal from './EditEmployeeModal/EditEmployeeModal';

import { showNotification } from '../../../../../ui/Toast/ToastNotification';
import useLoadingSpinner from '../../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner';
import useApiService from '../../../../../services/api.service';
import DeleteEmployeeModal from "./DeleteEmployeeModal/DeleteEmployeeModal";
import {UserDto} from "../../../../../types/AdminData";
import {ErrorResponseDto} from "../../../../../types/ErrorData";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {MenusEndpointsDto} from "../../../../../types/UserMenu";

const EmployeesGridComponent: React.FC<{gridData: UserDto[]}> = (props) => {
  const authStore = useContext(AuthContext);
  const adminPanelStore = useContext(AdminPanelContext);
  
  const gridRef = useRef<any>();
  const grid = useRef<any>();
    
  const filterInputRef = useRef<any>(); 
  const appliedFilters = useRef<any>([]);

  let editModal = useModal();
  let deleteModal = useModal();
  let spinnerService = useLoadingSpinner();
  const { refreshToken } = useApiService();

  const gridColumnsDefinition: any = [
    { title: "", field: "rowNumber", minWidth: 25, maxWidth: 75, formatter: "rownum", headerSort: false },
    { title: "Name", field: "name", minWidth: 200, validator:["required", "string"], headerSortTristate: true },
    { title: "Email", field: "email", minWidth: 200, headerSortTristate: true, validator:["required", "unique"]},
    { title: "Job Title", field: "jobTitle", minWidth: 200, validator:["required", "string"], headerSortTristate: true },
    // { title: "Availability", field: "availability", minWidth: 150, validator:["required", "integer"], headerSortTristate: true },
    { title: "Manager", field: "isManager", sorter:"boolean",  minWidth: 150, headerSortTristate: true, formatter: 'tickCross' },
    { title: 'Actions', field: "actionButtons", headerSort: false, minWidth: 100, maxWidth: 125, // frozen: true,
      formatter: (cell: any)=> {
        let cellData = cell.getData();
        let row = cell.getRow();

        let wrapper = document.createElement('div') as HTMLDivElement;
        wrapper.classList.add('row-actions');
        
        // EDIT BUTTON
        let editButton = document.createElement('div') as HTMLDivElement;
        editButton.classList.add('edit-button');
        let editButtonSpan = document.createElement('span') as HTMLSpanElement;
        editButtonSpan.innerHTML = `<i class="fa-solid fa-pen"></i>`;
        editButtonSpan.title = 'Edit';
        editButtonSpan.addEventListener('click', ()=> {
          openEditForm(cellData);
        })      
        
        editButton.append(editButtonSpan);
        wrapper.append(editButton);

        if (cellData.id !== authStore.userData.userId){
          // REMOVE BUTTON
          let removeButton = document.createElement('div') as HTMLDivElement;
          removeButton.classList.add('remove-button');

          let removeButtonSpan = document.createElement('span') as HTMLSpanElement;
          removeButtonSpan.innerHTML = `<i class="fa-solid fa-trash"></i>`;
          removeButtonSpan.title = 'Delete';
          removeButtonSpan.addEventListener('click', ()=> {
            openDeleteModal(cellData);
          })

          removeButton.append(removeButtonSpan);
          wrapper.append(removeButton);
        }

        return wrapper;
     }
    }
  ]; 

  useEffect(() => {
    grid.current = new Tabulator(gridRef.current, {
      data: JSON.parse(JSON.stringify(props.gridData)),
      columns: gridColumnsDefinition,
      headerSortClickElement: "icon",
      layout:"fitColumns",
      validationMode:"highlight",
      headerSortElement: function(column, dir) {
        switch (dir) {
          case "asc":
            return "<span class='material-symbols-outlined'>sort</span>";    
          case "desc":
            return "<span class='material-symbols-outlined'>sort</span>";     
          default:
            return "<span class='material-symbols-outlined'>sort</span>"; 
        }
      }
    });

    return ()=> {
      grid.current.destroy();
    }
  }, [props.gridData])

  return (
    <div className={styles['employees-grid-component']}>  
          <div className='filter-element'>
            <div className='input-wrapper'>
              <label htmlFor='grid-filter-input' className='search-icon-wrapper'>
                <i className="fa-solid fa-magnifying-glass"></i>
              </label>
              <input id='grid-filter-input' type='text' placeholder='Search' ref={filterInputRef} onChange={applyFilter} autoComplete='off' />
              <span className='clear-icon-wrapper' title='Clear filter' onClick={clearFilter}>
                <i className="fa-solid fa-xmark"></i>
              </span>
            </div>                  
          </div>    
         <div className='grid-wrapper'>            
          <div id='admin-grid' ref={gridRef}></div>
        </div>       
      { 
        editModal.showModal && <EditEmployeeModal data={editModal.modalData} dataIsLoading={editModal.dataIsLoading} okCallback={(data: any)=> updateEmployeeData(data, authStore.userData.accessToken)} cancelCallback={editModal.removeModal} />
      }
      {
        deleteModal.showModal && <DeleteEmployeeModal data={deleteModal.modalData} okCallback={(data : any) => {deleteUser(data, authStore.userData.accessToken)}} cancelCallback={deleteModal.removeModal} />
      }
      {
        spinnerService.spinner
      }
    </div> 
  )

  function applyFilter(event: any) {
    grid.current.setFilter([
      [ {field: 'name', type: 'like', value: event.target.value }, 
        {field: 'email', type: 'like', value: event.target.value }, 
        {field: 'jobTitle', type: 'like', value: event.target.value }]
    ]);
  }

  function clearFilter() {
    grid.current.clearFilter();
    filterInputRef.current.value = '';    
    appliedFilters.current = [];
  }
 
  function openEditForm(rowData?: any) {
    let data = {
      title: "Edit employee's data",
      rowData: rowData,
      formData: {
        "id": rowData.id,
        "name": rowData.name,
        "email": rowData.email,
        "jobTitle": rowData.jobTitle,
        // "availability": rowData.availability,
        "isManager": rowData.isManager
      }
    }
    editModal.createModal(data);
  }

  function openDeleteModal(rowData? : any) {
    let data = {
      rowData: rowData,
      formData: {
        "id": rowData.id,
        "name": rowData.name,
        "email": rowData.email,
        "jobTitle": rowData.jobTitle,
        "isManager": rowData.isManager
      }
    }
    deleteModal.createModal(data);
  }

  function updateEmployeeData (payload: any, accessToken: string) {
    editModal.removeModal();

    let originalRowData = props.gridData.find((item: any)=> item.id === payload.id);
    if (originalRowData) {
      let hasChanges: boolean = false;

      if (originalRowData.id !== payload.id || originalRowData.name !== payload.name || originalRowData.email !== payload.email || 
        originalRowData.jobTitle !== payload.jobTitle || originalRowData.isManager !== payload.isManager) { // || originalRowData.availability !== payload.availability
        hasChanges = true;
      }
      else {
        hasChanges = false;
      }

      if (hasChanges) {
        spinnerService.createSpinner();
        appliedFilters.current = grid.current.getFilters();

        adminPanelStore.updateEmployee(payload, accessToken)
          .then((response$: any)=> {
            if (originalRowData?.email === authStore.userData.email && originalRowData.isManager !== payload.isManager) {
              findMenuStructure(accessToken);
            }
            spinnerService.removeSpinner();
          })
          .catch((error$: ErrorResponseDto)=> {
            if (error$.response.data.message === 'Unauthorized') {
              // Get new Access Token
              refreshToken(authStore.userData.refreshToken)
                .then((response$: any) => {		
                  authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                  updateEmployeeData(payload, response$.data.accessToken);						
                })
            }
            // else if (error$.response.data.message.indexOf('higher availability') > -1) {
            //   spinnerService.removeSpinner();
            //   showNotification('warning', error$.response.data.message);
            // }
            else {
              spinnerService.removeSpinner();
              showNotification('warning', error$.response.data.message);
            }
          })
      }
      else {        
        showNotification('warning', 'No changes have been made to the user data');
      }   
    }
  }

  function deleteUser (id: any, accessToken: string) {
    spinnerService.createSpinner();
    deleteModal.removeModal();
    appliedFilters.current =  grid.current.getFilters();

    adminPanelStore.deleteEmployee(id, accessToken)
      .then(()=> {
        setTimeout(()=> {    
          if (appliedFilters.current.length > 0) {
            grid.current.setFilter(appliedFilters.current);   
          }                         
        }, 0);
        spinnerService.removeSpinner();
      })
      .catch((error$: ErrorResponseDto)=> {
        if (error$.response.data.message === 'Unauthorized') {
          // Get new Access Token
          refreshToken(authStore.userData.refreshToken)
            .then((response$: any) => {		
              authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
              deleteUser(id, response$.data.accessToken);						
            })
        }
        else {
          spinnerService.removeSpinner();
          showNotification('warning', error$.response.data.message, 'Error while deleting user');  
        }
      })
  }

  function findMenuStructure(accessToken: string) {
    const menuStructureURL = process.env.REACT_APP_PUBLIC_URL + "/auth/menu-structure";

    spinnerService.createSpinner();

    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }

    axios
       .get(menuStructureURL, {headers})
       .then((response$: AxiosResponse<MenusEndpointsDto[]>) => {
         authStore.updateMenu(response$.data);
         spinnerService.removeSpinner();
       })
       .catch((error$: ErrorResponseDto)=> {
         if (error$.response.data.message === 'Unauthorized') {
           // Get new Access Token
           refreshToken(authStore.userData.refreshToken)
              .then((response$: any) => {
                authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                findMenuStructure(response$.data.accessToken);
              })
         }
         else {
           showNotification('warning', error$.response.data.message);
         }
       })
  }
}

export default EmployeesGridComponent;