import {EmployeeDto, SkillDto} from "./Settings";

export type RegisterQuestionDto = {
   id: number;
   aiId: string;
   questionText: string;
   preferredAnswer?: string;
   questionOrder?: number;
   isAlreadyAnswered: boolean;
   answer?: string;
   answerType: QuestionTypeEnum;
   employee: EmployeeDto;
   skills?: SkillDto[];
}

export enum QuestionTypeEnum {
   TEXT = "TEXT",
   CHECKBOX = "CHECKBOX",
   SKILL_SELECTION = "SKILL_SELECTION",
   SHORT_TEXT = "SHORT_TEXT"
}