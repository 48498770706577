import { useContext, useEffect, useState } from 'react';
import styles from './SettingsGeneralComponent.module.scss';
import ProjectsContext from '../../../../../../../store/projects-context';
import AuthContext from '../../../../../../../store/auth-context';
import { showNotification } from '../../../../../../../ui/Toast/ToastNotification';
import useLoadingSpinner from '../../../../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner';
import useApiService from "../../../../../../../services/api.service";
import {ErrorResponseDto} from "../../../../../../../types/ErrorData";

const SettingsGeneralComponent: React.FC<{projectData: any}> = (props) => {
  const projectStore = useContext(ProjectsContext);
  const authStore = useContext(AuthContext); 

  const [projectName, setProjectName] = useState('');
  const [projectNameInput, setProjectNameInput] = useState('');
  const [projectDescription, setProjectDescription] = useState('');
  const [projectDescriptionInput, setProjectDescriptionInput] = useState('');
  
  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const spinnerService = useLoadingSpinner();

  const { refreshToken } = useApiService();

  useEffect(()=> {
    setProjectName(props.projectData.name);
    setProjectNameInput(props.projectData.name);
    setProjectDescription(props.projectData.description !== null ? props.projectData.description : '');
    setProjectDescriptionInput(props.projectData.description !== null ? props.projectData.description : '');
  }, [projectName, projectDescription])

  useEffect(() => {
    if (projectNameInput != projectName || projectDescriptionInput !== projectDescription) {
      setHasChanges(true);
    }
    else {
      setHasChanges(false);
    }
  }, [projectNameInput, projectDescriptionInput])

  return (
    <div className={styles['settings-general-component']}>
      <div className='row'>    
        <div className='col-12 col-lg-8 col-xl-6'>
          <div className='project-title-container'>
            <input type="text" value={projectNameInput} onChange={projectNameHandler} autoComplete='off' placeholder='Add project name' />
          </div>
        </div>
      </div>      

      <div className='row'>    
        <div className='col-12 col-lg-8 col-xl-6'>
          <div className='textarea-wrapper'>
            <textarea id='projectName' autoComplete='off' placeholder='Add project description'
              value={projectDescriptionInput} onChange={projectDescHandler} />   
          </div>
        </div>
      </div>
      <div className={'row project-status'}>
        <div>Status: <span>{props.projectData.status === 'PROCESSED' ? 'Ongoing project' : props.projectData.status}</span></div>
      </div>
      { hasChanges &&
        <div className='row'>
          <div className='col-12 col-lg-8 col-xl-6'>
            <div className='commands-wrapper-section'>       
              <button className='button button-secondary' onClick={cancelChanges}>Cancel</button>
              <button className='button button-primary u-margin-left-s' onClick={() => saveChanges(authStore.userData.accessToken)}>Save changes</button>
            </div>
          </div>
        </div>
      }
      {
        spinnerService.spinner
      }
    </div>
  )
  
  function projectNameHandler(e: any) {
    setProjectNameInput(e.target.value);
  }

  function projectDescHandler(e: any) {
    setProjectDescriptionInput(e.target.value);
    e.target.style.height = 'auto';
    e.target.style.height = (e.target.scrollHeight) + 'px'; 
  }
  
  function cancelChanges() {
    setProjectNameInput(projectName);
    setProjectDescriptionInput(projectDescription);
  }

  function saveChanges(accessToken: string) {
    spinnerService.createSpinner();
    
    projectStore.updateProject(projectNameInput.trim(), projectDescriptionInput.trim(), props.projectData.id, accessToken)
      .then(()=> {
        spinnerService.removeSpinner();
        setHasChanges(false);
        setProjectName(projectNameInput.trim());
        setProjectDescription(projectDescriptionInput.trim());
      })
      .catch((error$: ErrorResponseDto) => {
        if (error$.response.data.message === 'Unauthorized') {
          // Get new Access Token
          refreshToken(authStore.userData.refreshToken)
              .then((response$: any) => {
                authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                saveChanges(response$.data.accessToken);
              })
        }
        else{
          spinnerService.removeSpinner();
          showNotification('warning', error$.response.data.message);
        }
      })
  }
}

export default SettingsGeneralComponent;