import React, {useContext, useState} from "react";
import styles from './EmployeeSettingsPage.module.scss';
import TermsConditionsPage from "./TermsConditionsPage/TermsConditionsPage";
import PersonalInfoPage from "./PersonalInfoPage/PersonalInfoPage";
import {AuthContext} from "../../../../store/auth-context";

const EmployeeSettingsPage: React.FC<{}> = props => {

   const authStore = useContext(AuthContext);

   const [showTerms, setShowTerms] = useState(false);

   return (
      <>
         <div className={styles['employee-settings-page']}>
            {
               showTerms ? <TermsConditionsPage checkTerms={checkTerms} /> : <PersonalInfoPage checkTerms={checkTerms} />
            }
         </div>
      </>
   )

   function checkTerms() {
      setShowTerms(prevState => {
         if (!prevState) {
            authStore.changePageTitle('Terms & Conditions of Use');
         }
         return !prevState;
      });
   }
}

export default EmployeeSettingsPage;