import React, { useContext, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import styles from './PageNotFound.module.scss';
import AuthContext from '../../../store/auth-context';

const PageNotFound: React.FC = () => {
  const authStore = useContext(AuthContext);

  useEffect(() => {
    authStore.changePageTitle("");
  }, []);

  return (
     <>
        <div className={styles['page-not-found']}>
           <div className="not-found-wrapper">
              <div className='not-found-image'>
                 <img src='/assets/images/global-icons/error-page-icon.svg' alt='Page Not Found'/>
              </div>
              <h1 className={'header-1 u-margin-bottom-xs'}>This page doesn’t exist.<br/>But you exist.</h1>
              <div className={'not-found-content'}>This is your sign to take a break. And take a breath. In and out.<br/>When you’re ready, ease back to work below.</div>
              <NavLink to={'/'} className='button button-primary'>Go to the main page</NavLink>
           </div>
        </div>
     </>
  )
}

export default PageNotFound;