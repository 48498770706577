// Types for needed people feature

export type EmployeeModalData = {
   allEmployees: EmployeeInfoModal,
   skill: NeededSkill
}

export type EmployeeInfoModal = {
   neededSkillEmployees: SkillEmployee[],
   employeeSelectedList: NeededEmployee[],
   selectedSkillEmployeeList: NeededEmployee[]
}

export type ProjectSkill = {
   id: number,
   project: {
      id: number,
      name: string,
      description?: string,
      startDate: Date,
      company: {
         id: number,
         name: string,
         parent?: any,
         levelType: string
      },
      status: SkillStatus,
      feComponent: string
   },
   skill: {
      id: number,
      name: string,
      description: string,
      category: string,
      icon?: any,
      aiId: string,
      isTool: boolean,
      isAiTool?: boolean,
      level?: any,

   },
   demandType: string,
   synonym?: string
}

export type CoveredSkill = {
   project_skill_id: number,
   skill_name: string,
   skill_description: string,
   skill_id: number,
   synonym_name?: string,
   total_validation_score: number,
   unique_owner?: any | boolean
}

export enum EmployeeType {
   CANDIDATE = "CANDIDATE",
   LEARNER = "LEARNER"
}

export enum SkillStatus {
   NEW = "NEW",
   APPROVED = "APPROVED",
   LEARNING = "LEARNING"
}

export type SkillEmployee = {
   unique_owner?: boolean,
   employeeId: number,
   projectEmployeeId: number,
   employeeName: string,
   totalValidationScore: number,
   status: SkillStatus,
   signedUserProfilePicture?: string,
   color?: string
}

export type NeededEmployee = {
   employeeId: number,
   employeeName: string,
   type: EmployeeType,
   projectAvailability: number,
   signedUserProfilePicture?: string,
   color: string,
   coveredSkills: CoveredSkill[]
}

export type ProjectEmployee = {
   employeeId: number,
   projectEmployeeId: number,
   employeeName: string,
   projectAvailability: number,
   signedUserProfilePicture?: string,
   color?: string,
   skills: ProjectSkill[],
   skillsToLearn: ProjectSkill[],
}

export type NeededSkill = {
   neededSkillId: number,
   skillId: number,
   skillName: string,
   people: SkillEmployee[],
   missing?: boolean,
};

export type NeededPeopleDto = {
   topCandidates: NeededEmployee[],
   potentialLearners: NeededEmployee[],
}

export type NeededPeopleData = {
   employeesLists: { neededPeople: NeededPeopleDto, allEmployees: NeededEmployee[]},
   team: { projectEmployees: ProjectEmployee[] },
   neededSkills: NeededSkill[]
}