export const Sources: {value: string, label: string, description: string}[] = [
   {value: 'CV', label: 'CV', description: ''},
   {value: 'JIRA', label: 'JIRA', description: ''},
   {value: 'PLANVIEW', label: 'PLANVIEW', description: ''},
   {value: 'WORKDAY', label: 'WORKDAY', description: ''},
   {value: 'CERTIFICATE', label: 'CERTIFICATE', description: ''},
   {value: 'LOTUS', label: 'LOTUS', description: ''},
   {value: 'LINKEDIN', label: 'LINKEDIN', description: ''},
   {value: 'ASANA', label: 'ASANA', description: ''},
   {value: 'TRELLO', label: 'TRELLO', description: ''},
   {value: 'MEETING_NOTES', label: 'MEETING NOTES', description: ''},
   {value: 'GITHUB', label: 'GITHUB', description: ''},
   {value: 'MANUALLY_ADDED', label: 'MANUALLY ADDED', description: ''},
   {value: 'PERSONAL_NOTES', label: 'PERSONAL NOTES', description: ''},
   {value: 'TEAM_SKILLS', label: 'TEAM SKILLS', description: ''},
   {value: 'PROJECT_RETRO', label: 'PROJECT RETRO', description: ''},
   {value: 'PSYCHOMETRIC ASSESSMENT', label: 'PSYCHOMETRIC ASSESSMENT', description: ''},
   {value: 'UNKNOWN', label: 'UNKNOWN', description: ''},
];