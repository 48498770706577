import React, {useContext, useEffect, useState} from "react";
import styles from './AddCategoriesComponent.module.scss';
import {ErrorResponseDto} from "../../../../../types/ErrorData";
import {showNotification} from "../../../../../ui/Toast/ToastNotification";
import useLoadingSpinner from "../../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner";
import AdminPanelContext from "../../../../../store/admin-panel-context";
import useApiService from "../../../../../services/api.service";
import AuthContext from "../../../../../store/auth-context";
import {capitalizeFirstLetter} from "../../../../../utils/capitalize-first-letter";

const AddCategoriesComponent: React.FC<{
   changeSection: Function,
}> = props => {
   const [componentIsInit, setComponentIsInit] = React.useState(false);
   const [allCategories, setAllCategories] = useState<Map<string, boolean>>(new Map());
   const selectedCategories = Array.from(allCategories.values()).filter(selected => selected).length;

   const adminPanelStore = useContext(AdminPanelContext);
   const authStore = useContext(AuthContext);

   const spinnerService = useLoadingSpinner();
   const { refreshToken } = useApiService();

   let timeout: string | number | NodeJS.Timeout | undefined;

   useEffect(() => {
      getCategories(authStore.userData.accessToken);
   }, []);

   return (
     <>
        {
           componentIsInit &&
           <div className={styles['add-categories-component']}>
              <h1 className="header-1">Choose your Skill Categories</h1>
              <p className="text u-margin-top-s">First, we need to ensure we tailor our skill extraction process to your needs. Please select the skill categories that are most relevant to your expertise or the specific jobs you're targeting. The more specific you can be, the better we can customize our results for you.</p>
              <p className="text u-margin-top-s">Please select at most 3 categories.</p>
              <main className="skill-categories-section">
                 {
                    Array.from(allCategories.keys()).map(category => {
                       const checked = allCategories.get(category);
                       return (
                          <div key={category} className="category_item" onClick={() => clickCategory(category)}>
                             <div className={`checkbox-dark ${checked ? 'checked' : ''}`}><i className="fa-solid fa-check"></i></div>
                             <span className={'u-margin-left-xs'}>{capitalizeFirstLetter(category)}</span>
                          </div>
                       )
                    })
                 }
              </main>
              <div className="footer">
                 {
                    selectedCategories === 0 && <span>Please select at least one skill category</span>
                 }
                 <button disabled={selectedCategories === 0} className="button button-primary u-margin-left-xs" onClick={chooseCategories}>Next</button>
              </div>
           </div>
        }
        {
           spinnerService.spinner
        }
     </>
   );

   function chooseCategories() {
      const checkedCategories = Array.from(allCategories.keys()).filter(c => allCategories.get(c));
      if (checkedCategories.length > 0 && checkedCategories.length <= 3) {
         saveCategories(checkedCategories, authStore.userData.accessToken);
      }
   }

   function clickCategory(category: string) {
      if (Array.from(allCategories.values()).filter(v => v).length <= 2 || allCategories.get(category)) {
         const newMap = new Map(allCategories);

         const selectedCategory = !newMap.get(category);

         newMap.set(category, selectedCategory);

         setAllCategories(newMap);
      }
      else {
         clearTimeout(timeout);

         timeout = setTimeout(() => {
            showNotification("warning", "You can only select at maximum 3 categories.", "Too much categories");
         }, 500);
      }
   }

   function saveCategories(categories: string[], accessToken: string) {
      spinnerService.createSpinner();
      adminPanelStore.chooseSkillCategories(categories, accessToken)
         .then(() => {
            props.changeSection('add-employee-upload');
            spinnerService.removeSpinner();
         })
         .catch((error$: ErrorResponseDto)=> {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     saveCategories(categories, response$.data.accessToken);
                  })
            }
            else {
               setComponentIsInit(true);
               spinnerService.removeSpinner();
               showNotification('warning', error$.response.data.message);
            }
         })
   }

   function getCategories(accessToken: string) {
      spinnerService.createSpinner();

      adminPanelStore.getCategories(accessToken)
         .then((response$: { category: string }[]) => {
            spinnerService.removeSpinner();
            response$.filter(category => category.category !== null).map(category => {
               if (adminPanelStore.categories.find(c => c === category.category)) {
                  allCategories.set(category.category, true);
               } else {
                  allCategories.set(category.category, false);
               }
            })
            setComponentIsInit(true);
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     getCategories(response$.data.accessToken);
                  })
            }
            else {
               setComponentIsInit(true);
               spinnerService.removeSpinner();
               showNotification('warning', error$.response.data.message);
            }
         })
   }

}

export default AddCategoriesComponent;