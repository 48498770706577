import {Route, Routes, Navigate} from 'react-router-dom';
import AllProjectsPage from './AllProjectsPage/AllProjectsPage';
import ManagerDashboardPage from './ManagerDashboardPage/ManagerDashboardPage';
import CreateProjectComponent from './CreateProjectComponent/CreateProjectComponent';
import { ProjectsContextProvider } from '../../../../store/projects-context';
import { CreateProjectContextProvider } from '../../../../store/create-project-context';
import React from "react";

const ProjectsPage: React.FC = () => {

  return ( 
    <>
      <Routes>
        <Route path="/" element={<Navigate replace to='/manager/projects/all-projects'></Navigate>}/>

        <Route path="all-projects" element={
          <CreateProjectContextProvider>
            <AllProjectsPage />
          </CreateProjectContextProvider>
        }/>

        <Route path="create/:id?/:component?" element={
          <CreateProjectContextProvider>
            <CreateProjectComponent  />
          </CreateProjectContextProvider>
        }/>

        <Route path=":id/*" element={
          <ProjectsContextProvider>
            <ManagerDashboardPage />
          </ProjectsContextProvider>
        }/>

      </Routes>
    </> 
  )
}

export default ProjectsPage;