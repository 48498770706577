import React, {useContext, useEffect, useRef, useState} from 'react';
import styles from './EmployeesGridComponent.module.scss';

import {TabulatorFull as Tabulator} from "tabulator-tables";

import {FileUploader} from 'react-drag-drop-files';

import AuthContext from '../../../../../../store/auth-context';
import AdminPanelContext from '../../../../../../store/admin-panel-context';
import {UserData} from '../../../../../../types/AuthData';

import useModal from '../../../../../../services/modal.service';
import NewEditEmployeeModal from './NewEditEmployeeModal/NewEditEmployeeModal';
import {showNotification} from '../../../../../../ui/Toast/ToastNotification';
import useLoadingSpinner from '../../../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner';
import useApiService from '../../../../../../services/api.service';
import {
  adminRegexPatterns,
  EmployeePayloadInformation,
  EMPLOYEES_TYPE,
  UserDataDto
} from "../../../../../../types/AdminData";
import {ErrorResponseDto} from "../../../../../../types/ErrorData";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {MenusEndpointsDto} from "../../../../../../types/UserMenu";

const EmployeesGridComponent: React.FC<{gridData: UserDataDto[], changeSection: Function, changeEmployeesSection: Function, adminOnboarding?: Function}> = (props) => {
  const gridRef = useRef<any>();
  const grid = useRef<any>();
  const authStore = useContext(AuthContext);
  const adminPanelStore = useContext(AdminPanelContext);
  const userData: UserData = authStore.userData;

  const spinnerService = useLoadingSpinner();

  const filterInputRef = useRef<any>(); 
  const appliedFilters = useRef<any>([]);
  const [haveManagers, setHaveManagers] = useState<boolean>(false);

  let modalCrud = useModal();
  const { refreshToken } = useApiService();

  const gridColumnsDefinition: any = [
    { title: "", field: "rowNumber", minWidth: 100, formatter: "rownum", headerSort: false },
    { title: "Name", field: "firstName", minWidth: 150, validator:["required", "string"], headerSortTristate: true, sorter: customStringSorter },
    { title: "Surname", field: "lastName", minWidth: 150,  validator:["required", "string"], headerSortTristate: true, sorter: customStringSorter },
    { title: "Email", field: "email", minWidth: 150, headerSortTristate: true, validator:["required", "unique",
      {
        type: (_cell: any, value: any)=> {
          return (adminRegexPatterns.email.test(value));
        }
      }
    ], sorter: customStringSorter},
    { title: "Job Title", field: "jobTitle", minWidth: 150, validator:["required", "string"], headerSortTristate: true, sorter: customStringSorter },
    // { title: "Availability", field: "availability", minWidth: 150, validator:["required", "integer"], headerSortTristate: true, sorter: customNumberSorter },
    { title: "GitHub link", field: "githubLink", minWidth: 150, validator:[
      {
        type: (_cell: any, value: any)=> {
          let valid: boolean = true;
          if (value && value.trim() !== "") {
            valid = (adminRegexPatterns.githubUrl.test(value));
          }
          return valid;
        }
      }
    ], headerSortTristate: true, sorter: customStringSorter },
    { title: "CV Google Drive Link", field: "cvDriveLink", minWidth: 150, validator:[
      {
        type: (_cell: any, value: any)=> {
          let valid: boolean = true;
          if (value && value.trim() !== "") {
            valid = (adminRegexPatterns.cvGoogleDriveLink.test(value));
          }
          return valid;
        }
      }
    ], headerSortTristate: true, sorter: customStringSorter },
    // { title: "LinkedIn Link", field: "linkedinLink", minWidth: 150, validator:[
    //   {
    //     type: (cell: any, value: any)=> {
    //       let valid: boolean = true;
    //       if (value && value.trim() !== "") {
    //         valid = (adminRegexPatterns.linkedinLink.test(value));
    //       }
    //       return valid;
    //     }
    //   }
    // ], headerSortTristate: true, sorter: customStringSorter },
    { title: "Manager", field: "isManager", sorter:"boolean",  minWidth: 150, headerSortTristate: true, formatter: 'tickCross' },
    { field: "actionButtons", headerSort: false, minWidth: 150, // frozen: true,
      formatter: (cell: any)=> {
        let cellData = cell.getData();
    
        let wrapper = document.createElement('div') as HTMLDivElement;
        wrapper.classList.add('row-actions');   

        // REMOVE BUTTON           
        let removeButton = document.createElement('div') as HTMLDivElement;        
        removeButton.classList.add('remove-button');
             
        let removeButtonSpan = document.createElement('span') as HTMLSpanElement;
        removeButtonSpan.title = 'Remove';
        removeButtonSpan.innerHTML = `<i class="fa-solid fa-trash"></i>`;
        removeButtonSpan.addEventListener('click', ()=> {  
          deleteUserHandler([cellData.id], userData.accessToken);
        })   

        removeButton.append(removeButtonSpan);
        
        // EDIT BUTTON
        let editButton = document.createElement('div') as HTMLDivElement;
        editButton.classList.add('edit-button');
        let editButtonSpan = document.createElement('span') as HTMLSpanElement;
        editButtonSpan.innerHTML = `<i class="fa-solid fa-pen"></i>`;
        editButtonSpan.title = 'Edit';
        editButtonSpan.addEventListener('click', ()=> {
          openNewEditEmployeeForm('edit', cellData);
        })      
        
        editButton.append(editButtonSpan);  
 
        wrapper.append(editButton);        
        wrapper.append(removeButton);
        
        return wrapper;
     }
    }
  ]; 

  useEffect(() => {
    let counter: number = 0;
    for (let employee of props.gridData) {
      if (employee.userType === 'Manager' || employee.userType === 'EmployeeAndManager') {
        employee.isManager = true;
        counter++;
      } else {
        employee.isManager = false;
      }
    }

    if (counter > 0 || !props.adminOnboarding) {
      setHaveManagers(true);
    } else {
      setHaveManagers(false);
    }

    grid.current = new Tabulator(gridRef.current, {
      data: JSON.parse(JSON.stringify(props.gridData)),
      columns: gridColumnsDefinition,
      headerSortClickElement:"icon",
      layout:"fitDataStretch",      
      validationMode:"highlight",
      headerSortElement: function(_column, dir) {
        switch (dir) {
          case "asc":
            return "<span class='material-symbols-outlined'>sort</span>";    
          case "desc":
            return "<span class='material-symbols-outlined'>sort</span>";     
          default:
            return "<span class='material-symbols-outlined'>sort</span>"; 
        }
      }
    });

    setTimeout(() => {
      grid.current.on("tableBuilt", function () {
        grid.current.validate();
      });
      if (grid.current) {
        grid.current.validate();
        grid.current.setSort("firstName", "asc");
      }
    }, 0);

    return ()=> {
      grid.current.destroy();
    }
  }, [props.gridData])

  return (
    <div className={styles['employees-grid-component']}> 
        <div className='row'>
          <div className='col-12'>              
            <div className='row'>
              <div className='col-12'>      
                <div className='logout-button mb-3'>
                  <button className={'button button-tertiary'} onClick={()=> props.changeEmployeesSection('add-employee-upload')}>
                    <i className="fa-solid fa-arrow-left"></i> Back
                  </button>
                </div>          
              </div>
              <div className='col-12 col-lg-6'>
                <h1 className={'header-1 u-margin-top-xs'}>Add your colleagues</h1>
                <p className='text-secondary u-margin-top-xs'>
                  First we need to gather people from your company!<br/>
                  After that managers from projects will be able to create teams.
                </p>
                <FileUploader name="fileHiddenInput" classes={"file-uploader"} children={<button className='button button-tertiary u-margin-bottom-s u-margin-top-xs'>Upload file (.csv)</button>}
                              handleChange={(file: any)=> uploadEmployeeFile(file, userData.accessToken)} />
              </div>
              <div className='col-12 col-lg-6'>
                <div className='filter-element'>
                  <div className='input-wrapper'>
                    <label htmlFor='grid-filter-input' className='search-icon-wrapper'>
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </label>
                    <input id='grid-filter-input' type='text' placeholder='Search' ref={filterInputRef} onChange={applyFilter} autoComplete='off' />
                    <span className='clear-icon-wrapper' title='Clear filter' onClick={clearFilter}>
                      <i className="fa-solid fa-xmark"></i>
                    </span>
                  </div>                  
                </div>
              </div>
            </div>             
          </div>
          
          <div className='col-12'>
            <div className='grid-wrapper'>            
              <div id='admin-onboarding-grid' ref={gridRef}></div>
            </div>
            <div className='add-employee-section'>
              <button className={'button button-tertiary'} onClick={()=> openNewEditEmployeeForm('new')}>+ Add employee</button>
            </div>          
          </div>
        </div>     
        <div className='row'>
          <div className='col-12'>
            <div className='submit-button-wrapper'>
              <div className='element-container'>
                {!haveManagers &&
                   <span className='submit-info'>Please assign at least one manager</span>
                }
                <div className="buttons-wrapper">
                  <button className='button button-secondary u-margin-right-xs'
                          disabled={props.gridData.length === 0}
                          onClick={() => deleteUserHandler(getAllIds(), userData.accessToken)}>Delete all
                  </button>
                  <button className='button button-primary' onClick={() => saveUsersIntoDB(userData.accessToken)}
                          disabled={!haveManagers}>Send invitations
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

      {
         modalCrud.showModal && <NewEditEmployeeModal data={modalCrud.modalData} dataIsLoading={modalCrud.dataIsLoading}
                                                      okCallback={(crudOperation: string, payload: any) => newEditEmployeeHandler(crudOperation, payload, userData.accessToken)}
                                                      cancelCallback={modalCrud.removeModal}/>
      }
      {
        spinnerService.spinner
      }
    </div>
  )

  function getAllIds() {
    return props.gridData.map(userObject => userObject.id);
  }

  function customStringSorter(a: string, b: string, aRow: any, bRow: any, _column: any, dir: string, _sorterParams: any) {
    const rowValidationA = aRow.validate() === true;
    const rowValidationB = bRow.validate() === true;

    if (rowValidationA && !rowValidationB) {
      return dir === 'asc' ? 1 : -1;
    } else if (!rowValidationA && rowValidationB) {
      return dir === 'asc' ? -1 : 1;
    } else {
      if (!a || a.trim().length === 0) {
        return dir === 'asc' ? -1 : 1;
      }
      else if (!b || b.trim().length === 0) {
        return dir === 'asc' ? 1 : -1;
      }
      return dir === 'asc' ? a.localeCompare(b) : a.localeCompare(b) * -1;
    }
  }

  function customNumberSorter(a: number, b: number, aRow: any, bRow: any, _column: any, dir: string, _sorterParams: any) {
    const rowValidationA = aRow.validate() === true;
    const rowValidationB = bRow.validate() === true;
    if (rowValidationA && !rowValidationB) {
      return dir === 'asc' ? 1 : -1;
    }
    else if (!rowValidationA && rowValidationB) {
      return dir === 'asc' ? -1 : 1;
    }
    else {
      return dir === 'asc' ? a - b : (a - b) * -1;
    }
  }

  function applyFilter(event: any) {
    grid.current.setFilter([
      [{field: 'firstName', type: 'like', value: event.target.value }, {field: 'lastName', type: 'like', value: event.target.value },
        {field: 'email', type: 'like', value: event.target.value }, {field: 'jobTitle', type: 'like', value: event.target.value },
        {field: 'githubLink', type: 'like', value: event.target.value },
        {field: 'cvDriveLink', type: 'like', value: event.target.value },
        // {field: 'linkedinLink', type: 'like', value: event.target.value },
      ]
    ]);
  }

  function clearFilter() {
    grid.current.clearFilter();
    filterInputRef.current.value = '';    
    appliedFilters.current = [];
  }
 
  function openNewEditEmployeeForm(crudOperation: string, rowData?: any) { 
    let data;
    if (crudOperation === 'new') {
      data = { 
        title: "Add new employee",
        crud: crudOperation,
        formData: {
          "name": "",
          "surname": "",
          "email": "",
          "jobTitle": "",
          "isManager": false,
          // "availability": 40,
          "githubLink": "",
          "cvDriveLink" : "",
          // "linkedinLink" : "",
        }
      }
    }
    else {
      data = {
        title: "Edit employee's data",
        crud: crudOperation,
        rowData: rowData,
        formData: {
          "id": rowData.id,
          "name": rowData.firstName,
          "surname": rowData.lastName,
          "email": rowData.email,
          "jobTitle": rowData.jobTitle,
          "isManager": rowData.isManager,
          // "availability": rowData.availability,
          "githubLink": rowData.githubLink,
          "cvDriveLink": rowData.cvDriveLink,
          // "linkedinLink": rowData.linkedinLink
        }
      }
    }
    modalCrud.createModal(data); 
  }

  function newEditEmployeeHandler(crudOperation: string, payload: EmployeePayloadInformation, accessToken: string) {
    if (crudOperation === 'new') { 
      appliedFilters.current = grid.current.getFilters();
      
      adminPanelStore.createTempUser(payload, accessToken)
      .then(()=> { 
        modalCrud.removeModal();
        
        setTimeout(()=> {   
          if (appliedFilters.current.length > 0) {
            grid.current.setFilter(appliedFilters.current);   
          }                           
        }, 0)    
      })
      .catch((error$: ErrorResponseDto)=> {
        if (error$.response.data.message === 'Unauthorized') {
          // Get new Access Token
          refreshToken(authStore.userData.refreshToken)
            .then((response$: any) => {
              authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
              newEditEmployeeHandler(crudOperation, payload, response$.data.accessToken);        
            })
        }
        else {
          modalCrud.removeModal();
          showNotification('warning', error$.response.data.message, 'Error while creating user data');
        }
      });
    }
    else if (crudOperation === 'edit') {
      let originalRowData = props.gridData.find((item: any)=> item.id === payload.id);
      if (originalRowData) {
        let hasChanges: boolean = false;

        if (originalRowData.id !== payload.id || originalRowData.firstName !== payload.name || originalRowData.lastName !== payload.surname ||
          originalRowData.email !== payload.email || originalRowData.jobTitle !== payload.jobTitle || originalRowData.isManager !== payload.isManager || originalRowData.githubLink !== payload.githubLink
          || originalRowData.cvDriveLink !== payload.cvDriveLink) { // originalRowData.availability !== payload.availability || originalRowData.linkedinLink !== payload.linkedinLink
          hasChanges = true;
        }
        else {
          hasChanges = false;
        }

        if (hasChanges) {
          appliedFilters.current = grid.current.getFilters();

          adminPanelStore.updateTempUser(payload, accessToken)
          .then(()=> {
            modalCrud.removeModal();
            
            setTimeout(()=> {    
              if (appliedFilters.current.length > 0) {
                grid.current.setFilter(appliedFilters.current);   
              }                         
            }, 0)    
          })
          .catch((error$: ErrorResponseDto)=> {
            if (error$.response.data.message === 'Unauthorized') {
              // Get new Access Token
              refreshToken(authStore.userData.refreshToken)
                .then((response$: any) => {
                  authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                  newEditEmployeeHandler(crudOperation, payload, response$.data.accessToken);        
                })
            }
            else {
              modalCrud.removeModal();
              showNotification('warning', error$.response.data.message, 'Error while updating user data');
            }
          }) 
        }
        else {
          modalCrud.removeModal()
          showNotification('info', 'No changes have been made to the user data');
        }
      }
    }
  }

  function deleteUserHandler(id: number[], accessToken: string) {
    spinnerService.createSpinner();
    appliedFilters.current =  grid.current.getFilters();
    
    adminPanelStore.deleteTempUser(id, accessToken)
      .then(()=> {
        setTimeout(()=> {    
          if (appliedFilters.current.length > 0) {
            grid.current.setFilter(appliedFilters.current);   
          }                         
        }, 0)  
        spinnerService.removeSpinner(); 
      })
      .catch((error$: ErrorResponseDto)=> {
        if (error$.response.data.message === 'Unauthorized') {
          // Get new Access Token
          refreshToken(authStore.userData.refreshToken)
            .then((response$: any) => {
              authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
              deleteUserHandler(id, response$.data.accessToken);        
            })
        }
        else {
          spinnerService.removeSpinner();
          showNotification('warning', error$.response.data.message, 'Error while deleting user data');
        }
      })
  }

  function saveUsersIntoDB (accessToken: string) {
    spinnerService.createSpinner();

    let valid: any = grid.current.validate();
    let employeesType = props.adminOnboarding ? EMPLOYEES_TYPE.onboard : EMPLOYEES_TYPE.employees;

    if (valid === true) {
      adminPanelStore.saveTempUsersIntoDB(employeesType, accessToken)
        .then(()=> {
          spinnerService.removeSpinner();
          if (props.gridData.find(emp => emp.email === authStore.userData.email)) {
            findMenuStructure(accessToken);
          }

          if (props.adminOnboarding) {
            props.adminOnboarding();
          }
          else {
            props.changeSection('tabs-section');
          }
        })
        .catch((error$: ErrorResponseDto)=> {
          if (error$.response.data.message === 'Unauthorized') {       
            // Get new Access Token
            refreshToken(authStore.userData.refreshToken)
              .then((response$: any) => {
                authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                saveUsersIntoDB(response$.data.accessToken);					
              })
          }
          else {
            spinnerService.removeSpinner();
            showNotification('warning', error$.response.data.message, 'Save employees error');
          }
        });
    }
  }

  function uploadEmployeeFile (file: any, accessToken: string) {
    spinnerService.createSpinner();

    let hiddenFileInput = document.getElementsByName('fileHiddenInput')[0] as HTMLInputElement;
    if (hiddenFileInput) {
      hiddenFileInput.value = "";
    }

    appliedFilters.current = grid.current.getFilters();

    adminPanelStore.uploadCSVFile(file, accessToken)
      .then(()=> {
        setTimeout(()=> {    
          if (appliedFilters.current.length > 0) {
            grid.current.setFilter(appliedFilters.current);   
          }                         
        }, 0) 
        spinnerService.removeSpinner();
      })
      .catch((error$: ErrorResponseDto)=> {
        if (error$.response.data.message === 'Unauthorized') {
          // Get new Access Token
          refreshToken(authStore.userData.refreshToken)
            .then((response$: any) => {
              authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
              uploadEmployeeFile(file, response$.data.accessToken);					
            })
        }
        else {
          spinnerService.removeSpinner(); 
          showNotification('warning', error$.response.data.message);
        }
      });
  }

  function findMenuStructure(accessToken: string) {
    const menuStructureURL = process.env.REACT_APP_PUBLIC_URL + "/auth/menu-structure";

    spinnerService.createSpinner();

    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }

    axios
       .get(menuStructureURL, {headers})
       .then((response$: AxiosResponse<MenusEndpointsDto[]>) => {
         authStore.updateMenu(response$.data);
         spinnerService.removeSpinner();
       })
       .catch((error$: ErrorResponseDto)=> {
         if (error$.response.data.message === 'Unauthorized') {
           // Get new Access Token
           refreshToken(authStore.userData.refreshToken)
              .then((response$: any) => {
                authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                findMenuStructure(response$.data.accessToken);
              })
         }
         else {
           showNotification('warning', error$.response.data.message);
         }
       })
  }

}

export default EmployeesGridComponent;