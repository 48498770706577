import React, {useContext, useState} from 'react';
import styles from './EmployeeChoosePosition.module.scss';
import AuthContext from "../../../../../store/auth-context";
import { Avatar } from "@mui/material";
import {capitalizeFirstLetter} from "../../../../../utils/capitalize-first-letter";
import {JobPositionDto} from "../../../../../types/EmployeeDashboard";

const EmployeeChoosePosition: React.FC<{name: string, image: string, jobOptions?: JobPositionDto[],
okCallback: Function}> = (props) => {
	const [jobOption, setJobOption] = useState<string>();
	const authStore = useContext(AuthContext);
	const jobOptionsFlag = props.jobOptions && props.jobOptions.length > 0;
	
	return (
		<>
			<div className='container-fluid'>
				<div className='row'>
					<div className='col-12'>
					<div className={styles['employee-choose-position']}>
						<div className='employee-choose-position-card'>
							<div className='title-wrapper'>
								<div className='image-container'>
									{
										props.image ? <img src={props.image} alt="User Image" /> : 
										<Avatar sx={{backgroundColor: authStore.userData.color}} className={'img'}>{props.name.slice(0,1).toUpperCase()}</Avatar>
									}
								</div>
								<h1 className={'header-1'}>Hi {props.name}</h1>
							</div>
							<div className='info-section u-margin-top-m'>
								<p className={'u-margin-top-s'}>
									{jobOptionsFlag ? 'Before you check your personal growth dashboard, please help us link your job title to one that exists in our database.'
										: 'Oops! No suggestions for your job title right now. Please try again later or reach out to our support team for assistance!'}
								</p>
								<div className='job-options'>
									{
										jobOptionsFlag && props.jobOptions!.map((jobPosition: { id: string, name: string, description: string}) => {
											return <div key={jobPosition.id} className={`job-position-tag ${jobOption === jobPosition.id ? 'checked' : ''}`}
											onClick={() => chooseJobOption(jobPosition.id)}>{jobOption === jobPosition.id ? <i className="fa-solid fa-check check-icon"></i> : null} {capitalizeFirstLetter(jobPosition.name)}
											</div>
										})
									}
								</div>
								<p className='link-support text-secondary'>
									{jobOptionsFlag ? 'If none of these positions matches yours, contact our support:' : 'Contact our support:'}
								</p>
								<a className={'button button-tertiary'} href='mailto: support@teamlift.co'>support@teamlift.co</a>
								{
									jobOptionsFlag &&
									<div className='button-wrapper'>
										<button disabled={!jobOption} className='button button-primary' type="button" onClick={() => jobOption && props.okCallback(jobOption)}>Submit</button>
									</div>
								}
							</div>
						</div>
					</div>
					</div>
				</div>
			</div>
		</>
	)

	function chooseJobOption(id: string) {
		setJobOption(id);
	}
}

export default EmployeeChoosePosition;