import {useContext, useEffect, useRef, useState} from 'react';

import axios, {AxiosRequestConfig} from 'axios';
import {resizeFile} from '../../../../../../utils/image-resize';
import {showNotification} from '../../../../../../ui/Toast/ToastNotification';

import AuthContext from '../../../../../../store/auth-context';
import {Avatar, Badge} from "@mui/material";
import useApiService from "../../../../../../services/api.service";
import {ErrorResponseDto} from "../../../../../../types/ErrorData";

const ImageUploadComponent: React.FC<{
    onUploadImage: any,
    file: any,
    saveProfilePictureURL: string,
    deleteProfilePictureURL: string }> = (props) => {
    const authStore = useContext(AuthContext);
    const uploadedImageInput = useRef<any>();
    const { refreshToken } = useApiService();
    const [uploadedImage, setUploadedImage] = useState(authStore.userData.profilePicture ? authStore.userData.profilePicture : '');

    function prepareImageCrop(event: any) {
        let file = event.target.files[0];
        resizeFile(file).then(r => {
            props.onUploadImage(file);
        }).catch(() => {
            showNotification('warning', 'It seems like you are trying to upload a file that is not an image format. Please choose the correct format.');
        })
    }

    useEffect(() => {
        if (props.file) {
            uploadImageHandler(props.file, authStore.userData.accessToken).then(r => {})
        }
    }, [props.file]);

    useEffect(() => {
       setUploadedImage(authStore.userData.profilePicture ? authStore.userData.profilePicture : '');
    }, [authStore.userData.profilePicture]);

    return (
        <div className='change-picture-wrapper'>
            <div className='change-picture-element'>
                <input id="upload-image" type='file' style={{'display': 'none'}} onChange={prepareImageCrop}
                       ref={uploadedImageInput}/>
                        <Badge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            badgeContent={<div className="dropdown">
                                <button id="managePhoto" className='change-picture-button dropdown-toggle' data-bs-toggle="dropdown"
                                        aria-expanded="false">
                                    <i className="fa-solid fa-pen"></i>
                                </button>
                                <ul className="dropdown-menu dropdown-menu-dark dropdown-menu-start" aria-labelledby="managePhoto">
                                    <li className="dropdown-item"><label htmlFor='upload-image'>Upload photo</label></li>
                                    {
                                        authStore.userData.profilePicture &&
                                        <li className="dropdown-item" onClick={() => deleteImageHandler(authStore.userData.accessToken)}>Delete photo</li>
                                    }

                                </ul>
                            </div>}
                        >
                            {
                                uploadedImage !== '' ?
                                    <img style={{borderRadius: '50%', width: '100%'}} src={uploadedImage} alt="User Image"/> :
                                    <Avatar sx={{width: 100, height: 100, fontSize: '300%', backgroundColor: authStore.userData.color}} className={'img'}>{authStore.userData.fullName.slice(0,1).toUpperCase()}</Avatar>
                            }
                        </Badge>
            </div>
        </div>
    )

    async function uploadImageHandler(file: any, accessToken: string) {
        // event.preventDefault()
        // let file = event.target.files[0];

        if (file) {
            try {
                const image = await resizeFile(file);

                const bodyFormData = new FormData();
                bodyFormData.append('file', image);

               const headers: AxiosRequestConfig['headers'] = {
                  'Authorization': `Bearer ${accessToken}`
               }

                axios
                    .post(props.saveProfilePictureURL, bodyFormData, {headers})
                    .then((response$) => {
                        uploadedImageInput.current.value = null;
                        authStore.changeProfileImage(response$.data.profilePicture);
                        showNotification('success', 'Profile picture was changed successfully.');
                    })
                    .catch((error$: ErrorResponseDto) => {
                        if (error$.response.data.message === 'Unauthorized') {
                            // Get new Access Token
                            refreshToken(authStore.userData.refreshToken)
                                .then((response$: any) => {
                                    authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                                    uploadImageHandler(file, response$.data.accessToken);
                                })
                        }
                        else {
                            uploadedImageInput.current.value = null;
                            showNotification('warning', error$.response.data.message);
                        }
                    })
            } catch (error$) {
                uploadedImageInput.current.value = null;
                showNotification('warning', 'It seems like you are trying to upload a file that is not an image format. Please choose the correct format.');
            }
        }
    }

    async function deleteImageHandler(accessToken: string) {
        if (uploadedImage !== '') {
           const headers: AxiosRequestConfig['headers'] = {
              'Authorization': `Bearer ${accessToken}`
           }

            axios
                .post(props.deleteProfilePictureURL, null, {headers})
                .then((response$) => {
                    authStore.changeProfileImage(response$.data.profilePicture);
                    showNotification('success', 'Profile picture was deleted successfully.');
                })
                .catch((error$: ErrorResponseDto) => {
                    if (error$.response.data.message === 'Unauthorized') {
                        // Get new Access Token
                        refreshToken(authStore.userData.refreshToken)
                            .then((response$: any) => {
                                authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                                deleteImageHandler(response$.data.accessToken);
                            })
                    }
                    else {
                        showNotification('warning', error$.response.data.message);
                    }
                });
        }
    }
}

export default ImageUploadComponent;