export type SkillSynonymData = {
   id: number,
   name: string,
   description?: string,
   aiId?: string,
   category?: string,
}

export enum SKILL_INVENTORY_TYPE {
   COMPANY = "company",
   PROJECT = "projects"
}

export const SKILL_INVENTORY_SOURCES = [
   'CV',
   'JIRA',
   'PLANVIEW',
   'WORKDAY',
   // 'CERTIFICATE',
   'LOTUS',
   'LINKEDIN',
   'ASANA',
   'TRELLO',
   'MEETING_NOTES',
   'GITHUB',
   'MANUALLY_ADDED'
]