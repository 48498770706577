import { SkillData } from "./SkillsData";
import { Employee } from "./Employee";
import {SynonymData} from "./ProjectOnboardingData";
import {SkillSynonymData} from "./SkillsInventoryData";

import {PaginationDataDto} from "./SkillsData";
import {SkillDto} from "./Settings";
import {ProjectDto} from "./Projects";

//
export type DashboardDto = {
  skillsDashboard: SkillsDashboardDto[];
  paginationData: PaginationDataDto;
}

export type SkillsDashboardDto = {
  skill: SkillDto;
  learnersNumber: number;
  lastEmployees: EmployeeDto[];
  leftEmployeesNumber: number;
}

export type DashBoardRequestDto = {
  page: number;
  pageSize: number;
  projectId?: number;
  searchTerm?: string;
  dashboardFilter?: DashboardFilter;
}

export type DashboardFilter = {
  dashboardFilterType?: SKILL_FILTER;
  categories?: string[];
  sources?: string[];
  projectIds?: number[];
}

export enum SKILL_FILTER {
  NEW = 'NEW',
  APPROVED = 'APPROVED',
  MISSING = 'MISSING',
  LEARNING = 'LEARNING',
}

export type SideBarDto = {
  skill: SkillsDashboardDto;
  activeSkillFilter: SKILL_VIEW_FILTER;
  skillPlusInformation: {
    employees: EmployeeDto[];
    projects?: ProjectDto[];
    sources?: {source: string}[];
  }
}

export type SideBarRequestDto = {
  skillId: number;
  skillViewFilter: SKILL_VIEW_FILTER;
  synonymId?: number;
  projectIds?: number[];
}

export enum SKILL_VIEW_FILTER {
  NEW = 'NEW',
  APPROVED = 'APPROVED',
  LEARNING = 'LEARNING',
  UPSKILL = 'UPSKILL',
  COMPANY_WIDE = 'COMPANY_WIDE',
}
//

//
export type JobPostingDashboardDto = {
  dashboardData: JobPostingSkillsDashboardDto[];
  bestCandidates: BestCandidate[];
}

export type JobPostingSkillsDashboardDto = {
  skill: SkillDto;
  employees: EmployeeDto[];
}
//

export type EmployeeDto = {
  id: number;
  name: string;
  surname: string;
  recoveryUsername: string;
  recoveryEmail: string;
  icon: string;
  profilePicture?: string;
  color?: string;
  validationFormulaScore: number;
}

export type ManagerDashboardData = {
  skills: {category: string, neededSkills: NeededSkillData[]}[],
  neededSkillsNumber: number,
  firstAttempt?: boolean,
  page: number,
  pageSize: number,
  totalCount: number,
  totalPages: number
}

export type BestCandidate = {
  employee: EmployeeDto;
  rank: number;
  skills: JobPostingSkillDto[];
  skillsCount: number;
}

export type JobPostingSkillDto = {
  skillId: number;
  skillName: string;
  skillDescription: string;
  synonymName?: string;
  totalValidationScore?: number;
  status?: string;
}

export type NeededSkillData = {
  accepted: {selfReported: boolean, employee: Employee, dateAdded: string, newSkill: boolean}[],
  unaccepted: {selfReported: boolean, employee: Employee, dateAdded: string, newSkill: boolean}[],
  learning: {selfReported: boolean, employee: Employee}[],
  bestCandidatesToLearn: Employee[],
  missing: boolean,
  skill: SkillData,
  synonym: SynonymData | SkillSynonymData[],
  projectSkillId: number
}