import React from "react";
import ReactTooltip from "react-tooltip";

const Tooltip: React.FC<{place?: string; multiline?: boolean; html?: boolean; backgroundColor?: string; content?: any; offset?: string; 
    event?: string; eventOff?: string; tooltipId: string, children?: any, className?: string, border?: boolean, borderColor?: string }> = (props) => {
  
    return (
    <div style={{'display': 'inline-block'}}>
      <div data-for={props.tooltipId} data-class={props.className} data-place={props.place} data-multiline={props.multiline} 
        data-background-color={props.backgroundColor} data-offset={props.offset} data-tip="" data-event={props.event} data-aria-has-popup={true}>
        {props.children}
      </div>
      
      <ReactTooltip id={props.tooltipId || ""} effect="solid" delayShow={100} delayHide={100} globalEventOff={'click'}
        clickable={true} arrowColor={props.backgroundColor} border={props.border} borderColor={props.borderColor} >
        {props.content}
      </ReactTooltip>
    </div>
  );
};

export default Tooltip;