import { useState, useEffect, useContext, useRef } from 'react';
import { useSpring, animated, useTransition } from 'react-spring';
import styles from './AddDescriptionComponent.module.scss';
import AuthContext from '../../../../../../../../../store/auth-context';
import { UserData } from '../../../../../../../../../types/AuthData';
import { showNotification } from '../../../../../../../../../ui/Toast/ToastNotification';
import ProjectsContext from '../../../../../../../../../store/projects-context';
import { ExtractedNeededSkillData } from '../../../../../../../../../types/ProjectOnboardingData';
import { PulseLoader } from 'react-spinners';
import Pusher from 'pusher-js';
import { FileUploader } from 'react-drag-drop-files';
import useApiService from "../../../../../../../../../services/api.service";
import Tooltip from '../../../../../../../../../services/tooltip.service';
import SpinnerButton from "../../../../../../../../../ui/SpinnerButton/SpinnerButton";
import {capitalizeFirstLetter} from "../../../../../../../../../utils/capitalize-first-letter";
import useLoadingSpinner from "../../../../../../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner";
import {ExtractedNeededSkillsDto} from "../../../../../../../../../types/ProjectsStoreTypes";
import {ErrorResponseDto} from "../../../../../../../../../types/ErrorData";

const AddDescriptionComponent: React.FC<{projectData: any, closeModal: Function}> = (props) => {
  const authStore = useContext(AuthContext);
  const userData: UserData = authStore.userData;
  const projectsStore = useContext(ProjectsContext);
  const spinnerService = useLoadingSpinner();
   
  const [showGeneratingSkillsTitle, setShowGeneratingSkillsTitle] = useState(false);
  const [projectDescriptionInput, setProjectDescriptionInput] = useState<string>('');
  const [projectDescriptionResponse, setProjectDescriptionResponse] = useState<string>('');
  const [dataIsLoadingDesc, setDataIsLoadingDesc] = useState<boolean>(false);
  const { refreshToken } = useApiService();

  const lastSkillElement: ExtractedNeededSkillData = projectsStore.extractedNeededSkillsData[projectsStore.extractedNeededSkillsData.length - 1];
  // Animation definitions
  const [firstSpring] = useSpring(()=> ({
    from: { top: '65%', opacity: 0 },
    to: { top: '4%', opacity: 1 },
    delay: 500,
  }))

  const [generatingSkillsTitleSpring] = useSpring(() => ({
    from: { opacity: 0 },
    to: { opacity: 1 }
  }))

  const [projectNeededSkillsTitleSpring] = useSpring(() => ({
    from: { opacity: 0 },
    to: { opacity: 1 }
  }))

  const [saveSkillsSpring] = useSpring(() => ({
    from: { opacity: 0 },
    to: { opacity: 1 }
  }))

  const [projectDescResSpring, projectDescResSpringApi] = useSpring(()=> ({
    from: { top: '65%', opacity: 0 }
  }))
 
  const transitions = useTransition(projectsStore.extractedNeededSkillsData, {    
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 1 },
    trail: 100,  
    update: null,
    key: (item: any) => {
      return item.skillAIId
    },    
    onRest: (event: any, event2: any, element: ExtractedNeededSkillData) => {
      if (lastSkillElement && lastSkillElement.skillName === element.skillName) {
        setShowGeneratingSkillsTitle(false);   
      }
    }
  })

  useEffect(() => {
    // projectsStore.getCompanyEmployees(props.projectData.id, userData.accessToken);   
    let pusher = new Pusher('44ba88727279103cb61c', {
      cluster: 'us2'
    });

    const channel = pusher.subscribe('my-channel');
    channel.bind(`skills_${props.projectData.id.toString()}`, (data: any) => pusherInitializer(data, authStore.userData.accessToken));
  }, []);

  useEffect(()=>{
    if (projectDescriptionResponse !== '') {
      projectDescResSpringApi.start({
        from: {
          top: '90%', opacity: 0
        },
        to: {
          top: '1%', opacity: 1
        }
      })
    }
  }, [projectDescriptionResponse])

  function pusherInitializer(data : any, accessToken: string) {
    {
      if (data.message.indexOf('failed') > -1) {
        showNotification('warning', 'Your file has failed processing. Please upload it again.');
      }
      else {
        projectsStore.getExtractedNeededSkillsAI(props.projectData.id, accessToken)
            .then(() => {
              setShowGeneratingSkillsTitle(false);
            })
            .catch((error$: ErrorResponseDto) => {
              if (error$.response.data.message === 'Unauthorized') {
                // Get new Access Token
                refreshToken(authStore.userData.refreshToken)
                    .then((response$: any) => {
                      authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                      pusherInitializer(data,response$.data.accessToken);
                    })
              }
              else {
                setShowGeneratingSkillsTitle(false);
                showNotification('warning', error$.response.data.message);
              }
            });
      }
    }
  }
 

  return (
    <section className={styles['add-description-section']}>
      <div className='needed-skills-wrapper'>
        <div className='info-wrapper'>
          { projectsStore.extractedNeededSkillsData.length === 0 &&
            <animated.span className={'q-field mt-5'} key={'run-ai'} style={{ ...firstSpring }}>
              Provide a detailed description of the work that needs to be done. Our AI will analyze your input and extract the main skills required. The more detailed your description, the more accurate our analysis will be!
            </animated.span>  
          }    
          
          {/* Generating skills tag */}
          {showGeneratingSkillsTitle &&
            <animated.div className='generating-skills' style={{ ...generatingSkillsTitleSpring }}>
              AI is generating needed skills
              <PulseLoader
                loading={true}
                color='#9A9DA7'
                size={7}
                speedMultiplier={.8}
                margin={8}
                style={{ width: 'auto', display: 'inline-flex', alignItems: 'flex-end' }}
              />
            </animated.div>
          }
          {/* Needed skills title */}
          {!showGeneratingSkillsTitle && projectsStore.extractedNeededSkillsData.length > 0  &&
            <animated.div style={{ ...projectNeededSkillsTitleSpring }}>
              {/* { onboardingManagerStore.extractedNeededSkillsData.length > 0 ? */}
                <div className='generating-skills'>Skills, needed for your project:</div>
                {/* <p className='mt-5'>AI can't find skills from your data. Please upload another file or write another project description, or just find the skills manually.</p> */}
              {/* }             */}
            </animated.div>
          }
          {/* Skills tags */}      
          { projectsStore.extractedNeededSkillsData !== undefined && projectsStore.extractedNeededSkillsData.length > 0 &&
            <>
              <div className='skills-tag-wrapper'>
                { transitions((style: any, item: any) => (                  
                    <animated.span style={style} key={item.skillAIId} id={item.skillAIId} onClick={()=> { toggleSkillSelect(item) }} 
                    className={`skill-tag ${checkSelection(item.skillAIId ? item.skillAIId : '')}`}>
                      {capitalizeFirstLetter(item.skillName)}
                      <span>
                        <Tooltip
                          place="right"                                         
                          tooltipId={item.skillAIId + "_tooltip"}  
                          backgroundColor="#101319"
                          borderColor='#5C5F6A !important'
                          border={true}            
                          content={
                            <span>{item.skillDescription}</span>                                 
                          }
                          className='tag-skill-description'
                        >
                          <span className='select-skill-description-symbol'><i className="fa-regular fa-circle-question"></i></span>
                        </Tooltip>  
                      </span>
                    </animated.span>            
                  ))
                } 
              </div>
              <animated.div style={{ ...saveSkillsSpring, position: 'relative' }}>
                <button disabled={showGeneratingSkillsTitle} className={'button button-primary'} onClick={() => saveExtractedSkills(authStore.userData.accessToken)}>
                  Save <i className="fa-solid fa-arrow-right u-margin-left-xxs"></i>
                </button>
              </animated.div>
            </>
          }          
        
          { projectDescriptionResponse !== '' &&
            <animated.span className={'a-field'} style={{...projectDescResSpring}}>
              {projectDescriptionResponse}
            </animated.span> 
          }            
        </div>
        {/* Description text area and upload document */}
        { (projectDescriptionResponse === '' && !showGeneratingSkillsTitle) &&
          <div className='add-description-wrapper'>
            <div className='textarea-wrapper'>
              <textarea autoComplete='off' placeholder='Answer here' value={projectDescriptionInput} onKeyDown={onKeyDownHandler} onChange={insertProjectDescHandler} />
                <div className='button-wrapper'>
                  {
                    projectDescriptionInput !== '' && projectDescriptionInput.trim().length > 1 &&              
                      <SpinnerButton dataLoading={dataIsLoadingDesc} cssClasses='button button-primary u-margin-bottom-xs' onClickCallback={saveProjectDescription} title={'Send'}></SpinnerButton>
                  }
                </div>
            </div>
            <FileUploader name="fileHiddenInput" classes={"file-uploader"} children={
              <button className="button button-tertiary u-margin-left-xs">upload a file</button>
            }  handleChange={(file: any) => browseFile(file, authStore.userData.accessToken)} />
          </div> 
        } 
      </div>
      {
        spinnerService.spinner
      }
    </section>
  );

  function browseFile (file: any, accessToken: string) {
    let hiddenFileInput = document.getElementsByName('fileHiddenInput')[0] as HTMLInputElement;
    if (hiddenFileInput) {
      hiddenFileInput.value = "";
    }

    setShowGeneratingSkillsTitle(true);

    projectsStore.uploadNeededSkillsFileHandler(file, props.projectData.id, accessToken)
      .then(()=> {        
        return
      })
      .catch((error$: ErrorResponseDto)=> {
        if (error$.response.data.message === 'Unauthorized') {
          // Get new Access Token
          refreshToken(authStore.userData.refreshToken)
              .then((response$: any) => {
                authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                browseFile(file, response$.data.accessToken);
              })
        }
        else {
          showNotification('warning', error$.response.data.message);
        }
      });
  }

  function insertProjectDescHandler(e: any) {
    e.target.style.height = 'auto';
    e.target.style.height = (e.target.scrollHeight) + 'px';
    setProjectDescriptionInput(e.target.value);
  }

  function onKeyDownHandler(e: any){
    if (e.key === 'Enter' && !dataIsLoadingDesc) {
      saveProjectDescription();
    }
  }

  function saveProjectDescription() {
    let projectDescription: string = projectDescriptionInput.trim();
    
    if (projectDescription !== '') {
      setProjectDescriptionResponse(projectDescription);
      setShowGeneratingSkillsTitle(true);
      setProjectDescriptionInput('');
      
      setTimeout(()=> {
        setProjectDescription(projectDescription, authStore.userData.accessToken);
      }, 1000)
    }
  }


  function setProjectDescription (projectDescription : string, accessToken : string) {
    setDataIsLoadingDesc(true);
    projectsStore.sendTextNeededSkills(projectDescription, props.projectData.id, accessToken)
        .then((response$: any)=> {
          setShowGeneratingSkillsTitle(false);
          setProjectDescriptionResponse('');
          setDataIsLoadingDesc(false);
        })
        .catch((error$: ErrorResponseDto)=> {
          if (error$.response.data.message === 'Unauthorized') {
            // Get new Access Token
            refreshToken(authStore.userData.refreshToken)
                .then((response$: any) => {
                  authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                  setProjectDescription(projectDescription, response$.data.accessToken);
                })
          }
          else {
            setDataIsLoadingDesc(false);
            setShowGeneratingSkillsTitle(false);
            setProjectDescriptionResponse('');
            showNotification('warning', error$.response.data.message);
          }
        });
  }

  function checkSelection(id: string) {    
    let skill: any = projectsStore.neededSkillsTempData.find((item: any) => item.skillAIId === id);
    return skill.deleted  ? 'removed' : '';
  }

  function toggleSkillSelect (skill: ExtractedNeededSkillData) {
    let skillElement = document.getElementById(skill.skillAIId as string) as HTMLSpanElement;
    if (skillElement) {
      skillElement.classList.toggle('removed');
    }
    projectsStore.toggleNeededSkillsTempData(skill.skillAIId as string);
  }

  function saveExtractedSkills(accessToken: string): void {
    spinnerService.createSpinner();
    let neededSkills: number[] = [];
    let projectId = +props.projectData.id;

    for (let skill of projectsStore.neededSkillsTempData as ExtractedNeededSkillsDto[]) {
      if (!skill.deleted) {
        neededSkills.push(skill.skillProjectDataId);
      }      
    }
    projectsStore.selectExtractedNeededSkill(neededSkills, projectId, accessToken)
      .then(() => {
        props.closeModal();
        spinnerService.removeSpinner();
      })
      .catch((error$ : ErrorResponseDto)=> {
        if (error$.response.data.message === 'Unauthorized') {
          // Get new Access Token
          refreshToken(authStore.userData.refreshToken)
              .then((response$: any) => {
                authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                saveExtractedSkills(response$.data.accessToken);
              })
        }
        else {
          spinnerService.removeSpinner();
          showNotification('warning', error$.response.data.message);
        }
      })
  }
}

export default AddDescriptionComponent;