import { useEffect, useContext } from 'react';
import { useSpring, animated } from 'react-spring';
import styles from './FinishOnboardingComponent.module.scss';

import AuthContext from '../../../../../../store/auth-context';
import OnboardingManagerContext from '../../../../../../store/create-project-context';

import { UserData } from '../../../../../../types/AuthData';

import useLoadingSpinner from '../../../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner';
import {useNavigate} from 'react-router-dom';

import useApiService from '../../../../../../services/api.service';
import { showNotification } from '../../../../../../ui/Toast/ToastNotification';
import {ErrorResponseDto} from "../../../../../../types/ErrorData";

const FinishOnboardingComponent: React.FC<{changeSection: Function, isDraft?: boolean, projectId: any,
  isOnboarding: boolean, clickedSaveAsDraft: boolean, saved: Function, jobPosting?: boolean}> = (props) => {
  const authStore = useContext(AuthContext);
  const onboardingManagerContext = useContext(OnboardingManagerContext);
  const userData: UserData = authStore.userData;

  const spinnerService = useLoadingSpinner();
  const navigate = useNavigate();
  const apiService = useApiService();

  const [greatNewsTextSpring, greatNewsTextSpringApi] = useSpring(()=> ({
    key: 'great-news',
    from: { top: '55%', opacity: 0 }
  }))

  const [everyThingSetUp, everyThingSetUpApi] = useSpring(()=> ({
    key: 'great-news',
    from: { top: '55%', opacity: 0 }
  }))

  const [readyToStartTextSpring, readyToStartTextSpringApi] = useSpring(()=> ({
    key: 'ready-to-start',
    from: { top: '55%', opacity: 0 }
  }))

  const [letsStartSpring, letsStartSpringApi] = useSpring(()=> ({
    key: 'lets-start',
    from: { top: '55%', opacity: 0 }
  }))
  
  const [nextStepSpring, nextStepSpringApi] = useSpring(()=> ({
    key: 'next-step',
    from: { top: '55%', opacity: 0 }
  }))

  useEffect(() => {
    if (props.isOnboarding) {
      greatNewsTextSpringApi.start({
        to: { top: '2%', opacity: 1 },
        delay: 600, 
        })
      readyToStartTextSpringApi.start({
          to: { top: '2%', opacity: 1 },
          delay: 1200, 
      })
      letsStartSpringApi.start({  
          to: { top: '2%', opacity: 1 },
          delay: 1800,     
        })
    }
    nextStepSpringApi.start({    
        to: { opacity: 1 },
        delay: props.isOnboarding ? 2400 : 1200
      })
    everyThingSetUpApi.start({
      to: { opacity: 1 },
      delay: 600
    })
  }, [])

  useEffect(() => {
    if (props.clickedSaveAsDraft){
      props.saved();
    }
  }, [props.clickedSaveAsDraft]);

  return (
    <section className={styles['finish-project']}>
      {
        props.isOnboarding ? 
        <div className='project-name-wrapper'>
          <div className='info-wrapper'>
            <animated.span className={'q-field'} style={{...greatNewsTextSpring}}>
              Great news!
            </animated.span>
          
            <animated.span className={'q-field'} style={{...readyToStartTextSpring}}>
              We're just about ready for you to start using TeamLift.
            </animated.span>
            
            <animated.span className={'q-field'} style={{...letsStartSpring}}>
              Let's get started by going to the product.
            </animated.span>

            <animated.div style={{ ...nextStepSpring }} key='next-step' className={'mt-5'}>
              <button className={'btn next-step-button'} onClick={()=>finishOnboarding(userData.accessToken)}>
                Start with TeamLift
              </button>
            </animated.div>
          </div>         
        </div>
        :
        <div className='project-name-wrapper'>
          <div className='back-section-button'>
            <button className='btn btn-outline-text-secondary btn-sm' onClick={()=>props.changeSection(props.jobPosting ? 'project-skills-section' : 'project-employees-section')}><i className="fa-solid fa-angle-left"></i> Back</button>
          </div>
          <animated.span className={'q-field'} style={{ ...everyThingSetUp }}>
            Everything is set up! You can click '{props.jobPosting ? 'Review candidates' : 'Create project'}' and see your dashboard.
          </animated.span>

          <animated.div style={{ ...nextStepSpring }} key='next-step' className={'mt-3'}>
            <button className={'btn btn-sm btn-blue-custom next-step-button'} onClick={()=>finishOnboarding(userData.accessToken)}>
              {
                props.jobPosting ? 'Review candidates' : 'Create project'
              }
            </button>
          </animated.div>
        </div>
      }
      {
        spinnerService.spinner
      }
    </section> 
  )

  function finishOnboarding(accessToken: string) {
    spinnerService.createSpinner();
    let projectId: any = props.isDraft ? props.projectId : onboardingManagerContext.projectData.project?.id;
    onboardingManagerContext.finishOnboardingProject(projectId, accessToken)
      .then((response$: any) => {
        spinnerService.removeSpinner();
        if (props.jobPosting) {
          navigate(`/manager/tasks/${projectId}/needed-skills`);
        }
        else {
           navigate(`/manager/projects/${projectId}/needed-skills`);
        }
      })
      .catch((error$: ErrorResponseDto) => {
        if (error$.response.data.message === 'Unauthorized') {
          // Get new Access Token
          apiService.refreshToken(authStore.userData.refreshToken)
            .then((response$: any) => {
              authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
              finishOnboarding(response$.data.accessToken);
            })
        }
        else {
          spinnerService.removeSpinner();
          showNotification('warning', error$.response.data.message);
        }
      });
  }
}

export default FinishOnboardingComponent;