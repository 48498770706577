import { useEffect, useRef } from 'react';
import styles from './WelcomeComponent.module.scss';

const WelcomeComponent: React.FC<{user: string, changeSection: React.MouseEventHandler<HTMLButtonElement>}> = (props) => {
  const titleRef = useRef<any>();
  const nameRef = useRef<any>();
  const descriptionRef = useRef<any>();
  const cancelButtonRef = useRef<any>();
  const startButtonRef = useRef<any>();

  useEffect(() => {
     setTimeout(()=> {
      titleRef.current.classList.add('show');
     }, 800)     
     setTimeout(()=> {
      nameRef.current.classList.add('show');
     }, 1600)
     setTimeout(()=> {
      descriptionRef.current.classList.add('show');
     }, 2400)  
     setTimeout(()=> {
      cancelButtonRef.current.classList.add('show');
      startButtonRef.current.classList.add('show');
     }, 3200)
  }, [])  

  return (
    <div className={styles['welcome-manager-component']}>
      <div className='title-wrapper' ref={titleRef}>
        <div className='logo-container' >
          <img src='/assets/images/global-icons/teamlift-icon-white.svg' alt="TeamLift logo" />
        </div>
        <h1>Welcome to TeamLift</h1>
      </div>
      <div className='info-container'>
        <p><span id='user-name' ref={nameRef}>Hi, {props.user}</span><br />
        <span id='description' ref={descriptionRef}>You have been invited to make your teams more efficient <br />
          by using AI and skills data. We are thrilled to help you get started!</span></p>
      </div>
      <div className='button-container'>
        <button ref={cancelButtonRef} className='btn btn-surface-60 me-2' onClick={goToSkillWallet}>Cancel</button>
        <button ref={startButtonRef} className='btn btn-blue-custom' onClick={props.changeSection}>Get started</button>
      </div>
    </div>
  )

  function goToSkillWallet() {
    let employeeButton = document.getElementById('Employee_menu-switch-button') as HTMLButtonElement;
    if (employeeButton) {
      employeeButton.click();
    }
  }
}

export default WelcomeComponent;