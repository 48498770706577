import {SKILLS_SORT_TYPE} from "../enums/Skills.enum";
import {SynonymDto} from "./Settings";
import {LevelDto} from "./Projects";
import {ValidationFactorLevelEnum} from "./EmployeeDashboard";

export type AnimationDateMapping = Record<ApprovedStatus.NEW | ApprovedStatus.APPROVED | ApprovedStatus.REJECTED, { id: number, counter: number }[]>;

export type SearchSkillsWalletDto = {
   name?: string,
   sources?: {
      value: string,
      label: string,
      description: string}[]
}

export type SkillsResponseDto = {
   skills: SetDetailJsonDto[];
   socialValidation: boolean;
   pagination: PaginationDataDto;
   skillsListInformation: SkillsListInformationDto;
}

export type SkillsListInformationDto = {
   newListTotalCount: number;
   approvedListTotalCount: number;
   learningListTotalCount: number;
   rejectedListTotalCount: number;
};

export type SetDetailJsonDto = {
   skill: SkillDto;
   synonym?: SynonymDto;
   dateAdded?: Date;
   dateApproved?: Date;
   approved: ApprovedStatus;
   descriptors: SetDetailDescriptorsDto[];
   dynamicExtracted: boolean;
   quantitativeValidationLevel: ValidationFactorLevelEnum;
   quantitativeValidationScore: number;
   socialValidationLevel: ValidationFactorLevelEnum;
   socialValidationScore: number;
   totalValidationScore: number;
   totalValidationLevel: ValidationFactorLevelEnum;
   message: string;
   isNewSkill?: boolean;
}

export type SetDetailDescriptorsDto = {
   source: string;
   dateAdded: Date;
}

export enum ApprovedStatus {
   NEW = 'NEW',
   APPROVED = 'APPROVED',
   REJECTED = 'REJECTED',
   LEARNING = 'LEARNING',
}

export type PaginationDataDto = {
   page: number;
   pageSize: number;
   totalCount: number;
   totalPages: number;
}

export type SkillDto = {
   id: number;
   name: string;
   description: string;
   icon: string;
   category: string;
   aiId: string;
   isTool: boolean;
   isAITool: boolean;
   level: LevelDto;
   synonym?: SynonymDto;
}

export type SkillData = {
   id: number,
   name: string,
   category: string,
   description: string,
   icon: any
}

export type Skill = {
   skill: SkillData
   approved: string,
   descriptors: any[],
   weight: string,
   selfReported?: boolean,
   dateAdded: string,
   isNewSkill?: boolean,
   dynamicExtracted: boolean,
   message: string,
   quantitativeValidationLevel: string,
   quantitativeValidationScore: number,
   socialValidationLevel: string,
   socialValidationScore: number,
   totalValidationLevel: string,
   totalValidationScore: number
}

export type SkillCardData = {
   id: number,
   title: string,
   type: string,
   level?: string,
   addDenySkill?: (id: number, title: string, isApproved: boolean) => void,
   dataLoading?: boolean,
   descriptors: any[],
   description?: string,
   selfReported?: boolean,
   isNewSkill?: boolean,
   checkSkill?: (id: number) => void,
   checked?: boolean,
   totalValidationLevel: string,
   totalValidationScore: number,
   validationMessage: string
}

export type SkillFilterOptions = {
   title: string,
   value: SKILLS_SORT_TYPE
}