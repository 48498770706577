import React, { useEffect, useState, useContext } from 'react';
import styles from './AdminPanelPage.module.scss';

import { Tabs, Tab, TabList, TabPanel } from 'react-tabs';

import AuthContext from '../../../../store/auth-context';
import AdminPanelContext from '../../../../store/admin-panel-context';

import useLoadingSpinner from '../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner';

import EmployeesGridComponent from './EmployeeGridComponent/EmployeesGridComponent';
import AddEmployeeComponent from './AddEmployeeComponent/AddEmployeeComponent';
import useApiService from '../../../../services/api.service';
import {showNotification} from "../../../../ui/Toast/ToastNotification";
import {ErrorResponseDto} from "../../../../types/ErrorData";
import ReactSlidingPane from "react-sliding-pane";
import {capitalizeFirstLetter} from "../../../../utils/capitalize-first-letter";
import {useNavigate} from "react-router-dom";

const AdminPanelPage: React.FC = props => {
  const [activeSection, setActiveSection] = useState<string>('tabs-section');
    
  const authStore = useContext(AuthContext);
  const adminPanelStore = useContext(AdminPanelContext);
  const navigate = useNavigate();

  const [openPanel, setOpenPanel] = useState(false);
  const [allCategories, setAllCategories] = useState<Map<string, boolean>>(new Map());
  const spinnerService = useLoadingSpinner();

  const [componentIsInit, setComponentIsInit] = useState<boolean>(false);
  const { refreshToken } = useApiService();

  let timeout: string | number | NodeJS.Timeout | undefined;


  useEffect(()=> {
     authStore.changePageTitle(authStore.userData.displayName + "'s Admin Panel");
     initializeComponent(authStore.userData.accessToken);
  }, []);

   useEffect(() => {
      if (componentIsInit && adminPanelStore.employeeData && adminPanelStore.employeeData.length === 0) {
         navigate('/admin-onboard/admin-onboarding');
      }
   }, [adminPanelStore.employeeData]);
  
  return (
    <div className={styles['admin-panel-page']}>
      { componentIsInit &&
        <div className='container-fluid'>
          <div className='row'>         
            {
              activeSection === 'tabs-section' &&
                <div className="tabs-container col-12">
                  <Tabs className={'custom-tabs'} selectedTabClassName={'selected-tab'}>
                    <TabList>
                      <Tab>People</Tab>
                      <Tab>Categories</Tab>
                    </TabList>
                    <TabPanel>
                      <div className='employees-tab'>
                        <div className='employees-header'>
                          <div className='employees-counter'>All employees: {adminPanelStore.employeeData.length}</div>
                          <button className='button button-tertiary' onClick={()=> changeSection('add-employee')}>+ Add people</button>
                        </div>    
                        <div className='employees-grid'>
                          <EmployeesGridComponent gridData={adminPanelStore.employeeData} />
                        </div>
                      </div>               
                    </TabPanel>

                    <TabPanel>
                      <div className="categories-tab">
                        <div className="categories-title">
                          <h1 className="header-1">Skill categories settings</h1>
                          <button className="button button-tertiary" onClick={() => getCategories(authStore.userData.accessToken)}>Edit</button>
                        </div>
                        <p className={'categories-text'}>The AI uses these preselected categories for extracting skills within your company.
                          These categories have been chosen in advance.</p>
                        <div className="categories-section">
                          {
                            adminPanelStore.categories.map(category => <div key={category} className={'category'}>{capitalizeFirstLetter(category)}</div>)
                          }
                        </div>
                      </div>
                    </TabPanel>
                  </Tabs>                                     
                </div>
            }
            {
              activeSection === 'add-employee' &&
              <div className='col-12 col-xxl-11'>
                <AddEmployeeComponent gridData={adminPanelStore.userData} changeSection={changeSection} />
              </div>
            }
          </div>
        </div>

      }
      {
        spinnerService.spinner
      }
      <ReactSlidingPane isOpen={openPanel}
                        onRequestClose={() => setOpenPanel(false)}
                        from={'right'}
                        className='admin-side-panel'
                        width='45%' hideHeader>
        <div>
           <div className="admin-side-panel-body">
              <div className="admin-side-panel-header">
                 <h1 className="header-1">Edit skill categories</h1>
                 <div onClick={() => setOpenPanel(false)} className="close-button-container"><i
                    className="fa-solid fa-xmark"></i></div>
              </div>

              <p className={'admin-side-panel-text u-margin-top-xs'}>These are all the categories you've selected in advance. To disable a category, simply uncheck it.</p>
              <p className={'admin-side-panel-text u-margin-top-xs'}>Please select at most 3 categories.</p>
              <div className="category-list u-margin-top-s">
                 {
                    Array.from(allCategories.keys()).map(category => {
                       const checked = allCategories.get(category);
                       return (
                          <div key={category} className="category_item" onClick={() => clickCategory(category)}>
                             <div className={`checkbox-dark ${checked ? 'checked' : ''}`}><i className="fa-solid fa-check"></i></div>
                             <span className={'u-margin-left-xs'}>{capitalizeFirstLetter(category)}</span>
                          </div>
                       );
                    })
                 }
              </div>
           </div>
           <div className="buttons-wrapper u-margin-top-m">
              <button className="button button-secondary u-margin-right-s" onClick={() => setOpenPanel(false)}>Cancel</button>
              <button disabled={checkForChanges()} className="button button-primary" onClick={chooseCategories}>Save</button>
           </div>
        </div>
      </ReactSlidingPane>
    </div>
  )

   function chooseCategories() {
     if (!checkForChanges()) {
        const checkedCategories = Array.from(allCategories.keys()).filter(c => allCategories.get(c));
        if (checkedCategories.length > 0 && checkedCategories.length <= 3) {
           chooseSkillCategories(checkedCategories, authStore.userData.accessToken);
           setOpenPanel(false);
        }
     }
   }

   function checkForChanges() {
      const checkedCategories = Array.from(allCategories.keys()).filter(c => allCategories.get(c));
      if (checkedCategories.length === 0) {
         return true;
      }
      else if (checkedCategories.length === adminPanelStore.categories.length) {
         return checkedCategories.every(category => (adminPanelStore.categories as string[]).includes(category));
      }
      return false;
   }

   function clickCategory(category: string) {
     if (Array.from(allCategories.values()).filter(v => v).length <= 2 || allCategories.get(category)) {
        setAllCategories(prevState => {
           // Create a new map from the previous state
           const newMap = new Map(prevState);

           // Toggle the boolean value for the given category
           const currentValue = newMap.get(category);
           newMap.set(category, !currentValue);

           // Return the new map directly, without using "new"
           return newMap;
        });
     }
     else {
        clearTimeout(timeout);

        timeout = setTimeout(() => {
           showNotification("warning", "You can only select at maximum 3 categories.", "Too much categories");
        }, 500);
     }
   }


   function initializeComponent (accessToken: string) {
    
    spinnerService.createSpinner();

    adminPanelStore.getAdminPanel(accessToken)
      .then(()=> {
        setComponentIsInit(true);
        spinnerService.removeSpinner();
      })
      .catch((error$: ErrorResponseDto)=> {
        if (error$.response.data.message === 'Unauthorized') {
          // Get new Access Token
          refreshToken(authStore.userData.refreshToken)
            .then((response$: any) => {
              authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
              initializeComponent(response$.data.accessToken);
            })
        }
        else {
          setComponentIsInit(true);
          spinnerService.removeSpinner();
          showNotification('warning', error$.response.data.message);
        }
      })
  }

  function getCategories(accessToken: string){
    spinnerService.createSpinner();

    adminPanelStore.getCategories(accessToken)
       .then((response$: {category: string}[]) => {
         spinnerService.removeSpinner();
         response$.map(category => {
            if (adminPanelStore.categories.find(c => c === category.category)) {
               allCategories.set(category.category, true);
            }
            else {
               allCategories.set(category.category, false);
            }
         })
         setOpenPanel(true);
       })
       .catch((error$: ErrorResponseDto)=> {
         if (error$.response.data.message === 'Unauthorized') {
           // Get new Access Token
           refreshToken(authStore.userData.refreshToken)
              .then((response$: any) => {
                authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                getCategories(response$.data.accessToken);
              })
         }
         else {
           setComponentIsInit(true);
           spinnerService.removeSpinner();
           showNotification('warning', error$.response.data.message);
         }
       })
  }

  function chooseSkillCategories(categories: string[], accessToken: string) {
     spinnerService.createSpinner();
     adminPanelStore.chooseSkillCategories(categories, accessToken)
        .then(() => {
           spinnerService.removeSpinner();
        })
        .catch((error$: ErrorResponseDto)=> {
           if (error$.response.data.message === 'Unauthorized') {
              // Get new Access Token
              refreshToken(authStore.userData.refreshToken)
                 .then((response$: any) => {
                    authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                    chooseSkillCategories(categories, response$.data.accessToken);
                 })
           }
           else {
              setComponentIsInit(true);
              spinnerService.removeSpinner();
              showNotification('warning', error$.response.data.message);
           }
        })
  }

  function changeSection (section: string): void {
    setActiveSection(section);
  }
}

export default AdminPanelPage;