import React, {useState, useEffect, useContext} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import styles from './OnboardingManagerProcessComponent.module.scss';
import ProjectNameComponent from './ProjectNameDescriptionComponent/ProjectNameDescriptionComponent';
import ProjectNeededSkillComponent from './ProjectNeededSkillsComponent/ProjectNeededSkillsComponent';
import SearchNeededSkillComponent from './SearchNeededSkillComponent/SearchNeededSkillComponent';
import FinishOnboardingComponent from '../FinishOnboardingComponent/FinishOnboardingComponent';
import useLoadingSpinner from "../../../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner";
import OnboardingManagerContext from "../../../../../../store/create-project-context";
import authContext from "../../../../../../store/auth-context";
import axios, {AxiosRequestConfig} from "axios";
import useApiService from "../../../../../../services/api.service";
import ProjectTeamComponent from "./ProjectTeamComponent/ProjectTeamComponent";
import {showNotification} from "../../../../../../ui/Toast/ToastNotification";
import {ErrorResponseDto} from "../../../../../../types/ErrorData";

const OnboardingManagerProcessComponent: React.FC<{ projectId?: any, feComponent?: any, isOnboarding?: boolean, clickedSaveAsDraft?: boolean, projectIsDraft? : Function, jobPosting?: boolean }> = (props)=> {
  const [section, setSection] = useState('project-name-section');
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  let params = useParams() as any;
  const [isDraft, setIsDraft] = useState<boolean>(false);
  const [projectId, setProjectId] = useState<any>(null);
  const onboardingManagerStore = useContext(OnboardingManagerContext);
  const authStore = useContext(authContext);
  const apiService = useApiService();
 
  const resize_ob = new ResizeObserver((element: any) => {
    let el = element[0].target as HTMLDivElement;
    if (el.scrollHeight > el.clientHeight) {
      el.scrollTo({ top: el.scrollHeight, behavior: "smooth" });
    }
  });

  const navigate = useNavigate();
  const spinnerService = useLoadingSpinner();
 
  useEffect(()=> {
    if (params.component) { // This is draft from create project
      setIsDraft(true);
      setProjectId(params.id ? params.id : null);
      setSection(params.component);
      if (props.projectIsDraft) {
        props.projectIsDraft();
      }
    }
    else if (props.feComponent) { // This is draft from onboarding
      setIsDraft(true);
      setProjectId(props.projectId ? props.projectId : null);
      setSection(props.feComponent);
    }
    
    setIsInitialized(true);
    let element = document.getElementById('onboarding-manager-process') as HTMLDivElement;    
    resize_ob.observe(element);
  }, [])

  useEffect(() => {
    if (!props.clickedSaveAsDraft){
      return;
    }
    spinnerService.createSpinner();
  }, [props.clickedSaveAsDraft]);

  return (
    <div className={styles['onboarding-manager-process-component']} id='onboarding-manager-process'>
      {
        isInitialized &&
        <>
          {
            section === 'project-name-section' && <ProjectNameComponent jobPosting={props.jobPosting} changeSection={changeSection} clickedSaveAsDraft={props.clickedSaveAsDraft!} saved={savedAsDraft} saveProgress={saveProgress}/>
          }    
          {
            (section === 'project-skills-section' || section === 'project-skills-section-file') && <ProjectNeededSkillComponent  jobPosting={props.jobPosting}
              changeSection={changeSection} isDraft={isDraft} projectId={projectId} clickedSaveAsDraft={props.clickedSaveAsDraft!} saved={savedAsDraft} saveProgress={saveProgress}
             uploadedFile={section === 'project-skills-section-file'}/>
          }
          {
            section === 'search-skills-section' && <SearchNeededSkillComponent  jobPosting={props.jobPosting}
              changeSection={changeSection} clickedSaveAsDraft={props.clickedSaveAsDraft!} saved={savedAsDraft} isDraft={isDraft} projectId={projectId} saveProgress={saveProgress}/>
          }
          {
            section === 'project-employees-section' && <ProjectTeamComponent isOnboarding={props.isOnboarding ? true : false} clickedSaveAsDraft={props.clickedSaveAsDraft!} saved={savedAsDraft} saveProgress={saveProgress} projectId={projectId} changeSection={changeSection} />
          }
          {
            section === 'finish-onboarding-section' && <FinishOnboardingComponent jobPosting={props.jobPosting}
                  changeSection={changeSection} isDraft={isDraft} projectId={projectId}
              isOnboarding={props.isOnboarding ? true : false} clickedSaveAsDraft={props.clickedSaveAsDraft!} saved={savedAsDraft}/>
          }
        </>
      }
      {
        spinnerService.spinner
      }
    </div>
  )

  function saveProgress(projectId: number, section: string, accessToken: string) {
    const saveProgressURL: string = process.env.REACT_APP_PUBLIC_URL + '/project/update-project-fe-component';

    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }

    let body = {
      'projectId': +projectId,
      'component': section
    }

    axios
        .post(saveProgressURL, body, {headers})
        .then(() => {
          return
        })
        .catch((error$: ErrorResponseDto) => {
          if (error$.response.data.message === 'Unauthorized') {
            // Get new Access Token
            apiService.refreshToken(authStore.userData.refreshToken)
                .then((response$: any) => {
                  authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                  saveProgress(projectId, section, response$.data.accessToken);
                })
          }
          else {
            spinnerService.removeSpinner();
            showNotification('warning', error$.response.data.message);
          }
        })
  }

  function savedAsDraft() {
    navigate(`/manager/${props.jobPosting ? 'tasks' : 'projects'}`);
    spinnerService.removeSpinner();
  }

  function changeSection(section: string) {
    setSection(section);
  }
}

export default OnboardingManagerProcessComponent;