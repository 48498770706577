 import styles from './ProjectsComponent.module.scss';
import {ProjectData} from "../../../../../../types/ProjectDashboardData";
import React, {useContext, useEffect, useState} from "react";
import {PROJECT_SORT_TYPE, PROJECT_STATUS} from "../../../../../../enums/Projects.enum";
import AuthContext from "../../../../../../store/auth-context";
import {compareDates} from "../../../../../../utils/compare-dates";
import {Checkbox} from "@mui/material";
import FilterComponent from "../../../../FilterComponent/FilterComponent";
import ProjectCardComponent from "../ProjectCard/ProjectCardComponent";

const ProjectsComponent: React.FC<{
   projectList: ProjectData[],
   deleteProjects: Function,
   changeProjectStatus: Function,
   changeProjectStatusesOrDelete: Function,
   filterOptions: any,
   isManager: boolean,
   projectStatus: PROJECT_STATUS,
   jobPosting?: boolean
}> = (props) => {

   const [checkedProjectsState, setCheckedProjectsState] = useState(new Map<number, boolean>());
   const [checkedBState, setCheckedBState] = useState<boolean>(false);
   const [indeterminateBState, setIndeterminateBState] = useState<boolean>(false);
   const [sortType, setSortType] = useState(PROJECT_SORT_TYPE.NEWEST as string);
   const userData = useContext(AuthContext).userData;

   let projects = sortProjects(props.projectList);

   useEffect(() => {
      let mappedProjects = new Map<number, boolean>();
      projects.forEach(project => mappedProjects.set(project.id, false));
      setCheckedProjectsState(mappedProjects);
   }, [props.projectList]);

   useEffect(() => {
      setCheckedBState(Array.from(checkedProjectsState.values()).reduce((acc, curr) => {
         return (acc && curr);
      }, true));
      setIndeterminateBState(
         Array.from(checkedProjectsState.values()).filter(p => p).length > 0 &&
         Array.from(checkedProjectsState.values()).filter(p => !p).length > 0
      );
      if (checkedBState && props.projectList.length === 0) {
         setCheckedBState(false);
      }
   }, [checkedProjectsState]);

   return (
      <>
         <div className={styles['projects']}>
            {
               props.projectList.length > 0 &&
               <div className={'heading-wrapper'}>
                  {
                     props.isManager &&
                     <>
                        <Checkbox
                           disableRipple={true}
                           sx={{
                              transition: 'all 0.5s',
                              backgroundColor: 'transparent',
                              color: '#50545FFF',
                              '&:hover, &:active, &:active:hover, &:hover:active': {
                                 backgroundColor: 'transparent',
                                 color: '#626872FF',
                              },
                              '&.Mui-checked': {
                                 color: '#A0A8E5FF',
                                 backgroundColor: 'transparent',
                                 '&:hover, &:active, &:active:hover, &:hover:active': {
                                    backgroundColor: 'transparent',
                                    color: '#C6CAEFFF',
                                 },
                              },
                              '&.MuiCheckbox-indeterminate': {
                                 color: '#A0A8E5FF',
                                 backgroundColor: 'transparent',
                                 '&:hover, &:active, &:active:hover, &:hover:active': {
                                    backgroundColor: 'transparent',
                                    color: '#C6CAEFFF',
                                 },
                              },
                              '&.Mui-disabled': {
                                 color: '#50545FFF',
                                 opacity: '0.6'
                              },
                           }}
                           checked={checkedBState}
                           indeterminate={indeterminateBState}
                           onChange={checkButtonHandler}
                           disabled={props.projectList.length == 0}
                        />
                        <button onClick={() => archiveOrDelete()} className={`button button-tertiary archive-delete-button 
						        ${Array.from(checkedProjectsState.values()).filter(item => item).length === 0 ? 'no-selected' : ''}`}>
                           {props.projectStatus === PROJECT_STATUS.ONGOING ? 'Archive' : 'Delete'}
                           <span>{Array.from(checkedProjectsState.values()).filter(item => item).length}</span>
                        </button>
                     </>
                  }
                  <FilterComponent sort={setSort} id={"ProjectsSortButton"}
                                   filterOptions={props.filterOptions}/>
               </div>
            }
            {
               projects.map((item: any) => {
                  return <ProjectCardComponent key={item.id} projectId={item.id} check={checkProject}
                                               isManager={props.isManager}
                                               checked={checkedProjectsState.get(item.id) ? true : false}
                                               projectName={item.name} projectStatus={item.status}
                                               moveToArchiveOrOngoing={props.projectStatus === PROJECT_STATUS.ONGOING ? archiveOrDelete : undefined}
                                               deleteProject={props.projectStatus !== PROJECT_STATUS.ONGOING ? archiveOrDelete : undefined}
                                               feComponent={props.projectStatus === PROJECT_STATUS.DRAFT ? item.feComponent : null}
                                               isTeamLead={item.isTeamLead}
                                               projectEmployeesNumber={item.projectEmployeesNumber ? item.projectEmployeesNumber : null}
                                               jobPosting={props.jobPosting}
                  />
               })
            }
            {
               props.projectList.length === 0 &&
               <div className={'no-projects'}>
                  <h2 className={'header-1'}>There are no {props.jobPosting ? 'tasks' : 'projects'}</h2>
                  <p>Your {props.jobPosting ? 'tasks' : 'projects'} will be saved here...</p>
               </div>
            }
         </div>
      </>
   );

   function archiveOrDelete(id?: number) {
      if (props.projectStatus === PROJECT_STATUS.ONGOING) {
         moveToArchive(id);
      }
      else {
         deleteProjects(id);
      }
   }

   function moveToArchive(id?: number) {
      let projectIds: number[];
      if (id) {
         projectIds = [id];
      } else {
         projectIds = Array.from(checkedProjectsState.keys()).filter(pId => checkedProjectsState.get(pId));
      }
      props.changeProjectStatusesOrDelete('MOVE_ARCHIVE', projectIds);
      props.changeProjectStatus(projectIds, true, userData.accessToken);
   }

   function deleteProjects(id?: number) {
      let projectIds: number[];
      if (id) {
         projectIds = [id];
      } else {
         projectIds = Array.from(checkedProjectsState.keys()).filter(pId => checkedProjectsState.get(pId));
      }
      props.changeProjectStatusesOrDelete('DELETE', projectIds);
      props.deleteProjects(projectIds, userData.accessToken);
   }

   function checkProject(projectId: number) {
      setCheckedProjectsState(new Map(checkedProjectsState.set(projectId, !checkedProjectsState.get(projectId))));
   }

   function checkButtonHandler() {
      const flag = !checkedBState || indeterminateBState;

      let mappedProjects = new Map(
         props.projectList.filter(project => !project.isTeamLead).map(project => {
            return [project.id, flag]
         })
      );

      setCheckedProjectsState(mappedProjects);
   }

   function sortProjects(projects: ProjectData[]): ProjectData[] {
      return [...projects].sort((a, b) => {
         switch (sortType) {
            case PROJECT_SORT_TYPE.NEWEST:
               // Assuming compareDates does a standard date comparison,
               // To sort from newest to oldest, we swap b and a
               return compareDates(b.startDate, a.startDate);
            case PROJECT_SORT_TYPE.OLDEST:
               // For oldest to newest, we keep a and b in their original order
               return compareDates(a.startDate, b.startDate);
            case PROJECT_SORT_TYPE.TITLE:
               // Simplify string comparison using localeCompare
               return a.name.toUpperCase().localeCompare(b.name.toUpperCase());
            default:
               return 0; // No sorting applied if sortType doesn't match
         }
      });
   }


   function setSort(sortType: string) {
      setSortType(sortType);
   }
}

export default ProjectsComponent;