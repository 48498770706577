import {capitalizeOnlyFirstLetter} from "../utils/capitalize-only-first-letter";

export type UploadDataTypesDto = {
   subscriptions?: SubscriptionsDto[];
   frequencyTypes: FrequencyTypesDto[];
}

export type FrequencyTypesDto = {
   frequencyType: FrequencyTypeEnum;
   fileUploadsTypesAndData: FileTypesAndDataDto[];
}

export enum FrequencyTypeEnum {
   WEEKLY = 'WEEKLY',
   BI_WEEKLY = 'BI_WEEKLY',
   MONTHLY = 'MONTHLY',
   ANNUALLY = 'ANNUALLY',
   ONCE_ONLY = 'ONCE_ONLY',
   NO_TYPE = "NO_TYPE",
   "SEMI_ANNUALLY" = "SEMI_ANNUALLY"
}

export const FrequencyTypesDto: { [key in FrequencyTypeEnum]: string } = {
   [FrequencyTypeEnum.WEEKLY]: 'Weekly',
   [FrequencyTypeEnum.BI_WEEKLY]: 'Bi-weekly',
   [FrequencyTypeEnum.MONTHLY]: 'Monthly',
   [FrequencyTypeEnum.ANNUALLY]: 'Annually',
   [FrequencyTypeEnum.ONCE_ONLY]: 'Once only',
   [FrequencyTypeEnum.NO_TYPE]: "No type",
   [FrequencyTypeEnum.SEMI_ANNUALLY]: "Semi-annually"
}

export type SubscriptionsDto = {
   name: string,
   active: boolean
}

export type FileTypesAndDataDto = {
   fileType: FileType,
   uploadedFileData?: UploadedFileData
}

export type FileType = {
   id: number,
   field: string,
   value: FileTypeValue,
   parent: FileTypeParentCategory
}

export type FileTypeParentCategory = {
   id: string,
   value: FileTypeParentCategoryValue,
   field: string
}

export type UploadedFileData = {
   fileName: string;
   fileSubCategory: string;
   dateAdded: Date; // LAST TIME UPLOADED
   isProcessing: boolean;
   dueDate?: Date;
   isInDueDate: boolean;
}

export enum FileTypeValue {
   CV = 'CV',
   LINKEDIN = 'LINKEDIN',
   GITHUB = 'GITHUB',
   CERTIFICATIONS = 'CERTIFICATIONS',
   PLANVIEW = 'PLANVIEW',
   LOTUS = 'LOTUS',
   TRELLO = 'TRELLO',
   ASANA = 'ASANA',
   MEETING_NOTES = 'MEETING_NOTES',
   WORKDAY = 'WORKDAY',
   PERSONAL_NOTES = "PERSONAL_NOTES",
   TEAM_SKILLS = "TEAM_SKILLS",
   PROJECT_RETRO = "PROJECT_RETRO",
   PSYCHOMETRIC_ASSESSMENT = "PSYCHOMETRIC_ASSESSMENT"
}

export enum FileTypeParentCategoryValue {
   STATIC = 'STATIC',
   ACTION_ITEMS = 'ACTION_ITEMS'
}

export enum UploadDataType {
   PERSONAL = 'personal',
   COMPANY = 'company'
}

export const UPLOAD_DATA_TEXTS = {
   [UploadDataType.PERSONAL]: {
      title: "Personal Data Uploading",
      paragraph1: "Upload your data now to allow TeamLift's AI to identify your skills based on your contributions to the team.",
      paragraph2: "By adding your data, you can include your skills in your AI-extracted skills wallet. Don't wait, start feeding our AI today.",
      paragraph3: "Upload a file from:"
   },
   [UploadDataType.COMPANY]: {
      title: "Upload your company’s data to feed TeamLift’s AI",
      paragraph1: "Upload data from one of the management tools below.",
      paragraph2: "Our AI will identify your teams’ skills based on their contributions.",
      paragraph3: "Upload a file and connect your project:"
   }
}

export function getFileNames(fileType: FileTypeValue) {
   switch (fileType) {
      case FileTypeValue.CV:
         return "CV";
      case FileTypeValue.GITHUB:
         return "GitHub";
      case FileTypeValue.LINKEDIN:
         return "LinkedIn";
      case FileTypeValue.PSYCHOMETRIC_ASSESSMENT:
         return "Psychometric Assessment Test";
      default:
         return capitalizeOnlyFirstLetter(fileType.toString().replace("_", " "));
   }
}

export const CATEGORY_IMAGES: { [key in FileTypeValue]: string } = {
   [FileTypeValue.CV]: '/assets/images/upload-data-icons/cv-icon.svg',
   [FileTypeValue.LINKEDIN]: '/assets/images/upload-data-icons/linkedin-icon.svg',
   [FileTypeValue.GITHUB]: '/assets/images/upload-data-icons/github-icon.svg',
   [FileTypeValue.PLANVIEW]: '/assets/images/upload-data-icons/planview-icon.svg',
   [FileTypeValue.LOTUS]: '/assets/images/upload-data-icons/cv-icon.svg',
   [FileTypeValue.TRELLO]: '/assets/images/upload-data-icons/trello-icon.svg',
   [FileTypeValue.ASANA]: '/assets/images/upload-data-icons/asana-icon.svg',
   [FileTypeValue.MEETING_NOTES]: '/assets/images/upload-data-icons/meeting-notes-icon.svg',
   [FileTypeValue.CERTIFICATIONS]: "/assets/images/upload-data-icons/certifications-icon.svg",
   [FileTypeValue.PERSONAL_NOTES]: "/assets/images/upload-data-icons/project-retro-icon.svg",
   [FileTypeValue.WORKDAY]: "/unknown",
   [FileTypeValue.TEAM_SKILLS]: "/assets/images/upload-data-icons/map-team-skills-icon.svg",
   [FileTypeValue.PROJECT_RETRO]: "/assets/images/upload-data-icons/project-retro-icon.svg",
   [FileTypeValue.PSYCHOMETRIC_ASSESSMENT]: "/unknown"
};

export enum PROCESSING_STATUS {
   NO_PROCESSING,
   PROCESSING,
   FINISHED
}

export type ProcessedFileNotificationDto = {
   message: string;
   fileCategory: FileTypeValue;
   newSkills: number;
}