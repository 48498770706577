import { NavLink } from 'react-router-dom';
import styles from './PersonalHighlights.module.scss';
import {capitalizeFirstLetter} from "../../../../../utils/capitalize-first-letter";
import {SimilarJobPositionSkillDto} from "../../../../../types/EmployeeDashboard";
import React from "react";
import Tooltip from "../../../../../services/tooltip.service";

const PersonalHighlights: React.FC<{mostUsedSkills?: SimilarJobPositionSkillDto[], uniqueSkills?: SimilarJobPositionSkillDto[], readOnlyMode?: boolean}> = (props) => {
	
	return (
		<div className={styles['personal-highlights']}>
			<div className='card-main'>
				<div className='card-main-header-wrapper u-margin-bottom-s'>
					<div className='card-main-header-title'>
						<h3 className={'header-3'}>Personal highlights</h3>
					</div>
					{
						!props.readOnlyMode &&
						<div className='skills-wallet-link'>
							<NavLink className={'button button-tertiary'} to='/employee/skills-wallet'><i className="fa-solid fa-arrow-up-right-from-square"></i> Open skills wallet</NavLink>
						</div>
					}				
				</div>

				{
					props.mostUsedSkills && props.mostUsedSkills.length > 0 &&
					<div className='card-child'>
						<div className='card-child-header-wrapper'>
							<img src='/assets/images/global-icons/computer-icon.svg' className='computer-icon'
								  alt='Computer Icon'/>
							<h4 className={'header-4'}>Most used skills</h4>
						</div>
						<div className='skills-section'>
							{props.mostUsedSkills &&
								props.mostUsedSkills.map(skill => {
									return (
										<Tooltip key={skill.skill.id} tooltipId={`${skill.skill.id}_personal_highlights_tooltip`}
													className={'custom-tooltip'} place={"top"}
													content={<div>{skill.skill.description}</div>}>
											<div className="skill-tag">{capitalizeFirstLetter(skill.skill.name)}</div>
										</Tooltip>
									)
								})
							}
						</div>
					</div>
				}

				{
					props.uniqueSkills && props.uniqueSkills.length > 0 &&
					<div className='card-child'>
						<div className='card-child-header-wrapper'>
							<img src='/assets/images/global-icons/star-icon.svg' className='star-icon' alt='Star Icon'/>
							<h4 className={"header-4"}>Unique expertise</h4>
						</div>
						<div className='skills-section'>
							{props.uniqueSkills &&
								props.uniqueSkills.map(skill => {
									return (
										<Tooltip key={skill.skill.id} tooltipId={`${skill.skill.id}_unique_expertise_tooltip`}
													className={'custom-tooltip'} place={"top"}
													content={<div>{skill.skill.description}</div>}>
											<div className="skill-tag">{capitalizeFirstLetter(skill.skill.name)}</div>
										</Tooltip>
									)
								})
							}
						</div>
					</div>
				}
			</div>
		</div>
	)
}

export default PersonalHighlights;