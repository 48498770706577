import  { useState } from 'react';

const useModal = () => {
	const [showModal, setShowModal] = useState(false);
	const [dataIsLoading, setDataIsLoading] = useState(false);
	const [modalData, setModalData] = useState<{} | any>({});

	return {
		showModal,
		createModal,
		removeModal,
		modalData,
		editModalData,
		dataIsLoading,
		toggleDataIsLoading
	}

	function createModal(data: {} = {}) {
		setModalData(data);
		setShowModal(true);
	}
	
	function removeModal() {
		setModalData({});
		setDataIsLoading(false);
		setShowModal(false);
	}
	
	function editModalData(key: string, value: any) {
		setModalData((prevState: any) => {
			return {...prevState, key: value};
		});
	}

	function toggleDataIsLoading() {
		setDataIsLoading(!dataIsLoading);
	}
}

export default useModal;