import styles from './DeleteEmployeeModal.module.scss';
import {CompanyEmployeePayloadInformation} from "../../../../../../types/AdminData";
import React from "react";

const DeleteEmployeeModal: React.FC<{ data: { formData: CompanyEmployeePayloadInformation }, okCallback: any, cancelCallback: React.MouseEventHandler<HTMLElement> }> = (props) => {
   return (
      <div className={styles['new-edit-employee-modal']}>
         <div className='card card-modal'>
            <div className='card-header'>
               <h2 className={'header-2'}>Delete {props.data.formData.name} from list of employees</h2>
            </div>
            <div className="card-body">
               <div className={'mb-4 body-content'}>
                  <div className={'u-margin-bottom-xxs'}>This action will revoke <b>{props.data.formData.name}</b>'s access to their
                     profile.
                  </div>
                  <div>Are you sure you want to proceed?</div>
               </div>
               <div className='buttons-wrapper'>
                  <button className='button button-secondary u-margin-right-xs' onClick={props.cancelCallback}>Cancel</button>
                  <button className='button button-primary' onClick={submitForm}>Delete</button>
               </div>
            </div>
         </div>
      </div>
   )

   function submitForm() {
      let payload = props.data.formData.id!;
      props.okCallback(payload);
   }
}

export default DeleteEmployeeModal;