import { useRef } from 'react';
import styles from './FilterComponent.module.scss';

const FilterComponent: React.FC<{sort: Function, id: string, filterOptions : {title : string, value: string}[]}> = (props) => {
    const filterInput = useRef<HTMLButtonElement>(null!);

    return (
        <div className={styles['sort-wrapper'] + ' dropdown'}>
            <button	id={props.id} ref={filterInput} className="btn btn-outline-secondary dropdown-toggle mt-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                Sort: {props.filterOptions[0].title}
            </button>
            <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby={props.id}>
                {
                    props.filterOptions.map((option) => {
                        return <li key={option.value}>
                            <button className="dropdown-item" type="button" onClick={() => {handleSort(option.value, option.title) }}>{option.title}</button>
                        </li>
                    })
                }
            </ul>
        </div>
    )

    function handleSort(type: string, title: string) {
        filterInput.current.innerText = `Sort: ${title}`;
        props.sort(type);
    }
}

export default FilterComponent;