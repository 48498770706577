import React, { useState, useEffect, useContext } from 'react';
import styles from './CreateProjectComponent.module.scss';

import { UserData } from '../../../../../types/AuthData';

import { AuthContext } from '../../../../../store/auth-context';
import OnboardingManagerProcessComponent from './OnboardingManagerProcessComponent/OnboardingManagerProcessComponent';
import useApiService from '../../../../../services/api.service';
import { showNotification } from '../../../../../ui/Toast/ToastNotification';
import useLoadingSpinner from '../../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner';
import OnboardingManagerContext from "../../../../../store/create-project-context";
import {ErrorResponseDto} from "../../../../../types/ErrorData";

const CreateProjectComponent: React.FC<{ jobPosting?: boolean }> = props => {
  const authStore = useContext(AuthContext);
  const onboardingManagerStore = useContext(OnboardingManagerContext);
  const userData: UserData = authStore.userData;
  const [initializedComponent, setInitializedComponent] = useState(false);
  const [clickedSaveAsDraft, setClickedSaveAsDraft] = useState<boolean>(false);
  const [isDraft, setIsDraft] = useState<boolean>(false);

  const apiService = useApiService();
  const spinnerService = useLoadingSpinner();

  useEffect(() => {
    initializeComponent(userData.accessToken);
  }, [])

  return (
    <div className={styles['onboarding-manager-page']}>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12'>
            { initializedComponent &&
              <div className='onboarding-manager-modal'>
                <div className='card-modal'>              
                    <div className='row'>
                      <div className='col-12'>
                        <div className={`modal-header`}>    
                          <div className='logo-container'>
                            <img src='/assets/images/global-icons/teamlift-logo-white.svg' alt="TeamLift logo" />
                          </div>
                          {
                            !isDraft &&
                              <>
                                <div className='logout-button' onClick={hideCreateProject}>Save as draft</div>
                              </>
                          }
                          {
                            isDraft &&
                              <>
                                <div className='logout-button' onClick={hideCreateProject}>Save</div>
                              </>
                          }
                        </div>   
                      </div>
                    </div>    
                  <div className='modal-body'>                 
                    <OnboardingManagerProcessComponent jobPosting={props.jobPosting} clickedSaveAsDraft={clickedSaveAsDraft} projectIsDraft={projectIsDraft} />
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
      {
        spinnerService.spinner
      }
    </div>
  )

  function initializeComponent(accessToken: string) {
    setInitializedComponent(true);

    spinnerService.createSpinner();
    onboardingManagerStore.getSkillCategories(accessToken)
      .then(()=> {
        setInitializedComponent(true);
        spinnerService.removeSpinner();
      })
      .catch((error$: ErrorResponseDto)=> {
        if (error$.response.data.message === 'Unauthorized') {
          // Get new Access Token
          apiService.refreshToken(authStore.userData.refreshToken)
            .then((response$: any) => {
              authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
              initializeComponent(response$.data.accessToken);
            })
        }
        else {
          setInitializedComponent(true);
          spinnerService.removeSpinner();
          showNotification('warning', error$.response.data.message);
        }
      })   
  }

  function projectIsDraft() {
    setIsDraft(true);
  }
  function hideCreateProject() {
    setClickedSaveAsDraft(true);
  }
}

export default CreateProjectComponent;