import { useEffect, useRef } from "react";
import styles from "./SkillsGrowthChartComponent.module.scss";
import Chart from 'chart.js/auto';
import { Colors } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { capitalizeFirstLetter } from "../../../../../utils/capitalize-first-letter";
import 'chartjs-adapter-moment';
import DatePicker from "react-datepicker";

const SkillsGrowthChartComponent: React.FC<{ rawData: any }> = (props) => {  
  const chartRef = useRef<any>(null);

  Chart.defaults.borderColor = '#2F303C';
  Chart.defaults.color = '#828892';
  Chart.register(Colors);
  Chart.register(zoomPlugin);

  // let colorPalette: {color: string, hoverColor: string}[] = [
  //   { color: 'rgba(80, 80, 160, 1)', hoverColor: 'rgba(80, 80, 160, .7)' },
  //   { color: 'rgb(40, 40, 120, 1)', hoverColor: 'rgb(40, 40, 120, .7)' },
  //   { color: 'rgba(80, 80, 120, 1)', hoverColor: 'rgba(80, 80, 120, .7)' },
  //   { color: 'rgba(240, 40, 240, 1)', hoverColor: 'rgba(240, 40, 240, .7)' },
  //   { color: 'rgba(120, 120, 160, 1)', hoverColor: 'rgba(120, 120, 160, .7)' },
  //   { color: 'rgba(40, 80, 120, 1)', hoverColor: 'rgba(40, 80, 120, .7)' },
  //   { color: 'rgba(120, 240, 240, 1)', hoverColor: 'rgba(120, 240, 240, .7)' },
  //   { color: 'rgba(160, 80, 240, 1)', hoverColor: 'rgba(160, 80, 240, .7)' }      
  // ];



  let colorPalette: string[] = ["#5050A0", "#282878", "#505078", "#F028F0", "#285078", "#78F0F0", "#A050F0"];
  
  useEffect(() => {    
    let data: any = prepareChartData();
    
    var ctx: any = document.getElementById('skills-growth-chart-container') as any;
    
    var myChart = new Chart(ctx, {
      type: "line",
      data: data,
          
      options: {
        scales: {
          x: {
            type: 'time',
            time: {
              unit:  props.rawData.length > 0 ? 'day' : 'month',
              displayFormats: {
                day: 'MMM D',
                week: 'MMM D',
                month: 'MMM'
              },
            },
            title: {
              text: "Date",
              display: true,
              color: '#B3BAC3',
              font: {
               size: 15,
               weight: 'bold'  
              }
            },
            grid: {
              color: '#2F303C',
              display: false             
            }
          },
          y: {            
            min: 0,
            max: 100,
            title: {
              text: "Validation score",
              display: true,
              color: '#B3BAC3',
              font: {
                size: 15, 
                weight: 'bold'
              }
            },
            
            grid: {
              color: props.rawData.length > 0 ? '#2F303C' : '#1C1B28'  
            }         
          }
        },
        aspectRatio: 6 / 2,
        maintainAspectRatio: true,
        responsive: true,          
        interaction: {
          mode: 'nearest',
          intersect: false,
        },
        plugins: {
          legend: {
            position: 'top',
            align: 'start',
            fullSize: false,
            maxWidth: 9999,                                                
            labels: {
              padding: 20,  
              textAlign: 'left',
              boxWidth: 28,
              useBorderRadius: true,
              borderRadius: 4                         
            }        
          },
          tooltip: {
            enabled: false,   
            external: externalTooltipHandler
          },
          zoom: { 
            zoom: {
              wheel: {
                enabled: props.rawData.length > 0 ? true : false,
                speed: 0.1                   
              },
              pinch: {
                enabled: true,         
              },
              mode: "xy"
            },
            pan: {
              enabled: props.rawData.length > 0 ? true : false,
              mode: 'xy'
            },
            limits: {
              y: {min: 0, max: 100}
            }
          }
        },
        elements:{
          point: {
            radius: 8,
            hoverRadius: 12
          }
        }       
      }
    })

    chartRef.current = myChart;

    return () => {
			myChart.destroy();
		}
  }, [props.rawData]);



  return (
    <>
      <div className={styles["skills-growth-chart-component"]}>	
        {
          props.rawData.length === 0 ? 
          // linesBg
          <div className="chart-placeholder"> 
            <img src='/assets/images/global-icons/chart-icon.svg'  alt={'chart-icon'}/>
            <h2 className='header-1'>Track the evolution <br />of skills validation score over time</h2>
            <p className="text-text-secondary">Input skills and then create chart</p>
          </div> :
          <div className="tooltip-wraper">
            {/*<div className="datepicker-wrapper">*/}
            {/*  <DatePicker*/}
            {/*    // selected={startDate}       */}
            {/*    onChange={(dates) => {      */}
            {/*      // const [start, end] = dates;*/}
            {/*      // setStartDate(start);*/}
            {/*      // setEndDate(end);*/}
            {/*    }}*/}
            {/*    isClearable*/}
            {/*    placeholderText="Select date..."*/}
            {/*    dateFormat="yyyy/MM/dd"*/}
            {/*    className="datepicker-custom"*/}
            {/*    calendarClassName="datepicker-calendar"*/}
            {/*    // maxDate={addDays(new Date(), 0)}*/}
            {/*    calendarStartDay={1}*/}
            {/*    // startDate={startDate}*/}
            {/*    // endDate={endDate}*/}
            {/*    selectsRange*/}
            {/*  />*/}
            {/*</div>*/}
            <div className="button-wrapper">
              <span className="btn btn-surface-80" onClick={resetChart}><img src='/assets/images/global-icons/resize-symbol.svg' alt={'resize symbol'}/></span>
            </div>
          </div>  
        }
        <canvas id="skills-growth-chart-container"></canvas>
      </div>      
    </>
  );

  function prepareChartData(): void {
    let datasets: any = [];
    let minimumMiliseconds: number = 0;
    let maximumMiliseconds: number = 0;
    
    for (let i = 0; i < props.rawData.length; i++) {
      let tempData: any = [];
      for (let item of props.rawData[i].validationFormulaHistory) {
        // Format dates  
        let dateObject = new Date(item.dateChanged);
     
        let year: string = dateObject.getFullYear().toString();
        let month: string = (dateObject.getMonth() + 1).toString();
        let day: string = dateObject.getDate().toString();
        let hour = dateObject.getHours();
        let minutes = dateObject.getMinutes();
        let seconds = dateObject.getSeconds();
        
        if (month.length === 1) {
          month = "0" + month;
        }

        if (day.length === 1) {
          day = "0" + day;
        }

        let dateString: string = year + "-" + month + "-" + day + " " + hour + ":" + minutes + ":" + seconds;

        // Set the interval
        // let miliseconds = dateObject.getTime();
        // if (miliseconds < minimumMiliseconds) {
        //   maximumMiliseconds = miliseconds;
        // }
        // if (miliseconds > maximumMiliseconds) {
        //   maximumMiliseconds = miliseconds;
        // }

        // Format sources
        let sources: string = item.sources.join(', ');

        tempData.push({x: dateString, y: item.totalValidationScore, sources: sources})
      }

      let object: any = {
        label: capitalizeFirstLetter(props.rawData[i].skillName),
        data: tempData,
        tension: 0.2
      }

      if (i <= colorPalette.length) {
        object['backgroundColor'] = colorPalette[i];
        object['borderColor'] = colorPalette[i];
      }      
      datasets.push(object);
    }

    let data: any = {};
    data.datasets = datasets;
    
    // Set time unit which depends from
    return data;
  }

  function resetChart() {
    chartRef.current.resetZoom();
  } 
  
  function externalTooltipHandler (context: any) {
    // Tooltip Element
    let tooltipEl = document.getElementById('chartjs-tooltip');    
    // Create element on first render
    if (tooltipEl) {
      tooltipEl.remove();
    }    
    tooltipEl = document.createElement('div');
    tooltipEl.id = 'chartjs-tooltip';
    tooltipEl.innerHTML = '<div></div>';
    document.body.appendChild(tooltipEl);
    
    // Hide if no tooltip
    const tooltipModel = context.tooltip;
    if (tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = '0';
      return;
    }
    // Set caret Position
    tooltipEl.classList.remove('above', 'below', 'no-transform');
    if (tooltipModel.yAlign) {
      tooltipEl.classList.add(tooltipModel.yAlign);
    } 
    else {
      tooltipEl.classList.add('no-transform');
    }
   
    let tooltipRoot = document.getElementById('chartjs-tooltip') as HTMLDivElement;
    tooltipRoot.style.fontSize = "13px";
    tooltipRoot.style.maxWidth = "300px";

    let htmlString = "";
    htmlString += `<div style="color: #B3BAC3">${tooltipModel.title}</div>`;
    
    for (let item of tooltipModel.$context.tooltipItems) {
      htmlString += `<div style="font-weight: 700; margin-top: 6px">
        <span style="width: 10px; height: 10px; border-radius: 100%; background-color: ${item.dataset.backgroundColor}; display: inline-block; margin-right: 4px"></span> 
        ${item.dataset.label}</div>`;
      htmlString += `<div style="margin-left: 18px"><span style="color: #B3BAC3">Validation score: </span>${item.raw.y.toFixed(2)}</div>`;
      htmlString += `<div style="margin-left: 18px"><span style="color: #B3BAC3">Sources: </span>${item.raw.sources}</div>`;
      tooltipRoot.innerHTML = htmlString;
    }

    const position = context.chart.canvas.getBoundingClientRect();
    if (position.left + tooltipModel.caretX + tooltipEl.offsetWidth < position.right) {
      tooltipEl.style.left = position.left + window.scrollX + tooltipModel.caretX + "px";
    } 
    else {
      tooltipEl.style.left = position.left + window.scrollX + tooltipModel.caretX - (tooltipEl.offsetWidth / 2) + "px"
    }

    // Display, position, and set styles for font
    tooltipEl.style.opacity = "1";
    tooltipEl.style.position = 'fixed';
    // tooltipEl.style.left = position.left + window.scrollX + tooltipModel.caretX - 160 + 'px';
    tooltipEl.style.top = position.top + window.scrollY + tooltipModel.caretY + 'px';  
    tooltipEl.style.padding = '16px';
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.transform = 'translate(24px, -50%)';
    tooltipEl.style.transition = 'all .2s ease';
    tooltipEl.style.margin = "0";
    tooltipEl.style.borderRadius = "4px";
    tooltipEl.style.border = "1px solid #383A46";
    tooltipEl.style.backgroundColor = "rgba(47, 48, 60, .7)";   
  };
};

export default SkillsGrowthChartComponent;
