import React, { useState, useRef, useContext, useEffect } from 'react';

import styles from './EmployeeDashboard.module.scss';

import axios, {AxiosRequestConfig} from "axios";

import useApiService from '../../../../services/api.service';
import useLoadingSpinner from '../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner';

import { UserData } from '../../../../types/AuthData';
import { EmployeeDashboardData } from '../../../../types/EmployeeDashboard';

import EmployeeInfo from './EmployeeInfo/EmployeeInfo';
import PersonalHighlights from './PersonalHighlights/PersonalHighlights';
import EmployeeChoosePosition from './EmployeeChoosePosition/EmployeeChoosePosition';
import UpdatesComponent from './UpdatesComponent/UpdatesComponent';
import PersonalGrowthComponent from './PersonalGrowthComponent/PersonalGrowthComponent';

import AuthContext from "../../../../store/auth-context";
import { showNotification } from '../../../../ui/Toast/ToastNotification';
import {ErrorResponseDto} from "../../../../types/ErrorData";

const EmployeeDashboard: React.FC = () => {
	const initializedComponent = useRef(false);
  const authStore = useContext(AuthContext);
	const userData: UserData = authStore.userData;
	const upperMenu = userData.menu.find(item => item.title === 'Upper Left')!;
	const employeeMenu = upperMenu!.menus!.find(item => item.title === 'Employee')!;
	const componentData = employeeMenu!.menus!.find(item => item.url === '/employee/employee-dashboard');
	
	const [employeeDashboardData, setEmployeeDashboardData] = useState<EmployeeDashboardData>({} as EmployeeDashboardData); 
	const profileImageUrl = authStore.userData?.profilePicture;

	const loadDashboardDataURL = process.env.REACT_APP_PUBLIC_URL + componentData!.url!;

	const spinnerService = useLoadingSpinner();
	const { refreshToken } = useApiService();

  useEffect(() => {
	  initializeComponent(authStore.userData.accessToken);
  }, []);

	return (
		<>
			{
				initializedComponent.current &&
				<div>
					{
						(employeeDashboardData && employeeDashboardData.dashboardAvailable) ?
							<div className={styles['employee-dashboard']}>
							<div className='container-fluid'>
								<div className='row'>
									{/* Left section */}
									<div className='col-12 col-xl-6 col-xxl-5'>										
										<EmployeeInfo name={employeeDashboardData.employeeDashboard!.employee.name} surname={employeeDashboardData.employeeDashboard!.employee.surname}
											profileImageUrl={profileImageUrl} jobPositionName={employeeDashboardData.employeeDashboard!.jobPosition!.name} color={employeeDashboardData.employeeDashboard!.employee.color!} />
										<PersonalHighlights mostUsedSkills={employeeDashboardData.employeeDashboard!.personalMostUsedSkills}
											uniqueSkills={employeeDashboardData.employeeDashboard!.personalUniqueSkills}/>
										<UpdatesComponent />
									</div>
		
									{/* Right section */}
									<div className='col-12 col-xl-6 col-xxl-5'>																				
										<PersonalGrowthComponent data={employeeDashboardData.employeeDashboard!} />
									</div>
								</div>
							</div>					
						</div> : 
						<EmployeeChoosePosition name={userData.fullName} image={userData.profilePicture} jobOptions={employeeDashboardData.similarJobPositions} 
							okCallback={(id: string) => submitJobOptionWrapper(id, authStore.userData.accessToken)} />
					}
				</div>	
			}
			{
				spinnerService.spinner
			}
		</>
	)

	function initializeComponent(accessToken: string) {
		authStore.changePageTitle(userData.displayName + "'s Personal Dashboard");

		const headers: AxiosRequestConfig['headers'] = {
			'Authorization': `Bearer ${accessToken}`
		}

		spinnerService.createSpinner();
		axios
			.get(loadDashboardDataURL, {headers})
			.then((response$) => {
				setEmployeeDashboardData({...response$.data});
				initializedComponent.current = true;
				spinnerService.removeSpinner();
			})
			.catch((error$: ErrorResponseDto) => {
				spinnerService.removeSpinner();
				if (error$.response.data.message === 'Unauthorized') {
					initializedComponent.current = false;
					// Get new Access Token
					refreshToken(authStore.userData.refreshToken)
						.then((response$: any) => {
							authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
							initializeComponent(response$.data.accessToken);
						})
				}
				else {
					// Notification Something went wrong
					initializedComponent.current = true;
					showNotification('warning', error$.response.data.message);
				}
			})
	}

	function submitJobOptionWrapper(jobPositionId: string, accessToken: string) {
		spinnerService.createSpinner();

		submitJobOption(jobPositionId, accessToken)
				.then((response$: any) => {
					setEmployeeDashboardData({...response$.data});
					initializedComponent.current = true;
					spinnerService.removeSpinner();
				})
				.catch((error$: ErrorResponseDto) => {
					spinnerService.removeSpinner();
					if (error$.response.data.message === 'Unauthorized') {
						initializedComponent.current = false;
						// Get new Access Token
						refreshToken(authStore.userData.refreshToken)
							.then((response$: any) => {						
								authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
								submitJobOptionWrapper(jobPositionId, response$.data.accessToken);
							})
					}
					else {
						showNotification("warning", error$.response.data.message);
						initializedComponent.current = true;
					}
      })
	}

	async function submitJobOption(jobPositionId: string, accessToken: string) {
		const submitJobOptionURL = process.env.REACT_APP_PUBLIC_URL + '/employee/set-job-position';

		const headers: AxiosRequestConfig['headers'] = {
			'Authorization': `Bearer ${accessToken}`
		}

		let data = {
			jobPositionId
		}

		let promise = new Promise((resolve: any, reject: any) => {
			axios
				.post(submitJobOptionURL, data, { headers })
				.then((response$: any) => {
					resolve(response$);
				})
				.catch((error$) => {
					reject(error$);
				});
		});
		return await promise;
	}
}

export default EmployeeDashboard;