import styles from "../LoginFormComponent/LoginFormComponent.module.scss";
import {NavLink} from "react-router-dom";
import SpinnerButton from "../../../ui/SpinnerButton/SpinnerButton";
import React, {useEffect, useState} from "react";
import { useSearchParams } from 'react-router-dom';
import {RegisterData, SendRegisterData, UserRegisterFormParams} from "../../../types/LoginRegisterData";

const RegisterFormComponent: React.FC<UserRegisterFormParams> = props => {

   const [formData, setFormData] = useState<RegisterData>({
      fullName: '',
      username: '',
      company: '',
      password: '',
      registerCode: '',
   });

   const [formErrorData, setFormErrorData] = useState({
      fullName: false,
      company: false,
      password: false,
      username: false,
      registerCode: false,
   });

   const [typePassword, setTypePassword] = useState(true);
   const [searchParams] = useSearchParams();
   const [error, setError] = useState('');

   useEffect(() => {
      const registerCode = searchParams.get("code");
      const email = searchParams.get("email")?.replace(" ", "+");

      registerCode && email && setFormData({
         ...formData,
         registerCode,
         username: email
      });
   }, []);

   useEffect(() => {
      setError(props.error);
   }, [props.error]);

   return (
      <>
         <div className={styles['login-form']}>
            <form onSubmit={checkForm}>

               <label htmlFor="fullName" className="form-label">Full Name</label>
               <div className={`mb-4 input-wrapper ${formErrorData.fullName ? "error" : ""}`}>
                  <span className='icon-container'>
                     <i className="fa-solid fa-user"></i>
                  </span>
                  <input value={formData.fullName} onChange={handleChange} type="text" className={`form-control`}
                         id="fullName" name="fullName" autoComplete='off' placeholder='Enter your full name (e.g., John Doe)'/>
               </div>

               <label htmlFor="username" className="form-label">Email</label>
               <div className={`mb-4 input-wrapper disabled ${formErrorData.username ? "error" : ""}`}>
                  <span className='icon-container'>
                     <i className="fa-solid fa-envelope"></i>
                  </span>
                  <input disabled value={formData.username} onChange={handleChange} type="text"
                         className={`form-control`}
                         id="username" name="username" autoComplete='off'
                         placeholder='Enter your email'/>
               </div>

               <label htmlFor="company" className="form-label">Company</label>
               <div className={`mb-4 input-wrapper ${formErrorData.company ? "error" : ""}`}>
                  <span className='icon-container'>
                     <i className="fa-solid fa-building"></i>
                  </span>
                  <input value={formData.company} onChange={handleChange} type="text" className={`form-control`}
                         id="company" name="company" autoComplete='off'
                         placeholder='Enter your company'/>
               </div>

               <div>
                  <label htmlFor="password" className="form-label">Password</label>
                  <div className={`mb-4 input-wrapper ${formErrorData.password ? "error" : ""}`}>
                  <span className='icon-container'>
                     <i className="fa-solid fa-lock"></i>
                  </span>
                     <input value={formData.password} onChange={handleChange}
                            type={typePassword ? 'password' : 'text'}
                            name="password" className={`form-control`} id="password" autoComplete='off'
                            placeholder='Enter your password'/>
                     <span className={'eye'} onClick={() => setTypePassword(prevState => !prevState)}>
                     <i className={typePassword ? 'fa-solid fa-eye' : 'fa-solid fa-eye-slash'}></i>
                  </span>
                  </div>
               </div>

               {
                  error !== "" &&
                  <div className="alert alert-danger alerting mt-3" role="alert">{error}</div>
               }
               <div>
                  {
                     <SpinnerButton title='Register' buttonType='login'
                                    cssClasses='button button-primary u-margin-top-m'
                                    dataLoading={props.isDataLoading} onClickCallback={() => checkForm()} />
                  }
               </div>

               <div className="forgot-password u-margin-top-m">
                  <NavLink to={'/login'} className='button button-tertiary forgot-password-link'>Already have an
                     account? Log in</NavLink>
               </div>
            </form>
         </div>
      </>
   );

   function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
      const {name, value} = e.target;
      setFormData({
         ...formData,
         [name]: value
      })
   }

   function checkForm(event?: React.FormEvent<HTMLFormElement>) {
      event?.preventDefault();

      let fullName = formData.fullName.trim().length === 0;
      let company = formData.company.trim().length === 0;
      let username = formData.username.trim().length === 0;
      let password = formData.password.trim().length < 6;
      let registerCode = formData.registerCode.trim().length === 0;

      setFormErrorData({fullName, username, company, password, registerCode});
      if (fullName || username || company || password || registerCode) {
         if (password) {
            setError("Password must be at least 6 characters long.");
         }
         else {
            setError("");
         }
         return;
      }

      const data: SendRegisterData = {
         data: formData
      }

      props.submitFormFunction(data);
   }
}

export default RegisterFormComponent;