import styles from './TeamComponent.module.scss';
import React, {useContext, useEffect, useRef, useState} from "react";
import {HTML5Backend} from "react-dnd-html5-backend";
import DragLayer from "../elements/DragLayer";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import DropContainer from "../elements/DropContainer";
import {DndProvider} from "react-dnd";
import {Badge} from "@mui/material";
import AuthContext from "../../../../../../../../store/auth-context";
import {NeededEmployee, NeededSkill, ProjectEmployee} from "../../../../../../../../types/NeededPeopleData";

const TeamComponent: React.FC<{
   projectEmployees: ProjectEmployee[],
   potentialLearners: NeededEmployee[],
   topCandidates: NeededEmployee[],
   allEmployees: NeededEmployee[],
   setSelectedTeamEmployees: Function,
   selectedSkill: NeededSkill,
   addProjectEmployee: Function,
   deleteProjectEmployees: Function,
   complete: Function,
   changeStep: Function,
   inMiddle: boolean

   // similarPeople: any,
   // findSimilarPeople: Function,
   // setSimilarFlag: Function,
   // similarMessage: string,
   // updateTeamEmployee: Function,
}> = (props) => {

   const userData = useContext(AuthContext).userData;

   const teamZoneRef = useRef<any>();
   const learnersZoneRef = useRef<any>();
   const knowersZoneRef = useRef<any>();
   const allEmployeesZoneRef = useRef<any>();
   // const similarZoneRef = useRef<any>();

   const [teamList, setTeamList] = useState<ProjectEmployee[]>(props.projectEmployees);
   const [learnersList, setLearnersList] = useState<NeededEmployee[]>(props.potentialLearners);
   const [knowersList, setKnowersList] = useState<NeededEmployee[]>(props.topCandidates);
   const [allEmployeesList, setAllEmployeesList] = useState<NeededEmployee[]>(props.allEmployees);

   // const [similarPeopleList, setSimilarPeopleList] = useState<any>(props.similarPeople);

   const [selectedEmployees, setSelectedEmployees] = useState<{team: boolean, employees: NeededEmployee[]}>({team: false, employees: []});
   const [sortState, setSortState] = useState<boolean>(true); // true za top knowers, false za bottom
   const [sortStateCandidates, setSortStateCandidates] = useState<boolean>(true); // true za A-Z, false za Z-A
   // const [similarPeopleFlag, setSimilarPeopleFlag] = useState<boolean>(false);

   const [candidatesState, setCandidatesState] = useState<number>(1);
   const [filterNameState, setFilterNameState] = useState<string>('');

   useEffect(() => {
      // Deselect employees (if there are any selected) when you click on a skill card
      if (selectedEmployees.employees && selectedEmployees.employees.length > 0 && props.selectedSkill) {
         deselectEmployees();    
      }
   }, [props.selectedSkill]);

   useEffect(() => {
      if (knowersList !== props.topCandidates) {
         setKnowersList(props.topCandidates);
      }
      if (learnersList !== props.potentialLearners) {
         setLearnersList(props.potentialLearners);
      }
      if (teamList !== props.projectEmployees) {
         setTeamList(props.projectEmployees);
      }
      if (allEmployeesList !== props.allEmployees) {
         setAllEmployeesList(props.allEmployees);
      }
      // if (similarPeopleList !== props.similarPeople) {
      //    setSimilarPeopleList(props.similarPeople);
      // }
   }, [props.potentialLearners, props.topCandidates, props.projectEmployees]); // props.similarPeople

   useEffect(() => {
      if (learnersList !== props.potentialLearners || knowersList !== props.topCandidates || teamList.length > 0 && props.projectEmployees.length !== teamList.length) {
         props.changeStep();
      }
   }, [teamList, learnersList, knowersList]);

   return (
      <>
         <div className={styles['team-component']}>
            <div className="teams">
               <DndProvider backend={HTML5Backend}>
                  <DragLayer/>
                  <div className="row">
                     <div className={`col-12 col-lg-6`}>
                        <div className="section">
                           <div className={'tabs-container candidates-container'}>
                              <Tabs className={'custom-tabs'} selectedTabClassName={'selected-tab'}>
                                 <TabList>
                                    <Tab onClick={() => setCandidatesState(1)} className={'react-tabs__tab top-employees-tab'}>Top From AI</Tab>
                                    <Tab onClick={() => setCandidatesState(2)} className={'react-tabs__tab all-employees-tab'}>All Employees</Tab>
                                 </TabList>

                                 <TabPanel>
                                    <div className={'wrapper'}>
                                       <div className={'needed-people-title items-center'}>
                                          <img src="/assets/images/global-icons/suggested-by-ai-icon.svg" alt="Suggested By AI"/> <span>Suggested by AI: TOP 10</span>
                                       </div>

                                       <div className="items-center">
                                          <div className="filters">
                                             <div className="filter-component">
                                                <div onClick={changeCandidates} className={`${candidatesState === 1 ? 'selected' : ''}`}>Knowers</div>
                                                <div onClick={changeCandidates} className={`learners-tab ${candidatesState === -1 ? 'selected' : ''}`} >Learners</div>
                                             </div>
                                          </div>
                                       </div>

                                       <p className="needed-people-text">
                                          {
                                             candidatesState === 1 ? 'Candidates based on their knowledge of the needed skills.' : 'Candidates based on their learning ability of the needed skills.'
                                          }
                                       </p>

                                       <div className={'body'}>
                                          {
                                             ((candidatesState === 1 && knowersList.length > 0) || (candidatesState === -1 && learnersList.length > 0)) &&
                                             <div className={'name-skill-body'}>
                                                <span onClick={() => checkCandidates()} className={`check-box ${checkCandidatesSelected()}`}>
                                                   <i className="fa-solid fa-minus"></i>
                                                   <i className="fa-solid fa-check"></i>
                                                </span>
                                                <div>Employee</div>
                                                <div className={'name-deselect'}>
                                                   {
                                                      selectedEmployees.employees && !selectedEmployees.team && selectedEmployees.employees.length > 0 &&
                                                      <div onClick={deselectEmployees} className={'deselect'}>Deselect</div>
                                                   }
                                                </div>
                                                <div>
                                                   {
                                                      candidatesState === 1 ? 'Skill knows' : 'Can learn'
                                                   }
                                                </div>
                                             </div>
                                          }
                                          <div className={'drop-container'}>
                                             {
                                                candidatesState === 1 ?
                                                   <DropContainer targetId={'knowers-zone'} ref={knowersZoneRef}
                                                                  fields={knowersList}
                                                                  selectedSkill={props.selectedSkill}
                                                                  employeeSelected={employeeSelected}
                                                                  addItemsToCart={addItemsToCartNew}/>
                                                   :  <DropContainer targetId={'learners-zone'} ref={learnersZoneRef}
                                                                     fields={learnersList}
                                                                     selectedSkill={props.selectedSkill}
                                                                     employeeSelected={employeeSelected}
                                                                     addItemsToCart={addItemsToCartNew}/>
                                             }
                                          </div>
                                       </div>
                                    </div>
                                 </TabPanel>

                                 <TabPanel>
                                    <div className={'wrapper'}>
                                       <div className="filter-name">
                                          <div className="wrapper-filter-name">
                                             <i className="fa-solid fa-magnifying-glass"></i>
                                             <input className={'filter-name-input form-control'} type="text"
                                                    onChange={filterNameStateHandler} value={filterNameState}
                                                    placeholder={'Search skill by name'}/>
                                          </div>
                                       </div>
                                       <div className="items-center space-between m-bottom">
                                          <div className="num-employees">{filterEmployees().length} employees</div>
                                          <span onClick={changeSortStateCandidates}
                                                className={`sort ${sortStateCandidates ? '' : 'rotating'}`}>A-Z <i
                                             className="fa-solid fa-chevron-down"></i></span>
                                       </div>
                                       <div className={'body'}>
                                          {
                                          allEmployeesList.length > 0 &&
                                             <div className={'name-skill-body'}>
                                                <span onClick={() => checkCandidates()} className={`check-box ${checkCandidatesSelected()}`}>
                                                   <i className="fa-solid fa-minus"></i>
                                                   <i className="fa-solid fa-check"></i>
                                                </span>
                                                <div className={'name-deselect'}>
                                                   <div>Name</div>
                                                   {
                                                      selectedEmployees.employees && !selectedEmployees.team && selectedEmployees.employees.length > 0 &&
                                                      <div onClick={deselectEmployees}
                                                           className={'deselect'}>Deselect</div>
                                                   }
                                                </div>
                                                <div>Skill Knows</div>
                                             </div>
                                          }
                                          <div className={'drop-container'}>
                                             <DropContainer targetId={'allEmployees-zone'} ref={allEmployeesZoneRef}
                                                            fields={filterEmployees()}
                                                            sortFunction={sortFunctionCandidates}
                                                            selectedSkill={props.selectedSkill}
                                                            employeeSelected={employeeSelected}
                                                            addItemsToCart={addItemsToCartNew} />
                                          </div>
                                       </div>
                                    </div>
                                 </TabPanel>
                              </Tabs>
                           </div>
                           {/*{*/}
                           {/*   similarPeopleFlag && <div className={'backdrop'}></div>*/}
                           {/*}*/}
                        </div>
                     </div>
                     <div className={'col-12 col-lg-6'}>
                        <div className="middle-part">
                           <div className={`section-2`}> {/*${similarPeopleFlag ? 'similar-active' : ''}*/}
                              <div className="header-team">
                                 <h2>Your team {teamList.length > 0 ? `(${teamList.length})` : ''}</h2>
                                 {
                                    <button onClick={() => props.complete()} className={'button button-primary'}>
                                       {
                                          props.projectEmployees.length === 0 ? 'Skip' : 'Complete'
                                       }
                                    </button>
                                 }
                              </div>
                              {teamList.length > 0 && // !similarPeopleFlag
                                 <div className="info">
                                    {/*<div onClick={toggleSimilarPeople} className={'similar-button'}><i className="fa-solid fa-user-plus"></i>*/}
                                    {/*   {*/}
                                    {/*      selectedEmployees.team && selectedEmployees.employees.length > 0 ?*/}
                                    {/*         <span>Find similar people to selected ({selectedEmployees.employees.length})</span> : <span>Find similar people to all</span>*/}
                                    {/*   }*/}
                                    {/*</div>*/}
                                    <div className={'delete-wrapper'}>
                                       <Badge
                                          onClick={() => deleteProjectEmployees()}
                                          className={'delete-badge'}
                                          anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                                          badgeContent={<span
                                             className={"badge rounded-pill num-employees"}>{selectedEmployees.team ? selectedEmployees.employees.length : 0}</span>}>
                                          <i className="fa-regular fa-trash-can"></i>
                                       </Badge>
                                    </div>
                                    <div onClick={changeSortState} className={`sort ${sortState ? '' : 'rotating'}`}>Top knowers <i className="fa-solid fa-chevron-down"></i></div>
                                 </div>
                              }
                              {
                                 teamList.length === 0 &&
                                 <>
                                    <p className={'no-items-text'}>Drag and drop candidates from the left panel to form your
                                       team.</p>
                                    <div className={'no-items'}>
                                       <Badge
                                          sx={{width: '60%'}}
                                          anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                                          badgeContent={<>
                                             <i className="fa-solid fa-circle-user"></i>
                                             <i className="fa-solid fa-arrow-pointer"></i>
                                          </>}>
                                          <div className={'empty-div'}></div>
                                       </Badge>
                                    </div>
                                 </>
                              }
                              <div className="body-team">
                                 {
                                    teamList.length > 0 &&
                                    <div className="card-info">
                                       <div className={'first-wrapper'}>
                                          <span onClick={() => checkCandidates(true)} className={`check-box ${checkCandidatesSelected(true)}`}>
                                                   <i className="fa-solid fa-minus"></i>
                                                   <i className="fa-solid fa-check"></i>
                                                </span>
                                          <div>Name</div>
                                          {selectedEmployees.employees.length > 0 && selectedEmployees.team && // !similarPeopleFlag &&
                                             <div className={'deselect'} onClick={deselectEmployees}>Deselect</div>}
                                       </div>
                                       <div className={'can-learn'}>Can learn</div>
                                       <div>Knows</div>
                                    </div>
                                 }
                                 <div className={'drop-container'}>
                                 <DropContainer targetId={'team-zone'} ref={teamZoneRef} fields={teamList}
                                                   sortFunction={sortFunction}
                                                   addItemsToCart={addItemsToCartNew} employeeSelected={employeeSelected}
                                                   moveItemBack={moveItemBack}
                                                   teamComponent={true}
                                                   addProjectEmployee={addProjectEmployee}
                                                   projectEmployees={props.projectEmployees}
                                                   // updateProjectEmployee={updateProjectEmployee}
                                                   // similarPeopleFlag={similarPeopleFlag}
                                                   // getSimilarPeople={getSimilarPeople}
                                                   inMiddle={props.inMiddle}
                                    />
                                 </div>
                              </div>
                           </div>
                           {/*{*/}
                           {/*   similarPeopleFlag && similarPeopleList && similarPeopleList.length > 0 &&*/}
                           {/*   <div className={`section-2 similar-people`}>*/}
                           {/*      <p>Similar to selected (2):</p>*/}
                           {/*      <div className="card-info">*/}
                           {/*         <div className={'first-wrapper'}>*/}
                           {/*            <div>Name</div>*/}
                           {/*         </div>*/}
                           {/*         <div>Skills</div>*/}
                           {/*      </div>*/}
                           {/*      <div className={'similar-body'}>*/}
                           {/*         <div className={'drop-container'}>*/}
                           {/*            <DropContainer targetId={'similar-zone'} ref={similarZoneRef}*/}
                           {/*                           fields={similarPeopleList}*/}
                           {/*                           similarZone={true}*/}
                           {/*                           addItemsToCart={addItemsToCart}*/}
                           {/*                           employeeSelected={employeeSelected}*/}
                           {/*            />*/}
                           {/*         </div>*/}
                           {/*      </div>*/}
                           {/*      <div className={'similar-footer'}>*/}
                           {/*         <div onClick={toggleSimilarPeople} className={'similar-cancel'}>Close</div>*/}
                           {/*         <button disabled={selectedEmployees.employees.length === 0} onClick={addSimilarPeople} className={'btn btn-primary similar-add'}>*/}
                           {/*            Add to the team ({selectedEmployees.employees.length})*/}
                           {/*         </button>*/}
                           {/*      </div>*/}
                           {/*   </div>*/}
                           {/*}*/}
                           {/*{*/}
                           {/*   similarPeopleFlag && similarPeopleList && similarPeopleList.length === 0 && filterSimilarPeople().length === 0 &&*/}
                           {/*   <div className={`section-2 similar-people`}>*/}
                           {/*      <div className={'learning-similar-title'}>Choose 'Knowers' to find similar profiles.</div>*/}
                           {/*      <div className={'learning-similar-body'}>Sorry, for now similar matches are available only for team members who already know a skill.</div>*/}
                           {/*      <div className={'similar-footer'}>*/}
                           {/*         <div onClick={toggleSimilarPeople} className={'similar-cancel'}>Close</div>*/}
                           {/*      </div>*/}
                           {/*   </div>*/}
                           {/*}*/}
                           {/*{*/}
                           {/*   similarPeopleFlag && similarPeopleList && similarPeopleList.length === 0 && filterSimilarPeople().length > 0 &&*/}
                           {/*   <div className={`section-2 similar-people`}>*/}
                           {/*      <div className={'learning-similar-title'}>No similar people to these profiles.</div>*/}
                           {/*      <div className={'learning-similar-body'}>There are no similar profiles for the selected employee {filterSimilarPeople().length > 1 ? 's' : ''} .</div>*/}
                           {/*      <div className={'similar-footer'}>*/}
                           {/*         <div onClick={toggleSimilarPeople} className={'similar-cancel'}>Close</div>*/}
                           {/*      </div>*/}
                           {/*   </div>*/}
                           {/*}*/}
                        </div>
                     </div>
                  </div>
               </DndProvider>
            </div>
         </div>
      </>
   )

   function filterEmployees() {
      return allEmployeesList.filter(e => {
         return e.employeeName.trim().toLowerCase().includes(filterNameState.trim().toLowerCase());
      })
   }
   function filterNameStateHandler(event: any) {
      deselectEmployees();
      setFilterNameState(event.target.value);
   }

   function sortFunctionCandidates(e1: NeededEmployee, e2: NeededEmployee) {
      const sortValue = sortStateCandidates ? 1 : -1;
      return e1.employeeName.localeCompare(e2.employeeName) * sortValue;
   }
   function sortFunction(e1: ProjectEmployee, e2: ProjectEmployee) {
      if (!e1.skills) {
         return sortState ? -1 : 1;
      }
      else if (!e2.skills) {
         return sortState ? 1 : -1;
      }
      else {
         if (e1.skills && e2.skills) {
            return sortState ? e1.skills.length - e2.skills.length : e2.skills.length - e1.skills.length;
         }
         else {
            return 0;
         }
      }
   }
   function isSubset(subsetArray: any, superSetArray: any) {
      if (subsetArray.length === 0) {
         return false;
      }
      return subsetArray.every((subElement: any) =>
         superSetArray.some((superElement: any) =>
            JSON.stringify(subElement) === JSON.stringify(superElement)
         )
      );
   }

   function checkCandidatesSelected(teamZone?: boolean) { // for checkbox above the container
      if (selectedEmployees.team && selectedEmployees.employees.length > 0 && teamZone) {
         if (JSON.stringify(selectedEmployees.employees) === JSON.stringify(teamList)) {
            return 'selected';
         }
         else if (isSubset(selectedEmployees.employees, teamList)) {
            return 'partial';
         }
      }
      if (selectedEmployees.team !== teamZone) {
         return '';
      }
      if (candidatesState === 2) { // all employees
         if (JSON.stringify(selectedEmployees.employees) === JSON.stringify(allEmployeesList)) {
            return 'selected';
         }
         else if (isSubset(selectedEmployees.employees, allEmployeesList)) {
            return 'partial';
         }
      }
      else if (candidatesState === 1) { // knowers
         if (JSON.stringify(selectedEmployees.employees) === JSON.stringify(knowersList)) {
            return 'selected';
         }
         else if (isSubset(selectedEmployees.employees, knowersList)) {
            return 'partial';
         }
      }
      else { // learners
         if (JSON.stringify(selectedEmployees.employees) === JSON.stringify(learnersList)) {
            return 'selected';
         }
         else if (isSubset(selectedEmployees.employees, learnersList)) {
            return 'partial';
         }
      }
      return '';
   }

   function changeCandidates() {
      setCandidatesState(prevState => (prevState * -1));
      setSelectedEmployees({team: false, employees: []});
      deselectEmployees();
   }

   function checkCandidates(teamZone?: boolean) {
      const hasEmployees = selectedEmployees.employees.length > 0;

      if (hasEmployees && teamZone !== selectedEmployees.team) {
         deselectEmployees();
      }

      if (hasEmployees && teamZone === selectedEmployees.team) {
         deselectEmployees();
      }

      else {
         if (teamZone) {
            teamZoneRef.current.selectAll();
         }
         else {
            switch (candidatesState) {
               case 2:
                  allEmployeesZoneRef.current.selectAll();
                  break;
               case 1:
                  knowersZoneRef.current.selectAll();
                  break;
               case -1:
                  learnersZoneRef.current.selectAll();
                  break;
               default:
                  return;
            }
         }
      }
   }

   // function addSimilarPeople() {
   //    if (similarPeopleFlag && selectedEmployees.employees.length > 0) {
   //       props.addProjectEmployee(selectedEmployees.employees,null,userData.accessToken);
   //    }
   //    toggleSimilarPeople();
   // }

   // function filterSimilarPeople() {
   //    return selectedEmployees.employees.filter((employee: any) => employee.workAvailability > 0);
   // }

   // function toggleSimilarPeople() {
   //    if (similarPeopleFlag) {
   //       setSimilarPeopleFlag(false);
   //       props.setSimilarFlag(false);
   //       deselectEmployees();
   //    }
   //    else {
   //       deselectEmployees();
   //       getSimilarPeople();
   //       setSimilarPeopleFlag(true);
   //       props.setSimilarFlag(true);
   //    }
   // }

   // function getSimilarPeople() {
   //    let projectEmployeeIds = [];
   //    if (selectedEmployees.team && selectedEmployees.employees && selectedEmployees.employees.length > 0) {
   //       projectEmployeeIds = selectedEmployees.employees.filter((employee: any) => +employee.workAvailability > 0).map((employee: any) => employee.projectEmployeeId);
   //    }
   //    else {
   //       projectEmployeeIds = teamList.filter((employee: any) => +employee.workAvailability > 0).map((employee: any) => employee.projectEmployeeId);
   //    }
   //    props.findSimilarPeople(projectEmployeeIds, userData.accessToken);
   // }

   function changeSortState() {
      setSortState((prevState) => {
         return !prevState;
      })
   }

   function changeSortStateCandidates() {
      setSortStateCandidates(prevState => !prevState);
   }

   // function updateProjectEmployee(employee: { projectEmployeeId: number, availability: number, type: string }) {
   //    props.updateTeamEmployee(employee, userData.accessToken);
   // }

   function addProjectEmployee(employee: any, availability: number) {
      props.addProjectEmployee(employee, availability, userData.accessToken);
   }

   function moveItemBack() {
      setKnowersList(props.topCandidates);
      setLearnersList(props.potentialLearners);
      setTeamList(props.projectEmployees);
      // setSimilarPeopleList(props.similarPeople);
   }

   function deselectEmployees() {     
      teamZoneRef.current.clearSelection();

      if (learnersZoneRef.current) {
         learnersZoneRef.current.clearSelection();
      }
      if (knowersZoneRef.current) {
         knowersZoneRef.current.clearSelection();
      }
      if (allEmployeesZoneRef.current) {
         allEmployeesZoneRef.current.clearSelection();
      }
      // if (similarZoneRef.current) {
      //    similarZoneRef.current.clearSelection();
      // }
      setSelectedEmployees({team: false, employees: []});
      props.setSelectedTeamEmployees({team: false, employees: []});
   }

   function employeeSelected(selection: { team: boolean, employees: any }) {
      if (selectedEmployees.team != selection.team) {
         if (selection.team) {
            if (learnersZoneRef.current) {
               learnersZoneRef.current.clearSelection();
            }
            if (knowersZoneRef.current) {
               knowersZoneRef.current.clearSelection();
            }
         } else {
            teamZoneRef.current.clearSelection();
         }
      }
      setSelectedEmployees(selection);
      props.setSelectedTeamEmployees(selection);
   }

   function deleteProjectEmployees(employees? : any) {
      if (employees) {
         props.deleteProjectEmployees(employees, userData.accessToken);
         deselectEmployees();
         return;
      }

      if (selectedEmployees.team && selectedEmployees.employees && selectedEmployees.employees.length > 0 && !employees) {
         props.deleteProjectEmployees(selectedEmployees.employees, userData.accessToken);
         deselectEmployees();
      }
   }

   function addItemsToCartNew(items: any, sourceId: any, dropResult: any): void {
      let sourceContainerId: string = sourceId;
      let targetContainerId: string = dropResult.targetId;

      let source = getElements(sourceContainerId);
      let destination = getElements(targetContainerId);

      if (sourceContainerId === 'team-zone') { // delete employees from project
         deleteProjectEmployees(items);
         return;
      }

      for (let item of items) {
         // delete employee from source container list
         let elementIndexInSourceList: number = source.list.findIndex((employee: any) => employee.employeeId === item.employeeId);
         let elementIndexInDestinationList: number = destination.list.findIndex((employee: any) => employee.employeeId === item.employeeId);

         if (elementIndexInSourceList > -1) {
            source.list.splice(elementIndexInSourceList, 1);
            if (elementIndexInDestinationList === -1) {  // this is to not have duplicated employees in the destination list for ex. leaner and knower for the same person
               destination.list.push(item);
            }
         }
      }

      source.callback(source.list);
      destination.callback(destination.list); // set resultant lists

      deselectEmployees();

      if (targetContainerId === 'team-zone') { // add employees to project
         props.addProjectEmployee(items, 1, userData.accessToken);
      }
   }

   function addItemsToCart(items: any, sourceId: any, dropResult: any): void {
      let sourceContainerId: string = sourceId;
      let targetContainerId: string = dropResult.targetId;

      let source = getElements(sourceContainerId);
      let destination = getElements(targetContainerId);

      if (sourceContainerId === 'team-zone') {
         // if (targetContainerId === 'similar-zone') {
         //    return;
         // }
         deleteProjectEmployees(items);
         deselectEmployees();
         return;
      }

      // if (sourceContainerId === 'similar-zone' && targetContainerId === 'team-zone') {
      //    props.addProjectEmployee(items,null,userData.accessToken);
      //    // toggleSimilarPeople();
      // }

      for (let item of items) {
         let elementIndexInSourceList: number = source.list.findIndex((employee: any) => employee.employeeId === item.employeeId);
         let elementIndexInTargetList: number = destination.list.findIndex((employee: any) => employee.employeeId === item.employeeId);
         let elementInTargetList = destination.list.find((employee: any) => employee.employeeId === item.employeeId);


         if (elementIndexInSourceList > -1) {
            source.list.splice(elementIndexInSourceList, 1);

            if (targetContainerId === 'team-zone') {
               if (elementIndexInTargetList > -1) {
                  destination.list.splice(elementIndexInTargetList, 1);
                  item.duplicate = true;
                  // item.workAvailability = elementInTargetList.workAvailability;
                  // item.learningAvailability = elementInTargetList.learningAvailability;
               }
            }
            destination.list.push(item);
         }
      }

      source.callback(source.list);
      destination.callback(destination.list);

      if (sourceContainerId !== 'similar-zone') {
         teamZoneRef.current.clearSelection();
         setSelectedEmployees({team: false, employees: []});
         props.setSelectedTeamEmployees({team: false, employees: []});
      }

      if (learnersZoneRef.current) {
         learnersZoneRef.current.clearSelection();
      }
      if (knowersZoneRef.current) {
         knowersZoneRef.current.clearSelection();
      }
   }

   function getElements(id: any) {
      let list = [];
      let callback: any;

      switch (id) {
         case 'team-zone':
            list = JSON.parse(JSON.stringify(teamList));
            callback = setTeamList;
            break;
         case 'learners-zone':
            list = JSON.parse(JSON.stringify(learnersList));
            callback = setLearnersList;
            break;
         case 'knowers-zone':
            list = JSON.parse(JSON.stringify(knowersList));
            callback = setKnowersList;
            break;
         case 'allEmployees-zone':
            list = JSON.parse(JSON.stringify(allEmployeesList));
            callback = setKnowersList;
            break;
         // case 'similar-zone':
         //    list = JSON.parse(JSON.stringify(similarPeopleList));
         //    callback = setSimilarPeopleList;
         //    break;
      }
      return {list, callback};
   }

}

export default TeamComponent;